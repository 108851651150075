import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";
import "./payment.css";
import BackArrow from "../../../assets/img/back_arrow.png";
import {API_ENDPOINT, IMG_ALT , STRIPE_PROMISE } from "../../../constants";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createCards, deleteCard, getCards, getClientSecret } from "../../../redux/actions/booking";
import { useSelector } from "react-redux";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {actionTypes} from "../../../redux/constants/action-type";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import {auth} from "../auth/firebase";
import axios from "axios";

const stripePromise = loadStripe(STRIPE_PROMISE);

// const stripePromise = loadStripe('pk_live_51NHB2lIsqurXT5hXLItJPy5YOcT1dpvYXOu08uqMgHPpxuYpM9aB1MuikDFgh1thhmo75aeQxTrYc1QHhCXJGN1K00WJEmDpOX');

const Payment = ({
  selectedBooking,
  isPaymentSidebar,
  onClosePaymentSidebar,
  isFromBooking,
  getPaymentCard,
}) => {
  const dispatch = useDispatch()
  const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(null);
  const [userPaymentCards, setUserPaymentCards] = useState([]);
  const [setupClientSecret, setSetupClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDots, setLoadingDots] = useState(false);
  const [paymentCards, setPaymentCards] = useState({
    viewPaymentCards: true,
    addPaymentCard: false,
    editPaymentCard: false,
    paymentCardForm: false,
  });
  const [paymentCardInfo, setPaymentCardInfo] = useState({
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCVC: "",
  });


  useEffect(() => {
    dispatch(getCards(1))
    dispatch(getClientSecret())
  }, [dispatch])
  const cards = useSelector(state => state.cards.cards)
  const clientSecret = useSelector(state => state.clientSecret.clientSecret)
  useEffect(() => {
    if (cards) {
      setUserPaymentCards(cards)
    }
    // console.log(clientSecret);
    if (clientSecret) {
      setSetupClientSecret({
        // passing the client secret obtained from the server
        clientSecret: clientSecret.clientSecret,
      });
    }
  }, [cards, clientSecret])

  async function getAuthObject() {
    const token = await auth.currentUser.getIdToken();

    return { Authorization: `Bearer ${token}` }
  }
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const handleAddPaymentCard = async () => {
    try {
      // Mettez isLoading à true avant de démarrer la requête
      setLoadingDots(true);
  
      console.log('adding payment card');
      console.log(clientSecret);
  
      if (isEmpty(userPaymentCards)) {
        dispatch(getCards(1));
      }
  
      if (isEmpty(clientSecret)) {
        const response = await fetch(`${API_ENDPOINT}/strip/client-setup-secret`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject())
          }
        });
  
        const result = await response.json();
  
        if (response.status >= 200 && response.status < 400) {
          dispatch({ type: actionTypes.CLIENTSECRET, payload: result });
        } else {
          dispatch({ type: actionTypes.CLIENTSECRETERR, payload: result });
        }
      }
  
      // alert('awa');
      setPaymentCards((prevState) => ({
        ...prevState,
        viewPaymentCards: false,
        paymentCardForm: true,
        addPaymentCard: true,
      }));
  
      setPaymentCardInfo({
        cardName: "",
        cardNumber: "",
        cardExpiry: "",
        cardCVC: "",
      });
    } catch (error) {
      console.log('Error while adding payment card', error);
    } finally {
      // Quelle que soit la fin de la requête (réussie ou échouée), mettez à jour isLoading
      setLoadingDots(false);
    }
  };
  

  const handleDeleteCard = (id) => {
    console.log("delete Clicked");
    dispatch(deleteCard({
      id: id
    }));
    dispatch(getCards())
  }

  const savecardcomplete = async () => {
    console.log("Add Complete")

    setPaymentCards((prevState) => ({
      ...prevState,
      viewPaymentCards: true,
      paymentCardForm: false,
      addPaymentCard: false,
    }));
    dispatch(getCards())
  };
  const handleSavePaymentCard = async () => {
    const sepration = paymentCardInfo?.cardExpiry?.split("/")
    const data = {
      number: parseInt(paymentCardInfo.cardNumber ? paymentCardInfo.cardNumber : 42424242424242424242),
      expYear: parseInt(sepration ? sepration[1] : 24),
      expMonth: parseInt(sepration ? sepration[0] : 12),
      cvc: parseInt(paymentCardInfo.cardCVC),
    }
    const dataToSend = JSON.stringify(data)
    const response = await dispatch(createCards(dataToSend))
    if (response.error) {
    } else {
      setPaymentCards((prevState) => ({
        ...prevState,
        viewPaymentCards: true,
        paymentCardForm: false,
        addPaymentCard: false,
      }));
      dispatch(getCards())
    }
  };
  const handleEditPaymentCard = () => {
    setPaymentCards((prevState) => ({
      ...prevState,
      viewPaymentCards: false,
      paymentCardForm: true,
      editPaymentCard: true,
    }));
    setPaymentCardInfo({
      cardName: "MasterCard",
      cardNumber: "2141412414",
      cardExpiry: "02/24",
      cardCVC: "768",
    });
  };
  const handleBack = (from, to) => {
    setPaymentCards((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
      paymentCardForm: !prevState.paymentCardForm,
    }));
  };
  const handleInputChange = (event) => {
    setPaymentCardInfo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleConfirmPaymentCard = async () => {
    if (isFromBooking && selectedBooking && selectedBooking.id) {
      setIsLoading(true);
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.post(API_ENDPOINT + "/booking/payment/method/update",
          { id: isPaymentCardSelected.id, bookingId: selectedBooking.id, paymentId: isPaymentCardSelected.payment_id },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setIsLoading(false);
        getPaymentCard(isPaymentCardSelected);
        onClosePaymentSidebar();
      } catch (error) {
        console.log('locaiton eroor: ', error); 
        setIsLoading(false);
      }
    } else {
      getPaymentCard(isPaymentCardSelected);
      onClosePaymentSidebar();
    }

  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));
  return (
    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isPaymentSidebar}
      onHide={onClosePaymentSidebar}
    >
      <Offcanvas.Header className="justify-content-start clt_payment_offcanvas_header">
        {paymentCards.viewPaymentCards && (
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onClosePaymentSidebar}
              // onClick={() => {  getPaymentCard(isPaymentCardSelected); onClosePaymentSidebar(); }}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Offcanvas.Title>Moyen de paiement</Offcanvas.Title>
              {paymentCards.viewPaymentCards && userPaymentCards.length > 0 && (
                <Button
                  className="clt_header_add_button shadow-none"
                  onClick={handleAddPaymentCard}
                  disabled={isLoadingDots} // Désactivez le bouton pendant le chargement
                >
                  {isLoadingDots ? (
                    <LoaderDots /> 
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              )}
            </div>
          </React.Fragment>
        )}
        {paymentCards.addPaymentCard && (
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={() => handleBack("addPaymentCard", "viewPaymentCards")}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <Offcanvas.Title>Ajouter</Offcanvas.Title>
          </React.Fragment>
        )}
        {paymentCards.editPaymentCard && (
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={() => handleBack("editPaymentCard", "viewPaymentCards")}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <Offcanvas.Title>Modifier</Offcanvas.Title>
          </React.Fragment>
        )}
      </Offcanvas.Header>
      <Offcanvas.Body
        className={`clt_payment_offcanvas_body ${paymentCards.viewPaymentCards &&
          userPaymentCards.length <= 0 &&
          `d-flex flex-column justify-content-center align-items-center`
          }`}
      >
        {paymentCards.viewPaymentCards &&
          (userPaymentCards.length > 0 ? (
            <>
              <div className="clt_payment_innerdivs">
                <h2 className="clt_payment_innerhead">Moyen de paiement</h2>
                {userPaymentCards.map((value, index) => {
                  return (
                    <div
                      className={`d-flex align-items-center justify-content-start pfr_payment_cardsdiv ${isFromBooking && "cursor-pointer"}`}
                      key={index}
                      onClick={() => isFromBooking
                        ? setIsPaymentCardSelected(value)
                        : console.log("From Payment")
                      }
                    >
                      <div
                        className="d-flex align-items-center justify-content-start"
                        style={{ width: "20%" }}
                      >
                        <div className="pfr_payment_imgdiv">
                          <img
                            src={require("../../../assets/img/icon_credit_card.png")}
                            className=""
                            alt="Image"
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-start justify-content-center flex-column"
                        style={{ width: "70%" }}
                      >
                        <h3 className="pfr_payment_cardnumber">{value.number}</h3>
                        <p className="pfr_payment_cardname">
                          {value?.type === "card" ? "Carte bancaire" : (value?.type?.replace("_", " ")[0].toUpperCase() + value?.type?.replace("_", " ").substring(1))}
                        </p>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                        style={{ width: "10%" }}
                      >
                        {isFromBooking ? (
                          <div
                            className="inner-div-circle"
                            style={
                              isPaymentCardSelected === value
                                ? {
                                  borderColor: `#000`,
                                }
                                : {
                                  borderColor: `#ddd`,
                                }
                            }
                          >
                            <div
                              className="subDiv"
                              style={
                                isPaymentCardSelected === value
                                  ? {
                                    background: `#000`,
                                  }
                                  : {
                                    background: `transparent`,
                                  }
                              }
                            ></div>
                          </div>
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu
                              size="sm"
                              title=""
                              className="pfr_dropdown_menu"
                            >
                              {/*<Dropdown.Item*/}
                              {/*  onClick={handleEditPaymentCard}*/}
                              {/*  className="pfr_payment_cards3dots_options"*/}
                              {/*>*/}
                              {/*  Modifier*/}
                              {/*</Dropdown.Item>*/}
                              <Dropdown.Item
                                onClick={() => handleDeleteCard(value.id)}
                                className="pfr_payment_cards3dots_options"
                              >
                                Supprimer
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <Row>
              <Col>
                <div className="clt_payment_offcanvas_screen1_div d-flex flex-column justify-content-center align-items-center">
                  <h2>Aucun moyen de paiement</h2>
                  <p>Enregistré vos moyens de paiement pour des réservations futures</p>
                  <Button
                    className="shadow-none"
                    onClick={handleAddPaymentCard}
                  >
                    <span>Ajouter</span>
                  </Button>
                </div>
              </Col>
            </Row>
          ))}
        {paymentCards.paymentCardForm && (
          <div className="clt_payment_innerdivs">
            <h2 className="clt_payment_innerhead">
              {paymentCards.addPaymentCard && "Ajouter un moyen de paiement"}
              {paymentCards.editPaymentCard && "Modifier un moyen de paiement"}
            </h2>
            <Row>
              {/*<Col*/}
              {/*  lg={12}*/}
              {/*  md={12}*/}
              {/*  sm={12}*/}
              {/*  xs={12}*/}
              {/*  className="pfr_inputFloatDiv"*/}
              {/*>*/}
              {/*  <Form.Floating className="pfr_inputFloat">*/}
              {/*    <Form.Control*/}
              {/*      id="floatingInput1"*/}
              {/*      name="cardName"*/}
              {/*      type="text"*/}
              {/*      placeholder="Name on the card"*/}
              {/*      value={paymentCardInfo.cardName}*/}
              {/*      onChange={(event) => handleInputChange(event)}*/}
              {/*    />*/}
              {/*    <label htmlFor="floatingInput1">Name on the card</label>*/}
              {/*  </Form.Floating>*/}
              {/*</Col>*/}
              {/*<Col*/}
              {/*  lg={12}*/}
              {/*  md={12}*/}
              {/*  sm={12}*/}
              {/*  xs={12}*/}
              {/*  className="pfr_inputFloatDiv"*/}
              {/*>*/}
              {/*  <Form.Floating className="pfr_inputFloat">*/}
              {/*    <Form.Control*/}
              {/*      id="floatingInput1"*/}
              {/*      name="cardNumber"*/}
              {/*      type="text"*/}
              {/*      placeholder="Card number"*/}
              {/*      value={paymentCardInfo.cardNumber}*/}
              {/*      onChange={(event) => handleInputChange(event)}*/}
              {/*    />*/}
              {/*    <label htmlFor="floatingInput1">Card number</label>*/}
              {/*  </Form.Floating>*/}
              {/*</Col>*/}
              {/*<Col*/}
              {/*  lg={12}*/}
              {/*  md={12}*/}
              {/*  sm={12}*/}
              {/*  xs={12}*/}
              {/*  className="pfr_inputFloatDiv"*/}
              {/*>*/}
              {/*  <Row>*/}
              {/*    <Col lg={6} md={6} sm={6} xs={6}>*/}
              {/*      <Form.Floating className="pfr_inputFloat">*/}
              {/*        <Form.Control*/}
              {/*          id="floatingInput1"*/}
              {/*          name="cardExpiry"*/}
              {/*          type="text"*/}
              {/*          placeholder="MM/AA"*/}
              {/*          value={paymentCardInfo.cardExpiry}*/}
              {/*          onChange={(event) => handleInputChange(event)}*/}
              {/*        />*/}
              {/*        <label htmlFor="floatingInput1">MM/AA</label>*/}
              {/*      </Form.Floating>*/}
              {/*    </Col>*/}
              {/*    <Col lg={6} md={6} sm={6} xs={6}>*/}
              {/*      <Form.Floating className="pfr_inputFloat">*/}
              {/*        <Form.Control*/}
              {/*          id="floatingInput1"*/}
              {/*          name="cardCVC"*/}
              {/*          type="text"*/}
              {/*          placeholder="CVC"*/}
              {/*          value={paymentCardInfo.cardCVC}*/}
              {/*          onChange={(event) => handleInputChange(event)}*/}
              {/*        />*/}
              {/*        <label htmlFor="floatingInput1">CVC</label>*/}
              {/*      </Form.Floating>*/}
              {/*    </Col>*/}
              {/*  </Row>*/}
              {/*</Col>*/}
              {clientSecret && setupClientSecret?

                  <Elements stripe={stripePromise} options={setupClientSecret}>
                    <CheckoutForm savecardcomplete={() => savecardcomplete()} />
                  </Elements>
                  :<div>Chargement..</div>

              }
            </Row>
          </div>
        )}
        {/*{paymentCards.addPaymentCard && (*/}
        {/*  // <div className="clt_payment_footer">*/}
        {/*  //   <Button className="shadow-none" onClick={handleSavePaymentCard}>*/}
        {/*  //     Add*/}
        {/*  //   </Button>*/}
        {/*  // </div>*/}
        {/*)}*/}
        {paymentCards.editPaymentCard && (
          <div className="clt_payment_footer">
            <Button className="shadow-none clt_payment_header_button_black">
              Enregistrer
            </Button>
          </div>
        )}
      </Offcanvas.Body>
      <div>
        {paymentCards.viewPaymentCards && userPaymentCards.length > 0 && (
          <>
            {isFromBooking && (
              <>
                <Row className="clt-datepicker-sidebar-footer-space" />
                <div className="clt-datepicker-sidebar-footer">
                  <div className="inner-div">
                    <Button
                      onClick={handleConfirmPaymentCard}
                      className={`clt-datepicker-sidebar-footer-btn black_btn shadow-none ${!isPaymentCardSelected && "disabled"
                        }`}
                      disabled={isPaymentCardSelected && !isLoading ? false : true}
                    >
                      <span>Confirmer</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Offcanvas>
  );
};

export default Payment;
