import React from "react";
import { Col, Row, Form, InputGroup } from "react-bootstrap";
// import { IMG_ALT } from "../../../constants";
import Switch from "@mui/material/Switch";
import './Section.css'
import CustomHr from "../CustomHr";
const SectionDepositRefund = ({ title, subText, handleDamageEdit,handleInputChange }) => {

    const [selectedEvent, setSelectedEvent] = React.useState(false);

    return (
        <Row className="deposit_refund">
            <Col>
                <div className="d-flex justify-content-start align-items-start ">
                    <div className="d-flex justify-content-between align-items-start w-100 flex-column">
                        {title && (
                            <h2 className="mb-3 clt-booking-cp-div5-title">{title}</h2>
                        )}
                        <div className=" d-flex w-100 justify-content-center align-items-center flex-row">
                            <div className="d-flex w-100 flex-column justify-content-center align-items-start">
                                <h3 className="h3">Caution</h3>
                                <p>Activez cette option si une caution doit être prélevée</p>
                            </div>
                            <Switch
                                checked={selectedEvent}
                                onChange={() =>
                                    setSelectedEvent(!selectedEvent)
                                }
                                color="primary"
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ mb: 1.5 }}
                            />

                        </div>
                    </div>
                </div>
                {selectedEvent && (
                    <div className="w-100">
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr3"  />
                        <div className="clt_admin_dash_timeslot_edit_box">
                            <div className="d-flex w-100 flex-column justify-content-center align-items-start mb-3">
                                <h3 className="h3">Montant</h3>
                                <p>Définissez le montant d'une caution à prélever sur le moyen de paiement utilisé pour la réservation</p>
                            </div>
                            <Form.Floating className="clt_deposit_dash_timeslot_inputFloat">
                                <InputGroup>
                                    <Form.Control
                                        id="floatingInput1"
                                        type="number"
                                        placeholder=""
                                        onChange={(event) => handleInputChange(event)}
                                    />
                                    <InputGroup.Text id="inputGroup-sizing-sm">€</InputGroup.Text>
                                </InputGroup>
                            </Form.Floating>
                        </div>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default SectionDepositRefund;