// Import
import React, { useState, useEffect } from "react";
import {
    Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { IMG_ALT, eventweddingData, API_ENDPOINT } from "../../../../../../constants";
import "./AdminDashboardBookingDetail.css";
import CustomHr from "../../../../../reusable/CustomHr";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../auth/authContext";

// ------------------------------------------------------------------------------

// Import - Icon / Image
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import img_section_info from "../../../../../../assets/img/img_section_info.png";
import ConfirmPay4 from "../../../../../../assets/img/admin_icon_booking_detail_3.png";
import IconEuro from "../../../../../../assets/img/icon_euro.png";

import ConfirmPay5 from "../../../../../../assets/img/confirm_pay_5.png";
import IconRemainingPayment from "../../../../../../assets/img/remaining_payment.png";
import SectionProfile from '../../../../../reusable/booking/SectionProfile';
import SectionAccess from '../../../../../reusable/SectionAccess';
import SectionClickable2 from '../../../../../reusable/SectionClickable2';
// ------------------------------------------------------------------------------

// import - Section - Top
import SectionTop from "../../../../../reusable/booking/SectionTop";

// import - Section - Booking request
import SectionRequestPending from "../../../../../reusable/SectionRequestPending";

// import - Sidebar - Booking request
import SidebarAdminBookingRequest from "../../admin_dash_booking_request/[SIDEBAR] Admin - Booking Request";

// import - Section - Event
import SectionEvent from "../../../../../reusable/booking/SectionEvent";

// import - Section - Date
import SectionDate from "../../../../../reusable/booking/SectionDate";
import Booking from "../../../../detail/booking/booking_user";

// import - Section - Guest
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import Guest from "../../../../guest/Guest";

// import - Section - Item Additional
import SectionItemAdditional from "../../../../../reusable/booking/SectionItemAdditional";
import AdminItemAdditional from "../../admin_dash_booking_component/component_item_additional/component_item_additional";

// import - Section - User
import SectionUser from "../../../../../reusable/booking/SectionUser";
import Account from "../../../../account/Account";

// import - Section - Room
import SectionBed from "../../../../../reusable/booking/SectionRoom";

// import - Section - Remaining payment
import SectionRemainingPayment from "../../../../../reusable/booking/SectionRemainingPayment";
import RemainingPayment from "../../../../myBooking/RemainingPayment";

// import - Section - Rule and condition
import SidebarRuleAndCondition from "../../../../../reusable/booking/SidebarRuleAndCondition";

// import - Section - Damage deposit
import SectionDamageDeposit from "../../../../../reusable/booking/SectionDamageDeposit";
import DamageDeposit from "../../admin_dash_booking_component/component_damage_deposit/component_damage_deposit";

// import - Section - invoice
import InvoiceOffcanvas from "../../../../../reusable/invoice/SidebarInvoiceList";

// import - Section - Edit request list
import SidebarAdminEditRequestList from "../../../../../reusable/invoice/SidebarInvoiceList";

// import - Section - Price Detail
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetail";

// import - Section - Clickable
import SectionClickable from "../../../../../reusable/SectionClickable";
import SectionClickable3 from "../../../../../reusable/SectionClickable3";

// import - Section - Receipt
import SidebarDocumentRequire from "../../../../../reusable/documentrequire/SidebarDocumentRequire";

// import - Button - Cancel booking
import CancelBooking from "../../admin_dash_booking_component/component_cancel_booking/[SIDEBAR] Admin - Booking - Cancellation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getEvent } from "../../../../../../redux/actions/avent";
import { getBooking } from "../../../../../../redux/actions/booking";
import { useQuery } from "react-query";

import {
    PROFILE_KEY,
    deleteProfessionalProfileRequest,
    addProfessionalProfileRequest,
    getProfessionalProfilesRequest,
    updatePersonalProfileRequest,
    uploadProfilePicRequest,
    updateProfessionalProfileRequest,
} from "../../../../account/api";
import { auth } from "../../../../auth/firebase";
import axios from "axios";
const initialBookingScreens = {
    noBooking: true,
    bookingList: false,
    bookingDetail: false,
    cancelBooking: false,
};

// Const
const SidebarBookingAdmin = ({ isOpenSidebarBookingAdmin, onCloseOpenSidebarBookingAdmin, setIsOpenSidebarBookingAdmin, data, setData }) => {
    const [professionalProfiles, setProfessionalProfiles] = useState([]);
    console.log("first", data)
    const dataa = [];
    const [priceDetailsRooms, setpriceDetailsRooms] = useState([]);
    const [priceDetailsSlots, setpriceDetailsSlots] = useState([]);


    const [isItemAdditionalSidebar, setIsItemAdditionalSidebar] = useState(false);

    const [isItemAdditional, setIsItemAdditional] = useState(false);
    
    const [sectionItemAdditionalList, setSectionItemAdditionalList] = useState([]);

    const [damagePrice, setdamagePrice] = useState(0);
    const [priceDetailsDamage, setpriceDetailsDamage] = useState([
        {
            title: "Caution",
            tooltipMsg:
                "Caution : Un état des lieux sera réalisé avant et après la réservation. Une caution pourra être automatiquement déduite pour les dégâts subis.",
            price: "facturé automatiquement en cas de dommage.",
        },
    ]);
    useEffect(async () => {
        if (data) {
            setpriceDetailsRooms(
                data?.BookingRooms?.map((room) => {
                    return {
                        title: `${room?.title} (${room?.quantity} nuit)`,
                        tooltipMsg: null,
                        price: `${room?.price}€`,
                    };
                })
            );
            // setdamagePrice(data?.damage_deposit);
            if (data?.damage_deposit) {
                var tempprice = 0;
                for (var i = 0; i < data?.damage_deposit.length; i++) {
                    console.log(data?.damage_deposit[i]);
                    tempprice += parseInt(data?.damage_deposit[i]?.amount);
                }
                setdamagePrice(tempprice);
            }
            // const dbooking = await dispatch(getBooking(data?.id));
            // data?.damage_deposit.foreach((ditem)=>{
            //     console.log("item--"+JSON.stringify(ditem))
            //     setdamagePrice(damagePrice+ditem.amount)
            // });
            setpriceDetailsSlots([
                { title: "Créneau", tooltipMsg: null, price: `${data?.ChildTimeSlot?.price}€` }
            ])
        }
    }, [data])
    const event = useSelector((state) => state.event.event);
    const dispatch = useDispatch();
    const [sortedsection6DetailCategory, setSortedsection6DetailCategory] = useState([]);
    useEffect(() => {
        if (data)
            dispatch(getEvent(data?.Event?.id));
    }, [data])
    useEffect(() => {
        if (event) {

            const section6DetailCategory = event?.detailCategory;
            const myData = section6DetailCategory?.map(
                (category) => {
                    return {
                        ...category,
                        rules: category.rules.sort((a, b) => a.order - b.order),
                    };
                }
            );
            setSortedsection6DetailCategory(myData)
        }
    }, [event])

    {/* Const - Request Pending */ }
    const [isOpenSidebarAdminBookingRequest, setIsOpenSidebarAdminBookingRequest] = useState(false);

    {/* Const - Event */ }
    const [eventType, setEventType] = useState(eventweddingData);
    const [isEventSidebar, setIsEventSidebar] = useState(false);

    /* Const - Section - Document Require */
    const [isSidebarDocumentRequire, setIsSidebarDocumentRequire] = useState(false);

    {/* Const - Date */ }
    const [bookingScreens, setBookingScreens] = useState(initialBookingScreens);
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);
    const handleDateEdit = () => {
        setIsBookingSidebar(true);
        setIsFromEditRequest(true)
        setBookingScreens((prevState) => ({
            ...prevState,
            dateAndTimeSlots: true,
            confirmContinueAndPay: false,
            confirmAndPay: false,
        }));

    };
    const handleDamagePrice = (price) => {
        setdamagePrice(parseInt(damagePrice) + parseInt(price));
    }
    let bookingProps = {
        isFromBooking: true,
        isFromBookingDate: {
            startDate: new Date(),
            endDate: new Date(),
        },
        isFromBookingTimeSlot: {
            id: 1,
            title: "Title",
            subTitle: "subTitle",
            time: "time",
        },
    };
    const [isFromEditRequest, setIsFromEditRequest] = useState(false);

    {/* Const - Guest */ }
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [guestCount, setGuestCount] = useState(10);

    {/* Const - User */ }
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const [isTrue, setIsTrue] = useState(false);
    const { isLoggedIn, user, socket } = useAuth();
    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com"
    });

    // useEffect(() => {
    //     if (user)
    //         setSelectedProfile({ type: "Personal", detail: `${user?.firstName} ${user?.lastName} · ${user?.phone} · ${user?.email}` })
    // }, [user])
    useEffect(() => {
        if (user)
            setSelectedProfile({
                type: "Personal",
                detail: `${user?.firstName}`,
            });
    }, [user]);
    console.log('selected profile', selectedProfile);
    const profileMainText = `${data?.profile?.businessName} . ${data?.profile?.billingZipCode} . ${data?.profile?.billingCountry}...`

    {/* Const - Room */ }
    const [isBedSidebar, setIsBedSidebar] = useState(false);
    const [bedCount, setBedCount] = useState(10);
    const [isFromRoom, setIsFromRoom] = useState(false);
    isFromRoom && (bookingProps.isFromRoom = true);

    {/* Const - Rule and condition */ }
    const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] =
        useState(false);

    {/* Const - Remaining payment */ }
    const [isRemainingSidebar, setIsRemainingSidebar] = useState(false);

    {/* Const - Damage deposit */ }
    const [isDamageDeposit, setIsDamageDeposit] = useState(false);

    {/* Const - invoice */ }
    const [isInvoiceDetail, setIsInvoiceDetail] = useState(false);

    {/* Const - Edit request list */ }
    const [isEditRequestList, setIsEditRequestList] = useState(false);

    const [selectedCard, setselectedCard] = useState();
    const [subscription, setSubscription] = useState({});
    {/* Const - Clickable */ }
    const paymentCard = (card) => {
        console.log("SELECTED PAYMENT CARD");
        console.log(card);
        setselectedCard(card);
    }

    const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);
    {/* Const - Button - Cancel booking */ }
    const handleCancelBooking = () => {
        setBookingScreens((prevState) => ({
            ...prevState,
            bookingDetail: false,
            cancelBooking: true,
        }));
    };
    const calculateInstallements = (installment) => {
        var today = new Date();
        var date_to_reply = new Date(data?.ChildTimeSlot?.startDate);
        var timeinmilisec = date_to_reply.getTime() - today.getTime();
        var totalDays = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
        var slotsround = Math.round(totalDays / 3);
        // setTotDays(totalDays);
        if (installment === 2) {
            return slotsround;
        } else {
            return slotsround * 2;
        }
    }
    const getBalancePayment = (selectBooking) => {
        const price = Math.round((selectBooking.price / 3 + Number.EPSILON) * 100) / 100;
        let two = `${price}€ in ${calculateInstallements(2)} days`;
        let three = `${price}€ in ${calculateInstallements(3)} days`;
        if (selectBooking?.subscription_payments?.length === 1) {
            return [two, three];
        } else if (selectBooking?.subscription_payments?.length === 2) {
            return [three];
        } else {
            return ["Aucun paiement restant"];
        }
    }
    const openRemainingPayment = async (selectBooking) => {
        if (selectBooking?.subscription_payments?.length !== 3) {
            // setIsRemainingLoading(true);
            console.log("OPENING REMAINING 34");
            const token = await auth.currentUser.getIdToken();
            // const subscription = await dispatch(getsubscription(selectedBooking.id));
            const subscription = await axios.get(API_ENDPOINT + "/booking/getsubscription/" + selectBooking.id,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log("OPENING REMAINING 3");
            setSubscription(subscription);
            paymentCard(subscription?.data?.message?.card);
            console.log("OPENING REMAINING 2");
            // setIsRemainingLoading(false);
            console.log("OPENING REMAINING 1");
            setIsRemainingSidebar(true);
            console.log("OPENING REMAINING");
            console.log(isRemainingSidebar);
        }
    }

    const calculatePriceList = (val) => {
        let priceList = [
            { title: "CLT", tooltipMsg: null, price: `${data?.ChildTimeSlot?.price}€` },
        ];
        if (val?.BookingRooms?.length) {
            for (let index = 0; index < val.BookingRooms.length; index++) {
                const element = val.BookingRooms[index];
                let totalPrice = element.quantity * element.price;
                priceList.push({ title: `${element.title} (${element.quantity} nuit)`, tooltipMsg: null, price: `${element.price}€` });
                // priceList.push({ title: `${element.title} (${element.quantity} night)`, tooltipMsg: null, price: `${totalPrice}€` });
            }
        }
        if (val?.BookingAdditionalItems?.length) {
            for (let index = 0; index < val.BookingAdditionalItems.length; index++) {
              const element = val.BookingAdditionalItems[index];
              priceList.push({ title: `${element.title}`, tooltipMsg: null, price: `${element.price}€` });
            }
        }
        return priceList;
    }

    const calculateTotal = (val) => {
        let total = val.price;
        if (val?.BookingAdditionalItems?.length) {
          for (let index = 0; index < val.BookingAdditionalItems.length; index++) {
            const element = val.BookingAdditionalItems[index];
            total = total + element.price;
          }
        }
        return total
    }

    const getStatusText = (status) => {
        switch (status) {
          case "Cancel":
            return "Annulé";
          case "comming":
            return "À venir";
          case "Ongoing":
            return "En cours";
          case "Finish":
            return "Terminé";
          default:
            return status; // Default to the original status if no mapping is found
        }
    };

    // ------------------------------------------------------------------------------

    // Body
    return (
        <div style={{
            display: 'none'
        }}>
            <Offcanvas
                placement="end"
                className="clt_admin_dash_booking_offcanvas"
                show={isOpenSidebarBookingAdmin}
                onHide={onCloseOpenSidebarBookingAdmin}
            >


                {/* Header */}
                <Offcanvas.Header className="justify-content-between clt_admin_dash_booking_offcanvas_header">

                    <div className="d-flex justify-content-start align-items-center">
                        <button
                            type="button"
                            className="btn-close btn-back shadow-none d-flex"
                            aria-label="Close"
                            onClick={onCloseOpenSidebarBookingAdmin}
                        >
                            <img src={BackArrow} className="" alt={IMG_ALT} />
                        </button>
                        <div className="d-flex align-items-center justify-content-between">
                            <Offcanvas.Title>
                                {getStatusText(data.status)} - #{data?.id}
                            </Offcanvas.Title>
                        </div>
                    </div>
                </Offcanvas.Header>

                {/* Body */}
                <Offcanvas.Body className="clt_mybooking_offcanvas_body2">
                    <>
                        <div className="clt_mybooking_offcanvas_body">
                            <SectionTop
                                image={ConfirmPayProfilePicture1}
                                title="Château Latournelle"
                                subTitle={`Booking for a ${data?.Event?.title}`}
                            />

                            {data.status !== 'Ongoing' && data.status !== 'Finish' && data.status !== 'Cancel' && data?.CancelBookings?.some(x => x.status === 'Sent' || x.status === 'Accept') ? (
                                <SectionRequestPending
                                    innerTitle="Demande"
                                    innerSubTitle={`Il y à ${data.CancelBookings.filter(x => x.status === 'Sent' || x.status === 'Accept').length} demande(s) en cours concernant cette réservation`}
                                    onClick={() => setIsOpenSidebarAdminBookingRequest(true)}
                                />
                            ) : null}
                        </div>
                        <CustomHr
                            rowClass="p-0 m-0"
                            colClass="clt-booking-hr-pd p-0 w-100"
                            hrClass="p-0 m-0 clt-hr-2"
                        />
                        <div className="clt_mybooking_offcanvas_body">
                            <h2 className="clt-booking-cp-div1-title">Réservation</h2>
                            <SectionEvent
                                image={data?.Event?.icon}
                                subText={data?.Event?.title}
                                event={data?.Event}
                            />
                            <SectionDate
                                image={ConfirmPay2}
                                subText1={data?.ChildTimeSlot?.startDate}
                                subText2={data?.ChildTimeSlot?.endDate}
                                isEditRequest={true}
                                // handleDateEdit={handleDateEdit}
                                selectedTimeSlot={data?.ChildTimeSlot}
                                isDateSelect={true}
                            />
                            <SectionGuest
                                image={ConfirmPay3}
                                subText={`${data?.guest} guests`}
                            />
                            {/*
                            <SectionBed
                                title="Room"
                                image={ConfirmPay5}
                                subText={"No room"}
                                roomSelected={data?.BookingRooms}
                            /*setIsBedSidebar={() => setIsBedSidebar(true)}
                            buttonText={bedCount.length > 0 ? "Edit" : "Add"} 
                            /> */}
                        </div>
                        <CustomHr
                            rowClass="p-0 m-0"
                            colClass="clt-booking-hr-pd p-0 w-100"
                            hrClass="p-0 m-0 clt-hr-2"
                        />
                        <div className="clt_mybooking_offcanvas_body">
                            <h2 className="clt-booking-cp-div1-title">Option</h2>
                            <SectionItemAdditional
                                image={img_section_info}
                                subText="Aucune option"
                                onClickSidebar={() => setIsItemAdditionalSidebar(true)}
                                isItemAdditional={isItemAdditional}
                                sectionItemAdditional={data?.BookingAdditionalItems}
                                hideButton={data.status === 'Cancel' || data.status === 'Finish'}
                            />
                        </div>
                        <CustomHr
                            rowClass="p-0 m-0"
                            colClass="clt-booking-hr-pd p-0 w-100"
                            hrClass="p-0 m-0 clt-hr-2"
                        />
                        <div className="clt_mybooking_offcanvas_body">

                            <SectionPriceDetail
                                title="Détail du prix"
                                parent="user_mybooking"
                                priceListData={calculatePriceList(data)}
                                totalPrice={calculateTotal(data)}
                            />

                        </div>
                        <CustomHr
                            rowClass="p-0 m-0"
                            colClass="clt-booking-hr-pd p-0 w-100"
                            hrClass="p-0 m-0 clt-hr-2"
                        />
                        <div className="clt_mybooking_offcanvas_body">

                            {/* <SectionProfile
                                title="Information réservation"
                                addedText='Selected Profile : '
                                handleClick={() => setIsAccountInfo(true)}
                                text={`${selectedProfile.type} profile`}
                                subText={<span>{selectedProfile.detail}</span>}
                            /> */}
                            <SectionProfile
                                title="Information réservation"
                                addedText='Profil sélectionné : '
                                handleClick={() => setIsAccountInfo(true)}
                                data={data}
                                // text={`${data.profile_type} profile`}
                                text={data?.profile_type === 'personal' ? 'Profil personnel' : ' Profil professionnel'}
                                subText={
                                    <span>
                                        {data?.profile_type === 'personal' ? data?.User.firstName : (data?.Profile !== null && data?.Profile?.businessName !== null ? data?.Profile?.businessName : 'Requis')}
                                    </span>}
                            />

                            {/* {professionalProfiles.map((profile, index) => (
                                <SectionUser key={index}
                                    handleClick={() => setIsAccountInfo(true)}
                                    text='Professional profile'
                                    title="Information réservation"
                                    subText={profile.business_name
                                        ? profile.business_name
                                        : "Business name: Required"}
                                    value={profile}
                                />

                            ))} */}
                            <SectionUser
                                handleClick={() => setIsTrue(true)}
                                text='Tous les profils'
                                subText='Voir tous les profils'
                            />



                            {/* <SectionUser
                                handleClick={() => setIsAccountInfo(true)}
                                text={`${data?.profile?.type} profile`}
                                title="Information réservation"
                                subText={
                                    <span>{profileMainText}</span>
                                }
                                value = {data?.profile}
                            /> */}


                            <SectionClickable3
                                innerTitle="Document obligatoire "
                                innerSubTitle="Document nécessaire pour une réservation"
                                onClick={() => setIsSidebarDocumentRequire(true)}
                            />
                            {
                                data?.payment_id?.startsWith("sub_") ?
                                    <SectionRemainingPayment
                                        image={IconRemainingPayment}
                                        selectedBooking={data}
                                        title={`Paiement restant: ` + `${data?.subscription_payments === null ? '0' : (3 - data?.subscription_payments?.length)}`}
                                        paymentTexts={getBalancePayment(data)}
                                        onClick={() => openRemainingPayment(data)}
                                    />
                                    : <></>
                            }

                            <SectionDamageDeposit
                                image={IconEuro}
                                subText={damagePrice + "€"}
                                handleDamageEdit={() => setIsDamageDeposit(true)}
                            />

                            <SectionClickable2
                                innerTitle="Règles et conditions"
                                innerSubTitle="Règles et conditions"
                                onClick={() => setIsSection6SiderbarDetail(true)}
                            />

                            <SectionAccess
                                handleClick={() => console.log("clicked")}
                                innerTitle="Remise des clefs"
                                innerSubTitle="Subtext"
                            />
                            <SectionClickable
                                innerTitle="Facture"
                                innerSubTitle="Afficher les factures"
                                onClick={() => setIsInvoiceDetail(true)}
                            />


                            {/*
                            <SectionClickable
                                title="Rules and conditions"
                                innerTitle="Rules and conditions"
                                innerSubTitle="Here are the rules and conditions about your booking"
                                onClick={() => setIsSection6SiderbarDetail(true)}
                            />
                            <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                            <SectionClickable
                                title="Invoice"
                                innerTitle="Invoice"
                                innerSubTitle="Find the invoices of this reservation"
                                onClick={() => setIsInvoiceDetail(true)}
                            />
                            <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" /> */}
                            {
                                data.status !== 'Ongoing' && data.status !== 'Finish' && data.status !== 'Cancel' ?
                                    <Row>
                                        <Col>
                                            <div className="clt-booking-btn-refuse-div mb-5">
                                                <Button
                                                    className="clt-booking-btn-refuse"
                                                    onClick={handleCancelBooking}
                                                >
                                                    <span>Annuler réservation</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    : null
                            }
                        </div>
                    </>
                </Offcanvas.Body>
            </Offcanvas>


            {/* ------------------------------ */}

            {/* Function - Date */}
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={(data) => {
                    setIsFromRoom(false);
                    setIsBookingSidebar(false);
                    setIsFromEditRequest(false);
                }}
                isFromBooking={true}
                isFromBookingDate={{
                    startDate: new Date(),
                    endDate: new Date(),
                }}
                isFromBookingTimeSlot={{
                    id: 1,
                    title: "Title",
                    subTitle: "subTitle",
                    time: "time",
                }}
                eventType={eventType}
                {...bookingProps}
                isFromEditRequest={isFromEditRequest}
            />

            {/* Function - Guest */}
            <Guest
                isGuestSidebar={isGuestSidebar}
                editGuestValue={guestCount}
                onCloseGuestSidebar={(guestCount) => {
                    setIsGuestSidebar(false);
                    setGuestCount(guestCount);
                }}
            />

            {/* Function - User */}
            {isAccountInfo ? <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="admin"
                // selectedProfileData={selectedProfile}
                selectedProfileData={{
                    type: data.profile_type === "professional" ? 'Professional' : 'Personal',
                    data: data.Profile ? {
                        id: data.Profile.id,
                        business_name: data.Profile.businessName,
                        phone: user.phone,
                        email: user.email,
                        head_office_address: {
                            city: data.Profile.headOfficeCity,
                            country: data.Profile.headOfficeCountry,
                            address: data.Profile.headOfficeAddress,
                            zip_code: data.Profile.headOfficeZipCode,
                            state_region: data.Profile.headOfficeState,
                        },
                        billing_address: {
                            city: data.Profile.billingCity,
                            country: data.Profile.billingCountry,
                            address: data.Profile.billingAddress,
                            zip_code: data.Profile.billingZipCode,
                            state_region: data.Profile.billingState,
                        },
                        tva_number: data.Profile.tvaNumber,
                        defrayment_note: data.Profile.defraymentNote,
                        created_at: data.Profile.createdAt,
                        updated_at: data.Profile.updatedAt,
                    } : null
                }}
                setSelectedProfileData={setSelectedProfile}
                isTrue='false'
                dataa={data}
            /> : null}
            {isTrue ? <Account
                isOpenSidebar={isTrue}
                setIsOpen={() => {
                    setIsTrue(true);
                }}
                onCloseSidebar={() => {
                    setIsTrue(false);
                }}
                selectProfile="admin"
                // selectedProfileData={selectedProfile}
                // setSelectedProfileData={setSelectedProfile}
                isTrue={isTrue}
                dataa={data}
            /> : null}

            {/* Function - Damage deposit */}
            <DamageDeposit
                isOpen={isDamageDeposit}
                bookingId={data?.id}
                setdamagePrice={handleDamagePrice}
                setIsOpen={() => setIsDamageDeposit(false)}
            />

            {/* Function - ItemAditional */}
            {   isItemAdditionalSidebar ?
                    <AdminItemAdditional
                        data={data}
                        isSidebar={isItemAdditionalSidebar}
                        onCloseSidebar={() => setIsItemAdditionalSidebar(false)}
                        sectionItemAdditionalList={data?.BookingAdditionalItems}
                        setSectionItemAdditionalList={setSectionItemAdditionalList}
                        setIsItemAdditional={setIsItemAdditional}
                    />
                :   null   
            }

            

            {/* Function - Rule and condition */}
            {(Object.keys(event)?.length > 0 && sortedsection6DetailCategory?.length > 0) && (
                <SidebarRuleAndCondition
                    isSection6SiderbarDetail={isSection6SiderbarDetail}
                    setIsSection6SiderbarDetail={() => setIsSection6SiderbarDetail(false)}
                    headerTitle="Header title"
                    category1={
                        sortedsection6DetailCategory?.length > 0
                            ? sortedsection6DetailCategory[0]?.name
                            : ""
                    }
                    column1_1={{
                        title: sortedsection6DetailCategory[0]?.rules[0]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[0]?.subTitle,
                      }}
                      column1_2={{
                        title: sortedsection6DetailCategory[0]?.rules[1]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[1]?.subTitle,
                      }}
                      column1_3={{
                        title: sortedsection6DetailCategory[0]?.rules[2]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[2]?.subTitle,
                      }}
                      column1_4={{
                        title: sortedsection6DetailCategory[0]?.rules[3]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[3]?.subTitle,
                      }}
                      column1_5={{
                        title: sortedsection6DetailCategory[0]?.rules[4]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[4]?.subTitle,
                      }}
                      column1_6={{
                        title: sortedsection6DetailCategory[0]?.rules[5]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[5]?.subTitle,
                      }}
                      column1_7={{
                        title: sortedsection6DetailCategory[0]?.rules[6]?.title,
                        subTitle: sortedsection6DetailCategory[0]?.rules[6]?.subTitle,
                      }}
        
        
                      category2={sortedsection6DetailCategory[1]?.name}
                      column2_1={{
                        title: sortedsection6DetailCategory[1]?.rules[0]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[0]?.subTitle,
                      }}
                      column2_2={{
                        title: sortedsection6DetailCategory[1]?.rules[1]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[1]?.subTitle,
                      }}
                      column2_3={{
                        title: sortedsection6DetailCategory[1]?.rules[2]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[2]?.subTitle,
                      }}
                      column2_4={{
                        title: sortedsection6DetailCategory[1]?.rules[3]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[3]?.subTitle,
                      }}
                      column2_5={{
                        title: sortedsection6DetailCategory[1]?.rules[4]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[4]?.subTitle,
                      }}
                      column2_6={{
                        title: sortedsection6DetailCategory[1]?.rules[5]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[5]?.subTitle,
                      }}
        
                      column2_7={{
                        title: sortedsection6DetailCategory[1]?.rules[6]?.title,
                        subTitle: sortedsection6DetailCategory[1]?.rules[6]?.subTitle,
                      }}
        
                      
                      category3={sortedsection6DetailCategory[2]?.name}
                      column3_1={{
                          title: sortedsection6DetailCategory[2]?.rules[0]?.title,
                          subTitle: sortedsection6DetailCategory[2]?.rules[0]?.subTitle,
                      }}
                      column3_2={{
                          title: sortedsection6DetailCategory[2]?.rules[1]?.title,
                          subTitle: sortedsection6DetailCategory[2]?.rules[1]?.subTitle,
                      }}
                      column3_3={{
                          title: sortedsection6DetailCategory[2]?.rules[2]?.title,
                          subTitle: sortedsection6DetailCategory[2]?.rules[2]?.subTitle,
                      }}
                      column3_4={{
                          title: sortedsection6DetailCategory[2]?.rules[3]?.title,
                          subTitle: sortedsection6DetailCategory[2]?.rules[3]?.subTitle,
                      }}
        
                      category4={sortedsection6DetailCategory[3]?.name}
                      column4_1={{
                          title: sortedsection6DetailCategory[3]?.rules[0]?.title,
                          subTitle: sortedsection6DetailCategory[3]?.rules[0]?.subTitle,
                      }}
                      column4_2={{
                          title: sortedsection6DetailCategory[3]?.rules[1]?.title,
                          subTitle: sortedsection6DetailCategory[3]?.rules[1]?.subTitle,
                      }}
                      column4_3={{
                          title: sortedsection6DetailCategory[3]?.rules[2]?.title,
                          subTitle: sortedsection6DetailCategory[3]?.rules[2]?.subTitle,
                      }}
                      column4_4={{
                          title: sortedsection6DetailCategory[3]?.rules[3]?.title,
                          subTitle: sortedsection6DetailCategory[3]?.rules[3]?.subTitle,
                      }}
        
                    />
            )}

            {/* Function - Receipt */}
            <InvoiceOffcanvas
                booking={data}
                isInvoiceSidebar={isInvoiceDetail}
                onCloseInvoiceSidebar={setIsInvoiceDetail}
            />

            {/* Function - Booking request - Admin */}
            {isOpenSidebarAdminBookingRequest ? <SidebarAdminBookingRequest
                data={data}
                isOpenSidebarAdminBookingRequest={isOpenSidebarAdminBookingRequest}
                onCloseOpenSidebarAdminBookingRequest={() => setIsOpenSidebarAdminBookingRequest(false)}
            /> : null}

            {/* Function - Section - Remaining Payment */}
            <RemainingPayment
                isSidebar={isRemainingSidebar}
                selectedBooking={data}
                // setSelectedBooking={setSelectedBooking}
                onCloseSidebar={() => setIsRemainingSidebar(false)}
                isPaymentCardsOpen={() => setIsPaymentSidebar(true)}
                selectedCard={selectedCard}
                subscription={subscription?.data?.message}
            />

            {/* Function - Section - Document */}
            {isSidebarDocumentRequire ? <SidebarDocumentRequire
                isSidebarDocumentRequire={isSidebarDocumentRequire}
                onCloseSidebarDocumentRequire={setIsSidebarDocumentRequire}
                booking={data}
            /> : null}

            {/* Function - Cancel booking */}
            {bookingScreens.cancelBooking ? <CancelBooking
                booking={data}
                // setBooking={setData}
                oriavailRefundamount={data.refund_data}
                isOpenSidebarCancelBooking={bookingScreens.cancelBooking}
                bookingId={data?.id}
                orirefundamount={data?.price}
                onCloseOpenSidebarCancelBooking={() => {
                    setBookingScreens((prevState) => ({
                        ...prevState,
                        bookingDetail: true,
                        cancelBooking: false,
                    }));
                }
                }
            /> : null }
        </div>
    );
};

export default SidebarBookingAdmin;
