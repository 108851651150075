// Import
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
    Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] User - Edit Request - Accept.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../../../assets/img/confirm_pay_4.png";
import ConfirmPay5 from "../../../../../../assets/img/confirm_pay_5.png";
// import ConfirmPay4 from "../../../../assets/img/admin_icon_booking_detail_3.png";
import CustomHr from "../../../../../reusable/CustomHr";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import SectionUser from "../../../../../reusable/booking/SectionUser";
import SectionAccess from "../../../../../reusable/SectionAccess";
import SectionBed from "../../../../../reusable/booking/SectionRoom";
import Bed from "../../../../bed/OptionRoom";
// import SectionDamageDeposit from "../../../reusable/booking/SectionDamageDeposit";
// import { Link } from "react-router-dom";
import Guest from "../../../../guest/Guest";
import Account from "../../../../account/Account";
import Booking from "../../../../detail/booking/booking_user";
import SectionContact from "../../../../../reusable/booking/SectionContact";
import SectionEvent from "../../../../../reusable/booking/SectionEvent";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetailPendingEditRequest.js";
import Payment from "../../../../payment/Payment";
import SectionPaymentPlan from "../../../../../reusable/booking/SectionPaymentPlan";
import SectionPaymentMethod from "../../../../../reusable/booking/SectionPaymentMethod";
import { SuccessToast } from "../../../../../reusable/Toast";
import SectionInformationMessageRequestAccept from "../../../../../reusable/SectionInformationMessageRequestAccept";
import SidebarMyBookingUser from "../../../../myBooking/user_mybooking"; 

{/* Const */}
const initialBookingScreens = {
    noBooking: true,
    bookingList: false,
    bookingDetail: false,
    cancelBooking: false,
};


const ChatSidebarEditRequestAcceptUser = ({ hideSidebar }) => {

    /* Const - Event */
    const [eventType, setEventType] = useState(eventweddingData);

    /* Const - Booking */
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);

    /* Const - Section - Date */
    const handleDateEdit = () => {
        setIsBookingSidebar(true);
    };
    let bookingProps = {
        isFromBookingDate: {
            startDate: new Date(),
            endDate: new Date(),
        },
        isFromBookingTimeSlot: {
            id: 1,
            title: "Title",
            subTitle: "subTitle",
            time: "time",
        },
    };

    /* Const - Section - Price detail*/
    const [priceDetailsSlots, setpriceDetailsSlots] = useState([
        { title: "Date change", tooltipMsg: null, price: "10€" },
    ]);

    /* Const - Section - Payment plan */
    const [selectedPlan, setSelectedPlan] = useState({});

    /* Const - Section - Payment method */
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);

    /* Const - Accept request */
    const handleAccept = () => {
        SuccessToast("The request has been paid and accept");
        hideSidebar();
    };

    /* Const - Make a new request  */
    const handleMakeNewRequest = () => {
        SuccessToast("There is the user booking, you can edit what you need");
        setIsOpenSidebarMyBookingUser(true);
    };

    /* Const - Sidebar - Booking */
    const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);

    






    return (
        <>


            {/* Sidebar - Edit request - Accept - User */}
            <div
                className="clt_message_user_chat_offcanvas"
            >


                {/* Header */}
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        [CHAT-SIDEBAR] User - Edit Request - Accept
                    </div>

                </div>

                
                {/* Body */}
                <div
                    className="clt_message_user_chat_offcanvas_body"
                >
                    <>
                        <SectionTop
                            image={ConfirmPayProfilePicture1}
                            title="CLT"
                            imageSize="large"
                            subTitle="Wedding · 25€ · #111111"
                        />
                        <CustomHr
                            colClass="clt-booking-hr-pd"
                            hrClass="p-0 m-0 clt-hr"
                        />
                        <SectionInformationMessageRequestAccept
                            innerTitle="Accept"
                            innerSubTitle="The request is accept, you can make a new one if necessary."
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <h2 className="clt-mybooking-title">Edit request - Accept</h2>
                        <SectionDate
                            image={ConfirmPay2}
                            subText1="Subtext 1"
                            subText2="Subtext 2"
                            isEditRequest={true}
                            oldSubText1="Subtext 1"
                            oldSubText2="Subtext 2"
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionPriceDetail
                            title="Price detail"
                            priceListData={priceDetailsSlots}
                            totalPrice="10€"
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                        {/* Footer */}
                        <Row>
                            <Col>

                            <div className="clt-booking-btn-refuse-div">
                                <Button
                                className="shadow-none clt-booking-btn-refuse"
                                onClick={handleMakeNewRequest}
                                >
                                <span>Make a new request</span>
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </>


                </div>
            </div>
            {/* Function - Booking : Sidebar date and Accept request*/}
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={(data) => {
                    if (data === "Notification") {
                        SuccessToast("Edited");
                        hideSidebar();
                    }
                    setIsBookingSidebar(false);
                }}
                eventType={eventType}
                setEventType={(event) => setEventType(event)}
                isFromEditRequest={true}
                {...bookingProps}
            />

            {/* Function - Sidebar - Booking - User */}
            <SidebarMyBookingUser
                isOpenSidebarMyBookingUser={isOpenSidebarMyBookingUser}
                onCloseOpenSidebarMyBookingUser={() => setIsOpenSidebarMyBookingUser(false)}
                isFromMakeNewRequest={true}
            />

            {/* Function - Section Payment method */}
            <Payment
                isPaymentSidebar={isPaymentSidebar}
                onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
                isFromBooking={true}
                getPaymentCard={(val) => setIsPaymentCardSelected(val)}
            />
            
        </>
    );
};

export default ChatSidebarEditRequestAcceptUser;
