import React, { useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import ReactCodeInput from "react-verification-code-input";
import { Col, Row, Button } from "react-bootstrap";
import { ErrorToast } from "../../../../reusable/Toast";
import "../../auth-modal.css";
import { auth } from "../../firebase";
import CustomButton from "../../../../reusable/CustomButton"; // Import the CustomButton component
import { SuccessToast } from "../../../../reusable/Toast";
import {
	RecaptchaVerifier,
	linkWithPhoneNumber,
	signInWithPhoneNumber,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";

const COUNTER = 30;

const PhoneVerification = ({ phone, handleContinue, handleSendCode }) => {
	const [code, setCode] = useState(null);
	const recaptchaRef = useRef(null);
	const handleClick = () => {
		if (code === null) {
			ErrorToast("Mauvais code", "Veuillez rectifier le code de verification.")
			return;
		}
		handleContinue(code);
		setCode("");
	};

	
	const [counter, setCounter] = useState(COUNTER);

	const counterRef = useRef(COUNTER);
	useEffect(() => {
		setCounter(COUNTER);
		counterRef.current = COUNTER
	}, []);

	const [isCompleted, setIsCompleted] = useState(false);
	
	useEffect(() => {
		const timer = setInterval(() => {
			if (counterRef.current === 0) {
				console.log(counter)
				counterRef.current = -1
				setIsCompleted(true)
				clearInterval(timer);
				return;
			}		
			if (!isCompleted) {
				console.log('counter')
				counterRef.current--;
				setCounter(c => c - 1);
			} else {
				setCounter(COUNTER);
				counterRef.current = COUNTER;
			} 

		}, 1000);
		return () => clearInterval(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCompleted]);


	const sendCodeAgain = async () => {
		if (!isCompleted) return;
		
		try {
			if (!recaptchaRef.current) {
				recaptchaRef.current = new RecaptchaVerifier(
					"recaptcha-container",
					{
						size: "invisible",
					},
					auth
				);
			}
			
			let result = null;

			if (auth.currentUser) {
				try {
					result = await linkWithPhoneNumber(
						auth.currentUser,
						phone,
						recaptchaRef.current
					);
				} catch (error) {
					if (
						error instanceof FirebaseError &&
						error.code === "auth/provider-already-linked"
					) {
						result = await signInWithPhoneNumber(auth, phone, recaptchaRef.current);
					}
				}
			} else {
				result = await signInWithPhoneNumber(auth, phone,recaptchaRef.current);
			}
			setIsCompleted(false)
			handleSendCode(result);
			SuccessToast("Nouveau code envoyé")
		} catch (error) {
			console.log(error)
		}
	};

	return (
		<div className="pfr_loginModalVerify">
			<div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
				<Row>
					<Col>
						<h2 className="pfr_loginModalH2">
							Entrez le code envoyé à {formatPhoneNumberIntl(phone)}
						</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<ReactCodeInput
							type="number"
							fields={6}
							onChange={(val) => {
								setCode(val);
							}}
							onComplete={(val) => console.log(val)}
							autoFocus={true}
							className="pfr_signUpNumberVerify"
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<div style={{ opacity: !isCompleted ? 0.6 : 1 }} className="d-flex justify-content-start clt-account-timer-msg pfr_loginForgotPassDiv">
						<CustomButton
							onClick={sendCodeAgain}
							disabled={!isCompleted}
							text={!isCompleted ? `Je n'ai pas reçu de code (0:${counter})` : `Je n'ai pas reçu de code`}
						/>
						</div>
					</Col>
				</Row>
				
			</div>
			<div className="pfr_loginModalVerifyDiv">
				<Row className="pfr_loginModalSeparatorDiv">
					<div className="pfr_loginModalSeparator" />
				</Row>
				<div id="recaptcha-container"></div>
				<div className="pfr_loginModal_footer">
					<Col>
						<Button
							className="pfr_loginModalBtn shadow-none"
							onClick={handleClick}
						>
							Continuer
						</Button>
					</Col>
				</div>
			</div>
		</div>
	);
};

export default PhoneVerification;
