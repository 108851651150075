import React from 'react'
import SectionEvent from '../../../../../../reusable/booking/SectionEvent';
import SectionGuest from '../../../../../../reusable/booking/SectionGuest';
import SectionDate from '../../../../../../reusable/booking/SectionDate';
import BackArrowRight from "../../../../../../../assets/img/back_arrow_right.png";
import IconAccept from "../../../../../../../assets/img/icon_accept.png";
import IconPending from "../../../../../../../assets/img/icon_pending.png";
import IconCancel from "../../../../../../../assets/img/icon_cancel.png";
import ConfirmPay1 from "../../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../../assets/img/confirm_pay_3.png";
import NextArrow from "../../../../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../../../../constants";
import { Button } from "react-bootstrap";
import "../../[SECTION] Admin - Booking Request.css";
// import - Sidebar - Edit request - Cancel
import SidebarEditRequestRejectAdmin from "../sidebar/[SIDEBAR] Admin - Edit Request - Reject";


const SlideEditRequestRejectAdmin = ({ key }) => {
    const [openSidebar, setOpenSidebar] = React.useState(false)
    return (
        <>
            <div key={key} onClick={()=> setOpenSidebar(true)} className="d-flex flex-column justify-content-start align-items-start clt-admin-booking-section-3-cards clt-admin-booking-section-3-cards-secondary" >
                <div className='w-100'>

                    <div className="d-flex justify-content-between align-center">

                        <div className="d-flex justify-content-center align-center">
                            <div className='clt_admin_dash_booking_avatar_wrapper_navigation_request mt-0'>
                                <div className="clt_admin_dash_booking_avatar_container_request">
                                    <div className='clt_admin_dash_booking_avatar_name_container_request'>J</div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                                <h2>Jhon doe</h2>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="back-button"
                            aria-label="Close"
                        >
                            <img src={BackArrowRight} className="back-button-img mt-2" alt={IMG_ALT} />
                        </button>
                    </div>

                    <div className="clt-booking-mb row">
                        <div className='clt_slider_edit_request_container_request_state'>
                            <img src={IconCancel} style={{ width: "16px" }} alt={IMG_ALT} />
                            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                                <h2>Request - Cancel </h2>
                                <p>Il y à 2 j</p>
                            </div>
                        </div>
                    </div>
                    <SectionEvent
                        image={ConfirmPay1}
                        subText="Wedding"
                        buttonText="Edit"
                    />
                    <SectionDate
                        image={ConfirmPay2}
                        subText1="Subtext 1"
                        subText2="Subtext 2"
                        oldSubText1="Subtext 1"
                        oldSubText2="Subtext 2"
                        isCancelRequest={true}
                    />
                </div>
            </div>
            <SidebarEditRequestRejectAdmin
                isOpenSidebarEditRequestRejectAdmin={openSidebar}
                onCloseOpenSidebarEditRequestRejectAdmin={()=> setOpenSidebar(false)}

            />
        </>
    )
}

export default SlideEditRequestRejectAdmin;