import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import "../account.css";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../constants";
import { ErrorToast } from "../../../reusable/Toast";
const AccountInputText = ({
  isSidebar,
  onCloseSidebar,
  title,
  inputData,
  onUpdateData,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState(inputData);
  const [validationError, setValidationError] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const handleInputChange = (event) => {
    inputData.value = event.target.value;
    setInputValue((prevState) => ({
      ...prevState,
      value: event.target.value,
    }));
  };
  const checkLength = (value) => !value || value.length !== 13;

  const handleSave = () => {
    if (inputData.name === 'tvaNumber' && checkLength(inputData.value)) {
      ErrorToast("Numéro de tva incomplet", "Le numéro de tva doit commencer par 'FR' suivi de 11 chiffres.");
      setValidationError(true);
    } else {
      onUpdateData({ name: inputData.name, value: inputData.value });
      setInputValue({});
      onCloseSidebar();
    }
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  useEffect(()=>{
    setValidationError(false);
  },[isSidebar,inputData.name])
  return (
    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isSidebar}
      onHide={onCloseSidebar}
    >
      <Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onCloseSidebar}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className={`clt_payment_offcanvas_body`}>
        <div className="">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="pfr_inputFloatDiv">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  name={inputData.name}
                  type={inputData.type}
                  placeholder={inputData.placeholder}
                  value={inputData.value}
                  onChange={(event) => handleInputChange(event)}
                  style={{
                    borderColor: validationError && checkLength(inputData.value) ? '#d32f2f' : '',
                    backgroundColor: focusedField !== 'check' && validationError && checkLength(inputData.value) ? '#f2e4e4' : '',
                  }}
                  onFocus={() => handleFocus('check')}
                  onBlur={handleBlur}
                />
                <label htmlFor="floatingInput1"
                  style={{
                    color:validationError && checkLength(inputData.value) && focusedField !== 'check'? '#d32f2f' : '',
                  }}
                >{inputData.placeholder}</label>
              </Form.Floating>
            </Col>
          </Row>
          <div className="pfr_payment_space_bottom" />
        </div>
        <div className="clt_payment_footer">
          <Button
            className="shadow-none clt_payment_header_button_black"
            disabled={inputData.value === "" ? true : false}
            onClick={handleSave}
          >
            Enregistrer
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AccountInputText;
