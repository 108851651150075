import React, { useContext, useState } from "react";
import { Col, Row, Button, Offcanvas } from "react-bootstrap";
import "../auth-modal.css";
import "react-phone-number-input/style.css";
import BackArrow from "../../../../assets/img/back_arrow.png";

import { API_ENDPOINT, IMG_ALT } from "../../../../constants";
import PhoneSignin from "./steps/PhoneSignin";
import PhoneVerification from "./steps/PhoneVerification";
import EmailSignin from "./steps/EmailSigin";
import PasswordSigin from "./steps/PasswordSignin";
import { Actions, AuthContext, useAuth } from "../authContext";
import {
	FacebookAuthProvider,
	GoogleAuthProvider,
	linkWithCredential,
	signInWithEmailAndPassword,
	signInWithPopup,
	unlink,
} from "firebase/auth";
import { auth } from "../firebase";
import axios, { AxiosError } from "axios";
import ForgotPassword from "../ForgotPassword";
import { ErrorToast, InfoToast, SuccessToast } from "../../../reusable/Toast";
import { FirebaseError } from "firebase/app";
import "../auth-modal.css";
import { Helmet } from "react-helmet";
const loginTypes = {
	PASSWORD: "password",
	PHONE: "phone",
	PHONE_VERIFY: "phone-verify",
	PROVIDER_VERIFY: "provider-verify",
	EMAIL: "email",
};

const AuthSignin = (props) => {
	const { toggleAuthModal } = props;
	const [isLoginBy, setIsLoginBy] = useState(props.authenticationFlow && props.authenticationFlow.userId ? loginTypes.PASSWORD : loginTypes.PHONE);
	const [verifier, setVerifier] = useState(null);
	const [credentials, setCredentials] = useState(null);
	const [showForgotPassword, setShowForgotPassword] = useState(false);






	const { dispatch, email, phone, googleId, facebookId } = useAuth();

	const changeBackButton = (prps) => {
		if (isLoginBy === "phone") toggleAuthModal();
		else if (isLoginBy === "phone-verify") setIsLoginBy("phone");
		else if (isLoginBy === "password") setIsLoginBy("email");
		else if (isLoginBy === "email") setIsLoginBy("phone");
	};

	const phoneSiginHandler = (phone, verifier) => {
		setVerifier(verifier);
		dispatch({ type: Actions.SET_DATA, payload: { phone } })
		setIsLoginBy(loginTypes.PHONE_VERIFY);
	};

	const phoneVerificationHandler = async (code) => {
		try {
			if (verifier === null) return;
			await verifier.confirm(code);
			debugger;
			const user = auth.currentUser;
			const token = await user.getIdToken();
			const { data } = await axios.post(
				API_ENDPOINT + "/auth/login",
				{
					phone: user.phoneNumber,
					email: user.email,
					uid: user.uid,
				},
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			localStorage.setItem("userToken", token);
			localStorage.setItem("name", data.firstName + " " + data.lastName);
			dispatch({ type: Actions.USER_LOGGED_IN, payload: user });
			toggleAuthModal();
			localStorage.setItem('user-login-in', true)

		} catch (error) {
			auth.signOut();
			localStorage.removeItem('selectedUserId');

			localStorage.removeItem('user-login-in')

			ErrorToast(error.message);
		}
	};

	const emailSigninHandler = (email) => {
		dispatch({ type: Actions.SET_DATA, payload: { email } });
		setIsLoginBy(loginTypes.PASSWORD);
	};




	const passwordSigninHandler = async (email, password) => {
		try {
			const { user } = await signInWithEmailAndPassword(auth, email, password);
			await signInUser(user);
			SuccessToast("Connecté", "Bienvenue, vous êtes maintenant connecté à votre compte.")
		} catch (error) {
			auth.signOut();
			localStorage.removeItem('selectedUserId');

			localStorage.removeItem('user-login-in')

			console.log(error.message);
			if (error.message === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).') {
				ErrorToast("Trop de tentative de connexion", "L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Vous pouvez le restaurer immédiatement en réinitialisant votre mot de passe ou vous pouvez essayer plus tard. ");
				return
			}
			if (error?.message === 'Firebase: Error (auth/wrong-password).') {
				ErrorToast("Mot de passe incorrect", "Veuillez saisir à nouveau votre mot de passe");
				return

			} else {
				// do nothing

			}
			console.log(error.message);
		}
	};

	async function signInUser(user) {
		try {
			const token = await user.getIdToken();
			console.log("Sigin User ", { token, user });
			const { data: loginData } = await axios.post(
				API_ENDPOINT + "/auth/login",
				{
					phone: user.phoneNumber,
					email: user.email,
					uid: user.uid,
				},
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			dispatch({ type: Actions.USER_LOGGED_IN, payload: loginData.data });
			toggleAuthModal();
			localStorage.setItem('user-login-in', true)


		} catch (error) {
			if (error instanceof AxiosError) {
				await auth.signOut()
				localStorage.removeItem('selectedUserId');

				localStorage.removeItem('user-login-in')

				setIsLoginBy(loginTypes.PHONE)
			}
			throw error;
		}
	}

	const getUserInfoFromGoogle = async (accessToken) => {
		const googleUrl = new URL("https://www.googleapis.com/oauth2/v1/userinfo");
		googleUrl.searchParams.set("access_token", accessToken);
		const googleUserInfo = await axios.get(googleUrl);
		return googleUserInfo.data;
	};

	const getUserInfoFromFacebook = async (id, accessToken) => {
		const fields = ["email", "first_name", "picture", "last_name", "name"];
		const fbUrl = new URL(`https://graph.facebook.com/${id}`);
		fbUrl.searchParams.set("fields", fields.join(","));
		fbUrl.searchParams.set("access_token", accessToken);
		const { data } = await axios.get(fbUrl);
		return data;
	};

	const checkUserOnServer = async (email) => {
		const { data } = await axios.post(API_ENDPOINT + "/auth/check", {
			email,
		});
		return data;
	};

	// eslint-disable-next-line no-unused-vars
	const ConnectWithGoogle = async () => {
		try {
			const provider = new GoogleAuthProvider();
			provider.addScope("https://www.googleapis.com/auth/userinfo.email");
			provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
			provider.addScope("openid");
			const result = await signInWithPopup(auth, provider);
			const credentials = GoogleAuthProvider.credentialFromResult(result);
			const userInfo = await getUserInfoFromGoogle(credentials?.accessToken);
			const data = await checkUserOnServer(userInfo.email);
			if (!data.userExists) {
				dispatch({
					type: Actions.SET_DATA,
					payload: {
						email: userInfo.email,
						firstName: userInfo.given_name,
						lastName: userInfo.family_name,
						googleId: userInfo.id,
					},
				});
				props.createAccount();
				SuccessToast("Bienvenue", "Commençons la création de votre compte.")
				return;
			}
			if (!data.user.googleId || data.user.googleId === null) {
				dispatch({
					type: Actions.SET_DATA,
					payload: { email: userInfo.email, googleId: userInfo.id },
				});
				await result.user.delete();
				setCredentials(credentials);
				setIsLoginBy(loginTypes.PROVIDER_VERIFY);
				InfoToast(
					"Connectez-vous avec votre compte Google"
				);
				return;
			}
			await signInUser(result.user);
			SuccessToast("Connecté", "Bienvenue, vous êtes maintenant connecté à votre compte.")
		} catch (error) {
			// ErrorToast(error.message);
		}
	};

	const ConnectWithFacebook = async () => {
		try {
			const provider = new FacebookAuthProvider();
			const result = await signInWithPopup(auth, provider);
			const credentials = FacebookAuthProvider.credentialFromResult(result);
			const facebookId = result.user.providerData.find(
				(provider) => provider.providerId === "facebook.com"
			).uid;
			const userInfo = await getUserInfoFromFacebook(
				facebookId,
				credentials.accessToken
			);

			const data = await checkUserOnServer(userInfo.email);
			if (!data.userExists) {
				dispatch({
					type: Actions.SET_DATA,
					payload: {
						email: userInfo.email,
						firstName: userInfo.first_name,
						lastName: userInfo.last_name,
						facebookId,
					},
				});
				props.createAccount();
				SuccessToast("Bienvenue", "Commençons la création de votre compte.")
				return;
			}
			if (!data.user.facebookId || data.user.facebookId === null) {
				dispatch({
					type: Actions.SET_DATA,
					payload: { facebookId, email: userInfo.email },
				});
				await result.user.delete();
				setCredentials(credentials);
				setIsLoginBy(loginTypes.PROVIDER_VERIFY);
				InfoToast(
					"Looks like your account has been registered already. Authenticate yourself to connect with facebook."
				);
				return;
			}
			await signInUser(result.user);
			SuccessToast("Connecté", "Bienvenue, vous êtes maintenant connecté à votre compte.")
		} catch (error) {
			ErrorToast(error.message);
		}
	};

	const providerVerifyHandler = async (email, password) => {
		try {
			var { user } = await signInWithEmailAndPassword(auth, email, password);
		} catch (error) {
			if (error instanceof FirebaseError) {
				ErrorToast(error.code);
			}
			return;
		}

		if (!credentials) {
			setIsLoginBy(loginTypes.PHONE);
			ErrorToast("Veuillez effectuer d'abbord une sélection ");
		}

		const token = await user.getIdToken();

		try {
			var [{ user: updatedUser }, response] = await Promise.all([
				linkWithCredential(user, credentials),
				axios.patch(
					API_ENDPOINT + "/auth/connect-social",
					{
						googleId: googleId ?? undefined,
						facebookId: facebookId ?? undefined,
					},
					{ headers: { Authorization: `Bearer ${token}` } }
				),
			]);
			SuccessToast(response.data.message);
		} catch (error) {
			unlink(auth.currentUser, credentials.providerId);
			ErrorToast(error.message);
			return;
		}
		await signInUser(updatedUser);
		SuccessToast("Connecté", "Bienvenue, vous êtes maintenant connecté à votre compte.")
	};

	const canvasSteps = {
		[loginTypes.PHONE_VERIFY]: {
			title: "Verification code",
			element: <PhoneVerification phone={phone} handleContinue={phoneVerificationHandler} setVerifier={setVerifier} />,
		},
		[loginTypes.PASSWORD]: {
			title: "Connexion par email",
			element: (
				<PasswordSigin
					authenticationFlow={props.authenticationFlow}
					handleContinue={passwordSigninHandler}
					goToForgotPass={() => setShowForgotPassword(true)}
				/>
			),
		},
		[loginTypes.EMAIL]: {
			title: "Connexion / Créer un compte",
			element: (
				<EmailSignin
					createAccount={props.createAccount}
					handleContinue={emailSigninHandler}
				/>
			),
		},
		[loginTypes.PHONE]: {
			title: "Connexion / Créer un compte",
			element: (
				<PhoneSignin
					createAccount={props.createAccount}
					handleContinue={phoneSiginHandler}
				/>
			),
		},
		[loginTypes.PROVIDER_VERIFY]: {
			title: "Authenticate Your Credentials",
			element: <PasswordSigin handleContinue={providerVerifyHandler} />,
		},
	};

	return !showForgotPassword ? (
		<>
			<Helmet>
				<title>Login</title>
				<meta name="title" content="Login or register" />
				<meta property="og:title" content="Login or register" />
				<meta name="description" content="Redirect to home page" />
			</Helmet>
			<Offcanvas.Header className="justify-content-start clt_auth_user_offcanvas_header">
				<button
					type="button"
					onClick={() => changeBackButton(props)}
					className={`btn-close btn-back shadow-none d-flex ${props.variant === "second" && (isLoginBy === "phone" || isLoginBy === "email") ? "d-none" : "d-flex"}`}
					aria-label="Close"
				>
					<img src={BackArrow} className="" alt={IMG_ALT} />
				</button>
				<Offcanvas.Title>{canvasSteps[isLoginBy].title}</Offcanvas.Title>
			</Offcanvas.Header>

			{/* <Offcanvas.Body className="clt_auth_user_offcanvas_body"> */}
			<Offcanvas.Body className="clt_auth_user_offcanvas_body variant_2nd">
				{isLoginBy in canvasSteps ? canvasSteps[isLoginBy].element : <></>}
				{isLoginBy === "phone" || isLoginBy === "email" ? (
					<div className="pfr_loginModal_Body">
						<Row>
							<Col className="pfr_loginModalSeparatorDiv">
								<div className="pfr_loginModalSeparator" />
								<span>OR</span>
								<div className="pfr_loginModalSeparator" />
							</Col>
						</Row>
						<Row>
							<Col className="pfr_loginModalBottomBtnCol">
								{isLoginBy === "phone" && (
									<Row>
										<Col>
											<Button
												className="pfr_loginModalIconBtn shadow-none"
												onClick={() => setIsLoginBy(loginTypes.EMAIL)}
											>
												<img
													src={
														require("../../../../assets/img/icon_email.png")}
													className=""
													alt={IMG_ALT}
												/>
												<span>Continuer avec adresse e-mail</span>
											</Button>
										</Col>
									</Row>
								)}
								{isLoginBy === "email" && (
									<Row>
										<Col>
											<Button
												className="pfr_loginModalIconBtn shadow-none"
												onClick={() => setIsLoginBy(loginTypes.PHONE)}
											>
												<img
													src={
														require("../../../../assets/img/icon_phone.png")

													}
													className=""
													alt={IMG_ALT}
												/>
												<span>Continuer avec téléphone</span>
											</Button>
										</Col>
									</Row>
								)}
								<Row>
									<Col>
										<Button
											onClick={() => ConnectWithGoogle()}
											className="pfr_loginModalIconBtn shadow-none"
										>
											<img
												src={
													require("../../../../assets/img/icon_google.png")

												}
												className=""
												alt={IMG_ALT}
											/>
											<span>Continuer avec Google</span>
										</Button>
									</Col>
								</Row>
								{/*
								<Row>
									<Col>
										<Button
											onClick={() => ConnectWithFacebook()}
											className="pfr_loginModalIconBtn shadow-none"
										>
											<img
												src={
													require("../../../../assets/img/icon_fb.png")
												}
												className=""
												alt={IMG_ALT}
											/>
											<span>Continue with Facebook</span>
										</Button>
									</Col>
								</Row> */}
							</Col>
						</Row>
					</div>
				) : (
					""
				)}
			</Offcanvas.Body>
		</>
	) : (
		<ForgotPassword
			{...props}
			isForgotPassword={showForgotPassword}
			forgotPass={() => setShowForgotPassword((p) => !p)}
		/>
	);
};

export default AuthSignin;
