import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import "./[CHAT-COMPONENT] Image view.css";
import PhotoSwipeLightbox from "photoswipe/lightbox"; // Correction pour le chemin du module Photoswipe Lightbox
import "photoswipe/style.css";
import CustomCarousel from '../../../../../reusable/CustomCarousel';

const ChatComponentImageView = ({ images, IMG_ALT }) => {

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const openGallery = async (gallery, index) => {
        const lightboxGallery = await Promise.all(
            gallery.map(async (item) => {
                const img = new Image();
                img.src = item.src;
                await img.decode();
                return {
                    src: img.src,
                    width: windowSize?.innerWidth,
                    height: (img.height / img.width) * windowSize?.innerWidth,
                };
            })
        );

        const options = {
            gallery: '#gallery--with-custom-button',
            children: 'a',
            dataSource: lightboxGallery,
            pswpModule: () => import("photoswipe"), // Correction pour le chemin du module Photoswipe
        };

        const lightbox = new PhotoSwipeLightbox(options);

        lightbox.on('uiRegister', function() {
            lightbox.pswp.ui.registerElement({
                name: 'test-button',
                ariaLabel: 'Toggle zoom',
                order: 9,
                isButton: true,
                html: 'Test',
                onClick: (event, el) => {
                    if (window.confirm('Do you want to toggle zoom?')) { // Correction pour la fonction confirm
                        lightbox.pswp.toggleZoom();
                    }
                }
            });
        });

        lightbox.init();
        lightbox.loadAndOpen(index);
    };

    return (
        <div className='clt_messages_container_images_container'>
            {images.map((image, index) => {
                return (
                    <div key={index} className="clt_messages_container_images_container_preview">
                        <img onClick={() => openGallery(images, index)} src={image.src} alt={IMG_ALT} style={{ borderRadius: "10px", objectFit: "cover" }} />
                    </div>
                );
            })}
        </div>
    )
}

export default ChatComponentImageView;

function getWindowSize() {
    const { innerHeight, innerWidth } = window;
    return { innerHeight, innerWidth };
}
