// Import
import React, { useEffect, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { RangeDatePicker } from "react-google-flight-datepicker";
import { Link } from "react-router-dom";
import { IMG_ALT } from "../../../../../constants";
import CustomHr from "../../../../reusable/CustomHr";
import "./admin_dash_booking_add.css";
import { isEqual } from "lodash";
import moment from "moment";

// Import - JPG PNG
import BackArrow from "../../../../../assets/img/back_arrow.png";
import ConfirmPay2 from "../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay5 from "../../../../../assets/img/confirm_pay_5.png";
import ConfirmPayProfilePicture1 from "../../../../../assets/img/confirm_pay_profil_picture_1.png";

// Import - Section
import InvoiceOffcanvas from "../../../../reusable/invoice/SidebarInvoiceList";
import AuthModal from "../../../auth/AuthModal";
import Bed from "../../../bed/OptionRoom";
import Guest from "../../../guest/Guest";
import Payment from "../../../payment/Payment";
import SectionDate from "../../../../reusable/booking/SectionDate";
import SectionEvent from "../../../../reusable/booking/SectionEvent";
import SectionGuest from "../../../../reusable/booking/SectionGuest";
import SectionPaymentPlanAdmin from "../../../../reusable/booking/SectionPaymentPlanAdmin";
import SectionPriceDetail from "../../../../reusable/booking/SectionPriceDetail";
import SectionBed from "../../../../reusable/booking/SectionRoom";
import SectionTop from "../../../../reusable/booking/SectionTop";
import SectionUserSearch from "../../../../reusable/search/Search - Seach a user or invite/section/[SECTION] Admin - Search or Invite a user";
import { useAuth } from "../../../auth/authContext";
import SidebarRuleAndCondition from "../../../../reusable/booking/SidebarRuleAndCondition";
import Event from "../../../event/Event";
// import SidebarRuleAndCondition from "../../../../../../sections/SidebarRuleAndCondition";
import AdminAmountPaid from "../admin_dash_booking_component/component_amount_paid/component_amount_paid";
import { userlist } from "../../../../reusable/search/Search - API/[API] Seach a user or invite.js";


// --------------------------------------------------------------------

// Const
const isEmail = (input) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(input)
}
const initialBookingScreens = {
    dateAndTimeSlots: false,
    AddBooking: true,
    guestCounter: false,
    bedCounter: false,
    confirmBooking: false,
};
const initialReserveDate = {
    startDate: null,
    endDate: null,
};

const AddAdminBooking = ({
    openBookingSidebar,
    onCloseBookingSidebar,
    eventType,
    isFromBooking,
    isFromBookingDate,
    isFromBookingTimeSlot,
    setEventType,
    isFromContactForm,
    isFromContactFormDateTime,
}) => {


    const [users, setUsers] = useState([]);
    const [bookingScreens, setBookingScreens] = useState(initialBookingScreens);
    const [isReserveDate, setIsReserveDate] = useState(
        isFromBookingDate ? isFromBookingDate : initialReserveDate
    );
    const [isReserveTimeSlots, setIsReserveTimeSlots] = useState([
        { id: 1, title: "Title", subTitle: "subTitle", time: "time" },
        { id: 2, title: "Title", subTitle: "subTitle", time: "time" },
    ]);

    const [sectionAmountList, setSectionAmountList] = useState([
        { id: "1", title: "Credit Card", isActive: true, amount: null },
        { id: "2", title: "Bank Check", isActive: true, amount: null },
    ]);


    const [isReserveSelectedTimeSlot, setIsReserveSelectedTimeSlot] = useState(
        isFromBookingTimeSlot ? isFromBookingTimeSlot : null
    );

    const { isLoggedIn, user } = useAuth();

    const [selectedProfile, setSelectedProfile] = useState({
        type: "",
        detail: "",
        name: "",
    });

    const [isPaidSidebar, setIsPaidSidebar] = useState(false);
    const [isAmountPaid, setIsAmountPaid] = useState(false);

    const [isEventSidebar, setIsEventSidebar] = useState(false);
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [isBedSidebar, setIsBedSidebar] = useState(false);
    const [guestCount, setGuestCount] = useState(0);
    const [bedCount, setBedCount] = useState([]);
    const [userToken, setUserToken] = useState(null);
    const [isLoginModal, setIsLoginModal] = useState(false);
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] =
        useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [isInvoiceDetail, setIsInvoiceDetail] = useState(false);

    const searchHandler = async (input) => {
        try {
            const response = await userlist({ search: input });
            const getUsers = response.data?.data.map((user) => {
                return {
                    name: `${user.first_name} ${user.last_name}`,
                    type: "user",
                    detail: `${user.phone} ${user.phone ? " - " : ""} ${user.email}`
                }
            }) || []
            if (getUsers.length === 0 && input.length > 0 && isEmail(input)) getUsers.push({ name: input, type: "email", detail: "" })
            setUsers(getUsers);
        } catch (error) {
            console.log(error);
        }
    };

    const handleBack = (from, to) => {
        setBookingScreens((prevState) => ({
            ...prevState,
            [from]: !prevState[from],
            [to]: !prevState[to],
        }));
    };

    const handleDateEdit = () => {
        setBookingScreens((prevState) => ({
            ...prevState,
            dateAndTimeSlots: true,
            confirmAndPay: false,
        }));
    };

    const handleSelectDataTimeSlot = () => {
        if (isFromContactForm && isFromContactForm) {
            isFromContactFormDateTime(
                `${moment(isReserveDate.startDate).format(
                    "DD/MM/YY"
                )} - 23:59 -> ${moment(isReserveDate.endDate).format(
                    "DD/MM/YY"
                )} - 23:59`
            );
            onCloseBookingSidebar();
        } else {
            setBookingScreens((prevState) => ({
                ...prevState,
                dateAndTimeSlots: false,
                confirmAndPay: true,
            }));
        }
    };

    const handlePay = () => {
        setBookingScreens((prevState) => ({
            ...prevState,
            confirmAndPay: false,
            confirmBooking: true,
        }));
    };


    const toggleAuthModal = () => {
        setIsLoginModal((prevState) => !prevState);
        setUserToken("null");
    };

    return (
        <>
            <Offcanvas
                placement="end"
                className="clt_products_offcanvas"
                show={openBookingSidebar}
                onHide={onCloseBookingSidebar}
            >


                {/* Header */}
                <Offcanvas.Header className="justify-content-start clt_products_offcanvas_header">

                    {/* Header - Add a booking */}
                    {bookingScreens.AddBooking && (
                        <>
                            <button
                                type="button"
                                className="btn-close btn-back shadow-none d-flex"
                                aria-label="Close"
                                onClick={onCloseBookingSidebar}
                            >
                                <img src={BackArrow} className="" alt={IMG_ALT} />
                            </button>
                            <Offcanvas.Title>Add a booking</Offcanvas.Title>
                        </>
                    )}

                    {/* Header - Select timeslot */}
                    {bookingScreens.dateAndTimeSlots && (
                        <React.Fragment>
                            <button
                                type="button"
                                className="btn-close btn-back shadow-none d-flex"
                                aria-label="Close"
                                onClick={() => handleBack("confirmAndPay", "dateAndTimeSlots")}
                            >
                                <img src={BackArrow} className="" alt={IMG_ALT} />
                            </button>
                            <Offcanvas.Title>Select a timeslot</Offcanvas.Title>
                        </React.Fragment>
                    )}

                </Offcanvas.Header>



                {/* Body */}
                <Offcanvas.Body className="clt_products_offcanvas_body clt-admin-booking-datepicker-sidebar">

                    {/* Body - Add a booking */}
                    {bookingScreens.AddBooking && (
                        <>
                            <SectionUserSearch
                                title="Select a user"
                                text="Find a user or invite someone by entering his email"
                                usersData={users}
                                searchHandler={searchHandler}
                            />
                            <CustomHr colClass="clt-admin-booking-admin-booking-hr-pd" hrClass="p-0 m-0 clt-admin-booking-hr" />
                            <h2 className="clt-admin-booking-admin-booking-cp-div1-title">Booking of the user</h2>
                            <SectionEvent
                                image={eventType.iconActive}
                                subText={eventType.title}
                                setIsEventSidebar={() => setIsEventSidebar(true)}
                                buttonText="Edit"
                            />
                            <SectionDate
                                image={ConfirmPay2}
                                handleDateEdit={handleDateEdit}
                                subText1="Subtext 1"
                                subText2={"Subtext 2"}
                                isEditRequest={true}
                            />
                            <SectionGuest
                                image={ConfirmPay3}
                                subText={
                                    guestCount ? `${guestCount} guests` : "Add the guest number"
                                }
                                setIsGuestSidebar={() => setIsGuestSidebar(true)}
                                buttonText={guestCount ? "Edit" : "Add"}
                            />
                            <CustomHr colClass="clt-admin-booking-admin-booking-hr-pd" hrClass="p-0 m-0 clt-admin-booking-hr" />
                            <h2 className="clt-admin-booking-admin-booking-cp-div1-title">Optional</h2>
                            <SectionBed
                                title="Room"
                                image={ConfirmPay5}
                                subText={"Add room"}
                                roomSelected={bedCount}
                                setIsBedSidebar={() => setIsBedSidebar(true)}
                                buttonText={bedCount.length > 0 ? "Edit" : "Add"}
                            />
                            <CustomHr colClass="clt-admin-booking-admin-booking-hr-pd" hrClass="p-0 m-0 clt-admin-booking-hr" />
                            <SectionPaymentPlanAdmin
                                title="Select your payment plan"
                                oneTimePayment="15€"
                                selectedPlan={selectedPlan}
                                setSelectedPlan={(plan) => setSelectedPlan(plan)}
                                moreInfo="More information"
                                onClick={() => setIsPaidSidebar(true)}
                                isAmountPaid={isAmountPaid}
                                sectionAmountList={sectionAmountList}
                            />
                            <CustomHr colClass="clt-admin-booking-admin-booking-hr-pd" hrClass="p-0 m-0 clt-admin-booking-hr" />
                            <SectionPriceDetail
                                title="Price detail"
                                priceListData={[
                                    { title: "CLT", tooltipMsg: null, price: "15€" },
                                    { title: "Room 1 (1 night)", tooltipMsg: null, price: "10€" },
                                    {
                                        title: "Deposit",
                                        tooltipMsg:
                                            "Deposit: An inventory will be made before and after your reservation. A deposit may be automatically deducted for damages incurred. You will be notified before any automatic debit.",
                                        price:
                                            "You will be charged automatically in case of damage.",
                                    },
                                ]}
                                totalPrice="25€"

                            />
                            <CustomHr colClass="clt-admin-booking-admin-booking-hr-pd" hrClass="p-0 m-0 clt-admin-booking-hr" />
                            <Row>
                                <Col>
                                    <div className="clt-admin-booking-admin-booking-cp-div7-btn">
                                        <Button className="shadow-none" onClick={handlePay}>
                                            <span>Send request</span>
                                        </Button>
                                    </div>
                                    <p className="clt-admin-booking-admin-booking-cp-div7-p">
                                        En réservant, vous acceptez les Conditions vous acceptez les
                                        Conditions{" "}
                                        <Link to="#" className="">
                                            générales de ventes
                                        </Link>
                                    </p>
                                </Col>
                            </Row>

                        </>
                    )}

                    {/* Body - Select timeslot */}
                    {bookingScreens.dateAndTimeSlots && (
                        <>
                            <Row>
                                <Col>
                                    <h2 className="clt-admin-booking-datepicker-sidebar-h2">Date</h2>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="clt-admin-booking-custom-datepicker">
                                    <RangeDatePicker
                                        startDate={isReserveDate.startDate}
                                        endDate={isReserveDate.endDate}
                                        onChange={(startDate, endDate) => (
                                            setIsReserveSelectedTimeSlot(null),
                                            setIsReserveDate({
                                                startDate: startDate,
                                                endDate: endDate,
                                            })
                                        )}
                                        minDate={new Date()}
                                        monthFormat="MMM YYYY"
                                        highlightToday={true}
                                        startDatePlaceholder="Start Date"
                                        endDatePlaceholder="End Date"
                                        disabled={false}
                                        className=""
                                        startWeekDay="monday"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h2 className="clt-admin-booking-datepicker-sidebar-h3">Time slot</h2>
                                </Col>
                            </Row>
                            {isReserveDate.startDate && isReserveDate.endDate ? (
                                <>
                                    {isReserveTimeSlots.map((value, index) => {
                                        return (
                                            <Row key={index}>
                                                <Col>
                                                    <div
                                                        className="d-flex flex-row align-items-center justify-content-between clt-admin-booking-datepicker-sidebar-timeslot-mainDiv"
                                                        onClick={() => setIsReserveSelectedTimeSlot(value)}
                                                        style={
                                                            isEqual(isReserveSelectedTimeSlot, value)
                                                                ? {
                                                                    borderColor: `#000`,
                                                                    background: "#f6f6f6",
                                                                }
                                                                : {
                                                                    borderColor: `#ddd`,
                                                                    background: "#fff",
                                                                }
                                                        }
                                                    >
                                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                                                            <h2>{value.title}</h2>
                                                            <h3>{value.subTitle}</h3>
                                                            <span className="d-flex justify-content-start align-items-center">
                                                                <i
                                                                    className="fa fa-clock-o"
                                                                    aria-hidden="true"
                                                                />{" "}
                                                                {value.time}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="inner-div-circle"
                                                            style={
                                                                isEqual(isReserveSelectedTimeSlot, value)
                                                                    ? {
                                                                        borderColor: `#000`,
                                                                    }
                                                                    : {
                                                                        borderColor: `#ddd`,
                                                                    }
                                                            }
                                                        >
                                                            <div
                                                                className="subDiv"
                                                                style={
                                                                    isEqual(isReserveSelectedTimeSlot, value)
                                                                        ? {
                                                                            background: `#000`,
                                                                        }
                                                                        : {
                                                                            background: `transparent`,
                                                                        }
                                                                }
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </>
                            ) : (
                                <Row>
                                    <Col>
                                        <div className="clt-admin-booking-datepicker-sidebar-info">
                                            <i className="fa fa-info-circle" aria-hidden="true" />
                                            <span>
                                                Select the dates of your event to see the available
                                                slots.
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row className="clt-admin-booking-datepicker-sidebar-footer-space" />
                        </>
                    )}


                </Offcanvas.Body>

            </Offcanvas>


            {/* Function */}


            {/* Function - Auth */}
            <AuthModal
                toggleAuthModal={toggleAuthModal}
                isLoginModal={isLoginModal}
            />

            {/* <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="select"
                setSelectedProfileData={setSelectedProfile}
            /> */}

            <Payment
                isPaymentSidebar={isPaymentSidebar}
                onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
                isFromBooking={true}
                getPaymentCard={(val) => setIsPaymentCardSelected(val)}
            />

            <Guest
                isGuestSidebar={isGuestSidebar}
                onCloseGuestSidebar={(guestCount) => {
                    setIsGuestSidebar(false);
                    setGuestCount(guestCount);
                }}
            />

            <Bed
                isBedSidebar={isBedSidebar}
                onCloseBedSidebar={() => setIsBedSidebar(false)}
                onConfirm={(val) => setBedCount(val)}
            />

            <Event
                show={isEventSidebar}
                onHide={() => setIsEventSidebar(false)}
                eventType={eventType}
                onConfirmEvent={(event) => {
                    if (event !== eventType) {
                        setBookingScreens(initialBookingScreens);
                    }
                    setEventType(event);
                }}
            />
            <AdminAmountPaid
                isSidebar={isPaidSidebar}
                onCloseSidebar={() => setIsPaidSidebar(false)}
                sectionAmountList={sectionAmountList}
                setSectionAmountList={setSectionAmountList}
                setIsAmountPaid={setIsAmountPaid}
            />
        </>
    );
};

export default AddAdminBooking;
