import { API_ENDPOINT } from "../../constants";
import { actionTypes } from "../constants/action-type";
import { auth } from "../../components/screens/auth/firebase";


export const createTimeSlot = (data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/timeslot`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while adding room' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while adding room' });
      }
    });
  }
}

export const getTimeSlotById = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/timeslot/${id}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while fetching timeslot' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while fetching timeslot' });
      }
    })
  }
}

export const updateTimeSlot = (data, id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/timeslot/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject()),
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while adding room' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while adding room' });
      }
    });
  }
}

export const DeleteTimeSlot = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/timeslot/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject()),
          }
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while adding room' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while adding room' });
      }
    });
  }
}

export const getTimeSlots = () => {
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/timeslot`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const result = await response.json()
      if (response.status >= 200 && response.status < 400) {
        dispatch({ type: actionTypes.ALLTIMESLOTS, payload: result })
      }
      else {
        dispatch({ type: actionTypes.ALLTIMESLOTSERR, payload: result })
      }
    } catch (err) {
      console.log('error')
    }
  }
}

export const getEventsTimeSlots = () => {
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/events/timeslots/all`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          // ...( await getAuthObject())
        },
      });
      const result = await response.json()
      if (response.status >= 200 && response.status < 400) {
        dispatch({ type: actionTypes.EVENTSTIMESLOTS, payload: result })
      }
      else {
        dispatch({ type: actionTypes.EVENTSTIMESLOTSERR, payload: result })
      }
    } catch (err) {
      console.log('error')
    }
  }
}

export const getTimeSlotsByDate = (data) => {
  console.log('getTimeSlotsByDate', data);
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/timeslot/range`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          // ...(await getAuthObject()),
        },
        body: (data)
      });
      const result = await response.json();
      console.log('This is date range', result)
      if (response.status >= 200 && response.status < 400) {
        // result.sort((a, b) => {
        //   const dateA = new Date(a.startDate);
        //   const dateB = new Date(b.startDate);
        //   return dateA - dateB;
        // });
        dispatch({ type: actionTypes.FORMATEDSLOTS, payload: result })
      }
      else {
        dispatch({ type: actionTypes.FORMATEDSLOTSERR, payload: result })
      }
    } catch (err) {
      console.log('error')
      console.log(err);
    }
  }
}

async function getAuthObject() {
  const token = await auth.currentUser.getIdToken();

  return { Authorization: `Bearer ${token}` }
}


export const createRoomTimeSlot = (data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/timeslot/selectRoom`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject()),
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while adding room' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while adding room' });
      }
    });
  }
}

export const updateRoomTimeSlot = (data, id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/timeslot/selectRoom/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject()),
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while adding room' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while adding room' });
      }
    });
  }
}

export const getSlotRooms = (id) => {
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/timeslot/rooms/${id}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const result = await response.json()
      if (response.status >= 200 && response.status < 400) {
        dispatch({ type: actionTypes.SLOTROOMS, payload: result?.selectedrooms })
      }
      else {
        dispatch({ type: actionTypes.SLOTROOMSERR, payload: result })
      }
    } catch (err) {
      console.log('error')
    }
  }
}
