import React, { useContext, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IMG_ALT, IMG_BASEURL } from "../../constants";
import IconBar1Active from "../../assets/img/icon-bar-1-active.png";
import IconBar2Active from "../../assets/img/icon-bar-2-active.png";
import IconBar3Active from "../../assets/img/icon-bar-3-active.png";
import IconBar4Active from "../../assets/img/icon-bar-4-active.png";
import { useDispatch } from "react-redux";
import { getEvent } from "../../redux/actions/avent";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../screens/auth/authContext";

import Slider from "react-slick";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        color: "red",
        border: "1px solid rgb(0 0 0/0.3)",
        borderRadius: "50%",
        backgroundColor: "white",
      }}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  if(props?.currentSlide  === 0) return <div></div>
  else {
    return (
      <div
      className={className}
      style={{
        ...style,
        color: "red",
        border: "1px solid rgb(0 0 0/0.3)",
        borderRadius: "50%",
        backgroundColor: "white",
      }}
      onClick={onClick}
      >
      <NavigateBeforeIcon />
    </div>
  );
}
}

const EventsNavbar = (props) => {

  const {
    setAllEvents,
    setSelectedDate,
    setSelectedPrice,
    setSelectedTimeSlot,
    setBookingScreens,
    guestCount,
    setGuestCount,
    guestCountInput,
    setGuestCountInput,
    setSelectedProfile,
    setIsPaymentCardSelected,
    setSelectedPlan,
    setBedCount,
    setSelectTotalRooms,
    selectedRoomData,
    setSelectedRoomData,
  } = useContext(AuthContext);

  // Move the initialization of sortedEvents and defaultActiveEvent here
  const isHomePage = props.isHomePage;
  const sortedEvents = props?.myAllEvents?.sort((a, b) => a.eventType - b.eventType);
  const defaultActiveEvent = sortedEvents ? sortedEvents[0] : null;
  

  const selectedEventIndex =
    sortedEvents?.findIndex((event) => event.title === props.currenEVENT) ?? 0;

  const settings = {
    className: "slider variable-width",
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    ...(isHomePage ? {} : { initialSlide: selectedEventIndex }),
  };

  useEffect(() => {
    setAllEvents(props.myAllEvents);
  }, [props.myAllEvents, setAllEvents]);

  
  const isStick = props.isStick;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleGetEvent = (event) => {
    dispatch(getEvent(event.id));
    if (props.parent === "details") {
      history.push(`/detail/${event.title}`);
      window.scrollTo(0, 0);
    }
  };

  console.log("sortedEvents", sortedEvents?.length)

  // const handleBack = (to) => {
  //   setBookingScreens((prevState) => ({
  //     [to]: !prevState[to],
  //   }));
  // };

  return (
    <>
      <Container
        fluid
        className={isHomePage && !isStick ? "clt-homepage-container2" : ""}
      >
        <Row
          className={
            isHomePage
              ? "clt-section-2-row-home stickyBar"
              : "clt-section-2-row stickyBar"
          }
        >
          <Col className="clt-section-nav-col" style={{ paddingLeft: '0px' }}>
            <Container className="clt-section-2-row-container">
              <Row>
                <Col
                  className={
                    isHomePage
                      ? "clt-section-2-row-col-home-sticked"
                      : "clt-section-2-row-col"
                  }
                >
                  <div style={{ width: "100%" }} className="d-none d-md-flex justify-content-start align-items-center clt-section-2">
                    <Slider {...settings}>
                      {sortedEvents?.map((event, index) => {
                        if (!event.isActive) {
                          return null;
                        }
                        return (
                          <MyComponent key={index}>
                            <div
                              className={`d-flex flex-column justify-content-center align-items-center clt-section-2-divs ${
                                isHomePage
                                  ? "d-flex flex-column justify-content-center align-items-center clt-section-2-divs-secondary"
                                  : ""
                              } ${
                                defaultActiveEvent.eventType ===
                                  event.eventType && "active"
                              }`}
                              onClick={() => {
                                setSelectedDate({
                                  start: null,
                                  end: null,
                                });
                                setSelectedPrice(null);
                                setSelectedTimeSlot({});
                                handleGetEvent(event);
                                //do handler
                                setGuestCount(0);
                                setGuestCountInput(0);
                                // window.location.reload();
                                setSelectedProfile({
                                  type: "",
                                  detail: "",
                                });
                                setBedCount([]);
                                setSelectedPlan({});
                                setIsPaymentCardSelected({});
                                setSelectedRoomData([]);
                                setBookingScreens((prevState) => ({
                                  ...prevState,
                                  bookingdateAndTimeSlots: true,
                                  bookingconfirmAndPay: false,
                                }));
                              }}
                            >
                              <img
                                src={`${event.icon}`}
                                style={
                                  props.currenEVENT === event?.title
                                    ? { filter: "grayscale(0)" }
                                    : { filter: "grayscale(1)" }
                                }
                                className=""
                                alt={IMG_ALT}
                              />
                              <h2
                                style={
                                  props.currenEVENT === event?.title
                                    ? {
                                        color: `${event.color}`,
                                        borderBottom: `2.4px solid ${event.color}`,
                                      }
                                    : {
                                        color: `#8c8c8c`,
                                        borderBottom: "2.4px solid transparent",
                                      }
                                }
                              >
                                {event?.title}
                              </h2>
                            </div>
                          </MyComponent>
                        );
                      })}
                    </Slider>
                  </div>
                  <div style={{ width: "100%" }} className="d-flex d-md-none justify-content-start align-items-center clt-section-2">
                      <Slider {...settings}>
                      {sortedEvents?.map((event, index) => {
                        if (!event.isActive) {
                          return null;
                        }
                        return (
                          <MyComponent key={index}>
                            
                            <div
                              className={`d-flex flex-column justify-content-center align-items-center clt-section-2-divs ${
                                isHomePage
                                  ? "d-flex flex-column justify-content-center align-items-center clt-section-2-divs-secondary"
                                  : ""
                              } ${
                                defaultActiveEvent.eventType ===
                                  event.eventType && "active"
                              }`}
                              onClick={() => {
                                setSelectedDate({
                                  start: null,
                                  end: null,
                                });
                                setSelectedPrice(null);
                                setSelectedTimeSlot({});
                                handleGetEvent(event);
                                //do handler
                                setGuestCount(0);
                                setGuestCountInput(0);
                                // window.location.reload();
                                setSelectedProfile({
                                  type: "",
                                  detail: "",
                                });
                                setBedCount([]);
                                setSelectedPlan({});
                                setIsPaymentCardSelected({});
                                setSelectedRoomData([]);
                                setBookingScreens((prevState) => ({
                                  ...prevState,
                                  bookingdateAndTimeSlots: true,
                                  bookingconfirmAndPay: false,
                                }));
                              }}
                            >
                              <img
                                src={`${event.icon}`}
                                style={
                                  props.currenEVENT === event?.title
                                    ? { filter: "grayscale(0)" }
                                    : { filter: "grayscale(1)" }
                                }
                                className=""
                                alt={IMG_ALT}
                              />
                              <h2
                                style={
                                  props.currenEVENT === event?.title
                                    ? {
                                        color: `${event.color}`,
                                        borderBottom: `2.4px solid ${event.color}`,
                                      }
                                    : {
                                        color: `#8c8c8c`,
                                        borderBottom: "2.4px solid transparent",
                                      }
                                }
                              >
                                {event?.title}
                              </h2>
                            </div>
                          </MyComponent>
                        );
                      })}
                      </Slider>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EventsNavbar;

const MyComponent = ({ children }) => {
  const comRef = useRef();
  return (
    <div
      ref={comRef}
      className="ababab"
      style={{
        width: `${comRef?.current?.offsetWidth}px`,
        minWidth: "100px",
        maxWidth: "fit-content",
      }}
    >
      {children}
    </div>
  );
};
