
import React from "react";
import { Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../../constants";
import DetailSection5_Icon1 from "../../../../assets/img/detail_section_5_icon_1.png";
import DetailSection5_Icon2 from "../../../../assets/img/detail_section_5_icon_2.png";
import DetailSection5_Icon3 from "../../../../assets/img/detail_section_5_icon_3.png";
import DetailSection5_Icon4 from "../../../../assets/img/detail_section_5_icon_4.png";
import DetailSection5_Icon5 from "../../../../assets/img/detail_section_5_icon_5.png";
import DetailSection5_Icon6 from "../../../../assets/img/detail_section_5_icon_6.png";
import DetailSection5_Icon7 from "../../../../assets/img/detail_section_5_icon_7.png";
import DetailSection5_Icon8 from "../../../../assets/img/detail_section_5_icon_8.png";
import DetailSection5_Icon9 from "../../../../assets/img/detail_section_5_icon_9.png";
import DetailSection5_Icon10 from "../../../../assets/img/detail_section_5_icon_10.png";
import DetailSection5_Icon11 from "../../../../assets/img/detail_section_5_icon_11.png";
import DetailSection5_Icon12 from "../../../../assets/img/detail_section_5_icon_12.png";
import DetailSection5_Icon13 from "../../../../assets/img/detail_section_5_icon_13.png";
import DetailSection5_Icon14 from "../../../../assets/img/detail_section_5_icon_14.png";
import DetailSection5_Icon15 from "../../../../assets/img/detail_section_5_icon_15.png";
import DetailSection5_Icon16 from "../../../../assets/img/detail_section_5_icon_16.png";
import DetailSection5_Icon17 from "../../../../assets/img/detail_section_5_icon_17.png";
import DetailSection5_Icon18 from "../../../../assets/img/detail_section_5_icon_18.png";
import DetailSection5_Icon20 from "../../../../assets/img/detail_section_5_icon_20.png";
import DetailSection5_Icon21 from "../../../../assets/img/detail_section_5_icon_21.png";
import DetailSection5_Icon22 from "../../../../assets/img/detail_section_5_icon_22.png";
import DetailSection5_Icon23 from "../../../../assets/img/detail_section_5_icon_23.png";
import DetailSection5_Icon24 from "../../../../assets/img/detail_section_5_icon_24.png";
import DetailSection5_Icon25 from "../../../../assets/img/detail_section_5_icon_25.png";

const DetailSection5 = (props) => {
  return (
    <Row>
      <Col className="clt-detail-left-section-1">
        <h2 className="clt-detail-left-section-1-heading">
          {props.headTitleSection5}
        </h2>
        <div className="clt-detail-left-section-subdiv-1">
          {/* First section */}
          <div className="clt-detail-left-sub-section-div-main">
            <div className="clt-detail-left-sub-section-div-p">
              <p>INSTALLATION</p>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon20} alt={IMG_ALT} />
              <h3>Tente nomade</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon3} alt={IMG_ALT} />
              <h3>Accès PMR</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon25} alt={IMG_ALT} />
              <h3>Parking (x50)
              </h3>
            </div>
          </div>

          {/* Second section */}
          <div className="clt-detail-left-sub-section-div-main">
            <div className="clt-detail-left-sub-section-div-p">
              <p>MATERIEL</p>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon8} alt={IMG_ALT} />
              <h3>Wifi</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon5} alt={IMG_ALT} />
              <h3>Vidéo projecteur</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon23} alt={IMG_ALT} />
              <h3>Chauffage / Climatisation</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon24} alt={IMG_ALT} />
              <h3>Réfrigérateur</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon22} alt={IMG_ALT} />
              <h3>Frigo</h3>
            </div>
          </div>


          {/* Second section */}
          <div className="clt-detail-left-sub-section-div-main">
            <div className="clt-detail-left-sub-section-div-p">
              <p>DORMIR</p>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon15} alt={IMG_ALT} />
              <h3>Lit simple (x13)
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon14} alt={IMG_ALT} />
              <h3>Lit double (x10)
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon21} alt={IMG_ALT} />
              <h3>Linge de lit</h3>
            </div>
          </div>


          {/* Second section */}
          <div className="clt-detail-left-sub-section-div-main">
            <div className="clt-detail-left-sub-section-div-p">
              <p>MOBILIER</p>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon7} alt={IMG_ALT} />
              <h3>Chaise (x150)
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon1} alt={IMG_ALT} />
              <h3>Table ronde (x20)
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon18} alt={IMG_ALT} />
              <h3>Table demi-rond (x2)
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon2} alt={IMG_ALT} />
              <h3>Table rectangle (x10)

              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon16} alt={IMG_ALT} />
              <h3>Table carré (x3)

              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon17} alt={IMG_ALT} />
              <h3>Table mange debout (x10)

              </h3>
            </div>
          </div>



          {/* Second section */}
          <div className="clt-detail-left-sub-section-div-main">
            <div className="clt-detail-left-sub-section-div-p">
              <p>SÉCURITÉ</p>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon10} alt={IMG_ALT} />
              <h3>Caméra de surveillance
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon9} alt={IMG_ALT} />
              <h3>Extincteur
              </h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon13} alt={IMG_ALT} />
              <h3>Issue de secours</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon12} alt={IMG_ALT} />
              <h3>Alarme incendie</h3>
            </div>
            <div className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div">
              <img src={DetailSection5_Icon11} alt={IMG_ALT} />
              <h3>Détecteur de fumée</h3>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};


export default DetailSection5;

{/* import React from "react";
import { Col, Row } from "react-bootstrap";
import { IMG_ALT, IMG_BASEURL } from "../../../../constants";
import DetailSection5_Icon7 from "../../../../assets/img/detail_section_5_icon_7.png";

const DetailSection5 = (props) => {
  return (
    <Row>
      <Col className="clt-detail-left-section-1">
        <h2 className="clt-detail-left-section-1-heading">
          {props.headTitleSection5}
        </h2>
        <div className="clt-detail-left-section-subdiv-1">
          {props.sectionTextList?.sort((a, b) => a.order - b.order).map((value, index) => {
            return (
              <div key={index} className="clt-detail-left-sub-section-div-main">
                <div className="clt-detail-left-sub-section-div-p">
                  <p>{value.title}</p>
                </div>
                {value.amenity?.sort((a, b) => a.order - b.order).map((subVal, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-start align-items-center clt-detail-left-sub-section-div"
                    >
                      <img  src={`${subVal?.image}`} alt={DetailSection5_Icon7} />
                      <h3>{subVal.title}</h3>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}; */}

