// import
import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import BackArrow from "../../../../../assets/img/back_arrow.png";
import { IMG_ALT, IMG_BASEURL } from "../../../../../constants";


/* Const */
const ManageName = ({
  isSidebar,
  onCloseSidebar,
  name,
  inputData,
  onUpdateData,
}) => {

  /* Const - Input value*/
  const [inputValue, setInputValue] = useState(inputData.name);
  const handleInputChange = (val) => {
    setInputValue((prevState) => ({
      ...prevState,
      name: val,
    }));
  };

  /* Const - Save*/
  const handleSave = () => {
    onUpdateData({
      id: inputData.id,
      image: inputData?.ImageUrl,
      name: inputValue,
    });
    setInputValue({});
    onCloseSidebar();
  };


  /* Screen */
  return (
    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isSidebar}
      onHide={onCloseSidebar}
    >

      {/* Screen - Header */}
      <Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onCloseSidebar}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>{name}</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>



      {/* Screen - Body */}
      <Offcanvas.Body className={`clt_payment_offcanvas_body`}>
        <>

          {/* Section - Image */}
          <Row>
            <Col>
              <div
                className="clt-admin-event-gallery-div clt-admin-height-imgdiv"
                style={{
                  backgroundImage: `url(${IMG_BASEURL}${inputData?.ImageUrl})`,
                }}
              ></div>
            </Col>
          </Row>

          {/* Section - Input : Name */}
          <div className="">
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pfr_inputFloatDiv"
              >
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="name"
                    value = {inputValue?.name}
                    type="text"
                    placeholder="Name"
                    defaultValue={inputData?.name}
                    onChange={(event) => setInputValue(event.target.value)}
                  />
                  <label htmlFor="floatingInput1">Name</label>
                </Form.Floating>
                
              </Col>
            </Row>
            <div className="pfr_payment_space_bottom" />
          </div>

          {/* Section - Button - Save */}
          <div className="clt_payment_footer">
            <Button
              className="shadow-none clt_payment_header_button_black"
              disabled={
                inputValue === "" || !inputValue ? true : false
              }
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ManageName;
