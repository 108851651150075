// Import
import React, { useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { IMG_ALT, eventweddingData } from "../../../../constants";
import SectionTop from "../../../reusable/booking/SectionTop";
import ConfirmPayProfilePicture1 from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../assets/img/confirm_pay_4.png";
import MessageIcon from "../../../../assets/img/chat_icon.png";

import CustomHr from "../../../reusable/CustomHr";
import SectionInformationMessageRequestCancel from "../../../reusable/SectionInformationMessageRequestCancel";

import { SuccessToast } from "../../../reusable/Toast";

import SectionContactRequestDate from "../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestMessage from "../../../reusable/contactrequest/SectionContactRequestMessage";
import SectionContactRequestEvent from "../../../reusable/contactrequest/SectionContactRequestEvent";

/* Const */
const SidebarContactRequestUserCancel = ({ data, isOpenSidebarContactRequestUserCancel, onCloseOpenSidebarContactRequestUserCancel, setIsOpenSidebarContactRequestUserCancel, isFromMakeNewRequest }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(eventweddingData);

  /* Const - Booking */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);

  /* Const - Section - Date */
  const handleDateEdit = () => {
    setIsBookingSidebar(true);
  };
  let bookingProps = {
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - Guest  */


  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Section - Payment plan */
  const [selectedPlan, setSelectedPlan] = useState({});

  /* Const - Cancel request */
  const handleRefuse = () => {
    SuccessToast("La demande a été annulé");
    onCloseOpenSidebarContactRequestUserCancel(true);
  };
  let editrequestProps = {
  };

  /* Const - Make a new request  */
  const handleMakeNewRequest = () => {
    SuccessToast("There is the user booking, you can edit what you need");
    setIsOpenSidebarMyBookingUser(true);
  };

  /* Const - Section - Date - Make a new request - Open Sidebar Booking */
  const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);

  

  



  {/* Sidebar - User - Edit request - Sent */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarContactRequestUserCancel}
        onHide={onCloseOpenSidebarContactRequestUserCancel}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarContactRequestUserCancel}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>[SIDEBAR] User - Contact request - Cancel</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          {
            data && Object.keys(data).length ?
              <>
                <div className="clt_mybooking_offcanvas_body">

                  <h2 className="clt-mybooking-title">Contact request</h2>
                  <SectionInformationMessageRequestCancel
                    innerTitle="Annuler"
                    innerSubTitle="La demande a été annulée"
                  />

                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <h2 className="clt-mybooking-title">Request detail</h2>
                  <SectionTop
                    image={ConfirmPayProfilePicture1}
                    title="Place"
                    subTitle="CLT"
                  />
                  <SectionContactRequestEvent
                      image={ConfirmPay1}
                      subText={`${data.ContactRequest.Event.title}`}
                      IsEventButtonDropdown={false} 
                      // buttonText="Edit"
                  />

                  <SectionContactRequestDate
                      image={ConfirmPay2}
                      subText={`${data.ContactRequest.month} ${data.ContactRequest.year}`}
                      IsDateButtonDropdown={false} 
                      // isDateSelect={true}
                      // buttonText="Edit"
                  />

                  <SectionContactRequestGuest
                      image={ConfirmPay3}
                      subText={`${data.ContactRequest.guest_number}`}
                      IsGuestButtonDropdown={false} 
                      // subText={`${guestCount} guests`}
                      // buttonText="Edit"
                  />

                  <SectionContactRequestMessage
                      image={MessageIcon}
                      subText={`${data.ContactRequest.message}`}
                  />
                </div>
              </> 
            : null
          }
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SidebarContactRequestUserCancel;
