import { isEqual } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";

const SectionPaymentPlan = ({
    title,
    selectedPlan,
    setSelectedPlan,
    oneTimePayment,
    onClick,
    isAmountPaid,
    sectionAmountList
}) => {


    return (
        <Row>
            <Col>
                {title && <h2 className="clt-booking-cp-div6-title mb-3">Select a payment plan</h2>}

                <Row >
                    <Col>
                        <div
                            className={`d-flex mt-0 flex-row align-items-start justify-content-start gap-3 border-0 clt-paymentplan-select-mainDiv`}
                            onClick={() => setSelectedPlan("One Time")}
                        >
                            <div
                                className="inner-div-circle"
                                style={{ borderColor: selectedPlan === "One Time" ? `#000` : `#ddd` }}
                            >
                                <div
                                    className="subDiv"
                                    style={{ background: selectedPlan === "One Time" ? `#000` : `transparent` }}
                                ></div>
                            </div>
                            <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                                <h2>Pay in one time</h2>
                                <p className="clt-payment-plan-p" >
                                    {oneTimePayment}
                                </p>

                            </div>

                        </div>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <div
                            className={`d-flex mt-0 flex-row align-items-start justify-content-start gap-3 border-0 clt-paymentplan-select-mainDiv`}
                            onClick={() => setSelectedPlan("All Time")}
                        >
                            <div
                                className="inner-div-circle"
                                style={{ borderColor: selectedPlan === "All Time" ? `#000` : `#ddd` }}
                            >
                                <div
                                    className="subDiv"
                                    style={{ background: selectedPlan === "All Time" ? `#000` : `transparent` }}
                                ></div>
                            </div>
                            <div className="w-100 d-flex flex-column align-items-start justify-content-center inner-div-text">
                                <h2>User has already paid part or all</h2>
                                {/* <p className="clt-payment-plan-p" >
                                    {oneTimePayment}
                                </p> */}
                                {selectedPlan === "All Time" && (
                                    <div className="mt-2 w-100 d-flex justify-content-between align-items-center clt-booking-cp-div4-inner"
                                        onClick={onClick}
                                    >
                                        <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div4-text">
                                            <h2>Amount Paid</h2>
                                            {isAmountPaid ?
                                                <>
                                                    {sectionAmountList.filter(item => item.amount !== null).map((item, index) =>
                                                        <p key={index}>{`Payment ${index + 1}: ${item.title}(${item.amount}€)`}</p>
                                                    )}

                                                </>
                                                :
                                                <p>{`Enter amount(s)`}</p>
                                            }
                                        </div>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </div>
                                )}
                            </div>

                        </div>
                    </Col>
                </Row>


            </Col>
        </Row>
    );
};

export default SectionPaymentPlan;
