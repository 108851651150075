import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "../account.css";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../constants";
import { Link } from "react-router-dom";
  
const AccountInputProfilePicture = ({
  isSidebar,
  onCloseSidebar,
  title,
  inputData,
  onUpdateData,
  onDeleteProfilePic
}) => {
  const [inputValue, setInputValue] = useState(inputData);
  const [image, setImage] = useState(inputData.value ?? '')
  // const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    setImage(inputData.value)
  }, [inputData])

  const handleClickUpload = (e) => {
    inputValue.value = null;
    setInputValue((prevState) => ({
      ...prevState,
      value: null,
    }));
    setImage('')
  };
  const handleInputChange = (val) => {
    setInputValue((prevState) => ({
      ...prevState,
      value: val,
    }));
    setImage(URL.createObjectURL(val))

  };
  const handleSave = () => {
    if (inputData.value === inputValue.value) {
      onCloseSidebar()
      return;
    };

    // if(isDelete) {
    //   onDeleteProfilePic()
    //   return
    // }

    onUpdateData({ name: inputData.name, value: inputValue.value });   
    setInputValue(prevData => ({  ...prevData, value: null }))
  };
  const CustomToggleImage = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
    </Link>
  ));

  const handleImageDelete = () => {
    inputValue.value = null;
    setInputValue((prevState) => ({
      ...prevState,
      value: null,
    }));
    // setIsDelete(true)
    onDeleteProfilePic()
    setImage('')
  }

  return (
    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isSidebar}
      onHide={onCloseSidebar}
    >
      <Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onCloseSidebar}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className={`clt_payment_offcanvas_body`}>
        <>
          <Row>
            {image ? (
              <Col>
                <div
                  className="clt-admin-event-gallery-div clt-admin-height-imgdiv"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                >
                  <div
                    className="d-flex align-items-center justify-content-end pfr_payment_cards3dots clt-admin-cards3dots"
                    style={{ width: "10%" }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggleImage} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu"
                      >
                        <Dropdown.Item
                          onClick={handleClickUpload}
                          className="pfr_payment_cards3dots_options"
                        >
                          Changer photo
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={handleImageDelete}
                          className="pfr_payment_cards3dots_options"
                        >
                          Supprimer
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            ) : (
              <Col>
                <div className="clt-admin-event-fileinput-div">
                  <i className="fa-solid fa-plus"></i>
                  <span>
                    {inputValue.value
                      ? inputValue.value
                      : "Ajouter une photo de profil"}
                  </span>
                  <div className="opacity-0 div-hidden">
                    <input
                      type="file"
                      name={inputValue.name}
                      onChange={(e) =>
                        handleInputChange(e.target.files[0])
                      }
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <div className="clt_payment_footer">
            <Button
              className="shadow-none clt_payment_header_button_black"
              disabled={
                // (inputValue.value === "" || !inputValue.value ? true : false) && !isDelete
                inputValue.value === "" || !inputValue.value ? true : false
              }
              onClick={handleSave}
            >
              Enregistrer
            </Button>
          </div>
        </>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AccountInputProfilePicture;
