import React from 'react'
import "./SidebarMessageContinueConfirm&Pay.css"
import { Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../assets/img/info_icon.png";
import { IMG_ALT } from "../../constants";

const ConfirmPay = ({ onClick }) => {
    return (
        <>
            <div className="h-75">
                <Row className="h-100">
                    <Col className="h-100">
                        <div className="clt-continue-confirm-pay d-flex flex-column justify-content-center align-items-center">
                            <img src={BackArrow} alt={IMG_ALT} />
                            <h2>Are you sure</h2>
                            <p>Secondary text</p>
                        </div>
                    </Col>
                </Row>
                <div className="clt-continue-confirm-pay-space_bottom" />
            </div>

            <div className="clt-continue-confirm-pay-btn">
                <Button
                    className="shadow-none"
                    onClick={onClick}
                >
                    Continuer
                </Button>
            </div>
        </>
    )
}

export default ConfirmPay