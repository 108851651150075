import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";

import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import CustomHr from "../../../../../reusable/CustomHr";
import { getAllArticles } from "../api";
import { ErrorToast } from "../../../../../reusable/Toast";

const headingStyle = {
  fontSize: "18px",
  color: "#000",
};
const rowStyle = {
  marginTop: "-25px",
};

function CreatePath({ isOpen, onClose, selectedCategory, createPath }) {
  const [path, setPath] = useState("");
  const [article, setArticle] = useState(null);
  const [allArticles, setAllArticles] = useState([]);

  const getArticles = async (id) => {
    const res = await getAllArticles(id);
    if (res.success) {
      setAllArticles(Object.values(res.data));
    } else {
      ErrorToast("Unable to fetch articles");
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      getArticles(selectedCategory.id);
    }
  }, [selectedCategory]);
  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      backdrop={false}
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Create path</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col className="clt-admin-event-info-input-div">
            <h2 class="clt_admin_dash_menu_container_name" style={headingStyle}>
              Path name
            </h2>
            <Form.Floating className="pfr_inputFloat">
              <Form.Control
                id="pathName"
                type="text"
                placeholder="Path name"
                value={path}
                onChange={(e) => setPath(e.target.value)}
              />
              <label htmlFor="pathName">Path name</label>
            </Form.Floating>
          </Col>
        </Row>
        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
        <Row style={rowStyle}>
          <Col className="clt-admin-event-info-input-div">
            <h2 class="clt_admin_dash_menu_container_name" style={headingStyle}>
              Help article (optional)
            </h2>
            <p>Select an article, the path will stop here.</p>
            <Form.Floating className="pfr_inputFloat">
              <Form.Select
                id="article"
                type="select"
                placeholder="Article"
                value={article}
                onChange={(e) => setArticle(e.target.value)}
              >
                <option key={0} value={null}>
                  -
                </option>
                {allArticles.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              <label htmlFor="article">Article</label>
            </Form.Floating>
          </Col>
        </Row>

        <div className="clt_payment_footer">
          <Button
            className="shadow-none clt_payment_header_button_black"
            disabled={path === ""}
            onClick={() => {
              createPath({
                path: path,
                article: article && article !== "-" ? article : null,
              });
            }}
          >
            Save
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CreatePath;
