import { API_ENDPOINT } from "../../constants";
import { actionTypes } from "../constants/action-type";
import { auth } from "../../components/screens/auth/firebase";

export const createRoom = (data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject()),
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while adding room' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while adding room' });
      }
    });
  }
}

export const getRooms = () => {
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/room`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const result = await response.json()
      if (response.status >= 200 && response.status < 400) {
        dispatch({ type: actionTypes.ALLROOMS, payload: result })
      }
      else {
        dispatch({ type: actionTypes.ALLEVENTSERR, payload: result })
      }
    } catch (err) {
      console.log('error')
    }
  }
}

export const getRoom = (id) => {
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/room/${id}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const result = await response.json()
      console.log('result***', result.data)
      if (response.status >= 200 && response.status < 400) {
        dispatch({ type: actionTypes.ROOM, payload: result.data })
      }
      else {
        dispatch({ type: actionTypes.ROOMERR, payload: result })
      }
    } catch (err) {
      console.log('error')
    }
  }
}

export const EditRoomInfo = (roomId, formData) => {
  console.log('id and data is', roomId, formData)
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room/${roomId}`, {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            // ...( await getAuthObject())
          },
          body: (formData)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const EditRoomInfoDrag = (id, data) => {
  console.log('id and data is', id, data)
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject())
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const deleteRoom = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject())
          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const AddRoomGalleryImage = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room/galleries/${id}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',

          },
          body: (data)
        });
        const result = await response.json()
        console.log('result @@@@@', result)
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while uploading the image.' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while uploading the image.' });
      }
    });
  }
}

export const editRoomGallery = (room, id, data) => {
  return async function (dispatch) {
    try {
      const response = await fetch(`${API_ENDPOINT}/room/${room.id}/galleries/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          // ...( await getAuthObject())

        },
        body: (data)
      });
      const result = await response.json()
    } catch (err) {
      console.log('error')
    }
  }
}

export const deleteGalleryItem = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room/galleries/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject())

          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const EditEventGalleryOrder = (room, id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/room/${room.id}/galleries/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            // ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}
