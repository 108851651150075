import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "../invoice/SidebarInvoiceList.css";
import BackArrow from "../../../assets/img/back_arrow.png";
import InvoiceIcon from "../../../assets/img/icon_invoice.png";
import {API_ENDPOINT, IMG_ALT} from "../../../constants";
import { Link } from "react-router-dom";
import {auth} from "../../screens/auth/firebase";
import axios from "axios";
import { SuccessToast, ErrorToast } from "../Toast";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const SidebarDocumentRequire = ({
    isSidebarDocumentRequire,
    onCloseSidebarDocumentRequire,
    booking
}) => {

    const [isInvoiceCardSelected, setIsInvoiceCardSelected] = useState(isSidebarDocumentRequire);
    const [docTypeSelect, setDocTypeSelect] = useState(null);
    const inputFileRef = React.useRef();
    
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            className="clt_reuseable_invoice_card3dot"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </Link>
    ));

    const handleCloseSidebar = () => {
        onCloseSidebarDocumentRequire()
    }

    const handleUploadFile = (type) => {
        setDocTypeSelect(type);
        inputFileRef.current.click();
    }

    const handleDeleteDoc = async (type) => {
        try {
            const token = await auth.currentUser.getIdToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` },
                data: { type: type, bookingId: booking?.id }
            }
            const response = await axios.delete(API_ENDPOINT + "/document", config);
            ErrorToast("Document supprimé", "Le document a été supprimé");
        } catch (error) {
            ErrorToast("Une erreur est survenue", "Veuillez essayer de supprimer votre document à nouveau");
            console.log('location Error in delet document: ', error);
        }  
    }


    const changeImages = async (e) => {
        for (let i = 0; i < e.target.files?.length; i++) {
          imageUploading(e.target.files[i]);
        }
    }
    
    const imageUploading = async (image) => {

        let data = new FormData();
        data.append('file', image);
        data.append('bookingId', booking.id);
        data.append('type', docTypeSelect);

        try {
            ErrorToast("En cours d'importation", "Veuillez patienter, le document est en cours d'importation");
            const token = await auth.currentUser.getIdToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            const response = await axios.post(API_ENDPOINT + "/document", data, config);
            SuccessToast('Document ajouté');
        } catch (error) {
            ErrorToast("Une erreur est survenue", "Veuillez essayer d'ajouter votre document à nouveau");
            console.log('location Error in uploading images: ', error);
        } 
    }


    /* Const - Handle the screen size to make page responsive */
    const [windowSize, setWindowSize] = React.useState(getWindowSize());
        React.useEffect(() => {
            function handleWindowResize() {
                setWindowSize(getWindowSize());
            }
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }, []);

    const openGallery = async (gallery, index) => {
        const lightboxGallery = await Promise.all(
            gallery.map(async (item) => {
                const img = new Image();
                img.src = item.src;
                await img.decode();
                return {
                    src: img.src,
                    width: windowSize?.innerWidth,
                    height: (img.height / img.width) * windowSize?.innerWidth,
                };
            })
        );

        let lightbox = {};
        lightbox = new PhotoSwipeLightbox({
            gallery: "#gallery",
            children: "a",
            dataSource: lightboxGallery,
            pswpModule: () => import("photoswipe"),
        });
        lightbox.init();
        lightbox.loadAndOpen(index);
    };


    return (
        <Offcanvas
            placement="end"
            className="clt_reuseable_invoice_offcanvas"
            show={isSidebarDocumentRequire}
            onHide={onCloseSidebarDocumentRequire}
        >
            <Offcanvas.Header className="justify-content-start clt_reuseable_invoice_offcanvas_header">

                <React.Fragment>
                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={handleCloseSidebar}
                    >
                        <img src={BackArrow} alt={IMG_ALT} />
                    </button>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title className="clt_reuseable_invoice_title">Document obligatoire</Offcanvas.Title>
                        {/* export button  */}
                        {/* <Button className="clt_reuseable_invoice_header_button shadow-none">
                            Export
                        </Button> */}
                    </div>
                </React.Fragment>


            </Offcanvas.Header>
            <Offcanvas.Body
                className="clt_reuseable_invoice_offcanvas_body"
            >
                <>
                    <div className="clt_reuseable_invoice_innerdivs">
                        <h2 className="clt_reuseable_invoice_innerhead">Document</h2>
                        <p className="clt_admin_dash_timeslot_edit_box_subheading">Pour démarrer une réservation, veuillez ajouter votre carte d'identité ainsi qu'une attestation d'assurance responsabilité civile personnelle ou professionnelle
                        {/*<Link to="#" className="clt_underline_text">
                            Voir plus
                        </Link>*/}
                        </p>
                        <div className="clt_message_input_component_inner_upload">
                            <input 
                                name="imageUpload" 
                                id="imageUpload" 
                                type="file"
                                multiple={false} 
                                accept="application/pdf, image/*" 
                                onChange={(e) => changeImages(e)}
                                onClick={(e) => e.target.value = ''} 
                                style={{ display: "none" }} 
                                ref={inputFileRef}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">Carte identité (Recto)</h3>
                                <p className="clt_reuseable_invoice_cardname">{booking?.BookingDocuments?.some(x => x.type === 'docA') ? `1/1 document importé` : `0/1 document importé`}</p>
                                {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            disabled={!booking?.BookingDocuments?.some(x => x.type === 'docA')}
                                            onClick={()=>{openGallery(booking?.BookingDocuments?.filter(x => x.type === 'docA'))}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Voir
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => { handleUploadFile('docA') }}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                           Ajouter 
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={!booking?.BookingDocuments?.some(x => x.type === 'docA')}
                                            onClick={()=>{handleDeleteDoc('docA')}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Supprimer
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">Carte identité (Verso)</h3>
                                <p className="clt_reuseable_invoice_cardname">{booking?.BookingDocuments?.some(x => x.type === 'docC') ? `1/1 document importé` : `0/1 document importé`}</p>
                                {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            disabled={!booking?.BookingDocuments?.some(x => x.type === 'docC')}
                                            onClick={()=>{openGallery(booking?.BookingDocuments?.filter(x => x.type === 'docC'))}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Voir
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => { handleUploadFile('docC') }}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                           Ajouter 
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={!booking?.BookingDocuments?.some(x => x.type === 'docC')}
                                            onClick={()=>{handleDeleteDoc('docC')}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Supprimer
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">Attestation d'assurance responsabilité civile personnelle ou professionnelle selon votre réservation (Datant de -3 mois avant le début de la réservation)</h3>
                                <p className="clt_reuseable_invoice_cardname">{booking?.BookingDocuments?.some(x => x.type === 'docB') ? `1/1 document importé` : `0/1 document importé`}</p>
                                {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            disabled={!booking?.BookingDocuments?.some(x => x.type === 'docB')}
                                            onClick={()=>{openGallery(booking?.BookingDocuments?.filter(x => x.type === 'docB'))}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Voir
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={()=>{ handleUploadFile('docB') }}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Ajouter
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={!booking?.BookingDocuments?.some(x => x.type === 'docB')}
                                            onClick={()=>{handleDeleteDoc('docB')}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Supprimer
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        {/*<div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-center justify-content-start"*/}
                        {/*        style={{ width: "20%" }}*/}
                        {/*    >*/}
                        {/*        <div className="clt_reuseable_invoice_imgdiv">*/}
                        {/*            <img*/}
                        {/*                src={InvoiceIcon}*/}
                        {/*                className=""*/}
                        {/*                alt="Image"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-start justify-content-center flex-column"*/}
                        {/*        style={{ width: "70%" }}*/}
                        {/*    >*/}
                        {/*        <h3 className="clt_reuseable_invoice_cardnumber">#F10000001</h3>*/}
                        {/*        <p className="clt_reuseable_invoice_cardname">Payment Booking - 01 Jan 23:59 </p>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"*/}
                        {/*        style={{ width: "10%" }}*/}
                        {/*    >*/}
                        {/*        <Dropdown>*/}
                        {/*            <Dropdown.Toggle as={CustomToggle} />*/}
                        {/*            <Dropdown.Menu size="sm" title=""*/}
                        {/*                className="pfr_dropdown_menu">*/}
                        {/*                <Dropdown.Item*/}
                        {/*                    className="clt_reuseable_invoice_cards3dots_options"*/}
                        {/*                >*/}
                        {/*                    View the receipt*/}
                        {/*                </Dropdown.Item>*/}
                        {/*            </Dropdown.Menu>*/}
                        {/*        </Dropdown>*/}

                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*
                        <div className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}>
                            <div className="d-flex align-items-center justify-content-start" style={{ width: "20%" }}>
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img src={InvoiceIcon} className="" alt="Image"/>
                                </div>
                            </div>
                            <div className="d-flex align-items-start justify-content-center flex-column" style={{ width: "70%" }}>
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000004</h3>
                                <p className="clt_reuseable_invoice_cardname">Refund - Booking - 01 Jan 23:59 </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots" style={{ width: "10%" }} >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div
                            className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000003</h3>
                                <p className="clt_reuseable_invoice_cardname">Refund - Edit - 01 Jan 23:59 </p>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div
                            className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000002</h3>
                                <p className="clt_reuseable_invoice_cardname">Payment - Edit - 01 Jan 23:59 </p>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        */}
                        

                    </div>
                </>

            </Offcanvas.Body>
        </Offcanvas >
    );
};

export default SidebarDocumentRequire;


function getWindowSize() {
    const { innerHeight, innerWidth } = window;
    return { innerHeight, innerWidth };
}