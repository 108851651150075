// import
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getEventsTimeSlots, getSlotRooms, updateTimeSlot } from "../../../../../../redux/actions/timeSlot";
// import { Link } from "react-router-dom";


/* Const */

/* Const - Section - Select option room  */
const SectionSelectOption = ({
  title,
  selectedOptionRoom,
  setSelectedOptionRoom,
  onClick,
  isRoomPricePerNight,
  isNumberOfNightMax,
  roomList,
  selectedTimeSlot,
  isEdit,
  createdTimeSlot,
}) => {

  const dispatch = useDispatch();
  const [isClick, setIsClick] = useState(false)
  const selectedSlotRooms = useSelector((state) => state.allSlotRooms.slotRooms);
  const [isOption2Checked, setIsOption2Checked] = useState(true);
  const loading = useSelector((state) => state.allSlotRooms.loading);
  const [rooms, setRooms] = useState([])
  const [Iscomplete, setIsComplete] = useState(false)
  useEffect(() => {
    setRooms([])
    setIsComplete(true)

    setIsOption2Checked(selectedTimeSlot?.isOption2Available)
  }, [selectedTimeSlot])

  useEffect(() => {
    setRooms([])
    if (selectedTimeSlot?.id) {
      dispatch(getSlotRooms(selectedTimeSlot?.id))
    }
    if (createdTimeSlot?.id) {
      dispatch(getSlotRooms(createdTimeSlot?.id))
    }
    setIsComplete(true)
  },[selectedTimeSlot, createdTimeSlot])

  useEffect(() => {
    setRooms([])
    setRooms(selectedSlotRooms)
    setIsComplete(true)
  }, [selectedSlotRooms, loading, selectedTimeSlot?.id])


  const onOption2Update = async (option) => {
    const dataToSend = {
      isOption2Available: option
    }
    const currentId = isEdit ? selectedTimeSlot?.id : createdTimeSlot?.id
    const updateData = JSON.stringify(dataToSend);
    const response = await dispatch(updateTimeSlot(updateData, currentId))
    if (response.error) {
      alert('error while adding timeslot')
    } else {
      dispatch(getEventsTimeSlots());
    }
  }
  /* Section */
  return (
    <Row>
      <Col>

        {/* Section - Heading */}
        {title && <h2 className="clt-booking-cp-div6-title mb-3">Option</h2>}


        {/* Section - SubHeading */} {/*
        <p className="clt_admin_dash_timeslot_edit_box_subheading">
          Select the options to make available for this slot
        </p>
        <Row>
          <Col>
            <div
              className={`d-flex mt-0 flex-row align-items-start justify-content-start gap-3 border-0 clt-select-option-mainDiv`}
              onClick={() =>  {
                  setIsClick(!isClick);
                  setSelectedOptionRoom("All Time");
                  if(isEdit) {
                    setRooms([])
                  }
                console.log(selectedSlotRooms);
                console.log(isEdit);
                console.log({isClick})
                console.log({rooms: selectedSlotRooms?.length})
              }
              }
            >
              <input
                type="checkbox"
                checked= {isClick || rooms?.length > 0}
                onChange={() => setIsClick(!isClick)}
                className="inner-div-checkbox"
                style={{
                  borderColor: (isClick || selectedSlotRooms?.length > 0) ? `#000` : `#ddd`,
                }}
              />
              {/* <div
                className="inner-div-circle"
                style={{
                  borderColor:
                  (isClick || selectedSlotRooms?.length > 0) ? `#000` : `#ddd`,
                }}
              > 
                {/* <div
                  className="subDiv"
                  style={{
                    background:
                    (isClick || selectedSlotRooms?.length > 0)
                        ? `#000`
                        : `transparent`,
                  }}
                ></div>
              </div> 
              <div className="w-100 d-flex flex-column align-items-start justify-content-center inner-div-text">
                <h2>Option : Room</h2>
                {(isClick || selectedSlotRooms?.length > 0) && (
                  <div
                    className="mt-2 w-100 d-flex justify-content-between align-items-center clt-booking-cp-div4-inner"
                    onClick={onClick}
                  >


                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div4-text">
                      <h2>Room {rooms[0]?.room?.name}</h2>
                      {(selectedSlotRooms?.length && Iscomplete) ? (
                        <>
                          {selectedSlotRooms?.map((room, index) => {
                            return (
                              <p
                              key={index}
                              >{`${room?.room?.name}: ${room?.selectedroom?.pricePerNight}€/night - ${room?.selectedroom?.maxQuantity}`}</p>
                            )
                          })}
                        </>
                      ) : (
                        <p>{`Add price and night`}</p>
                      )}
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col>
            {/* Section - Button - Select */}
            <div
              className={`d-flex mt-0 flex-row align-items-start justify-content-start gap-3 border-0 clt-select-option-mainDiv`}
              onClick={() => {
                setIsOption2Checked(!isOption2Checked);
                onOption2Update(!isOption2Checked);
              }}
            >
              <input
                type="checkbox"
                checked={isOption2Checked}
                onChange={() => {
                  setIsOption2Checked(!isOption2Checked);
                  onOption2Update(!isOption2Checked);
                }}
                className="inner-div-checkbox"
                style={{
                  borderColor: isClick || selectedSlotRooms?.length > 0 ? `#000` : `#ddd`,
                }}
              />
              <div className="w-100 d-flex flex-column align-items-start justify-content-center inner-div-text">
                <h2>Option 2</h2>
              </div>
            </div>
          </Col>
        </Row>

      </Col>
    </Row>
  );
};

export default SectionSelectOption;
