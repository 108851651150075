import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Offcanvas, Button, Row, Col } from 'react-bootstrap';
import BackArrow from "../../../../assets/img/back_arrow.png";
import '../[SIDEBAR] Search.css'
import SearchIcon from "../../../../assets/img/home_searchbar_icon.png";
import AdminDashboardBookingDetail from '../../../screens/adminDashboard/AdminDashboardBooking/admin_dash_booking_detail/sidebar/AdminDashboardBookingDetail';
import { IMG_ALT } from "../../../../constants";
import { getBookingsForUsers } from '../../../../redux/actions/booking';
import moment from 'moment';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { LoaderDots } from '@thumbtack/thumbprint-react';




/* Const - Search */
const SidebarSearchBooking = ({ usersData, searchHandler, isLoading, setIsLoading, onCloseAdminSearchBooking, onOpenAdminBookingDetailSidebar, setBookingSidebarDetail }) => {

    const dispatch = useDispatch();
    const [isSearch, setIsSearch] = React.useState(false);
    const [isSearchCanvas, setIsSearchCanvas] = React.useState(false);
    const ref = useDetectClickOutside({ onTriggered: () => setIsSearch(false) });
    const searchbookings = useSelector(state => state.searchBookings.userSearchBooking);


    const onChange = (e) => {
        setIsSearch(false)
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log("event", e.target?.search?.value)
        await searchHandler(e.target?.search?.value)
    };

    const openBookingSidebarDetail = (e) => {
        onOpenAdminBookingDetailSidebar();
        setIsSearch(false);
    }
    const userIdsSet = new Set(Object.values(usersData).map(user => user.id));
    const userIds = Array.from(userIdsSet);
    useEffect(() => {
        callBooking();
    }, [dispatch, usersData]);

    const callBooking = async () => {
        const boo = await dispatch(getBookingsForUsers(userIds));
        setIsLoading(false);
    }

    console.log("usersData", usersData);
    console.log("searchBookings", searchbookings);
    /* Section & Sidebar */


    const inputRef = useRef(null);

    useEffect(() => {
        if (isSearchCanvas) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 0);
        }
    }, [isSearchCanvas]);
    return (


        /* Section  */
        <div className="clt_search_chat_user_container" >
            <div className="clt_search_chat_user_button">
                <div className="clt_search_booking_field" >
                    <Button
                        onClick={() => setIsSearchCanvas(true)}
                        type="text" className="shadow-none">
                        {'Recherche'}
                    </Button>
                    <span className="clt_search_booking_icon_div">
                        <img className="clt_search_chat_user_icon" src={SearchIcon} alt={IMG_ALT} />
                    </span>
                </div>
            </div>


            { /* Sidebar */}
            <Offcanvas
                placement="end"
                className="clt_search_chat_user_offcanvas"
                show={isSearchCanvas}
                onHide={() => setIsSearchCanvas(false)}
            >


                { /* Sidebar - Header */}
                <Offcanvas.Header className="justify-content-start clt_search_chat_user_offcanvas_header " >
                    <>


                        { /* Sidebar - Header - Close button */}
                        <Button
                            type="button"
                            className="shadow-none btn-close btn-back"
                            aria-label="Close"
                            onClick={() => setIsSearchCanvas(false)}
                        >
                            <img src={BackArrow} alt={IMG_ALT} />
                        </Button>


                        { /* Sidebar - Header - Search button */}
                        <div className="w-100">
                            <Form className="clt_search_chat_user_field_2" onSubmit={handleFormSubmit}>
                                <Form.Group className="clt_search_chat_user_field">
                                    <Form.Control
                                        ref={inputRef}
                                        name='search' type="text" class="form-control-search" placeholder="Search" onChange={onChange} onBlur={() => setIsSearch(false)} />
                                </Form.Group>
                            </Form>
                        </div>
                    </>
                </Offcanvas.Header>


                { /* Sidebar - Body */}
                <Offcanvas.Body className={`clt_search_chat_user_offcanvas_body ${(isLoading || searchbookings.length == 0) && `d-flex flex-column justify-content-center align-items-center`}`}>


                    { /* Sidebar - Body - Result */}

                    {
                        isLoading ?
                            <Row>
                                <Col>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <LoaderDots theme="muted" />
                                    </div>
                                </Col>
                            </Row>
                            :
                            !isLoading && searchbookings.length == 0 ?
                                <Row>
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <h3>Aucun résultat</h3>
                                        </div>
                                    </Col>
                                </Row>
                                :

                                searchbookings &&
                                searchbookings.map((bookingList, index) => (
                                    <div key={index}>
                                        {bookingList &&
                                            bookingList.map((item, innerIndex) => (

                                                <div className="clt_search_chat_user_datalist_2">
                                                    <div>
                                                        <div>
                                                            {/* Result - Container */}
                                                            <div key={innerIndex} onClick={() => { setBookingSidebarDetail(item); openBookingSidebarDetail() }} className="clt_search_sidebar_container_box p-3 cursor-pointer">
                                                                {/* Result - Container - Avatar */}
                                                                <div className='clt_admin_dash_booking_avatar_wrapper'>
                                                                    {item?.User?.profilePictureUrl ?
                                                                        <div className="clt_admin_dash_booking_image">
                                                                            <img src={item?.User?.profilePictureUrl} alt={item?.User?.firstName} />
                                                                        </div>
                                                                        :
                                                                        <div className="clt_admin_dash_booking_avatar">
                                                                            <div className='clt_admin_dash_booking_avatar_name'>{item?.User?.firstName[0].toUpperCase()}</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {/* Result - Container - Detail */}
                                                                <div className="clt_admin_dash_booking_container_detail">
                                                                    <div className="clt_admin_dash_booking_container_box_name">{item?.User?.firstName} {item?.User?.lastName}</div>
                                                                    <div className="subtitle">{moment(item?.ChildTimeSlot?.startDate).format("DD MMM YYYY")} {'-'} {moment(item?.ChildTimeSlot?.startHour, "HH:mm:ss").format("HH:mm")} {'➔'}</div>
                                                                    <div className="subtitle2"> {moment(item?.ChildTimeSlot?.endDate).format("DD MMM YYYY")} {'-'} {moment(item?.ChildTimeSlot?.endHour, "HH:mm:ss").format("HH:mm")}</div>

                                                                    <div className="event" style={{ color: `${item?.Event?.color}`, backgroundColor: `${item?.Event?.background}` }}>{item?.Event?.title}</div>
                                                                </div>

                                                            </div>
                                                            <div
                                                                className="clt_searchbar_hr">
                                                            </div>
                                                        </div>

                                                        {/*********** There is a preview of a similar code develop in "AdminDashboardBooking.js" that you will have to start from for keep consistency

                                {currentItems &&
                                    currentItems.map((item, index) => (
                                        <div key={index} onClick={() => { setBookingSidebarDetail(item); onOpenAdminBookingDetailSidebar() }} className={currentItems.length == 1 ? "clt_admin_dash_booking_container_wrapper" : index == 0 ? "clt_admin_dash_booking_container_wrapper_first" : (index + 1) == currentItems?.length ? "clt_admin_dash_booking_container_wrapper_last" : "clt_admin_dash_booking_container_wrapper_center"}>
                                            <div className="clt_admin_dash_booking_container_box">
                                                <div className='clt_admin_dash_booking_avatar_wrapper'>
                                                    {item?.User?.profilePictureUrl ?
                                                        <div className="clt_admin_dash_booking_image">
                                                            <img src={item?.User?.profilePictureUrl} alt={item?.User?.firstName} />
                                                        </div>
                                                        :
                                                        <div className="clt_admin_dash_booking_avatar">
                                                            <div className='clt_admin_dash_booking_avatar_name'>{item?.User?.firstName[0].toUpperCase()}</div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="clt_admin_dash_booking_container_detail">
                                                    <div className="clt_admin_dash_booking_container_box_name">{item?.User?.firstName} {item?.User?.lastName}</div>
                                                    <div className="subtitle">{moment(item?.ChildTimeSlot?.startDate).format("DD MMM YYYY")} {'-'} {moment(item?.ChildTimeSlot?.startHour, "HH:mm:ss").format("HH:mm")} {'➔'}</div>
                                                    <div className="subtitle2"> {moment(item?.ChildTimeSlot?.endDate).format("DD MMM YYYY")} {'-'} {moment(item?.ChildTimeSlot?.endHour, "HH:mm:ss").format("HH:mm")}</div>

                                                    <div className="event" style={{ color: `${item?.Event?.color}`, backgroundColor: `${item?.Event?.background}` }}>{item?.Event?.title}</div>
                                                </div>
                                                <div className="clt_admin_dash_booking_container_arrow">
                                                    <img src={NextArrow} className="clt_admin_arrow" alt={IMG_ALT} />
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    */}


                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))}
                </Offcanvas.Body>
            </Offcanvas>
            {/*
            {isOpenSidebarBookingAdmin ? <AdminDashboardBookingDetail
                onCloseOpenSidebarBookingAdmin={onCloseOpenSidebarBookingAdmin}
                isOpenSidebarBookingAdmin={isOpenSidebarBookingAdmin}
                data={bookingSidebarDetail}
            /> : null} */}
        </div>
    )
}

export default SidebarSearchBooking
