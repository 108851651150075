import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import './Section.css';
const SectionGuest = ({ image, subText, setIsGuestSidebar, buttonText }) => {
  return (
    <Row className="clt-booking-mb notranslate">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Invité</h2>
              <p>{subText}</p>
            </div>
            {setIsGuestSidebar && (
              <Button className="shadow-none" onClick={setIsGuestSidebar}>
                <span>{buttonText}</span>
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionGuest;
