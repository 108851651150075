import { API_ENDPOINT } from "../../constants";
import { actionTypes } from "../constants/action-type";
import { auth } from "../../components/screens/auth/firebase";
import { SuccessToast, ErrorToast } from "../../components/reusable/Toast";
import { Stripe } from 'stripe';
import { useState } from "react";
const stripe = new Stripe('sk_test_51NPWRHJXpxuUxxe0pmxneFwEgmEhvksOvopAsK6OeAlhoPb9l5jTTQKW82OI8qMQXYoznCv1gTMS7YQTwPwwm6UE007Qmpwfxj');

async function getAuthObject() {
  const token = await auth.currentUser.getIdToken();

  return { Authorization: `Bearer ${token}` }
}
async function confirmPaymentIntent(clientSecret, cardDetails) {
  console.log('entereddddddddddd', stripe, "00000000")
  stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      type: 'card',
      card: {
        number: '4242424242424242',
        exp_month: '12',
        exp_year: '2024',
        cvc: '123'
      }, // Pass the card details directly
      // Other payment method details if required
    }
  })
    .then((result) => {
      if (result.error) {
        // Display error to the user (e.g., card declined)
        console.error(result.error);
      } else {
        // Payment succeeded
        console.log(result.paymentIntent);
        // Proceed with any additional steps or show a success message
      }
    }).catch((err) => console.log("errrrrrrrrrrrr", err))
}
export const createBooking = (data, cardDetails) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject())
          },
          body: (data)
        });
        const result = await response.json()
        console.log('*-------------------------***', result)
        console.log("rruuuiiii", response.status)
        if (response.status >= 200 && response.status < 400) {
          console.log("rrrrrrrrrrrrrrrrrrrrrrrrrr")
          // const data = {bookingId: result?.message?.id, amount: result?.message?.price}
          // const dataToSend = JSON.stringify(data)
          // try {
          //   const paymentResponse = await fetch(`${API_ENDPOINT}/strip`, {
          //     method: 'POST',
          //     headers: {
          //         'Content-type': 'application/json',
          //         'Accept': 'application/json',
          //         ...( await getAuthObject())
          //       },
          //     body: (dataToSend)
          //   });
          //   const response = await paymentResponse.json()
          //   console.log("uuuuuuuuuuuuuuuuuuuu", paymentResponse.status)
          //   if (paymentResponse.status >= 200 && paymentResponse.status < 400){
          //     console.log("vvvvvvvvvvvvvvvvvvvvvvv",response )
          //     stripe.createToken('card', cardDetails).then(token => console.log(token)).catch(error => console.log(error))
          //     // console.log(token,"tokennnnnnnnnnnnnnnnnnnnnn")
          //     // const stripeResponse = await confirmPaymentIntent(response?.clientSecret,cardDetails)
          //     // console.log(stripeResponse, "payment result")
          //   }
          // }
          // catch {
          //
          // }
          // SuccessToast("Booking Created successfully");
          resolve(result);
        }
        else {
          ErrorToast(`${result.error}`);
          resolve({ error: true, message: 'Une erreur est apparu lors de la sélection de la chambre' });
        }
      } catch (err) {
        ErrorToast("Une erreur est survenue", "Veuillez essayer de finaliser votre réservation à nouveau ou rafraichisser la page.")
        resolve({ error: true, message: 'Error while creating booking' });
      }
    });
  }
}
export const startLoading = () => ({ type: 'START_LOADING' });
export const stopLoading = () => ({ type: 'STOP_LOADING' });


export const getBookings = (page, itemsPerPage, eventFilter, bookingFilterType) => {
  return async function (dispatch) {
    dispatch(startLoading()); // Dispatch action to set loading state to true

    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking?page=${page}&itemsPerPage=${itemsPerPage}&eventFilter=${eventFilter}&bookingFilterType=${bookingFilterType}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
        });
        const result = await response.json();
        console.log('this is api result: ', result)
        if (response.status >= 200 && response.status < 400) {
          dispatch({ type: actionTypes.ALLBOOKINGS, payload: result.bookings, totalCount: result.totalCount });
        } else {
          dispatch({ type: actionTypes.ALLBOOKINGSERR, payload: result });
        }
      } catch (err) {
        console.log('Error while getting booking');
      } finally {
        dispatch(stopLoading()); // Dispatch action to set loading state to false when API call is complete
      }
    });
  };
};


export const getBooking = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking/${id}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          }
        });
        const result = await response.json()
        console.log('*-------------------------*', result)
        if (response.status >= 200 && response.status < 400) {
          dispatch({ type: actionTypes.ALLBOOKINGS, payload: result })
        }
        else {
          dispatch({ type: actionTypes.ALLBOOKINGSERR, payload: result })
        }
      } catch (err) {

      }
    });
  }
}

export const getBookingsForUsers = (userIds) => {
  return async function (dispatch) {
    try {
      const bookingPromises = userIds.map(async (userId) => {
        const response = await fetch(`${API_ENDPOINT}/booking/${userId}/list`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          }
        });

        if (response.status >= 200 && response.status < 400) {
          const result = await response.json();
          return result;
        } else {
          throw new Error('Failed to fetch bookings');
        }
      });

      const bookings = await Promise.all(bookingPromises);

      // Dispatch an action with the bookings for all users
      dispatch({ type: actionTypes.USERSEARCHBOOKING, payload: bookings });
    } catch (error) {
      // Dispatch an action for error handling if needed
      dispatch({ type: actionTypes.ALLBOOKINGSERR, payload: error.message });
    }
  }
};

export const getsubscription = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking/getsubscription/${id}`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          }
        });
        const result = await response.json()
        console.log('*-------------------------*', result)
        if (response.status >= 200 && response.status < 400) {
          dispatch({ type: actionTypes.ALLBOOKINGS, payload: result })
        }
        else {
          dispatch({ type: actionTypes.ALLBOOKINGSERR, payload: result })
        }
      } catch (err) {

      }
    });
  }
}



export const paynowSubscription = (data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking/paynowSubscription`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject())
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          ErrorToast("Une erreur est survenue", "Veuillez essayer de finaliser votre réservation à nouveau ou rafraichisser la page.")
          resolve({ error: true, message: 'Une erreur est apparue durant le paiement, veuillez réessayez' });
        }
      } catch (err) {
        resolve({ error: true, message: 'Une erreur est apparue durant le paiement, veuillez réessayez' });
      }
    });
  }
}

export const createCards = (data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/card`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject())
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          SuccessToast("Moyen de paiement ajouté");
          resolve(result);
        }
        else {
          ErrorToast("Echec de l'ajout de la carte", "Veuillez essayer d'ajouter à nouveau votre carte'.")
          
          resolve({ error: true, message: "Une erreur est apparue lors de l'ajout du moyen de paiement, veuillez raffraichir la page" });
        }
      } catch (err) {
        resolve({ error: true, message: "Une erreur est apparue lors de l'ajout du moyen de paiement, veuillez raffraichir la page " });
      }
    });
  }
}

export const getCards = () => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/card`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject())
          }
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          dispatch({ type: actionTypes.ALLCARDS, payload: result })
        }
        else {
          dispatch({ type: actionTypes.ALLCARDSERR, payload: result })
        }
      } catch (err) {
        console.log('Error while getting booking')
      }
    });
  }
}

export const getClientSecret = () => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/strip/client-setup-secret`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject())
          }
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          dispatch({ type: actionTypes.CLIENTSECRET, payload: result })
        }
        else {
          dispatch({ type: actionTypes.CLIENTSECRETERR, payload: result })
        }
      } catch (err) {
        console.log('Error while getting booking')
      }
    });
  }
}

export const saveCard = (data) => {
  console.log("SAving card" + JSON.stringify(data))
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        console.log("calling card")
        const response = await fetch(`${API_ENDPOINT}/strip/save-card`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject()),
          },
          body: (JSON.stringify(data.setupIntent))
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: "Une erreur est survenue lors de l'ajout de la carte, veuillez raffraichir la page" });
        }
      } catch (err) {
        resolve({ error: true, message: "Une erreur est survenue lors de l'ajout de la carte, veuillez raffraichir la page" });
      }
    });
  }
}
export const deleteCard = (data) => {
  console.log("Deliting card" + JSON.stringify(data))
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        console.log("calling card")
        const response = await fetch(`${API_ENDPOINT}/strip/delete-card`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...(await getAuthObject()),
          },
          body: (JSON.stringify(data))
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400) {
          resolve(result);
        }
        else {
          resolve({ error: true, message: "Une erreur est survenue lors de la suppression de la carte, veuillez raffraichir la page" });
        }
      } catch (err) {
        resolve({ error: true, message: 'Une erreur est survenue lors de la suppression de la carte, veuillez raffraichir la page.' });
      }
    });
  }
}
