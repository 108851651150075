import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";

import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import PathList from "./PathList";
import EditPath from "./EditPath";
import ManageSubpath from "./ManageSubpath";
import CreatePath from "./CreatePath";
import { ErrorToast } from "../../../../../reusable/Toast";
import { createPath, deletePath, getAllPaths, updatePath } from "../api";

function ManagePath({ isOpen, onClose, selectedCategory }) {
  const [paths, setPaths] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);
  const [openEditPath, setOpenEditPath] = useState(false);
  const [openManageSubpath, setOpenManageSubpath] = useState(false);
  const [openCreatePath, setOpenCreatePath] = useState(false);

  const createP = async (path) => {
    const res = await createPath({
      path: path.path,
      articleId: path.article ? path.article : null,
      categoryId: selectedCategory.id,
    });
    if (res.success) {
      getPaths(selectedCategory.id);
      setOpenCreatePath(false);
    } else {
      ErrorToast("Unable to create path");
    }
  };
  const deleteP = async (path) => {
    const res = await deletePath(path.id);
    if (res.success) {
      getPaths(selectedCategory.id);
    } else {
      ErrorToast("Unable to delete path");
    }
  };
  const updateP = async (path) => {
    const res = await updatePath(path.id, path);
    if (res.success) {
      getPaths(selectedCategory.id);
      setOpenEditPath(false);
    } else {
      ErrorToast("Unable to update path");
    }
  };
  const getPaths = async (id) => {
    const res = await getAllPaths(id);
    if (res.success) {
      setPaths(Object.values(res.data));
    } else {
      ErrorToast("Unable to fetch paths");
    }
  };

  const onDragEndEvent = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...paths];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setPaths(copiedItems);

    copiedItems.map(async (path, index) => {
      await updatePath(path.id, { position: index });
    });

    console.log("AMMAR", copiedItems, source, destination);
  };

  useEffect(() => {
    if (selectedCategory) {
      getPaths(selectedCategory.id);
    }
  }, [selectedCategory]);

  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      backdrop={false}
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Path - {selectedCategory?.name}</Offcanvas.Title>

            <Button
              className="clt_admin_dash_manage_access_header_button shadow-none"
              onClick={() => setOpenCreatePath(true)}
            >
              Add
            </Button>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <PathList
          onDragEndEvent={onDragEndEvent}
          list={paths}
          openEditPath={(path) => {
            setSelectedPath(path);
            setOpenEditPath(true);
          }}
          openManageSubpath={(path) => {
            setSelectedPath(path);
            setOpenManageSubpath(true);
          }}
          deletePath={deleteP}
        />
        <EditPath
          isOpen={openEditPath}
          onClose={() => setOpenEditPath(false)}
          selectedPath={selectedPath}
          updatePath={updateP}
        />
        <CreatePath
          isOpen={openCreatePath}
          onClose={() => setOpenCreatePath(false)}
          selectedCategory={selectedCategory}
          createPath={createP}
        />
        <ManageSubpath
          isOpen={openManageSubpath}
          onClose={() => setOpenManageSubpath(false)}
          heading={selectedPath?.path}
          pSelectedPath={selectedPath}
          selectedCategory={selectedCategory}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ManagePath;
