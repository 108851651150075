import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import CustomMapIcon from "../../../../assets/img/mybooking_icon_ping_address_white.png";

const DetailSection7 = (props, isDetail) => {
  // Function to handle button click and open the specific Google Maps link in a new tab
  const handleButtonClick = () => {
    // Replace the Google Maps link with the one you provided
    const googleMapsLink =
      "https://www.google.com/maps/place/Ch%C3%A2teau+Latournelle/@43.6306114,1.3458085,11z/data=!4m6!3m5!1s0x12aea577700a3621:0x574d4da825a6c523!8m2!3d43.6845113!4d1.4022851!16s%2Fg%2F11s1ttsyk1?entry=ttu";

    // Open the link in a new tab
    window.open(googleMapsLink, "_blank");
  };

  return (
    <Row className="clt-detail-section-7 ">
      <Col className="clt-detail-left-section-1">
        <Row className={isDetail ? 'clt-detail-section-7-header545354' : 'clt-detail-section-7-header'}>
          <Col>
            <h2>{props.title}</h2>
            <p>{props.subTitle}</p>
          </Col>
        </Row>
        <Row className="clt-detail-section-7-img-row  m-0">
          {/* Desktop image */}
          <Col
            className={isDetail ? 'clt-detail-section-7-img-div d-none d-sm-block position-relative clt-detail-section-7-img-div5462' : 'clt-detail-section-7-img-div d-none d-sm-block position-relative'}
            style={{
              backgroundImage: `url(${props.imageDesktop})`,
            }}
          >
            {/* Button at the bottom left */}
            <Button
              variant="primary"
              className="position-absolute bottom-0 start-0 m-3 clt-button-map-afficher-carte"
              onClick={handleButtonClick}
            >
              <img
                src={CustomMapIcon}
                alt="Custom Map Icon"
                className="me-2"
                style={{ width: "13px", height: "22px", paddingBottom: "4px" }}
              />
              Afficher la carte
            </Button>
          </Col>
          {/* Mobile image */}
          <Col
              className="clt-detail-section-7-img-div d-block d-sm-none d-flex align-items-end justify-content-end"
              style={{
                backgroundImage: `url(${props.imageMobile})`,
              }}
            >
              {/* Button at the bottom right */}
              <Button
                variant="primary"
                className="m-3 clt-button-map-afficher-carte"
                onClick={handleButtonClick}
              >
                <img
                  src={CustomMapIcon}
                  alt="Custom Map Icon"
                  className="me-2"
                  style={{ width: "15px", height: "20px" }}
                />
                Afficher la carte
              </Button>
            </Col>

        </Row>
      </Col>
    </Row>
  );
};

export default DetailSection7;
