import React, {useState, useEffect} from 'react'
import BackArrowRight from "../../../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../../../constants";
import ConfirmPay1 from "../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import SectionEvent from "../../../../../reusable/booking/SectionEvent";
import SectionContactRequestDate from '../../../../../reusable/contactrequest/SectionContactRequestDate';
import SectionContactRequestGuest from '../../../../../reusable/contactrequest/SectionContactRequestGuest';

const ChatComponentContactRequest = ({onClick, contactRequest, socket}) => {
    const [request, setRequest] = useState(null)

    const handleOnClick = () => {
        onClick(request)
    }


    useEffect(() => {
        setRequest(contactRequest)
    }, [contactRequest])

    useEffect(() => {
        const updateContactRequest = (data) => {

            if(contactRequest.id !== data.id) return

            setRequest(data)
        }

        socket.on('contactRequestUpdated', updateContactRequest)

        return () => {
            socket.off('contactRequestUpdated', updateContactRequest)
        }
    }, [socket])

    return (
        <div className="clt_messages_container_request_message" onClick={handleOnClick}>
            <div className="clt_messages_container_request_message_header">
                <h5>Demande contact</h5>
                <button
                    type="button"
                    className="back-button "
                    aria-label="Close"
                >
                    <img src={BackArrowRight} className="" alt={IMG_ALT} />
                </button>
            </div>
            <SectionEvent
                image={ConfirmPay1}
                subText={request?.Event?.title}
                buttonText="Edit"
                event={request?.Event}
            />

            <SectionContactRequestDate
                image={ConfirmPay2}
                subText={`${request?.month} ${request?.year}`}
            />

            <SectionContactRequestGuest
                image={ConfirmPay3}
                subText={`${request?.guest_number}`}
            />
        </div>
    )
}

export default ChatComponentContactRequest