/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../../assets/img/back_arrow.png";
import { IMG_ALT, API_ENDPOINT } from "../../../../../constants";
import "../../../account/account.css";
import IconTrashIcon from "../../../../../assets/img/icon_trash_icon.png";
import CustomHr from "../../../../reusable/CustomHr";
import AccountInputText from "../../../account/sections/AccountInputText";
import AccountInputPhone from "../../../account/sections/AccountInputPhone";
import AccountInputEmail from "../../../account/sections/AccountInputEmail";
import AccountInputAddress from "../../../account/sections/AccountInputAddress";
import AccountInputProfilePicture from "../../../account/sections/AccountInputProfilePicture";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { ErrorToast, SuccessToast } from "../../../../reusable/Toast";
import {
	PROFILE_KEY,
	getAdminProfile,
	updateBusinessInformationRequest,
	uploadProfilePicRequest,
} from "./api";
import { useAuth } from "../../../auth/authContext";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import ReactCodeInput from "react-verification-code-input";
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from "firebase/auth";
import { auth } from "../../../auth/firebase";
import ConfirmPay from "../../../../reusable/SidebarMessageContinueConfirm&Pay";

const initialAccountScreens = {
	// accountsList: true,
	profileInformation: true,
	selectProfile: false,
	confirm: false,
};

const isAddressEmpty = (address) => {
	return (
		!address.address &&
		!address.city &&
		!address.zip_code &&
		!address.state_region &&
		!address.country
	);
};

export default function BusinessInformation(
	{ isOpenSidebar,
		onCloseSidebar,
		selectProfile,
		selectedProfileData,
		setSelectedProfileData,
		setIsUserSidebar
	}) {
	const queryClient = useQueryClient();



	const handleUploadProfilePic = (file) => {
		const data = new FormData();
		data.append("profile_picture_url", file);
		uploadProfilePic.mutate(data);
	};

	const updateBusinessInformationProfileSuccess = (result) => {
		const user = result.data.data;
		setAdminProfile({
			name: user.name,
			profile_picture_url: user?.profilePictureUrl ?? null,
			email: user.email,
			phone: user.phone,
			business_address: {
				address: user?.businessAddress ?? "",
				city: user?.businessCity ?? "",
				zip_code: user?.businessZipCode ?? "",
				state_region: user?.businessState ?? "",
				country: user?.businessCountry ?? "",
			},
			billing_address: {
				address: user?.billingAddress ?? "",
				city: user?.billingCity ?? "",
				zip_code: user?.billingZipCode ?? "",
				state_region: user?.billingState ?? "",
				country: user?.billingCountry ?? "",
			},
			commercial_name: user.commercialName,
			capital_social: user.capitalSocial,
			siret: user.siret,
			tva_number: user.tvaNumber,
		});
		SuccessToast(result.data.message);
	};

	const updateBusinessInformationProfile = useMutation(updateBusinessInformationRequest, {
		onSuccess: updateBusinessInformationProfileSuccess,
	});

	const uploadProfilePic = useMutation(uploadProfilePicRequest, {
		onSuccess: (result) => {
			const user = result.data.data
			setAdminProfile((prevData) => {
				return {
					name: user.name,
					profile_picture_url: user?.profilePictureUrl ?? null,
					email: user.email,
					phone: user.phone,
					business_address: {
						address: user?.businessAddress ?? "",
						city: user?.businessCity ?? "",
						zip_code: user?.businessZipCode ?? "",
						state_region: user?.businessState ?? "",
						country: user?.businessCountry ?? "",
					},
					billing_address: {
						address: user?.billingAddress ?? "",
						city: user?.billingCity ?? "",
						zip_code: user?.billingZipCode ?? "",
						state_region: user?.billingState ?? "",
						country: user?.billingCountry ?? "",
					},
					commercial_name: user.commercialName,
					capital_social: user.capitalSocial,
					siret: user.siret,
					tva_number: user.tvaNumber,
				};
			});
			SuccessToast(result.data.message);
			setIsProfilePicInputSidebar(false);
		},
	});

	const [accountScreens, setAccountScreens] = useState(initialAccountScreens);
	const [isAccount, setIsAccount] = useState({
		BusinessInformation: true,
	});

	const [selectedProfile, setSelectedProfile] = useState("")

	const { user } = useAuth();

	const [AdminProfile, setAdminProfile] = useState({
		// name: user?.lastName,
		// email: user?.email,
		// phone: user?.phone,
		// business_address: {
		// 	address: user?.businessAddress ?? "",
		// 	city: user?.businessCity ?? "",
		// 	zip_code: user?.businessZipCode ?? "",
		// 	state_region: user?.businessState ?? "",
		// 	country: user?.businessCountry ?? "",
		// },
		// billing_address: {
		// 	address: user?.billingAddress ?? "",
		// 	city: user?.billingCity ?? "",
		// 	zip_code: user?.billingZipCode ?? "",
		// 	state_region: user?.billingState ?? "",
		// 	country: user?.billingCountry ?? "",
		// },
		// commercial_name: user.commercial_name,
		// capital_social: user.capital_social,
		// siret: user.siret,
		// tva_number: user.tva_number,

		name: "",
		profile_picture_url: null,
		email: "",
		phone: "",
		business_address: {
			address: "",
			city: "",
			zip_code: "",
			state_region: "",
			country: "",
		},
		billing_address: {
			address: "",
			city: "",
			zip_code: "",
			state_region: "",
			country: "",
		},
		commercial_name: "",
		capital_social: "",
		siret: "",
		tva_number: "",
	});

	useEffect(() => {
		const bootstrap = async () => {
			const req = await getAdminProfile()
			const user = req.data.data
			setAdminProfile({
				name: user?.name || "",
				commercial_name: user?.commercialName || "",
				capital_social: user?.capitalSocial || "",
				siret: user?.siret || "",
				tva_number: user?.tvaNumber || "",
				email: user?.email || "",
				phone: user?.phone || "",
				business_address: {
					address: user?.businessAddress ?? "",
					city: user?.businessCity ?? "",
					zip_code: user?.businessZipCode ?? "",
					state_region: user?.businessState ?? "",
					country: user?.businessCountry ?? "",
				},
				billing_address: {
					address: user?.billingAddress ?? "",
					city: user?.billingCity ?? "",
					zip_code: user?.billingZipCode ?? "",
					state_region: user?.billingState ?? "",
					country: user?.billingCountry ?? "",
				},
				profile_picture_url: user?.profilePictureUrl ?? null,
			});
		}
		bootstrap()
	}, [])

	// useEffect(() => {
	// 	if (user) {
	// 		setAdminProfile({
	// 			name: user.lastName,
	// 			commercial_name: "",
	// 			capital_social: "",
	// 			siret: "",
	// 			tva_number: "",
	// 			email: user.email,
	// 			phone: user.phone,
	// 			business_address: {
	// 				address: user?.businessAddress ?? "",
	// 				city: user?.businessCity ?? "",
	// 				zip_code: user?.businessZipCode ?? "",
	// 				state_region: user?.businessState ?? "",
	// 				country: user?.businessCountry ?? "",
	// 			},
	// 			billing_address: {
	// 				address: user?.billingAddress ?? "",
	// 				city: user?.billingCity ?? "",
	// 				zip_code: user?.billingZipCode ?? "",
	// 				state_region: user?.billingState ?? "",
	// 				country: user?.billingCountry ?? "",
	// 			},
	// 			profile_picture_url: user?.profilePictureUrl ?? null,
	// 		});
	// 	}
	// }, [user]);

	const [isTextInputSidebar, setIsTextInputSidebar] = useState(false);
	const [isPhoneInputSidebar, setIsPhoneInputSidebar] = useState(false);
	const [isEmailInputSidebar, setIsEmailInputSidebar] = useState(false);
	const [isAddressInputSidebar, setIsAddressInputSidebar] = useState(false);
	const [isProfilePicInputSidebar, setIsProfilePicInputSidebar] =
		useState(false);
	const [isSidebarTitle, setIsSidebarTitle] = useState("");
	const [isSidebarInputData, setIsSidebarInputData] = useState({});
	const [verificationData, setVerificationData] = useState({
		name: "",
		value: ''
	})

	const handleBack = (from, to) => {
		setAccountScreens((prevState) => ({
			...prevState,
			[from]: !prevState[from],
			[to]: !prevState[to],
		}));
	};

	const handleEditBusinessInformationProfile = () => {
		setIsAccount((prevState) => ({
			...prevState,
			BusinessInformation: true,
		}));
		setAccountScreens((prevState) => ({
			...prevState,
			selectProfile: false,
			profileInformation: true,
		}));
	};

	const handleBusinessInformationProfile = () => {
		setIsAccount((prevState) => ({
			...prevState,
			BusinessInformation: true,
		}));
		setAccountScreens((prevState) => ({
			...prevState,
			accountsList: false,
			profileInformation: true,
		}));
	};
	const handleInputData = async (data) => {
		// if (data.name === "email") {
		// 	setAdminProfile((prevState) => ({
		// 		...prevState,
		// 		email: data.value
		// 	}));
		// 	return;
		// }
		// 		if (data.name === "phone") {
		// 			const applicationVerifier = new RecaptchaVerifier('recaptcha-container');
		// const provider = new PhoneAuthProvider(auth);
		// const verificationId = await provider.verifyPhoneNumber('+16505550101', applicationVerifier);
		// 			updatePhoneNumber()
		// 		}

		// 		if (["email", "phone"].includes(data.name)) {
		// 			setVerificationData({ name: data.name, value: data.value })
		// 			setAccountScreens(prevState => ({
		// 				...prevState,
		// 				accountsList: false,
		// 				profileInformation: false,
		// 				verification: true
		// 			}))
		// 			return;
		// 		}


		if (isAccount.BusinessInformation || data.name === "phone") {
			return updateBusinessInformationProfile.mutate({ [data.name]: data.value });
		}

	};

	useEffect(() => {
		if (selectProfile == "select") {
			setAccountScreens((prevState) => ({
				accountsList: false,
				profileInformation: false,
				selectProfile: true,
			}));
		}
	}, [])

	useEffect(() => {
		if (selectedProfileData && selectProfile === "update") {
			setAccountScreens((prevState) => ({
				accountsList: false,
				profileInformation: selectedProfileData?.type === "BusinessInformation" ? true : false,
				selectProfile: false,
			}));
			setSelectedProfile(selectedProfileData?.type)
		}
	}, [])

	useEffect(() => {
		if (selectedProfileData && selectProfile === "admin") {
			setAccountScreens((prevState) => ({
				accountsList: false,
				profileInformation: selectedProfileData?.type === "BusinessInformation" ? true : false,
				selectProfile: false,
			}));
			setSelectedProfile(selectedProfileData?.type)
		}
	}, [selectedProfileData])


	return (
		<>
			<Offcanvas
				placement="end"
				className="clt_mybooking_offcanvas"
				show={isOpenSidebar}
				onHide={onCloseSidebar}
			>
				<Offcanvas.Header className="justify-content-between clt_account_offcanvas_header">
					{/* {accountScreens.accountsList && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={onCloseSidebar}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>Account</Offcanvas.Title>
							</div>
						</React.Fragment>
					)} */}
					{accountScreens.profileInformation && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={() => {
									selectProfile == "select" ?
										handleBack("profileInformation", "selectProfile") :
										onCloseSidebar()
								}
								}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>Admin - Profile</Offcanvas.Title>
							</div>
						</React.Fragment>
					)}

					{accountScreens.selectProfile && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={onCloseSidebar}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>Profile</Offcanvas.Title>
							</div>
						</React.Fragment>
					)}
					{accountScreens.confirm && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={() => handleBack("selectProfile", "confirm")}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>Continuer vers confirmer et payer</Offcanvas.Title>
							</div>
						</React.Fragment>
					)}
				</Offcanvas.Header>
				<Offcanvas.Body className={`clt_mybooking_offcanvas_body`}>
					{/* {accountScreens.accountsList && (
						<>
							<Row>
								<Col className="clt-account-screen">
									<h2 className="clt-account-screen-title">Profile</h2>
									<div
										className="d-flex justify-content-between align-items-start clt-account-screen-div"
										onClick={() => {
											if (selectProfile !== "admin") {
												handleBusinessInformationProfile()
											}
											else return null
										}
										}
									>
										<div className="d-flex flex-row justify-content-start align-items-start clt-account-screen-inner-div">
											<div className="clt-account-screen-inner-div-img">
												<div
													className="inner-div"
													style={
														AdminProfile.profile_picture_url && {
															backgroundImage: `url(${AdminProfile.profile_picture_url})`,
														}
													}
												>
												</div>
											</div>
											<div className="clt-account-screen-inner-div-text">
												<h2>BusinessInformation Profile</h2>
												<p>{AdminProfile.phone}</p>
												<p>{AdminProfile.email}</p>
												{isAddressEmpty(AdminProfile.business_address) ? (
													<p>Businessg Address: Required</p>
												) : (
													<p>{`${AdminProfile.business_address.address}, ${AdminProfile.business_address.zip_code} ${AdminProfile.business_address.city}`}</p>
												)}
												{isAddressEmpty(AdminProfile.billing_address) ? (
													<p>Billing Address: Required</p>
												) : (
													<p>{`${AdminProfile.billing_address.address}, ${AdminProfile.billing_address.zip_code} ${AdminProfile.billing_address.city}`}</p>
												)}
											</div>
										</div>
										<div className={`clt-account-screen-inner-div-i ${selectProfile === "admin" && ("d-none")}`}>
											<i className="fa-solid fa-chevron-right"></i>
										</div>
									</div>
								</Col>
							</Row>
							<CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
							
							
						</>
					)} */}
					{accountScreens.profileInformation && (
						<>
							<Row className="clt-account-section-offcanvas-row">
								<Col className="p-0">
									<Row>
										<Col>
											<h2 className="clt_admin_dash_timeslot_edit_box_heading_2">
												Profile information
											</h2>
											<p className="clt_admin_dash_timeslot_edit_box_subheading">
												Le profil du château est commun à tout les administrateurs et est utilisé lors d'envoie de message ou de notification vers un utilisateur. Il sert également à appraître sur le détail des factures.
											</p>
										</Col>
									</Row>
									<Row className="clt-detail-section-6-offcanvas-data-row m-0">
										<Col>
											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Name");
															setIsSidebarInputData({
																value: AdminProfile.name,
																placeholder: "Name",
																name: "name",
																type: "text",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Name</h2>
														<p>{AdminProfile.name}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Profile picture");
															setIsSidebarInputData({
																value: AdminProfile.profile_picture_url,
																placeholder: "Profile picture",
																name: "profile_picture_url",
																type: "file",
															});
															setIsProfilePicInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Profile picture</h2>
														<p>
															{AdminProfile.profile_picture_url ? (
																<img
																	className="clt-account-profile-pic"
																	src={AdminProfile.profile_picture_url}
																	alt={IMG_ALT}
																/>
															) : (
																"No profile picture"
															)}
														</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>
											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Phone");
															setIsSidebarInputData({
																value: AdminProfile.phone,
																placeholder: "Phone",
																name: "phone",
																type: "phone",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Phone</h2>
														<p>{AdminProfile.phone}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>
											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Email");
															setIsSidebarInputData({
																value: AdminProfile.email,
																placeholder: "Email",
																name: "email",
																type: "email",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Email</h2>
														<p>{AdminProfile.email}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Business address");
															setIsSidebarInputData({
																value: AdminProfile.business_address,
																placeholder: "Business address",
																name: "business",
																type: "business_address",
															});
															setIsAddressInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Business address</h2>
														{isAddressEmpty(AdminProfile.business_address) ? (
															<p>Required</p>
														) : (
															<p>{`${AdminProfile.business_address.address}, ${AdminProfile.business_address.zip_code} ${AdminProfile.billing_address.city}`}</p>
														)}
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Billing address");
															setIsSidebarInputData({
																value: AdminProfile.billing_address,
																placeholder: "Billing address",
																name: "billing",
																type: "billing_address",
															});
															setIsAddressInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Billing address</h2>
														{isAddressEmpty(AdminProfile.billing_address) ? (
															<p>Required</p>
														) : (
															<p>{`${AdminProfile.billing_address.address}, ${AdminProfile.billing_address.zip_code} ${AdminProfile.billing_address.city}`}</p>
														)}
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Commercial name");
															setIsSidebarInputData({
																value: AdminProfile.commercial_name,
																placeholder: "Commercial name",
																name: "commercial_name",
																type: "text",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Commercial name</h2>
														<p>{AdminProfile.commercial_name}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Capital social");
															setIsSidebarInputData({
																value: AdminProfile.capital_social,
																placeholder: "Capital social",
																name: "capital_social",
																type: "text",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Capital social</h2>
														<p>{AdminProfile.capital_social}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("Siret");
															setIsSidebarInputData({
																value: AdminProfile.siret,
																placeholder: "Siret",
																name: "siret",
																type: "text",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>Siret</h2>
														<p>{AdminProfile.siret}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

											<Row>
												<Col
													className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
													onClick={() => {
														if (selectProfile !== "admin") {
															setIsSidebarTitle("TVA number");
															setIsSidebarInputData({
																value: AdminProfile.tva_number,
																placeholder: "TVA number",
																name: "tva_number",
																type: "text",
															});
															setIsTextInputSidebar(true);
														}
														else return null
													}}
												>
													<div className="w-80">
														<h2>TVA number</h2>
														<p>{AdminProfile.tva_number}</p>
													</div>
													<i className="fa-solid fa-chevron-right"></i>
												</Col>
											</Row>

										</Col>
									</Row>
								</Col>
							</Row>
						</>
					)}
					{accountScreens.selectProfile && (
						<>
							<div>
								<Row>
									<Col className="clt-account-screen">
										<h2 className="clt-account-screen-title">Select a profile for your reservation</h2>
										<div
											className="d-flex justify-content-between align-items-start clt-account-screen-div"
											onClick={(e) => { e.stopPropagation(); setSelectedProfile("BusinessInformation"); setSelectedProfileData({ type: "BusinessInformation", detail: `${AdminProfile.phone} . ${AdminProfile.email}` }) }}
										>
											<div className="d-flex flex-row justify-content-start align-items-start clt-account-screen-inner-div">
												<div className="clt-account-screen-inner-div-img">
													<div
														className="inner-div"
														style={
															AdminProfile.profile_picture_url && {
																backgroundImage: `url(${AdminProfile.profile_picture_url})`,
															}
														}
													>
														{!AdminProfile.profile_picture_url && (
															<span>{AdminProfile.first_name?.charAt(0)}</span>
														)}
													</div>
												</div>
												<div className="clt-account-screen-inner-div-text">
													<h2>BusinessInformation Profile</h2>
													<p>
														{AdminProfile.name}
													</p>
													<p>{AdminProfile.commercial_name}</p>
													<p>{AdminProfile.capital_social}</p>
													<p>{AdminProfile.siret}</p>
													<p>{AdminProfile.tva_number}</p>
													<p>{AdminProfile.phone}</p>
													<p>{AdminProfile.email}</p>
													<>
														{isAddressEmpty(AdminProfile.business_address) ? (
															<p>Business Address: Required</p>
														) : (
															<p>{`${AdminProfile.business_address.address}, ${AdminProfile.business_address.zip_code} ${AdminProfile.billing_address.city}`}</p>
														)}
													</>

													<>
														{isAddressEmpty(AdminProfile.billing_address) ? (
															<p>Billing Address: Required</p>
														) : (
															<p>{`${AdminProfile.billing_address.address}, ${AdminProfile.billing_address.zip_code} ${AdminProfile.billing_address.city}`}</p>
														)}
													</>
													<p className="link" onClick={(e) => { e.stopPropagation(); handleEditBusinessInformationProfile() }}>Edit</p>
												</div>
											</div>
											<div className="clt-account-screen-inner-div-i">
												<div
													className="inner-div-circle"
													style={
														selectedProfile === "BusinessInformation"
															? {
																borderColor: `#000`,
															}
															: {
																borderColor: `#ddd`,
															}
													}
												>
													<div
														className="subDiv"
														style={
															selectedProfile === "BusinessInformation"
																? {
																	backgroundColor: `#000`,
																}
																: {
																	backgroundColor: `white`,
																}
														}
													></div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
								<CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

								<div className="pfr_payment_space_bottom" />
							</div>
							<div className="clt_payment_footer">
								<Button
									className="shadow-none clt_payment_header_button_black"
									disabled={selectedProfile == "" ? true : false}
									onClick={() => {

										selectProfile == "select" ?
											onCloseSidebar()
											: selectProfile == "update" ? handleBack("confirm", "selectProfile") : onCloseSidebar()
									}
									}
								>
									{selectProfile == "select" ?
										"Confirm Profile"
										: selectProfile == "update" ? "Enregistrer" : "Continuer"
									}

								</Button>
							</div>
						</>
					)}
					{accountScreens.confirm && (
						<ConfirmPay onClick={() => { onCloseSidebar(); setIsUserSidebar() }} />
					)}
				</Offcanvas.Body>
			</Offcanvas >

			<AccountInputText
				title={isSidebarTitle}
				inputData={isSidebarInputData}
				isSidebar={isTextInputSidebar}
				onCloseSidebar={() => setIsTextInputSidebar(false)}
				onUpdateData={(data) => {
					handleInputData(data);
				}}
			/>



			{/* <AccountInputPhone
				title={isSidebarTitle}
				inputData={isSidebarInputData}
				isSidebar={isPhoneInputSidebar}
				onCloseSidebar={() => setIsPhoneInputSidebar(false)}
				onUpdateData={(data) => {
					handleInputData(data)
				}}
			/>
			<AccountInputEmail
				title={isSidebarTitle}
				inputData={isSidebarInputData}
				isSidebar={isEmailInputSidebar}
				onCloseSidebar={() => setIsEmailInputSidebar(false)}
				onUpdateData={(data) => {
					handleInputData(data);
				}}
			/> */}
			<AccountInputAddress
				title={isSidebarTitle}
				inputData={isSidebarInputData}
				isSidebar={isAddressInputSidebar}
				onCloseSidebar={() => setIsAddressInputSidebar(false)}
				onUpdateData={(data) => {
					handleInputData(data);
				}}
			/>
			<AccountInputProfilePicture
				title={isSidebarTitle}
				inputData={isSidebarInputData}
				isSidebar={isProfilePicInputSidebar}
				onCloseSidebar={() => setIsProfilePicInputSidebar(false)}
				onUpdateData={(data) => {
					handleUploadProfilePic(data.value);
				}}
			/>
		</>
	);
};

