import axios from "axios";
import { API_ENDPOINT } from "../../../../../constants";

const API_URL = `${API_ENDPOINT}/helpcenter`;

export const createCategory = (categoryName, title, subtitle) => {
  return axios
    .post(`${API_URL}/category`, { name: categoryName, title, subtitle })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const getAllCategories = () => {
  return axios
    .get(`${API_URL}/category`)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

export const deleteCategory = (categoryId) => {
  return axios
    .delete(`${API_URL}/category/${categoryId}`)
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const updateCategory = (categoryId, category) => {
  return axios
    .put(`${API_URL}/category/${categoryId}`, { ...category })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const createArticle = (article) => {
  return axios
    .post(`${API_URL}/category/articles`, { ...article })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const getAllArticles = (id) => {
  return axios
    .get(`${API_URL}/category/articles/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const getArticleById = (id) => {
  return axios
    .get(`${API_URL}/category/articles/single/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const updateArticle = (id, article) => {
  return axios
    .put(`${API_URL}/category/articles/${id}`, { ...article })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const updateArticleContent = (id, content) => {
  return axios
    .put(`${API_URL}/content/articles/content/${id}`, { ...content })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const createArticleContent = (content) => {
  return axios
    .post(`${API_URL}/content/articles/content`, { ...content })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
export const deleteArticleContent = (id) => {
  return axios
    .delete(`${API_URL}/content/articles/content/${id}`)
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const deleteArticle = (id) => {
  return axios
    .delete(`${API_URL}/category/articles/${id}`)
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const createPath = (path) => {
  return axios
    .post(`${API_URL}/category/paths`, { ...path })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
export const getAllPaths = (id) => {
  return axios
    .get(`${API_URL}/category/paths/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
export const deletePath = (id) => {
  return axios
    .delete(`${API_URL}/category/paths/${id}`)
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
export const updatePath = (id, path) => {
  return axios
    .put(`${API_URL}/category/paths/${id}`, { ...path })
    .then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
export const getAllPathByParentPathId = (id) => {
  return axios
    .get(`${API_URL}/category/paths/subpaths/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
