import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import icon from '../../../assets/img/icon_euro.png';
const SectionRemainingPayment = ({
  image,
  title,
  paymentTexts,
  onClick,
  buttonText,
    isLoading
}) => {
  return (
    <Row>
      <Col onClick={onClick} className="clt-booking-cp-div4">
        {/* <div className="d-flex justify-content-start align-items-center clt-booking-cp-div3">
        <i class="fa-solid fa-euro-sign" style={{fontSize:'24px'}}></i>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>{title}</h2>
              {paymentTexts?.map((paymentText, index) => {
                return <p key={index}>{paymentText}</p>;
              })}
            </div>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div> */}
        <div
            className="clt-booking-cp-div1Box clt-booking-hoverActive"
          >
            <div className="d-flex align-items-center justify-content-between flex-row w-100">
            <div className="clt-booking-div1"  style={{marginLeft:0,width:'calc(100% - 10px)'}}>
                <div className="clt-booking-innner-div1 clt-booking-bgImg2"></div>
                <div className="inner-div-1 clt-booking-cp-div3-text"  style={{marginLeft:0,width:'calc(100% - 40px)'}}>
                    {/*{!isLoading?<h2>{title}</h2>:<h2>Please Wait</h2>}*/}
                    <h2>{title}</h2>

              {paymentTexts?.map((paymentText, index) => {
                return <p key={index} style={{overflow:'hidden',whiteSpace:'nowrap',overflowWrap:'break-word'}}>{paymentText}</p>;
              })}
                </div>
              </div>
              <div>
                 <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
      </Col>
    </Row>
  );
};

export default SectionRemainingPayment;
