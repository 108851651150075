// Import
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Nav, Navbar as HomeNavbar, Offcanvas, Row, } from "react-bootstrap";
import { API_ENDPOINT, IMG_ALT } from "../constants";
import { ToastContainer } from "react-toastify";
import { NavLink, useLocation } from "react-router-dom";
import NavbarHelpCenter from "../components/layout/navbar";
import { AuthContext, useAuth } from "../components/screens/auth/authContext";
import { auth } from "../components/screens/auth/firebase";
import DropdownButton from "react-bootstrap/DropdownButton";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import { useHistory } from "react-router-dom";
// Import - Icon / Image
import cancel_icon from "../assets/img/cancel_icon.png";
import cancel_icon_white from "../assets/img/cancel_icon_white.png";

import MenuBlack from "../assets/img/menu_black.png";
import wall_color from "../assets/img/wall-color.png";
import wall_color2 from "../assets/img/wall-color2.png";
import Logoclt from "../assets/img/Logoclt.png";

// Import - Screen - Auth
import AuthUser from "../components/screens/auth/AuthUser";
import AuthModal from "../components/screens/auth/AuthModal";

// Import - Screen - Account
import Account from "../components/screens/account/Account";

// Import - Screen - Payment method
import Payment from "../components/screens/payment/Payment";

// Import - Screen - Notification
import Notification from "../components/screens/notification/user_notification";

// Import - Screen - My booking
import MyBooking from "../components/screens/myBooking/user_mybooking";
import { getCards, getClientSecret } from "../redux/actions/booking";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { io } from "socket.io-client";
import axios from "axios";
import { SuccessToast, ErrorToast } from "../components/reusable/Toast";



/* Const */
const Navbar = ({ page }) => {

	const history = useHistory();
	//handle notification
	const { isNewNotification, setNewNotification } = useContext(AuthContext);

	/* Const - Sidebar */
	const [isSideBar, setIsSideBar] = useState(false);

	const openSideBar = () => {
		setIsSideBar(true);
	};

	const [showAnnouncement, setShowAnnouncement] = useState(false);

	useEffect(() => {
		// Check if the current location is the home page
		if (window.location.pathname === '/') {
		setShowAnnouncement(true);
		} else {
		setShowAnnouncement(false);
		}
	}, []);

	const closeAnnouncement = () => {
		setShowAnnouncement(false);
	};

	/* Const - Screen */

	/* Const - Screen - Auth */
	const [isLoginModal, setIsLoginModal] = useState(false);
	const { isLoggedIn, user } = useAuth();
	const logOut = (e) => {
		auth.signOut();
		localStorage.removeItem('selectedUserId');

		localStorage.removeItem("userToken");
		localStorage.removeItem("name");
		localStorage.removeItem('user-login-in')
		history.push(`/`);
		setIsSideBar(false);
		SuccessToast('Deconnecté de votre compte');
	};
	const toggleAuthModal = () => {
		setIsLoginModal((prevState) => !prevState);
		setIsSideBar(false);
	};


	/* Const - Screen - Account */
	const [isAccountDrawer, setIsAccountDrawer] = useState(false);
	const openAccountDrawer = (e) => {
		setIsAccountDrawer(true);
		setIsSideBar(false);
	};

	const closeAccountDrawer = (e) => {
		setIsAccountDrawer(false);
		setIsSideBar(false);
	};
	const [name, setName] = useState("");


	/* Const - Screen - Payment method */
	const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);

	const dispatch = useDispatch()
	const onOpenPaymentSidebar = () => {
		dispatch(getCards(1))
		dispatch(getClientSecret())
		setIsPaymentSidebar(true);
		setIsSideBar(false);
	};
	const onClosePaymentSidebar = () => {
		setIsPaymentSidebar(false);
		setIsSideBar(false);
	};

	const [currentClassName, setCurrentClassName] = useState('pfr_drawermenuitem15');

	/* Const - Screen - Notification */
	const [isNotificationSidebar, setIsNotificationSidebar] = useState(false);
	const onOpenNotificationSidebar = () => {
		setIsNotificationSidebar(true);
		setIsSideBar(false);
		setNewNotification(false);
	};
	const onCloseNotificationSidebar = () => {
		setIsNotificationSidebar(false);
		setNewNotification(false);
		if (param && hel) {
			setIsSideBar(false);
		} else {
			setIsSideBar(false);
		}
	};


	/* Const - Screen - My booking */
	const [isOpenSidebarMyBookingUser, setsetIsSidebarMyBookingUser] = useState(false);
	const onOpenSidebarMyBookingUser = () => {
		setsetIsSidebarMyBookingUser(true);
		setIsSideBar(false);

	};

	const onCloseOpenSidebarMyBookingUser = () => {
		setsetIsSidebarMyBookingUser(false);
		setIsSideBar(false);
	};

	/* Const - Screen - Help center */
	const [helpCenter, setHelpCenter] = useState(false);
	const [helpCenterTitle, setHelpCenterTitle] = useState("Path 1");
	const location = useLocation();

	/* UseEffect */
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const param = searchParams.get("notification");
	const paramBooking = searchParams.get("booking");
	const hel = searchParams.get('auth');


	const [notificationsData, setNotificationData] = useState([]);
	const classNames = ['pfr_drawermenuitem15', 'pfr_drawermenuitem3', 'pfr_drawermenuitem4', 'pfr_drawermenuitem5'];




	const getNotifications = async () => {


		try {
			const token = await auth.currentUser.getIdToken();
			const response = await axios.get(API_ENDPOINT + "/notifications",
				{ headers: { Authorization: `Bearer ${token}` } }
			);
			console.log(response.data);
			setNotificationData(response.data.data);

			const notificationData = response?.data?.data;

			const notificationLenght = parseInt(response?.data?.count);
			localStorage.setItem('unreadNotification', notificationLenght);
			const count = parseInt(localStorage.getItem('unreadNotification')) - parseInt(localStorage.getItem('newNotification'));
			localStorage.setItem('newNotificationCount', count);
			const newNotificationCount = parseInt(localStorage.getItem('newNotificationCount'));

			if (newNotificationCount > 0) {
				setNewNotification(true);
			}



			// SuccessToast(response.data.message);
		} catch (error) {

		}

	}




	useEffect(() => {
		const newNotification = localStorage.getItem('newNotification');
		getNotifications();

		if (newNotification === null) {
			localStorage.setItem('newNotification', '0');
		}
		if (param && hel) {
			setIsNotificationSidebar(true);

		}
		if (paramBooking && hel) {
			setsetIsSidebarMyBookingUser(true);
		}
	}, [param, paramBooking, hel, isLoggedIn, user, setNewNotification, isNewNotification]);
	

	useEffect(() => {
		const intervalId = setInterval(() => {
		// Rotate through the classNames array
		const currentIndex = classNames.indexOf(currentClassName);
		const nextIndex = (currentIndex + 1) % classNames.length;
		setCurrentClassName(classNames[nextIndex]);
		}, 1200);

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId);
	}, [currentClassName, classNames]);

	/* UseEffect - Help center - Path */
	useEffect(() => {
		let currentLocation = location.pathname.split("/");
		if (
			currentLocation.includes("variation-3") ||
			currentLocation.includes("variation-2")
		) {
			if (currentLocation.includes("variation-3")) {
				setHelpCenterTitle("Help Article");
			}
			setHelpCenter(true);
		}
		// if (isLoggedIn == false && currentLocation?.includes("inbox")) {
		// 	history.push(`/`);
		// }

	}, [location.pathname]);

	const [isLoadingName, setIsLoadingName] = useState(true);

	useEffect(() => {
		// Listen for 'notificationSent' event from the server
		const socket = io(); // Initialize the socket connection
		socket.on('notificationSent', ({ message }) => {
			// Check if the notification is unread, then update the state and store in local storage
			if (!isNewNotification) {
				setNewNotification(true);

			}

		});


		if (isLoggedIn && user) {
			setIsLoadingName(true);
			console.log("running");
			setName(user?.firstName);
			setIsLoadingName(false);
		}
		else if (isLoggedIn) {
			setName("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, user]);

	if (helpCenter) {
		return <NavbarHelpCenter backBtn title={helpCenterTitle} />;
	}

	return (
		<React.Fragment>
			{showAnnouncement && (
  <div className="announcement-bar" style={{ backgroundColor: '#9e6403', backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Container style={{ padding: '0 24px', width: '100%' }}>
      <div className="row align-items-center">
        <div className="text-center" style={{ flex: '1' }}>
          <div className="announcement-text-container">
            <p className="announcement-text">Bienvenue au Château Latournelle!</p>
          </div>
        </div>
        <div className="text-end" style={{ flex: '0' }}>
          <div className="close-button-container" style={{ marginLeft: 'auto' }}>
            <button
              type="button"
              className="custom-icon-button shadow-none d-flex"
              aria-label="Close"
              onClick={closeAnnouncement}
              style={{ background: 'none', border: 'none' }}
            >
              <img src={cancel_icon_white} style={{ width: '14px' }} alt="Your Custom Icon" />
            </button>
          </div>
        </div>
      </div>
    </Container>
  </div>
)}




				  






				  



			{/* Navbar */}
			<HomeNavbar className="pfr_navbarNav">

				{/* Navbar - Container */}
				<Container fluid className="pfr_innerNav_container">

					{/* Navbar - Row */}
					<Row className="pfr_navbarRow">

						{/*  Navbar - Button menu */}
						<Col
							lg={6}
							md={6}
							sm={6}
							xs={6}
							className="d-flex justify-content-start"
						>
							<div className="pfr_navbarToggle" onClick={openSideBar}>
								<img src={MenuBlack} className="" alt={IMG_ALT} />
							</div>
							<div className="pfr_navbarToggle1" href="/" onClick={openSideBar}>
								<img src={Logoclt} className="" alt={IMG_ALT} />
							</div>
							{/*<HomeNavbar.Brand  className="d-flex pfr_navbarLogo">
								{page}
							</HomeNavbar.Brand>*/}
						</Col>

						{/*  Navbar - Button User */}
						<Col
							lg={6}
							md={6}
							sm={6}
							xs={6}
							className="d-flex justify-content-end"
						>
							<>
								{localStorage.getItem('user-login-in') ? (
									<HomeNavbar.Text className="d-flex pfr_navbarDropDownMenu" key="loggedInText">
										<Row>
											<Col
												lg={12}
												md={12}
												sm={12}
												xs={12}
											>
												<DropdownButton
													size="sm"
													align="end"
													// title={name}
													title={
														isLoadingName ? (
															<div className="d-flex align-items-center justify-content-center" style={{ height: '22px' }}>
																<LoaderDots theme="muted" size="small" />
															</div>
														) : (
															<span>{name}</span>
														)
													}
												>
													<p className="pfr_drawermenuHead2">Hello {name}</p>


													<div onClick={onOpenNotificationSidebar} className="pfr_drawermenuitem">
														<div className="pfr_drawermenuitemdiv">
															<div className={isNewNotification ? "pfr_drawermenuitem12Active" : "pfr_drawermenuitem12"}></div>
															<span>Notification</span>
														</div>
													</div>

													<div
														onClick={onOpenSidebarMyBookingUser}
														className="pfr_drawermenuitem"
													>
														<div className="pfr_drawermenuitemdiv">
															<div className="pfr_drawermenuitem8"></div>
															<span>Mes réservations</span>
														</div>
													</div>

													<NavLink
														to="/inbox"
														onClick={() => setIsSideBar(false)}
														className={`pfr_drawermenuitem ${page === "Message" ? "activeNav" : ""
															}`}
													>
														<div className="pfr_drawermenuitemdiv">
															<div className="pfr_drawermenuitem7"></div>
															<span>Message</span>
														</div>
													</NavLink>

													<div onClick={openAccountDrawer} className="pfr_drawermenuitem">
														<div className="pfr_drawermenuitemdiv">
															<div className="pfr_drawermenuitem6"></div>
															<span>Profile</span>
														</div>
													</div>

													<div
														onClick={onOpenPaymentSidebar}
														className="pfr_drawermenuitem"
													>
														<div className="pfr_drawermenuitemdiv">
															<div className="pfr_drawermenuitem9"></div>
															<span>Moyen de paiement</span>
														</div>
													</div>

													{isLoggedIn ? (
														<div onClick={logOut} className="pfr_drawermenuitem">
															<div className="pfr_drawermenuitemdivred">
																<div className="pfr_drawermenuitem11"></div>
																<span>Déconnexion</span>
															</div>
														</div>
													) : null}
												</DropdownButton>
											</Col>
										</Row>
									</HomeNavbar.Text>
								) : (
									<HomeNavbar.Text className="d-flex pfr_navbarRightTxt">
										<span key="loggedOutText" onClick={toggleAuthModal}>
											Connexion
										</span>
										<span key="loggedOutText2" onClick={toggleAuthModal}>
											Inscription
										</span>
									</HomeNavbar.Text>
								)}
							</>
						</Col>
					</Row>

					<Offcanvas
						show={isSideBar}
						onHide={() => setIsSideBar(false)}
						placement="start"
						className="pfr_navOffCanvas"
					>
						{isLoggedIn ? (
							<Offcanvas.Header
								key="pfr_navuseractive"
								className="pfr_navuseractive"
							>
								<span>
									{name}
									{/* <i className="fas fa-chevron-down"></i> */}
								</span>
							</Offcanvas.Header>
						) : (
							<Offcanvas.Header
								key="pfr_navuseractive"
								className="pfr_navusernotactive"
							>
								<Button className="shadow-none" onClick={toggleAuthModal}>
									Connexion
								</Button>
								<Button className="shadow-none" onClick={toggleAuthModal}>
									Inscription
								</Button>
							</Offcanvas.Header>
						)}

						<Offcanvas.Body className="pfr_navOffCanvasBody">
							<Nav className="justify-content-end flex-grow-1">
								<p className="pfr_drawermenuHead">Accueil</p>
								<NavLink
									to="/"
									onClick={() => setIsSideBar(false)}
									className={`pfr_drawermenuitem ${page === "Accueil" ? "activeNav" : ""
										}`}
								>
									<div className="pfr_drawermenuitemdiv">
										<div className="pfr_drawermenuitem1"></div>
										<span>Accueil</span>
									</div>
								</NavLink>
								
								<hr className="hr" />
								
								<p className="pfr_drawermenuHead">Évenement</p>
								<NavLink to="/detail/Mariage" className={`pfr_drawermenuitem`}>
									<div className="pfr_drawermenuitemdiv">
										<div className={`${currentClassName}`}></div>
										<span>Détail des événements</span>
									</div>
								</NavLink>
								<NavLink to="/detail/Mariage" className="pfr_drawermenuitem">
									<div className="pfr_drawermenuitemdiv">
										<div className="pfr_drawermenuitem14"></div>
										<span>Réserver</span>
									</div> 
								</NavLink>
								{localStorage.getItem('user-login-in') && !name ? (
									<>
										<hr className="hr" />
										<p className="pfr_drawermenuHead">Espace utilisateur</p>
										<div className="d-flex align-items-center justify-content-center" style={{ height: '40vh' }}>
											<LoaderDots theme="muted" />
										</div>
									</>
								) : isLoggedIn ? (
									<>
										<hr className="hr" />
										<p className="pfr_drawermenuHead">Espace utilisateur</p>
										{/* { isLoadingName ? (
									<>
										<hr className="hr" />
										<p className="pfr_drawermenuHead">Espace utilisateur</p>
        								<div className="d-flex align-items-center justify-content-center" style={{ height: '40vh' }}>
            								<LoaderDots theme="muted" />
        								</div>
									</>
								): (<> */}
										<div onClick={onOpenNotificationSidebar} className="pfr_drawermenuitem">
											<div className="pfr_drawermenuitemdiv">
												<div className={isNewNotification ? "pfr_drawermenuitem12Active" : "pfr_drawermenuitem12"}></div>
												<span>Notification</span>
											</div>
										</div>

										<div
											onClick={onOpenSidebarMyBookingUser}
											className="pfr_drawermenuitem"
										>
											<div className="pfr_drawermenuitemdiv">
												<div className="pfr_drawermenuitem8"></div>
												<span>Mes réservations</span>
											</div>
										</div>

										<NavLink
											to="/inbox"
											onClick={() => setIsSideBar(false)}
											className={`pfr_drawermenuitem ${page === "Message" ? "activeNav" : ""
												}`}
										>
											<div className="pfr_drawermenuitemdiv">
												<div className="pfr_drawermenuitem7"></div>
												<span>Message</span>
											</div>
										</NavLink>

										<div onClick={openAccountDrawer} className="pfr_drawermenuitem">
											<div className="pfr_drawermenuitemdiv">
												<div className="pfr_drawermenuitem6"></div>
												<span>Profile</span>
											</div>
										</div>

										<div
											onClick={onOpenPaymentSidebar}
											className="pfr_drawermenuitem"
										>
											<div className="pfr_drawermenuitemdiv">
												<div className="pfr_drawermenuitem9"></div>
												<span>Moyen de paiement</span>
											</div>
										</div>

										<div onClick={logOut} className="pfr_drawermenuitem">
											<div className="pfr_drawermenuitemdivred">
												<div className="pfr_drawermenuitem11"></div>
												<span>Déconnexion</span>
											</div>
										</div>
										{/* </>)} */}
									</>
								) : (
									<>
										<hr className="hr" />
										<p className="pfr_drawermenuHead">Espace utilisateur</p>
										<div onClick={toggleAuthModal} className="clt_left_menu_section_user_divContent">
											<div className="pfr_drawermenuitemdivs clt_left_menu_section_user_divLogin">
												<div className="clt_left_menu_section_user_span clt_left_menu_section_user_divText">
													<h6 style={{ marginBottom: '0px' }}>Connexion/Inscription</h6>
													<span className="clt_left_menu_section_user_span">Crée un compte pour gérer votre réservation, message et plus encore !</span>
												</div>
												<div className="clt_left_menu_section_user_divLogin_img pfr_drawermenuitemdiv-div"></div>

											</div>
										</div>


									</>)}
								<hr className="hr" />

								<p className="pfr_drawermenuHead">Aide</p>

								<NavLink
									to="/aide"
									onClick={() => setIsSideBar(false)}
									className={`pfr_drawermenuitem ${page === "Help center" ? "activeNav" : ""
										}`}
								>
									<div className="pfr_drawermenuitemdiv">
										<div className="pfr_drawermenuitem10"></div>
										<span>Centre aide</span>
									</div>
								</NavLink>
							</Nav>
						</Offcanvas.Body>
					</Offcanvas>
				</Container>
			</HomeNavbar>

			<AuthUser />

			<AuthModal
				toggleAuthModal={toggleAuthModal}
				isLoginModal={isLoginModal}
				// variant can have two possible type either first or second. (first: sidebar/user can close it at anytime) (second: full screen/user must authenticate to close the screens)
				variant={(param || paramBooking) && hel ? "second" : "first"}
				authenticationFlow={(param || paramBooking) && hel ? jwt_decode(hel) : null}
			// variant={"first"}
			// variant="second"
			/>

			<ToastContainer className="toast-container" />

			{isLoggedIn ? (
				<Account
					isOpenSidebar={isAccountDrawer}
					onCloseSidebar={() => closeAccountDrawer()}
				/>
			) : null}

			<Payment
				isPaymentSidebar={isPaymentSidebar}
				onClosePaymentSidebar={() => onClosePaymentSidebar()}
			/>

			{isNotificationSidebar ? <Notification
				getNotiId={param ? param : null}
				hel={hel ? hel : null}
				isNotificationSidebar={isNotificationSidebar}
				onCloseNotificationSidebar={() => onCloseNotificationSidebar()}
				setIsAccountDrawer={() => toggleAuthModal()}
			/> : null}

			{isOpenSidebarMyBookingUser ? <MyBooking
				getBookingId={paramBooking ? paramBooking : null}
				hel={hel ? hel : null}
				isOpenSidebarMyBookingUser={isOpenSidebarMyBookingUser}
				onCloseOpenSidebarMyBookingUser={() => onCloseOpenSidebarMyBookingUser()}
				setIsAccountDrawer={() => toggleAuthModal()}
			/> : null}

		</React.Fragment>
	);
};
export default Navbar;
