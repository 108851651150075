import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navbar from './user_navbar';

const PublicPageNotFoundLayout = ({ children, header }) => (
  <Container fluid style={{ paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}>
    {/* <Navbar page={header ? header : "Château la tournelle"} /> */}
    {children}
  </Container>
);

const PublicPageNotFoundRoute = ({ setIsOpen, header, component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <PublicPageNotFoundLayout header={header}>
        <Component {...matchProps} setIsOpen={setIsOpen} />
      </PublicPageNotFoundLayout>
    )} />
  )
};

export default PublicPageNotFoundRoute;