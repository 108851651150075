import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Offcanvas } from "react-bootstrap";
import "./auth-modal.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ReactCodeInput from "react-verification-code-input";
import BackArrow from "../../../assets/img/back_arrow.png";
import { API_ENDPOINT, IMG_ALT } from "../../../constants";
import { useAuth } from "./authContext";
import axios from "axios";
import { ErrorToast, SuccessToast } from '../../reusable/Toast'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomButton from "../../reusable/CustomButton"; // Import the CustomButton component
const COUNTER = 30;

function ForgotPassword(props) {
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [passwordVisible, setpasswordVisible] = useState(false);
	const togglePasswordVisibility = () => {
		setPasswordVisibility((v) => !v);
	};
	const togglePasswordVisible = () => {
		setpasswordVisible((v) => !v);
	};
	// // constructor(props) {
	//     super(props);
	//     state = {
	//         isforgotpassby: 'email',
	//         isForgotPassStep: 1,
	//     };
	// }
	const { email } = useAuth();

	const [data, setData] = useState({
		email,
		password: "",
		confirm_password: "",
	});
	const [code, setCode] = useState("");
	const [state, setState] = useState({
		isforgotpassby: "email",
		isForgotPassStep: 1,
	});

	// const onChangeLoginType = (type) => {
	// 	if (type !== "email" || type !== "phone") return;
	// 	setState((s) => ({ ...s, isforgotpassby: type }));
	// };

	const handleChange = (e) => {
		setData((d) => ({ ...d, [e.target.name]: e.target.value }));
	};

	const backForgotPass = () => {
		setState((prevState) => {
			if (prevState.isForgotPassStep > 1) {
				return {
					...prevState,
					isForgotPassStep: prevState.isForgotPassStep - 1,
				};
			} else {
				return { ...prevState, isForgotPassStep: 1 };
			}
		});
		if (state.isForgotPassStep <= 1) {
			props.forgotPass();
		}
	};

	const continueForgotPass = () => {
		setState((prevState) => {
			if (prevState.isForgotPassStep < 3) {
				return {
					...prevState,
					isForgotPassStep: prevState.isForgotPassStep + 1,
				};
			}
			return prevState;
		});
	};

	const handleStep1 = async () => {
		try {
			const response = await axios.post(API_ENDPOINT + "/auth/forget-password", {
				email: data.email,
			});
			localStorage.setItem("forgetpasswordtoken", response.data.token);
			continueForgotPass();
		} catch (error) {
			ErrorToast(error?.response?.data?.message)
		}
	};

	const handleStep2 = async () => {
		const forgotPasswordToken =
			localStorage.getItem("forgetpasswordtoken") ?? "";
		if (!forgotPasswordToken) return;
		try {
			const response = await axios.post(
				API_ENDPOINT + `/auth/verify-code/${forgotPasswordToken}`,
				{ code }
			);
			localStorage.removeItem("forgetpasswordtoken");
			localStorage.setItem("resetpasswordtoken", response.data.token);
			continueForgotPass();
		} catch (error) {
			ErrorToast(error?.response?.data?.message)
		}
	};

	const contains = (characters,value = "", count = 1) => {
		const findCount = 	value
				.split("")
				.map((char, i) => (characters.includes(char) ? true : false))
				.filter((val) => val).length
		console.log({characters, findCount, value})
		return findCount >= count;
	};

	const handleStep3 = async () => {
		if (data.password !== data.confirm_password) {
			ErrorToast("passwords dont match");
			return;
		}

		const letters = 'abcdefghijklmnopqrstuvwxyz'
		const special = "!~@`#$%^&*(_)+=/?.,}{[]|-><"
		const numbers = "1234567890"

		const isValid =
			data.password.length >= 8 &&
			contains(letters.toLowerCase(), data.password, 1) &&
			contains(letters.toUpperCase(), data.password, 1) &&
			contains(special,data.password, 1) &&
			contains(numbers, data.password, 1);

		if (!isValid) {
			console.log("validation error");
			ErrorToast("Mot de passe incomplet", "Les critères obligatoire pour un mot de passe doivent être respectés.  ");

			return;
		}

		const token = localStorage.getItem("resetpasswordtoken");
		try {
			const response = await axios.post(
				`${API_ENDPOINT}/auth/reset-password/${token}`,
				{ password: data.password }
			);
			SuccessToast(response.data.message);
			props.forgotPass();
		} catch (error) {
			ErrorToast(error.message)
		}
	};
	
		const [isCompleted, setIsCompleted] = useState(false);
	const counterRef = useRef(null);
	const [counter, setCounter] = useState(COUNTER)

	useEffect(() => {
		setCounter(COUNTER);
		counterRef.current = COUNTER
	}, []);


	useEffect(() => {
		const timer = setInterval(() => {
			if (state.isForgotPassStep === 2) {
				if (counterRef.current === 0) {
					console.log(counter)
					setCounter(COUNTER)
					counterRef.current = COUNTER
					setIsCompleted(true)
					clearInterval(timer);
					return;
				}		
				if (!isCompleted) {
					console.log('counter')
					counterRef.current--;
					setCounter(counterRef.current);
				} 
				// else {
				// 	setCounter(COUNTER);
				// 	console.log('comming');
				// 	counterRef.current = COUNTER;
				// } 
			}			
		}, 1000);
		return () => clearInterval(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCompleted, state.isForgotPassStep]);

	const sendCodeAgain = async () => {
		if (!isCompleted) return;
		try {
			const response = await axios.post(API_ENDPOINT + "/auth/forget-password", {
				email: data.email,
			});
			SuccessToast("Le code a été renvoyé");
			localStorage.setItem("forgetpasswordtoken", response.data.token);
			setIsCompleted(false)
		} catch (error) {
			ErrorToast(error.message)
		}
	}

	return (
		<React.Fragment>
			<Offcanvas.Header className="justify-content-start clt_auth_user_offcanvas_header">
				{state.isForgotPassStep === 3 ? (
					<button
						type="button"
						onClick={props.toggleAuthModal}
						className="btn-close btn-back shadow-none d-flex"
						aria-label="Close"
					>
						<img src={BackArrow} className="" alt={IMG_ALT} />
					</button>
				) : (
					<button
						type="button"
						onClick={backForgotPass}
						className="btn-close btn-back shadow-none d-flex"
						aria-label="Close"
					>
						<img src={BackArrow} className="" alt={IMG_ALT} />
					</button>
				)}
				<Offcanvas.Title>
					{state.isForgotPassStep === 1 ? (
						<>Mot de passe oublié</>
					) : state.isForgotPassStep === 2 ? (
						<>Code de vérification</>
					) : state.isForgotPassStep === 3 ? (
						<>Mot de passe oublié</>
					) : (
						""
					)}
				</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body className="clt_auth_user_offcanvas_body">
			<ToastContainer className="toast-container" />
				{state.isForgotPassStep === 1 && (
					<div className="pfr_loginModalVerify">
						<div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
							<div></div>
							<Row>
								<Col>
									<h2 className="pfr_loginModalH2">Mot de passe oublié</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<p className="pfr_loginModalp">
									Entrez votre adresse email et nous vous enverrons un
									code pour réinitialiser votre mot de passe
									</p>
								</Col>
							</Row>
							{state.isforgotpassby === "phone" && (
								<Row>
									<Col className="pfr_loginModalTelInputDiv">
										<div className="pfr_loginModalTelInputInnerDiv">
											<PhoneInput
												// placeholder="Numéro de téléphone"
												international
												withCountryCallingCode
												defaultCountry="FR"
												countryCallingCodeEditable={false}
												value={props.userphone}
												onCountryChange={(crt) => console.log("crt ", crt)}
												focusInputOnCountrySelection
												autoFocus
												onChange={(val) =>
													props.inputHandleChange(val, "userphone")
												}
												className="pfr_loginModalTelInput"
											/>
											<span className="pfr_loginModalTelInputLabel toFloat">
												Numéro de téléphone
											</span>
										</div>
									</Col>
								</Row>
							)}
							{state.isforgotpassby === "email" && (
								<Row>
									<Col className="pfr_loginModalEmailDiv">
										<Row>
											<Col className="pfr_inputFloatDiv">
												<Form.Floating className="pfr_inputFloat">
													<Form.Control
														id="floatingInput1"
														type="email"
														placeholder="Adresse e-mail"
														name="email"
														value={data.email}
														onChange={handleChange}
													/>
													<label htmlFor="floatingInput1">Adresse e-mail</label>
												</Form.Floating>
											</Col>
										</Row>
									</Col>
								</Row>
							)}
						</div>
						{/* <Row>
                                <Col className='pfr_loginModalSeparatorDiv'>
                                    <div className='pfr_loginModalSeparator' />
                                    <span>OU</span>
                                    <div className='pfr_loginModalSeparator' />
                                </Col>
                            </Row>
                            <div>
                                <Row>
                                    <Col className='pfr_loginModalBottomBtnCol'>
                                        {state.isforgotpassby === 'phone' &&
                                            <Row>
                                                <Col>
                                                    <Button className='pfr_loginModalIconBtn shadow-none' onClick={() => onChangeLoginType('email')}>
                                                        <img src={require('../../../assets/img/icon_email.png')}
                                                            className=""
                                                            alt={IMG_ALT} />
                                                        <span>Réinitialiser avec Adresse e-mail</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        {state.isforgotpassby === 'email' &&
                                            <Row>
                                                <Col>
                                                    <Button className='pfr_loginModalIconBtn shadow-none' onClick={() => onChangeLoginType('phone')}>
                                                        <img src={require('../../../assets/img/icon_phone.png')}
                                                            className=""
                                                            alt={IMG_ALT} />
                                                        <span>Réinitialiser avec un téléphone</span>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            </div> */}

						<div className="pfr_loginModalVerifyDiv">
							<Row className="pfr_loginModalSeparatorDiv">
								<div className="pfr_loginModalSeparator" />
							</Row>
							<div className="pfr_loginModal_footer">
								<Col>
									<Button
										className="pfr_loginModalBtn shadow-none"
										onClick={handleStep1}
									>
										Envoyer code
									</Button>
								</Col>
							</div>
						</div>
					</div>
				)}
				{state.isForgotPassStep === 2 && (
					<div className="pfr_loginModalVerify">
						<div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
							<Row>
								<Col>
									<h2 className="pfr_loginModalH2">
										Entrez le code envoyé à {data.email}
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<ReactCodeInput
										type="number"
										fields={6}
										onChange={(val) => setCode(val)}
										onComplete={(val) => console.log(val)}
										autoFocus={true}
										// loading
										className="pfr_signUpNumberVerify"
									/>
								</Col>
							</Row>
							{/* <Row>
								<Col>
									<div style={{ opacity: !isCompleted ? .6 : 1 }} className="d-flex justify-content-start clt-account-timer-msg pfr_loginForgotPassDiv">
										<span  onClick={sendCodeAgain}>
											{!isCompleted ? `Je n'ai pas reçu de code (0:${counter})` : `Je n'ai pas reçu de code`}
											</span>
									</div>
								</Col>
								</Row> */}
									
							<Row>
								<Col>
									<div style={{ opacity: !isCompleted ? 0.6 : 1 }} className="d-flex justify-content-start clt-account-timer-msg pfr_loginForgotPassDiv">
									<CustomButton
										onClick={sendCodeAgain}
										disabled={!isCompleted}
										text={!isCompleted ? `Je n'ai pas reçu de code (0:${counter})` : `Je n'ai pas reçu de code`}
									/>
									</div>
								</Col>
							</Row>
						</div>
						<div className="pfr_loginModalVerifyDiv">
							<Row className="pfr_loginModalSeparatorDiv">
								<div className="pfr_loginModalSeparator" />
							</Row>
							<div className="pfr_loginModal_footer">
								<Col>
									<Button
										className="pfr_loginModalBtn shadow-none"
										onClick={handleStep2}
									>
										Continuer
									</Button>
								</Col>
							</div>
						</div>
					</div>
				)}
				{state.isForgotPassStep === 3 && (
					<div className="pfr_loginModalVerify">
						<div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
							<Row>
								<Col>
									<h2 className="pfr_loginModalH2">
										Réinitialiser mot de passe
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<p className="pfr_loginModalp">
										Minimum 8 caractères (au moins une lettre minuscule, une
										lettre majuscule, un caractère spécial et un chiffre)
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="pfr_inputFloatDiv">
									<Form.Floating className="pfr_inputFloat">
										<Form.Control
											id="floatingInput2"
											type={!passwordVisibility ? "password" : "text"}
											placeholder="Nouveau mot de passe"
											name="password"
											value={data.password}
											onChange={handleChange}
										/>
										<label htmlFor="floatingInput2">Nouveau mot de passe</label>
										<img
											src={
												require(`../../../assets/img/${
													passwordVisibility
														? "icon_pass_hide.png"
														: "icon_pass_show.png"
												}`)
											}
											className="pfr_loginShowPass"
											onClick={togglePasswordVisibility}
											alt={IMG_ALT}
										/>
									</Form.Floating>
								</Col>
							</Row>
							<Row>
								<Col className="pfr_inputFloatDiv">
									<Form.Floating className="pfr_inputFloat">
										<Form.Control
											id="floatingInput2"
											type={!passwordVisible ? "password" : "text"}
											placeholder="Confirmer mot de passe"
											name="confirm_password"
											value={data.confirm_password}
											onChange={handleChange}
										/>
										<label htmlFor="floatingInput2">
											Confirmer mot de passe
										</label>
										<img
											src={
												require(`../../../assets/img/${
													passwordVisible
														? "icon_pass_hide.png"
														: "icon_pass_show.png"
												}`)
											}
											className="pfr_loginShowPass"
											onClick={togglePasswordVisible}
											alt={IMG_ALT}
										/>
									</Form.Floating>
								</Col>
							</Row>
						</div>
						<div className="pfr_loginModalVerifyDiv">
							<Row className="pfr_loginModalSeparatorDiv">
								<div className="pfr_loginModalSeparator" />
							</Row>
							<div className="pfr_loginModal_footer">
								<Col>
									<Button
										className="pfr_loginModalBtn shadow-none"
										onClick={handleStep3}
									>
										Réinitialiser mot de passe
									</Button>
								</Col>
							</div>
						</div>
					</div>
				)}
			</Offcanvas.Body>
		</React.Fragment>
	);
}

export default ForgotPassword;
