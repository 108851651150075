import React from "react";
import { Col, Row } from "react-bootstrap";
import IconAccept from "../../assets/img/icon_accept.png";
import { IMG_ALT } from "../../constants";
const SectionInformationMessageRequestAccept = (props) => {
  return (
    <Row className="clt-detail-section-6">
      <Col>
        <Row>
          <Col>
            <div className="d-flex justify-content-start align-items-center clt-booking-cp-div1-request-accept ">
              <div className='clt_messages_container_request_state'>
                  <img src={IconAccept} alt={IMG_ALT} />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                <h2>{props.innerTitle}</h2>
                <p>{props.innerSubTitle}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SectionInformationMessageRequestAccept;
