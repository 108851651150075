import React, { useState, useEffect } from "react";
import {
    // Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { IMG_ALT } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./ChatSidebarContactRequesUser.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import MessageIcon from "../../../../../../assets/img/chat_icon.png";
// import ConfirmPay4 from "../../../../assets/img/admin_icon_booking_detail_3.png";
import CustomHr from "../../../../../reusable/CustomHr";

import SectionContactRequestDate from "../../../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestMessage from "../../../../../reusable/contactrequest/SectionContactRequestMessage";
import SectionContactRequestEvent from "../../../../../reusable/contactrequest/SectionContactRequestEvent";
import SectionContactRequestMessagAnswer from "../../../../../reusable/contactrequest/SectionContactRequestMessagAnswer";

import SectionUser from "../../../../../reusable/booking/SectionUser";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetail";
import { SuccessToast, ErrorToast } from "../../../../../reusable/Toast";
import SectionInformationMessageRequestSent from "../../../../../reusable/SectionInformationMessageRequestSent";
import axios from "axios";
import { API_ENDPOINT } from "../../../../../../constants";
import { auth } from "../../../../auth/firebase";


const ChatSidebarContactRequestUser = ({ hideSidebar, socket, contactRequest }) => {
    const [eventType, setEventType] = useState(null);
    const [latestContactRequest, setLatestContactRequest] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [guestCount, setGuestCount] = useState(null);

    useEffect(() => {
        setLatestContactRequest(contactRequest);
    }, [contactRequest])

    useEffect(() => {

        const handleContactRequestUpdated = (request) => {
            if(contactRequest && contactRequest.id !== request.id) {
                return;
            }
            setLatestContactRequest(request);
        }

        socket.on("contactRequestUpdated", handleContactRequestUpdated)

        return () => {
            socket.off("contactRequestUpdated", handleContactRequestUpdated)
        }
    }, [socket])


    const handleRequestEdit = async (dataToSend) => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    ...dataToSend
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("La demande a été mise à jour");
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    }

    const handleRefuse = async () => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    status: "Cancel",
                    notificationData: {
                        title: `Notification: Demande de contact annulée par ${latestContactRequest?.User?.firstName} ${latestContactRequest?.User?.lastName}`,
                        message: `La demande de contact a été annulée par le château ou ${latestContactRequest?.User?.firstName}`,
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("La demande a été annulé")
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    return (
        <>
            <div
                className="clt_message_user_chat_offcanvas"
            >
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        Demande de contact
                    </div>

                </div>

                {/* Body */}
                <div className="clt_message_user_request_offcanvas_body" >

                    {latestContactRequest && (
                        <>
                            {latestContactRequest ? (
                                <>
                                    <h2 className="clt-mybooking-title">Demande de contact</h2>
                                    <SectionInformationMessageRequestSent
                                        innerTitle="Demande envoyée"
                                        innerSubTitle="Le château reviendra vers vous prochainement."
                                    />
                                </>
                            ) : null}
                        </>
                    )}

                    <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <h2 className="clt-mybooking-title">Detail</h2>
                    <SectionTop
                        image={ConfirmPayProfilePicture1}
                        title="Lieu"
                        subTitle="Château Latournelle"
                    />

                    {latestContactRequest ? (
                        <>
                            <SectionContactRequestEvent
                                image={`ConfirmPay1`}
                                subText={`${latestContactRequest.Event.title}`}
                                buttonText="Modifier"
                                IsEventButtonDropdown={true} 
                                setEventType={setEventType}
                                event={latestContactRequest.Event}
                                eventType={eventType}
                                handleRequestEdit={handleRequestEdit}
                            />

                            <SectionContactRequestDate
                                image={ConfirmPay2}
                                subText={`${latestContactRequest.month} ${latestContactRequest.year}`}
                                IsDateButtonDropdown={true} 
                                selectedMonth={selectedMonth}
                                setSelectedMonth={setSelectedMonth}
                                selectedYear={selectedYear}
                                setSelectedYear={setSelectedYear}
                                handleRequestEdit={handleRequestEdit}
                            />

                            <SectionContactRequestGuest
                                image={ConfirmPay3}
                                subText={`${latestContactRequest.guest_number}`}
                                IsGuestButtonDropdown={true}
                                guestCount={guestCount}
                                setGuestCount={setGuestCount}
                                handleRequestEdit={handleRequestEdit}
                                // subText={`${guestCount} guests`}
                                // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                                // buttonText="Edit"
                            />

                            <SectionContactRequestMessage
                                image={MessageIcon}
                                subText={`${latestContactRequest.message}`}
                            />

                            <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                            <Row>
                                <Col>
                                    <div className="clt-booking-btn-refuse-div">
                                    <Button
                                        className="shadow-none clt-booking-btn-refuse"
                                        onClick={handleRefuse}
                                    >
                                        <span>Annuler demande</span>
                                    </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : null }

                    {/* <>
                        <div className="clt_message_user_request_top_section">
                            <SectionTop
                                image={ConfirmPayProfilePicture1}
                                title="CLT"
                            />
                        </div>
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <h2 className="clt-booking-cp-div1-title">Request</h2>
                        <SectionContactRequestEvent
                            image={ConfirmPay1}
                            subText="event_type"
                            buttonText="Edit"
                        />

                        <SectionContactRequestDate
                            image={ConfirmPay2}
                            subText="month year"
                            isDateSelect={true}
                            buttonText="Edit"
                        />

                        <SectionContactRequestGuest
                            image={ConfirmPay3}
                            subText={`${guestCount} guests`}
                            // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                            // buttonText="Edit"
                        />

                        <SectionContactRequestMessage
                            image={MessageIcon}
                            subText="Hello, we would like more information for our event.."
                        />

                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <Row>
                            <Col>
                                <div className="clt-booking-btn-refuse-div mb-5">
                                    <Button
                                        className="clt-booking-btn-refuse"
                                        onClick={handleRefuse}
                                    >
                                        <span>Cancel Contact request</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </> */}


                </div>
            </div>
        </>
    );
};

export default ChatSidebarContactRequestUser;
