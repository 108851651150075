/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { Offcanvas } from "react-bootstrap";
import BackArrow from "../../../assets/img/back_arrow.png";
import SuccessIcon from "../../../assets/img/success_icon.png";
import { SuccessToast } from "../../reusable/Toast";
import { IMG_ALT } from "../../../constants";
import { getArticlesByName } from "../../../utils/filters";
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import {
    getAllArticlesWithCategory,
    getAllCategoryWithPaths,
  } from "../../../containers/helpCenterPages/api";
  import Article from "../helpCenter/Article";
import './clt_cookie.css'


const Cookie = ({ isOpen, setIsOpen, show, setShow}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [isOpenArticle, setIsOpenArticle] = useState(false);
    
    const [articles, setArticles] = useState(null);
    const getArticles = async (ids) => {
        const res = await Promise.all(
          ids.map(async (id) => {
            const res = await getAllArticlesWithCategory(id);
            if (res.success) {
              return Object.values(res.data);
            } else {
              return null;
            }
          })
        );
    
        const nRes = [].concat(...res).filter((item) => item !== null);
        setArticles(nRes);
    };
    useEffect(() => {
        getCategories();
    }, []);
    const getCategories = async () => {
        setIsLoading(true);
        const res = await getAllCategoryWithPaths();
        if (res.success) {
          getArticles(Object.values(res.data).map((cat) => cat.id));
          console.log("res.data", res.data);
        } else {
          console.log("error");
        }
        setIsLoading(false);
    };
    const handleClose = () => setShow(false);
    const [essentialCookie, setEssentialCookie] = useState("accept")
    const [adtargetCookie, setADTARGET] = useState("")
    const [analyseCookie, setANALYSE] = useState("")

    const [isDisabled, setIsDisabled] = useState(true)



    useEffect(() => {
        if (analyseCookie !== "" && adtargetCookie !== "")
            setIsDisabled(false)
        else
            setIsDisabled(true)

    }, [adtargetCookie, analyseCookie])

    useEffect(() => {
        const cookie = localStorage.getItem('isCookie');
        if (cookie) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
        setIsLoading(false);
    }, [setIsOpen]);

    useEffect(() => {
        // Check if the user has accepted, refused, or selected preferences for both cookies
        const cookieADTARGET = localStorage.getItem('cookieADTARGET');
        const cookieANALYSE = localStorage.getItem('cookieANALYSE');

        if (cookieADTARGET === 'accept' || cookieANALYSE === 'accept' ||
            cookieADTARGET === 'refuse' || cookieANALYSE === 'refuse') {
            setIsOpen(false);
        }
    }, [setIsOpen]);

    if (isLoading) {
        // Render a loading state, or nothing if you don't want any content during loading
        return null;
    }


    //cookies info _________Change it with your specific needs
    const demoData = {
        name: 'demoNameee',
        email: 'demo@gmail.com',
        description: 'demo description',
        interest: 'demo interest',
    }

    // Function to set the demoData in browser cookies
    const setDemoDataInCookies = () => {
        const cookieData = JSON.stringify(demoData);
        document.cookie = `demoData=${cookieData}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
        localStorage.setItem('isCookie', true);
    };
    //***************************************************** */


    const OpenSidebar = () => {
        // setIsOpen(false)
        setShow(true)
    }

    const RefuseCookies = () => {
        setIsOpen(false)
        setShow(false)
        localStorage.setItem('cookieADTARGET', 'refuse')
        localStorage.setItem('cookieANALYSE', 'refuse')
        SuccessToast("Cookies refusés", "Vous pouvez modifier vos préférences en matière de cookies en bas de la page d'accueil")
    }

    const AcceptCookies = () => {

        setIsOpen(false)
        setShow(false);
        setDemoDataInCookies(); // Set the demoData in browser cookies on accepting cookies
        localStorage.setItem('cookieADTARGET', 'accept')
        localStorage.setItem('cookieANALYSE', 'accept')
        SuccessToast("Cookies acceptés", "Vous pouvez modifier vos préférences en matière de cookies en bas de la page d'accueil")
    }

    const SavePreferenceCookies = () => {
        setIsOpen(false)
        setShow(false)
        setDemoDataInCookies(); // Set the demoData in browser cookies
        SuccessToast("Préférence cookies mise à jour", "Vous pouvez modifier vos préférences en matière de cookies en bas de la page d'accueil")
    }




    return (
        <>
            {isOpen && (
                <div className="clt_cookie_modal">
                    <div className="clt_cookie_modal_header">
                        <p className="clt_cookie_modal_title">Le château utilise des cookies</p>
                    </div>
                    <div className="clt_cookie_modal_body">
                        <p>Nous utilisons des cookies:</p>
                        <p>«Accepter» autorisera les cookies essentiels et facultatifs afin d'améliorer votre expérience ainsi qu'à des fins de marketing et d'analyse</p>
                        <p>«Choisir» vous permet de sélectionner vos préférences en matière de cookies facultatifs.</p>
                        <p>«Refuser» n'autorisera que les cookies essentiels.
                            
                        {articles && getArticlesByName(articles, ["Politique de cookies"])?.map((art) => (
                            <a
                            key={art.id}
                            onClick={() => {
                                setSelectedArticle(art);
                                setIsOpenArticle(true);
                            }}
                            >
                            {art.name}
                            </a>
                        ))}</p>
                        <div className="clt_cookie_modal_button_wrapper">
                            <div className="second">
                                <Button onClick={RefuseCookies}>
                                    Refuser
                                </Button>
                            </div>
                            <div className="second">
                                <Button onClick={OpenSidebar}>
                                    Choisir
                                </Button>
                            </div>
                            <div className="main">
                                <Button onClick={AcceptCookies}>
                                    Accepter
                                </Button>
                            </div>
                        </div>
                    </div>


                </div>
            )}
            <Offcanvas
                placement="end"
                className="clt_cookie_offcanvas"
                show={show}
                onHide={() => setShow(false)}
            >
                <Offcanvas.Header className="justify-content-start clt_cookie_offcanvas_header">

                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={() => setShow(false)}
                    >
                        <img
                            src={BackArrow}
                            className=""
                            alt={IMG_ALT}
                        />
                    </button>

                    <Offcanvas.Title>Préférence cookie</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="clt_cookie_offcanvas_body">

                    <div className='clt_cookie_box_field'>

                        <p className='clt_cookie_offcanvas_body_title'>Choisissez vos préférences</p>
                        <Form>
                            <div className="clt_cookie_offcanvas_body_cookie_box" style={{ pointerEvents: "none", cursor: "not-allowed !important" }}>
                                <p className="title">
                                    Cookies essentiels
                                </p>
                                <p className="desc">
                                    Les Cookies Essentiels sont obligatoires et vous permettent de mémoriser vos choix et vos préférences sur votre compte et de proposer des fonctionnalités facilitant l'utilisation du site.
                                </p>
                                <div className="clt_cookie_radio_group_force">
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_cookie_radio"
                                        style={
                                            essentialCookie === "accept"
                                                ? {
                                                    borderColor: `#000`,
                                                }
                                                : {
                                                    borderColor: `#ddd`,
                                                }
                                        }
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                essentialCookie === "accept"
                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    essentialCookie === "accept"
                                                        ? {
                                                            borderColor: `#000`,
                                                            backgroundColor: `#000`,
                                                        }
                                                        : {
                                                            borderColor: `#ddd`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <span>Accepter</span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_cookie_radio"
                                        style={
                                            essentialCookie === "refuse"
                                                ? {
                                                    borderColor: `#000`,
                                                }
                                                : {
                                                    borderColor: `#ddd`,
                                                }
                                        }
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                essentialCookie === "refuse"
                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    essentialCookie === "refuse"
                                                        ? {
                                                            borderColor: `#000`,
                                                            backgroundColor: `#000`,
                                                        }
                                                        : {
                                                            borderColor: `#ddd`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <span>Refuser</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className='clt_cookie_hr' />
                            </div>
                            <div className="clt_cookie_offcanvas_body_cookie_box">
                                <p className="title">
                                    Cookies de ciblage publicitaire
                                </p>
                                <p className="desc">
                                    Les cookies de ciblage publicitaire sont facultatifs et permettent au Château Latournelle de collaborer avec des partenaires publicitaires, y compris des services de réseaux sociaux, dans le but de diffuser des publicités plus pertinentes sur d'autres applications et sites Web, conformément aux objectifs fixés par ces partenaires.
                                </p>
                                <div className="clt_cookie_radio_group">
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_cookie_radio"
                                        onClick={() => {
                                            setADTARGET("accept")
                                            localStorage.setItem('cookieADTARGET', 'accept')
                                        }}
                                        style={
                                            adtargetCookie === "accept" || localStorage.getItem('cookieADTARGET') === 'accept'
                                                ? {
                                                    borderColor: `#000`,
                                                }
                                                : {
                                                    borderColor: `#ddd`,
                                                }
                                        }
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                adtargetCookie === "accept" || localStorage.getItem('cookieADTARGET') === 'accept'
                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    adtargetCookie === "accept" || localStorage.getItem('cookieADTARGET') === 'accept'
                                                        ? {
                                                            borderColor: `#000`,
                                                            backgroundColor: `#000`,
                                                        }
                                                        : {
                                                            borderColor: `#ddd`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <span>Accepter</span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_cookie_radio"
                                        onClick={() => {
                                            localStorage.setItem('cookieADTARGET', 'refuse')
                                            setADTARGET("refuse")
                                        }}
                                        style={
                                            adtargetCookie === "refuse" || localStorage.getItem('cookieADTARGET') === 'refuse'

                                                ? {
                                                    borderColor: `#000`,
                                                }
                                                : {
                                                    borderColor: `#ddd`,
                                                }
                                        }
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={

                                                adtargetCookie === "refuse" || localStorage.getItem('cookieADTARGET') === 'refuse'
                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={

                                                    adtargetCookie === "refuse" || localStorage.getItem('cookieADTARGET') === 'refuse'
                                                        ? {
                                                            borderColor: `#000`,
                                                            backgroundColor: `#000`,
                                                        }
                                                        : {
                                                            borderColor: `#ddd`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <span>Refuser</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className='clt_cookie_hr' />
                            </div>
                            <div className="clt_cookie_offcanvas_body_cookie_box">
                                <p className="title">
                                    Cookies d'analyse
                                </p>
                                <p className="desc">
                                    Les cookies d'analyse sont facultatifs et permettent au Château Latournelle d'améliorer l'efficacité de notre site en comprenant vos visites et actions.
                                </p>
                                <div className="clt_cookie_radio_group">
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_cookie_radio"
                                        onClick={() => {
                                            localStorage.setItem('cookieANALYSE', 'accept')
                                            setANALYSE("accept")
                                        }}
                                        style={
                                            analyseCookie === "accept" || localStorage.getItem('cookieANALYSE') === "accept"
                                                ? {
                                                    borderColor: `#000`,
                                                }
                                                : {
                                                    borderColor: `#ddd`,
                                                }
                                        }
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                analyseCookie === "accept" || localStorage.getItem('cookieANALYSE') === "accept"

                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    analyseCookie === "accept" || localStorage.getItem('cookieANALYSE') === "accept"

                                                        ? {
                                                            borderColor: `#000`,
                                                            backgroundColor: `#000`,
                                                        }
                                                        : {
                                                            borderColor: `#ddd`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <span>Accepter</span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_cookie_radio"
                                        onClick={() => {
                                            localStorage.setItem('cookieANALYSE', 'refuse')
                                            setANALYSE("refuse")
                                        }}
                                        style={
                                            analyseCookie === "refuse" || localStorage.getItem('cookieANALYSE') === "refuse"

                                                ? {
                                                    borderColor: `#000`,
                                                }
                                                : {
                                                    borderColor: `#ddd`,
                                                }
                                        }
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                analyseCookie === "refuse" || localStorage.getItem('cookieANALYSE') === "refuse"

                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    analyseCookie === "refuse" || localStorage.getItem('cookieANALYSE') === "refuse"

                                                        ? {
                                                            borderColor: `#000`,
                                                            backgroundColor: `#000`,
                                                        }
                                                        : {
                                                            borderColor: `#ddd`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <span>Refuser</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className='clt_cookie_hr' />
                            </div>
                        </Form>
                        <div className="clt_cookie_modal_button_wrapper2">
                            <div className="main">
                                {getArticlesByName(articles || [], ["Politique de cookies"])?.map((art) => (
                                    <Button className="clt_cookie_link"
                                    key={art.id} // Make sure to add a unique key if you are mapping over an array
                                    onClick={() => {
                                        setSelectedArticle(art);
                                        setIsOpenArticle(true);
                                    }}
                                    >
                                    {art.name}
                                    </Button>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                    <div className="FooterButton" >
                        <Button
                            onClick={SavePreferenceCookies}
                            className="clt_cookie_formButton shadow-none"
                            disabled={isDisabled}
                        >
                            <span>Confirmer la sélection</span>
                        </Button>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
            <Article
                article={selectedArticle}
                isOpenArticle={isOpenArticle}
                onClose={() => setIsOpenArticle(false)}
            />

        </>
    )
}

export default Cookie