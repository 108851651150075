// Import
import React, { useEffect, useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { API_ENDPOINT, IMG_ALT, eventweddingData } from "../../../../constants";
import SectionTop from "../../../reusable/booking/SectionTop";
import ConfirmPayProfilePicture1 from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../assets/img/confirm_pay_4.png";
import MessageIcon from "../../../../assets/img/chat_icon.png";
import img1 from '../../../../assets/img/admin_icon_menu_1.png';

import CustomHr from "../../../reusable/CustomHr";
import SectionDate from "../../../reusable/booking/SectionDate";
import SectionInformationMessageRequestSent from "../../../reusable/SectionInformationMessageRequestSent.js";
import SidebarContactRequestAdminCancel from "./[SIDEBAR] Admin - Contact Request - Cancel";

import { ErrorToast, SuccessToast } from "../../../reusable/Toast";

import SectionContactRequestDate from "../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestMessage from "../../../reusable/contactrequest/SectionContactRequestMessage";
import SectionContactRequestEvent from "../../../reusable/contactrequest/SectionContactRequestEvent";
import { auth } from '../../auth/firebase';
import axios from "axios";
import SectionUser from "../../../reusable/booking/SectionUser";
import Account from "../../account/Account";

/* Const */
const SidebarContactRequestAdminSent = ({ data, isOpenSidebarContactRequestAdminSent, onCloseOpenSidebarContactRequestAdminSent, setIsOpenSidebarContactRequestAdminSent, isFromMakeNewRequest, setAdminNavLinks, onCloseNotificationSidebar }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [guestCount, setGuestCount] = useState(null);
  const [contactRequest, setContactRequest] = useState(null);

  const [selectedProfile, setSelectedProfile] = useState({
      type: "Personal",
      detail: "John Doe . 055555555 . johndoe@gmail.com",
      profilePic: null,
      name: "John Doe",
  })

  const [contactRequestUser, setContactRequestUser] = useState(null)
  const [showAccountSidebar, setShowAccountSidebar] = useState(false);


  useEffect(() => {
    if (data) {
      setContactRequest(data.ContactRequest)

      setSelectedProfile({ 
        type: "Personal", 
        detail: `${data?.ContactRequest?.User?.firstName} ${data?.ContactRequest?.User?.lastName} · ${data?.ContactRequest?.User?.phone} · ${data?.ContactRequest?.User?.email}`, 
        profilePic: data?.ContactRequest?.User?.profilePictureUrl, 
        name: `${data?.ContactRequest?.User?.firstName} ${data.ContactRequest?.User?.lastName}`})
      }

      setContactRequestUser(data?.ContactRequest?.User)
  }, [data]);


  /* Const - Booking */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  /* Const - Section - Date */
  const handleRequestEdit = async (dataToSend) => {
    if (data.ContactRequest.status !== "Sent") {
      ErrorToast("Erreur", "Vous n'êtes pas autorisé à effectué cette action.")
    } else {
      const token = await auth.currentUser.getIdToken();
      try {
        const response = await axios.patch(API_ENDPOINT + "/contact/request",
          {
            id: data.ContactRequest.id,
            ...dataToSend
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setContactRequest(response.data.contactRequest)
        SuccessToast("La demande a été mise à jour");
      } catch (error) {
        ErrorToast(error?.response?.data?.message);
      }
    }
  };
  
  let bookingProps = {
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - Guest  */


  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Section - Payment plan */
  const [selectedPlan, setSelectedPlan] = useState({});


  

  /* Const - State "Sent" to "In progress" */
  const HandleStateInProgress = async () => {

    if(setAdminNavLinks) {
      // setTimeout(() => {
        onCloseOpenSidebarContactRequestAdminSent(true)
        onCloseNotificationSidebar()
      // }, 500)
      setAdminNavLinks(3);
    }

    

    // setIsLoading(true);
    // const token = await auth.currentUser.getIdToken();
    // try {
    //   const response = await axios.patch(API_ENDPOINT + "/contact/request",
    //     {
    //       id: data.ContactRequest.id,
    //       status: "In Progress",
    //       notificationData: {
    //         title: "Notification: Contact request - In Progress",
    //         message: "The ticket has been reply by admin"
    //       }
    //     },
    //     { headers: { Authorization: `Bearer ${token}` } }
    //   );
    //   setIsLoading(false);
    //   onCloseOpenSidebarContactRequestAdminSent(true);
    //   SuccessToast("The state of the request is change to 'In progress'");
    //   // SuccessToast(response.data.message);
    // } catch (error) {
    //   setIsLoading(false);
    //   ErrorToast(error?.response?.data?.message);
    // }
  };


  /* Const - Cancel request */
  const handleRefuse = async () => {
    setIsLoading(true);
    const token = await auth.currentUser.getIdToken();
    try {
      const response = await axios.patch(API_ENDPOINT + "/contact/request",
        {
          id: data.ContactRequest.id,
          status: "Cancel",
          notificationData: {
            title: `Demande de contact de ${data?.ContactRequest?.User?.firstName} ${data?.ContactRequest?.User?.lastName} annulée`,
            message: `La demande a été annulée`,
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLoading(false);
      SuccessToast("La demande a été annulé");
      onCloseOpenSidebarContactRequestAdminSent(true);
      setIsOpenSidebarContactRequestAdminCancel(true);
      // SuccessToast(response.data.message);
    } catch (error) {
      setIsLoading(false);
      ErrorToast(error?.response?.data?.message);
    }
  };

  /* Const - Cancel Request - Sidebar */
  const [isOpenSidebarContactRequestAdminCancel, setIsOpenSidebarContactRequestAdminCancel] = useState(false);


  /* Const - Make a new request  */
  const handleMakeNewRequest = () => {
    SuccessToast("There is the user booking, you can edit what you need");
    setIsOpenSidebarMyBookingUser(true);
  };

  /* Const - Section - Date - Make a new request - Open Sidebar Booking */
  const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);

  

  



  {/* Sidebar - User - Edit request - Sent */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarContactRequestAdminSent}
        onHide={onCloseOpenSidebarContactRequestAdminSent}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarContactRequestAdminSent}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>Demande de contact</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          { 
            data && Object.keys(data).length > 0 ?
              <>
              <div className="clt_mybooking_offcanvas_body">

                  <h2 className="clt-mybooking-title">Utilisateur</h2>
                  { 
                    data?.User ? 
                      <SectionUser
                        handleClick={() => setShowAccountSidebar(true)}
                        text={`${selectedProfile.type} profile`}
                        title="User"
                        subText={
                          <span>{selectedProfile.detail}</span>
                        }
                        profilePic={selectedProfile.profilePic}
                        name={selectedProfile.name}
                        isClassActive={true}
                      /> 
                    : 
                      <Row>
                        <Col className="clt-booking-cp-div4">
                          <div
                            className={`clt-booking-cp-div1Box clt-booking-hoverActive clt-booking-cp-div1Box-Extension`}
                          >
                            <div className="d-flex align-items-center justify-content-between flex-row w-100">
                              <div
                                className="clt-booking-div1"
                                style={{ marginLeft: 0, width: "calc(100% - 10px)" }}
                              >
                                <div className="clt-account-screen-inner-div-img">
                                  <img src={img1} alt={IMG_ALT} />
                                </div>
                                <div
                                  className="inner-div-1 clt-booking-cp-div3-text"
                                  style={{ marginLeft: 0, width: "calc(100% - 40px)" }}
                                >
                                  <h2>{`Utilisateur sans compte`}</h2>
                                  <p
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {`${data?.ContactRequest?.first_name} ${data?.ContactRequest?.name}`}
                                  </p>
                                  <p
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {`${data?.ContactRequest?.phone}`}
                                  </p>
                                  <p
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {`${data?.ContactRequest?.email}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                  }
                  <CustomHr
                    rowClass="p-0 mt-4 mb-4"
                    colClass="clt-booking-hr-pd p-0 w-100"
                    hrClass="p-0 m-0 clt-hr-2"
                  />

                <h2 className="clt-mybooking-title">Demande de contact</h2>
                <SectionContactRequestEvent
                    image={ConfirmPay1}
                    subText={`${contactRequest?.Event.title}`}
                    buttonText="Edit"
                    IsEventButtonDropdown={true} 
                    eventType={eventType}
                    event={contactRequest?.Event}
                    setEventType={setEventType}
                    handleRequestEdit={handleRequestEdit}
                />

                <SectionContactRequestDate
                    image={ConfirmPay2}
                    subText={`${contactRequest?.month} ${contactRequest?.year}`}
                    IsDateButtonDropdown={true} 
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    handleRequestEdit={handleRequestEdit}
                />

                <SectionContactRequestGuest
                    image={ConfirmPay3}
                    subText={`${contactRequest?.guest_number}`}
                    IsGuestButtonDropdown={true} 
                    guestCount={guestCount}
                    setGuestCount={setGuestCount}
                    handleRequestEdit={handleRequestEdit}
                    // subText={`${guestCount} guests`}
                    // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                    // buttonText="Edit"
                />

                <SectionContactRequestMessage
                    image={MessageIcon}
                    subText={`${contactRequest?.message}`}
                />
                <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                {/* Footer */}
                <Row>
                  <Col>
                    <div className="clt-booking-btn-refuse-div">
                      {data?.User ? (
                        <Button
                          disabled={isLoading || contactRequest?.status !== 'Sent'}
                          className="shadow-none clt-booking-btn-refuse"
                          onClick={HandleStateInProgress}
                        >
                          <span>Démarrer un chat</span>
                        </Button>
                      ) : null}
                    </div>

                    <div className="clt-booking-btn-refuse-div mt-2">
                      <Button
                        disabled={isLoading || contactRequest?.status !== 'Sent'}
                        className="shadow-none clt-booking-btn-refuse"
                        onClick={handleRefuse}
                      >
                        <span>Annuler demande de contact</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              </>
            : null
          }
        </Offcanvas.Body>
      </Offcanvas>

      {/* Function - Contact Request - Cancel */}
      <SidebarContactRequestAdminCancel
        data={data}
        isOpenSidebarContactRequestAdminCancel={isOpenSidebarContactRequestAdminCancel}
        onCloseOpenSidebarContactRequestAdminCancel={() => setIsOpenSidebarContactRequestAdminCancel(false)}
      />

      {showAccountSidebar &&
        <Account
          isOpenSidebar={showAccountSidebar}
          setIsOpen={() => {
            setShowAccountSidebar(true);
          }}
          onCloseSidebar={() => {
            setShowAccountSidebar(false);
          }}
          selectProfile="admin"
          isTrue={true}
          dataa={{ User: contactRequestUser }}
        />
      }

    </>
  );
};

export default SidebarContactRequestAdminSent;
