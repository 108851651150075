import React from "react";
import { Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../constants";
const SectionClickable4 = (props) => {
  return (
    <Row className="clt-detail-section-6">
      <Col>
        {/* <Row className="clt-detail-section-6-header">
          <Col>
            <h2>{props.title}</h2>
          </Col>
        </Row> */}
        <Row>
          <Col>
            {/* <div
              className="d-flex align-items-center clt-clickable-chevron-div "
              onClick={props.onClick}
            >
              <div className="inner-div-1 clt-booking-cp-div3-text">
                <h2>{props.innerTitle}</h2>
                <p>{props.innerSubTitle}</p>
              </div>
              <div className="inner-div-2">
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div> */}
             <div
            className="clt-booking-cp-div1Box clt-booking-hoverActive"
            onClick={props.onClick}
          >
            <div className="d-flex align-items-center justify-content-between flex-row w-100">
            <div className="clt-booking-div1"  style={{marginLeft:0,width:'calc(100% - 10px)'}}>
                <div className="clt-booking-innner-div1 clt-booking-bgImg8"></div>
                <div className="inner-div-1 clt-booking-cp-div3-text"  style={{marginLeft:0,width:'calc(100% - 40px)'}}>
                <h2>{props.innerTitle}</h2>
                <p style={{overflow:'hidden',whiteSpace:'nowrap',overflowWrap:'break-word'}}>{props.innerSubTitle}</p>
                </div>
              </div>
              <div>
                 <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
            
          </Col>

        </Row>
      </Col>
    </Row>
  );
};

export default SectionClickable4;
