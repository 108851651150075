// Import
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";

/* Const */
const SectionBed = ({
  title,
  image,
  subText,
  roomSelected,
  setIsBedSidebar,
  buttonText,
}) => {
  return (

    /* Section */
    <Row className="clt-booking-mb">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">

          {/* Section - Icon */}
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">

            {/* Section - Text/Subtext */}
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">

              {/* Text */}
              <h2>{title}</h2>

              {/* Subtext */}
              {roomSelected?.length > 0 ? (
                roomSelected?.map((room, index) => {
                  return (
                    <p key={index}>
                      {room?.night_quantity_variation ?
                        <>
                          {room?.room.title}: {room?.selectedroom?.maxQuantity} night
                        </>
                        :
                        <>
                          {room?.title}: {room?.quantity} nuit
                          {/* {room?.title}: {room?.quantity} night booked ({room?.price}€/night) */}
                        </>
                      }
                    </p>
                  );
                })
              ) : (
                <p>{subText}</p>
              )}
            </div>

            {/* Section - Button */}
            {setIsBedSidebar && (
              <Button className="shadow-none" onClick={setIsBedSidebar}>
                <span>{buttonText}</span>
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionBed;
