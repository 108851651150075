// src/components/Overlay/Overlay.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './priceSimulatorModel.css';

const Overlay = ({ logoSrcMobile, logoSrcDesktop }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(fakeLoading);
  }, []);

  return (
    <div className={`overlay ${loading ? 'visible' : 'hidden'}`}>
      <div className="overlay-box">
        {/* Use a media query to conditionally render the image */}
        <img
          src={window.innerWidth <= 768 ? logoSrcMobile : logoSrcDesktop}
          alt="Votre Logo"
          className="overlay-img"
        />
      </div>
    </div>
  );
};

Overlay.propTypes = {
  logoSrcMobile: PropTypes.string.isRequired,
  logoSrcDesktop: PropTypes.string.isRequired,
};

export default Overlay;
