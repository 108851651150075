import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import img1 from "../../../assets/img/admin_icon_menu_1.png";
import { IMG_ALT } from "../../../constants";
const SectionUser = ({ title, subTitle, handleClick, text, subText, profilePic, name, isClassActive}) => {

  return (
    <Row>
      <Col className="clt-booking-cp-div4">
        {/* {title && (
          <h2 className="clt-booking-cp-div4-title">{title}</h2>
        )}
        {subTitle && (
          <h2 className="clt_admin_dash_timeslot_edit_box_subheading2">{subTitle}</h2>
        )}
        <div
          className="d-flex justify-content-between align-items-center clt-booking-cp-div4-inner"
          onClick={handleClick}
        >
          <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div4-text">
            <h2>{text}</h2>
            <p>{subText}</p>
          </div>
            <i className="fa-solid fa-chevron-right"></i>
        </div>  */}
        <div
          className={`clt-booking-cp-div1Box clt-booking-hoverActive ${isClassActive ? 'clt-booking-cp-div1Box-Extension' : ''}`}
          onClick={handleClick}
        >
          <div className="d-flex align-items-center justify-content-between flex-row w-100">
            <div
              className="clt-booking-div1"
              style={{ marginLeft: 0, width: "calc(100% - 10px)" }}
            >
              {/* <div className="clt-booking-innner-div1 clt-booking-bgImg1"></div> */}
              {/* <div className="clt-account-screen-inner-div-img"> */}
              <div className="clt-account-screen-inner-div-img">
                {/* {text === "All Profile" ? (
                  <img src={img1} alt="icon" />
                ) : (
                  <div className="inner-div" style={{ color: "white" }}>
                    {subText && subText.length > 0 ? subText.charAt(0) : "R"}
                  </div>
                )} */}
                {profilePic ? (
                  <img src={img1} alt={IMG_ALT} />
                ) : (
                  <img src={img1} alt={IMG_ALT} />
                )}
              </div>
              <div
                className="inner-div-1 clt-booking-cp-div3-text"
                style={{ marginLeft: 0, width: "calc(100% - 40px)" }}
              >
                <h2>{text}</h2>
                {/* <p>{subText}</p> */}
                <p
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    overflowWrap: "break-word",
                  }}
                >
                  {subText}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionUser;
