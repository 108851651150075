import { combineReducers } from "redux";
import {
  allEvents,
  allRooms,
  event,
  room,
  allTimeSlots,
  eventsTimeSlots,
  timeSlotsByDate,
  allSlotRooms,
  bookings,
  searchBookings,
  cards,
  clientSecret,
  loading,
  chat
} from "./reducer";
export const reducers = combineReducers(
  {
    allEvents: allEvents,
    event: event,
    allRooms: allRooms,
    room: room,
    allTimeSlots: allTimeSlots,
    eventsTimeSlots: eventsTimeSlots,
    timeSlotsByDate: timeSlotsByDate,
    allSlotRooms: allSlotRooms,
    bookings: bookings,
    searchBookings: searchBookings,
    cards: cards,
    clientSecret: clientSecret,
    loading: loading,
    chat: chat
  }
)
