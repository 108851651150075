import { actionTypes } from "../constants/action-type";

const initialState = {
  loading: true,
  events: [],
  rooms: []
}

const eventInitial = {
  event: {}
}

const roomInitial = {
  room: {}
}

const timeSlotsInitial = {
  timeSlots: [],
  eventsTimeSlots: [],
  timeSlotsByDate: [],
  isCreated: false,
}

const slotRooms = {
  slotRooms: [],
  loading: true,
}

const booking = {
  allBookings: [],
  totalCount: 0,
}

const searchBooking = {
  userSearchBooking: []
}

const card = {
  cards: []
}
const clientSecretobj = {
  clientSecret: {}
}

const chatInitial = {
  openChat: null
}

export const loading = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'START_LOADING':
      return {
        ...state, events: payload, loading: true
      };
    case 'STOP_LOADING':
      return {
        ...state, events: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const allEvents = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ALLEVENTS:
      return {
        ...state, events: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const event = (state = eventInitial, { type, payload }) => {
  switch (type) {
    case actionTypes.EVENT:
      return {
        ...state, event: payload
      };
    default:
      return { ...state }
  }
}

export const allRooms = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ALLROOMS:
      return {
        ...state, rooms: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const room = (state = eventInitial, { type, payload }) => {
  switch (type) {
    case actionTypes.ROOM:
      return {
        ...state, room: payload
      };
    default:
      return { ...state }
  }
}

export const allTimeSlots = (state = timeSlotsInitial, { type, payload }) => {
  switch (type) {
    case actionTypes.ALLTIMESLOTS:
      return {
        ...state, timeSlots: payload, loading: false
      };
    case actionTypes.NEWSLOT:
      return {
        ...state, isCreated: true, loading: false
      };
    default:
      return { ...state }
  }
}

export const eventsTimeSlots = (state = timeSlotsInitial, { type, payload }) => {
  switch (type) {
    case actionTypes.EVENTSTIMESLOTS:
      return {
        ...state, eventsTimeSlots: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const timeSlotsByDate = (state = timeSlotsInitial, { type, payload }) => {
  switch (type) {
    case actionTypes.FORMATEDSLOTS:
      return {
        ...state, timeSlotsByDate: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const allSlotRooms = (state = slotRooms, { type, payload }) => {
  switch (type) {
    case actionTypes.SLOTROOMS:
      return {
        ...state, slotRooms: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const bookings = (state = booking, { type, payload, totalCount }) => {
  switch (type) {
    case actionTypes.ALLBOOKINGS:
      return {
        ...state, allBookings: payload, loading: false, totalCount
      };
    case actionTypes.LOADING:
      return {
        ...state, loading: payload,
      };
    default:
      return { ...state }

  }
}

export const searchBookings = (state = searchBooking, { type, payload }) => {
  switch (type) {
    case actionTypes.USERSEARCHBOOKING:
      return {
        ...state, userSearchBooking: payload, loading: false
      };
    case actionTypes.LOADING:
      return {
        ...state, loading: payload,
      };
    default:
      return { ...state }
  }
}

export const cards = (state = card, { type, payload }) => {
  switch (type) {
    case actionTypes.ALLCARDS:
      return {
        ...state, cards: payload, loading: false
      };
    default:
      return { ...state }
  }
}
export const clientSecret = (state = clientSecretobj, { type, payload }) => {
  switch (type) {
    case actionTypes.CLIENTSECRET:
      return {
        ...state, clientSecret: payload, loading: false
      };
    default:
      return { ...state }
  }
}

export const chat = (state = chatInitial, { type, payload }) => {
  switch (type) {
    case actionTypes.OPENCHAT:
      return {
        ...state, openChat: payload
      };
    default:
      return { ...state }
  }
}


// this is chat reducer to set open chat state