import React, { useState } from 'react'
import SectionEvent from '../../../../../../reusable/booking/SectionEvent';
import SectionGuest from '../../../../../../reusable/booking/SectionGuest';
import SectionDate from '../../../../../../reusable/booking/SectionDate';
import BackArrowRight from "../../../../../../../assets/img/back_arrow_right.png";
import IconPending from "../../../../../../../assets/img/icon_pending.png";
import IconCancel from "../../../../../../../assets/img/icon_cancel.png";
import ConfirmPay1 from "../../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../../assets/img/confirm_pay_3.png";
import NextArrow from "../../../../../../../assets/img/back_arrow_right.png";
import icon_accept from "../../../../../../../assets/img/icon_notification_transactionnal_accept.png";
import icon_cancel from "../../../../../../../assets/img/icon_notification_transactionnal_refuse.png";
import ProfilePicture from "../../../../../../../assets/img/detail_slider_1_1.png";

import { IMG_ALT } from "../../../../../../../constants";
import { Button } from "react-bootstrap";
import moment from 'moment';
// import - Sidebar - Edit request - Pending



const SlideDocumentRequire = ({ key, dataItem, setBookingSidebarDetail, onOpenAdminBookingDetailSidebar }) => {
    const [openSidebar, setOpenSidebar] = React.useState(false)
    /* Const - Sidebar - Booking Detail */
    const [isSidebarCancelRequestPendingAdmin, setIsSidebarCancelRequestPendingAdmin] = useState(false);
    const onOpenSidebarCancelRequestPendingAdmin = () => {
        setIsSidebarCancelRequestPendingAdmin(true)
    }
    const onCloseSidebarCancelRequestPendingAdmin = () => {
        setIsSidebarCancelRequestPendingAdmin(false)
    }

    return (
        <>
            <div key={dataItem?.id} onClick={()=> { setBookingSidebarDetail(dataItem); onOpenAdminBookingDetailSidebar(); }} className="d-flex flex-column justify-content-start align-items-center clt-admin-booking-section-3-cards clt-admin-slide-document-require-section-3-cards-secondary" >
                <div className='w-100'>
                    <div
                        className={`d-flex align-items-center justify-content-start }`}
                    >


                        {/* Profile picture*/}
                        <div
                            className="d-flex align-items-center justify-content-start"
                            style={{ width: "20%" }}
                        >
                            <div className='clt_admin_dash_booking_avatar_wrapper_navigation_request mt-0'>
                                {dataItem?.User?.profilePictureUrl ?
                                    <div className="clt_admin_dash_booking_image">
                                        <img src={dataItem?.User?.profilePictureUrl} alt={dataItem?.User?.firstName} />
                                    </div>
                                    :
                                    <div className="clt_admin_dash_booking_avatar">
                                        <div className='clt_admin_dash_booking_avatar_name'>{dataItem?.User?.firstName[0].toUpperCase()}</div>
                                    </div>
                                }
                            </div>
                        </div>


                        {/* Name / Document require */}
                        <div className="clt_admin_dash_booking_container_detail">
                            <div className="clt_admin_dash_booking_container_box_name">{`${dataItem?.User?.firstName} ${dataItem?.User?.lastName}`}</div>
                            <div className="subtitle" style={{ marginBottom: '0px' }}>{`Réservation dans ${moment(dataItem?.ChildTimeSlot?.startDate).diff(moment(), 'days')+1} jours`}</div>
                            <div className="d-flex justify-content-start align-items-center clt-document-require-text-tags">
                                <span className={`d-flex justify-content-center align-items-center ${dataItem?.BookingDocuments.some(x => x.type === 'docA') && dataItem?.BookingDocuments.some(x => x.type === 'docC') ? 'clt-document-require-text-tags-green' : 'clt-document-require-text-tags-red' } `}>
                                    <img src={dataItem?.BookingDocuments.some(x => x.type === 'docA') && dataItem?.BookingDocuments.some(x => x.type === 'docC') ? icon_accept : icon_cancel} alt={IMG_ALT} />
                                    Carte identité
                                </span>
                                <span className={`d-flex justify-content-center align-items-center ${dataItem?.BookingDocuments.some(x => x.type === 'docB') ? 'clt-document-require-text-tags-green' : 'clt-document-require-text-tags-red' }`}>
                                    <img src={dataItem?.BookingDocuments.some(x => x.type === 'docB') ? icon_accept : icon_cancel} alt={IMG_ALT} />
                                    Assurance
                                </span>
                            </div>
                        </div>


                        {/* Arrow */}
                        <div className='clt_slider_edit_request_container_request_state'>
                            <button
                                type="button"
                                className="back-button"
                                aria-label="Close"
                            >
                                <img src={BackArrowRight} className="back-button-img mt-2" alt={IMG_ALT} />
                            </button>
                        </div>


                    </div>
                </div>
            </div>
            {/* <SidebarCancelRequestPendingAdmin
                isOpenSidebarCancelRequestPendingAdmin={openSidebar}
                onCloseOpenSidebarCancelRequestPendingAdmin={()=> setOpenSidebar(false)}
            />*/}
            {/* Hook - Sidebar - Booking Detail  
            <SidebarCancelRequestPending
                onCloseSidebarCancelRequestPending={onCloseSidebarCancelRequestPending}
                isSidebarCancelRequestPending={isSidebarCancelRequestPending}
            />*/}
        </>
    )
}

export default SlideDocumentRequire