import React, { useState } from "react";

const CustomButton = ({ onClick, disabled, text }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleClick = async () => {
    if (disabled || isButtonClicked) return;

    setIsButtonClicked(true);

    try {
      await onClick();
    } catch (error) {
      console.error(error);
    } finally {
      setIsButtonClicked(false);
    }
  };

  return (
    <span onClick={handleClick} style={{ cursor: disabled || isButtonClicked ? "pointer" : "pointer" }}>
      {text}
    </span>
  );
};

export default CustomButton;