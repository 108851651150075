import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "./[SIDEBAR] User - Booking Request.css";
import CustomHr from "../../../reusable/CustomHr";
import BackArrow from "../../../../assets/img/back_arrow.png";
import InvoiceIcon from "../../../../assets/img/icon_invoice.png";
import NextArrow from "../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../constants";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import BackArrowRight from "../../../../assets/img/back_arrow_right.png";
import SlideEditRequestAcceptUser from "./request_edit/slide/[SLIDE] User - Edit Request - Accept";
import SlideEditRequestSentUser from "./request_edit/slide/[SLIDE] User - Edit Request - Sent";
import SlideEditRequestPendingUser from "./request_edit/slide/[SLIDE] User - Edit Request - Pending";
import SlideEditRequestRejectUser from "./request_edit/slide/[SLIDE] User - Edit Request - Reject";
import SidebarCancelRequestAcceptUser from './request_cancel/sidebar/[SIDEBAR] User - Cancel Request - Accept';
import SidebarCancelRequestSentUser from './request_cancel/sidebar/[SIDEBAR] User - Cancel Request - Sent';


/* Const */
const SidebarUserBookingRequest = ({ isOpenSidebarUserBookingRequest, onCloseOpenSidebarUserBookingRequest, setIsOpenSidebarUserBookingRequest, title, data }) => {

    
    /* Const - Cancellation request - Accept*/
    const [isOpenSidebarCancelRequestAcceptUser, setIsOpenSidebarCancelRequestAcceptUser] = React.useState(false)

    /* Const - Cancellation request - Sent*/
    const [isOpenSidebarCancelRequestSentUser, setIsOpenSidebarCancelRequestSentUser] = React.useState(false)

    const [selectedRequest, setSelectedRequest] = React.useState(null);

    /* Const -  Edit request  
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <p className="clt_admin_dash_booking_navigation_request_filter_chip"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                e.stopPropagation();
            }}
        >
            {children}
            {requestFilterUser}
            <img src={NextArrow} className="clt_admin_arrow down" alt={IMG_ALT} />
        </p>
    ));
    const [editRequestCount, setEditRequestCount] = useState(0);
    const [requestFilterUser, setRequestFilterUser] = useState('See All');
    const [cancelFilterUser, setCancelFilterUser] = useState('See All');*/
    /* Const - Slider 
    const ButtonGroup = ({ next, previous, goToSlide, type, ...rest }) => {
        const {
            carouselState: { currentSlide, totalItems, slidesToShow },
        } = rest;
        const disableState = totalItems - slidesToShow;
        

        return (
            <div>
                <div className="d-flex justify-content-between clt_user_booking_request_section pt-3">
                    <div>
                        {type === 'edit' && (
                            <h2 className="clt-admin-booking-section-3-h2">{`Edit Request (${editRequestCount})`}</h2>
                        )}
                        {type === 'cancel' && (
                            <h2 className="clt-admin-booking-section-3-h2">{`Cancel Request (${editRequestCount})`}</h2>
                        )}
                        <div className="d-flex justify-content-between">

                            <div className="clt_admin_dash_booking_navigation_request_filter_chip_container">*/

                                {/* Button filter - Order State 

                                {type === 'edit' && (
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu
                                                size="sm"
                                                title=""
                                                className="pfr_dropdown_menu"
                                            >
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setRequestFilterUser('See All')}
                                                >
                                                    See All
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setRequestFilterUser('Accept')}
                                                >
                                                    Accept
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setRequestFilterUser('Sent')}
                                                >
                                                    Sent
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setRequestFilterUser('Pending')}
                                                >
                                                    Pending
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setRequestFilterUser('Cancel')}
                                                >
                                                    Cancel
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                )}
                                
                                {type === 'cancel' && (
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggleFirst} />
                                            <Dropdown.Menu
                                                size="sm"
                                                title=""
                                                className="pfr_dropdown_menu"
                                            >
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setCancelFilterUser('See All')}
                                                >
                                                    See All
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setCancelFilterUser('Request to cancel')}
                                                >
                                                    Request to cancel
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => setCancelFilterUser('Cancel')}
                                                >
                                                    Cancel
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                )}

                            </div>

                        </div>
                    </div>
                    <div className="hideMobile" style={{ marginRight: "0px", marginBottom: "20px" }}>
                        <Button
                            className={currentSlide === 0
                                ? "disable clt-admin-booking-section-3-cards-arrowBtn me-2"
                                : "clt-admin-booking-section-3-cards-arrowBtn me-2"}
                            onClick={() => previous()}
                        >
                            <i className="fa-solid fa-chevron-left"></i>
                        </Button>

                        <Button
                            className={currentSlide === disableState
                                ? "disable clt-admin-booking-section-3-cards-arrowBtn"
                                : "clt-admin-booking-section-3-cards-arrowBtn"}
                            onClick={() => next()}
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </Button>
                    </div>
                </div>
            </div>

        );
    };*/}

    useEffect(() => {
        if (!data?.CancelBookings?.some(x => x.status === 'Sent' || x.status === 'Accept')) {
            setIsOpenSidebarCancelRequestSentUser(false);
            onCloseOpenSidebarUserBookingRequest();
        }
    }, [data]);

    return (
        <Offcanvas
            placement="end"
            className="clt_reuseable_invoice_offcanvas"
            show={isOpenSidebarUserBookingRequest}
            onHide={onCloseOpenSidebarUserBookingRequest}
        >
            <Offcanvas.Header className="justify-content-start clt_reuseable_invoice_offcanvas_header">

                <React.Fragment>
                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={onCloseOpenSidebarUserBookingRequest}
                    >
                        <img src={BackArrow} alt={IMG_ALT} />
                    </button>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title className="clt_reuseable_invoice_title">Demande</Offcanvas.Title>
                        {/* export button  */}
                        {/* <Button className="clt_reuseable_invoice_header_button shadow-none">
                            Export
                        </Button> */}
                    </div>
                </React.Fragment>


            </Offcanvas.Header>
            
            
            <Offcanvas.Body
                className="clt_reuseable_invoice_offcanvas_body"
                style={{ padding: '24px' }}
            >
                <>
                
                    <div className="user-edit-request-list d-flex flex-column" >

                        {/* Section - Cancel request */}
                        <h2 class="clt_admin_dash_timeslot_edit_box_heading_2">
                            Demande annulation
                        </h2>
                        <p class="clt_admin_dash_timeslot_edit_box_subheading">Vous pouvez trouver ici les détails d'une annulation ici</p>

                        {
                            data?.CancelBookings.map((item, index) => {
                                if (item.status === 'Sent') {
                                    {/* Card - Cancel request - Sent*/}
                                    return (
                                        <div onClick={()=> { setSelectedRequest(item); setIsOpenSidebarCancelRequestSentUser(true) }} className="d-flex flex-column justify-content-start align-items-start clt-admin-booking-section-3-cards clt-booking-request-card" >
                                            <div className='w-100'>

                                                <div className="d-flex justify-content-between align-center">

                                                    <div className="d-flex justify-content-center align-center">
                                                        {/* Title */}
                                                        <div   className="d-flex flex-column justify-content-center align-items-start clt-admin-dash-booking-slider-text">
                                                            <h2>Demande annulation envoyée</h2>
                                                        </div>
                                                    </div>
                                                    <div   className='clt_slider_edit_request_container_request_state'>
                                                        {/* Button - arrow */}
                                                        <button
                                                            type="button"
                                                            className="back-button"
                                                            aria-label="Close"
                                                            
                                                        >
                                                            <img src={BackArrowRight}  className="back-button-img mt-2" alt={IMG_ALT} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Subtitle */}
                                                <div className='clt_slider_edit_request_container_request_state'>
                                                    <div className="d-flex flex-column justify-content-center align-items-start clt-admin-dash-booking-slider-text">
                                                        <p>Votre demande est envoyée, le château répondra bientôt à votre demande.</p>
                                                    </div>
                                                </div>

                                                {/* Detail 
                                                <div className="clt-booking-mb row">
                                                    <div className="clt_admin_dash_booking_cancel_container_detail">
                                                        <div className="wedding">01-02 Jan</div>
                                                        <div className="wedding">#1235467</div>
                                                    </div>
                                                </div>
                                                <div className="clt-booking-mb2 row">
                                                    <div className="clt_admin_dash_booking_cancel_container_detail">
                                                        <div className="wedding">Wedding</div>
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>
                                    )
                                } else if (item.status === 'Accept') {
                                    {/* Card - Cancel request - Accept*/}
                                    return (
                                        <div onClick={() => { setSelectedRequest(item); setIsOpenSidebarCancelRequestAcceptUser(true)}} className="d-flex flex-column justify-content-start align-items-start clt-admin-booking-section-3-cards clt-booking-request-card" >
                                            <div className='w-100'>

                                                <div className="d-flex justify-content-between align-center">

                                                    <div className="d-flex justify-content-center align-center">
                                                        {/* Title */}
                                                        <div  className="d-flex flex-column justify-content-center align-items-start clt-admin-dash-booking-slider-text">
                                                            <h2>Demande annulation acceptée</h2>
                                                        </div>
                                                    </div>
                                                    <div  className='clt_slider_edit_request_container_request_state'>
                                                        {/* Button - arrow */}
                                                        <button
                                                            type="button"
                                                            className="back-button"
                                                            aria-label="Close"
                                                            
                                                        >
                                                            <img src={BackArrowRight} className="back-button-img mt-2" alt={IMG_ALT} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Subtitle */}
                                                <div className='clt_slider_edit_request_container_request_state'>
                                                    <div className="d-flex flex-column justify-content-center align-items-start clt-admin-dash-booking-slider-text">
                                                        <p>Votre réservation est désormais annulée</p>
                                                    </div>
                                                </div>

                                                {/* Detail 
                                                <div className="clt-booking-mb row">
                                                    <div className="clt_admin_dash_booking_cancel_container_detail">
                                                        <div className="wedding">01-02 Jan</div>
                                                        <div className="wedding">#1235467</div>
                                                    </div>
                                                </div>
                                                <div className="clt-booking-mb2 row">
                                                    <div className="clt_admin_dash_booking_cancel_container_detail">
                                                        <div className="wedding">Wedding</div>
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                       

                       
                        

                        <CustomHr
                            colClass="clt-booking-hr-pd"
                            hrClass="p-0 m-0 clt-hr"
                        />
                        {/* Carousel - Edit request */}
                        {/* {type === 'edit' && ( */}
                            {/*<Carousel
                                additionalTransfrom={0}
                                // arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite={false}
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                // renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                arrows={false}
                                renderButtonGroupOutside={true}
                                customButtonGroup={<ButtonGroup type="edit" />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 1.1,
                                        partialVisibilityGutter: 30
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 900,
                                            min: 0
                                        },
                                        items: 1.1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 900
                                        },
                                        items: 1.1,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {requestDetailListUser?.filter(detail => requestFilterUser !== 'See All' ? detail.status == requestFilterUser : true)?.map(item =>
                                    <div key={item} style={{ margin: "0px 20px 20px 0px", height: '100%' }}>
                                        {item.status == 'Accept' ?
                                            <SlideEditRequestAcceptUser/>
                                            : item.status == 'Sent' ?
                                                <SlideEditRequestSentUser/>
                                                : item.status == 'Cancel' ?
                                                    <SlideEditRequestRejectUser />
                                                    :
                                                    <SlideEditRequestPendingUser/>
                                        }
                                    </div>
                                )}
                            </Carousel>*/}
                        {/* )} */}
                        

                        
                        

                        {/* Carousel - Cancel request */}
                        {/* {type === 'cancel' && ( 
                            <Carousel
                                additionalTransfrom={0}
                                // arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite={false}
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                // renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                arrows={false}
                                renderButtonGroupOutside={true}
                                customButtonGroup={<ButtonGroup type="cancel" />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 1.1,
                                        partialVisibilityGutter: 30
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 900,
                                            min: 0
                                        },
                                        items: 1.1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 900
                                        },
                                        items: 1.1,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {requestCancelDetailListUser?.filter(detail => cancelFilterUser !== 'See All' ? detail.status == cancelFilterUser : true).map(item =>
                                    <div style={{ margin: "0px 20px 20px 0px", height: '100%' }}>
                                        {item.status == 'Cancel' ?
                                            <SlideCancelRequestAcceptUser key={item} />
                                            :
                                            <SlideCancelRequestSentUser key={item} />
                                        }
                                    </div>
                                )}
                            </Carousel>*/}
                        {/* )} */}
                    </div>
                </>
            </Offcanvas.Body>
            {   isOpenSidebarCancelRequestAcceptUser ?
                    <SidebarCancelRequestAcceptUser
                        booking={data}
                        data={selectedRequest}
                        isOpenSidebarCancelRequestAcceptUser={isOpenSidebarCancelRequestAcceptUser}
                        onCloseOpenSidebarCancelRequestAcceptUser={()=> setIsOpenSidebarCancelRequestAcceptUser(false)}
                    /> 
                :
                    null 
            }
            {   isOpenSidebarCancelRequestSentUser ?
                    <SidebarCancelRequestSentUser
                        booking={data}
                        data={selectedRequest}
                        onCloseOpenSidebarUserBookingRequest={() => onCloseOpenSidebarUserBookingRequest(false)}
                        isOpenSidebarCancelRequestSentUser={isOpenSidebarCancelRequestSentUser}
                        onCloseOpenSidebarCancelRequestSentUser={()=> setIsOpenSidebarCancelRequestSentUser(false)}
                    />
                :
                    null 
            }
        </Offcanvas >
        
    );
    
};

export default SidebarUserBookingRequest;
