// Import
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "./user_notification.css";
import { API_ENDPOINT, IMG_ALT } from "../../../constants";
import { Link } from "react-router-dom";

// Import - Icon / Image
import BackArrow from "../../../assets/img/back_arrow.png";
import icon_notification_transactionnal_refuse from "../../../assets/img/icon_notification_transactionnal_refuse.png";
import icon_notification_transactionnal_information from "../../../assets/img/icon_notification_transactionnal_information.png";
import icon_notification_transactionnal_accept from "../../../assets/img/icon_notification_transactionnal_accept.png";

// import - Sidebar - Contact request

// import - Sidebar - Contact request - Accept
import SidebarContactRequestUserAccept from "../contactRequest/user/[SIDEBAR] User - Contact Request - Accept";

// import - Sidebar - Contact request - In progress
import SidebarContactRequestUserInProgress from "../contactRequest/user/[SIDEBAR] User - Contact Request - InProgress";

// import - Sidebar - Contact request - Sent
import SidebarContactRequestUserSent from "../contactRequest/user/[SIDEBAR] User - Contact Request - Sent";

// import - Sidebar - Contact request - Sent
import SidebarContactRequestUserCancel from "../contactRequest/user/[SIDEBAR] User - Contact Request - Cancel";

// import - Sidebar - Edit request

// import - Sidebar - Edit request - Accept
import SidebarEditRequestAcceptUser from "../myBooking/user_booking_request/request_edit/sidebar/[SIDEBAR] User - Edit Request - Accept";

// import - Sidebar - Edit request - Sent
import SidebarEditRequestSentUser from "../myBooking/user_booking_request/request_edit/sidebar/[SIDEBAR] User - Edit Request - Sent";

// import - Sidebar - Edit request - Pending
import SidebarEditRequestPendingUser from "../myBooking/user_booking_request/request_edit/sidebar/[SIDEBAR] User - Edit Request - Pending";

// import - Sidebar - Edit request - Reject
import SidebarEditRequestRejectUser from "../myBooking/user_booking_request/request_edit/sidebar/[SIDEBAR] User - Edit Request - Reject";

// import - Sidebar - Cancel request

// import - Sidebar - Cancel request - Accept
import SidebarCancelRequestAcceptUser from "../myBooking/user_booking_request/request_cancel/sidebar/[SIDEBAR] User - Cancel Request - Accept";

// import - Sidebar - Cancel request - Sent
import SidebarCancelRequestSentUser from "../myBooking/user_booking_request/request_cancel/sidebar/[SIDEBAR] User - Cancel Request - Sent";

import { auth } from "../auth/firebase";
import axios from "axios";
import { ErrorToast, SuccessToast, SuccessBookingToast } from "../../reusable/Toast";
import { AuthContext, useAuth } from "../auth/authContext";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import jwt_decode from "jwt-decode";

import { useHistory } from "react-router-dom";
import MyBooking from "../myBooking/user_mybooking";
import moment from "moment"; 


/* Const */
const NotificationOffcanvas = ({
  isNotificationSidebar,
  onCloseNotificationSidebar,
  getNotiId,
  hel,
  setIsAccountDrawer
}) => {

  /* Const - Sidebar - Notification */
  const history = useHistory();

  /* Const - Sidebar - Notification - Open */
  const [isNotificationCardSelected, setIsNotificationCardSelected] = useState(
    isNotificationSidebar
  );

  /* Const - Sidebar - Notification - Close */
  const handleCloseSidebar = () => {
    onCloseNotificationSidebar()
  };

  /* Const - Sidebar - Notification - Manage */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="clt_reusable_notification_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));

  /* Const - Contact request */

  /* Const - Contact request - Accept */
  const [isOpenSidebarContactRequestUserAccept, setIsOpenSidebarContactRequestUserAccept] = useState(false);

  /* Const - Contact request - Sent */
  const [isOpenSidebarContactRequestUserSent, setIsOpenSidebarContactRequestUserSent] = useState(false);

  /* Const - Contact request - In progress */
  const [isOpenSidebarContactRequestUserInProgress, setIsOpenSidebarContactRequestUserInProgress] = useState(false);

  /* Const - Contact request - Cancel */
  const [isOpenSidebarContactRequestUserCancel, setIsOpenSidebarContactRequestUserCancel] = useState(false);

  /* Const - Edit request */

  /* Const - Edit request - Accept*/
  const [isOpenSidebarEditRequestAcceptUser, setIsOpenSidebarEditRequestAcceptUser] = useState(false);

  /* Const - Edit request - Sent*/
  const [isOpenSidebarEditRequestSentUser, setIsOpenSidebarEditRequestSentUser] = useState(false);

  /* Const - Edit request - Pending*/
  const [isOpenSidebarEditRequestPendingUser, setIsOpenSidebarEditRequestPendingUser] = useState(false);

  /* Const - Edit request - Cancel */
  const [isOpenSidebarEditRequestRejectUser, setIsOpenSidebarEditRequestRejectUser] = useState(false);

  /* Const - Cancel request

  /* Const - Cancel request - Accept*/
  const [isOpenSidebarCancelRequestAcceptUser, setIsOpenSidebarCancelRequestAcceptUser] = useState(false);

  /* Const - Cancel request - Sent*/
  const [isOpenSidebarCancelRequestSentUser, setIsOpenSidebarCancelRequestSentUser] = useState(false);



  const [notificationData, setNotificationData] = useState([]);
  const [notificationDataGrouped, setNotificationDataGrouped] = useState({});
  const [selectedNotification, setSelectedNotification] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { socket } = useAuth();

  const { isNewNotification, setNewNotification } = useContext(AuthContext);

  const [isOpenSidebarMyBookingUser, setsetIsSidebarMyBookingUser] = useState(false);
  const [bookingSidebarDetail, setBookingSidebarDetail] = useState([]);

  const onOpenSidebarCancelRequestSent = () => {
    setIsOpenSidebarCancelRequestSentUser(true);
  };

  const onOpenSidebarMyBookingUser = () => {
    setsetIsSidebarMyBookingUser(true);
  };

  const onCloseOpenSidebarMyBookingUser = () => {
    setsetIsSidebarMyBookingUser(false);
  };

  useEffect(() => {
    if (auth.currentUser) {
      getNotifications(true);
      if (socket) {
        socket.on('notificationSent', function (dataComing, callback) {
          getNotifications();
          setNewNotification(true);

        });
        socket.on('cancelRequestSent', function (dataComing, callback) {
          setBookingSidebarDetail(dataComing.data);
          getNotifications(false);            
        });
      }
    }
  }, [auth.currentUser]);

  useEffect(() => {
    let check = localStorage.getItem('user-login-in');
    if (check && check == 'true') {
      console.log('user loggedIn');
    } else {
      let decode = jwt_decode(hel);
      if (decode.userId) {
        setIsAccountDrawer();
      } else {
        setIsAccountDrawer();
        SuccessToast("Votre demande n'est associée à aucun compte, vous devez en créer un.");
      }
      onCloseNotificationSidebar();
    }
  }, []);

  const getNotifications = async (load = false) => {
    setIsLoading(load)
    setNewNotification(true);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(API_ENDPOINT + "/notifications",
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setNotificationData(response.data.data);
      gorupNotificationData(response.data.data);

      const notificationData = response?.data?.data;
      const notificationLenght = parseInt(response?.data?.count);
      const isNewNotification = localStorage.getItem('isNewNotification');
      localStorage.setItem('newNotification', notificationLenght);


      setIsLoading(false);
      if (load && getNotiId) {
        let filtered = response.data.data.filter(x => x.id == getNotiId);
        if (filtered.length) {
          setSelectedNotification(filtered[0]);
          if (filtered[0].status == 'Sent') {
            setIsOpenSidebarContactRequestUserSent(true)
          } else if (filtered[0].status == 'In Progress') {
            setIsOpenSidebarContactRequestUserInProgress(true)
          } else if (filtered[0].status == 'Accept') {
            setIsOpenSidebarContactRequestUserAccept(true)
          } else if (filtered[0].status == 'Cancel') {
            setIsOpenSidebarContactRequestUserCancel(true)
          }
          history.replace('/');
        } else {
          history.replace('/');
          ErrorToast(`La notification n'existe pas`);
          
        }
      }
      // SuccessToast(response.data.message);
    } catch (error) {
      history.replace('/');
      console.log('location on here: ', error);
      setIsLoading(false);
      ErrorToast(error?.response?.data?.message);
    }

  }

  const handleIsRead = async (item) => {
    if (!item.is_read) {
      let readAbleData = notificationData.map(x => (x.id === item.id) ? { ...x, is_read: true } : x);
      setNotificationData(readAbleData);
      gorupNotificationData(readAbleData);
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.patch(API_ENDPOINT + `/notifications/${item.id}`, { is_read: true },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.log('location on here: ', error);
      }
    }
  }

  const handleCancelRequest = async (item) => {
    const myArray = item.title.split(" ");
    let word = myArray[myArray.length - 1];
    if (word === 'Accept') {
      let itemToShow = item.Booking.CancelBookings.filter(x => x.status === 'Accept');
      if (itemToShow.length) {
        setBookingSidebarDetail(item.Booking);
        setSelectedNotification(itemToShow[itemToShow.length - 1]);
        setIsOpenSidebarCancelRequestAcceptUser(true);
      } else { return; }
    } else if (word === 'Sent' || 'envoyée' || 'envoyé') {
      let itemToShow = item.Booking.CancelBookings.filter(x => x.status === 'Sent');
      if (itemToShow.length) {
        setBookingSidebarDetail(item.Booking);
        setSelectedNotification(itemToShow[itemToShow.length - 1]);
        setIsOpenSidebarCancelRequestSentUser(true);
      } else { return; }
    } else {
      return;
    }
  }

  const handleDateFormat = (dateTime) => {
    const now = moment();
    const diffInMinutes = now.diff(dateTime, 'minutes');
  
    if (diffInMinutes < 1) {
      return 'Maintenant';
    } else if (diffInMinutes < 60) {
      return `Il y à ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'}`;
    } else if (diffInMinutes < 1440) {
      const hoursAgo = Math.floor(diffInMinutes / 60);
      return `Il y à ${hoursAgo} heure${hoursAgo === 1 ? '' : 's'}`;
    } else if (now.isSame(dateTime, 'day')) {
      return moment(dateTime).format('HH:mm');
    } else if (now.isSame(dateTime, 'year')) {
      return moment(dateTime).format('DD MMM HH:mm');
    } else {
      return moment(dateTime).format('DD MMM YYYY, HH:mm');
    }
  }

  const gorupNotificationData = (dataToCategorize) => {
   
    const currentDate = moment().startOf('day');

    const categorizedData = dataToCategorize.reduce((result, item) => {
      const itemDate = moment(item.createdAt).startOf('day');
      const category = currentDate.isSame(itemDate, 'day')
        ? 'aujourdhui'
        : itemDate.isSame(currentDate.clone().subtract(1, 'day'), 'day')
        ? 'hier'
        : currentDate.isSame(itemDate, 'week')
        ? 'cette semaine'
        : itemDate.format('MMMM YYYY');

      result[category] = (result[category] || []).concat(item);

      return result;
    }, { });

    console.log('location: ', categorizedData);
    // Display the categorized data
    setNotificationDataGrouped(categorizedData);
  }




  {/* Sidebar - Notification */ }
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_reusable_notification_offcanvas"
        show={isNotificationSidebar}
        onHide={onCloseNotificationSidebar}
      >


        {/* Header */}
        <Offcanvas.Header className="justify-content-start clt_reusable_notification_offcanvas_header">
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={handleCloseSidebar}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Offcanvas.Title className="clt_reusable_notification_title">
                Notification
                {/* Notification - User */}
              </Offcanvas.Title>
              {/* export button  */}
              {/* <Button className="clt_reusable_notification_header_button shadow-none">
                            Export
                        </Button> */}
            </div>
          </React.Fragment>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_reusable_notification_offcanvas_body ${notificationData.length === 0 ? 'd-flex justify-content-center align-items-center' : ''}`}>
        {/* <Offcanvas.Body className="clt_reusable_notification_offcanvas_body ${notificationData.length === 0 then display flex justify-content-center align-item-center}"> */}
          <>
            <div className="clt_reusable_notification_innerdivs">



              {/* Heading */}
              {/* <h2 className="clt_reusable_notification_innerhead">
                Notification
              </h2> */}






              {/* Notification 1 - Accept */}
              {/* <div className="d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv">
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "20%" }}
                >
                  <div className="clt_reusable_notification_transactionnal_accept_imgdiv">
                    <img
                      src={icon_notification_transactionnal_accept}
                      className=""
                      alt="Image"
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                  style={{ width: "70%" }}
                >
                  <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                    Notification 1
                  </h3>
                  <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>Subtext</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                  style={{ width: "10%" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu
                      size="sm"
                      title=""
                      className="pfr_dropdown_menu"
                    >
                      <Dropdown.Item className="clt_reusable_notification_cards3dots_options">
                        Voir détail
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}

              {/* Notification 2 - Alert */}
              {/* <div
                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "20%" }}
                >
                  <div className="clt_reusable_notification_transactionnal_information_imgdiv">
                    <img
                      src={icon_notification_transactionnal_information}
                      className=""
                      alt="Image"
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                  style={{ width: "70%" }}
                >
                  <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                    Notification 2
                  </h3>
                  <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>Subtext</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                  style={{ width: "10%" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu
                      size="sm"
                      title=""
                      className="pfr_dropdown_menu"
                    >
                      <Dropdown.Item className="clt_reusable_notification_cards3dots_options">
                        Voir détail
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}

              {/* Notification 3 - Refuse */}
              {/* <div
                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "20%" }}
                >
                  <div className="clt_reusable_notification_transactionnal_refuse_imgdiv">
                    <img
                      src={icon_notification_transactionnal_refuse}
                      className=""
                      alt="Image"
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                  style={{ width: "70%" }}
                >
                  <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                    Notification 3
                  </h3>
                  <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>Subtext</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                  style={{ width: "10%" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu
                      size="sm"
                      title=""
                      className="pfr_dropdown_menu"
                    >
                      <Dropdown.Item className="clt_reusable_notification_cards3dots_options">
                        Voir détail
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}


              {/* Notification - Alert - Document require 
              <div
                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "20%" }}
                >
                  <div className="clt_reusable_notification_transactionnal_information_imgdiv">
                    <img
                      src={icon_notification_transactionnal_information}
                      className=""
                      alt="Image"
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                  style={{ width: "70%" }}
                >
                  <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                    Document require
                  </h3>
                  <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>Booking start in less than x days and the 'Document A', 'Document B' miss</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                  style={{ width: "10%" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu
                      size="sm"
                      title=""
                      className="pfr_dropdown_menu"
                    >
                      <Dropdown.Item className="clt_reusable_notification_cards3dots_options">
                        Voir détail
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}




              {
                isLoading ?
                  <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
                    <LoaderDots theme="muted" />
                  </div>
                  : notificationData.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center">
                    <div className="clt_mybooking_offcanvas_screen1_div d-flex flex-column justify-content-center align-items-center">
                        <h2>Aucune notification</h2>
                        <p>Vous n'avez actuellement aucune notification</p>
                      </div>
                    </div>
             
                  ):notificationData.length >=1 ? (
                    <div>
                     <h2 className="clt_reusable_notification_innerhead">
                      Notification
                      </h2> 
                  {
                    Object.keys(notificationDataGrouped).map((key, index) => (
                      <div key={index}>
                        {notificationDataGrouped[key]?.length ? <h6 className="clt_notification_category_heading" >{`${key}`}</h6> : null}
                        {
                          notificationDataGrouped[key]?.map((item, ind) => {
                            if (item.type === 'Contact Request') {
                              if (item.status === 'Sent') {
                                {/* Notification - Contact request - Sent*/ }
                                return (<div
                                  className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                  style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                  key={ind}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                  >
                                    <div>
                                      {item?.User?.profilePictureUrl ?
                                          <div className="clt_notification_avatar">
                                            <div className="clt_notification_confirm_booking_badge_container">
                                              <img
                                                src={icon_notification_transactionnal_information}
                                                className="clt_notification_confirm_booking_badge_container_img"
                                                alt="Image"
                                              />
                                            </div>
                                            <img
                                              className="clt_notification_confirm_booking_profile_img" 
                                              src={item?.User?.profilePictureUrl} 
                                              alt={item?.User?.firstName} 
                                            />
                                          </div>
                                        :
                                          <div className="clt_notification_avatar">
                                            <div className="clt_notification_confirm_booking_badge_container">
                                              <img
                                                src={icon_notification_transactionnal_information}
                                                className="clt_notification_confirm_booking_badge_container_img"
                                                alt="Image"
                                              />
                                            </div>
                                            <div className="clt_notification_confirm_booking_profile_img">
                                              <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                            </div>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                    style={{ width: "70%"}}
                                  >
                                    <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                      {`Demande de contact envoyé`}
                                    </h3>
                                    <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                      {`Votre demande de contact à était envoyé`}
                                    </p>
                                    <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                      {handleDateFormat(item.createdAt)}
                                    </p>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                    style={{ width: "10%" }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu
                                        size="sm"
                                        title=""
                                        className="pfr_dropdown_menu"
                                      >
                                        <Dropdown.Item
                                          className="clt_reusable_notification_cards3dots_options"
                                          onClick={() => { handleIsRead(item); setSelectedNotification(item); setIsOpenSidebarContactRequestUserSent(true); }}
                                        >
                                          Voir détail
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>)
                              } else if (item.status === 'In Progress') {
                                {/* Notification - Contact request - In progress*/ }
                                return (<div
                                  className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                  style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                  key={ind}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                  >
                                    <div>
                                      {item?.User?.profilePictureUrl ?
                                          <div className="clt_notification_avatar">
                                            <div className="clt_notification_confirm_booking_badge_container">
                                              <img
                                                src={icon_notification_transactionnal_information}
                                                className="clt_notification_confirm_booking_badge_container_img"
                                                alt="Image"
                                              />
                                            </div>
                                            <img
                                              className="clt_notification_confirm_booking_profile_img" 
                                              src={item?.User?.profilePictureUrl} 
                                              alt={item?.User?.firstName} 
                                            />
                                          </div>
                                        :
                                          <div className="clt_notification_avatar">
                                            <div className="clt_notification_confirm_booking_badge_container">
                                              <img
                                                src={icon_notification_transactionnal_information}
                                                className="clt_notification_confirm_booking_badge_container_img"
                                                alt="Image"
                                              />
                                            </div>
                                            <div className="clt_notification_confirm_booking_profile_img">
                                              <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                            </div>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                    style={{ width: "70%" }}
                                  >
                                    <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                      {`Le château a répondu à votre demande de contact`}
                                    </h3>
                                    <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                      {`La demande de contact a été répondue`}
                                    </p>
                                    <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                      {handleDateFormat(item.createdAt)}
                                    </p>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                    style={{ width: "10%" }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu
                                        size="sm"
                                        title=""
                                        className="pfr_dropdown_menu"
                                      >
                                        <Dropdown.Item
                                          className="clt_reusable_notification_cards3dots_options"
                                          onClick={() => { handleIsRead(item); setSelectedNotification(item); setIsOpenSidebarContactRequestUserInProgress(true) }}
                                        >
                                          Voir détail
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>)
                              /* } else if (item.status === 'Accept') {
                                Notification - Contact request - Accept 
                                return (<div 
                                    className="d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv"
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                  >
                                  
                                  <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                  >
                                    <div className="clt_reusable_notification_transactionnal_accept_imgdiv">
                                      <img
                                        src={icon_notification_transactionnal_accept}
                                        className=""
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                    style={{ width: "70%" }}
                                  >
                                    <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                      {item.title}
                                    </h3>
                                    <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                      {item.message}
                                    </p>
                                    <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                      {handleDateFormat(item.createdAt)}
                                    </p>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                    style={{ width: "10%" }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu
                                        size="sm"
                                        title=""
                                        className="pfr_dropdown_menu"
                                      >
                                        <Dropdown.Item
                                          className="clt_reusable_notification_cards3dots_options"
                                          onClick={() => { handleIsRead(item); setSelectedNotification(item); setIsOpenSidebarContactRequestUserAccept(true) }}
                                        >
                                          Voir détail
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>)
                              } else if (item.status === 'Cancel') {
                                return (<div
                                  className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                  style={notificationDataGrouped[key].length === ind ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                  >
                                    <div className="clt_reusable_notification_transactionnal_refuse_imgdiv">
                                      <img
                                        src={icon_notification_transactionnal_refuse}
                                        className=""
                                        alt="Image"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                    style={{ width: "70%" }}
                                  >
                                    <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                      {item.title}
                                    </h3>
                                    <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                      {item.message}
                                    </p>
                                    <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                      {handleDateFormat(item.createdAt)}
                                    </p>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                    style={{ width: "10%" }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu
                                        size="sm"
                                        title=""
                                        className="pfr_dropdown_menu"
                                      >
                                        <Dropdown.Item
                                          className="clt_reusable_notification_cards3dots_options"
                                          onClick={() => { handleIsRead(item); setSelectedNotification(item); setIsOpenSidebarContactRequestUserCancel(true) }}
                                        >
                                          Voir détail
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>)*/ 
                              }
                            } else if (item.type === 'Booking') {
                              if (item.status === 'Comming') {
                                {/* Notification - Booking - Coming*/}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        { item?.Booking?.User?.profilePictureUrl ?
                                          <div className="clt_notification_avatar">
                                            <div className="clt_notification_confirm_booking_badge_container">
                                              <img
                                                src={icon_notification_transactionnal_information}
                                                className="clt_notification_confirm_booking_badge_container_img"
                                                alt="Image"
                                              />
                                            </div>
                                            <img
                                              className="clt_notification_confirm_booking_profile_img" 
                                              src={item?.Booking?.User?.profilePictureUrl} 
                                              alt={item?.Booking?.User?.firstName} 
                                            />
                                          </div>
                                          :
                                          <div className="clt_notification_avatar">
                                            <div className="clt_notification_confirm_booking_badge_container">
                                              <img
                                                src={icon_notification_transactionnal_information}
                                                className="clt_notification_confirm_booking_badge_container_img"
                                                alt="Image"
                                              />
                                            </div>
                                            <div className="clt_notification_confirm_booking_profile_img">
                                              <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`Réseration confirmée`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {`${item?.Booking?.User?.firstName}, votre résarvation est confirmé pour un ${item?.Booking?.Event?.title} le ${moment(item?.Booking?.ChildTimeSlot?.startDate).format("DD/MM/YY")}`}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item); setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Ongoing') {
                                {/* Notification - Booking - Ongoing */}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        {item?.Booking?.User?.profilePictureUrl ?
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <img
                                                className="clt_notification_confirm_booking_profile_img" 
                                                src={item?.Booking?.User?.profilePictureUrl} 
                                                alt={item?.Booking?.User?.firstName} 
                                              />
                                            </div>
                                          :
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <div className="clt_notification_confirm_booking_profile_img">
                                                <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`C'est parti, votre réservation commence !`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {`Votre réservation pour un ${item?.Booking?.Event?.title} vient de commencer`}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item); setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Finish') { 
                                {/* Notification - Booking - Finish */}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        {item?.Booking?.User?.profilePictureUrl ?
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <img
                                                className="clt_notification_confirm_booking_profile_img" 
                                                src={item?.Booking?.User?.profilePictureUrl} 
                                                alt={item?.Booking?.User?.firstName} 
                                              />
                                            </div>
                                          :
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <div className="clt_notification_confirm_booking_profile_img">
                                                <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`Réservation terminée`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {`Votre réservation pour un ${item?.Booking?.Event?.title} est terminée`}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item); setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Cancel') {
                                {/* Notification - Booking cancel */}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        {item?.Booking?.User?.profilePictureUrl ?
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <img
                                                className="clt_notification_confirm_booking_profile_img" 
                                                src={item?.Booking?.User?.profilePictureUrl} 
                                                alt={item?.Booking?.User?.firstName} 
                                              />
                                            </div>
                                          :
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <div className="clt_notification_confirm_booking_profile_img">
                                                <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`Réservation annulée`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {`Votre réservation pour un ${item?.Booking?.Event?.title} est annulée`}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item); setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Alert') {
                                {/* Notification - Booking alert */}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        { item?.User?.profilePictureUrl ?
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <img
                                                className="clt_notification_confirm_booking_profile_img" 
                                                src={item?.User?.profilePictureUrl} 
                                                alt={item?.User?.firstName} 
                                              />
                                            </div>
                                          :
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <div className="clt_notification_confirm_booking_profile_img">
                                                <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`Document manquant pour votre réservation du ${moment(item?.Booking?.ChildTimeSlot?.startDate).format("DD/MM/YY")}`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {item.message}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item); setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Payment_Failed') {
                                {/* Notification - Booking alert */}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        { item?.User?.profilePictureUrl ?
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <img
                                                className="clt_notification_confirm_booking_profile_img" 
                                                src={item?.User?.profilePictureUrl} 
                                                alt={item?.User?.firstName} 
                                              />
                                            </div>
                                          :
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <div className="clt_notification_confirm_booking_profile_img">
                                                <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`Paiement manquant`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {`${item.message} ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un évenement ${item?.Booking?.Event?.title} à échoué`}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item);setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Payment_Success') {
                                {/* Notification - Booking alert */}
                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                    style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                    key={ind}
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-start"
                                      style={{ width: "20%" }}
                                    >
                                      <div>
                                        { item?.User?.profilePictureUrl ?
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <img
                                                className="clt_notification_confirm_booking_profile_img" 
                                                src={item?.User?.profilePictureUrl} 
                                                alt={item?.User?.firstName} 
                                              />
                                            </div>
                                          :
                                            <div className="clt_notification_avatar">
                                              <div className="clt_notification_confirm_booking_badge_container">
                                                <img
                                                  src={icon_notification_transactionnal_information}
                                                  className="clt_notification_confirm_booking_badge_container_img"
                                                  alt="Image"
                                                />
                                              </div>
                                              <div className="clt_notification_confirm_booking_profile_img">
                                                <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                      style={{ width: "70%" }}
                                    >
                                      <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                        {`Paiement réussi`}
                                      </h3>
                                      <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                        {`${item.message} ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un évenement ${item?.Booking?.Event?.title} a été payé avec succès`}
                                      </p>
                                      <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                        {handleDateFormat(item.createdAt)}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                      style={{ width: "10%" }}
                                    >
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu
                                          size="sm"
                                          title=""
                                          className="pfr_dropdown_menu"
                                        >
                                          <Dropdown.Item
                                            className="clt_reusable_notification_cards3dots_options"
                                            onClick={() => {handleIsRead(item); setBookingSidebarDetail(item.Booking); onOpenSidebarMyBookingUser()}}
                                          >
                                            Voir détail
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                )
                              } else if (item.status === 'Request To Cancel') {
                                  {/* Notification - Cancel request - Sent */}
                                  return (
                                    <div
                                      className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv }`}
                                      style={notificationDataGrouped[key].length === ind+1 ? { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6', borderBottom: 0 } : { backgroundColor: item.is_read ? 'transparent' : '#f6f6f6' }}
                                      key={ind}
                                    >
                                      <div
                                        className="d-flex align-items-center justify-content-start"
                                        style={{ width: "20%" }}
                                      >
                                        <div className="clt_reusable_notification_transactionnal_accept_imgdiv">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className=""
                                            alt="Image"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                        style={{ width: "70%" }}
                                      >
                                        <h3 className={item.is_read ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                          {`${item.title}`}
                                        </h3>
                                        <p className={item.is_read ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                          {`Demande d'annulation pour votre réservation du ${moment(item?.Booking?.ChildTimeSlot?.startDate).format("DD/MM/YY")}`}
                                        </p>
                                        <p className={item.is_read ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                          {handleDateFormat(item.createdAt)}
                                        </p>
                                      </div>
                                      <div
                                        className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                        style={{ width: "10%" }}
                                      >
                                        <Dropdown>
                                          <Dropdown.Toggle as={CustomToggle} />
                                          <Dropdown.Menu
                                            size="sm"
                                            title=""
                                            className="pfr_dropdown_menu"
                                          >
                                            <Dropdown.Item
                                              className="clt_reusable_notification_cards3dots_options"
                                              onClick={() => {handleIsRead(item);  handleCancelRequest(item); }}
                                            >
                                              Voir détail
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  )
                              }
                            } 
                          })
                        }
                      </div>
                    ))
                  
                  }
                  </div>)
                  : null}
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Body - End */}



      {/* Function - Sidebar - Notification */}

      {/* ----------------------------------- */}

      {/* Function - Contact Request - Accept*/}
     {isOpenSidebarContactRequestUserAccept ?  <SidebarContactRequestUserAccept
        data={selectedNotification}
        isOpenSidebarContactRequestUserAccept={isOpenSidebarContactRequestUserAccept}
        onCloseOpenSidebarContactRequestUserAccept={() => setIsOpenSidebarContactRequestUserAccept(false)}
      /> : null}

      {/* Function - Contact Request - Sent*/}
      {isOpenSidebarContactRequestUserSent ? <SidebarContactRequestUserSent
        data={selectedNotification}
        isOpenSidebarContactRequestUserSent={isOpenSidebarContactRequestUserSent}
        onCloseOpenSidebarContactRequestUserSent={() => setIsOpenSidebarContactRequestUserSent(false)}
      /> : null}

      {/* Function - Contact Request - In progress */}
      {isOpenSidebarContactRequestUserInProgress ? <SidebarContactRequestUserInProgress
        data={selectedNotification}
        isOpenSidebarContactRequestUserInProgress={isOpenSidebarContactRequestUserInProgress}
        onCloseOpenSidebarContactRequestUserInProgress={() => setIsOpenSidebarContactRequestUserInProgress(false)}
      /> : null}

      {/* Function - Contact Request - Sent*/}
      {isOpenSidebarContactRequestUserCancel ? <SidebarContactRequestUserCancel
        data={selectedNotification}
        isOpenSidebarContactRequestUserCancel={isOpenSidebarContactRequestUserCancel}
        onCloseOpenSidebarContactRequestUserCancel={() => setIsOpenSidebarContactRequestUserCancel(false)}
      /> : null}

      {/* ----------------------------------- */}

      {/* Function - Edit request */}

      {/* Function - Edit request - Accept */}
      {isOpenSidebarEditRequestAcceptUser ? <SidebarEditRequestAcceptUser
        isOpenSidebarEditRequestAcceptUser={isOpenSidebarEditRequestAcceptUser}
        onCloseOpenSidebarEditRequestAcceptUser={() => setIsOpenSidebarEditRequestAcceptUser(false)}
      /> : null}

      {/* Function - Edit request - Sent */}
      {isOpenSidebarEditRequestSentUser ? <SidebarEditRequestSentUser
        isOpenSidebarEditRequestSentUser={isOpenSidebarEditRequestSentUser}
        onCloseOpenSidebarEditRequestSentUser={() => setIsOpenSidebarEditRequestSentUser(false)}
      /> : null}

      {/* Function - Edit request - Pending */}
      {isOpenSidebarEditRequestPendingUser ? <SidebarEditRequestPendingUser
        isOpenSidebarEditRequestPendingUser={isOpenSidebarEditRequestPendingUser}
        onCloseOpenSidebarEditRequestPendingUser={() => setIsOpenSidebarEditRequestPendingUser(false)}
      /> : null}

      {/* Function - Edit request - Cancel */}
      {isOpenSidebarEditRequestRejectUser ? <SidebarEditRequestRejectUser
        isOpenSidebarEditRequestRejectUser={isOpenSidebarEditRequestRejectUser}
        onCloseOpenSidebarEditRequestRejectUser={() => setIsOpenSidebarEditRequestRejectUser(false)}
      /> : null}

      {/* ----------------------------------- */}

      {/* Function - Cancel request */}

      {/* Function - Cancel request - Accept */}
      {isOpenSidebarCancelRequestAcceptUser ? <SidebarCancelRequestAcceptUser
        data={selectedNotification}
        booking={bookingSidebarDetail}
        isOpenSidebarCancelRequestAcceptUser={isOpenSidebarCancelRequestAcceptUser}
        onCloseOpenSidebarCancelRequestAcceptUser={() => setIsOpenSidebarCancelRequestAcceptUser(false)}
      /> : null}

      {/* Function - Cancel request - Sent */}
      {isOpenSidebarCancelRequestSentUser ? <SidebarCancelRequestSentUser
        data={selectedNotification}
        booking={bookingSidebarDetail}
        isOpenSidebarCancelRequestSentUser={isOpenSidebarCancelRequestSentUser}
        onCloseOpenSidebarCancelRequestSentUser={() => setIsOpenSidebarCancelRequestSentUser(false)}
      /> : null}

      {isOpenSidebarMyBookingUser ? (
        <MyBooking
          isOpenSidebarMyBookingUser={isOpenSidebarMyBookingUser}
          onCloseOpenSidebarMyBookingUser={() => onCloseOpenSidebarMyBookingUser()}
          bookingSidebarDetail={bookingSidebarDetail}
          isNotification={true}
        />
      ) : null}

    </>
  );
};

export default NotificationOffcanvas;
