import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import icon from "../../../../../../assets/img/icon_contact_us.png";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    className="clt_admin_dash_manage_access_card3dot"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
      console.log(e);
    }}
  >
    {children}
    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
  </Link>
));

function ArticlesList({ list, openEditArticle, deleteArt }) {
  return (
    list &&
    list.length > 0 &&
    list.map((article) => {
      return (
        <div className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_cardsdiv ">
          <div
            className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_account_circle"
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <img
              src={icon}
              alt="icon"
              style={{
                height: "30px",
                width: "30px",
              }}
            />

            <div className="d-flex align-items-start justify-content-center flex-column">
              <h3
                className="clt_admin_dash_manage_access_cardnumber"
                style={{ margin: 0, padding: 0, marginLeft: "15px" }}
              >
                {article.name}
              </h3>
              <p
                className="clt_admin_dash_invoice_cardname"
                style={{ margin: 0, padding: 0, marginLeft: "15px" }}
              >
                {article.groupTag}
              </p>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-end clt_admin_dash_manage_access_cards3dots"
            style={{ width: "10%" }}
          >
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                <Dropdown.Item
                  className="clt_admin_dash_manage_access_cards3dots_options"
                  onClick={() => openEditArticle(article)}
                >
                  Edit article
                </Dropdown.Item>
                <Dropdown.Item
                  className="clt_admin_dash_manage_access_cards3dots_options"
                  onClick={() => deleteArt(article)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    })
  );
}

export default ArticlesList;
