import axios from "axios";
import { API_ENDPOINT } from "../../../constants";
import { auth } from "../auth/firebase";

export const PROFILE_KEY = "profile";

export const addProfessionalProfileRequest = async () => {
	const token = await auth.currentUser.getIdToken();
	return axios.post(
		API_ENDPOINT + "/profiles",
		{},
		{ headers: { Authorization: `Bearer ${token}` } }
	);
};

export const getProfessionalProfilesRequest = async () => {
	const user = auth.currentUser;
	if (user) {
	  try {
		const token = await user.getIdToken();
		const response = await axios.get(API_ENDPOINT + "/profiles", {
		  headers: { Authorization: `Bearer ${token}` },
		});
		return { success: true, ...response.data };
	  } catch (error) {
		return { success: false, error: error.message };
	  }
	} else {
	  // Handle the case when the user is not authenticated
	  // You can return an error or take other appropriate action.
	  return { success: false, error: "User is not authenticated" };
	}
};

export const getProfessionalProfilesRequestAdmin = async (id) => {
	const token = await auth.currentUser.getIdToken();
	return axios.get(API_ENDPOINT + `/profiles/admin/${id}`, {
		headers: { Authorization: `Bearer ${token}` },
	}).then((res) => ({ success: true, ...res.data }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const updateProfessionalProfileRequest = async ({ id, data }) => {
	const token = await auth.currentUser.getIdToken();
	return axios.patch(API_ENDPOINT + `/profiles/${id}`, data, {
		headers: { Authorization: `Bearer ${token}` },
	});
}

export const deleteProfessionalProfileRequest = async ({ id }) => {
	const token = await auth.currentUser.getIdToken();
	return axios.delete(API_ENDPOINT + `/profiles/${id}`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const updatePersonalProfileRequest = async (data) => {
	const token = await auth.currentUser.getIdToken();
	return axios.patch(API_ENDPOINT + "/users/profile", data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const uploadProfilePicRequest = async (data) => {
	const token = await auth.currentUser.getIdToken();
	return axios.post(
		API_ENDPOINT + "/users/image",
		data,
		{ headers: { Authorization: `Bearer ${token}` } }
	);
}

export const deleteProfilePicRequest = async () => {
	const token = await auth.currentUser.getIdToken()
	return axios.delete(
		API_ENDPOINT + "/users/delete-image",
		{ headers: { Authorization: `Bearer ${token}` } }
	)
}