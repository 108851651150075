// Import
import React, { useEffect, useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { IMG_ALT, eventweddingData } from "../../../../constants";
import SectionTop from "../../../reusable/booking/SectionTop";
import ConfirmPayProfilePicture1 from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../assets/img/confirm_pay_4.png";
import MessageIcon from "../../../../assets/img/chat_icon.png";import SectionUser from "../../../reusable/booking/SectionUser";
import Account from "../../account/Account";
import img1 from '../../../../assets/img/admin_icon_menu_1.png';

import CustomHr from "../../../reusable/CustomHr";
import SectionInformationMessageRequestCancel from "../../../reusable/SectionInformationMessageRequestCancel";

import { SuccessToast } from "../../../reusable/Toast";

import SectionContactRequestDate from "../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestMessage from "../../../reusable/contactrequest/SectionContactRequestMessage";
import SectionContactRequestEvent from "../../../reusable/contactrequest/SectionContactRequestEvent";

/* Const */
const SidebarContactRequestAdminCancel = ({ data, isOpenSidebarContactRequestAdminCancel, onCloseOpenSidebarContactRequestAdminCancel, setIsOpenSidebarContactRequestAdminCancel, isFromMakeNewRequest }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(eventweddingData);

  /* Const - Booking */
  const [selectedProfile, setSelectedProfile] = useState({
    type: "Personal",
    detail: "John Doe . 055555555 . johndoe@gmail.com",
    profilePic: null,
    name: "John Doe",
  })  
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);
  const [contactRequestUser, setContactRequestUser] = useState(null);
  const [contactRequest, setContactRequest] = useState(null);

  useEffect(() => {
    if (data) {
      setContactRequest(data.ContactRequest)

      setSelectedProfile({ 
        type: "Personal", 
        detail: `${data?.ContactRequest?.User?.firstName} ${data?.ContactRequest?.User?.lastName} · ${data?.ContactRequest?.User?.phone} · ${data?.ContactRequest?.User?.email}`, 
        profilePic: data?.ContactRequest?.User?.profilePictureUrl, 
        name: `${data?.ContactRequest?.User?.firstName} ${data.ContactRequest?.User?.lastName}`})
      }

      setContactRequestUser(data?.ContactRequest?.User)
  }, [data]);

  const [showAccountSidebar, setShowAccountSidebar] = useState(false);

  /* Const - Section - Date */
  const handleDateEdit = () => {
    setIsBookingSidebar(true);
  };
  let bookingProps = {
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - Guest  */


  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Section - Payment plan */
  const [selectedPlan, setSelectedPlan] = useState({});

  /* Const - Cancel request */
  const handleRefuse = () => {
    SuccessToast("La demande a été annulé");
    onCloseOpenSidebarContactRequestAdminCancel(true);
  };
  let editrequestProps = {
  };

  /* Const - Make a new request  */
  const handleMakeNewRequest = () => {
    SuccessToast("There is the user booking, you can edit what you need");
    setIsOpenSidebarMyBookingUser(true);
  };

  /* Const - Section - Date - Make a new request - Open Sidebar Booking */
  const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);

  

  



  {/* Sidebar - User - Edit request - Sent */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarContactRequestAdminCancel}
        onHide={onCloseOpenSidebarContactRequestAdminCancel}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarContactRequestAdminCancel}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>Demande de contact annulé</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          {
            data && Object.keys(data).length > 0 ?
              <>
                <div className="clt_mybooking_offcanvas_body">

                  <h2 className="clt-mybooking-title">Utilisateur</h2>
                  { 
                    data?.User ? 
                      <SectionUser
                        handleClick={() => setShowAccountSidebar(true)}
                        text={`${selectedProfile.type} profile`}
                        title="User"
                        subText={
                          <span>{selectedProfile.detail}</span>
                        }
                        profilePic={selectedProfile.profilePic}
                        name={selectedProfile.name}
                        isClassActive={true}
                      /> 
                    : 
                      <Row>
                        <Col className="clt-booking-cp-div4">
                          <div
                            className={`clt-booking-cp-div1Box clt-booking-hoverActive clt-booking-cp-div1Box-Extension`}
                          >
                            <div className="d-flex align-items-center justify-content-between flex-row w-100">
                              <div
                                className="clt-booking-div1"
                                style={{ marginLeft: 0, width: "calc(100% - 10px)" }}
                              >
                                <div className="clt-account-screen-inner-div-img">
                                  <img src={img1} alt={IMG_ALT} />
                                </div>
                                <div
                                  className="inner-div-1 clt-booking-cp-div3-text"
                                  style={{ marginLeft: 0, width: "calc(100% - 40px)" }}
                                >
                                  <h2>{`Utilisateur sans compte`}</h2>
                                  <p
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {`${data?.ContactRequest?.first_name} ${data?.ContactRequest?.name}`}
                                  </p>
                                  <p
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {`${data?.ContactRequest?.phone}`}
                                  </p>
                                  <p
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {`${data?.ContactRequest?.email}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                  }
                  <CustomHr
                    rowClass="p-0 mt-4 mb-4"
                    colClass="clt-booking-hr-pd p-0 w-100"
                    hrClass="p-0 m-0 clt-hr-2"
                  />

                  <h2 className="clt-mybooking-title">Demande de contact</h2>
                  <SectionInformationMessageRequestCancel
                    innerTitle="Annuler"
                    innerSubTitle="La demande a été annulée"
                  />

                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <h2 className="clt-mybooking-title">Détail</h2>
                  <SectionTop
                    image={ConfirmPayProfilePicture1}
                    title="Lieu"
                    subTitle="Château Latournelle"
                  />
                  <SectionContactRequestEvent
                      image={ConfirmPay1}
                      subText={`${data.ContactRequest.Event.title}`}
                      buttonText="Edit"
                      IsEventButtonDropdown={false} 
                  />

                  <SectionContactRequestDate
                      image={ConfirmPay2}
                      subText={`${data.ContactRequest.month} ${data.ContactRequest.year}`}
                      IsDateButtonDropdown={false} 
                      // isDateSelect={true}
                      // buttonText="Edit"
                  />

                  <SectionContactRequestGuest
                      image={ConfirmPay3}
                      subText={`${data.ContactRequest.guest_number}`}
                      IsGuestButtonDropdown={false} 
                      // subText={`${guestCount} guests`}
                      // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                      // buttonText="Edit"
                  />

                  {showAccountSidebar &&
                    <Account
                      isOpenSidebar={showAccountSidebar}
                      setIsOpen={() => {
                        setShowAccountSidebar(true);
                      }}
                      onCloseSidebar={() => {
                        setShowAccountSidebar(false);
                      }}
                      selectProfile="admin"
                      isTrue={true}
                      dataa={{ User: contactRequestUser }}
                    />
                  }

                  <SectionContactRequestMessage
                      image={MessageIcon}
                      subText={`${data.ContactRequest.message}`}
                  />
                </div>
              </>
            : null
          }
        </Offcanvas.Body>
      </Offcanvas>



    </>
  );
};

export default SidebarContactRequestAdminCancel;
