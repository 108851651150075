import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./admin-dashboard-event.css";
import CustomHr from "../../../../reusable/CustomHr";
import NextArrow from "../../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../../constants";
import { useDispatch } from "react-redux";
import { createDetails2, createDetails6, createDetailSec4, createHomeSec4, createQuestions, createSlider, getEvent, setdetailsSection3 } from "../../../../../redux/actions/avent";
import { useSelector } from "react-redux";

const AddSection = ({ section, sectionId, onBack, eventId }) => {
  const [uploadDesktopImage, setUploadDesktopImage] = useState(null);
  const [uploadMobileImage, setUploadMobileImage] = useState(null);
  const dispatch = useDispatch();
  const event = useSelector(state => state.event.event)
  // useEffect(() => {
  // }, []);
  const [myformData, setMYFormData] = useState({
    heading: '',
    image: '',
    sliderMain: {
      title: '',
    },
    sliderFirst: {
      title: '',
      description: '',
      textlinkUrl: '',
    },
    sliderSecond: {
      title: '',
      description: '',
    },
    sliderThird: {
      title: '',
      description: '',
    },
    sliderLast: {
      title: '',
      buttonURL: '',
    },
  });

  const [questionData, setQuestionData] = useState({
    heading: '',
	  ask1: {
	     title: '',
	     description: '',
       textlink:'',
	     textlinkUrl: '',
	  },
    ask2: {
      title: '',
      description: '',
      textlink:'',
      textlinkUrl: '',
    },
	  ask3: {
      title: '',
      description: '',
      textlink:'',
      textlinkUrl: '',
   },
    ask4: {
      title: '',
      description: '',
      textlink:'',
      textlinkUrl: '',
  },
    ask5: {
      title: '',
      description: '',
      textlink:'',
      textlinkUrl: '',
    }
  });

  const [detailSection3, setDetailSection3] = useState({
    heading: '',
    subtext: '',
    footerTitle: '',
    footerSubtitle: '',
  })

  const [homeSec4, setHomeSec4] = useState({
    heading: '',
	  desktopImg: '',
    mobileImg: '',
  });

  const [details4, setDetails4] = useState({
    heading: '',
	  desktopImg: '',
    mobileImg: '',
  });

 const [detailSec2, setDetailsSec2] = useState({
      heading: '',
      image1: '',
      image2: '',
      subtext1: {
        title: '',
        subtitle: '',
        icon: '',
      },
      subtext2: {
        title: '',
        subtitle: '',
        icon: '',
      },
      subtext3: {
        title1: '',
        title2: '',
        title3: '',
        title4: '',
        title5: '',
        title6: '',
    }
    })
  const [detailsec6Topics, setDetailsec6Topics] = useState({
   topicOne: {
    title: '',
    subTitle: ''
   },
   topicTwo: {
    title: '',
    subTitle: ''
   },
   topicThree: {
    title: '',
    subTitle: ''
   },
   topicFour: {
    title: '',
    subTitle: ''
   }
  })

  const saveQuestion = async () => {
    const myData = JSON.stringify(questionData)
    const response = await dispatch(createQuestions(eventId, myData));
    if (response.error) {
      console.error("Create Question error:", response.message);
    } else {
      // dispatch(getEvent(eventId))
      onBack();
    }
  };

  const savehome4 = async () => {
    const formData = new FormData();
    formData.append('heading', homeSec4.heading);
    formData.append('desktopImg', homeSec4.desktopImg);
    formData.append('mobileImg', homeSec4.mobileImg);

    const response = await dispatch(createHomeSec4(eventId, formData));
    if (response.error) {
      console.error("Create Home sec 4 error:", response.message);
    } else {
      onBack();
    }
  };

  const savedetails4 = async () => {
    const formData = new FormData();
    formData.append('heading', details4.heading);
    // formData.append('desktopImg', details4.desktopImg);
    formData.append('desktopImg', '');
    // formData.append('mobileImg', details4.mobileImg);
    formData.append('mobileImg', '');
    const response = await dispatch(createDetailSec4(eventId, formData));
    if (response.error) {
      console.error("Create Detail sec 4 error:", response.message);
    } else {
      onBack();
    }
  };

  const handleSliderUpload = async () => {
    const formData = new FormData();
    formData.append('heading', myformData.heading);
    formData.append('image', myformData.image);
    formData.append('sliderMain[title]', myformData.sliderMain?.title);
    formData.append('sliderFirst[title]', myformData.sliderFirst?.title);
    formData.append('sliderFirst[description]', myformData.sliderFirst?.description);
    formData.append('sliderFirst[textlinkUrl]', myformData.sliderFirst?.textlinkUrl);
    formData.append('sliderSecond[title]', myformData.sliderSecond?.title);
    formData.append('sliderSecond[description]', myformData.sliderSecond.description);
    formData.append('sliderThird[title]', myformData.sliderThird?.title);
    formData.append('sliderThird[description]', myformData.sliderThird.description);
    formData.append('sliderLast[title]', myformData.sliderLast?.title);
    formData.append('sliderLast[buttonURL]', myformData.sliderLast.buttonURL);

    const response = await dispatch(createSlider(eventId, formData));

    if (response.error) {
      console.error("Create Slider error:", response.message);
    } else {

      onBack();
    }
  };

  // hnaldeDetailSection2
  const hnaldeDetailSection2 = async () => {
    const formData = new FormData();
    formData.append('heading', detailSec2.heading);
    formData.append('image1', detailSec2.image1);
    formData.append('image2', detailSec2.image2);
    formData.append('subtext1[title]', detailSec2.subtext1?.title);
    formData.append('subtext1[subtitle]',  detailSec2.subtext1.subtitle);
    formData.append('subtext2[title]', detailSec2.subtext2?.title);
    formData.append('subtext2[subtitle]', detailSec2.subtext2.subtitle);
    formData.append('subtext3[title1]', detailSec2.subtext3?.title1);
    formData.append('subtext3[title2]', detailSec2.subtext3?.title2);
    formData.append('subtext3[title3]', detailSec2.subtext3?.title3);
    formData.append('subtext3[title4]', detailSec2.subtext3?.title4);
    formData.append('subtext3[title5]', detailSec2.subtext3?.title5);
    formData.append('subtext3[title6]', detailSec2.subtext3?.title6);
    const response = await dispatch(createDetails2(eventId, formData));

    if (response.error) {
      console.error("Image upload error:", response.message);
    } else {
      onBack();
    }
  };

  const handleDetailsSection3 = async () => {
    const myData = JSON.stringify(detailSection3)
    const response = await dispatch(setdetailsSection3(eventId, myData));
    if (response.error) {
      console.error("Detail sec 3 error:", response.message);
    } else {
      onBack();
    }
  }

  const  handleDetailSec6Topics = async () => {
    const sec6Data = {eventTopics :detailsec6Topics}
    const myData = JSON.stringify(sec6Data)
    const response = await dispatch(createDetails6(eventId, myData));
    if (response.error) {
      console.error("Event sec 6 error:", response.message);
    } else {
      // dispatch(getEvent(eventId))
      onBack();
    }
  };

  useEffect(() => {
    dispatch(getEvent(eventId))
  },[]);

  useEffect(() => {
    if(event?.dataValues?.questions && event.dataValues.questions !== '{}') {
      const myQuestions = JSON.parse(event?.dataValues?.questions)
      setQuestionData(myQuestions)
    }
    if(event?.dataValues?.sliders && event.dataValues.sliders !== '{}') {
      const mySlider = JSON.parse(event?.dataValues?.sliders)
      setMYFormData(mySlider)
    }
    if(event?.dataValues?.detailSection3 && event.dataValues.detailSection3 !== '{}') {
      const myDetailSection3 = JSON.parse(event?.dataValues?.detailSection3)
      setDetailSection3(myDetailSection3)
    }
    if(event?.dataValues?.section4 && event?.dataValues?.section4 !== '{}') {
      const mySection4 = JSON.parse(event?.dataValues?.section4)
      setHomeSec4(mySection4)
    }
    if(event?.dataValues?.detailsSection4 && event?.dataValues?.detailsSection4 !== '{}') {
      const myDetailSection4 = JSON.parse(event?.dataValues?.detailsSection4)
      setDetails4(myDetailSection4)
    }
    if(event?.dataValues?.detailsSection2 && event?.dataValues?.detailsSection2 !== '{}') {
      const myDetailSection2 = JSON.parse(event?.dataValues?.detailsSection2)
      setDetailsSec2(myDetailSection2)
    }
    if(event?.dataValues?.eventTopics && event?.dataValues?.eventTopics !== '{}') {
      const myDetailSection6 = event?.dataValues?.eventTopics
      setDetailsec6Topics(myDetailSection6)
    }
  },[event]);

  const handleInputChange = (e, section, field) => {
    const updatedFormData = { ...myformData };
    if (section === 'heading') {
      updatedFormData[section] = e.target.value;
    }
    else if (section === 'image') {
      updatedFormData[section] = e.target.files[0];
    }
    else {
      updatedFormData[section][field] = e.target.value;
    }
    setMYFormData(updatedFormData);
  };

  const handledetailSec2 = (e, section, field) => {
    const updatedFormData = { ...detailSec2 };
    if (section === 'heading') {
      updatedFormData[section] = e.target.value;
    }
    else if (section === 'image1' ) {
      updatedFormData[section] = e.target.files[0];
    }
    else if (section === 'image2' ) {
      updatedFormData[section] = e.target.files[0];
    }
    else {
      updatedFormData[section][field] = e.target.value;
    }
    setDetailsSec2(updatedFormData);
  };

  const handleAskChange = (e, section, field) => {
    const updatedQuestionData = { ...questionData };
    if (section === 'heading') {
      updatedQuestionData[section] = e.target.value;
    }
    else {
      updatedQuestionData[section][field] = e.target.value;
    }
    setQuestionData(updatedQuestionData)
  };

  const handleHomeSec4 = (e, section, field) => {
    const updatedQuestionData = { ...homeSec4 };
    if (section === 'heading') {
      updatedQuestionData[section] = e.target.value;
    }
    else  {
      updatedQuestionData[section] = e.target.files[0];
    }
    setHomeSec4(updatedQuestionData)
  };

  const handleDetailSec4 = (e, section, field) => {
    const updatedQuestionData = { ...details4 };
    if (section === 'heading') {
      updatedQuestionData[section] = e.target.value;
    }
    else  {
      updatedQuestionData[section] = e.target.files[0];
    }
    setDetails4(updatedQuestionData)
  };

  const handleSec6Topics = (e, section, field) => {
    const updatedFormData = { ...detailsec6Topics };
    updatedFormData[section][field] = e.target.value;
    setDetailsec6Topics(updatedFormData);
  };


  return (
    <>
      {section === "Section 3" && sectionId === 11 && (
        <React.Fragment>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>{section}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="Heading"
                  value={myformData?.heading}
                  // value={selectedEvent.title}
                  onChange={(e) => handleInputChange(e, 'heading', null)}
                />
                <label htmlFor="floatingInput1">Heading</label>
              </Form.Floating>
            </Col>
          </Row>
          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Slider - Main</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderMain?.title}
                  // value={selectedEvent.title}
                  onChange={(e) => handleInputChange(e, 'sliderMain', 'title')}
                />
                <label htmlFor="floatingInput2">Title</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {myformData.image ? myformData?.image?.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    // onChange={(e) =>
                    //   setUploadDesktopImage(e.target.files[0].name)
                    // }
                    onChange={(e) => handleInputChange(e, 'image', null)}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Slider - Step 1</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderFirst?.title}
                  onChange={(e) => handleInputChange(e, 'sliderFirst', 'title')}
                />
                <label htmlFor="floatingInput2">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">DESRIPTION</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderFirst?.description}
                  onChange={(e) => handleInputChange(e, 'sliderFirst', 'description')}
                />
                <label htmlFor="floatingInput2">Description</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TEXT LINK URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderFirst?.textlinkUrl}
                  onChange={(e) => handleInputChange(e, 'sliderFirst', 'textlinkUrl')}
                />
                <label htmlFor="floatingInput2">Text link url</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Slider - Step 2</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput212"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderSecond?.title}
                  onChange={(e) => handleInputChange(e, 'sliderSecond', 'title')}
                />
                <label htmlFor="floatingInput212">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">DESRIPTION</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput52"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderSecond?.description}
                  onChange={(e) => handleInputChange(e, 'sliderSecond', 'description')}
                />
                <label htmlFor="floatingInput52">Description</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Slider - Step 3</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput234"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderThird?.title}
                  onChange={(e) => handleInputChange(e, 'sliderThird', 'title')}
                />
                <label htmlFor="floatingInput234">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">DESRIPTION</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput244"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderThird?.description}
                  onChange={(e) => handleInputChange(e, 'sliderThird', 'description')}
                />
                <label htmlFor="floatingInput244">Description</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Slider - Last</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput4342"
                  type="text"
                  value={myformData.sliderLast?.title}
                  onChange={(e) => handleInputChange(e, 'sliderLast', 'title')}
                />
                <label htmlFor="floatingInput4342">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">BUTTON URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput992"
                  type="text"
                  placeholder="Title"
                  value={myformData.sliderLast?.buttonURL}
                  onChange={(e) => handleInputChange(e, 'sliderLast', 'buttonURL')}
                />
                <label htmlFor="floatingInput992">Url</label>
              </Form.Floating>
            </Col>
          </Row>

          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button className="shadow-none" onClick={handleSliderUpload}>
              Save
            </Button>
          </div>
        </React.Fragment>
      )}
      {section === "Section 3" && sectionId === 2 && (
        <React.Fragment>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>{section}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="Heading"
                 value = {detailSection3.heading}
                 onChange={(event) => setDetailSection3({
                  ...detailSection3,
                  heading: event.target.value
                })}
                />
                <label htmlFor="floatingInput1">Heading</label>
              </Form.Floating>
            </Col>
          </Row>
          <CustomHr colClass="clt-section-hr-pd2" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Subtext</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value = {detailSection3.subtext}
                 onChange={(event) => setDetailSection3({
                  ...detailSection3,
                  subtext: event.target.value
                })}
                />
                <label htmlFor="floatingInput2">Subtext</label>
              </Form.Floating>
            </Col>
          </Row>
          <CustomHr colClass="clt-section-hr-pd2" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>footerTitle</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput3"
                  type="text"
                  placeholder="footerTitle"
                  value = {detailSection3.footerTitle}
                 onChange={(event) => setDetailSection3({
                  ...detailSection3,
                  footerTitle: event.target.value
                })}
                />
                <label htmlFor="floatingInput3">footerTitle</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd2" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Footer Subtitle</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput4"
                  type="text"
                  placeholder="footerSubtitle"
                  value = {detailSection3.footerSubtitle}
                 onChange={(event) => setDetailSection3({
                  ...detailSection3,
                  footerSubtitle: event.target.value
                })}
                />
                <label htmlFor="floatingInput4">Footer subtitle</label>
              </Form.Floating>
            </Col>
          </Row>
          
          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button className="shadow-none" onClick={handleDetailsSection3}>
              Save
            </Button>
          </div>
        </React.Fragment>
      )}

      {(section === "Section 4" && sectionId === 12) && (
        <React.Fragment>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>{section}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="Heading"
                  value={homeSec4.heading}
                  onChange={(event) => handleHomeSec4(event, 'heading', null)}
                />
                <label htmlFor="floatingInput1">Heading</label>
              </Form.Floating>
            </Col>
          </Row>
          <CustomHr colClass="clt-section-hr-pd2" hrClass="p-0 m-0 clt-hr" />

          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h4>Image cc</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">DESKTOP</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {homeSec4.desktopImg ? homeSec4.desktopImg.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleHomeSec4(e, 'desktopImg', null)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">MOBILE</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {homeSec4.mobileImg ? homeSec4.mobileImg.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleHomeSec4(e, 'mobileImg', null)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button className="shadow-none" onClick={savehome4}>
              Save
            </Button>
          </div>
        </React.Fragment>
      )}

      {(section === "Section 4" && sectionId === 3) && (
        <React.Fragment>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>{section}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="Heading"
                  value={details4.heading}
                  onChange={(event) => handleDetailSec4(event, 'heading', null)}
                />
                <label htmlFor="floatingInput1">Heading</label>
              </Form.Floating>
            </Col>
          </Row>
          <CustomHr colClass="clt-section-hr-pd2" hrClass="p-0 m-0 clt-hr" />

          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h4>Image cc</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">DESKTOP</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {details4.desktopImg ? details4.desktopImg.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleDetailSec4(e, 'desktopImg', null)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">MOBILE</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {details4.mobileImg ? details4.mobileImg.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleDetailSec4(e, 'mobileImg', null)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button className="shadow-none" onClick={savedetails4}>
              Save
            </Button>
          </div>
        </React.Fragment>
      )}
      {section === "Section 5" && (
        <React.Fragment>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>{section}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="Heading"
                  value={questionData.heading}
                  onChange={(e) => handleAskChange(e, 'heading', null)}
                />
                <label htmlFor="floatingInput1">Heading</label>
              </Form.Floating>
            </Col>
          </Row>
          {/* <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" /> */}
          {/* <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Slider - Main</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  // value={selectedEvent.title}
                  onChange={(event) => console.log(event.target.value)}
                />
                <label htmlFor="floatingInput2">Title</label>
              </Form.Floating>
            </Col>
          </Row> */}
          {/* <Row className="clt_admin_dash_manage_event_header mt-3">
        <Col>
          <h2>Image</h2>
        </Col>
      </Row> */}
          {/* <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">Image</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {uploadDesktopImage ? uploadDesktopImage : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      setUploadDesktopImage(e.target.files[0].name)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row> */}
          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Ask 1</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput3"
                  type="text"
                  placeholder="Title"
                  value={questionData.ask1?.title}
                  onChange={(e) => handleAskChange(e, 'ask1', 'title')}
                />
                <label htmlFor="floatingInput3">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">DESCRIPTION</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput4"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask1.description}
                  onChange={(e) => handleAskChange(e, 'ask1', 'description')}
                />
                <label htmlFor="floatingInput4">Description</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">TEXT LINK</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask1.textlink}
                  onChange={(e) => handleAskChange(e, 'ask1', 'textlink')}
                />
                <label htmlFor="floatingInput12224">Text link</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask1.textlinkUrl}
                  onChange={(e) => handleAskChange(e, 'ask1', 'textlinkUrl')}
                />
                <label htmlFor="floatingInput12224">Url</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr
            colClass="clt-section-hr-pd"
            hrClass="p-0 m-0 clt-hr mt-5"
          />

          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Ask 2</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput7"
                  type="text"
                  placeholder="Title"
                  value={questionData.ask2.title}
                  onChange={(e) => handleAskChange(e, 'ask2', 'title')}
                />
                <label htmlFor="floatingInput7">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">DESCRIPTION</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput8"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask2.description}
                  onChange={(e) => handleAskChange(e, 'ask2', 'description')}
                />
                <label htmlFor="floatingInput8">Description</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">TEXT LINK</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask2.textlink}
                  onChange={(e) => handleAskChange(e, 'ask2', 'textlink')}
                />
                <label htmlFor="floatingInput12224">Text link</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask2.textlinkUrl}
                  onChange={(e) => handleAskChange(e, 'ask2', 'textlinkUrl')}
                />
                <label htmlFor="floatingInput12224">Url</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr
            colClass="clt-section-hr-pd"
            hrClass="p-0 m-0 clt-hr mt-5"
          />

          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Ask 3</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput77"
                  type="text"
                  placeholder="Title"
                  value={questionData.ask3.title}
                  onChange={(e) => handleAskChange(e, 'ask3', 'title')}
                />
                <label htmlFor="floatingInput77">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">DESCRIPTION</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput88"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask3.description}
                  onChange={(e) => handleAskChange(e, 'ask3', 'description')}
                />
                <label htmlFor="floatingInput88">Description</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">TEXT LINK</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask3.textlink}
                  onChange={(e) => handleAskChange(e, 'ask3', 'textlink')}
                />
                <label htmlFor="floatingInput12224">Text link</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask3.textlinkUrl}
                  onChange={(e) => handleAskChange(e, 'ask3', 'textlinkUrl')}
                />
                <label htmlFor="floatingInput12224">Url</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr
            colClass="clt-section-hr-pd"
            hrClass="p-0 m-0 clt-hr mt-5"
          />

          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Ask 4</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput177"
                  type="text"
                  placeholder="Title"
                  value={questionData.ask4.title}
                  onChange={(e) => handleAskChange(e, 'ask4', 'title')}
                />
                <label htmlFor="floatingInput177">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">DESCRIPTION</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput288"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask4.description}
                  onChange={(e) => handleAskChange(e, 'ask4', 'description')}
                />
                <label htmlFor="floatingInput288">Description</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">TEXT LINK</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask4.textlink}
                  onChange={(e) => handleAskChange(e, 'ask4', 'textlink')}
                />
                <label htmlFor="floatingInput12224">Text link</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask4.textlinkUrl}
                  onChange={(e) => handleAskChange(e, 'ask4', 'textlinkUrl')}
                />
                <label htmlFor="floatingInput12224">Url</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr
            colClass="clt-section-hr-pd"
            hrClass="p-0 m-0 clt-hr mt-5"
          />

          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Ask 5</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput778"
                  type="text"
                  placeholder="Title"
                  value={questionData.ask5.title}
                  onChange={(e) => handleAskChange(e, 'ask5', 'title')}
                />
                <label htmlFor="floatingInput778">Title</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">DESCRIPTION</p>

              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput8890"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask5.description}
                  onChange={(e) => handleAskChange(e, 'ask5', 'description')}
                />
                <label htmlFor="floatingInput8890">Description</label>
              </Form.Floating>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">TEXT LINK</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask5.textlink}
                  onChange={(e) => handleAskChange(e, 'ask5', 'textlink')}
                />
                <label htmlFor="floatingInput12224">Text link</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div mt-3">
              <p class="clt_admin_dash_menu_container_name mt-3">URL</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12224"
                  type="text"
                  placeholder="Description"
                  value={questionData.ask5.textlinkUrl}
                  onChange={(e) => handleAskChange(e, 'ask5', 'textlinkUrl')}
                />
                <label htmlFor="floatingInput12224">Url</label>
              </Form.Floating>
            </Col>
          </Row>

          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button className="shadow-none" onClick={saveQuestion}>
              Save
            </Button>
          </div>
        </React.Fragment>
      )}
      {section === "Section 2" && (
        <React.Fragment>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>{section}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="Heading"
                  value={detailSec2.heading}
                  onChange={(e) => handledetailSec2(e, 'heading', null)}
                />
                <label htmlFor="floatingInput1">Heading</label>
              </Form.Floating>
            </Col>
          </Row>
          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Subdiv 1.1</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext1.title}
                  onChange={(event) => handledetailSec2(event, 'subtext1', 'title')}
                />
                <label htmlFor="floatingInput2">Title</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext1.subtitle}
                  onChange={(event) => handledetailSec2(event, 'subtext1', 'subtitle')}
                />
                <label htmlFor="floatingInput2">Subtitle</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {detailSec2?.image1.name ? detailSec2.image1.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      handledetailSec2(e, 'image1', null)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Subdiv 1.2</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">TITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext2.title}
                  onChange={(event) => handledetailSec2(event, 'subtext2', 'title')}
                />
                <label htmlFor="floatingInput2">Title</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTITLE</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext2.subtitle}
                  onChange={(event) => handledetailSec2(event, 'subtext2', 'subtitle')}
                />
                <label htmlFor="floatingInput2">Subtitle</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>
                  {detailSec2?.image2?.name ? detailSec2.image2?.name : "Add an file"}
                </span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) =>
                      handledetailSec2(e, 'image2', null)
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Subdiv 2</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTEXT 1</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext3.title1}
                  onChange={(event) => handledetailSec2(event, 'subtext3', 'title1')}
                />
                <label htmlFor="floatingInput2">Subtext 1</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTEXT 2</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput12"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext3.title2}
                  onChange={(event) => handledetailSec2(event, 'subtext3', 'title2')}
                />
                <label htmlFor="floatingInput12">Subtext 2</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTEXT 3</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput312"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext3.title3}
                  onChange={(event) => handledetailSec2(event, 'subtext3', 'title3')}
                />
                <label htmlFor="floatingInput312">Subtext 3</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTEXT 4</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput142"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext3.title4}
                  onChange={(event) => handledetailSec2(event, 'subtext3', 'title4')}
                />
                <label htmlFor="floatingInput142">Subtext 4</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTEXT 5</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput152"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext3.title5}
                  onChange={(event) => handledetailSec2(event, 'subtext3', 'title5')}
                />
                <label htmlFor="floatingInput152">Subtext 5</label>
              </Form.Floating>
            </Col>
          </Row>

          <Row>
            <Col className="clt-admin-event-info-input-div">
              <p class="clt_admin_dash_menu_container_name mt-3">SUBTEXT 6</p>
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput162"
                  type="text"
                  placeholder="Title"
                  value={detailSec2.subtext3.title6}
                  onChange={(event) => handledetailSec2(event, 'subtext3', 'title6')}
                />
                <label htmlFor="floatingInput162">Subtext 6</label>
              </Form.Floating>
            </Col>
          </Row>

          <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button className="shadow-none" onClick={hnaldeDetailSection2}>
              Save
            </Button>
          </div>
        </React.Fragment>
      )}

      {section === "Section" && (
        <>
          <React.Fragment>
            <Row className="clt_admin_dash_manage_event_header">
              <Col>
                <h2>Topic 1</h2>
              </Col>
            </Row>
            <Row>
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    type="text"
                    placeholder="Title"
                    value={detailsec6Topics.topicOne.title}
                    onChange={(event) => handleSec6Topics(event, 'topicOne', 'title')}
                  />
                  <label htmlFor="floatingInput1">Title</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput131"
                    type="text"
                    placeholder="Subtitle"
                    value={detailsec6Topics.topicOne.subTitle}
                    onChange={(event) => handleSec6Topics(event, 'topicOne', 'subTitle')}
                  />
                  <label htmlFor="floatingInput131">Subtitle</label>
                </Form.Floating>
              </Col>
            </Row>

            <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />

            <Row className="clt_admin_dash_manage_event_header">
              <Col>
                <h2>Topic 2</h2>
              </Col>
            </Row>
            <Row>
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput31"
                    type="text"
                    placeholder="Title"
                    value={detailsec6Topics.topicTwo.title}
                    onChange={(event) => handleSec6Topics(event, 'topicTwo', 'title')}
                  />
                  <label htmlFor="floatingInput31">Title</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput3131"
                    type="text"
                    placeholder="Subtitle"
                    value={detailsec6Topics.topicTwo.subTitle}
                    onChange={(event) => handleSec6Topics(event, 'topicTwo', 'subTitle')}
                  />
                  <label htmlFor="floatingInput3131">Subtitle</label>
                </Form.Floating>
              </Col>
            </Row>

            <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />

            <Row className="clt_admin_dash_manage_event_header">
              <Col>
                <h2>Topic 3</h2>
              </Col>
            </Row>
            <Row>
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput321"
                    type="text"
                    placeholder="Title"
                    value={detailsec6Topics.topicThree.title}
                    onChange={(event) => handleSec6Topics(event, 'topicThree', 'title')}
                  />
                  <label htmlFor="floatingInput321">Title</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput31341"
                    type="text"
                    placeholder="Subtitle"
                    value={detailsec6Topics.topicThree.subTitle}
                    onChange={(event) => handleSec6Topics(event, 'topicThree', 'subTitle')}
                  />
                  <label htmlFor="floatingInput31341">Subtitle</label>
                </Form.Floating>
              </Col>
            </Row>

            <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />

            <Row className="clt_admin_dash_manage_event_header">
              <Col>
                <h2>Topic 4</h2>
              </Col>
            </Row>
            <Row>
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput319"
                    type="text"
                    placeholder="Title"
                    value={detailsec6Topics.topicFour.title}
                    onChange={(event) => handleSec6Topics(event, 'topicFour', 'title')}
                  />
                  <label htmlFor="floatingInput319">Title</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput31319"
                    type="text"
                    placeholder="Subtitle"
                    value={detailsec6Topics.topicFour.subTitle}
                    onChange={(event) => handleSec6Topics(event, 'topicFour', 'subTitle')}
                  />
                  <label htmlFor="floatingInput31319">Subtitle</label>
                </Form.Floating>
              </Col>
            </Row>

            <div className="pfr_payment_space_bottom" />
            <div className="clt_payment_footer">
              <Button className="shadow-none" onClick={handleDetailSec6Topics}>
                Save
              </Button>
            </div>
          </React.Fragment>
        </>
      )}
    </>
  );
};

export default AddSection;
