// import
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Container, Row, Dropdown } from "react-bootstrap";
import "./[SECTION] Admin - Booking Request.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BookingSliderLandscapeResponsive, BookingSliderResponsive, IMG_ALT, REPONSIVE_MODES } from "../../../../../constants";
import useScreenOrientation from "react-hook-screen-orientation";

// Import - Components
import NextArrow from "../../../../../assets/img/back_arrow_right.png";

// --------------------------------------------------------------

// import - Slider - Edit request

// import - Slider - Edit request - Accept
import SliderEditRequestAccept from './request_edit/slide/[SLIDE] Admin - Edit Request - Accept';

// import - Slider - Edit request - Sent
import SliderEditRequestSent from './request_edit/slide/[SLIDE] Admin - Edit Request - Sent';

// import - Slider - Edit request - Pending
import SliderEditRequestPending from './request_edit/slide/[SLIDE] Admin - Edit Request - Pending';

// import - Slider - Edit request - Cancel
import SliderEditRequestCancel from './request_edit/slide/[SLIDE] Admin - Edit Request - Reject';

// --------------------------------------------------------------

// import - Slider - Cancel request

// import - Slider - Cancel request - Accept
// import SliderCancelRequestAccept from './request_cancel/slide/[SLIDE] Admin - Cancel Request - Accept';

// import - Slider - Cancel request - Pending 
import SliderCancelRequestPending from './request_cancel/slide/[SLIDE] Admin - Cancel Request - Pending';


// --------------------------------------------------------------

// import - Slider - Document require

// import - Slider - Document require - Accept
import SlideDocumentRequire from './documentrequire//slide/[SLIDE] Admin - Document Require';

// --------------------------------------------------------------

// import - Slider - Remaining payment

// import - Slider - Remaining payment - Accept
import SlideMissingPayment from './remainingpayment/slide/SlideMissingPayment';


const requestDetailList = [
    {
        id: 0,
        status: 'Cancel',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
    {
        id: 1,
        status: 'Accept',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
    {
        id: 2,
        status: 'Sent',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
    {
        id: 3,
        status: 'Pending',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
]
const requestCancelDetailList = [
    {
        id: 0,
        status: 'Cancel',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
    {
        id: 1,
        status: 'Request to cancel',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
    {
        id: 0,
        status: 'Cancel',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
    {
        id: 1,
        status: 'Request to cancel',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
]

const ListDocumentRequire = [
    {
        status: 'Cancel',
        type: 'wedding',
        clientName: 'Jhon',
        date: '01-02 Jan'
    },
]


/* Const */
const AdminDashboardBookingSlider = ({ title, subtitle, sidebar, type, data, dataCancelReq, dataMissingPayment, setBookingSidebarDetail, onOpenAdminBookingDetailSidebar }) => {

    const [requestFilter, setRequestFilter] = useState('See All');
    const [cancelFilter, setCancelFilter] = useState('See All');

    /* Const - Slider */
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const {
            carouselState: { currentSlide, totalItems, slidesToShow },
        } = rest;
        const disableState = totalItems - slidesToShow;

        return (
            <div>
                <div className="d-flex justify-content-between">
                    <div>
                        <h2 className="clt-admin-booking-section-3-h2">{title}</h2>
                        <h2 className="clt-admin-booking-section-h2-subtitle-2">{subtitle}</h2>
                    </div>
                    <div className='hideMobile' style={{ marginRight: "0px", marginBottom: "20px" }}>
                        <Button
                            className={currentSlide === 0
                                ? "disable clt-admin-booking-section-3-cards-arrowBtn me-2"
                                : "clt-admin-booking-section-3-cards-arrowBtn me-2"}
                            onClick={() => previous()}
                        >
                            <i className="fa-solid fa-chevron-left"></i>
                        </Button>

                        <Button
                            className={currentSlide === disableState
                                ? "disable clt-admin-booking-section-3-cards-arrowBtn"
                                : "clt-admin-booking-section-3-cards-arrowBtn"}
                            onClick={() => next()}
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </Button>
                    </div>


                </div>
            </div>

        );
    };

    /* Const - Navigation - Booking - Filter button : Order state / Event type  */
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <p className="clt_admin_dash_booking_navigation_request_filter_chip"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                e.stopPropagation();
            }}
        >
            {children}
            {requestFilter}
            <img src={NextArrow} className="clt_admin_arrow down" alt={IMG_ALT} />
        </p>
    ));

    /* Const - Navigation - Booking - Filter button : Order state / Event type  */
    const CustomToggleFirst = React.forwardRef(({ children, onClick }, ref) => (
        <p className="clt_admin_dash_booking_navigation_request_filter_chip"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                e.stopPropagation();
            }}
        >
            {children}
            {cancelFilter}
            <img src={NextArrow} className="clt_admin_arrow down" alt={IMG_ALT} />
        </p>
    ));

    /* Const - Slider - Screen orientation - Landscape / Portrait  */
    const screenOrientation = useScreenOrientation();

    /* Const - Slider - Initialize state with undefined width/height so server and client renders match */
    const size = useWindowSize();


    /* Screen */
    return (
        <div style={{ width: size > 768 ? `${size * .52}px` : `${size}px` }}>

            <div className="clt-admin-dashboard-booking-edit-request" >
                <Row>
                    <Col>
                        <div className="clt-admin-booking-section-3">
                            <div className="d-flex flex-column-reverse">


                                {/* Section - Edit request */}
                                {type == "edit" && (

                                    <Carousel
                                        additionalTransfrom={0}
                                        // arrows
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass="container-with-dots"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite={false}
                                        itemClass=""
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={false}
                                        // renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        arrows={false}
                                        renderButtonGroupOutside={true}
                                        customButtonGroup={<ButtonGroup />}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 40
                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 900,
                                                    min: 0
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 900
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots={false}
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                    >

                                        {requestDetailList?.filter(detail => requestFilter !== 'See All' ? detail.status == requestFilter : true)?.map(item =>
                                            <div key={item} style={{ margin: "0px 20px 20px 0px", height: '100%' }}>
                                                {item.status == 'Accept' ?
                                                    <SliderEditRequestAccept/>
                                                    : item.status == 'Sent' ?
                                                        <SliderEditRequestSent/>
                                                        : item.status == 'Cancel' ?
                                                            <SliderEditRequestCancel/>
                                                            :
                                                            <SliderEditRequestPending/>
                                                }
                                            </div>
                                        )}

                                    </Carousel>
                                )}

                                {/* Section - Cancel request */}
                                {type == "cancel" && (

                                    <Carousel
                                        additionalTransfrom={0}
                                        // arrows
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass="container-with-dots"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite={false}
                                        itemClass=""
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={false}
                                        // renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        arrows={false}
                                        renderButtonGroupOutside={true}
                                        customButtonGroup={<ButtonGroup />}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 40
                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 900,
                                                    min: 0
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 900
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots={false}
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                    >
                                        {
                                            dataCancelReq.map(item => (
                                                <div style={{ margin: "0px 20px 20px 0px", height: '100%' }}>
                                                    <SliderCancelRequestPending 
                                                        key={item} 
                                                        dataItem={item}
                                                        setBookingSidebarDetail={setBookingSidebarDetail} 
                                                        onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} 
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                )}




                                {/* Section - Document require */}
                                {type == "documentrequire" && (

                                    <Carousel
                                        additionalTransfrom={0}
                                        // arrows
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass="container-with-dots"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite={false}
                                        itemClass=""
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={false}
                                        // renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        arrows={false}
                                        renderButtonGroupOutside={true}
                                        customButtonGroup={<ButtonGroup />}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 40
                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 900,
                                                    min: 0
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 900
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots={false}
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                    >
                                        {
                                            data.map(item => (
                                                <div style={{ margin: "0px 20px 20px 0px", height: '100%' }}>
                                                    <SlideDocumentRequire 
                                                        key={item} 
                                                        dataItem={item}
                                                        setBookingSidebarDetail={setBookingSidebarDetail} 
                                                        onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} 
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                )}


                                {/* Section - Paiement manquant */}
                                {type == "missingpayment" && (

                                    <Carousel
                                        additionalTransfrom={0}
                                        // arrows
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass="container-with-dots"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        infinite={false}
                                        itemClass=""
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        pauseOnHover
                                        renderArrowsWhenDisabled={false}
                                        // renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        arrows={false}
                                        renderButtonGroupOutside={true}
                                        customButtonGroup={<ButtonGroup />}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {
                                                    max: 3000,
                                                    min: 1024
                                                },
                                                items: 2,
                                                partialVisibilityGutter: 40
                                            },
                                            mobile: {
                                                breakpoint: {
                                                    max: 900,
                                                    min: 0
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            },
                                            tablet: {
                                                breakpoint: {
                                                    max: 1024,
                                                    min: 900
                                                },
                                                items: 1.1,
                                                partialVisibilityGutter: 30
                                            }
                                        }}
                                        rewind={false}
                                        rewindWithAnimation={false}
                                        rtl={false}
                                        shouldResetAutoplay
                                        showDots={false}
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                    >
                                        {
                                            dataMissingPayment.map(item => (
                                                <div style={{ margin: "0px 20px 20px 0px", height: '100%' }}>
                                                    <SlideMissingPayment 
                                                        key={item} 
                                                        dataItem={item}
                                                        setBookingSidebarDetail={setBookingSidebarDetail} 
                                                        onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} 
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                )}



                            </div>
                        </div>
                    </Col>
                </Row >
            </div >

            {/* Hook - Admin - Edit request - Pending */}
            {/* <AdminEditRequestPending
                isOpenAdminEditRequestPending={isOpenAdminEditRequestPending}
                onCloseOpenAdminEditRequestPending={() => setIsOpenAdminEditRequestPending(false)}
            /> */}
        </div >
    )
}

export default AdminDashboardBookingSlider


// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize.width;
}

