import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./components/screens/auth/authContext";
import store from './redux/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const ClearCacheAndCookies = () => {
  useEffect(() => {
    const clearCacheAndCookies = async () => {
      // Clear all caches
      const keys = await caches.keys();
      keys.forEach(async (key) => {
        await caches.delete(key);
      });

      // Example of clearing cookies
      document.cookie.split(";").forEach(function(c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    };

    clearCacheAndCookies();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </QueryClientProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ClearCacheAndCookies />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
