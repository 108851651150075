import React from "react";
import { Button, Col, Container, Nav, Navbar, NavbarBrand, Offcanvas, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import notFoundImg from '../../../assets/img/page_not_found.gif';
import '../404/clt_page_not_found.css';

const PageNotFound = ({ setIsOpen }) => {

  setIsOpen(false);

  return (
    <>
      <div className="clt_page_not_found">
        <div className="navbar_container">
          <div className="clt_page_not_found_navbar">
            <strong>Page non trouvée</strong>
          </div>
        </div>
        <div className="clt_page_not_found_content">
          <div className="mx-auto w-100 text-center">
            <img src={notFoundImg} alt="logo" />
          </div>
          <h4 className="clt_page_not_found_main_title">Cette page est introuvable</h4>
          <h6 className="clt_page_not_found_sub_title">Essayez de saisir à nouveau l'adresse ou revenez à la page d'accueil</h6>
          <a className="btn btn-dark clt_page_not_found_return_btn" href="/">Retour à la page d'accueil</a>
        </div>
      </div>
    </>
  );
};
export default PageNotFound;
