// Import
import React, { useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "../../../../notification/user_notification.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay4 from "../../../../../../assets/img/confirm_pay_4.png";
import CustomHr from "../../../../../reusable/CustomHr";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionInformationMessageRequestAccept from "../../../../../reusable/SectionInformationMessageRequestAccept.js";
import Booking from "../../../../detail/booking/booking_user";
import Payment from "../../../../payment/Payment";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetailPendingEditRequest.js";
import { SuccessToast } from "../../../../../reusable/Toast";
import SidebarMyBookingUser from "../../../../myBooking/user_mybooking";

/* Const */
const SidebarEditRequestAcceptUser = ({ isOpenSidebarEditRequestAcceptUser, onCloseOpenSidebarEditRequestAcceptUser, setIsOpenSidebarEditRequestAcceptUser }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(eventweddingData);

  /* Const - Booking */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);


  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);


  /* Const - Make a new request  */
  const handleMakeNewRequest = () => {
      SuccessToast("There is the user booking, you can edit what you need");
      setIsOpenSidebarMyBookingUser(true);
  };

  /* Const - Sidebar - Booking */
  const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);



  {/* Sidebar - Admin - Edit request - Accept */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarEditRequestAcceptUser}
        onHide={onCloseOpenSidebarEditRequestAcceptUser}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarEditRequestAcceptUser}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>[SIDEBAR] User - Edit Request - Accept</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          <>
            <div className="clt_mybooking_offcanvas_body">
              <SectionTop
                image={ConfirmPayProfilePicture1}
                title="CLT"
                subTitle="Wedding · 25€ · #111111"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionInformationMessageRequestAccept
                innerTitle="Accept"
                innerSubTitle="The request is accept, you can make a new one if necessary"
              />
            </div>
            <CustomHr
              rowClass="p-0 m-0"
              colClass="clt-booking-hr-pd p-0 w-100"
              hrClass="p-0 m-0 clt-hr-2"
            />
            
            <div className="clt_mybooking_offcanvas_body">
              <h2 className="clt-mybooking-title">Edit request - Accept</h2>
              <SectionDate
                image={ConfirmPay2}
                subText1="Subtext 1"
                subText2="Subtext 2"
                isEditRequest={true}
                oldSubText1="Subtext 1"
                oldSubText2="Subtext 2"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionPriceDetail
                title="Price detail"
                priceListData={priceDetailsSlots}
                totalPrice="10€"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

              {/* Footer */}
              <Row>
                  <Col>

                  <div className="clt-booking-btn-refuse-div">
                      <Button
                      className="shadow-none clt-booking-btn-refuse"
                      onClick={handleMakeNewRequest}
                      >
                      <span>Make a new request</span>
                      </Button>
                  </div>
                  </Col>
              </Row>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>




      {/* Function - Booking : Sidebar date and Accept request*/}
      <Booking
        openBookingSidebar={isBookingSidebar}
        
        onCloseBookingSidebar={(data) => {
          if (data === "Notification") {
            SuccessToast("Edited");
            onCloseOpenSidebarEditRequestAcceptUser();
          }
          setIsBookingSidebar(false);
        }}
        eventType={eventType}
        setEventType={(event) => setEventType(event)}
        isFromEditRequest={true}
      />

      {/* Function - Sidebar - Booking - User */}
      <SidebarMyBookingUser
          isOpenSidebarMyBookingUser={isOpenSidebarMyBookingUser}
          onCloseOpenSidebarMyBookingUser={() => setIsOpenSidebarMyBookingUser(false)}
          isFromMakeNewRequest={true}
      />
    </>
  );
};

export default SidebarEditRequestAcceptUser;
