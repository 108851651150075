import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import React, { useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useAuth } from "../../authContext";
import { RecaptchaVerifier, linkWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import { signInWithPhoneNumber } from "firebase/auth";
import { ErrorToast } from "../../../../reusable/Toast";
import { FirebaseError } from "firebase/app";
import "../../auth-modal.css";
import { checkUser } from "../../api";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import { SuccessToast } from "../../../../reusable/Toast";

const PhoneSignup = ({ handleContinue, authenticationFlow }) => {
	const data = useAuth();
	const [phone, setPhone] = useState(authenticationFlow ? authenticationFlow.phone : data.phone);
	const recaptchaRef = useRef(null);
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const handleClick = async () => {
		try {
			setButtonDisabled(true);
			setLoading(true);
			if (phone === "") {
				ErrorToast("Information manquante", "Veuillez ajouter le numéro de téléphone");
				setButtonDisabled(false); // Réactivez le bouton
				setLoading(false); // Arrêtez le chargement
				return;
			}
	
			const data = await checkUser({ phone });
			if (data.userExists) {
				ErrorToast("Attention", "Un utilisateur existe déjà avec ces informations");
				setButtonDisabled(false); // Réactivez le bouton
				setLoading(false); // Arrêtez le chargement
				return;
			}
	
			if (!recaptchaRef.current) {
				recaptchaRef.current = new RecaptchaVerifier("recaptcha-container", {
					size: "invisible",
				}, auth);
			}
	
			let result = null;
	
			if (auth.currentUser) {
				try {
					result = await linkWithPhoneNumber(
						auth.currentUser,
						phone,
						recaptchaRef.current
					);
				} catch (error) {
					if (
						error instanceof FirebaseError &&
						error.code === "auth/provider-already-linked"
					) {
						result = await signInWithPhoneNumber(auth, phone, recaptchaRef.current);
					}
				}
			} else {
				result = await signInWithPhoneNumber(auth, phone, recaptchaRef.current);
			}
			handleContinue(phone, result);
	
			SuccessToast("Code envoyé", "Un code à 6 chiffres est envoyé sur votre téléphone.");
	
		} catch (error) {
			console.log(error);
			setButtonDisabled(false);
			setLoading(false);
		}
	};

	return (
		<div className="pfr_loginModalVerify">
			<div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
				<Row>
					<Col>
						<div id="recaptcha-container"></div>
						<h2 className="pfr_loginModalH2">Quel est votre numéro de téléphone ?</h2>
					</Col>
				</Row>
				<Row>
					<Col className="pfr_loginModalTelInputDiv">
						<div className="pfr_loginModalTelInputInnerDiv">
							<PhoneInput
								// placeholder="Numéro de téléphone"
								international
								withCountryCallingCode
								defaultCountry="FR"
								countryCallingCodeEditable={false}
								value={phone}
								onCountryChange={(crt) => console.log("crt ", crt)}
								focusInputOnCountrySelection
								autoFocus
								onChange={(value) => setPhone(value)}
								className="pfr_loginModalTelInput"
							/>
							<span className="pfr_loginModalTelInputLabel toFloat">
								Numéro de téléphone
							</span>
						</div>
					</Col>
				</Row>
			</div>
			<div id="recaptcha-container"></div>
			<div className="pfr_loginModalVerifyDiv">
				<Row className="pfr_loginModalSeparatorDiv">
					<div className="pfr_loginModalSeparator"></div>
				</Row>
				<div className="pfr_loginModal_footer">
					<Col>
					<Button
					className="pfr_loginModalBtn shadow-none"
					onClick={handleClick}
					disabled={isButtonDisabled}
					>
					{isLoading ? (
						<LoaderDots /> 
					) : (
						"Continuer"
					)}
					</Button>
					</Col>
				</div>
			</div>
		</div>
	);
};

export default PhoneSignup;
