/* Import */

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Offcanvas,
  // OverlayTrigger,
  Row,
  // Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../constants";
// import { Link } from "react-router-dom";
import SectionRemainingPaymentInfo from "../../reusable/booking/SectionRemainingPaymentInfo";
import ConfirmPay4 from "../../../assets/img/confirm_pay_4.png";
import IconRemainingPayment from "../../../assets/img/remaining_payment.png";
import CustomHr from "../../reusable/CustomHr";
import SectionPaymentMethodRemainingPayment from "../../reusable/booking/SectionPaymentMethodRemainingPayment";
import SectionPaymentPlan from "../../reusable/booking/SectionPaymentPlan";
import SectionPriceDetail from "../../reusable/booking/SectionPriceDetail";
import { useDispatch } from "react-redux";
import { createBooking, paynowSubscription } from "../../../redux/actions/booking";
import { ErrorToast } from "../../reusable/Toast";





/* Const */
const RemainingPayment = ({
  isSidebar,
  onCloseSidebar,
  isPaymentCardsOpen,
  selectedBooking,
  selectedCard,
  subscription,
  setSelectedBooking
}) => {
  const [remainingPaymentScreens, setRemainingPaymentScreens] = useState({
    remainingPayment: true,
    confirmAndPayRemainingPayment: false,
  });
  console.log('Here is selected card', selectedCard)
  const [selectedPlan, setSelectedPlan] = useState({});
  const [futurePayments, setFuturePayments] = useState([]);
  const [tot, setTot] = useState(0);
  const dispatch = useDispatch();
  const [payDisable, setpayDisable] = useState(false)
  const handleBack = (from, to) => {
    setRemainingPaymentScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };

  const handlePayNow = () => {
    setRemainingPaymentScreens((prevState) => ({
      ...prevState,
      remainingPayment: false,
      confirmAndPayRemainingPayment: true,
    }));
  };

  const payForBooking = async () => {
    setpayDisable(true)
    let dataToPass = {
      bookingId: selectedBooking?.id,
      allpayment: selectedPlan?.allpayment,
      paymentmethodId: selectedCard?.id,
      price: selectedPlan?.plan
    }
    console.log(dataToPass);
    const response = await dispatch(paynowSubscription(JSON.stringify(dataToPass)))
    setpayDisable(false)
    console.log('this is response', response);
    let errFlag = false;
    let errMsg = "Information missing";
    if (response.error === true) {
      console.log('I am error log')
      if (dataToPass.price === undefined) {
        errFlag = true;
        errMsg += "\n Payment Plan";
      }
      if (dataToPass.paymentmethodId === undefined) {
        errFlag = true;
        errMsg += "\nPayment Method";
      }
      if (errFlag) {
        ErrorToast(errMsg);
      }

    } else {
      console.log("RESS");
      console.log(response);
      selectedBooking.subscription_payments = response.message?.subscription_payments;
      setSelectedBooking(selectedBooking);

      const price = Math.round((selectedBooking?.price / 3 + Number.EPSILON) * 100) / 100;
      if (selectedBooking?.subscription_payments?.length === 1) {
        setFuturePayments([
          {
            id: 1,
            title: "Prochain paiement",
            plan: price,
            allpayment: false,
            days: calculateInstallements(2)
          },
          {
            id: 2,
            title: "Tous les paiements restants",
            plan: price + price,
            allpayment: true,
            days: calculateInstallements(3)
          },
        ]);
      } else if (selectedBooking?.subscription_payments?.length === 2) {
        setFuturePayments([
          {
            id: 2,
            title: "Tous les paiements restants",
            plan: price,
            allpayment: true,
            days: calculateInstallements(3)
          },
        ]);
      }
      onCloseSidebar();
    }
  }


  const calculateTot = (selectedBooking) => {
    console.log(selectedBooking?.subscription_payments)
    let tot = 0;
    for (let i = 0; i < selectedBooking?.subscription_payments?.length; i++) {
      console.log("Athulae");
      console.log(selectedBooking);
      tot += (selectedBooking?.subscription_payments[i]?.subtotal / 100);
    }
    return tot;
  }

  const calculateInstallements = (installment) => {
    var today = new Date();
    var date_to_reply = new Date(selectedBooking?.ChildTimeSlot?.startDate);
    var timeinmilisec = date_to_reply.getTime() - today.getTime();
    var totalDays = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
    var slotsround = Math.round(totalDays / 3);
    // setTotDays(totalDays);
    if (installment === 2) {
      return slotsround;
    } else {
      return slotsround * 2;
    }
  }

  useEffect(() => {
    const price = Math.round((selectedBooking?.price / 3 + Number.EPSILON) * 100) / 100;
    if (selectedBooking?.subscription_payments?.length === 1) {
      setFuturePayments([
        {
          id: 1,
          title: "Prochain paiement",
          plan: price,
          allpayment: false,
          days: calculateInstallements(2)
        },
        {
          id: 2,
          title: "Tous les paiements restants",
          plan: price + price,
          allpayment: true,
          days: calculateInstallements(3)
        },
      ]);
    } else if (selectedBooking?.subscription_payments?.length === 2) {
      setFuturePayments([
        {
          id: 2,
          title: "Tous les paiements restants",
          plan: price,
          allpayment: true,
          days: calculateInstallements(3)
        },
      ]);
    }
  }, [selectedBooking])

  const getBalancePayment = (selectedBooking) => {
    const price = Math.round((selectedBooking?.price / 3 + Number.EPSILON) * 100) / 100;
    let two = `${price}€ in ${calculateInstallements(2)} days`;
    let three = `${price}€ in ${calculateInstallements(3)} days`;
    if (selectedBooking?.subscription_payments?.length === 1) {
      return [two, three];
    } else if (selectedBooking?.subscription_payments?.length === 2) {
      return [three];
    } else {
      return [];
    }
  }

  const setCurrentPlan = (plan) => {
    console.log(plan)
    setSelectedPlan(plan);
    setTot(plan.plan);
  }


  {/* Sidebar : Remaining payment / Confirm and pay - Remaining payment */ }
  return (

    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isSidebar}
      onHide={onCloseSidebar}
    >



      {/* Header */}
      <Offcanvas.Header className="justify-content-start clt_payment_offcanvas_header">
        {remainingPaymentScreens.remainingPayment && (
          <React.Fragment>

            {/* Header - Remaining payment */}
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseSidebar}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Offcanvas.Title>Paiement restant</Offcanvas.Title>
            </div>
          </React.Fragment>
        )}

        {/* Header - Confirm and pay - Remaining payment */}
        {remainingPaymentScreens.confirmAndPayRemainingPayment && (
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={() =>
                handleBack("confirmAndPayRemainingPayment", "remainingPayment")
              }
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Offcanvas.Title>
                Payer maintenant
              </Offcanvas.Title>
            </div>
          </React.Fragment>
        )}
      </Offcanvas.Header>





      {/* Body */}
      <Offcanvas.Body className={`clt_payment_offcanvas_body`}>


        {/* Body - Remaining payment */}
        {remainingPaymentScreens.remainingPayment && (
          <>
            <h2 className="clt-mybooking-title">Plan de paiement</h2>
            {
              setSelectedBooking?
                  <>
                    <SectionPaymentMethodRemainingPayment
                        text="Carte bancaire"
                        image={ConfirmPay4}
                        subText={<span>{selectedCard === null ? 'Ajouter un moyen de paiement' : `${selectedCard?.number}`}</span>}
                        buttonText={<span>{selectedCard === null ? 'Ajouter' : `Modifier`}</span>}
                        // buttonText={"Edit"}
                        // subText={<span>{subscription?.card?.number}</span>}
                        handleClick={isPaymentCardsOpen}

                    />
                    <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  </>:<></>
            }
            <SectionRemainingPaymentInfo
              image={IconRemainingPayment}
              title={`Paiement restant: ` + `${selectedBooking?.subscription_payments === null ? '0' : (3 - selectedBooking?.subscription_payments?.length)}`}
              // paymentTexts={["No remaining payment"]}
              paymentTexts={getBalancePayment(selectedBooking)}
            />
            <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
            {
              setSelectedBooking?

                  <Row>
                    <Col>
                      <div className="clt-booking-cp-pay-now mb-5">
                        <Button
                            className="shadow-none clt-booking-btn-gray"
                            onClick={handlePayNow}
                        >
                          <span>Payer maintenant</span>
                        </Button>
                        <p className="clt-after-btn-p">
                          Vous serez redirigé vers une page de paiement avec la possibilité de payer une partie ou la totalité des paiements restants.
                        </p>
                      </div>
                    </Col>
                  </Row>:<></>
            }
          </>
        )}


        {/* Body - Confirm and pay - Remaining payment */}
        {remainingPaymentScreens.confirmAndPayRemainingPayment && (
          <>
            <SectionPaymentPlan
              paymentPlanList={futurePayments}
              isChange={true}
              selectedPlan={selectedPlan}
              setSelectedPlan={(plan) => setCurrentPlan(plan)}
            />
            <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
            <SectionPriceDetail
              title="Détail du prix"
              priceListData={[
                { title: "Paiement", tooltipMsg: null, price: tot + "€" },
              ]}
              totalPrice={tot}
            />
            <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
            <SectionPaymentMethodRemainingPayment
              title="Moyen de paiement"
              text="Carte bancaire"
              image={ConfirmPay4}
              subText={<span>{selectedCard === null ? 'Ajouter un moyen de paiement' : `${selectedCard?.number}`}</span>}
              handleClick={isPaymentCardsOpen}
              buttonText={"Modifier"}
            />
            <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
            <Row>
              <Col>
                <div className="clt-booking-cp-div7-btn mb-5">
                  <Button
                    className="shadow-none clt-booking-btn-black"
                    // onClick={onCloseSidebar}
                    onClick={payForBooking}
                    disabled={payDisable}
                  >
                    <span>Payer</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default RemainingPayment;
