// import
import React, { useEffect, useState } from "react";
import { Offcanvas, Button, Form, InputGroup, Row, Col, Dropdown } from "react-bootstrap";
import { IMG_ALT, eventweddingData } from "../../../../../constants";
import { useForm, useFieldArray } from "react-hook-form";
import "./AdminDashboardTimeslot.css";
import "./datepicker.css";

// import - Image/Icon

import BackArrow from "../../../../../assets/img/back_arrow.png";
import CancelIcon from "../../../../../assets/img/cancel_icon.png";
import NextArrow from "../../../../../assets/img/back_arrow_right.png";
import GalleryImage1 from "../../../../../assets/img/detail_slider_1_1.png";

// import - Component - Date picker
import { RangeDatePicker } from "react-google-flight-datepicker";

// import - Component - Select option room
import SectionTimeslotOptionRoom from "./section_select_option_room/SectionSelectOption";
import SelectedOptionRoom from "../AdminDashboardTimeslot/component_selected_option_room/component_selected_option_room";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEvents } from "../../../../../redux/actions/avent";
import { useSelector } from "react-redux";
import { createTimeSlot, DeleteTimeSlot, getEventsTimeSlots, getSlotRooms, getTimeSlots, updateTimeSlot } from "../../../../../redux/actions/timeSlot";
// import TimePicker from 'react-bootstrap-time-picker';

import moment from "moment";
import "moment/locale/fr";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import { getRooms } from "../../../../../redux/actions/room";




/* Const */


/* Const - Input - Hour*/
const HourList = [
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 AM",
];

/* Const - Screen - Manage timeslot */
const AdminDashboardTimeslot = ({
  isTimeSlotSidebar,
  onCloseTimeSlotSidebar,
}) => {

  moment.locale("fr");

  const [frenchDayNames, setFrenchDayNames] = useState({
    'monday': 'Lundi',
    'tuesday': 'Mardi',
    'wednesday': 'Mercredi',
    'thursday': 'Jeudi',
    'friday': 'Vendredi',
    'saturday': 'Samedi',
    'sunday': 'Dimanche'
  })
  /* Const - Open */
  const [screen, setScreen] = React.useState(1);

  /* Const - Back */
  const onBack = () => {
    if (screen === 1) {
      reset();
      onCloseTimeSlotSidebar();
    }
    else {
      reset();
      setScreen(1);
    }
  };

  /* Const - Close */
  const onClose = () => {
    onCloseTimeSlotSidebar();
    setScreen(1);
  };


  // timeslot integration start _______________________________________
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({})
  const [selectedTimeSlotName, setSelectedTimeSlotName] = useState('')
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const allEvents = useSelector((state) => state.allEvents.events);
  const eventsTimeSlots = useSelector((state) => state.eventsTimeSlots.eventsTimeSlots);
  const [screenChange, setScreenChange] = useState(false);
  const allRooms = useSelector((state) => state.allRooms.rooms);

  const [createdTimeSlot, setCreatedTimeSlot] = useState({})
  const [selectedEvent, setSelectedEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [selectedSlotRoomsTotal, setSelectedSlotRoomsTotal] = useState(0)
  const [roomsCount, setRoomsCount] = useState(0)


  useEffect(() => {
    dispatch(getEvents());
  }, []);

  useEffect(() => {
    dispatch(getRooms());
  }, []);

  useEffect(() => {
    dispatch(getTimeSlots());
  },[]);

  useEffect(() => {
    dispatch(getEventsTimeSlots());
  },[]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const dataToSend = {
        isAvailable: true,
        endHour: data?.end_hour,
        startHour: data?.start_hour,
        endDay: data?.end_day,
        startDay: data?.start_day,
        eventId: parseInt(data?.my_event),
        startTime: moment(data?.start_range).format("YYYY-MM-DD"),
        endTime: moment(data?.end_range).format("YYYY-MM-DD"),
        price: data?.slot_price,
        guests: parseInt(data?.guest_number_limit),
      }
  
      const updateData = JSON.stringify(dataToSend);
      const response = await dispatch(createTimeSlot(updateData));

      if (response.error) {
        alert('Error while adding timeslot');
      } else {
        if (response?.data?.id) {
          setCreatedTimeSlot(response?.data);
          setScreen(4);
        }
        dispatch(getEventsTimeSlots());
        reset();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async (data) => {
    const myTimeSlot = eventsTimeSlots.find((item) => item.eventName=== data?.my_event)
    const dataToSend = {
      isAvailable: true,
      endHour: data?.end_hour,
      startHour: data?.start_hour,
      endDay: data?.end_day,
      startDay: data?.start_day,
      eventId: myTimeSlot.eventID,
      startTime: moment(data?.start_range).format("YYYY-MM-DD"),
      endTime: moment(data?.end_range).format("YYYY-MM-DD"),
      price: data?.slot_price,
      guests: parseInt(data?.guest_number_limit),
    }
    const currentId = selectedTimeSlot?.id
    setSelectedTimeSlot(null)
    const updateData = JSON.stringify(dataToSend);
    const response = await dispatch(updateTimeSlot(updateData, currentId))
    if (response.error) {
      alert('error while adding timeslot')
    } else {
      dispatch(getEventsTimeSlots());
      setSelectedTimeSlotName(data?.event)
      reset();
      onBack();
    }
  }

  const handleDelete = async () => {
    const response = await dispatch(DeleteTimeSlot(selectedTimeSlot?.id))
    if (response.error) {
      alert('error while adding timeslot')
    } else {
      setScreen(1);
      dispatch(getEventsTimeSlots());
    }
  }


  // end__________________________________________________
  /* ------------------------------------------------------------------ */

  /* Const - Screen 1 - Manage Timeslot : List  */

  /* Const - List - Button - Filter Event */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="clt_mybooking_header_dropdown"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      See all <i className="fas fa-chevron-down"></i>
    </Link>
  ));

  /* ------------------------------------------------------------------ */

  /* Const - Screen 2 and 3 - Manage Timeslot - Add/Edit */

  /* Const - Form */
  const defaultValues = {
    policy: [
      {
        policy_range: "0 - 30",
        policy_percentage: "",
      },
    ],
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "policy", // unique name for your Field Array
  });


  /* Const - Form - Section - Select option room*/
  const [selectedOptionRoom, setSelectedOptionRoom] = useState({});
  const [isSelectedOptionRoomSidebar, setisSelectedOptionRoomSidebar] =
    useState(false);
  const [isRoomPricePerNight, setIsRoomPricePerNight] = useState(false);
  const [isNumberOfNightMax, setIsNumberOfNightMax] = useState([
    { id: "1", title: "1 night", isActive: true, amount: null },
    { id: "2", title: "2 night", isActive: true, amount: null },
    { id: "3", title: "3 night", isActive: true, amount: null },
    { id: "4", title: "4 night", isActive: true, amount: null },
    { id: "5", title: "5 night", isActive: true, amount: null },
  ]);
  const [roomList, setRoomList] = useState([
    {
      id: "1",
      title: "Room 1",
      image: GalleryImage1,
      night_quantity_limit: 0,
      night_price: 0,
    },
    {
      id: "2",
      title: "Room 2",
      image: GalleryImage1,
      night_quantity_limit: 0,
      night_price: 0,
    },
    {
      id: "3",
      title: "Room 3",
      image: GalleryImage1,
      night_quantity_limit: 0,
      night_price: 0,
    },
  ]);


  /* Const - Form - Button - Submit timeslot set */


  /* ------------------------------------------------------------------------------------------------------------------------------- */

  /* Screen 1 : List */
  /* Screen 2 and 3 : Add/Edit Timeslot */

  /* ------------------------------------------------------------------------------------------------------------------------------- */


  /* Screen */
  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_timeslot_offcanvas"
      show={isTimeSlotSidebar}
      onHide={onClose}
    >

      {/* Screen - Header */}
      <Offcanvas.Header className="justify-content-start clt_admin_dash_timeslot_offcanvas_header">
        <React.Fragment>

          {/* Button close */}
          <Button
            type="button"
            className="shadow-none clt_admin_dash_timeslot_offcanvas_header_back_btn btn-close btn-back d-flex"
            aria-label="Close"
            onClick={onBack}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </Button>

          {/* Title */}
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>
              {screen === 1
                ? "Créneau"
                : screen === 2
                ? "Ajouter un créneau"
                : screen === 3
                ? "Modifier un créneau"
                : "Option"}
            </Offcanvas.Title>
          </div>

          {/* Button */}
          {screen === 1 && (
            <>
              {/* Button - Filter state 
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                  <Dropdown.Item
                    onClick={() => console.log("See All")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    See All
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => console.log("Ongoing")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Ongoing
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => console.log("Coming")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Coming
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => console.log("Finished")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Finished
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => console.log("Cancelled")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Cancelled
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>*/}

              {/* Button - Add timeslot */}
              <div
                onClick={() => {
                  setScreen(2);
                  setValue("event", "Wedding");
                }}
                className="pfr_navbarRightTxt clt_admin_dash_timeslot_RightTxt_3"
              >
                <span>Ajouter</span>
              </div>
            </>
          )}

          {/* Button - Delete timeslot */}
          {screen === 3 && (
            <div className="clt_header_delete_button" style={{width: '35%'}} onClick={handleDelete}>
              <span>
                Supprimer<i className="fa-solid fa-trash"></i>
              </span>
            </div>
          )}
        </React.Fragment>
        {/* )} */}
      </Offcanvas.Header>


      {/* Screen - Body */}
      <Offcanvas.Body style={{ padding: "1rem 0rem" }}>
        <>


          {/* Screen 1 - Body - List */}
          {screen === 1 && (
            <div className="clt_admin_dash_timeslot_offcanvas_body">

              {/* Section - Wedding */}
              {eventsTimeSlots?.map((event,index) => {
                return(
                  <div
                    key={index}
                    className="clt_admin_dash_timeslot_event_detail"
                  >
                    <p className="clt_admin_dash_timeslot_event_detail_heading">
                      {event.eventName}
                    </p>
                    {event?.timeSlots?.map((timeslot, timeSlotindex) => {
                      return (
                        <div key={timeSlotindex} className="clt_admin_dash_timeslot_event_detail_container"
                        onClick={() => {
                          setSelectedTimeSlot({})
                          setSelectedTimeSlot(timeslot);
                          setSelectedTimeSlotName(event.eventName);
                          setValue("event", "Wedding");
                          setScreen(3);
                        }}
                        >
                        <div className="clt_admin_dash_timeslot_event_detail_box">
                          <p className="text">{frenchDayNames[timeslot?.startDay?.toLowerCase()]} {timeslot?.startHour?.slice(0, 5)} → {frenchDayNames[timeslot?.endDay?.toLowerCase()]} {timeslot?.endHour?.slice(0, 5)}</p>
                          <p className="description">
                            <div className="icon"></div>
                            <div className="detail">{new Date(timeslot.startTime).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "2-digit" }).replace(/\//g, '/')} - {new Date(timeslot.endTime).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "2-digit" }).replace(/\//g, '/')}</div>
                          </p>
                          <div className="clt_admin_dash_timeslot_event_detail_subdetail">
                            <div className={`subtitle wedding`} style={{ color: event.eventColor, backgroundColor: `${event.eventBackgrount}30`, fontWeight: 'bold' }}>{timeslot.price}€</div>
                          </div>
                        </div>
                        <div className="clt_admin_dash_store_container_arrow">
                          <img
                            src={NextArrow}
                            className="clt_admin_arrow"
                            alt={IMG_ALT}
                          />
                        </div>
                      </div>
                      )
                    })}
                </div>
                )
              })}
            </div>
          )}
        </>
        <>

          {/* Screen 2 - Body - Add Timeslot : Step 1/2 (Set timeslot information) */}
          {screen === 2 ? (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="clt_admin_dash_timeslot_offcanvas_body">


                  {/* Section - Event */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading">
                      Évenement
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Select
                        id="floatingInput1"
                        type="select"
                        placeholder="Events"
                        {...register("my_event")}
                      >
                        {allEvents?.map((item, index) =>{
                          return (
                          <option key={index} value={item.id}>{item.title}</option>
                        )
                        }
                        )}
                      </Form.Select>
                      <label htmlFor="floatingInput1">Évenement</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Timeslot */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading">
                      Créneau
                    </p>
                    <div className="clt_admin_dash_timeslot_edit_sub_box">
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Select
                          id="floatingInput1"
                          type="select"
                          placeholder="Jour"
                          {...register("start_day")}
                        >
                          <option value="Monday">Lundi</option>
                          <option value="Tuesday">Mardi</option>
                          <option value="Wednesday">Mercredi</option>
                          <option value="Thursday">Jeudi</option>
                          <option value="Friday">Vendredi</option>
                          <option value="Saturday">Samedi</option>
                          <option value="Sunday">Dimanche</option>
                        </Form.Select>
                        <label htmlFor="floatingInput1">Jour</label>
                      </Form.Floating>
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Control
                          id="floatingInput1"
                          type="time"
                          placeholder="Heure"
                          dateTime="YYYY-MM-DDThh:mm:ssTZD"
                          {...register("start_hour")}
                        />
                        <label htmlFor="floatingInput1">Heure</label>
                      </Form.Floating>
                    </div>
                    <div
                      className="clt_admin_dash_timeslot_edit_sub_box"
                      style={{ marginTop: "10px" }}
                    >
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Select
                          id="floatingInput1"
                          type="select"
                          placeholder="Day"
                          {...register("end_day")}
                        >
                          <option value="Monday">Lundi</option>
                          <option value="Tuesday">Mardi</option>
                          <option value="Wednesday">Mercredi</option>
                          <option value="Thursday">Jeudi</option>
                          <option value="Friday">Vendredi</option>
                          <option value="Saturday">Samedi</option>
                          <option value="Sunday">Dimanche</option>
                        </Form.Select>
                        <label htmlFor="floatingInput1">Jour</label>
                      </Form.Floating>
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Control
                          id="floatingInput1"
                          type="time"
                          placeholder="Heure"
                          dateTime="YYYY-MM-DDThh:mm:ssTZD"
                          {...register("end_hour")}
                        />
                        <label htmlFor="floatingInput1">Heure</label>
                      </Form.Floating>
                    </div>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Repetition */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Repetition
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Le créneau se répétera chaque semaine aux mêmes jours et heures pendant une période définie
                    </p>
                    <div className="clt-custom-datepicker" onClick={() => {
                      const dayNameMap = {
                        'Mo': 'Lu',
                        'Tu': 'Ma',
                        'We': 'Me',
                        'Th': 'Je',
                        'Fr': 'Ve',
                        'Sa': 'Sa',
                        'Su': 'Di',
                      };

                      const weekdayElements = document.querySelectorAll('.weekday');

                      weekdayElements.forEach((element) => {
                        const currentText = element.textContent;
                        if (dayNameMap[currentText]) {
                          element.textContent = dayNameMap[currentText];
                        }
                      })

                      const flipperButtons = document.querySelectorAll('.flipper-button');

                      flipperButtons.forEach((element) => {
                        element.addEventListener('click', () => {
                          setTimeout(() => {
                            const monthNameMap = {
                              'Jan': 'Jan',
                              'Feb': 'Fév',
                              'Mar': 'Mar',
                              'Apr': 'Avr',
                              'May': 'Mai',
                              'Jun': 'Juin',
                              'Jul': 'Juil',
                              'Aug': 'Août',
                              'Sep': 'Sept',
                              'Oct': 'Oct',
                              'Nov': 'Nov',
                              'Dec': 'Déc',
                            };

                            const monthNameElements = document.querySelectorAll('.month-name');

                            monthNameElements.forEach((element) => {
                              const currentText = element.textContent.trim();
                              
                              const monthMatch = monthNameMap[currentText.split(' ')[0]]

                              if(monthMatch) {
                                element.textContent = currentText.replace(currentText.split(' ')[0], monthMatch);
                              }
                            });
                          }, 300);


                        });
                      });
                    }}>
                      <RangeDatePicker
                        startDate={getValues("start_range")}
                        endDate={getValues("end_range")}
                        onChange={(startDate, endDate) => {
                          setValue("start_range", startDate);
                          setValue("end_range", endDate);

                          const startDateText = document.querySelectorAll('#start-date-input-button > .selected-date')
                          const endDateText = document.querySelectorAll('#end-date-input-button > .selected-date')

                          if (startDate) {
                            startDateText.forEach((element) => {
                              element.textContent = moment(startDate).format("ddd, DD MMM")
                              element.style.textTransform = "capitalize";
                            })
                          }

                          if (endDate) {
                            endDateText.forEach((element) => {
                              element.textContent = moment(endDate).format("ddd, DD MMM")
                              element.style.textTransform = "capitalize";
                            })         
                          }
                        }}
                        minDate={null}
                        monthFormat="MMM YYYY"
                        highlightToday={true}
                        startDatePlaceholder="Début"
                        endDatePlaceholder="Fin"
                        disabled={false}
                        className=""
                        startWeekDay="monday"
                      />
                    </div>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Price */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading">
                      Prix
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput10"
                        type="number"
                        placeholder="Price"
                        {...register("slot_price")}
                        defaultValue= ""
                      />
                      <label htmlFor="floatingInput10">Prix</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>
                  {/* <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Guest number
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Set a max limit of guest
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="number"
                        placeholder="Damage deposit price slot"
                        {...register("guest_number_limit")}
                      />
                      <label htmlFor="floatingInput1">Guest number</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div> */}

                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Nombre d'invité
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Définissez la limite d'invité pour ce créneau
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="number"
                        placeholder="Damage deposit price slot"
                        defaultValue={160}
                        {...register("guest_number_limit")}
                      />
                      <label htmlFor="floatingInput1">Nombre d'invité</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>

                  {/* Section - Select option room */}
                  {/* <SectionTimeslotOptionRoom
                    selectedOptionRoom={selectedOptionRoom}
                    setSelectedOptionRoom={(plan) =>
                      setSelectedOptionRoom(plan)
                    }
                    onClick={() => setisSelectedOptionRoomSidebar(true)}
                    isRoomPricePerNight={isRoomPricePerNight}
                    isNumberOfNightMax={isNumberOfNightMax}
                    roomList={roomList}
                  /> */}


                  {/* Section - Policy refund (Not include) */}
                  {/* <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Damage deposit price
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Funds in plus of slot price hold and give back 14 days
                      after departure date
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="number"
                        placeholder="Damage deposit price slot"
                        {...register("slot_deposit_price")}
                      />
                      <label htmlFor="floatingInput1">
                        Damage deposit price slot
                      </label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div> */}
                  {/* <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Cancellation policy
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Percentage of slot price refund to the user
                    </p>

                    {fields.map((field, index) => {
                      return (
                        <>
                          {fields.length === 1 ? (
                            <div
                              key={field.id}
                              className="clt_admin_dash_timeslot_policy_box"
                            >
                              <InputGroup>
                                <Form.Select
                                  id="floatingInput1"
                                  type="select"
                                  placeholder=""
                                  {...register(`policy.${index}.policy_range`)}
                                >
                                  <option value="0 - 30">0 - 30 days</option>
                                  <option value="0 - 60">0 - 60 days</option>
                                  <option value="0 - 90">0 - 90 days</option>
                                </Form.Select>

                                <Form.Control
                                  id="floatingInput1"
                                  type="number"
                                  placeholder=""
                                  {...register(
                                    `policy.${index}.policy_percentage`
                                  )}
                                />
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  %
                                </InputGroup.Text>
                              </InputGroup>
                              {fields.length !== 1 && (
                                <div
                                  className="clt_admin_dash_timeslot_cancel"
                                  onClick={() => remove(index)}
                                >
                                  <img src={CancelIcon} alt={IMG_ALT} />
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              <div
                                key={field.id}
                                className={`clt_admin_dash_timeslot_policy_box ${
                                  index === 0
                                    ? "clt_admin_dash_timeslot_policy_box_first"
                                    : index === fields.length - 1
                                    ? "clt_admin_dash_timeslot_policy_box_last"
                                    : "clt_admin_dash_timeslot_policy_box_center"
                                }`}
                              >
                                <InputGroup>
                                  <Form.Select
                                    id="floatingInput1"
                                    type="select"
                                    placeholder=""
                                    {...register(
                                      `policy.${index}.policy_range`
                                    )}
                                  >
                                    <option value="0 - 30">0 - 30 days</option>
                                    <option value="0 - 60">0 - 60 days</option>
                                    <option value="0 - 90">0 - 90 days</option>
                                  </Form.Select>

                                  <Form.Control
                                    id="floatingInput1"
                                    type="number"
                                    placeholder=""
                                    {...register(
                                      `policy.${index}.policy_percentage`
                                    )}
                                  />
                                  <InputGroup.Text id="inputGroup-sizing-sm">
                                    %
                                  </InputGroup.Text>
                                </InputGroup>
                                {fields.length !== 1 && (
                                  <div
                                    className="clt_admin_dash_timeslot_cancel"
                                    onClick={() => remove(index)}
                                  >
                                    <img src={CancelIcon} alt={IMG_ALT} />
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                    <Button
                      className="clt_admin_dash_timeslot_addlineButton shadow-none"
                      type="button"
                      onClick={() =>
                        append({
                          policy_range: "0 - 30",
                          policy_percentage: "",
                        })
                      }
                    >
                      <span>Add a line</span>
                    </Button>
                  </div> */}
                </div>


                {/* Button - Save */}
                <div className="clt_admin_dash_saveButton">
                  <Button
                    type="submit"
                    className="clt_admin_dash_timeslot_formButton shadow-none"
                    disabled={isLoading}
                  >
                    {isLoading ? <LoaderDots/> : <span>Enregistrer</span>}
                  </Button>
                </div>
              </form>
            </>
          ) : (
            ""
          )}
        </>

        <>

          {/* Screen 3 - Body - Edit timeslot */}
          {(screen === 3 && selectedTimeSlot) ? (
            <>
              <form onSubmit={handleSubmit(onUpdate)}>
                <div className="clt_admin_dash_timeslot_offcanvas_body">

                  {/* Section - Event */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading">
                      Évenement
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Select
                        id="floatingInput1"
                        type="select"
                        placeholder="Évenement"
                        onChange={(e) => setValue("my_event", e.target.value)}
                        {...register("my_event")}
                        defaultValue={selectedTimeSlotName}
                      >
                        {allEvents?.map((item, index) =>{
                          return (
                          <option value={item.title}>{item.title}</option>
                        )
                        }
                        )}
                      </Form.Select>
                      <label htmlFor="floatingInput1">Évenement</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Timeslot */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading">
                      Créneau
                    </p>
                    <div className="clt_admin_dash_timeslot_edit_sub_box">
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Select
                          id="floatingInput1"
                          type="select"
                          placeholder="Jour"
                          {...register("start_day")}
                          defaultValue={selectedTimeSlot?.startDay}
                        >
                          <option value="Monday">Lundi</option>
                          <option value="Tuesday">Mardi</option>
                          <option value="Wednesday">Mercredi</option>
                          <option value="Thursday">Jeudi</option>
                          <option value="Friday">Vendredi</option>
                          <option value="Saturday">Samedi</option>
                          <option value="Sunday">Dimanche</option>
                        </Form.Select>
                        <label htmlFor="floatingInput1">Jour</label>
                      </Form.Floating>
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Control
                          id="floatingInput1"
                          type="time"
                          placeholder="Heure"
                          dateTime="YYYY-MM-DDThh:mm:ssTZD"
                          defaultValue={selectedTimeSlot?.startHour}
                          {...register("start_hour")}
                        />
                        <label htmlFor="floatingInput1">Heure</label>
                      </Form.Floating>
                    </div>
                    <div
                      className="clt_admin_dash_timeslot_edit_sub_box"
                      style={{ marginTop: "10px" }}
                    >
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                        <Form.Select
                          id="floatingInput2"
                          type="select"
                          placeholder="Jour"
                          {...register("end_day")}
                          defaultValue={selectedTimeSlot?.endDay}
                        >
                          <option value="Monday">Lundi</option>
                          <option value="Tuesday">Mardi</option>
                          <option value="Wednesday">Mercredi</option>
                          <option value="Thursday">Jeudi</option>
                          <option value="Friday">Vendredi</option>
                          <option value="Saturday">Samedi</option>
                          <option value="Sunday">Dimanche</option>
                        </Form.Select>
                        <label htmlFor="floatingInput2">Jour</label>
                      </Form.Floating>
                      <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput3"
                        type="time"
                        placeholder="Heure"
                        defaultValue={selectedTimeSlot.endHour}
                        {...register("end_hour")}
                      />
                      <label htmlFor="floatingInput3">Heure</label>
                    </Form.Floating>
                    </div>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Repetition */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Répetition
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Le créneau se répétera chaque semaine aux mêmes jours et heures pendant une période définie 
                    </p>
                    <div className="clt-custom-datepicker">
                    <RangeDatePicker
                       startDate={selectedTimeSlot.startTime ? new Date(selectedTimeSlot.startTime) : null}
                       endDate={selectedTimeSlot.endTime ? new Date(selectedTimeSlot.endTime) : null}
                      onChange={(startDate, endDate) => {
                        setValue("start_range", startDate);
                        setValue("end_range", endDate);
                      }}
                      minDate={new Date()}
                      monthFormat="MMM YYYY"
                      highlightToday={true}
                      startDatePlaceholder="Début"
                      endDatePlaceholder="Fin"
                      disabled={false}
                      className=""
                      startWeekDay="monday"
                    />
                    </div>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Price */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading">
                      Prix
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput4"
                        type="number"
                        placeholder="Prix"
                        {...register("slot_price")}
                        defaultValue={selectedTimeSlot?.price}
                      />
                      <label htmlFor="floatingInput4">Prix</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <p className="clt_admin_dash_timeslot_edit_box_heading_2">
                      Invité
                    </p>
                    <p className="clt_admin_dash_timeslot_edit_box_subheading">
                      Définissez la limite d'invité pour ce créneau
                    </p>
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Control
                        id="floatingInput5"
                        type="number"
                        placeholder="Nombre invité"
                        {...register("guest_number_limit")}
                        defaultValue={selectedTimeSlot.guests}
                      />
                      <label htmlFor="floatingInput5">Nombre invité</label>
                    </Form.Floating>
                    <div className="clt_admin_dash_timeslot_hr"></div>
                  </div>


                  {/* Section - Select option room */}
                  <SectionTimeslotOptionRoom
                    selectedOptionRoom={selectedOptionRoom}
                    title = {true}
                    setSelectedOptionRoom={(plan) =>
                      setSelectedOptionRoom(plan)
                    }
                    onClick={() => {
                      console.log('--All Rooms--');
                      console.log(allRooms);
                      setIsEdit(true)
                      setisSelectedOptionRoomSidebar(true)
                    }
                    }
                    isRoomPricePerNight={isRoomPricePerNight}
                    isNumberOfNightMax={isNumberOfNightMax}
                    roomList={roomList}
                    selectedTimeSlot = {selectedTimeSlot}
                    isEdit = {true}
                  />
                </div>


                {/* Button - Save */}
                <div className="clt_admin_dash_saveButton">
                  <Button
                    type="submit"
                    className="clt_admin_dash_timeslot_formButton shadow-none"
                  >
                    <span>Enregistrer</span>
                  </Button>
                </div>
              </form>
            </>
          ) : (
            ""
          )}
        </>

        <>

          {/* Screen 4 - Body - Add Timeslot : Step 2/2 (Select option) */}
          {screen === 4 ? (
            <>
              <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
              <div className="clt_admin_dash_timeslot_offcanvas_body" style={{height: '75vh'}}>
                <SectionTimeslotOptionRoom
                    selectedOptionRoom={selectedOptionRoom}
                    title = {true}
                    setSelectedOptionRoom={(plan) =>
                      setSelectedOptionRoom(plan)
                    }
                    onClick={() => {
                      setIsEdit(false)
                      setisSelectedOptionRoomSidebar(true)
                    }
                    }
                    isRoomPricePerNight={isRoomPricePerNight}
                    isNumberOfNightMax={isNumberOfNightMax}
                    roomList={roomList}
                    createdTimeSlot = {createdTimeSlot}
                    isEdit = {false}
                    selectedTimeSlot = {selectedTimeSlot}
                  />
                </div>

                <div className="clt_admin_dash_saveButton">
                  <Button
                    type="submit"
                    className="clt_admin_dash_timeslot_formButton shadow-none"
                    onClick = {() => {
                      console.log(selectedSlotRoomsTotal);
                      console.log(roomsCount);
                      if(selectedSlotRoomsTotal !== roomsCount){
                        alert('Please add value for all rooms')
                      } else {
                        setScreen(1);
                      }
                    }}
                  >
                    <span>Savee</span>
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>

        {/* Function - Section - Select option room */}
          <SelectedOptionRoom
            isSidebar={isSelectedOptionRoomSidebar}
            onCloseSidebar={(data, value) => {
              setSelectedSlotRoomsTotal(value)
              setRoomsCount(data?.length)
              setRoomList(data);
              setisSelectedOptionRoomSidebar(false);
            }}
            isNumberOfNightMax={isNumberOfNightMax}
            setIsNumberOfNightMax={setIsNumberOfNightMax}
            setSelectedSlotRoomsTotal = {setSelectedSlotRoomsTotal}
            setIsRoomPricePerNight={setIsRoomPricePerNight}
            createdTimeSlot = {createdTimeSlot}
            roomList={allRooms}
            isEdit = {isEdit}
            selectedTimeSlot = {selectedTimeSlot}
            onClickSave = {() => {
              setScreen(4);
            }}
          />
      </Offcanvas.Body>
    </Offcanvas>

  );
};

export default AdminDashboardTimeslot;
