import React from 'react'

function Navbar(props) {
  return (
    <nav className="w-100 d-flex justify-content-between p-3" style={{borderBottom: "1px solid lightgrey"}}>
        <div className='d-flex align-items-center'>
            {"backBtn" in props?
            <button onClick={()=>window.location.href = "/"} className='btn'>
                <i className='fa fa-angle-left'></i>
            </button>
            :
                <button className='btn'>
                    <i className='fa fa-bars'></i>
                </button>
            }
            <h5 className='ml-3'> {props.title} </h5>
        </div>
        <button className={'btn bg-light rounded-circle'+("backBtn" in props?" d-none d-md-inline":"")}>
            <i className='fa fa-user'></i>
        </button>
    </nav>
  )
}

export default Navbar