import React, { useState, useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navbar from '../components/screens/adminDashboard/Navbar/admin_navbar';
import Footer from '../components/screens/adminDashboard/Footer/Footer';
import { useAuth } from "../components/screens/auth/authContext";
import AuthModal from "../components/screens/auth/AuthModal";

const AdminPagesLayout = ({ children, adminNavLinks, setAdminNavLinks }) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}>
      <Navbar setIsOpenSidebar={setIsOpenSidebar} isOpenSidebar={isOpenSidebar} adminNavLinks={adminNavLinks} setAdminNavLinks={setAdminNavLinks} />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >

        <div style={{ height: "calc(100vh - 52px)" }} className={`${isOpenSidebar ? "d-sm-block" : "d-sm-none"} d-block`}>
          <Footer
            adminNavLinks={adminNavLinks}
            setAdminNavLinks={setAdminNavLinks}
          />
        </div>

        <>
          {children}
        </>
      </div>

    </Container>
  );
}
const AdminPagesLayoutRoute = ({ setIsOpen, component: Component, ...rest }) => {

  const history = useHistory();
  const { isLoggedIn, user } = useAuth();
  let role;

  if (typeof user === "object" && user !== null && !Array.isArray(user)) {
    role = user.role
  }

  const [adminNavLinks, setAdminNavLinks] = useState(1);

  setIsOpen(false);

  // check if user is admin or not
  useEffect(() => {
    if (user !== null)
      if (user.role !== "admin")
        history.push('/');
  }, [user])

  if (localStorage.getItem('user-login-in')) {

    if (role === "admin") {
      return (
        <Route {...rest} render={matchProps => (
          <AdminPagesLayout adminNavLinks={adminNavLinks} setAdminNavLinks={setAdminNavLinks} >
            <Component {...matchProps} adminNavLinks={adminNavLinks}/>
          </AdminPagesLayout>
        )} />
      );
    }

  }



  return (
    <Route path={"/admin"} render={matchProps => (
      <AuthModal
        toggleAuthModal={() => { }}
        isLoginModal={localStorage.getItem('user-login-in') ? false : true}
        variant="second"
      />
    )} />
  );
};

export default AdminPagesLayoutRoute;
