import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
const SectionDateCancel = ({
  image,
  subText1,
  subText2,
  handleDateEdit,
  isCancelRequest,
  oldSubText1,
  oldSubText2,
}) => {
  return (
    <Row className="clt-booking-mb">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Date</h2>
              <p className={isCancelRequest && "text-red"}>{subText1}</p>
              <p className={isCancelRequest && "text-red"}>{subText2}</p>
              {isCancelRequest && (
                <>
                  <p className="text-decoration-line-through">{oldSubText1}</p>
                  <p className="text-decoration-line-through">{oldSubText2}</p>
                </>
              )}
            </div>
            {handleDateEdit && (
              <Button className="shadow-none" onClick={handleDateEdit}>
                <span>Edit</span>
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionDateCancel;
