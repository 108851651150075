import React from 'react'
import BackArrowRight from "../../../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../../../constants";
import ConfirmPay1 from "../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import SectionEvent from "../../../../../reusable/booking/SectionEvent";
import IconAccept from "../../../../../../assets/img/icon_accept.png";

const ChatComponentEditRequestAccept = ({ onClick }) => {
    return (
        <div className="clt_messages_container_request_message"
        onClick={onClick}
        >
            <div className="clt_messages_container_request_message_header">
                <h5>Edit request - Accept</h5>
                <button
                    type="button"
                    className="back-button "
                    aria-label="Close"
                >
                    <img src={BackArrowRight} className="" alt={IMG_ALT} />
                </button>
            </div>

            <div className='clt_messages_container_request_state'>
                <img src={IconAccept} alt={IMG_ALT} />
                <h2>Accept</h2>
            </div>
            <SectionEvent
                image={ConfirmPay1}
                subText="Wedding"
                buttonText="Edit"
            />

            <SectionDate
                image={ConfirmPay2}
                subText1="Subtext 1"
                subText2="Subtext 2"
                isEditRequest={true}
                oldSubText1="Subtext 1"
                oldSubText2="Subtext 2"
            />  
        </div>
    )
}

export default ChatComponentEditRequestAccept