import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react";
import { auth } from "./firebase";
import axios from "axios";
import { API_ENDPOINT, SOCK_ENDPOINT } from "../../../constants";
import io from 'socket.io-client';
import { SuccessToast, SuccessBookingToast } from "../../reusable/Toast";
import moment from "moment";

let socket = null;

const initialState = {
	email: "",
	phone: "",
	firstName: "",
	lastName: "",
	password: "",
	isLoggedIn: false,
	isRegister: false,
	facebookId: null,
	googleId: null,
	user: null
};

export const Actions = {
	SET_EMAIL: "SET_EMAIL",
	SET_PHONE: "SET_PHONE",
	SET_DATA: "SET_DATA",
	USER_LOGGED_IN: "USER_LOGGED_IN",
	DELETE_DATA: "DELETE_DATA",
	START_REGISTRATION: "START_REGISTRATION",
	END_REGISTRATION: "END_REGISTRATION",
	USER_LOGGED_OUT: "USER_LOGGED_OUT",
};

function reducer(state, action) {
	switch (action.type) {
		case Actions.SET_DATA:
			return {
				...state,
				...action.payload,
			};
		case Actions.USER_LOGGED_IN:
			return {
				...state,
				isLoggedIn: true,
				user: action.payload,
			};
		case Actions.DELETE_DATA:
			return {
				...state,
				email: "",
				phone: "",
				firstName: "",
				lastName: "",
				password: "",
				googleId: null,
				facebookId: null,
			};
		case Actions.START_REGISTRATION:
			return {
				...state,
				isRegister: true,
			};
		case Actions.END_REGISTRATION:
			return {
				...state,
				isRegister: false,
			};
		case Actions.USER_LOGGED_OUT:
			return {
				...state,
				isLoggedIn: false,
			};
		default:
			return state;
	}
}

export const AuthContext = createContext({
	...initialState,
	dispatch: () => { },
});

export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [user, setUser] = useState(null);
	const [socket, setSocket] = useState(null);
	const [dateTimeInput, setDateTimeInput] = useState(null);
	const [guestCount, setGuestCount] = useState(0);
	const [isProductsSidebarOpen, setIsProductsSidebarOpen] = useState(false);


	/* Const - Initial screen that open */
	const initialBookingScreens = {
		bookingdateAndTimeSlots: true,
		bookingconfirmAndPay: false,
		guestCounter: false,
		bedCounter: false,
		bookingsuccess: false,
		bookinginformationMsg: false,
	};

	const [bookingScreens, setBookingScreens] = useState(initialBookingScreens);

	const [selectedTimeSlot, setSelectedTimeSlot] = useState({})

	//navbar event
	const [allEvents, setAllEvents] = useState([]);

	const [selectedDate, setSelectedDate] = useState({
		start: null,
		end: null,
	});

	const [selectedPrice, setSelectedPrice] = useState(null);

	const getSidebarBookingUsers = useCallback(async (currentUser) => {
		const token = await currentUser.getIdToken(true);
		const meAccount = await axios.get(API_ENDPOINT + "/auth/me", {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		setUser(meAccount.data.data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isNewNotification, setNewNotification] = useState(false);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(
			async (user) => {
				if (state.isRegister) {
					return;
				}
				if (user) {

					const token = await user.getIdToken(true);
					const socketInstance = io(SOCK_ENDPOINT, {
						query: { token }
					});
					setSocket(socketInstance);

					// Set connected state when socket is connected
					socketInstance.on('connect', () => {
						console.log('connected');
					});

					socketInstance.on('notificationSent', function (dataComing, callback) {
						if (dataComing?.data && dataComing?.message === 'Automatic Payment has been failed') {
							SuccessBookingToast(
								dataComing?.data?.User?.profilePictureUrl,
								dataComing?.data?.User?.firstName,
								`Paiment échoué`,
								`${dataComing?.messageNoti} ${dataComing?.data?.User?.firstName} ${dataComing?.data?.User?.lastName} pour un événement ${dataComing?.data?.Event?.title} à échoué`
							)
						} else if (dataComing?.data && dataComing?.message === 'Automatic Payment has been success') {
							SuccessBookingToast(
								dataComing?.data?.User?.profilePictureUrl,
								dataComing?.data?.User?.firstName,
								`Paiement réussi`,
								`${dataComing?.messageNoti} ${dataComing?.data?.User?.firstName} ${dataComing?.data?.User?.lastName} pour un événement ${dataComing?.data?.Event?.title} a été payé avec succès`
							)
						} else if (dataComing?.data?.status === 'comming') {
							SuccessBookingToast(
								dataComing?.data?.User?.profilePictureUrl,
								dataComing?.data?.User?.firstName,
								'Réservation confirmée',
								`${dataComing?.data?.User?.firstName} ${dataComing?.data?.User?.lastName} a fait une réservation pour un événement ${dataComing?.data?.Event?.title} pour le ${moment(dataComing?.data?.ChildTimeSlot?.startDate).format("MMMM Do, YYYY")}`
							)
						} else if (dataComing?.data?.status === 'Ongoing') {
							SuccessBookingToast(
								dataComing?.data?.User?.profilePictureUrl,
								dataComing?.data?.User?.firstName,
								`${dataComing?.data?.User?.firstName} a commencé sa réservation`,
								`La réservation de ${dataComing?.data?.User?.firstName} ${dataComing?.data?.User?.lastName} pour un événement ${dataComing?.data?.Event?.title} à commencé`
							)
						} else if (dataComing?.data?.status === 'Cancel') {
							SuccessBookingToast(
								dataComing?.data?.User?.profilePictureUrl,
								dataComing?.data?.User?.firstName,
								`Réservation annulée`,
								`La réservation de ${dataComing?.data?.User?.firstName} ${dataComing?.data?.User?.lastName} pour un événement ${dataComing?.data?.Event?.title} est annulé`
							)
						} else if (dataComing?.data?.status === 'Finish') {
							SuccessBookingToast(
								dataComing?.data?.User?.profilePictureUrl,
								dataComing?.data?.User?.firstName,
								`${dataComing?.data?.User?.firstName} a terminé sa réservation`,
								`La réservation de ${dataComing?.data?.User?.firstName} ${dataComing?.data?.User?.lastName} pour un événement ${dataComing?.data?.Event?.title} est terminé`
							)
						} else {
							SuccessToast("Nouvelle notification");
						}
						setNewNotification(true);
					});

					// Set connected state when socket is disconnected
					socketInstance.on('disconnect', () => {
						console.log('disConneted');
					});

					socketInstance.on('error', (error) => {
						console.log('error');
						console.error(error);
					});

					getSidebarBookingUsers(user).then(() => {
						dispatch({ type: Actions.USER_LOGGED_IN });
					});
				} else {
					// socketInstance.disconnect();
					setSocket(null);
					dispatch({ type: Actions.USER_LOGGED_OUT });
				}
			},
			undefined,
			() => { }
		);
		return () => unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isRegister]);

	const [onBookingSideBarSet, setOnbookingSideBar] = useState(null);

	const [scrolTop, setScrollTop] = useState(false);

	const [guestCountInput, setGuestCountInput] = useState(0);
	const [selectTotalRooms, setSelectTotalRooms] = useState([]);
	const [selectedProfile, setSelectedProfile] = useState({
		type: "",
		detail: "",
	});
	const [isPaymentCardSelected, setIsPaymentCardSelected] = useState({});
	const [selectedPlan, setSelectedPlan] = useState({});
	const [bedCount, setBedCount] = useState([]);
	const [selectedRoomData, setSelectedRoomData] = useState([]);
	const [profilePictureUrl, setProfilePictureUrl] = useState(null);
	return (
		<AuthContext.Provider value={{ ...state, dispatch, user, getSidebarBookingUsers, socket, allEvents, setAllEvents, selectedDate, setSelectedDate, selectedPrice, setSelectedPrice, selectedTimeSlot, setSelectedTimeSlot, bookingScreens, setBookingScreens, initialBookingScreens, dateTimeInput, setDateTimeInput, onBookingSideBarSet, setOnbookingSideBar, isNewNotification, setNewNotification, scrolTop, setScrollTop, guestCount, setGuestCount, guestCountInput, setGuestCountInput, selectTotalRooms, setSelectTotalRooms, selectedProfile, setSelectedProfile, isPaymentCardSelected, setIsPaymentCardSelected, selectedPlan, setSelectedPlan, bedCount, setBedCount, selectedRoomData, setSelectedRoomData, profilePictureUrl, setProfilePictureUrl, isProductsSidebarOpen, setIsProductsSidebarOpen }}>
			{children}
		</AuthContext.Provider>
	);
};

export function useAuth() {
	return useContext(AuthContext);
}