import React, { useEffect, useState } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
/** CSS **/
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/index.css";

// react - slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/** Layouts **/
import PublicMainLayoutRoute from "./layouts/PublicMainLayoutRoute";
import PublicInnerLayoutRoute from "./layouts/PublicInnerLayoutRoute";
import AdminLayoutRouter from "./layouts/AdminLayoutRoute";

/** Components **/
import HomePage from "./components/screens/home/home";
import DetailPageWedding from "./components/screens/detail/detail_event_wedding";
import PageNotFound from "./components/screens/404/PageNotFound";
import AdminDashboard from "./components/screens/adminDashboard";
import Message from "./components/screens/messages/message";
import Overlay from './components/reusable/Overlay'; // Import the Overlay component
import logo from "./assets/img/wall-color7.png";
import logo2 from "./assets/img/logo2.png";
import logo3 from "./assets/img/logo3.png";
import logo4 from "./assets/img/wall-color8.png";
import {
  eventbirthdayData,
  eventweddingData,
  eventprofessionalData,
  eventreligiousData,
} from "./constants";

import HelpCenter from "./containers/helpCenterPages/helpCenter";
import PathDetail from "./containers/helpCenterPages/pathDetail";
import HelpArticle from "./containers/helpCenterPages/helpArticle";
// import MessageLayoutRoute from "./layouts/MessageLayoutRoute";
import Cookie from "./components/screens/Cookie/clt_cookie";
import { Container } from "react-bootstrap";
import PublicPageNotFoundRoute from "./layouts/PublicNotFoundRoute";
import ProtectedRoute from "./ProtectedRoute";
import { useAuth } from "./components/screens/auth/authContext";
import { ErrorToast } from "./components/reusable/Toast";

function App() {
  const [isOpenCookiesPopup, setIsOpenCookiesPopup] = useState(true);
  const [openCookiesSidebar, setOpenCookiesSidebar] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Function to display an alert when the internet connection is lost
  const showInternetLostAlert = () => {
    ErrorToast('Connexion perdue', 'Vous avez perdu la connexion Internet');
  };

  // Function to display a one-time message when the internet connection is restored
  const showInternetRestoredMessage = () => {
    ErrorToast('Connexion ok', 'Vous avez été de nouveau connecté à la connexion');
  };

  useEffect(() => {
    // Add event listeners for online and offline events
    const handleOnline = () => {
      if (!isOnline) {
        showInternetRestoredMessage();
        setIsOnline(true);
      }
    };

    const handleOffline = () => {
      if (isOnline) {
        showInternetLostAlert();
        setIsOnline(false);
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  return (
    <>
      {window.location.pathname === "/" && (
        <Overlay logoSrcMobile={logo4} logoSrcDesktop={logo} />
      )}
      <Router basename="/">
        <Switch>
          {/* --------------------------------------------------------------- */}
          {/* Home */}
          <PublicMainLayoutRoute
            path="/"
            exact
            component={HomePage}
            setIsOpen={setOpenCookiesSidebar}
          />
          {/* --------------------------------------------------------------- */}
          {/* Detail */}
          <PublicInnerLayoutRoute
            path={`/detail/:name`}
            exact
            component={DetailPageWedding}
          />
          {/* --------------------------------------------------------------- */}
          {/* Helpcenter */}
          {/* Helpcenter - Main */}
          <PublicMainLayoutRoute
            exact
            path="/aide"
            component={HelpCenter}
            header="Centre d'aide"
          />  
          {/* Helpcenter - Path */}
          <PublicInnerLayoutRoute path="/aide/path" component={PathDetail} />
          {/* Helpcenter - Article */}
          <PublicMainLayoutRoute
            exact
            path="/centre-aide/variation-3"
            component={HelpArticle}
          />
          {/* --------------------------------------------------------------- */}
          {/* Message */}
          <ProtectedRoute
            exact
            path="/inbox"
            component={Message}
            header="Message"
          />
          {/* --------------------------------------------------------------- */}
          {/* Admin dashboard */}
          <AdminLayoutRouter
            path={`/admin`}
            exact
            component={AdminDashboard}
            setIsOpen={setIsOpenCookiesPopup}
          />
          {/* --------------------------- */}
          {/* Page not found */}
          <PublicPageNotFoundRoute
            path="*"
            component={PageNotFound}
            setIsOpen={setIsOpenCookiesPopup}
          />
        </Switch>
      </Router>
      <div className="cookie-container">
        <Container className="cookie-container-wrapper">
          <Cookie
            isOpen={isOpenCookiesPopup}
            setIsOpen={setIsOpenCookiesPopup}
            show={openCookiesSidebar}
            setShow={setOpenCookiesSidebar}
          />
        </Container>
      </div>
    </>
  );
}

export default App;
