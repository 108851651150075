// Import
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "../../notification/user_notification.css";
import { API_ENDPOINT, IMG_ALT } from "../../../../constants";
import { Link } from "react-router-dom";
import moment from "moment";

// Import - Icon / Image
import BackArrow from "../../../../assets/img/back_arrow.png";
import icon_notification_transactionnal_refuse from "../../../../assets/img/icon_notification_transactionnal_refuse.png";
import icon_notification_transactionnal_information from "../../../../assets/img/icon_notification_transactionnal_information.png";
import icon_notification_transactionnal_accept from "../../../../assets/img/icon_notification_transactionnal_accept.png";

// ----------------------------------------------------------------- 

// import - Sidebar - Contact request

// import - Sidebar - Contact request - Accept
import SidebarContactRequestAdminAccept from "../../contactRequest/admin/[SIDEBAR] Admin - Contact Request - Accept";

// import - Sidebar - Contact request - In progress
import SidebarContactRequestAdminInProgress from "../../contactRequest/admin/[SIDEBAR] Admin - Contact Request - InProgress";

// import - Sidebar - Contact request - Sent
import SidebarContactRequestAdminSent from "../../contactRequest/admin/[SIDEBAR] Admin - Contact Request - Sent";

// import - Sidebar - Contact request - Cancel
import SidebarContactRequestAdminCancel from "../../contactRequest/admin/[SIDEBAR] Admin - Contact Request - Cancel";


// import - Sidebar - Edit request - Accept
import SidebarEditRequestAcceptAdmin from "../AdminDashboardBooking/admin_dash_booking_request/request_edit/sidebar/[SIDEBAR] Admin - Edit Request - Accept";

// import - Sidebar - Edit request - Sent
import SidebarEditRequestSentAdmin from "../AdminDashboardBooking/admin_dash_booking_request/request_edit/sidebar/[SIDEBAR] Admin - Edit Request - Sent";

// import - Sidebar - Edit request - Pending
import SidebarEditRequestPendingAdmin from "../AdminDashboardBooking/admin_dash_booking_request/request_edit/sidebar/[SIDEBAR] Admin - Edit Request - Pending";

// import - Sidebar - Edit request - Cancel
import SidebarEditRequestRejectAdmin from "../AdminDashboardBooking/admin_dash_booking_request/request_edit/sidebar/[SIDEBAR] Admin - Edit Request - Reject";

import AdminDashboardBookingDetail from '../AdminDashboardBooking/admin_dash_booking_detail/sidebar/AdminDashboardBookingDetail';
// ----------------------------------------------------------------- 

// import - Sidebar - Cancel request

// import - Sidebar - Cancel request - Accept
import SidebarCancelRequestAcceptAdmin from "../AdminDashboardBooking/admin_dash_booking_request/request_cancel/sidebar/[SIDEBAR] Admin - Cancel Request - Accept";

// import - Sidebar - Cancel request - Pending
import SidebarCancelRequestPendingAdmin from "../AdminDashboardBooking/admin_dash_booking_request/request_cancel/sidebar/[SIDEBAR] Admin - Cancel Request - Sent";
import { auth } from "../../auth/firebase";
import axios from "axios";
import { ErrorToast, SuccessToast } from "../../../reusable/Toast";
import { AuthContext, useAuth } from "../../auth/authContext";
import { LoaderDots } from '@thumbtack/thumbprint-react';





/* Const */
const NotificationOffcanvas = ({
  isNotificationSidebar,
  onCloseNotificationSidebar,
  setAdminNavLinks,
  updateCounters
}) => {

  /* Const - Sidebar - Notification */

  /* Const - Sidebar - Open */
  const [isNotificationCardSelected, setIsNotificationCardSelected] = useState(
    isNotificationSidebar
  );

  /* Const - Sidebar - Close */
  const handleCloseSidebar = () => {
    onCloseNotificationSidebar()
  };

  /* Const - Sidebar - Manage */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="clt_reusable_notification_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));

  /* Const - Contact request */

  /* Const - Contact request - Accept*/
  const [isOpenSidebarContactRequestAdminAccept, setIsOpenSidebarContactRequestAdminAccept] = useState(false);

  /* Const - Contact request - Sent*/
  const [isOpenSidebarContactRequestAdminSent, setIsOpenSidebarContactRequestAdminSent] = useState(false);

  /* Const - Contact request - In progress*/
  const [isOpenSidebarContactRequestAdminInProgress, setIsOpenSidebarContactRequestAdminInProgress] = useState(false);

  /* Const - Contact request - Cancel*/
  const [isOpenSidebarContactRequestAdminCancel, setIsOpenSidebarContactRequestAdminCancel] = useState(false);

  /* Const - Edit request

  /* Const - Edit request - Accept*/
  const [isOpenSidebarEditRequestAcceptAdmin, setIsOpenSidebarEditRequestAcceptAdmin] = useState(false);

  /* Const - Edit request - Sent*/
  const [isOpenSidebarEditRequestSentAdmin, setIsOpenSidebarEditRequestSentAdmin] = useState(false);

  /* Const - Edit request - Pending*/
  const [isOpenSidebarEditRequestPendingAdmin, setIsOpenSidebarEditRequestPendingAdmin] = useState(false);

  /* Const - Edit request - Cancel */
  const [isOpenSidebarEditRequestRejectAdmin, setIsOpenSidebarEditRequestRejectAdmin] = useState(false);

  /* Const - Cancel request

  /* Const - Cancel request - Accept*/
  const [isOpenSidebarCancelRequestAcceptAdmin, setIsOpenSidebarCancelRequestAcceptAdmin] = useState(false);

  /* Const - Cancel request - Pending*/
  const [isOpenSidebarCancelRequestPendingAdmin, setIsOpenSidebarCancelRequestPendingAdmin] = useState(false);


  const markAsTreated = (item) => {
    handleIsRead(item, true);
    updateCounters(); // Emit the event to update counters on the homepage
  };

  const markAsPending = (item) => {
    handleIsRead(item, false);
    updateCounters(); // Emit the event to update counters on the homepage
  };
  const [notificationData, setNotificationData] = useState([]);
  const [notificationDataGrouped, setNotificationDataGrouped] = useState({});
  const [selectedNotification, setSelectedNotification] = useState({});
  const [selectedBooking, setSelectedBooking] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { isNewNotification, setNewNotification } = useContext(AuthContext);

  const [isOpenSidebarBookingAdmin, setisOpenSidebarBookingAdmin] = useState(false);
  const [bookingSidebarDetail, setBookingSidebarDetail] = useState([]);

  const onOpenSidebarCancelRequestPending = () => {
    setIsOpenSidebarCancelRequestPendingAdmin(true);
  };

  const onOpenAdminBookingDetailSidebar = () => {
    setisOpenSidebarBookingAdmin(true)
  }
  const onCloseOpenSidebarBookingAdmin = () => {
    setisOpenSidebarBookingAdmin(false)
  }

  const { socket } = useAuth();


  useEffect(() => {
    getNotifications(true);

    socket.on('notificationSent', function (dataComing, callback) {
      getNotifications();
      setNewNotification(true);
    });

    socket.on('cancelRequestSent', function (dataComing, callback) {
      setBookingSidebarDetail(dataComing.data);
      getNotifications(false);
    });

  }, []);

  const getNotifications = async (load = false) => {
    setIsLoading(load);
    setNewNotification(true);
    const token = await auth.currentUser.getIdToken();
    try {
      const response = await axios.get(API_ENDPOINT + "/admin/notifications",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotificationData(response.data.data);
      gorupNotificationData(response.data.data);

      setIsLoading(false);
      // SuccessToast(response.data.message);
      const notificationData = response?.data?.data;

      const notificationLenght = parseInt(response?.data?.count);
      localStorage.setItem('newNotificationAdmin', notificationLenght);

    } catch (error) {
      setIsLoading(false);
      ErrorToast(error?.response?.data?.message);
    }

  }

  const handleIsRead = async (item, is_read_done, see=true) => {
    if (see) {
      let readAbleData = notificationData.map(x => (x.id === item.id) ? { ...x, is_read_admin: true, is_read_done: is_read_done } : x);
      setNotificationData(readAbleData);
      gorupNotificationData(readAbleData);
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.patch(API_ENDPOINT + `/notifications/${item.id}`, { is_read_admin: true, is_read_done: is_read_done },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.log('Error updating notification read status:', error);
      }
    }
  }
  

  const handleCancelRequest = async (item) => {
    const myArray = item.title.split(" ");
    let word = myArray[myArray.length - 1];
    if (word === 'Accept') {
      let itemToShow = item.Booking.CancelBookings.filter(x => x.status === 'Accept');
      if (itemToShow.length) {
        setBookingSidebarDetail(item.Booking);
        setSelectedNotification(itemToShow[itemToShow.length - 1]);
        setIsOpenSidebarCancelRequestAcceptAdmin(true);
      } else { return; }
    } else if (word === 'Sent' || 'envoyée' || 'envoyé') {
      console.log('location: ', item.Booking);
      let itemToShow = item.Booking.CancelBookings.filter(x => x.status === 'Sent');
      setSelectedBooking(item.Booking);
      if (itemToShow.length) {
        console.log('location: coming here',);
        setBookingSidebarDetail(item.Booking);
        setSelectedNotification(itemToShow[itemToShow.length - 1]);
        setIsOpenSidebarCancelRequestPendingAdmin(true);
      } else { return; }
    } else {
      return;
    }
  }


  const [doneNotifications, setDoneNotifications] = useState([]);
  const [notDoneNotifications, setNotDoneNotifications] = useState([]);
  const initialNotificationData = [
    {
      id: 1,
      type: "Contact Request",
      status: "Sent",
      isDone: false, // Add this field
      // other fields...
    },
    // other notification items...
  ];

  // Updated handleMarkAsDone function
// Updated handleMarkAsDone function
// Updated handleMarkAsDone function
const handleMarkAsDone = (item) => {
  if (!item.is_read_admin) {
    const updatedData = notificationData.map((notification) => {
      if (notification.id === item.id) {
        return { ...notification, isDone: true }; // Mark as done
      }
      return notification;
    });

    // Update the notification data state
    setNotificationData(updatedData);
  }
};

// Updated handleMarkAsNotDone function
const handleMarkAsNotDone = (item) => {
  if (!item.is_read_admin) {
    const updatedData = notificationData.map((notification) => {
      if (notification.id === item.id) {
        return { ...notification, isDone: false }; // Mark as not done
      }
      return notification;
    });

    // Update the notification data state
    setNotificationData(updatedData);
  }
};



  

  useEffect(() => {
    setNotificationData(initialNotificationData);
  }, []);


  const handleDateFormat = (dateTime) => {
    const now = moment();
    const diffInMinutes = now.diff(dateTime, 'minutes');

    if (diffInMinutes < 1) {
      return 'À l\'instant';
    } else if (diffInMinutes < 60) {
      return `il y à ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'}`;
    } else if (diffInMinutes < 1440) {
      const hoursAgo = Math.floor(diffInMinutes / 60);
      return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
    } else if (now.isSame(dateTime, 'day')) {
      return moment(dateTime).format('HH:mm');
    } else if (now.isSame(dateTime, 'year')) {
      return moment(dateTime).format('DD MMM HH:mm');
    } else {
      return moment(dateTime).format('DD MMM YYYY, HH:mm');
    }
  }

  const gorupNotificationData = (dataToCategorize) => {

    const currentDate = moment().startOf('day');

    const categorizedData = dataToCategorize.reduce((result, item) => {
      const itemDate = moment(item.createdAt).startOf('day');
      const category = currentDate.isSame(itemDate, 'day')
        ? 'aujourdhui'
        : itemDate.isSame(currentDate.clone().subtract(1, 'day'), 'day')
          ? 'hier'
          : currentDate.isSame(itemDate, 'week')
            ? 'cette semaine'
            : itemDate.format('MMMM YYYY');

      result[category] = (result[category] || []).concat(item);

      return result;
    }, {});

    console.log('location: ', categorizedData);
    // Display the categorized data
    setNotificationDataGrouped(categorizedData);
  }

  {/* Sidebar - Notification */ }
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_reusable_notification_offcanvas"
        show={isNotificationSidebar}
        onHide={onCloseNotificationSidebar}
      >


        {/* Header */}
        <Offcanvas.Header className="justify-content-start clt_reusable_notification_offcanvas_header">
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={handleCloseSidebar}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Offcanvas.Title className="clt_reusable_notification_title">
                Notification
              </Offcanvas.Title>
              {/* export button  */}
              {/* <Button className="clt_reusable_notification_header_button shadow-none">
                            Export
                        </Button> */}
            </div>
          </React.Fragment>
        </Offcanvas.Header>

        {/* Body */}
        <Offcanvas.Body className="clt_reusable_notification_offcanvas_body">
          <>
            <div className="clt_reusable_notification_innerdivs">

              {/* Heading */}
              <h2 className="clt_reusable_notification_innerhead">
                Notification
              </h2>

              {/* Notification - Alert - Document require 
              <div
                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "20%" }}
                >
                  <div className="clt_reusable_notification_transactionnal_information_imgdiv">
                    <img
                      src={icon_notification_transactionnal_information}
                      className=""
                      alt="Image"
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                  style={{ width: "70%" }}
                >
                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                    Document require
                  </h3>
                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>Booking start in less than x days and the 'Document A', 'Document B' miss</p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                  style={{ width: "10%" }}
                >
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu
                      size="sm"
                      title=""
                      className="pfr_dropdown_menu"
                    >
                      <Dropdown.Item className="clt_reusable_notification_cards3dots_options">
                        Voir
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}

              {
                isLoading ?
                  <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }}>
                    <LoaderDots theme="muted" />
                  </div>
                  :
                  Object.keys(notificationDataGrouped).map((key, index) => (
                    <div key={index}>
                      {notificationDataGrouped[key]?.length ? <h6 className="clt_notification_category_heading">{`${key}`}</h6> : null}
                      {notificationDataGrouped[key]?.map((item, ind) => {
                        if (item.type === 'Contact Request') {
                          if (item.status === 'Sent') {
                            {/* Notification - Contact request - Sent */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.User?.profilePictureUrl}
                                          alt={item?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase() || item?.ContactRequest?.first_name[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`Demande de contact par ${item?.User?.firstName || item?.ContactRequest?.first_name}`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`${item?.User?.firstName || item?.ContactRequest?.first_name} ${item?.User?.lastName || item?.ContactRequest?.name} a faits une demande de contact  `}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setSelectedNotification(item); setIsOpenSidebarContactRequestAdminSent(true); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>)
                          } else if (item.status === 'In Progress') {
                            {/* Notification - Contact request - In Progress */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.User?.profilePictureUrl}
                                          alt={item?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase() || item?.ContactRequest?.first_name[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`CLT à repondu la demande de contact de ${item?.User?.firstName || item?.ContactRequest?.first_name}`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`Vous avez répondu à la demande de contact  `}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setSelectedNotification(item); setIsOpenSidebarContactRequestAdminInProgress(true) }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>)
                          } else if (item.status === 'Accept') {
                            return (<div
                              className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                              style={notificationDataGrouped[key].length === ind + 1 ? 
                                { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                : 
                                { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                              }
                              key={ind}
                            >
                              <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                              >
                                <div className="clt_reusable_notification_transactionnal_accept_imgdiv">
                                  <img
                                    src={icon_notification_transactionnal_accept}
                                    className=""
                                    alt="Image"
                                  />
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                style={{ width: "70%" }}
                              >
                                <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                  {item.title}
                                </h3>
                                <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                  {item.message}
                                </p>
                                <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                  {handleDateFormat(item.createdAt)}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                style={{ width: "10%" }}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu
                                    size="sm"
                                    title=""
                                    className="pfr_dropdown_menu"
                                  >
                                    <Dropdown.Item
                                      className="clt_reusable_notification_cards3dots_options"
                                      onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setSelectedNotification(item); setIsOpenSidebarContactRequestAdminAccept(true) }}
                                    >
                                      Voir détail
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="clt_reusable_notification_cards3dots_options"
                                      onClick={() => markAsTreated(item)}
                                    >
                                      Marquer comme "Traitée"
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="clt_reusable_notification_cards3dots_options"
                                      onClick={() => markAsPending(item)}
                                    >
                                      Marquer comme "En attente"
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>)
                          } else if (item.status === 'Cancel') {
                            return (<div
                              className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                              style={notificationDataGrouped[key].length === ind + 1 ? 
                                { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                : 
                                { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                              }
                              key={ind}
                            >
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: "20%" }}
                              >
                                <div className="clt_reusable_notification_transactionnal_refuse_imgdiv">
                                  <img
                                    src={icon_notification_transactionnal_refuse}
                                    className=""
                                    alt="Image"
                                  />
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                style={{ width: "70%" }}
                              >
                                <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                  {item.title}
                                </h3>
                                <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                  {item.message}
                                </p>
                                <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                  {handleDateFormat(item.createdAt)}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                style={{ width: "10%" }}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu
                                    size="sm"
                                    title=""
                                    className="pfr_dropdown_menu"
                                  >
                                    <Dropdown.Item
                                      className="clt_reusable_notification_cards3dots_options"
                                      onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setSelectedNotification(item); setIsOpenSidebarContactRequestAdminCancel(true) }}
                                    >
                                      Voir détail
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="clt_reusable_notification_cards3dots_options"
                                      onClick={() => markAsTreated(item)}
                                    >
                                      Marquer comme "Traitée"
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="clt_reusable_notification_cards3dots_options"
                                      onClick={() => markAsPending(item)}
                                    >
                                      Marquer comme "En attente"
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>)
                          }
                        } else if (item.type === 'Booking') {

                          if (item.status === 'Comming') {
                            {/* Notification - Booking - Coming*/ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.Booking?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.Booking?.User?.profilePictureUrl}
                                          alt={item?.Booking?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`Nouvelle réservation ${item?.Booking?.User?.firstName}`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} a faits une réservation de ${item?.Booking?.price}€ pour un événement ${item?.Booking?.Event?.title} pour le  ${moment(item?.Booking?.ChildTimeSlot?.startDate).format("Do MMMM YYYY")}`}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Ongoing') {
                            {/* Notification - Booking - Ongoing */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.Booking?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.Booking?.User?.profilePictureUrl}
                                          alt={item?.Booking?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`${item?.Booking?.User?.firstName} a commencé sa réservation`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`La réservation de ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un événement ${item?.Booking?.Event?.title} à commencer`}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Finish') {
                            {/* Notification - Booking - Finish */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.Booking?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.Booking?.User?.profilePictureUrl}
                                          alt={item?.Booking?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {` ${item?.Booking?.User?.firstName} à terminer sa réservation`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {` La réservation de ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un évenement ${item?.Booking?.Event?.title} est terminé `}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Request To Cancel') {
                            {/* Notification - Cancel request - Sent */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.User?.profilePictureUrl}
                                          alt={item?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`${item.title}`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`${item?.User?.firstName} à fais une demande d'annulation de sa réservation`}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); handleCancelRequest(item); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Cancel') {
                            {/* Notification - Booking cancel */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.Booking?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.Booking?.User?.profilePictureUrl}
                                          alt={item?.Booking?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {` ${item?.Booking?.User?.firstName} à annulé sa réservation`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`La réservation de ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un évenement ${item?.Booking?.Event?.title} est annulé `}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Alert') {
                            {/* Notification - Booking alert */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.User?.profilePictureUrl}
                                          alt={item?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`Document manquant pour la réservation de ${item?.User?.firstName}`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {item.message}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Payment_Failed') {
                            {/* Notification - Booking alert */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.User?.profilePictureUrl}
                                          alt={item?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`Paiement échoué`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`${item.message} ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un événement ${item?.Booking?.Event?.title} à échoué`}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          } else if (item.status === 'Payment_Success') {
                            {/* Notification - Booking alert */ }
                            return (
                              <div
                                className={`d-flex align-items-center justify-content-start clt_reusable_notification_cardsdiv2 }`}
                                style={notificationDataGrouped[key].length === ind + 1 ? 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6', borderBottom: 0 } 
                                  : 
                                  { backgroundColor: item.is_read_admin && item.is_read_done ? '#DFF0D8' : item.is_read_admin && !item.is_read_done ? '#FFEFD5' : item.is_read_admin ? 'transparent' : '#f6f6f6' }
                                }
                                key={ind}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-start"
                                  style={{ width: "20%" }}
                                >
                                  <div>
                                    {item?.User?.profilePictureUrl ?
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <img
                                          className="clt_notification_confirm_booking_profile_img"
                                          src={item?.User?.profilePictureUrl}
                                          alt={item?.User?.firstName}
                                        />
                                      </div>
                                      :
                                      <div className="clt_notification_avatar">
                                        <div className="clt_notification_confirm_booking_badge_container">
                                          <img
                                            src={icon_notification_transactionnal_information}
                                            className="clt_notification_confirm_booking_badge_container_img"
                                            alt="Image"
                                          />
                                        </div>
                                        <div className="clt_notification_confirm_booking_profile_img">
                                          <div style={{ color: 'white' }}>{item?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-start justify-content-center flex-column clt-notification-detail-margin-left"
                                  style={{ width: "70%" }}
                                >
                                  <h3 className={item.is_read_admin ? 'clt_reusable_notification_cardnumber_read' : 'clt_reusable_notification_cardnumber'}>
                                    {`Paiement réussi`}
                                  </h3>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_cardname_read' : 'clt_reusable_notification_cardname'}>
                                    {`${item.message} ${item?.Booking?.User?.firstName} ${item?.Booking?.User?.lastName} pour un événement ${item?.Booking?.Event?.title} a été payé avec succès`}
                                  </p>
                                  <p className={item.is_read_admin ? 'clt_reusable_notification_created_at_read' : 'clt_reusable_notification_created_at'}>
                                    {handleDateFormat(item.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-end clt_reusable_notification_cards3dots"
                                  style={{ width: "10%" }}
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      size="sm"
                                      title=""
                                      className="pfr_dropdown_menu"
                                    >
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => { handleIsRead(item, false, item.is_read_admin && item.is_read_done ? false : true); setBookingSidebarDetail(item.Booking); onOpenAdminBookingDetailSidebar(); }}
                                      >
                                        Voir détail
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsTreated(item)}
                                      >
                                        Marquer comme "Traitée"
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="clt_reusable_notification_cards3dots_options"
                                        onClick={() => markAsPending(item)}
                                      >
                                        Marquer comme "En attente"
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            )
                          }
                        }
                      })
                      }

                    </div>
                  ))
              }
              {/* -------------------------------- */}
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Body - End */}

      {/* ---------------------------------------------------------------------- */}

      {/* Function - Contact request */}


      {/* Function - Contact Request - Accept*/}
      {isOpenSidebarContactRequestAdminAccept ? <SidebarContactRequestAdminAccept
        data={selectedNotification}
        isOpenSidebarContactRequestAdminAccept={isOpenSidebarContactRequestAdminAccept}
        onCloseOpenSidebarContactRequestAdminAccept={() => setIsOpenSidebarContactRequestAdminAccept(false)}
      /> : null}

      {/* Function - Contact Request - Sent*/}
      {isOpenSidebarContactRequestAdminSent ? <SidebarContactRequestAdminSent
        data={selectedNotification}
        isOpenSidebarContactRequestAdminSent={isOpenSidebarContactRequestAdminSent}
        onCloseOpenSidebarContactRequestAdminSent={() => setIsOpenSidebarContactRequestAdminSent(false)}
        setAdminNavLinks={setAdminNavLinks}
        onCloseNotificationSidebar={onCloseNotificationSidebar}
      /> : null}


      {/* Function - Contact Request - InProgress */}
      {isOpenSidebarContactRequestAdminInProgress ? <SidebarContactRequestAdminInProgress
        data={selectedNotification}
        isOpenSidebarContactRequestAdminInProgress={isOpenSidebarContactRequestAdminInProgress}
        onCloseOpenSidebarContactRequestAdminInProgress={() => setIsOpenSidebarContactRequestAdminInProgress(false)}
      /> : null}

      {/* Function - Contact Request - Cancel*/}
      {isOpenSidebarContactRequestAdminCancel ? <SidebarContactRequestAdminCancel
        data={selectedNotification}
        isOpenSidebarContactRequestAdminCancel={isOpenSidebarContactRequestAdminCancel}
        onCloseOpenSidebarContactRequestAdminCancel={() => setIsOpenSidebarContactRequestAdminCancel(false)}
      /> : null}
      {/* ---------------------------------------------------------------------- */}

      {/* Function - Edit request */}

      {/* Function - Edit request - Accept */}
      {isOpenSidebarEditRequestAcceptAdmin ? <SidebarEditRequestAcceptAdmin
        isOpenSidebarEditRequestAcceptAdmin={isOpenSidebarEditRequestAcceptAdmin}
        onCloseOpenSidebarEditRequestAcceptAdmin={() => setIsOpenSidebarEditRequestAcceptAdmin(false)}
      /> : null}

      {/* Function - Edit request - Sent */}
      {isOpenSidebarEditRequestSentAdmin ? <SidebarEditRequestSentAdmin
        isOpenSidebarEditRequestSentAdmin={isOpenSidebarEditRequestSentAdmin}
        onCloseOpenSidebarEditRequestSentAdmin={() => setIsOpenSidebarEditRequestSentAdmin(false)}
      /> : null}

      {/* Function - Edit request - Pending */}
      {isOpenSidebarEditRequestPendingAdmin ? <SidebarEditRequestPendingAdmin
        isOpenSidebarEditRequestPendingAdmin={isOpenSidebarEditRequestPendingAdmin}
        onCloseOpenSidebarEditRequestPendingAdmin={() => setIsOpenSidebarEditRequestPendingAdmin(false)}
      /> : null}

      {/* Function - Edit request - Reject */}
      {isOpenSidebarEditRequestRejectAdmin ? <SidebarEditRequestRejectAdmin
        isOpenSidebarEditRequestRejectAdmin={isOpenSidebarEditRequestRejectAdmin}
        onCloseOpenSidebarEditRequestRejectAdmin={() => setIsOpenSidebarEditRequestRejectAdmin(false)}
      /> : null}

      {/* ---------------------------------------------------------------------- */}

      {/* Function - Cancel request */}

      {/* Function - Cancel request - Accept */}
      {isOpenSidebarCancelRequestAcceptAdmin ? <SidebarCancelRequestAcceptAdmin
        data={selectedNotification}
        selectedBooking={bookingSidebarDetail}
        isOpenSidebarCancelRequestAcceptAdmin={isOpenSidebarCancelRequestAcceptAdmin}
        onCloseOpenSidebarCancelRequestAcceptAdmin={() => setIsOpenSidebarCancelRequestAcceptAdmin(false)}
      /> : null}

      {/* Function - Cancel request - Pending */}
      {isOpenSidebarCancelRequestPendingAdmin ? <SidebarCancelRequestPendingAdmin
        data={selectedNotification}
        selectedBooking={selectedBooking}
        isOpenSidebarCancelRequestSentAdmin={isOpenSidebarCancelRequestPendingAdmin}
        onCloseOpenSidebarCancelRequestSentAdmin={() => setIsOpenSidebarCancelRequestPendingAdmin(false)}
      /> : null}

      {isOpenSidebarBookingAdmin ? <AdminDashboardBookingDetail
        onCloseOpenSidebarBookingAdmin={onCloseOpenSidebarBookingAdmin}
        isOpenSidebarBookingAdmin={isOpenSidebarBookingAdmin}
        data={bookingSidebarDetail}
      /> : null}
    </>
  );
};

export default NotificationOffcanvas;
