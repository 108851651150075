import React from "react";
import { Col, Row } from "react-bootstrap";
import icon_key from "../../assets/img/icon_key.png";
import { IMG_ALT } from "../../constants";
const SectionInformationMessage = (props) => {
  return (
    <Row className="clt-detail-section-6">
      <Col>
        <Row>
          <Col>
            <div className="d-flex justify-content-start align-items-center clt-booking-cp-div1 ">
              <img src={icon_key} alt={IMG_ALT} />
              <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                <h2>Remis en mains propres</h2>
                <p>Les clefs vous seront remises en mains propres au début de votre réservation</p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SectionInformationMessage;
