import React from 'react'
// import Navbar from '../../components/layout/navbar'K
import "./helpCenter.css";

function HelpArticle() {
    return (
    <div>
        {/* <Navbar backBtn title="Help Article"/> */}
        <div className='overflow-auto clt-helpcenter-min-h-100'>
            <div className='container py-4'>
                <h4 className='clt-helpcenter-h4'>Title</h4>
                <p className='clt-helpcenter-text-dark clt-helpcenter-spliter py-mb-4 pb-3 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dolor nisi, tempor at posuere eu <a href='/#'>text link</a>.</p>
                <div className='clt-helpcenter-pathList mt-3 d-flex flex-column justify-content-center align-items-center py-mb-5 py-4'>
                    <h3 className='clt-helpcenter-h3'>Title</h3>
                    <button className='clt-helpcenter-btn clt-helpcenter-btn-light clt-helpcenter-rounded-pill'>Button</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HelpArticle