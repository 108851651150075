import React from "react";
import Img1 from "../../../assets/img/icon_required_action.png";
import { Button, Col, Row } from "react-bootstrap";

const SectionRequired = (props) => {
  return (
      <Row className="clt-detail-section-6">
        <Col>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-start clt-account-screen-div2" onClick={props.onClick}>
                <div className="d-flex flex-row justify-content-center align-items-start clt-account-screen-inner-div">
                  <div className="clt-account-screen-inner-div-img">
                    <img src={Img1} alt="hhh" style={{marginTop:'2px'}}/>
                  </div>
                  <div className="clt-account-screen-inner-div-text">
                    <h2 style={{color:'red'}}>{props.innerTitle}</h2>
                    <p>{props.innerSubTitle}</p>
                    <Button className="clt-button-text" onClick={props.onClick}>{props.ButtonText}</Button>
                  
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
  );
};

export default SectionRequired;
