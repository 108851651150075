import axios from "axios";
import { API_ENDPOINT } from "../../../constants";
import { auth } from "../auth/firebase";

export const createNewChat = async ({ data }) => {

	console.log("data", data)
	const token = await auth.currentUser.getIdToken();
	return axios.post(API_ENDPOINT + "/chats", data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};


export const addProfessionalProfileRequest = async () => {
	const token = await auth.currentUser.getIdToken();
	return axios.post(
		API_ENDPOINT + "/profiles",
		{},
		{ headers: { Authorization: `Bearer ${token}` } }
	);
};


export const updateProfessionalProfileRequest = async ({ id, data }) => {
	const token = await auth.currentUser.getIdToken();
	return axios.patch(API_ENDPOINT + `/profiles/${id}`, data, {
		headers: { Authorization: `Bearer ${token}` },
	});
}