import React, { useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import CustomHr from "../../../../../reusable/CustomHr";
import EditSection from "./EditSection";
import icon from "../../../../../../assets/img/admin_icon_bar_2_not_active.png";
import CreateSection from "./CreateSection";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const headingStyle = {
  fontSize: "18px",
  color: "#000",
};
const rowStyle = {
  marginTop: "-25px",
};
const contentStyle = {
  backgroundColor: "#fff",
  borderRadius: "50px",
};
const contentInnerStyle = {
  display: "flex",
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    className="clt_admin_dash_manage_access_card3dot"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
      console.log(e);
    }}
  >
    {children}
    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
  </Link>
));

function CreateArticle({ isOpen, onClose, category, createNewArticle }) {
  const [articleName, setArticleName] = useState("");
  const [groupTag, setGroupTag] = useState("");

  const [contents, setContents] = useState([]);
  const [openEditSection, setOpenEditSection] = useState(false);
  const [openCreateSection, setOpenCreateSection] = useState(false);

  const [selectedContent, setSelectedContent] = useState(null);

  const addNewContent = (content) => {
    setContents([...contents, content]);
    setOpenCreateSection(false);
  };
  const deleteContent = (content) => {
    setContents(contents.filter((c) => c.id !== content.id));
  };
  const updateContent = (content) => {
    setContents(
      contents.map((c) => {
        if (c.id === content.id) {
          return content;
        }
        return c;
      })
    );
    setOpenEditSection(false);
  };

  const onDragEndEvent = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...contents];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setContents(copiedItems);
  };

  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      backdrop={false}
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Create article</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col className="clt-admin-event-info-input-div">
            <h2 class="clt_admin_dash_menu_container_name" style={headingStyle}>
              Help article name
            </h2>

            <Form.Floating className="pfr_inputFloat">
              <Form.Control
                id="helpArticleName"
                type="text"
                placeholder="Name"
                value={articleName}
                onChange={(e) => setArticleName(e.target.value)}
              />
              <label htmlFor="helpArticleName">Name</label>
            </Form.Floating>
          </Col>
        </Row>
        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
        <Row style={rowStyle}>
          <Col className="clt-admin-event-info-input-div">
            <h2 class="clt_admin_dash_menu_container_name" style={headingStyle}>
              Group tag
            </h2>
            <Form.Floating className="pfr_inputFloat">
              <Form.Control
                id="groupTag"
                type="text"
                placeholder="Group tag"
                value={groupTag}
                onChange={(e) => setGroupTag(e.target.value)}
              />
              <label htmlFor="groupTag">Group tag</label>
            </Form.Floating>
          </Col>
        </Row>
        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
        <>
          <Row className="clt-account-section-offcanvas-row" style={rowStyle}>
            <Col className="p-0">
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    style={{ ...headingStyle, padding: "15px 0", margin: "0" }}
                  >
                    Content
                  </h2>

                  <Button
                    className="clt_admin_dash_manage_access_header_button shadow-none"
                    onClick={() => setOpenCreateSection(true)}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "-18px",
                }}
              >
                <Row className="clt-detail-section-6-offcanvas-data-row m-0 mt-0">
                  <DragDropContext
                    onDragEnd={(result) => onDragEndEvent(result)}
                  >
                    <Droppable droppableId="events">
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {contents.map((content, i) => (
                              <Draggable
                                key={content.id}
                                draggableId={content.id + ""}
                                index={i}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Row
                                      key={i}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        backgroundColor: snapshot.isDragging
                                          ? "#fff"
                                          : "transparent",
                                        border: snapshot.isDragging
                                          ? "1px solid #dedddd"
                                          : "none",
                                        borderRadius: snapshot.isDragging
                                          ? "10px"
                                          : "0",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <>
                                        <Col key={i}>
                                          <Row>
                                            <Col
                                              className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                                              onClick={() => {}}
                                              style={contentStyle}
                                            >
                                              <div
                                                className="w-80"
                                                style={contentInnerStyle}
                                              >
                                                <img
                                                  src={icon}
                                                  alt="icon"
                                                  style={{
                                                    marginTop: "2px",
                                                    height: "14px",
                                                    width: "14px",
                                                  }}
                                                />
                                                <h2
                                                  style={{ margin: "0 16px" }}
                                                >
                                                  {i + 1}
                                                </h2>
                                                <h2>{content.title}</h2>
                                              </div>
                                              <div className="d-flex align-items-center justify-content-end clt_admin_dash_manage_access_cards3dots">
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    as={CustomToggle}
                                                  />
                                                  <Dropdown.Menu
                                                    size="sm"
                                                    title=""
                                                    className="pfr_dropdown_menu"
                                                  >
                                                    <Dropdown.Item
                                                      className="clt_admin_dash_manage_access_cards3dots_options"
                                                      onClick={() => {
                                                        setSelectedContent(
                                                          content
                                                        );
                                                        setOpenEditSection(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      Edit section
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      className="clt_admin_dash_manage_access_cards3dots_options"
                                                      onClick={() =>
                                                        deleteContent(content)
                                                      }
                                                    >
                                                      Delete
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        {contents.length > 0 &&
                                          i !== contents.length - 1 && (
                                            <hr class="p-0 m-0 clt-hr" />
                                          )}
                                      </>
                                    </Row>
                                  );
                                }}
                              </Draggable>
                            ))}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </Row>
              </div>
            </Col>
          </Row>
          <EditSection
            isOpen={openEditSection}
            onClose={() => setOpenEditSection(false)}
            selectedContent={selectedContent}
            updateContent={updateContent}
          />
          <CreateSection
            isOpen={openCreateSection}
            onClose={() => setOpenCreateSection(false)}
            addNewContent={addNewContent}
          />
        </>
        <div className="clt_payment_footer">
          <Button
            className="shadow-none clt_payment_header_button_black"
            disabled={articleName === "" || groupTag === ""}
            onClick={() =>
              createNewArticle({
                name: articleName,
                groupTag: groupTag,
                categoryId: category.id,
                content: contents.map((c) => ({
                  title: c.title,
                  content: c.content,
                })),
              })
            }
          >
            Save
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CreateArticle;
