import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API_ENDPOINT } from "../../../../../../constants"
import { auth } from "../../../../auth/firebase";
import { ErrorToast, SuccessToast } from "../../../../../reusable/Toast";

const ChatComponentPreferenceContact = ({ contactRequest, socket }) => {
    const [isChecked, setIsChecked] = useState({ phone: false, email: false, chat: false });
    const [request, setRequest] = useState(null);

    useEffect(() => {
        setRequest(contactRequest);

        setIsChecked({
            phone: contactRequest.phone_preference,
            email: contactRequest.email_preference,
            chat: contactRequest.chat_preference
        })
    }, [contactRequest])

    useEffect(() => {
        const updateRequest = async (request) => {

            if(request.id !== contactRequest.id) return;

            setIsChecked({
                phone: request.phone_preference,
                email: request.email_preference,
                chat: request.chat_preference
            })

            setRequest(request);
        }

        socket.on('contactRequestUpdated', updateRequest)

        return () => {
            socket.off('contactRequestUpdated', updateRequest)
        }
    }, [socket])

    const onCheckChange = (type, value) => {
        setIsChecked({
            ...isChecked,
            [type]: !isChecked[type]
        });

        const dataToSend = {}

        switch (type) {
            case "phone": 
                dataToSend.phone_preference = value
                break;
            case "email":
                dataToSend.email_preference = value
                break;
            case "chat":
                dataToSend.chat_preference = value
                break;
            default:
                break;
        }

        handleRequestEdit(dataToSend);
    };

    const handleRequestEdit = async (dataToSend) => {
        const token = await auth.currentUser.getIdToken();
        try {
            const response = await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: request.id,
                    ...dataToSend
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("Préférence sélectionnée");
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    return (
        <div className="clt_messages_container_contact_message">
            <div onClick={() => onCheckChange("phone", !request.phone_preference)} className="clt_messages_container_contact_message_type">
                <p>Téléphone</p>
                <Form.Check
                    checked={isChecked.phone}
                    type="checkbox"
                    aria-label="phone"
                    onChange={() => {}}
                />
            </div>

            <div onClick={() => onCheckChange("email", !request.email_preference)} className="clt_messages_container_contact_message_type">
                <p>Email</p>
                <Form.Check
                    checked={isChecked.email}
                    type="checkbox"
                    aria-label="email"
                    onChange={() => {}}
                />
            </div>

            <div onClick={() => onCheckChange("chat", !request.chat_preference)} className="clt_messages_container_contact_message_type">
                <p>Chat en ligne</p>
                <Form.Check
                    checked={isChecked.chat}
                    type="checkbox"
                    aria-label="chat"
                    onChange={() => {}}
                />
            </div>
        </div>
    );
};

export default ChatComponentPreferenceContact;
