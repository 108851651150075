import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Offcanvas,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./admin-dashboard-event.css";
import BackArrow from "../../../../../assets/img/back_arrow.png";
import {
  eventbirthdayData,
  IMG_ALT,
  eventweddingData,
  newEventData,
  eventprofessionalData,
  eventreligiousData,
} from "../../../../../constants";
import { Link } from "react-router-dom";
import AdminIconDragDrop1 from "../../../../../assets/img/admin_icon_dragdrop_1.png";
import AdminIconManageEvent2 from "../../../../../assets/img/admin_icon_manage_event_2.png";
import AdminIconManageEvent3 from "../../../../../assets/img/admin_icon_manage_event_3.png";
import AdminIconManageEvent4 from "../../../../../assets/img/admin_icon_manage_event_4.png";
import GalleryImage1 from "../../../../../assets/img/detail_slider_1_1.png";
import GalleryImage2 from "../../../../../assets/img/detail_slider_1_2.png";
import GalleryImage3 from "../../../../../assets/img/detail_slider_1_3.png";
import NextArrow from "../../../../../assets/img/back_arrow_right.png";
import CustomHr from "../../../../reusable/CustomHr";
import Switch from "@mui/material/Switch";
import { v4 as uuid } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ManageName from "./ManageName";
import AddSection from "./integration_section3_home";
import IntegrationSection5 from "./integration_section5_detail";
import IntegrationSection6 from "./integration_section6_detail";
import { useDispatch } from "react-redux";
import {
  AddEventGalleryImage,
  createAmenity,
  createCategory,
  createDetails6Category,
  createEvents,
  createRules,
  deleteEvents,
  deleteGalleryItem,
  editEvent,
  EditEvent,
  EditEventGalleryOrder,
  EditEventInfo,
  getEvent,
  getEvents,
  toggleEvents,
} from "../../../../../redux/actions/avent";
import { useSelector } from "react-redux";
import { IMG_BASEURL } from "../../../../../constants";

const initialAdminEventScreens = {
  manageEvent: true,
  generalAndParameter: false,
  eventInformation: false,
  eventGallery: false,
  eventIntegration: false,
  eventIntegrationAddSection: false,
  eventIntegrationAddSectionSix: false,
};

const initialEventIntegrationSection5Screens = {
  manageCategory: true,
  manageAmenities: false,
  editName: false,
  editAmenities: false,
  editRules: false,
  isRules: false,
};

const homePageSection = [
  {
    id: 11,
    name: "Section 3",
  },
  {
    id: 12,
    name: "Section 4",
  },
  {
    id: 13,
    name: "Section 5",
  },
];

const detailPageSection = [
  {
    id: 1,
    name: "Section 2",
  },
  {
    id: 2,
    name: "Section 3",
  },
  {
    id: 3,
    name: "Section 4",
  },
  {
    id: 4,
    name: "Section 5",
  },
  {
    id: 5,
    name: "Section 6",
  },
];

const sectionSix = [
  {
    id: 21,
    name: "Section",
  },
  {
    id: 22,
    name: "Sidebar",
  },
];
const AdminDashboardEvent = ({
  openAdminEventSidebar,
  onCloseAdminEventSidebar,
}) => {
  const [adminEventScreens, setAdminEventScreens] = useState(
    initialAdminEventScreens
  );
  const [adminEventList, setAdminEventList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [activeTab, setActiveTab] = useState("general");
  const [isManageNameSidebar, setIsManageNameSidebar] = useState(false);
  const [uploadEventIcon, setUploadEventIcon] = useState(null);
  const [imageGalleryPicture, setImageGalleryPicture] = useState([]);
  const [selectedGalleryItem, setSelectedGalleryItem] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState(0);
  const [mySec5Categories, setMySec5Categories]= useState([]);
  const [mySec6Categories, setMySec6Categories]= useState([]);
  const [uploadedGallery, setUploadedGallery] = useState([
    {
      id: uuid(),
      image: GalleryImage1,
      name: "",
    },
    {
      id: uuid(),
      image: GalleryImage2,
      name: "",
    },
    {
      id: uuid(),
      image: GalleryImage3,
      name: "",
    },
  ]);

  const [eventIntegrationSection5Screens, setEventIntegrationSection5Screens] =
    useState(initialEventIntegrationSection5Screens);

  const [sectionCategoryList, setSectionCategoryList] = useState([
    { id: "1", title: "Category Name", isActive: true },
    { id: "2", title: "Category Name", isActive: true },
    { id: "3", title: "Category Name", isActive: true },
  ]);

  const [sectionAmenitiesList, setSectionAmenitiesList] = useState([
    { id: "1", title: "Amenities Name", isActive: true },
    { id: "2", title: "Amenities Name", isActive: true },
    { id: "3", title: "Amenities Name", isActive: true },
  ]);

  const [sectionRulesList, setSectionRulesList] = useState([
    { id: "1", title: "Rule Name", isActive: true },
    { id: "2", title: "Rule Name", isActive: true },
    { id: "3", title: "Rule Name", isActive: true },
  ]);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.allEvents.loading);
  const allEvents = useSelector((state) => state.allEvents.events);
  const [isEventCreate, setIsEventCreate] = useState(false);
  const [eventId, seteventId] = useState(null);
  const [currentEventGallery, setCurrentEventGallery] = useState({});
  const event = useSelector((state) => state.event.event);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [selectedMyCategory, setSelectedMyCategory] = useState(null);
  const [currentCatAminities, setCurrentCatAminities] = useState([])
  const [currentCategoryRules, setCurrentCategoryRules]= useState([])
  const [selectedMyDetails6Category, setSelectedMyDetails6Category] =
    useState(null);
  useEffect(() => {
    dispatch(getEvents());
  }, [isEventCreate]);

  useEffect(() => {
    if (allEvents?.length) {
      setAdminEventList(allEvents);
    }
  }, [allEvents]);

  useEffect(() => {
    if (event) {
      setCurrentEventGallery(event.eventGaller);
      setMySec5Categories(event?.categories)
      setMySec6Categories(event?.detailCategory)
      const currentCategory = event?.categories
      const allCategory = currentCategory?.find((myCategory) => myCategory.id === selectedCategoryName);
      if (allCategory) {
        setCurrentCatAminities(allCategory.amenity)
        const amenities = allCategory.amenity;
      }

      const currentCategoryRule = event?.detailCategory
      const allCategoryRule = currentCategoryRule?.find((myCategory) => myCategory.id === selectedCategoryName);
    if (allCategoryRule) {
      setCurrentCategoryRules(allCategoryRule?.rules)
      const amenities = allCategory?.rules;
    }
    }
  }, [event, selectedCategoryName]);

  useEffect(() => {}, []);

  //TODO: add new category
  const handleAddCategory = async () => {
    const data = { title: "Category Name", order:  mySec5Categories?.length};
    const categoryName = JSON.stringify(data);
    const response = await dispatch(
      createCategory(selectedEvent.id, categoryName)
    );
    if (response.error) {
    } else {
      dispatch(getEvent(selectedEvent.id));
    }
  };

  //TODO: add Section 6 category
  const handleAddDetails6Category = async () => {
    const data = { name: "Category Name", order: mySec6Categories?.length };
    const categoryName = JSON.stringify(data);
    const response = await dispatch(
      createDetails6Category(selectedEvent.id, categoryName)
    );
    if (response.error) {
      console.error("details category create error:", response.message);
    } else {
      dispatch(getEvent(selectedEvent.id));
    }
  };
  //TODO: add aminity firs time
  const handleAddAmenities = async () => {
    const data = { title: "Amenities Name", order: currentCatAminities?.length };
    const amenityName = JSON.stringify(data);
    const response = await dispatch(
      createAmenity(selectedMyCategory?.id, amenityName)
    );
    if (response.error) {
      console.error("Image upload error:", response.message);
    } else {
      dispatch(getEvent(selectedEvent.id));
    }
  };

  //TODO: selected aminity section 5
  const handleSetAminty = (data) => {
    setSelectedMyCategory(data);
  };

  //TODO: selected Rules section 6
  const handleSetRules = (data) => {
    setSelectedMyDetails6Category(data);
  };

  const handleAddRules = async () => {
    const data = { title: "Add Rule", order: currentCategoryRules?.length };
    const RuleName = JSON.stringify(data);
    const response = await dispatch(
      createRules(selectedMyDetails6Category?.id, RuleName)
    );
    if (response.error) {
      console.error("Rule name error:", response.message);
    } else {
      dispatch(getEvent(selectedEvent.id));
    }
  };

  //TODO: add rule section 6
  const handleAddRule = () => {
    setSectionRulesList((prevState) => [
      ...prevState,
      {
        ...newEventData,
        id: `${sectionRulesList.length + 1}`,
        title: "Rule Name",
        isActive: false,
      },
    ]);
  };

  const handleBackSection5 = (from, to) => {
    setEventIntegrationSection5Screens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };

  const handleBack = (from, to) => {
    setAdminEventScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };

  //TODO: Add event first time
  const handleAddEvent = async () => {
      const data = {
      title: "New",
      isActive: false,
      eventType: adminEventList?.length,
    };
    const eventName = JSON.stringify(data);
    const response = await  dispatch(createEvents(eventName));
    if (response.error) {
    } else {
      dispatch(getEvents());
    }
  };


  //TODO: edit event from event information
  const handleEventSave = async () => {
    const formData = new FormData();
    formData.append('title', selectedEvent.title);
    formData.append('color', selectedEvent.color);
    formData.append('background', selectedEvent.background);
    formData.append('image', uploadEventIcon);
    const id = selectedEvent.id;
    const response = await dispatch(EditEventInfo(id, formData));
    // setIsEventCreate(!isEventCreate);
    if (response.error) {
    } else {
      dispatch(getEvents());
    }
  };



  const handleDelete = async (id) => {
    const response = await dispatch(deleteEvents(id));
    if (response.error) {
    } else {
      dispatch(getEvents());
    }
  };

  //TODO: select gallery image
  const handleImageChange = (e) => {
    const myImage = e.target.files[0];
    setImageGalleryPicture(myImage);
  };

  //TODO:  gallery picture upload
  const handleImageUpload = async () => {
    const order = currentEventGallery?.length
    console.log('order is', order)
    const formData = new FormData();
    const title = "";
    formData.append("image", imageGalleryPicture);
    formData.append("title", title);
    formData.append("order", order);
    const response = await dispatch(
      AddEventGalleryImage(selectedEvent.id, formData)
    );
    if (response.error) {
      setImageGalleryPicture([]);
      console.error("Image upload error:", response.message);
    } else {
      setImageGalleryPicture([]);
      dispatch(getEvent(selectedEvent.id));
    }
  };

  //TODO: Delete images from gallery
  const handleGalleryDelete = async (id) => {
    const response = await dispatch(deleteGalleryItem(id));
    if (response.error) {
      console.error("Image upload error:", response.message);
    } else {
      dispatch(getEvent(eventId));
    }
  };

  const handleEventClick = (data) => {
    seteventId(data?.id);
    dispatch(getEvent(data?.id));
    setSelectedEvent(data);
    setAdminEventScreens((prevState) => ({
      ...prevState,
      generalAndParameter: true,
      manageEvent: false,
    }));
  };

  //TODO: open event information screen
  const handleEventInformationClick = () => {
    setAdminEventScreens((prevState) => ({
      ...prevState,
      eventInformation: true,
      generalAndParameter: false,
    }));
  };

  //TODO: open event gallery screen
  const handleEventGalleryClick = () => {
    setAdminEventScreens((prevState) => ({
      ...prevState,
      eventGallery: true,
      generalAndParameter: false,
    }));
  };

  //TODO: open event integration screens
  const handleEventIntegrationClick = () => {
    setAdminEventScreens((prevState) => ({
      ...prevState,
      eventIntegration: true,
      generalAndParameter: false,
    }));
  };

  const handleEventIntegrationAddSectionClick = (section) => {
    setSelectedSection(section.name);
    setSelectedSectionId(section.id);
    setAdminEventScreens((prevState) => ({
      ...prevState,
      eventIntegrationAddSection: true,
      eventIntegration: false,
      generalAndParameter: false,
    }));
  };

  const handleClose = () => {
    setAdminEventScreens(initialAdminEventScreens);
    setSelectedEvent(null);
    setUploadEventIcon(null);
    setActiveTab("general");
    onCloseAdminEventSidebar();
  };

  //TODO: Toggle to put online or offline
  const handleSelectedEvent = async () => {
    const id = selectedEvent.id;
    const status = { isActive: !selectedEvent.isActive };
    const data = JSON.stringify(status);
    setSelectedEvent((prevState) => ({
      ...prevState,
      isActive: !prevState.isActive,
    }));
    const response = await dispatch(EditEvent(id, data));
    if (response.error) {
    } else {
      dispatch(getEvents());
    }
  };


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));
  const CustomToggleImage = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
    </Link>
  ));

  const onDragEndEvent = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const draggedItemId = adminEventList[source.index].id;
    const copiedItems = [...adminEventList];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    copiedItems.forEach((eventData, index) => {
      eventData.order = index;
    });
    copiedItems.map((eventData, index) => {
      const data = { eventType: index };
      const updatedEvent = JSON.stringify(data);
      dispatch(EditEvent(eventData.id, updatedEvent));
    });
    const updatedAdminEventList = copiedItems.map((eventData, index) => {
      return { ...eventData, eventType: index };
    });
    setAdminEventList(updatedAdminEventList);
  };

  const onDragEndGallery = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...currentEventGallery];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    copiedItems.forEach((eventGallery, index) => {
      eventGallery.order = index;
    });
    copiedItems.map(async (galleryData, index) => {
      const data = { order: index };
      const updatedOrder = JSON.stringify(data);
      const result = await dispatch(EditEventGalleryOrder(galleryData.id, updatedOrder));
    });
    const updatedEventGallery = copiedItems.map((galleryData, index) => {
      return { ...galleryData, order: index };
    });
    setCurrentEventGallery(updatedEventGallery)
  };

  useEffect(() => {}, [adminEventScreens]);
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_products_offcanvas"
        show={openAdminEventSidebar}
        onHide={
          adminEventScreens.confirmBooking
            ? handleClose
            : onCloseAdminEventSidebar
        }
      >
        <Offcanvas.Header
          className={`justify-content-start clt_products_offcanvas_header_two ${
            adminEventScreens.generalAndParameter && "border-bottom-none"
          }`}
        >
          {adminEventScreens.manageEvent && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseAdminEventSidebar}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Évenement</Offcanvas.Title>
                <Button
                  className="clt_header_add_button shadow-none"
                  onClick={handleAddEvent}
                >
                  Ajouter
                </Button>
              </div>
            </React.Fragment>
          )}
          {adminEventScreens.generalAndParameter && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => handleBack("manageEvent", "generalAndParameter")}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <Offcanvas.Title>{selectedEvent?.title}</Offcanvas.Title>
            </React.Fragment>
          )}
          {adminEventScreens.eventInformation && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() =>
                  handleBack("generalAndParameter", "eventInformation")
                }
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <Offcanvas.Title>Event Information</Offcanvas.Title>
            </React.Fragment>
          )}
          {adminEventScreens.eventGallery && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() =>
                  handleBack("generalAndParameter", "eventGallery")
                }
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <Offcanvas.Title>Gallery</Offcanvas.Title>
            </React.Fragment>
          )}
          {adminEventScreens.eventIntegration && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() =>
                  handleBack("generalAndParameter", "eventIntegration")
                }
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <Offcanvas.Title>Integration</Offcanvas.Title>
            </React.Fragment>
          )}
          {adminEventScreens.eventIntegrationAddSection && (
            <>
              {(selectedSection !== "Section 5" &&
                selectedSection !== "Sidebar") ||
              selectedSectionId === 13 ? (
                <React.Fragment>
                  {selectedSectionId === 21 || selectedSectionId === 22 ? (
                    <button
                      type="button"
                      className="btn-close btn-back shadow-none d-flex"
                      aria-label="Close"
                      onClick={() =>
                        handleEventIntegrationAddSectionClick(
                          detailPageSection[4]
                        )
                      }
                    >
                      <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-close btn-back shadow-none d-flex"
                      aria-label="Close"
                      onClick={() =>
                        handleBack(
                          "eventIntegration",
                          "eventIntegrationAddSection"
                        )
                      }
                    >
                      <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                  )}
                  <Offcanvas.Title>{selectedSection}</Offcanvas.Title>
                </React.Fragment>
              ) : (
                <>
                  {eventIntegrationSection5Screens.manageCategory ? (
                    <React.Fragment>
                      {selectedSectionId === 21 || selectedSectionId === 22 ? (
                        <button
                          type="button"
                          className="btn-close btn-back shadow-none d-flex"
                          aria-label="Close"
                          onClick={() =>
                            handleEventIntegrationAddSectionClick(
                              detailPageSection[4]
                            )
                          }
                        >
                          <img src={BackArrow} className="" alt={IMG_ALT} />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-close btn-back shadow-none d-flex"
                          aria-label="Close"
                          onClick={() =>
                            handleBack(
                              "eventIntegration",
                              "eventIntegrationAddSection"
                            )
                          }
                        >
                          <img src={BackArrow} className="" alt={IMG_ALT} />
                        </button>
                      )}
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title>
                          {/* Section 5 */}
                          {selectedSection === "Sidebar"
                            ? "Sidebar"
                            : "Section 5"}
                        </Offcanvas.Title>
                        <Button
                          className="clt_header_add_button shadow-none"
                          onClick={
                            selectedSection === "Sidebar"
                              ? handleAddDetails6Category
                              : handleAddCategory
                          }
                        >
                          Add
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : eventIntegrationSection5Screens.manageAmenities ? (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={() =>
                          handleBackSection5(
                            "manageAmenities",
                            "manageCategory"
                          )
                        }
                      >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                      </button>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        {document.getElementById("mangerules")?.innerHTML ===
                        "Manage Rules" ? (
                          <Offcanvas.Title>
                          {selectedMyDetails6Category?.name}
                          </Offcanvas.Title>
                        ) : (
                          <Offcanvas.Title>
                          {selectedMyCategory?.title}
                          </Offcanvas.Title>
                        )}
                        {document.getElementById("mangerules")?.innerHTML ===
                        "Manage Rules" ? (
                          <Button
                            className="clt_header_add_button shadow-none"
                            onClick={handleAddRules}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            className="clt_header_add_button shadow-none"
                            onClick={handleAddAmenities}
                          >
                            Add
                          </Button>
                        )}
                      </div>
                    </React.Fragment>
                  ) : eventIntegrationSection5Screens.editName ? (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={() => {
                          if (selectedSection !== "Sidebar") {
                            initialEventIntegrationSection5Screens.isRules = true;
                          }
                          return;
                          handleBackSection5("editName", "manageCategory");
                        }}
                      >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                      </button>
                      <Offcanvas.Title>Edit Name</Offcanvas.Title>
                    </React.Fragment>
                  ) : eventIntegrationSection5Screens.editAmenities ? (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={() =>
                          handleBackSection5("editAmenities", "manageAmenities")
                        }
                      >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                      </button>
                      <Offcanvas.Title>Edit Amenities</Offcanvas.Title>
                    </React.Fragment>
                  ) : eventIntegrationSection5Screens.editRules ? (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={() =>
                          handleBackSection5("editRules", "manageAmenities")
                        }
                      >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                      </button>
                      <Offcanvas.Title>Edit Rules</Offcanvas.Title>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body
          className={`clt_products2_offcanvas_body ${
            adminEventScreens.generalAndParameter &&
            "clt-admin-event-body-no-padding"
          }`}
        >
          {adminEventScreens.manageEvent && (
            <>
              <Row className="clt-detail-section-6">
                <Col className="clt-admin-event-s1">
                  <Row className="clt-detail-section-6-header">
                    <Col>
                      <h2>Évenement</h2>
                      <p>Gérer les événements proposés aux utilisateurs</p>
                    </Col>
                  </Row>
                  <Row className="clt-admin-event-list-div-body m-0">
                    <Col>
                      <DragDropContext
                        // onDragEnd={(result) => onDragEndEvent(result)}
                        onDragEnd={onDragEndEvent}
                      >
                        <Droppable droppableId="events">
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {adminEventList
                                  ?.sort((a, b) => a.eventType - b.eventType)
                                  .map((eventData, index) => {
                                    return (
                                      <Draggable
                                        key={eventData.id}
                                        draggableId={eventData.id.toString()}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <Row
                                              key={index}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                userSelect: "none",
                                                backgroundColor:
                                                  snapshot.isDragging
                                                    ? "#fff"
                                                    : "transparent",
                                                border: snapshot.isDragging
                                                  ? "1px solid #dedddd"
                                                  : "none",
                                                borderRadius:
                                                  snapshot.isDragging
                                                    ? "10px"
                                                    : "0",
                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <Col
                                                className="clt-admin-event-list-div"
                                                style={{
                                                  borderBottom:
                                                    snapshot.isDragging &&
                                                    "none",
                                                }}
                                              >
                                                <div className="d-flex align-items-center w-100">
                                                  <img
                                                    src={AdminIconDragDrop1}
                                                    alt={IMG_ALT}
                                                    className="img-dragdrop"
                                                  />
                                                  <span className="dragdrop-counter">
                                                    {index + 1}
                                                  </span>
                                                  <div
                                                    className="inner-icon-title-div"
                                                    onClick={() =>
                                                      handleEventClick(
                                                        eventData
                                                      )
                                                    }
                                                  >
                                                    {eventData.icon ? (
                                                      <img
                                                        src={`${eventData.icon}`}
                                                        alt={IMG_ALT}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={
                                                          newEventData.iconActive
                                                        }
                                                        alt={IMG_ALT}
                                                      />
                                                    )}
                                                    <div className="d-flex flex-column align-items-start justify-content-center">
                                                      <h2>{eventData.title}</h2>
                                                      {!eventData.isActive && (
                                                        <p>Pause</p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                                    style={{
                                                      width: "10%",
                                                    }}
                                                  >
                                                    <Dropdown>
                                                      <Dropdown.Toggle
                                                        as={CustomToggle}
                                                      />
                                                      <Dropdown.Menu
                                                        size="sm"
                                                        title=""
                                                        className="pfr_dropdown_menu"
                                                      >
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleEventClick(
                                                              eventData
                                                            )
                                                          }
                                                          className="pfr_payment_cards3dots_options"
                                                        >
                                                          Edit
                                                        </Dropdown.Item>
                                                        {/*
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleDelete(
                                                              eventData.id
                                                            )
                                                          }
                                                          className="pfr_payment_cards3dots_options"
                                                        >
                                                          Delete
                                                        </Dropdown.Item>*/}
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }}
                                      </Draggable>
                                    );
                                  })}

                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {adminEventScreens.generalAndParameter && (
            <>
              <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="clt-admin-event-screen2-tabs"
              >
                <Tab
                  eventKey="general"
                  title="General"
                  className="clt-admin-event-screen2-tabs-body"
                >
                  <Row>
                    <Col>
                      <div className="clt-admin-event-screen2-tab-g-s1">
                        {selectedEvent?.icon ? (
                          <img src={`${selectedEvent.icon}`} alt={IMG_ALT} />
                        ) : (
                          <img src={newEventData.iconActive} alt={IMG_ALT} />
                        )}
                        {/* <img src={selectedEvent?.iconActive} alt={IMG_ALT} /> */}
                        <div className="d-flex flex-column align-items-start justify-content-center">
                          <h2>{selectedEvent?.title}</h2>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <CustomHr
                    colClass="clt-section-hr-pd2"
                    hrClass="p-0 m-0 clt-hr"
                  />
                  <Row>
                    <Col>
                      <div className="clt-admin-event-screen2-tab-g-s2 d-flex justify-content-between align-items-center">
                        <div className="inner-div d-flex flex-column">
                          <h2>En ligne</h2>
                          <p>Activez ce bouton pour mettre l'événement en ligne</p>
                        </div>
                        <Switch
                          checked={selectedEvent.isActive}
                          onChange={(e) => handleSelectedEvent()}
                          color="primary"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <CustomHr
                    colClass="clt-section-hr-pd2"
                    hrClass="p-0 m-0 clt-hr"
                  />
                </Tab>
                <Tab
                  eventKey="parameter"
                  title="Parameter"
                  className="clt-admin-event-screen2-tabs-body"
                >
                  <Row className="clt-admin-event-screen2-tab-info">
                    <Col lg="12" className="clt-admin-event-screen2-tab-col">
                      <div
                        className="d-flex justify-content-between align-items-center clt-admin-event-screen2-info"
                        onClick={handleEventInformationClick}
                      >
                        <div className="inner-div d-flex align-items-center">
                          <img src={AdminIconManageEvent2} alt={IMG_ALT} />
                          <h2>Event Information</h2>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>
                    </Col>
                    <Col
                      lg="12"
                      className="clt-admin-event-screen2-tab-col"
                      onClick={handleEventGalleryClick}
                    >
                      <div className="d-flex justify-content-between align-items-center clt-admin-event-screen2-info">
                        <div className="inner-div d-flex align-items-center">
                          <img src={AdminIconManageEvent3} alt={IMG_ALT} />
                          <h2>Gallery</h2>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>
                    </Col>
                    <Col lg="12" className="clt-admin-event-screen2-tab-col">
                      <div
                        className="d-flex justify-content-between align-items-center clt-admin-event-screen2-info"
                        onClick={handleEventIntegrationClick}
                      >
                        <div className="inner-div d-flex align-items-center">
                          <img src={AdminIconManageEvent4} alt={IMG_ALT} />
                          <h2>Integration</h2>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </>
          )}
          {adminEventScreens.eventInformation && (
            <>
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h3>Event Name</h3>
                  <p className="clt_admin_dash_timeslot_edit_box_subheading">
                    Choose the event type name
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-admin-event-info-input-div">
                  <Form.Floating className="pfr_inputFloat">
                    <Form.Control
                      id="floatingInput1"
                      type="text"
                      placeholder="Name"
                      value={selectedEvent.title}
                      onChange={(event) =>
                        setSelectedEvent((prevState) => {
                          return { ...prevState, title: event.target.value };
                        })
                      }
                    />
                    <label htmlFor="floatingInput1">Name</label>
                  </Form.Floating>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-section-hr-pd2"
                hrClass="p-0 m-0 clt-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Color</h2>
                </Col>
              </Row>
              <Row className="clt-admin-event-info-input-div">
                <Col>
                  <div className="clt-admin-event-input-form-lastchild">
                    <div className="clt-adminInputFloatDiv">
                      <Form.Floating className="clt-adminInputFloat">
                        <Form.Control
                          id="floatingInput1"
                          type="text"
                          placeholder="Event color"
                          value={selectedEvent.color}
                          onChange={(event) =>
                            setSelectedEvent((prevState) => {
                              return {
                                ...prevState,
                                color: event.target.value,
                              };
                            })
                          }
                        />
                        <label htmlFor="floatingInput1">Event color</label>
                      </Form.Floating>
                    </div>
                    <div className="clt-adminInputFloatDiv">
                      <Form.Floating className="clt-adminInputFloat">
                        <Form.Control
                          id="floatingInput1"
                          type="text"
                          placeholder="Event background"
                          value={selectedEvent.background}
                          onChange={(event) =>
                            setSelectedEvent((prevState) => {
                              return {
                                ...prevState,
                                background: event.target.value,
                              };
                            })
                          }
                        />
                        <label htmlFor="floatingInput1">Event background</label>
                      </Form.Floating>
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-section-hr-pd2"
                hrClass="p-0 m-0 clt-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Icon</h2>
                  <p class="clt_admin_dash_store_container_name">
                    NAVIGATION BAR ICON
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="clt-admin-event-fileinput-div">
                    <i className="fa-solid fa-plus"></i>
                    <span>
                      {uploadEventIcon ? uploadEventIcon.name : "Add an icon"}
                    </span>
                    <div className="opacity-0 div-hidden">
                      <input
                        type="file"
                        onChange={(e) =>
                          setUploadEventIcon(e.target.files[0])
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="pfr_payment_space_bottom" />
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none"
                  onClick={() => handleEventSave()}
                >
                  Save
                </Button>
              </div>
            </>
          )}
          {adminEventScreens.eventGallery && (
            <>
              <Row className="clt-detail-section-6-header">
                <Col>
                  <h2>Gallery</h2>
                </Col>
              </Row>
              <DragDropContext
              onDragEnd={onDragEndGallery}
                >
                <Droppable droppableId="gallery">
                  {(provided, snapshot) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Row className="mt-4">
                          <Col lg={6} xs={6}>
                            <div className="clt-admin-event-fileinput-div clt-admin-height-imgdiv clt-admin-event-gallery-file-upload">
                              <i className="fa-solid fa-plus"></i>
                              <span>
                                {imageGalleryPicture?.name
                                  ? imageGalleryPicture.name
                                  : "Add an icon"}
                              </span>
                              <div className="opacity-0 div-hidden">
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(e)}
                                />
                              </div>
                            </div>
                          </Col>

                          {currentEventGallery?.sort((a, b) => a.order - b.order).map((gallery, index) => {
                            return (
                              <Draggable
                                key={gallery.id}
                                draggableId={gallery.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Col
                                      lg={6}
                                      xs={6}
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        className="clt-admin-event-gallery-div clt-admin-height-imgdiv"
                                        style={{
                                          backgroundImage: `url(${gallery?.galleryImageUrl})`,
                                          // backgroundImage: `url(${IMG_BASEURL}${gallery?.galleryImageUrl})`,
                                        }}
                                      >
                                        <span>{index + 1}</span>
                                        <div
                                          className="d-flex align-items-center justify-content-end pfr_payment_cards3dots clt-admin-cards3dots"
                                          style={{ width: "10%" }}
                                        >
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              as={CustomToggleImage}
                                            />
                                            <Dropdown.Menu
                                              size="sm"
                                              title=""
                                              className="pfr_dropdown_menu"
                                            >
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setSelectedGalleryItem(
                                                    gallery
                                                  );
                                                  setIsManageNameSidebar(true);
                                                }}
                                                className="pfr_payment_cards3dots_options"
                                              >
                                                Manage name
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleGalleryDelete(
                                                    gallery.id
                                                  )
                                                }
                                                className="pfr_payment_cards3dots_options"
                                              >
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Row>
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
              <div className="pfr_payment_space_bottom" />
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none"
                  onClick={() => {imageGalleryPicture?.name && handleImageUpload()}}
                >
                  Save
                </Button>
              </div>
            </>
          )}
          {adminEventScreens.eventIntegration && (
            <>
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h3>Homepage variables data</h3>
                  <p className="clt_admin_dash_timeslot_edit_box_subheading">
                    On home there is all the sections that require variables
                    data from this event.
                  </p>
                </Col>
              </Row>

              <Row className="clt-admin-event-info-input-div mt-1">
                <Col>
                  {homePageSection.map((item, index) => (
                    <div
                      key={index}
                      className={`clt_admin_dash_store_event_container_wrapper_${
                        index == 0
                          ? "first"
                          : index == homePageSection.length - 1
                          ? "last"
                          : "center"
                      }`}
                      onClick={() =>
                        handleEventIntegrationAddSectionClick(item)
                      }
                    >
                      <div className="clt_admin_dash_store_event_container_box">
                        <div className="clt_admin_dash_store_event_container_box_name">
                          {item.name}
                        </div>
                        <div className="clt_admin_dash_store_event_container_arrow">
                          <img
                            src={NextArrow}
                            className="clt_admin_arrow"
                            alt={IMG_ALT}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row className="clt_admin_dash_manage_event_header mt-4 ">
                <Col>
                  <h3>Detail page variables data</h3>
                  <p className="clt_admin_dash_timeslot_edit_box_subheading">
                    On detail there is all the sections that require variables
                    data from this event.
                  </p>
                </Col>
              </Row>
              <Row className="clt-admin-event-info-input-div mb-4 mt-1">
                <Col>
                  {detailPageSection.map((item, index) => (
                    <div
                      key={index}
                      className={`clt_admin_dash_store_event_container_wrapper_${
                        index == 0
                          ? "first"
                          : index == detailPageSection.length - 1
                          ? "last"
                          : "center"
                      }`}
                      onClick={() =>
                        handleEventIntegrationAddSectionClick(item)
                      }
                    >
                      <div className="clt_admin_dash_store_event_container_box">
                        <div className="clt_admin_dash_store_event_container_box_name">
                          {item.name}
                        </div>
                        <div className="clt_admin_dash_store_event_container_arrow">
                          <img
                            src={NextArrow}
                            className="clt_admin_arrow"
                            alt={IMG_ALT}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>
            </>
          )}
          {adminEventScreens.eventIntegrationAddSection && (
            <>
              {selectedSection == "Section 5" && selectedSectionId !== 13 ? (
                <IntegrationSection5
                  screens={eventIntegrationSection5Screens}
                  setScreens={setEventIntegrationSection5Screens}
                  setCategory={setSelectedCategoryName}
                  category={selectedCategoryName}
                  sectionCategoryList={sectionCategoryList}
                  setSectionCategoryList={setSectionCategoryList}
                  sectionAmenitiesList={sectionAmenitiesList}
                  setSectionAmenitiesList={setSectionAmenitiesList}
                  onBack={handleBackSection5}
                  eventId={eventId}
                  onSelectEminity={handleSetAminty}
                />
              ) : selectedSection == "Sidebar" ? (
                <IntegrationSection6
                  screens={eventIntegrationSection5Screens}
                  setScreens={setEventIntegrationSection5Screens}
                  setCategory={setSelectedCategoryName}
                  category={selectedCategoryName}
                  sectionCategoryList={sectionCategoryList}
                  setSectionCategoryList={setSectionCategoryList}
                  sectionAmenitiesList={sectionRulesList}
                  setSectionAmenitiesList={setSectionRulesList}
                  onBack={handleBackSection5}
                  eventId={eventId}
                  onSelectedRules={handleSetRules}
                />
              ) : selectedSection !== "Section 6" ? (
                <AddSection
                  section={selectedSection}
                  sectionId={selectedSectionId}
                  eventId={eventId}
                  onBack={() => {
                    selectedSectionId === 21 || selectedSectionId === 22
                      ? handleEventIntegrationAddSectionClick(
                          detailPageSection[4]
                        )
                      : handleBack(
                          "eventIntegration",
                          "eventIntegrationAddSection"
                        );
                  }}
                />
              ) : (
                <>
                  <Row className="clt_admin_dash_manage_event_header">
                    <Col>
                      <h3>Section 6</h3>
                    </Col>
                  </Row>

                  {/* sectionSix */}

                  <Row className="clt-admin-event-info-input-div mt-1">
                    <Col>
                      {sectionSix.map((item, index) => (
                        <div
                          key={index}
                          className={`clt_admin_dash_store_event_container_wrapper_${
                            index == 0 ? "first" : "last"
                          }`}
                          onClick={() =>
                            handleEventIntegrationAddSectionClick(item)
                          }
                        >
                          <div className="clt_admin_dash_store_event_container_box">
                            <div className="clt_admin_dash_store_event_container_box_name">
                              {item.name}
                            </div>
                            <div className="clt_admin_dash_store_event_container_arrow">
                              <img
                                src={NextArrow}
                                className="clt_admin_arrow"
                                alt={IMG_ALT}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {selectedGalleryItem && (
        <ManageName
          title={"Manage name"}
          inputData={selectedGalleryItem}
          isSidebar={isManageNameSidebar}
          onCloseSidebar={() => {
            setSelectedGalleryItem({});
            setIsManageNameSidebar(false);
          }}
          onUpdateData={(data) => {
            console.log('data is', data)
            const mydata = { title: data.title };
            const updateData = JSON.stringify(mydata);
            dispatch(editEvent(data.id, updateData));
            dispatch(getEvent(eventId));
          }}
        />
      )}
    </>
  );
};

export default AdminDashboardEvent;
