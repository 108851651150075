import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function TextEditor({ value, setValue, theme = "snow", style, placeholder }) {
  return (
    <ReactQuill
      theme={theme}
      value={value}
      onChange={setValue}
      style={style}
      placeholder={placeholder}
    />
  );
}

export default TextEditor;
