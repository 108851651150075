import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";

const SectionPaymentMethod = ({
  title,
  image,
  handleClick,
  text,
  subText,
  buttonText,
}) => {
  return (
    <Row>
      <Col>
        {title && <h2 className="clt-booking-cp-div6-title">{title}</h2>}
        {/* <div className="d-flex justify-content-start align-items-start clt-booking-cp-div6">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div6-text">
              <h2>{text}</h2>
              <p>{subText}</p>
            </div>
            <Button className="shadow-none" onClick={handleClick}>
              <span>{buttonText}</span>
            </Button>
          </div>
        </div> */}
        <div
            className="clt-booking-cp-div1Box clt-booking-hoverActive"
            onClick={handleClick}
          >
            <div className="d-flex align-items-center justify-content-between flex-row w-100">
            <div className="clt-booking-div1" style={{marginLeft:0,width:'calc(100% - 10px)'}}>
                {/* <div className="clt-booking-innner-div1 clt-booking-bgImg1"></div> */}
                 {/* <div className="clt-account-screen-inner-div-img"> */}
                 <div className="clt-account-screen-inner-div-img clt-booking-innner-div1 ">
                 <div>
            <img src={image} alt={IMG_ALT} />
          </div>
        </div>
                <div className="inner-div-1 clt-booking-cp-div3-text" style={{marginLeft:0,width:'calc(100% - 40px)'}}>
                <h2>{text}</h2>
                <p style={{overflow:'hidden',whiteSpace:'nowrap',overflowWrap:'break-word'}}>{subText}</p>
                </div>
              </div>
              <div>
              <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
      </Col>
    </Row>
  );
};

export default SectionPaymentMethod;
