import React, { useState } from "react";
import { API_ENDPOINT, IMG_ALT } from "../../../../../constants";
import { Col, Form, Row, Button } from "react-bootstrap";
import { Actions, useAuth } from "../../authContext";
import axios, { Axios, AxiosError } from "axios";
import { ErrorToast, SuccessToast } from "../../../../reusable/Toast"; // Import SuccessToast
import { LoaderDots } from '@thumbtack/thumbprint-react'; // Importez le composant LoaderDots
import { AxiosErrorHandler } from "../../api";

const EmailSignUp = ({ handleContinue, handleError, authenticationFlow }) => {
  const auth = useAuth();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: authenticationFlow ? authenticationFlow.email : auth.email,
    password: auth.password,
  });

  const togglePasswordVisibility = () => {
    setPasswordVisibility((v) => !v);
  };

  const handleEmailChange = (e) => {
    setData((d) => ({ ...d, email: e.target.value }));
    setEmailError(false);
  };

  const handlePasswordChange = (e) => {
    setData((d) => ({ ...d, password: e.target.value }));
    setPasswordError(false);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(data.email);
  };

  const contains = (characters, value = "", count = 1) => {
    const findCount = value
      .split("")
      .map((char, i) => (characters.includes(char) ? true : false))
      .filter((val) => val).length;
    return findCount >= count;
  };

  const handleClick = async () => {
    if (data.email === "" || data.password === "") {
      ErrorToast("Information incorrecte", "Veuillez vérifier les informations saisies.");
      handleError(true);
      return;
    }

    if (!validateEmail()) {
      ErrorToast("Email incorrect", "L'e-mail ne répond pas aux critères.");
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const special = "!~@`#$%^&*(_)+=/?.,}{[]|-><";
    const numbers = "1234567890";

    const isValid =
      data.password.length >= 8 &&
      contains(letters.toLowerCase(), data.password, 1) &&
      contains(letters.toUpperCase(), data.password, 1) &&
      contains(special, data.password, 1) &&
      contains(numbers, data.password, 1);

    if (!isValid) {
      ErrorToast("Mot de passe incorrect", "Le mot de passe ne répond pas aux critères.");
      setPasswordError(true);
      return;
    }

    setLoading(true); // Activate the loader

    try {
      const url = API_ENDPOINT + "/auth/send-email-verification";
      const response = await axios.post(url, {
        email: data.email,
      });
      localStorage.setItem("email_verification_token", response.data.token);
      auth.dispatch({ type: Actions.SET_DATA, payload: data });

      setLoading(false); // Disable the loader

      // Show the success toast
      SuccessToast("Code envoyé", "Un code à 6 chiffres est envoyé sur votre adresse e-mail");

      handleContinue();
    } catch (error) {
      setLoading(false); // Disable the loader in case of an error

      if (error instanceof AxiosError) {
        ErrorToast(error.response.data.message);
        return;
      }
      ErrorToast(error.message);
    }
  };

  return (
    <div className="pfr_loginModalVerify">
      <div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
        <Row>
          <Col>
            <h2 className="pfr_loginModalH2">
              Entrez une adresse email et un mot de passe
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="pfr_loginModalEmailDiv">
            <Row>
              <Col className={`pfr_inputFloatDiv  ${emailError ? "error" : ""}`}>
                <Form.Floating className={`pfr_inputFloat ${emailError ? "error" : ""}`}>
                  <Form.Control
                    id="floatingInput1"
                    type="email"
                    placeholder="Email address"
                    value={data.email}
                    disabled={authenticationFlow && authenticationFlow.email}
                    onChange={handleEmailChange}
                    name="email"
                    className={`formInput ${emailError ? "error" : ""}`}
                  />
                  <label htmlFor="floatingInput1" className={`formLabel ${emailError ? "error" : ""}`}>Adresse e-mail</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col className={`pfr_inputFloatDiv ${passwordError ? "error" : ""}`}>
                <Form.Floating className={`pfr_inputFloat ${passwordError ? "error" : ""}`}>
                  <Form.Control
                    id="floatingInput2"
                    type={!passwordVisibility ? "password" : "text"}
                    placeholder="Password"
                    onChange={handlePasswordChange}
                    name="password"
                    value={data.password}
                    className={`formInput ${passwordError ? "error" : ""}`}
                  />
                  <label htmlFor="floatingInput2" className={`formLabel ${passwordError ? "error" : ""}`}>Mot de passe</label>
                  <img
                    src={require(`../../../../../assets/img/${
                      passwordVisibility
                        ? "icon_pass_hide.png"
                        : "icon_pass_show.png"
                    }`)}
                    className="pfr_loginShowPass"
                    onClick={togglePasswordVisibility}
                    alt={IMG_ALT}
                  />
                </Form.Floating>
              </Col>
            </Row>

            <Row>
              <Col className="pfr_signUpResendDiv">
                <p className="pfr_signUpResendP">
                  8 caractères minimum (au moins une lettre minuscule, une lettre majuscule, un caractère spécial et un nombre)
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="pfr_loginModalVerifyDiv">
        <Row className="pfr_loginModalSeparatorDiv">
          <div className="pfr_loginModalSeparator" />
        </Row>
        <div className="pfr_loginModal_footer">
          <Col>
            <Button
              disabled={disabled || isLoading}
              className={`pfr_loginModalBtn shadow-none ${disabled ? 'btn-opacity' : ''}`}
              onClick={handleClick}
            >
              {isLoading ? (
                <LoaderDots />
              ) : (
                "Continuer"
              )}
            </Button>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default EmailSignUp;
