import React, { useEffect, useState } from "react";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import NextArrow from "../../../assets/img/back_arrow_right.png";
import NextArrowWhite from "../../../assets/img/back_arrow_right_white_thin.png";
import { IMG_BASEURL } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "../../../redux/actions/avent";

const SectionContactRequestEvent = ({ props, image, subText, IsEventButtonDropdown, buttonText, event, eventType, setEventType, handleRequestEdit }) => {

  

  const CustomToggleFirst = React.forwardRef(
    ({ children, onClick, value }, ref) => (
      <p
        className={`clt_right_form_filter_chip`}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
          console.log(e);
          e.stopPropagation();
        }}
      >
        {"Modifier"}
        <img
          src={NextArrow}
          className="clt_admin_arrow down"
          alt={IMG_ALT}
        />
      </p> 
    )
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvents());

  }, []);

  const allEvents = useSelector((state) => state.allEvents.events);

  const sortedEvents = allEvents?.sort((a, b) => a.eventType - b.eventType);

  const [imageUrl, setImageUrl] = useState()
  useEffect (() => {
    if(event) {
      const url = event?.icon
      setImageUrl(url)
    }
  },[event])

  // Check if the props object and the required properties are defined
  // const data = props && props.data;
  // const setEventType = data && data.setEventType;
  // const eventType = data && data.eventType;
  
  return (
    <Row className="clt-booking-mb">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={event?.icon || image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Évenement</h2>
              <p>{ eventType ? eventType : subText}</p>
            </div>

            {/* Dropdown - Event */}
            { IsEventButtonDropdown && (
              <div className="clt_right_form_filter_chip_container">
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggleFirst} />
                  <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu_month_year">
                    {sortedEvents && sortedEvents.map((v) => (
                      <Dropdown.Item
                        key={Math.random()}
                        className="pfr_payment_cards3dots_options"
                        onClick={() => { console.log(v);setEventType(v.title); handleRequestEdit({ eventId: v.id, icon: v.icon }); }}
                      >
                        {v.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionContactRequestEvent;
