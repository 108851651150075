/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import "../account.css";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../constants";
import { ErrorToast } from "../../../reusable/Toast";
const AccountInputAddress = ({
  isSidebar,
  onCloseSidebar,
  title,
  inputData,
  onUpdateData,
}) => {
  const [inputValue, setInputValue] = useState(inputData);
  const [focusedField, setFocusedField] = useState(null);

  const handleInputChange = (event) => {
    inputData.value = {
      ...inputData.value,
      [event.target.name]: event.target.value,
    };
    setInputValue((prevState) => ({
      ...prevState,
      value: {
        ...inputData.value,
        [event.target.name]: event.target.value,
      },
    }));
  };
  const isRequired = (value) => value === null || value === '';

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  const [checkError,setCheckError]=useState(false);
  const handleSave = () => {
    let isError = false;
    if(inputData.value){
      isError =(
        isRequired(inputData.value.address) ||
        isRequired(inputData.value.city) ||
        isRequired(inputData.value.zip_code) ||
        isRequired(inputData.value.country) ||
        isRequired(inputData.value.state_region)
        )
    }
    setCheckError(isError);
    if(isError===false)
    {
    onUpdateData({ name: inputData.name, value: inputData.value });
    setInputValue({});
    onCloseSidebar();
  }
    else{
      ErrorToast("Information manquante", "Veuillez ajouté les informations nécessaires.");
      
      return isError;

    }
  };
  useEffect(()=>{
    setCheckError(false)
  },[isSidebar,onUpdateData])
  return (
    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isSidebar}
      onHide={onCloseSidebar}
    >
      <Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onCloseSidebar}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className={`clt_payment_offcanvas_body`}>
        <>
          <div className="">
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pfr_inputFloatDiv"
              >
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="address"
                    type="text"
                    placeholder="Address"
                    value={inputData?.value?.address}
                    onChange={(event) => handleInputChange(event)}
                    style={{
                      borderColor: checkError && isRequired(inputData?.value?.address) ? '#d32f2f' : '',
                      backgroundColor: checkError && focusedField !== 'adress' && isRequired(inputData?.value?.address) ? '#f2e4e4' : '',
                    }}
                    onFocus={() => handleFocus('adress')}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="floatingInput1"
                    style={{
                      color:checkError && focusedField !== 'adress'  && isRequired(inputData?.value?.address) ? '#d32f2f' : '',
                    }}>Address</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6} className="pfr_inputFloatDiv">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="city"
                    type="text"
                    placeholder="City"
                    value={inputData?.value?.city}
                    onChange={(event) => handleInputChange(event)}
                    style={{
                      borderColor: checkError && isRequired(inputData?.value?.city) ? '#d32f2f' : '',
                      backgroundColor: checkError && focusedField !== 'city' && isRequired(inputData?.value?.city) ? '#f2e4e4' : '',
                    }}
                    onFocus={() => handleFocus('city')}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="floatingInput1"
                    style={{
                      color:checkError && focusedField !== 'city' && isRequired(inputData.value.city) ? '#d32f2f' : '',
                    }}
                  >City</label>
                </Form.Floating>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="pfr_inputFloatDiv">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="zip_code"
                    type="text"
                    placeholder="Zip code"
                    value={inputData?.value?.zip_code}
                    onChange={(event) => handleInputChange(event)}
                    style={{
                      borderColor: checkError && isRequired(inputData?.value?.zip_code) ? '#d32f2f' : '',
                      backgroundColor: checkError && focusedField !== 'zip' && isRequired(inputData?.value?.zip_code) ? '#f2e4e4' : '',
                    }}
                    onFocus={() => handleFocus('zip')}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="floatingInput1"
                    style={{
                      color:checkError && focusedField !== 'zip' && isRequired(inputData.value.zip_code) ? '#d32f2f' : '',
                    }}
                  >Zip code</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pfr_inputFloatDiv"
              >
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="state_region"
                    type="text"
                    placeholder="State/Region"
                    value={inputData?.value?.state_region}
                    onChange={(event) => handleInputChange(event)}
                    style={{
                      borderColor: checkError && isRequired(inputData?.value?.state_region) ? '#d32f2f' : '',
                      backgroundColor: checkError && focusedField !== 'state' && isRequired(inputData?.value?.state_region) ? '#f2e4e4' : '',
                    }}
                    onFocus={() => handleFocus('state')}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="floatingInput1"
                    style={{
                      color:checkError && focusedField !== 'state' && isRequired(inputData.value.state_region) ? '#d32f2f' : '',
                    }}>State/Region</label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pfr_inputFloatDiv"
              >
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="country"
                    type="text"
                    placeholder="Country"
                    value={inputData?.value?.country}
                    onChange={(event) => handleInputChange(event)}
                    style={{
                      borderColor: checkError && isRequired(inputData?.value?.country) ? '#d32f2f' : '',
                      backgroundColor: checkError && focusedField !== 'country' && isRequired(inputData?.value?.country) ? '#f2e4e4' : '',
                    }}
                    onFocus={() => handleFocus('country')}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="floatingInput1"
                    style={{
                      color:checkError && focusedField !== 'country' && isRequired(inputData.value.country) ? '#d32f2f' : '',
                    }}>Country</label>
                </Form.Floating>
              </Col>
            </Row>
            <div className="pfr_payment_space_bottom" />
          </div>
          <div className="clt_payment_footer">
            <Button
              className="shadow-none clt_payment_header_button_black"
              // disabled={
              //   !inputData.value ||
              //   inputData.value.address === "" ||
              //   inputData.value.city === "" ||
              //   inputData.value.zip_code === "" ||
              //   inputData.value.state_region === "" ||
              //   inputData.value.country === ""
              //     ? true
              //     : false
              // }
              onClick={handleSave}
            >
              Enregistrer
            </Button>
          </div>
        </>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AccountInputAddress;
