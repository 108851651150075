import React, { useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";
import "./admin_dash_invoice.css";
import BackArrow from "../../../../../assets/img/back_arrow.png";
import {API_ENDPOINT, IMG_ALT} from "../../../../../constants";
import { Link } from "react-router-dom";
import { RangeDatePicker } from "react-google-flight-datepicker";
import InvoiceIcon from "../../../../../assets/img/icon_invoice.png";
import {auth} from "../../../auth/firebase";
import axios from "axios";
import {ErrorToast} from "../../../../reusable/Toast";

const initialExportDate = {
    startDate: null,
    endDate: null,
};


const AdminDashboardInvoice = ({
    isInvoiceSidebar,
    onCloseInvoiceSidebar,
    allInvoices,
    allAdditional,
    setAllInvoices,
    allRefunds,
    setAllRefunds
}) => {

    const [isInvoiceCardSelected, setIsInvoiceCardSelected] = useState(false);
    const [selectedExportOption, setSelectedExportOption] = useState(0);
    const CustomInvoiceToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
          to=""
          ref={ref}
          className="clt_mybooking_header_dropdown"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children} <i className="fas fa-chevron-down"></i>
        </Link>
      ));
    
    const [invoiceFilter, setInvoiceFilter] = useState('All invoice');
    const [selectedinvoiceFilter, setselectedinvoiceFilter] = useState('All invoice');


    const [isExportDate, setisExportDate] = useState(initialExportDate);
    const [isInvoiceelected, setisInvoiceelected] = useState(true);
    const [isCsvSelected, setisCsvSelected] = useState(true);
    const [isExportInvoiceSelected, setisExportInvoiceSelected] = useState(true);
    const [isExportRefundSelected, setisExportRefundSelectedd] = useState(true);

    const handleInvoiceCheckboxChange = (e) => {
        console.log(e.target.checked);
        setisInvoiceelected(e.target.checked);
    // You can perform any desired action here.
    // To get the current state, you can use e.target.checked.
    };
    const handleCSVCheckboxChange = (e) => {
        // You can perform any desired action here.
        // To get the current state, you can use e.target.checked.
        console.log(e.target.checked);
        setisCsvSelected(e.target.checked);
    };
    const handleExportInvoicePayment = (e) => {
        //console.log(e.target.checked);
        setisExportInvoiceSelected(e.target.checked);
        if (isExportRefundSelected && isExportInvoiceSelected){
            // All invoice
            setselectedinvoiceFilter('All invoice');
        }else if(!isExportRefundSelected && isExportInvoiceSelected) {
            setselectedinvoiceFilter('Invoice payment');
        }else {
            setselectedinvoiceFilter('Invoice refund');
        }
    // You can perform any desired action here.
    // To get the current state, you can use e.target.checked.
    };
    const handleExportInvoiceRefund = (e) => {
        // You can perform any desired action here.
        // To get the current state, you can use e.target.checked.
        //console.log(e.target.checked);
        setisExportRefundSelectedd(e.target.checked);
        if (isExportRefundSelected && isExportInvoiceSelected){
            // All invoice
            setselectedinvoiceFilter('All invoice');
        }else if(isExportRefundSelected && !isExportInvoiceSelected) {
            setselectedinvoiceFilter('Invoice refund');
        }else {
            setselectedinvoiceFilter('Invoice payment');
        }
    };

    

    
    
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            className="clt_admin_dash_invoice_card3dot"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                console.log(e);
            }}
        >
            {children}
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </Link>
    ));
    const handleReceiptClick = async (id,time='') => {
        const token = await auth.currentUser.getIdToken();
        const newWindow = window.open(API_ENDPOINT + "/booking/receipt/" + token + "/" + btoa(id)+'/'+time, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }
    const handleAdditionalInvoice = async (id,aId) => {
        const token = await auth.currentUser.getIdToken();
        const newWindow = window.open(API_ENDPOINT + "/booking/receiptAdditional/" + token + "/" + btoa(id)+'/'+aId, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const handleCloseSidebar = () => {
        if (!isInvoiceCardSelected)
            onCloseInvoiceSidebar()
        else
            setIsInvoiceCardSelected(false)
    }
    function convertToStartOfDay(inputDate) {
        const date = new Date(inputDate);
        date.setUTCHours(23, 0, 0, 0); // Set hours to 23 and reset minutes, seconds, and milliseconds
        return date.toISOString();
    }

    function getTomorrow(){
        const today = new Date() // get today's date
        const tomorrow = new Date(today)
        tomorrow.setDate(today.getDate() + 1)
        return tomorrow;
    }

    function getYesterday() {
        const today = new Date() // get today's date
        const tomorrow = new Date(today)
        tomorrow.setDate(today.getDate() - 1)
        return tomorrow;
    }

    const handleExportClick = () => {
        console.log(convertToStartOfDay(isExportDate.startDate));
        console.log(convertToStartOfDay(isExportDate.endDate));
        console.log(new Date().toISOString().split('T')[0]);
        console.log(isCsvSelected);
        console.log(selectedExportOption);
        let startdate = new Date().toISOString().split('T')[0];
        let enddate = getTomorrow().toISOString().split('T')[0];
        if (selectedExportOption !== 1){
            startdate = isExportDate.startDate.toISOString().split('T')[0];
            enddate =isExportDate.endDate.toISOString().split('T')[0];
        }
        const shouldExportInvoice = isExportInvoiceSelected?'1':'0';
        const shouldExportRefund = isExportRefundSelected?'1':'0';
        if (isCsvSelected){

            const newWindow = window.open(API_ENDPOINT + "/booking/downloadZip/"+startdate+"/"+enddate+"?invoice="+shouldExportInvoice+"&refund="+shouldExportRefund, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        }

        // if (isInvoiceelected){
        //     getFilteredPayments(startdate,enddate);
        //     getFilteredRefunds(startdate,enddate);
        // }
        // if (isExportRefundSelected){
        //     getFilteredRefunds(startdate,enddate);
        // }
        // if (isExportInvoiceSelected){
        //     getFilteredPayments(startdate,enddate);
        // }
            getFilteredPayments(startdate,enddate);
            getFilteredRefunds(startdate,enddate);

    }
    const getFilteredPayments = async (startData,endDate) => {
        console.log("All Payments Starting");
        let invFilter = '';
        if (selectedinvoiceFilter === 'All invoice'){
            invFilter = 'all';
        }else if(selectedinvoiceFilter === 'Invoice payment'){
            invFilter = 'invoice';
        }else if(selectedinvoiceFilter === 'Invoice refund'){
            invFilter = 'refund';
        }
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + "/booking/filterAllPayments/"+startData+"/"+endDate+"?type="+invFilter,
                {headers: {Authorization: `Bearer ${token}`}}
            );
            console.log("All Payments");
            console.log(response.data.payments);
            setAllInvoices(response.data.payments);
            setIsInvoiceCardSelected(false);
            // setOpenDashboardInvoice(true)
        } catch (error) {
            console.log('location on here: ', error);
            ErrorToast(error?.response?.data?.message);
        }
    }
    const getFilteredRefunds = async (startData,endDate) => {
        console.log("All Payments Starting");
        let invFilter = '';
        if (selectedinvoiceFilter === 'All invoice'){
            invFilter = 'all';
        }else if(selectedinvoiceFilter === 'Invoice payment'){
            invFilter = 'invoice';
        }else if(selectedinvoiceFilter === 'Invoice refund'){
            invFilter = 'refund';
        }
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + "/booking/filterAllrefunds/"+startData+"/"+endDate+"?type="+invFilter,
                {headers: {Authorization: `Bearer ${token}`}}
            );
            console.log("All Payments");
            console.log(response.data.payments);
            setAllRefunds(response.data.payments);
            setIsInvoiceCardSelected(false);
            // setOpenDashboardInvoice(true)
        } catch (error) {
            console.log('location on here: ', error);
            ErrorToast(error?.response?.data?.message);
        }
    }
    const getAllPayments = async (state) => {
        console.log(invoiceFilter);
        let invFilter = '';
        if (state === 'All invoice'){
            invFilter = 'all';
        }else if(state === 'Invoice payment'){
            invFilter = 'invoice';
        }else if(state === 'Invoice refund'){
            invFilter = 'refund';
        }
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + "/booking/all-payments/?type="+invFilter,
                {headers: {Authorization: `Bearer ${token}`}}
            );
            console.log(state);
            console.log(response.data.payments);
            setAllInvoices(response.data.payments);
            setIsInvoiceCardSelected(false);
            const refundresponse = await axios.get(API_ENDPOINT + "/booking/all-refunds/?type="+invFilter,
                {headers: {Authorization: `Bearer ${token}`}}
            );
            console.log("All Refunds");
            console.log(refundresponse.data.payments);
            setAllRefunds(refundresponse.data.payments);
            // setOpenDashboardInvoice(true)
        } catch (error) {
            console.log('location on here: ', error);
            ErrorToast(error?.response?.data?.message);
        }
    }

    const filterInvoiceController = async (state) =>{
        console.log("State :" + state)
        setInvoiceFilter(state);
        await getAllPayments(state);
        console.log("State :" + invoiceFilter)
    }
    const handleRefundInvoice = async (id,obj) =>{
        let invoice;
        if (Array.isArray(obj?.Booking?.refund_data)) {
            let findObj = obj.Booking.refund_data.filter(x => x.id == obj.id);
            if (findObj && findObj.length) {
                invoice = findObj[0].invoice;
            } else {
                return;
            }
        } else {
            return;
        }
        
        const token = await auth.currentUser.getIdToken();
        const newWindow = window.open(API_ENDPOINT + "/booking/refundInvoice/" + token + "/" + btoa(id)+'/'+invoice, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <Offcanvas
            placement="end"
            className="clt_admin_dash_invoice_offcanvas"
            show={isInvoiceSidebar}
            onHide={onCloseInvoiceSidebar}
        >
            <Offcanvas.Header className="justify-content-start clt_admin_dash_invoice_offcanvas_header">

                <React.Fragment>
                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={handleCloseSidebar}
                    >
                        <img src={BackArrow} alt={IMG_ALT} />
                    </button>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title>{isInvoiceCardSelected ? "Export" : "All invoice"}</Offcanvas.Title>
                        {!isInvoiceCardSelected && (
                            <Button
                                className="clt_admin_dash_invoice_header_button shadow-none"
                                onClick={() => setIsInvoiceCardSelected(true)}
                            >
                                Export
                            </Button>
                        )}
                        {!isInvoiceCardSelected && (
                            <Dropdown>
                                <Dropdown.Toggle as={CustomInvoiceToggle}>
                                    {invoiceFilter === 'All invoice' ? 'All invoice' : invoiceFilter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                                    <Dropdown.Item
                                    onClick={() => filterInvoiceController('All invoice')}
                                    className={`clt_mybooking_header_dropdown_option ${
                                        invoiceFilter === 'All invoice' ? 'active' : ''
                                    }`}
                                    >
                                    All invoice
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                    onClick={() => filterInvoiceController('Invoice payment')}
                                    className={`clt_mybooking_header_dropdown_option ${
                                        invoiceFilter === 'Invoice payment' ? 'active' : ''
                                    }`}
                                    >
                                    Invoice payment
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                    onClick={() => filterInvoiceController('Invoice refund')}
                                    className={`clt_mybooking_header_dropdown_option ${
                                        invoiceFilter === 'Invoice refund' ? 'active' : ''
                                    }`}
                                    >
                                    Invoice refund
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                    onClick={() => filterInvoiceController('Invoice refund')}
                                    className={`clt_mybooking_header_dropdown_option ${
                                        invoiceFilter === 'Invoice refund' ? 'active' : ''
                                    }`}
                                    >
                                    Invoice additionnal
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                    </div>
                </React.Fragment>


            </Offcanvas.Header>
            <Offcanvas.Body
                className={`${!isInvoiceCardSelected ? "clt_admin_dash_invoice_offcanvas_body" : "clt_admin_dash_invoice_offcanvas_body_2"}`}
            >
                {!isInvoiceCardSelected ?
                    <>
                    {
                        allInvoices && allInvoices?.map((booking) =><>
                            {booking?.payment_id?.startsWith("sub_")?
                                <>{booking?.subscription_payments?.map((subscription, i) => <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                                    <div
                                        className="d-flex align-items-center justify-content-start"
                                        style={{ width: "20%" }}
                                    >
                                        <div className="clt_reuseable_invoice_imgdiv">
                                            <img
                                                src={InvoiceIcon}
                                                className=""
                                                alt="Image"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex align-items-start justify-content-center flex-column"
                                        style={{ width: "70%" }}
                                    >
                                        <h3 className="clt_reuseable_invoice_cardnumber">#F{zeroPad(subscription?.invoice,8)}</h3>
                                        <p className="clt_reuseable_invoice_cardname">Payment ({i+1}/3) - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false}):new Date(booking?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                        {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                        style={{ width: "10%" }}
                                    >
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title=""
                                                           className="pfr_dropdown_menu">
                                                <Dropdown.Item
                                                    onClick={()=>{handleReceiptClick(booking?.message?booking?.message?.id:booking?.id,i+1)}}
                                                    className="clt_reuseable_invoice_cards3dots_options"
                                                >
                                                    View the receipt
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>)}</>
                                :<div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                                    <div
                                        className="d-flex align-items-center justify-content-start"
                                        style={{ width: "20%" }}
                                    >
                                        <div className="clt_reuseable_invoice_imgdiv">
                                            <img
                                                src={InvoiceIcon}
                                                className=""
                                                alt="Image"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex align-items-start justify-content-center flex-column"
                                        style={{ width: "70%" }}
                                    >
                                        <h3 className="clt_reuseable_invoice_cardnumber">#F{booking?.message?zeroPad(booking?.payment?.id,8):zeroPad(booking?.subscription_payments?.paymentId,8)}</h3>
                                        <p className="clt_reuseable_invoice_cardname">Payment (1/1) - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false}):new Date(booking?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                        {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                        style={{ width: "10%" }}
                                    >
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title=""
                                                           className="pfr_dropdown_menu">
                                                <Dropdown.Item
                                                    onClick={()=>{handleReceiptClick(booking?.message?booking?.message?.id:booking?.id)}}
                                                    className="clt_reuseable_invoice_cards3dots_options"
                                                >
                                                    View the receipt
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>}
                        </>)
                    }
                        {
                            allRefunds && allRefunds?.map((refund,i) => <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                                <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                >
                                    <div className="clt_reuseable_invoice_imgdiv">
                                        <img
                                            src={InvoiceIcon}
                                            className=""
                                            alt="ParkingAeroPortFr"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-start justify-content-center flex-column"
                                    style={{ width: "70%" }}
                                >
                                    <h3 className="clt_reuseable_invoice_cardnumber">#FA{zeroPad(refund?.id,7)}</h3>
                                    <p className="clt_reuseable_invoice_cardname">Cancel {new Date(refund?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                    {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                    style={{ width: "10%" }}
                                >
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title=""
                                                       className="pfr_dropdown_menu">
                                            <Dropdown.Item
                                                onClick={()=>{handleRefundInvoice(refund?.BookingId, refund)}}
                                                className="clt_reuseable_invoice_cards3dots_options"
                                            >
                                                View the receipt
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>)
                        }
                        {
                            allAdditional && allAdditional?.map((additional,i) => <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                                <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                >
                                    <div className="clt_reuseable_invoice_imgdiv">
                                        <img
                                            src={InvoiceIcon}
                                            className=""
                                            alt="ParkingAeroPortFr"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-start justify-content-center flex-column"
                                    style={{ width: "70%" }}
                                >
                                    <h3 className="clt_reuseable_invoice_cardnumber">#F{zeroPad(additional?.id,8)}</h3>
                                    <p className="clt_reuseable_invoice_cardname">Payment additional item - {new Date(additional?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                    {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                    style={{ width: "10%" }}
                                >
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title=""
                                                       className="pfr_dropdown_menu">
                                            <Dropdown.Item
                                                onClick={()=>{handleAdditionalInvoice(additional?.bookingId,additional?.id)}}
                                                className="clt_reuseable_invoice_cards3dots_options"
                                            >
                                                View the receipt
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>)
                        }
                    </>
                    :
                    <>
                        <div className='clt_admin_dash_invoice_refund_booking_box_field'>

                            
                            {/* Section - Pdf and/or csv ? */}
                            <Row>
                                <Col>
                                    <h2 className="clt_admin_dash_invoice_innerhead">Select document</h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="d-flex flex-row align-items-center justify-content-start clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2">
                                        
                                        <Form className="clt_admin_dash_all_invoice_form ">
                                            <div className="mt-3">
                                                <Form.Check
                                                    inline
                                                    label="Invoice"
                                                    name="Invoice"
                                                    type="checkbox"
                                                    id="ExportInvoice"
                                                    className="mb-3 clt_admin_dash_export_check"
                                                    defaultChecked={true} // Set this to true to make the checkbox initially checked
                                                    onChange={handleInvoiceCheckboxChange} // Handle checkbox changes
                                                />
                                                <Form.Check
                                                    inline
                                                    label="CSV"
                                                    name="CSV"
                                                    type="checkbox"
                                                    id="ExportCSV"
                                                    className="mb-3 clt_admin_dash_export_check"
                                                    defaultChecked={true} // Set this to true to make the checkbox initially checked
                                                    onChange={handleCSVCheckboxChange} // Handle checkbox changes
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>

                            {/* Section - Payment and/or refund ? */}
                            <Row>
                                <Col>
                                    <h2 className="clt_admin_dash_invoice_innerhead">Type</h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="d-flex flex-row align-items-center justify-content-start clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2">
                                        
                                        <Form className="clt_admin_dash_all_invoice_form ">
                                            <div className="mt-3">
                                                <Form.Check
                                                    inline
                                                    label="Payment"
                                                    name="Payment"
                                                    type="checkbox"
                                                    id="ExportPayment"
                                                    className="mb-3 clt_admin_dash_export_check"
                                                    defaultChecked={true} // Set this to true to make the checkbox initially checked
                                                    onChange={handleExportInvoicePayment} // Handle checkbox changes
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Refund"
                                                    name="Refund"
                                                    type="checkbox"
                                                    id="ExportRefund"
                                                    className="mb-3 clt_admin_dash_export_check"
                                                    defaultChecked={true} // Set this to true to make the checkbox initially checked
                                                    onChange={handleExportInvoiceRefund} // Handle checkbox changes
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Additionnal payment"
                                                    name="Additionnal payment"
                                                    type="checkbox"
                                                    id="ExportAdditionnal"
                                                    className="mb-3 clt_admin_dash_export_check"
                                                    defaultChecked={true} // Set this to true to make the checkbox initially checked
                                                    onChange={handleExportInvoiceRefund} // Handle checkbox changes
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>

                            


                            {/* Section - Period */}
                            <Row>
                                <Col>
                                    <h2 className="clt_admin_dash_export_innerhead">Period</h2>
                                </Col>
                            </Row>


                            {/*
                            <Row >
                                <Col>
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-start clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv"
                                        onClick={() => setSelectedExportOption(1)}
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                selectedExportOption === 1
                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    selectedExportOption === 1
                                                        ? {
                                                            background: `#000`,
                                                        }
                                                        : {
                                                            background: `transparent`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                                            <h2>Today</h2>
                                            <h3>12 Dec</h3>
                                        </div>

                                    </div>
                                </Col>
                            </Row>*/}
                            <Row >
                                <Col>
                                    <div
                                        className="d-flex flex-row align-items-start justify-content-start clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv"
                                        onClick={() => setSelectedExportOption(2)}
                                    >
                                        <div
                                            className="inner-div-circle"
                                            style={
                                                selectedExportOption === 2
                                                    ? {
                                                        borderColor: `#000`,
                                                    }
                                                    : {
                                                        borderColor: `#ddd`,
                                                    }
                                            }
                                        >
                                            <div
                                                className="subDiv"
                                                style={
                                                    selectedExportOption === 2
                                                        ? {
                                                            background: `#000`,
                                                        }
                                                        : {
                                                            background: `transparent`,
                                                        }
                                                }
                                            ></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                            <h2>Custom</h2>
                                            {selectedExportOption === 2 && (
                                                <div className="w-100">
                                                    <RangeDatePicker
                                                        startDate={isExportDate.startDate}
                                                        endDate={isExportDate.endDate}
                                                        onChange={(startDate, endDate) => (
                                                            // setIsReserveSelectedTimeSlot(null),
                                                            setisExportDate({
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                            })
                                                        )}
                                                        minDate={null}
                                                        monthFormat="MMM YYYY"
                                                        highlightToday={true}
                                                        startDatePlaceholder="Start Date"
                                                        endDatePlaceholder="End Date"
                                                        disabled={false}
                                                        className=""
                                                        startWeekDay="monday"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="customFooterButton w-100">
                            <hr />
                            <Button
                                onClick={() => handleExportClick()}
                                className="clt_admin_dash_invoice_refund_booking_formButton shadow-none"
                                disabled={selectedExportOption === 0 ? true : false}
                            >
                                <span>Export</span>
                            </Button>
                        </div>
                    </>
                }

            </Offcanvas.Body>
        </Offcanvas >
    );
};

export default AdminDashboardInvoice;
