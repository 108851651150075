import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import icon from "../../../../../../assets/img/menu.png";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    className="clt_admin_dash_manage_access_card3dot"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
      console.log(e);
    }}
  >
    {children}
    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
  </Link>
));

function CategoryList({
  list,
  deleteCat,
  openEditCategory,
  openManageArticles,
  openManagePath,
}) {
  return (
    list &&
    list.length > 0 &&
    list.map((category) => {
      return (
        <div className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_cardsdiv ">
          <div
            className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_account_circle"
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <div className="clt_admin_dash_manage_access_imgdiv">
              <div className="user">
                <img
                  src={icon}
                  alt="icon"
                  style={{
                    height: "12px",
                    width: "12px",
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-start justify-content-center flex-column">
              <h3
                className="clt_admin_dash_manage_access_cardnumber"
                style={{ margin: 0, padding: 0, marginLeft: "15px" }}
              >
                {category.name}
              </h3>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-end clt_admin_dash_manage_access_cards3dots"
            style={{ width: "10%" }}
          >
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                <Dropdown.Item
                  className="clt_admin_dash_manage_access_cards3dots_options"
                  onClick={() => openManageArticles(category)}
                >
                  Manage article
                </Dropdown.Item>
                <Dropdown.Item
                  className="clt_admin_dash_manage_access_cards3dots_options"
                  onClick={() => openManagePath(category)}
                >
                  Manage path
                </Dropdown.Item>
                <Dropdown.Item
                  className="clt_admin_dash_manage_access_cards3dots_options"
                  onClick={() => openEditCategory(category)}
                >
                  Edit category
                </Dropdown.Item>
                <Dropdown.Item
                  className="clt_admin_dash_manage_access_cards3dots_options"
                  onClick={() => deleteCat(category.id)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    })
  );
}

export default CategoryList;
