import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import BackArrow from "../../../../../assets/img/back_arrow.png";
import { IMG_ALT, IMG_BASEURL } from "../../../../../constants";

const ManageName = ({
  isSidebar,
  onCloseSidebar,
  title,
  inputData,
  onUpdateData,
}) => {
  const [inputValue, setInputValue] = useState(inputData.title);
  // const handleInputChange = (val) => {
  //   setInputValue((prevState) => ({
  //     ...prevState,
  //     name: val,
  //   }));
  // };
  const handleSave = () => {
    console.log('inputValue', inputValue, inputData.id)
    onUpdateData({
      id: inputData.id,
      image: inputData.galleryImageUrl,
      title: inputValue,
    });
    setInputValue('');
    onCloseSidebar();
  };

  return (
    <Offcanvas
      placement="end"
      className="clt_payment_offcanvas"
      show={isSidebar}
      onHide={onCloseSidebar}
    >
      <Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onCloseSidebar}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className={`clt_payment_offcanvas_body`}>
        <>
          <Row>
            <Col>
              <div
                className="clt-admin-event-gallery-div clt-admin-height-imgdiv"
                style={{
                  backgroundImage: `url(${IMG_BASEURL}${inputData?.galleryImageUrl})`,
                }}
              ></div>
            </Col>
          </Row>
          <div className="">
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pfr_inputFloatDiv"
              >
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    name="name"
                    value = {inputValue}
                    type="text"
                    placeholder="Name"
                    defaultValue={inputData?.title}
                    onChange={(event) => setInputValue(event.target.value)}
                  />
                  <label htmlFor="floatingInput1">Name</label>
                </Form.Floating>
              </Col>
            </Row>
            <div className="pfr_payment_space_bottom" />
          </div>
          <div className="clt_payment_footer">
            <Button
              className="shadow-none clt_payment_header_button_black"
              disabled={
                inputValue === "" || !inputValue ? true : false
              }
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ManageName;
