// import
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";
import "./admin-dashboard-room.css";
import { IMG_ALT } from "../../../../../constants";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CustomHr from "../../../../reusable/CustomHr";

// import - Image/Icon
import AdminIconDragDrop1 from "../../../../../assets/img/admin_icon_dragdrop_1.png";
import GalleryImage1 from "../../../../../assets/img/detail_slider_1_1.png";
import GalleryImage2 from "../../../../../assets/img/detail_slider_1_2.png";
import GalleryImage3 from "../../../../../assets/img/detail_slider_1_3.png";
import IconPicture from "../../../../../assets/img/icon_picture.png";
import BackArrow from "../../../../../assets/img/back_arrow.png";

// import - Section - Manage name
import ManageName from "./ManageName";
import { useDispatch } from "react-redux";
import { AddRoomGalleryImage, createRoom, deleteGalleryItem, deleteRoom, editRoom, editRoomGallery, EditRoomInfo, EditRoomInfoDrag, getRoom, getRooms, EditEventGalleryOrder } from "../../../../../redux/actions/room";
import { useSelector } from "react-redux";
import { getOptionGroupUnstyledUtilityClass } from "@mui/base";



/* ------------------------------------------------------------ */
/* ------------------------------------------------------------ */



/* Const */

/* Const - Screen - Inital  */
const initialAdminRoomScreens = {
  listRoom: true,
  editRoom: false,
  roomGallery: false,
};

/* Const  - Screen - Manage room*/
const AdminDashboardRoom = ({
  openAdminRoomSidebar,
  onCloseAdminRoomSidebar,
}) => {

  const [adminRoomScreens, setAdminRoomScreens] = useState(
    initialAdminRoomScreens
  );


  /* ------------------------------------------------------------ */
  const dispatch = useDispatch()
  const [isRoomCreate, setIsRoomCreate] = useState();
  const [adminRoomsList, setAdminRoomsList] = useState([]);
  const [imageGalleryPicture, setImageGalleryPicture] = useState(null);
  const [currentEventGallery, setCurrentEventGallery] = useState([]);

  const [myformData, setMYFormData] = useState({
    name: '',
    subTitle: '',
    subTag1Title: '',
    subTag2Title: '',
    subTag3Title: '',
    subTag4Title: '',
    subTag5Title: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
  });

  // Data direct from redux store
  const allRooms = useSelector((state) => state.allRooms.rooms);
  const room = useSelector((state) => state.room.room);

  // get ALL room
  useEffect(() => {
    dispatch(getRooms());
  }, [isRoomCreate]);

  //set All rooms to new state
  useEffect(() => {
    if (allRooms?.length || allRooms?.length === 0) {
      setAdminRoomsList(allRooms);
    }
  }, [allRooms]);

  useEffect(() => {
    if (room) {
      setCurrentEventGallery(room.galleries);
    }
  }, [room])

  /* Const - Screen 1 - Manage room - List */

  /* Const - List */
  const [adminRoomList, setAdminRoomList] = useState([
    {
      id: uuid(),
      room: "Room 1",
      img: GalleryImage1,
    },
    {
      id: uuid(),
      room: "Room 2",
      img: GalleryImage2,
    },
  ]);

  /* Const - List - Add room */
  const handleAddRoom = async () => {
    const data = {
      name: `Room ${allRooms.length + 1}`,
      order: allRooms.length
    };
    const roomName = JSON.stringify(data);
    const response = await dispatch(createRoom(roomName));
    if (response.error) {
      alert('error while adding room')
    } else {
      dispatch(getRooms());
    }
  };

  //handle input change for room edit form
  const handleInputChange = (e, section, field) => {
    const updatedFormData = { ...myformData };
    if (section === 'image1' || section === 'image2' || section === 'image3' || section === 'image4' || section === 'image5') {
      updatedFormData[section] = e.target.files[0];
    }
    else {
      updatedFormData[section] = e.target.value;
    }
    setMYFormData(updatedFormData);
  };

  //Api call for edit room request
  const handleEditRoom = async () => {
    console.log('myformData is', myformData)
    const formData = new FormData();
    formData.append('name', myformData.name);
    formData.append('subTitle', myformData.subTitle);
    formData.append('subTag1Title', myformData.subTag1Title);
    formData.append('subTag2Title', myformData.subTag2Title);
    formData.append('subTag3Title', myformData.subTag3Title);
    formData.append('subTag4Title', myformData.subTag4Title);
    formData.append('subTag5Title', myformData.subTag5Title);
    formData.append('image1', myformData.image1);
    formData.append('image2', myformData.image2);
    formData.append('image3', myformData.image3);
    formData.append('image4', myformData.image4);
    formData.append('image5', myformData.image5);
    const roomId = selectedRoom.id
    const response = await dispatch(EditRoomInfo(roomId, formData));
    console.log('This is form data', formData);
    if (response.error) {
      alert('error Editing room')
    } else {
      dispatch(getRooms());
      handleBack("listRoom", "editRoom")
    }
  }

  // Set gallery iamge
  const handleImageChange = (e) => {
    const myImage = e.target.files[0];
    console.log('myImage is', myImage)
    setImageGalleryPicture(myImage);
    const tmp = { id: myImage.lastModified, ImageUrl: URL.createObjectURL(myImage), local: true }
    setCurrentEventGallery([...currentEventGallery, tmp])
  };

  // upload gallery image
  const handleImageUpload = async () => {
    const order = currentEventGallery?.length
    console.log('order is', order)
    const formData = new FormData();
    const title = "";
    formData.append("image", imageGalleryPicture);
    formData.append("name", title);
    formData.append("order", order);
    const response = await dispatch(
      AddRoomGalleryImage(selectedRoom.id, formData)
    );
    if (response.error) {
      setImageGalleryPicture(null);
      console.error("Image upload error:", response.message);
    } else {
      handleBack("roomGallery", "editRoom")
      setImageGalleryPicture(null);
      dispatch(getRoom(selectedRoom.id));
    }
  };

  // Delete the gallery
  const handleGalleryDelete = async (id, local) => {
    if (local) {
      const tmp = currentEventGallery.filter(item => item.id !== id)
      setCurrentEventGallery(tmp)
      setImageGalleryPicture(null)
      return
    }

    const response = await dispatch(deleteGalleryItem(id));
    if (response.error) {
      console.error("Gallery delete error:", response.message);
    } else {
      dispatch(getRoom(selectedRoom.id));
    }
  };


  // TODO: Delete a room
  const handleDelete = async (id) => {
    const response = await dispatch(deleteRoom(id));
    if (response.error) {
    } else {
      dispatch(getRooms());
    }
  };

  /* Const - List - Drag & Drop position */
  const handleRoomClick = (data) => {
    setSelectedRoom(data);
    setMYFormData(data)
    dispatch(getRoom(data.id));
    setAdminRoomScreens((prevState) => ({
      ...prevState,
      editRoom: true,
      listRoom: false,
    }));
  };

  /* Const - List - Threedot */
  const CustomToggleImage = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
    </Link>
  ));

  /* ------------------------------------------------------------ */

  /* Const - Screen 2 - Manage room - Edit */

  /* Const - Edit - Room name */
  const [selectedRoom, setSelectedRoom] = useState(null);

  /* Const - Edit - Upload icon */
  const [uploadImage1, setUploadImage1] = useState(null);
  const [uploadImage2, setUploadImage2] = useState(null);

  /* Const - Edit - Drag & Drop */
  const onDragEndEvent = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const draggedItemId = adminRoomsList[source.index].id;
    const copiedItems = [...adminRoomsList];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    copiedItems.forEach((eventData, index) => {
      eventData.order = index;
    });
    copiedItems.map((eventData, index) => {
      const data = { order: index };
      const updatedEvent = JSON.stringify(data);
      dispatch(EditRoomInfoDrag(eventData.id, updatedEvent));
    });
    const updatedAdminEventList = copiedItems.map((eventData, index) => {
      return { ...eventData, order: index };
    });
    setAdminRoomsList(updatedAdminEventList);
  };

  /* ------------------------------------------------------------ */

  /* Const - Screen 3 - Manage room - Gallery */

  /* Const - Gallery */
  const [uploadedGallery, setUploadedGallery] = useState([
    {
      id: uuid(),
      image: GalleryImage1,
      name: "",
    },
    {
      id: uuid(),
      image: GalleryImage2,
      name: "",
    },
    {
      id: uuid(),
      image: GalleryImage3,
      name: "",
    },
  ]);

  /* Const - Gallery - Picture Name */
  const [isManageNameSidebar, setIsManageNameSidebar] = useState(false);
  const [selectedGalleryItem, setSelectedGalleryItem] = useState({});

  /* Const - Gallery - Upload image */
  const [uploadRoomImage, setUploadRoomImage] = useState(null);

  /* Const - Gallery - Drag & Drop*/

  const onDragEndGallery = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...currentEventGallery];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    copiedItems.forEach((eventGallery, index) => {
      eventGallery.order = index;
    });
    copiedItems.map(async (galleryData, index) => {
      const data = { order: index };
      const updatedOrder = JSON.stringify(data);
      const result = await dispatch(EditEventGalleryOrder(selectedRoom, galleryData.id, updatedOrder));
    });
    const updatedEventGallery = copiedItems.map((galleryData, index) => {
      return { ...galleryData, order: index };
    });
    setCurrentEventGallery(updatedEventGallery)
    dispatch(getRooms());
  };


  /* Const - Gallery - List - Threedot */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));



  /* ------------------------------------------------------------ */

  /* Const - Component */

  /* Const - Component - Button back */
  const handleBack = (from, to) => {
    setAdminRoomScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };

  /* Const - Component - Button close */
  const handleClose = () => {
    setAdminRoomScreens(initialAdminRoomScreens);
    setSelectedRoom(null);
    setUploadRoomImage(null);
    onCloseAdminRoomSidebar();
  };





  useEffect(() => { }, [adminRoomScreens]);




  /* ------------------------------------------------------------------------------------------------------------------------------- */

  /* Screen 1 : Manage room - List */
  /* Screen 2 : Manage room - Edit */
  /* Screen 3 : Manage room - Gallery */

  /* ------------------------------------------------------------------------------------------------------------------------------- */




  /* Screen */
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_products_offcanvas"
        show={openAdminRoomSidebar}
        onHide={
          adminRoomScreens.confirmBooking
            ? handleClose
            : onCloseAdminRoomSidebar
        }
      >

        {/* Screen - Header */}
        <Offcanvas.Header
          className={`justify-content-start clt_products_offcanvas_header_two`}
        >


          {/* Screen 1 : Manage room - List - Header */}
          {adminRoomScreens.listRoom && (
            <React.Fragment>

              {/* Close */}
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseAdminRoomSidebar}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>

              <div className="d-flex align-items-center justify-content-between w-100">

                {/* Title */}
                <Offcanvas.Title>Room</Offcanvas.Title>

                {/* Add */}
                <Button
                  className="clt_header_add_button shadow-none"
                  onClick={handleAddRoom}
                >
                  Add
                </Button>
              </div>
            </React.Fragment>
          )}

          {/* ------------------------------------------- */}


          {/* Screen 2 : Manage room - Edit- Header */}
          {adminRoomScreens.editRoom && (
            <React.Fragment>

              {/* Close */}
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => handleBack("listRoom", "editRoom")}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>

              {/* Title */}
              <Offcanvas.Title>Edit Room</Offcanvas.Title>
            </React.Fragment>
          )}


          {/* ------------------------------------------- */}


          {/* Screen 3 : Manage room - Gallery - Header */}
          {adminRoomScreens.roomGallery && (
            <React.Fragment>

              {/* Close */}
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => handleBack("editRoom", "roomGallery")}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>

              {/* Title */}
              <Offcanvas.Title>Gallery</Offcanvas.Title>
            </React.Fragment>
          )}
        </Offcanvas.Header>




        {/* ------------------------------------------- */}
        {/* ------------------------------------------- */}
        {/* ------------------------------------------- */}


        {/* Screen - Body */}
        <Offcanvas.Body className={`clt_products2_offcanvas_body`}>

          {/* Screen 1 : Manage room - List - Body */}
          {adminRoomScreens.listRoom && (
            <>
              <Row className="clt-detail-section-6">
                <Col className="clt-admin-event-s1">
                  <Row className="clt-detail-section-6-header">
                    <Col>
                      <h2>Room</h2>
                    </Col>
                  </Row>

                  {/* Screen - Manage room - List */}
                  <Row className="clt-admin-event-list-div-body m-0">
                    <Col>

                      {/* List - Drag & Drop - Context */}
                      <DragDropContext
                        // onDragEnd={(result) => onDragEndEvent(result)}
                        onDragEnd={onDragEndEvent}
                      >

                        {/* List - Drag & Drop - Droppable */}
                        <Droppable droppableId="events">
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {adminRoomsList?.sort((a, b) => a.order - b.order).map((eventData, index) => {
                                  return (

                                    /* List - Drag & Drop - Draggable */
                                    <Draggable
                                      key={eventData.id}
                                      draggableId={eventData.id.toString()}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <Row
                                            key={index}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              userSelect: "none",
                                              backgroundColor:
                                                snapshot.isDragging
                                                  ? "#fff"
                                                  : "transparent",
                                              border: snapshot.isDragging
                                                ? "1px solid #dedddd"
                                                : "none",
                                              borderRadius: snapshot.isDragging
                                                ? "10px"
                                                : "0",
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            <Col
                                              className="clt-admin-event-list-div"
                                              style={{
                                                borderBottom:
                                                  snapshot.isDragging && "none",
                                              }}
                                            >
                                              <div className="d-flex align-items-center w-100">
                                                <img
                                                  src={AdminIconDragDrop1}
                                                  alt={IMG_ALT}
                                                  className="img-dragdrop"
                                                />
                                                <span className="dragdrop-counter">
                                                  {index + 1}
                                                </span>
                                                <div
                                                  className="inner-icon-title-div"
                                                  onClick={() =>
                                                    handleRoomClick(eventData)
                                                  }
                                                >
                                                  <div
                                                    className="clt-admin-room-div-img"
                                                    style={{
                                                      backgroundImage: `url(${eventData?.roomgalleries[0]?.ImageUrl})`,
                                                    }}
                                                  />
                                                  <div className="d-flex flex-column align-items-start justify-content-center">
                                                    <h2>{eventData?.name}</h2>
                                                  </div>
                                                </div>
                                                <div
                                                  className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                                  style={{
                                                    width: "10%",
                                                  }}
                                                >

                                                  {/* List - Threedot */}
                                                  <Dropdown>
                                                    <Dropdown.Toggle
                                                      as={CustomToggle}
                                                    />
                                                    <Dropdown.Menu
                                                      size="sm"
                                                      title=""
                                                      className="pfr_dropdown_menu"
                                                    >
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleRoomClick(
                                                            eventData
                                                          )
                                                        }
                                                        className="pfr_payment_cards3dots_options"
                                                      >
                                                        Edit Room
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleDelete(
                                                            eventData.id
                                                          )
                                                        }
                                                        className="pfr_payment_cards3dots_options"
                                                      >
                                                        Delete
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}


          {/* ------------------------------------------- */}


          {/* Screen 2 : Manage room - Edit - Body */}
          {adminRoomScreens.editRoom && (
            <>
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Room</h2>
                </Col>
              </Row>

              {/* Section - room.title */}
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name2 mt-3">TITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.name}
                        onChange={(e) => handleInputChange(e, 'name', null)}
                      />
                      <label htmlFor="floatingInput1">Title</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>

              {/* Section - room.gallery */}
              <Row>
                <Col className="clt-booking-cp-div4">
                  <p class="clt_admin_dash_menu_container_name mt-3">GALLERY</p>
                  <div
                    className="d-flex justify-content-between align-items-center clt-admin-room-button-gallery"
                    onClick={() => handleBack("roomGallery", "editRoom")}
                  >
                    <div className="d-flex flex-row justify-content-start align-items-center clt-admin-room-button-gallery-text">
                      <img
                        src={IconPicture}
                        alt={IMG_ALT}
                        className="clt-admin-gallery-icon"
                      />
                      <h2>Gallery</h2>
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </Col>
              </Row>

              {/* Section - room.subTitle */}
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name mt-3">SUBTITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.subTitle}
                        onChange={(e) => handleInputChange(e, 'subTitle', null)}
                      />
                      <label htmlFor="floatingInput1">Subtitle</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />

              {/* Section - Tag */}
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Subtag 1</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name2 mt-3">TITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.subTag1Title}
                        onChange={(e) => handleInputChange(e, 'subTag1Title', null)}
                      />
                      <label htmlFor="floatingInput1">Title</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
                  <div className="clt-admin-event-fileinput-div">
                    <i className="fa-solid fa-plus"></i>
                    <span>{myformData?.image1 ? myformData?.image1?.name : "Add an file"}</span>
                    <div className="opacity-0 div-hidden">
                      <input
                        type="file"
                        onChange={(e) => handleInputChange(e, 'image1', null)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Subtag 2</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name2 mt-3">TITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.subTag2Title}
                        onChange={(e) => handleInputChange(e, 'subTag2Title', null)}
                      />
                      <label htmlFor="floatingInput1">Title</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
                  <div className="clt-admin-event-fileinput-div">
                    <i className="fa-solid fa-plus"></i>
                    <span>{myformData?.image2 ? myformData?.image2?.name : "Add an file"}</span>
                    <div className="opacity-0 div-hidden">
                      <input
                        type="file"
                        onChange={(e) => handleInputChange(e, 'image2', null)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Subtag 3</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name2 mt-3">TITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.subTag3Title}
                        onChange={(e) => handleInputChange(e, 'subTag3Title', null)}
                      />
                      <label htmlFor="floatingInput1">Title</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
                  <div className="clt-admin-event-fileinput-div">
                    <i className="fa-solid fa-plus"></i>
                    <span>{myformData?.image3 ? myformData?.image3?.name : "Add an file"}</span>
                    <div className="opacity-0 div-hidden">
                      <input
                        type="file"
                        onChange={(e) => handleInputChange(e, 'image3', null)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Subtag 4</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name2 mt-3">TITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.subTag4Title}
                        onChange={(e) => handleInputChange(e, 'subTag4Title', null)}
                      />
                      <label htmlFor="floatingInput1">Title</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
                  <div className="clt-admin-event-fileinput-div">
                    <i className="fa-solid fa-plus"></i>
                    <span>{myformData?.image4 ? myformData?.image4?.name : "Add an file"}</span>
                    <div className="opacity-0 div-hidden">
                      <input
                        type="file"
                        onChange={(e) => handleInputChange(e, 'image4', null)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Subtag 5</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name2 mt-3">TITLE</p>
                  <Col className="clt-admin-event-info-input-div">
                    <Form.Floating className="pfr_inputFloat">
                      <Form.Control
                        id="floatingInput1"
                        type="text"
                        placeholder="name"
                        value={myformData?.subTag5Title}
                        onChange={(e) => handleInputChange(e, 'subTag5Title', null)}
                      />
                      <label htmlFor="floatingInput1">Title</label>
                    </Form.Floating>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
                  <div className="clt-admin-event-fileinput-div">
                    <i className="fa-solid fa-plus"></i>
                    <span>{myformData?.image5 ? myformData?.image5?.name : "Add an file"}</span>
                    <div className="opacity-0 div-hidden">
                      <input
                        type="file"
                        onChange={(e) => handleInputChange(e, 'image5', null)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="pfr_payment_space_bottom" />
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none"
                  // onClick={() => handleBack("listRoom", "editRoom")}
                  onClick={handleEditRoom}
                >
                  Save
                </Button>
              </div>
            </>
          )}


          {/* ------------------------------------------- */}


          {/* Screen 3 : Manage room - Gallery - Body */}
          {adminRoomScreens.roomGallery && (
            <>
              <Row className="clt-detail-section-6-header">
                <Col>
                  <h2>Gallery</h2>
                </Col>
              </Row>

              {/* Gallery - Drag & Drop - Context */}
              <DragDropContext onDragEnd={onDragEndGallery}>

                {/* Gallery - Drag & Drop - Droppable */}
                <Droppable droppableId="gallery">
                  {(provided, snapshot) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Row className="mt-4">

                          {/* Gallery - Upload icon */}
                          <Col lg={6} xs={6}>
                            <div className="clt-admin-event-fileinput-div clt-admin-height-imgdiv clt-admin-event-gallery-file-upload">
                              <i className="fa-solid fa-plus"></i>
                              <span>
                                {imageGalleryPicture
                                  ? imageGalleryPicture.name  //"Add an icon"
                                  : "Add an icon"}
                              </span>
                              <div className="opacity-0 div-hidden">
                                <input
                                  type="file"
                                  // onChange={(e) =>
                                  //   setUploadRoomImage(e.target.files[0].name)
                                  // }
                                  onChange={(e) => handleImageChange(e)}
                                />
                              </div>
                            </div>
                          </Col>

                          {/* Gallery - Upload */}
                          {currentEventGallery?.sort((a, b) => a.order - b.order).map((gallery, index) => {
                            return (

                              /* Gallery - Drag & Drop - Draggable */
                              <Draggable
                                key={gallery.id + index}
                                draggableId={gallery.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Col
                                      lg={6}
                                      xs={6}
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        className="clt-admin-event-gallery-div clt-admin-height-imgdiv"
                                        style={{
                                          backgroundImage: `url(${gallery?.ImageUrl})`,
                                        }}
                                      >
                                        <span>{index + 1}</span>

                                        {/* Gallery - Threedot - Dropdown */}
                                        <div
                                          className="d-flex align-items-center justify-content-end pfr_payment_cards3dots clt-admin-cards3dots"
                                          style={{ width: "10%" }}
                                        >
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              as={CustomToggleImage}
                                            />
                                            <Dropdown.Menu
                                              size="sm"
                                              title=""
                                              className="pfr_dropdown_menu"
                                            >
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setSelectedGalleryItem(
                                                    gallery
                                                  );
                                                  setIsManageNameSidebar(true);
                                                }}
                                                className="pfr_payment_cards3dots_options"
                                              >
                                                Manage name
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleGalleryDelete(
                                                    gallery.id, gallery.local
                                                  )
                                                }
                                                className="pfr_payment_cards3dots_options"
                                              >
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Row>
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>

              <div className="pfr_payment_space_bottom" />


              {/* Button - Save */}
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none"
                  // onClick={() => handleBack("editRoom", "roomGallery")}
                  onClick={() => { imageGalleryPicture?.name && handleImageUpload() }}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {/* Function - Screen 1 : Manage room - List */}


      {/* Function - Screen 2 : Manage room - Edit */}


      {/* Function - Screen 3 : Manage room - Gallery */}

      {/* Function - Manage name */}
      {selectedGalleryItem && (
        <ManageName
          name={"Manage name"}
          inputData={selectedGalleryItem}
          isSidebar={isManageNameSidebar}
          onCloseSidebar={() => {
            setSelectedGalleryItem({});
            setIsManageNameSidebar(false);
          }}
          onUpdateData={(data) => {
            const mydata = { name: data.name };
            const updateData = JSON.stringify(mydata);
            dispatch(editRoomGallery(selectedRoom, data.id, updateData));
            dispatch(getRoom(selectedRoom?.id));
          }}
        />
      )}
    </>
  );
};

export default AdminDashboardRoom;
