import { identity } from "lodash";
import { API_ENDPOINT } from "../../constants";
import { actionTypes } from "../constants/action-type";
import { auth } from "../../components/screens/auth/firebase";

async function getAuthObject(){
  const token = await auth.currentUser.getIdToken();

  return { Authorization: `Bearer ${token}` }
}

export const createEvents = (data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
        try {
          const response = await fetch(`${API_ENDPOINT}/events`, {
              method: 'POST',
              headers: {
                  'Content-type': 'application/json',
                  'Accept': 'application/json',
                  ...( await getAuthObject()),
                },
              body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const getEvents = () => {
  return async function (dispatch) {
      try {
          const response = await fetch(`${API_ENDPOINT}/events/all`, {
              method: 'GET',
              headers: {
                  'Content-type': 'application/json',
                  'Accept': 'application/json',
                },
          });
          const result = await response.json()
          if (response.status >= 200 && response.status < 400) {
              dispatch({ type: actionTypes.ALLEVENTS, payload: result.events })
          }
          else {
              dispatch({ type: actionTypes.ALLEVENTSERR, payload:  result})
          }
        } catch (err) {
            console.log('error')
        }
  }
}

export const deleteEvents = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}`, {
          method: 'DELETE',
          headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              ...( await getAuthObject())
            },
      });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const EditEvent = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/update`, {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                ...( await getAuthObject())
              },
            body: (data)
       });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const EditEventInfo = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/update`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                ...( await getAuthObject())
              },
            body: (data)
       });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const AddEventGalleryImage = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/eventgallary`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while uploading the image.' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while uploading the image.' });
      }
    });
  }
}


export const getEvent = (id) => {
  return async function (dispatch) {
      try {
          const response = await fetch(`${API_ENDPOINT}/events/${id}`, {
              method: 'GET',
              headers: {
                  'Content-type': 'application/json',
                  'Accept': 'application/json',
                },
          });
          const result = await response.json()
          if (response.status >= 200 && response.status < 400) {
            dispatch({ type: actionTypes.EVENT, payload: result })
           }
          else {
            dispatch({ type: actionTypes.EVENTERR, payload:  result})
          }
        } catch (err) {
            console.log('error')
        }
  }
}

export const editEvent = (id, data) => {
  return async function (dispatch) {
      try {
          const response = await fetch(`${API_ENDPOINT}/events/eventgalleries/${id}/update`, {
              method: 'PATCH',
              headers: {
                  'Content-type': 'application/json',
                  'Accept': 'application/json',
                  ...( await getAuthObject())

                },
              body: (data)
          });
          const result = await response.json()
        } catch (err) {
            console.log('error')
        }
  }
}

export const EditEventGalleryOrder = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/eventgalleries/${id}/update`, {
          method: 'PATCH',
          headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              ...( await getAuthObject())

            },
          body: (data)
      });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const deleteGalleryItem = (id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/eventgalleries/${id}`, {
          method: 'DELETE',
          headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              ...( await getAuthObject())

            },
      });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const createSlider = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/eventsliders`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const createQuestions = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/eventquestions`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const setdetailsSection3 = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/section3`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const createCategory = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/categories`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const editCategory = (eventId,id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${eventId}/categories/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const deleteCategory = (eventId,id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${eventId}/categories/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const createAmenity = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/categories/${id}/amenities`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const editAmenity = (categoryID,id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${categoryID}/categories/${id}/amenities`, {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const editAmenityOrder = (categoryID,id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${categoryID}/categories/${id}/amenities`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const deleteAmenity = (categoryID,id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${categoryID}/categories/${id}/amenities`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const createHomeSec4 = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/section4`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const createDetailSec4 = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/${id}/section4`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const createDetails2 = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/${id}/section2`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const createDetails6 = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/${id}/update`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const createDetails6Category = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/${id}/detailcategory`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const editDetails6Category = (eventId,id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/${eventId}/detailcategory/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const deleteDetails6Category = (eventId,id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/${eventId}/detailcategory/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}


export const createRules = (id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/detailcategory/${id}/rules`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const editRules = (categoryID,id, data) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/detailcategory/${categoryID}/rules/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const deleteRules = (categoryID,id) => {
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/events/details/detailcategory/rules/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())

          },
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}

export const damageCharge = (data) =>{
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking/damage-charge`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}
export const refundCharge = (data) =>{
  return async function (dispatch) {
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(`${API_ENDPOINT}/booking/refund-charge`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            ...( await getAuthObject())
          },
          body: (data)
        });
        const result = await response.json()
        if (response.status >= 200 && response.status < 400){
          resolve(result);
        }
        else {
          resolve({ error: true, message: 'An error occurred while creating Questions' });
        }
      } catch (err) {
        console.log('error')
        resolve({ error: true, message: 'An error occurred while creating Questions' });
      }
    });
  }
}
