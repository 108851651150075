import React, { useContext, useState } from "react";
import { Col, Row, Button, Form, } from "react-bootstrap";
import "../../auth-modal.css";
import { IMG_ALT } from "../../../../../constants";
import { AuthContext, useAuth } from "../../authContext";
import { ErrorToast } from "../../../../reusable/Toast";
import { ToastContainer } from "react-toastify";

const PasswordSigin = ({ handleContinue, goToForgotPass = null, authenticationFlow }) => {
	const { email } = useAuth();

	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [data, setData] = useState({
		email: authenticationFlow ? authenticationFlow.email : email,
		password: "",
	});

	const togglePasswordVisibility = () => {
		setPasswordVisibility((v) => !v);
	};

	const handleChange = (e) => {
		setData((d) => ({ ...d, [e.target.name]: e.target.value }));
	};


	const handleClick = () => {
		if (data.email === "" || data.password === "") {
			ErrorToast("Information incorrect", "Les informations saisies ne sont pas correctes.");
			return;
		}

		// 

		handleContinue(data.email, data.password);
	};




	return (
		<>
			<div className="pfr_loginModal_Body">
				<Row>
					<Col>
						<p className="pfr_loginModalH2">Tapez votre mot de passe</p>
					</Col>
				</Row>
			</div>
			<div className="pfr_loginModalPassword">
				<div className="pfr_loginModalPasswordDiv pfr_loginModal_Body">
					<Row>
						<Col className="pfr_loginModalEmailDiv">
							<Row>
								<Col className="pfr_inputFloatDiv">
									<Form.Floating className="pfr_inputFloat">
										<Form.Control
											id="floatingInput1"
											type="email"
											placeholder="Adresse e-mail"
											disabled={authenticationFlow && authenticationFlow.userId}
											value={data.email}
											onChange={handleChange}
											name="email"
										/>
										<label htmlFor="floatingInput1">Adresse e-mail</label>
									</Form.Floating>
								</Col>
							</Row>
							<Row>
								<Col className="pfr_inputFloatDiv">
									<Form.Floating className="pfr_inputFloat">
										<Form.Control
											id="floatingInput2"
											type={!passwordVisibility ? "password" : "text"}
											placeholder="Mot de passe"
											onChange={handleChange}
											name="password"
											value={data.password}
										/>
										<label htmlFor="floatingInput2">Mot de passe</label>
										<img
											src={
												require(`../../../../../assets/img/${passwordVisibility
													? "icon_pass_hide.png"
													: "icon_pass_show.png"
													}`)
											}
											className="pfr_loginShowPass"
											onClick={togglePasswordVisibility}
											alt={IMG_ALT}
										/>
									</Form.Floating>
								</Col>
							</Row>
							{goToForgotPass && <Row>
								<Col>
									<div className="d-flex justify-content-start pfr_loginForgotPassDiv">
										<span onClick={goToForgotPass}>Mot de passe oublié</span>
									</div>
								</Col>
							</Row>}
						</Col>
					</Row>
				</div>
				<div className="pfr_loginModalPasswordDiv">
					<Row className="pfr_loginModalSeparatorDiv">
						<div className="pfr_loginModalSeparator" />
					</Row>
					<div className="pfr_loginModal_footer">
						<Col>
							<Button
								className="pfr_loginModalBtn shadow-none"
								onClick={handleClick}
							>
								Continuer
							</Button>
						</Col>
					</div>
				</div>
			</div>
			<ToastContainer className="toast-container" />
		</>
	);
};

export default PasswordSigin;
