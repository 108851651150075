import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./home.css";
import VideoDesktop from "../../../assets/vid/home-background3.mp4";
import homesection1 from "../../../assets/img/home-section1.jpg";
import homesection2 from "../../../assets/img/home-section2.png";
import homesection21 from "../../../assets/img/home-section2.1.png";
import homesection22 from "../../../assets/img/home-section2.2.png";
import homesection3 from "../../../assets/img/home-section3.png";
import homesection31 from "../../../assets/img/home-section3.1.png";
import homesection32 from "../../../assets/img/home-section3.2.png";
import VideoDesktop2 from "../../../assets/vid/home-background2.1.mp4";
import VideoMobile from "../../../assets/vid/home-background.mp4";
import SearchIcon from "../../../assets/img/home_searchbar_icon.png";
import drawer_m1 from "../../../assets/img/drawer_m1.png";
import CustomHr from "../../reusable/CustomHr";
import clt_gallery3 from "../../../assets/img/clt_gallery3.png";
import DetailSection7ImageMobile from "../../../assets/img/detail_section_7_img_mobile.jpg";
import DetailSection7ImageDesktop from "../../../assets/img/detail_section_77_img_desktop.jpg";
import clt_gallery21 from "../../../assets/img/clt_gallery21.jpg";
import PreviousIcon from "../../../assets/img/icon-previous-slider.png";
import NextIcon from "../../../assets/img/icon-next-slider.png";
import PauseIcon from "../../../assets/img/icon-pause-slider.png";
import PlayIcon from "../../../assets/img/icon-play-slider.png";


import DetailSection77 from "../detail/sections/DetailSection77";

import logo3 from "../../../assets/img/logo3.png";
import HomeWedding from "./home_event_wedding";
import {
  eventbirthdayData,
  IMG_ALT,
  eventweddingData,
  eventprofessionalData,
  eventreligiousData,
} from "../../../constants";
import EventsNavbar from "../../reusable/EventsNavbar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getEvent, getEvents } from "../../../redux/actions/avent";
import NewSection from "../../reusable/NewSection";
import NewSection2 from "../../reusable/NewSection2";
import NewSection3 from "../../reusable/NewSection3";
import { IMG_BASEURL } from "../../../constants";
import { Helmet } from "react-helmet";
import Typewriter from 'typewriter-effect';
import { AuthContext } from "../auth/authContext";
const HomePage = ({ setIsOpen }) => {
  const history = useHistory();
  const [stickyBarTop, setstickyBarTop] = useState(undefined);
  const [eventType, setEventType] = useState(eventweddingData);
  const dispatch = useDispatch();
  const allEvents = useSelector(state => state.allEvents.events);
  const event = useSelector(state => state.event.event)
  const [myCurrentEvent, setMyCurrentEvent] = useState({})
  const [myAllEvents, setMyAllEvents] = useState([])

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [homesection3, homesection31, homesection32];
  const imagesmobile = [homesection2, homesection21, homesection22]
  const [isStick, setIsStick] = useState(false);
  const [sliderInterval, setSliderInterval] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPlaying) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }
    }, 8000);

    setSliderInterval(intervalId);

    return () => clearInterval(intervalId);

  }, [isPlaying]);

  const { setNewNotification } = useContext(AuthContext);

  const newNotificationCount = parseInt(localStorage.getItem('newNotificationCount'));

  useEffect(() => {
    dispatch(getEvents());
    if (newNotificationCount > 0) {
      setNewNotification(true);
    }
  }, [])

  useEffect(() => {
    setMyAllEvents(allEvents)
    const sortedEvents = allEvents?.sort((a, b) => a.eventType - b.eventType);
    let firstEvent
    if (sortedEvents) {
      firstEvent = sortedEvents[0];
    }
    if (firstEvent) {
      dispatch(getEvent(firstEvent.id));
    }
  }, [allEvents]);

  useEffect(() => {
    if (event) {
      setMyCurrentEvent(event);
    }
  }, [event]);

  useEffect(() => {
    const stickyBarEl = document?.querySelector(".stickyBar")?.getBoundingClientRect();
    setstickyBarTop(stickyBarEl?.top);
  }, []);

  useEffect(() => {
    if (!stickyBarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyBarTop]);

  const isSticky = (e) => {
    const stickyBarEl = document.querySelector(".stickyBar");
    const scrollTop = window.scrollY;
    if (scrollTop >= stickyBarTop - 10) {
      stickyBarEl?.classList.add("A");
      setIsStick(true);
    } else {
      stickyBarEl?.classList.remove("A");
      setIsStick(false);
    }
  };
  const section4 = myCurrentEvent?.dataValues?.section4
    ? JSON.parse(myCurrentEvent.dataValues.section4)
    : null;

  // Find all elements with the 'data-notranslate' attribute
  const noTranslateSections = document.querySelectorAll('[data-notranslate]');

  // Loop through the elements and set the 'notranslate' attribute
  noTranslateSections.forEach((element) => {
    element.setAttribute('class', 'notranslate');
  });

  // You can also apply the 'notranslate' class to individual elements instead of the entire section
  // For example, if you want to prevent translation for specific headings, you can do the following:

  const noTranslateHeadings = document.querySelectorAll('.no-translate-heading');

  noTranslateHeadings.forEach((heading) => {
    heading.classList.add('notranslate');
  });

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  }

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Château Latournelle : Lieu de réception | Site officiel</title>
        <meta name="title" content="Château Latournelle: Lieu de réception" />
        <meta name="description" content="Bienvenue au Château Latournelle, un lieu de réception à 20 minutes de Toulouse permettant d'accueillir jusqu'à 160 personnes. Organisez votre événement et faites-en une expérience inoubliable." />
        <link rel="icon" href={logo3} />
        <meta property="og:title" content="Château Latournelle: Lieu de réception" />
        <meta property="og:description" content="Bienvenue au Château Latournelle, un lieu de réception à 20 minutes de Toulouse permettant d'accueillir jusqu'à 160 personnes. Organisez votre événement et faites-en une expérience inoubliable" />
        <meta property="og:image" content="https://chateaulatournelle.com/static/media/home-section1.c2cb4c08b28a998f5f16.jpg" />
        <meta property="og:url" content="https://chateaulatournelle.com" />
        <meta name="keywords" content="Château Latournelle, Toulouse, Mariage, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire" />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "mainEntity": {
              "@type": "ItemList",
              "itemListElement": {
                "@type": "WebPage",
                "name": "Château Latournelle: Lieu de réception",
                "description": "Le Château Latournelle est un lieu de réception à 20 minutes de Toulouse permettant d'accueillir jusqu'à 160 personnes. Organisez votre événement et faites-en une expérience inoubliable.",
                "image": "../../../assets/img/clt_gallery1.png",
                "url": "https://chateaulatournelle.com"
              }
            }
          }`}
        </script>
      </Helmet>
      <Container className="clt-homepage-container3">
        <Row>
        <Col className="clt-section-row-col-pd hideMobile" style={{ flexDirection: 'row', display: 'flex', position: 'relative' }}>
        <div className="Animation-Abdazaz clt-section-1-div d-flex flex-column align-items-start justify-content-start">
            <img
                src={images[currentImageIndex]}
                className="hideMobile image-transition"
                alt="Desktop Image"
                style={{ width: '100%', height: 'auto', position: 'absolute', zIndex: 1 }}
            />
                <div className="clt-section-1-div-inner" style={{ width: '100%', position: 'relative', zIndex: 2 }}>
                    <div>
                        <h2 className="clt-section-1-h2" >Château Latournelle</h2>
                        <h2 className="clt-section-1-h2-1 notranslate" style={{ flexDirection: 'column', display: 'flex', gap: '4px', }}>
                            Découvrez un château
                            <span style={{ display: 'inline-block' }}>
                                <Typewriter
                                    options={{
                                        autoStart: true,
                                        loop: true,
                                        delay: 75,
                                        pauseFor: 4000,
                                        strings: [
                                            ' à 20 minutes de Toulouse',
                                            ' pour un mariage',
                                            ' pour un séminaire',
                                            ' pour un anniversaire',
                                            ' à 20 minutes de Toulouse',
                                            ' pour un baptème',
                                            ' pour une convention',
                                            ' pour un mariage',
                                            ' à 20 minutes de Toulouse',
                                            ' pour une soirée',
                                            ' pour une cousinade',
                                            ' à 20 minutes de Toulouse',
                                            ' pour un événement professionnel',
                                        ],
                                    }}
                                />
                            </span>
                        </h2>
                        <Button
                            onClick={() => history.push({ pathname: `/detail/${myCurrentEvent?.dataValues?.title}`, state: { param1: myCurrentEvent } })}
                            className="clt-section-1-btn shadow-none"
                        >
                            <span>Découvrir</span>
                        </Button>
                        <div className="slider-controls" style={{ position: 'absolute', bottom: 0, right: 0,}}>
                            <img src={PreviousIcon} alt="Previous" onClick={handlePrevImage} style={{ cursor: 'pointer' }} />
                            <span style={{ color: '#fff'}} className="clt_icon_counter_slider" >{`${currentImageIndex + 1}/${images.length}`}</span>
                            <img src={NextIcon} alt="Next" onClick={handleNextImage} style={{ cursor: 'pointer' }} />
                            {isPlaying ?
                                <img src={PauseIcon} alt="Pause" className="clt_icon_play_pause" onClick={handlePlayPause} style={{ cursor: 'pointer' }} /> :
                                <img src={PlayIcon} alt="Play" className="clt_icon_play_pause" onClick={handlePlayPause} style={{ cursor: 'pointer' }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
</Col>






<Col className="clt-section-row-col-pddddd showMobile">
  <div className="clt-section-1-div d-flex flex-column align-items-start justify-content-start" style={{
    position: 'relative',
    overflow: 'hidden',
  }}>
    {imagesmobile.map((image, index) => (
      <img
        key={index + image} // Combine index with image URL for uniqueness
        src={index === currentImageIndex ? image : null}
        alt="Mobile Image"
        className={index === currentImageIndex ? '' : 'hideMobile'}
        style={{
          width: 'auto',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: '50%', // Center horizontally
          transform: 'translateX(-50%)', // Move back by 50% of the image width
          zIndex: index === currentImageIndex ? -1 : 0,
        }}
      />
    ))}
    <div className="clt-section-1-div-inner" style={{ width: '100%' }}>
      <h2 className="clt-section-1-h2">
        Château Latournelle
      </h2>
      <div>
        <h2 style={{ flexDirection: 'column', display: 'flex', gap: '4px', }} className="clt-section-1-h2-1 notranslate" >Découvrez un château 
          <span style={{ display: 'inline-block' }}>
            <Typewriter
              style={{paddingLeft: "8px"}}
              options={{
                autoStart: true,
                loop: true,
                delay: 75,
                pauseFor: 4000,
                strings: [
                  'à 20 minutes de Toulouse',
                  'pour un mariage',
                  'pour un séminaire',
                  'pour un anniversaire',
                  'à 20 minutes de Toulouse',
                  'pour un baptème',
                  'pour une convention',
                  'pour un mariage',
                  'à 20 minutes de Toulouse',
                  'pour une soirée',
                  'pour une cousinade',
                  'à 20 minutes de Toulouse',
                  'pour un événement professionnel',
                ],
              }}
            />
          </span>
        </h2>
      </div> 
      <Button
        onClick={() => history.push({ pathname: `/detail/${myCurrentEvent?.dataValues?.title}`, state: { param1: myCurrentEvent } })}
        className="clt-section-1-btn shadow-none"
      >
        <span>Découvrir</span>
      </Button>
      {/* Slider Controls */}
      <div className="slider-controls" style={{ position: 'absolute', bottom: 0, right: 0 }}>
        {/* Add your slider control JSX here */}
        <img src={PreviousIcon} alt="Previous" onClick={handlePrevImage} style={{ cursor: 'pointer' }} />
        <span style={{ color: '#fff'}} className="clt_icon_counter_slider" >{`${currentImageIndex + 1}/${imagesmobile.length}`}</span>
        <img src={NextIcon} alt="Next" onClick={handleNextImage} style={{ cursor: 'pointer' }} />
        {isPlaying ?
          <img src={PauseIcon} alt="Pause" className="clt_icon_play_pause" onClick={handlePlayPause} style={{ cursor: 'pointer' }} /> :
          <img src={PlayIcon} alt="Play" className="clt_icon_play_pause" onClick={handlePlayPause} style={{ cursor: 'pointer' }} />
        }
      </div>
    </div>
  </div>
</Col>



        </Row>
        
      </Container>
      
      <NewSection2/>
      

      <Container className="clt-homepage-container denzidnez">
        <Row>
          <Col className="clt-section-row-col-pd jhbdkjcbskjch" > 
              <DetailSection77
                title="Localisation"
                subTitle="Le château est situé à 20 minutes de Toulouse au 42 Rue Seveso, 31150 Fenouillet."
                imageDesktop={DetailSection7ImageDesktop}
                imageMobile={DetailSection7ImageMobile}
                head="Carte"
              />
          </Col>
        </Row>
      </Container>


      <NewSection />
      {/* <NewSection3/>*/}

      

      {/*
      <Container className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd">
            <div className="">
              <div className="clt-section-4">
                <img
                  src={`${section4?.desktopImg}`}
                  className="clt-section-4-img-desktop"
                  alt={IMG_ALT}
                />
                <h2>{section4?.heading}</h2>
                <img
                  src={`${section4?.mobileImg}`}
                  className="clt-section-4-img-mbl"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {myAllEvents &&
        <EventsNavbar
          eventType={eventType}
          myAllEvents={myAllEvents}
          currenEVENT={myCurrentEvent?.dataValues?.title}
          isHomePage={true}
          isStick={isStick}

        />
      } */}
      {myCurrentEvent && 
        <HomeWedding 
        eventType={eventType} 
        setIsOpen={setIsOpen} 
        myCurrentEvent={myCurrentEvent} 
        />
        }
    </React.Fragment>
  );
};
export default HomePage;
