// Import
import React, { useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "../../../../notification/user_notification.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay4 from "../../../../../../assets/img/confirm_pay_4.png";
import CustomHr from "../../../../../reusable/CustomHr";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionInformationMessageRequestSent from "../../../../../reusable/SectionInformationMessageRequestSent.js";
import Booking from "../../../../detail/booking/booking_user";
import Payment from "../../../../payment/Payment";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetailPendingEditRequest.js";
import SectionPaymentPlan from "../../../../../reusable/booking/SectionPaymentPlan";
import SectionPaymentMethod from "../../../../../reusable/booking/SectionPaymentMethod";
import { SuccessToast } from "../../../../../reusable/Toast";
import SidebarEditRequestRejectUser from "./[SIDEBAR] User - Edit Request - Reject";
import SidebarMyBookingUser from "../../../user_mybooking";

/* Const */
const SidebarEditRequestSentUser = ({ isOpenSidebarEditRequestSentUser, onCloseOpenSidebarEditRequestSentUser, setIsOpenSidebarEditRequestSentUser, isFromMakeNewRequest }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(eventweddingData);

  /* Const - Booking */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);

  /* Const - Section - Date */
  const handleDateEdit = () => {
    setIsBookingSidebar(true);
  };
  let bookingProps = {
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Section - Payment plan */
  const [selectedPlan, setSelectedPlan] = useState({});

  /* Const - Cancel request */
  const handleRefuse = () => {
    SuccessToast("La demande a été annulé");
    onCloseOpenSidebarEditRequestSentUser(true);
    setIsOpenSidebarEditRequestRejectUser(true);
  };
  let editrequestProps = {
  };

  /* Const - Sidebar - Request reject */
  const [isOpenSidebarEditRequestRejectUser, setIsOpenSidebarEditRequestRejectUser] = useState(false);

  /* Const - Make a new request  */
  const handleMakeNewRequest = () => {
    SuccessToast("There is the user booking, you can edit what you need");
    setIsOpenSidebarMyBookingUser(true);
  };

  /* Const - Section - Date - Make a new request - Open Sidebar Booking */
  const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);

  

  



  {/* Sidebar - User - Edit request - Sent */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarEditRequestSentUser}
        onHide={onCloseOpenSidebarEditRequestSentUser}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarEditRequestSentUser}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>[SIDEBAR] User - Edit Request - Sent</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          <>
            <div className="clt_mybooking_offcanvas_body">
              <SectionTop
                image={ConfirmPayProfilePicture1}
                title="CLT"
                subTitle="Wedding · 25€ · #111111"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionInformationMessageRequestSent
                innerTitle="Sent"
                innerSubTitle="The request can be accept or decline, you can make a modification if necessary"
              />
            </div>
            <CustomHr
              rowClass="p-0 m-0"
              colClass="clt-booking-hr-pd p-0 w-100"
              hrClass="p-0 m-0 clt-hr-2"
            />
            
            <div className="clt_mybooking_offcanvas_body">
              <h2 className="clt-mybooking-title">Edit request - Sent</h2>
              <SectionDate
                image={ConfirmPay2}
                handleDateEdit={handleMakeNewRequest}
                subText1="Subtext 1"
                subText2="Subtext 2"
                isEditRequest={true}
                oldSubText1="Subtext 1"
                oldSubText2="Subtext 2"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionPriceDetail
                title="Price detail"
                priceListData={priceDetailsSlots}
                totalPrice="10€"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />



              {/* Footer */}
              <Row>
                <Col>

                  <div className="clt-booking-btn-refuse-div">
                    <Button
                      className="shadow-none clt-booking-btn-refuse"
                      onClick={handleRefuse}
                    >
                      <span>Cancel request</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>




      {/* Function - Booking : Sidebar date and Accept request*/}
      <Booking
        openBookingSidebar={isBookingSidebar}
        
        onCloseBookingSidebar={(data) => {
          if (data === "Notification") {
            SuccessToast("Edited");
            onCloseOpenSidebarEditRequestSentUser();
          }
          setIsBookingSidebar(false);
        }}
        eventType={eventType}
        setEventType={(event) => setEventType(event)}
        isFromEditRequest={true}
        {...bookingProps}
      />

      {/* Function - Sidebar - Booking - User */}
      <SidebarMyBookingUser
        isOpenSidebarMyBookingUser={isOpenSidebarMyBookingUser}
        onCloseOpenSidebarMyBookingUser={() => setIsOpenSidebarMyBookingUser(false)}
        isFromMakeNewRequest={true}
      />

      {/* Function - Sidebar - Reject edit request */}
      <SidebarEditRequestRejectUser
        isOpenSidebarEditRequestRejectUser={isOpenSidebarEditRequestRejectUser}
        onCloseOpenSidebarEditRequestRejectUser={() => setIsOpenSidebarEditRequestRejectUser(false)}
      />
    </>
  );
};

export default SidebarEditRequestSentUser;
