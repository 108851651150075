import axios from "axios";
import { API_ENDPOINT } from "../../constants";

const API_URL = `${API_ENDPOINT}/helpcenter`;

export const getAllArticles = () => {
  return axios
    .get(`${API_URL}/category/articles`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const getAllCategoryWithPaths = (id) => {
  return axios
    .get(`${API_URL}/category/paths/details/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const getPathById = (id) => {
  return axios
    .get(`${API_URL}/category/path/single/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};

export const getAllArticlesWithCategory = (id) => {
  return axios
    .get(`${API_URL}/category/articles/${id}`)
    .then((res) => ({ success: true, data: { ...res.data } }))
    .catch((err) => ({ success: false, ...err.response.data }));
};
