export const actionTypes = {
  ALLEVENTS: 'ALLEVENTS',
  ALLEVENTSERR: 'ALLEVENTSERR',
  EVENT: 'EVENT',
  EVENTERR: 'EVENTERR',
  ALLROOMS: 'ALLROOMS',
  ROOM: 'ROOM',
  ROOMERR: 'ROOMERR',
  ALLROOMSERR: 'ALLROOMSERR',
  ALLTIMESLOTS: 'ALLTIMESLOTS',
  ALLTIMESLOTSERR: 'ALLTIMESLOTSERR',
  EVENTSTIMESLOTS: 'EVENTSTIMESLOTS',
  EVENTSTIMESLOTSERR: 'EVENTSTIMESLOTSERR',
  FORMATEDSLOTS: 'FORMATEDSLOTS',
  FORMATEDSLOTSERR: 'FORMATEDSLOTSERR',
  NEWSLOT: 'NEWSLOT',
  NEWSLOTERR: 'NEWSLOTERR',
  SLOTROOMS: 'SLOTROOMS',
  SLOTROOMSERR: 'SLOTROOMSERR',
  ALLBOOKINGS: 'ALLBOOKINGS',
  USERSEARCHBOOKING: 'USERSEARCHBOOKING',
  ALLBOOKINGSERR: 'ALLBOOKINGSERR',
  ALLCARDS: 'ALLCARDS',
  ALLCARDSERR: 'ALLCARDSERR',
  CLIENTSECRET: "CLIENTSECRET",
  CLIENTSECRETERR: "CLIENTSECRETERR",
  LOADING: 'LOADING',
  OPENCHAT: 'OPENCHAT',
}
