import React, { useState, useRef, useEffect, useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import "./auth-modal.css";
import "react-phone-number-input/style.css";
import SignIn from "./AuthSignin";
import SignUp from "./AuthSignup";
import { AuthContext } from "./authContext";
import { ErrorToast } from "../../reusable/Toast";

export default function ZAuthModal(props) {




	const [state, setState] = useState({
		isSignInView: true,
		isSignUpView: false,
		isSignUpStep: 1,
		ispasswordhide: true,
		email: "",
		phoneNumber: ""
	});

	const prevStateRef = useRef(state);
	const numberTempRef = useRef();

	useEffect(() => {
		if (numberTempRef.current !== undefined) {
			setState((prevState) => ({
				...prevState,
				phoneNumber: numberTempRef.current,
			}));
		}
	}, []);


	useEffect(() => {
		numberTempRef.current = state.phoneNumber;
	}, [state.phoneNumber]);


	useEffect(() => {
		prevStateRef.current = state;
	}, [state]);


	const updatePhoneVerification = (numberTemp) => {
		setState((prevState) => ({
			...prevState,
			phoneNumber: numberTemp,
		}));
	};
	const continueLogin = () => {
		setState((p) => ({
			...p,
			isSignInView: false,
		}));
	};

	const createAccount = () => {
		setState((prevState) => {
			return {
				...prevState,
				isSignInView: false,
				isSignUpView: true,
				isSignUpStep: 1,
			};
		});
	};

	const backSignUp = () => {
		setState((prevState) => {
			if (prevState.isSignUpStep === 4) {
				return { ...prevState, isSignUpStep: 2 };
			} else if (prevState.isSignUpStep > 1) {
				return { ...prevState, isSignUpStep: prevState.isSignUpStep - 1 };
			} else {
				return {
					...prevState,
					isSignInView: !prevState.isSignInView,
					isSignUpView: !prevState.isSignUpView,
					isSignUpStep: 1,
				};
			}
		});
	};
	const goToLoginScreen = () => {
		setState((p) => ({
			...p,
			isSignUpStep: 1,
			isSignInView: true,
			isSignUpView: false,
		}));
	};
	const continueSignUp = (phone) => {
		setState((prevState) => {
			const previousPhoneNumber = prevState.phoneNumber;
			const newPhoneNumber = phone;
			if (previousPhoneNumber === "") {
				return { ...prevState, isSignUpStep: prevState.isSignUpStep + 1 };
			}
			if (prevState.isSignUpStep === 2 && previousPhoneNumber === newPhoneNumber) {
				return { ...prevState, isSignUpStep: 4 };
			} else {
				return { ...prevState, isSignUpStep: prevState.isSignUpStep + 1 };
			}
		});
	};

	const showHidePass = () =>
		setState((prevState) => {
			return { ...prevState, ispasswordhide: !prevState.ispasswordhide };
		});

	const inputHandleChange = (value, type) => {
		setState((p) => ({ ...p, [type]: value }));
	};

	const resetRegistration = () => {
		setState({
			isSignInView: true,
			isSignUpView: false,
			isSignUpStep: 1,
			ispasswordhide: true,
			email: "",
		});
	};

	return (
		<Offcanvas
			placement={`${props.variant === "second" ? "bottom" : "end"}`}
			show={props.isLoginModal}
			onHide={props.variant !== "second" ? props.toggleAuthModal : () => null}
			className={`${props.variant === "second" ? "clt_auth_user_offcanvas_variant_2nd" : "clt_auth_user_offcanvas"}`}
		>
			{state.isSignInView && !props.authenticationFlow && (
				<SignIn
					{...props}
					createAccount={createAccount}
					continueLogin={continueLogin}
					ispasswordhide={state.ispasswordhide}
					showHidePass={showHidePass}
					inputHandleChange={(val, type) => inputHandleChange(val, type)}
				/>
			)}
			{state.isSignUpView && !props.authenticationFlow && (
				<SignUp
					{...props}
					backSignUp={backSignUp}
					goToLoginScreen={goToLoginScreen}
					isSignUpStep={state.isSignUpStep}
					continueSignUp={continueSignUp}
					resetRegistration={resetRegistration}
					inputHandleChange={(val, type) => inputHandleChange(val, type)}
					updatePhoneVerification={updatePhoneVerification}
				/>
			)}
			{props.authenticationFlow && props.authenticationFlow.userId && (
				<SignIn
					{...props}
					createAccount={createAccount}
					continueLogin={continueLogin}
					ispasswordhide={state.ispasswordhide}
					showHidePass={showHidePass}
					inputHandleChange={(val, type) => inputHandleChange(val, type)}
				/>
			)}
			{props.authenticationFlow && !props.authenticationFlow.userId && (
				<SignUp
					{...props}
					backSignUp={backSignUp}
					goToLoginScreen={goToLoginScreen}
					isSignUpStep={state.isSignUpStep}
					continueSignUp={continueSignUp}
					resetRegistration={resetRegistration}
					inputHandleChange={(val, type) => inputHandleChange(val, type)}
					updatePhoneVerification={updatePhoneVerification}
				/>
			)}
		</Offcanvas>
	);
}

