import React from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

const SectionPriceDetail = ({ title, priceListData, totalPriceEdit, totalPrice }) => {
  return (
    <Row>
      <Col className="clt-booking-cp-div5">
        {title && <h2 className="clt-booking-cp-div5-title">{title}</h2>}
        {priceListData.map((priceData, index) => {
          return (
            <div
              key={index}
              className="w-100 d-flex justify-content-between align-items-center clt-booking-cp-div5-inner"
            >
              <div className="d-flex">
                <h2 className="clt-booking-cp-div5-inner">{priceData.title}</h2>
                {priceData.tooltipMsg && (
                  <OverlayTrigger
                    placement="top-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-top-start`}
                        className="clt-booking-cp-tooltip"
                      >
                        {priceData.tooltipMsg}
                      </Tooltip>
                    }
                  >
                    <i className="fa-solid fa-info"></i>
                  </OverlayTrigger>
                )}
              </div>
              <h2
                className="clt-booking-cp-div5-inner"
                style={{ textAlign: "end" }}
              >
                {priceData.price}
              </h2>
            </div>
          );
        })}
        <div className="d-flex justify-content-between align-items-center clt-booking-cp-div5-inner">
          <h2 className="clt-booking-cp-text-bold">Total</h2>
          <h2 className="clt-booking-cp-text-bold">{totalPrice}</h2>
        </div>
      </Col>
    </Row>
  );
};

export default SectionPriceDetail;
