/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./detail.css";
import "./custom-datepicker.css";
import DetailIcon1 from "../../../assets/img/detail_icon_1.jpg";
import DetailIcon2 from "../../../assets/img/detail_icon_2.jpg";
import DetailIcon3 from "../../../assets/img/detail_icon_3.jpg";
import DetailIcon4 from "../../../assets/img/detail_icon_4.jpg";
import img_section_info from "../../../assets/img/img_section_info.png";
import DetailSlider_1_1 from "../../../assets/img/detail_slider_1_1.png";
import DetailSlider_1_2 from "../../../assets/img/detail_slider_1_2.png";
import DetailSlider_1_3 from "../../../assets/img/detail_slider_1_3.png";
import DetailSlider_1_4 from "../../../assets/img/detail_slider_1_4.png";
import IconContactUs from "../../../assets/img/icon_contact_us.png";
import wall_color from "../../../assets/img/wall-color.png";
import wall_color1 from "../../../assets/img/wall-color1.png";
import wall_color2 from "../../../assets/img/wall-color2.png";
import drawer_m8 from "../../../assets/img/drawer_m8.png";
import home_searchbar_icon from "../../../assets/img/home_searchbar_icon.png";
import DetailSection4_1 from "../../../assets/img/detail_section_4_1.png";
import DetailSection5_Icon1 from "../../../assets/img/detail_section_5_icon_1.png";
import DetailSection5_Icon2 from "../../../assets/img/detail_section_5_icon_2.png";
import DetailSection5_Icon3 from "../../../assets/img/detail_section_5_icon_3.png";
import DetailSection5_Icon4 from "../../../assets/img/detail_section_5_icon_4.png";
import DetailSection5_Icon5 from "../../../assets/img/detail_section_5_icon_5.png";
import DetailSection5_Icon6 from "../../../assets/img/detail_section_5_icon_6.png";
import DetailSection5_Icon7 from "../../../assets/img/detail_section_5_icon_7.png";
import DetailSection5_Icon8 from "../../../assets/img/detail_section_5_icon_8.png";

import IconMariage from "../../../assets/img/icon-bar-1-active.png";
import IconSeminaire from "../../../assets/img/icon-bar-2-active.png";
import IconAnniversaire from "../../../assets/img/icon-bar-3-active.png";
import IconReligieux from "../../../assets/img/icon-bar-4-active.png";
import IconSoirée from "../../../assets/img/icon-party.png";
import IconRepasNoël from "../../../assets/img/icon-bar-noel.png";

import clt_gallery1 from '../../../assets/img/clt_gallery1.png';
import clt_gallery2 from '../../../assets/img/clt_gallery2.png';
import clt_gallery3 from '../../../assets/img/clt_gallery3.png';
import clt_gallery20 from '../../../assets/img/clt_gallery20.jpg';
import clt_gallery4 from '../../../assets/img/clt_gallery4.jpg';
import clt_gallery27 from '../../../assets/img/clt_gallery27.jpg';
import clt_gallery28 from '../../../assets/img/clt_gallery28.jpg';
import clt_gallery29 from '../../../assets/img/clt_gallery29.jpg';
import clt_gallery30 from '../../../assets/img/clt_gallery30.jpg';
import clt_gallery33 from '../../../assets/img/clt_gallery33.jpg';
import clt_gallery37 from '../../../assets/img/clt_gallery37.jpg';
import clt_gallery31 from '../../../assets/img/clt_gallery31.jpg';
import clt_gallery26 from '../../../assets/img/clt_gallery26.jpg';
import clt_gallery23 from '../../../assets/img/clt_gallery23.jpg';
import clt_gallery6 from '../../../assets/img/clt_gallery6.jpg';
import clt_gallery36 from '../../../assets/img/clt_gallery36.jpg';
import clt_gallery7 from '../../../assets/img/clt_gallery7.jpg';
import clt_gallery10 from '../../../assets/img/clt_gallery10.jpg';
import clt_gallery11 from '../../../assets/img/clt_gallery11.jpg';
import clt_gallery22 from '../../../assets/img/clt_gallery22.jpg';


// Import - Screen - Account
import Account from "../account/Account";

// Import - Screen - Payment method
import Payment from "../payment/Payment";

// Import - Screen - Notification
import Notification from "../notification/user_notification";

// Import - Screen - My booking
import MyBooking from "../myBooking/user_mybooking";
import {
  getAllArticlesWithCategory,
  getAllCategoryWithPaths,
} from "../../../containers/helpCenterPages/api";
import Article from "../helpCenter/Article";
import { getArticlesByName } from "../../../utils/filters";

import DetailSection7ImageMobile from "../../../assets/img/detail_section_7_img_mobile.jpg";
import DetailSection7ImageDesktop from "../../../assets/img/detail_section_7_img_desktop.jpg";
import InnerNavbar from "../../../layouts/InnerNavbar";
import { isBrowser, isTablet } from "react-device-detect";
import FooterBottomUp from "../../reusable/FooterBottomUp";
import DetailFooter from "./components/DetailFooter";
import { section4NewSection5, NewSection5 } from "../../reusable/packoption/NewSection5"; // Import the data and component
import DetailGallery from "./components/DetailGallery";
import DetailContactForm from "./components/DetailContactForm";
import DetailReserveForm from "./components/DetailReserveForm";
import CustomModal from "../../reusable/CustomModal";
import { section4ProductsOptionMariage, ProductsOptionMariage } from "../../reusable/packoption/ProductsOptionMariage"; // Import the data and component
import { section4ProductsPrestataireMariage, ProductsPrestataireMariage } from "../../reusable/packoption/ProductsPrestataireMariage"; // Import the data and component
//import { section4ProductsOptionSéminaire, ProductsOptionSéminaire } from "../../reusable/packoption/ProductsOptionSéminaire"; // Import the data and component
//import { section4ProductsPrestataireSéminaire, ProductsPrestataireSéminaire } from "../../reusable/packoption/ProductsPrestataireSéminaire"; // Import the data and component
import PriceSimulatorModal from "../../reusable/PriceSimulatorModel";
import CustomCarousel from "../../reusable/CustomCarousel";
import NewSection6 from "../../reusable/NewSection6";
import DetailSection1 from "./sections/DetailSection1";
import DetailSection2 from "./sections/DetailSection2";
import CustomHr from "../../reusable/CustomHr";
import DetailSection3 from "./sections/DetailSection3";
import { useHistory, useLocation } from "react-router-dom";
import {
  detailMainSliderResponsive,
  detailSecondarySliderResponsive,
  IMG_ALT,
  eventweddingData,
  section4SliderResponsive,
  IMG_BASEURL,
  selectRoomSliderResponsive
} from "../../../constants";
import config from "../../../config";
import DetailSection5 from "./sections/DetailSection5";
import CustomOffCanvas from "../../reusable/CustomOffCanvas";
import DetailSection6 from "./sections/DetailSection6";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import DetailSection7 from "./sections/DetailSection7";
import DetailEventsNavbar from "./components/DetailEventsNavbar";
import Booking from "./booking/booking_user";
import SidebarRuleAndCondition from "../../reusable/booking/SidebarRuleAndCondition";
import DetailSectionRight from "./sections/DetailSectionRight";
import AuthModal from "../auth/AuthModal";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getEvent, getEvents } from "../../../redux/actions/avent";
import { useAuth } from "../auth/authContext";

// Import - Screen - Auth
import AuthUser from "../auth/AuthUser";
import { auth } from "../auth/firebase";
import { Helmet } from 'react-helmet';
import SearchIcon from "../../../assets/img/home_searchbar_icon.png";
import logo3 from "../../../assets/img/logo3.png";
import metaData from "./metaData";
const DetailPageWedding = (props) => {
  const [eventType, setEventType] = useState(eventweddingData);
  const [currentEvent, setCurrentEvent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenArticle, setIsOpenArticle] = useState(false);
  const [open, setOpen] = React.useState(false);
  /* Const - Screen - Notification */
  const [isNotificationSidebar, setIsNotificationSidebar] = useState(false);
  const onOpenNotificationSidebar = () => {
    setIsNotificationSidebar(true);
  };
  const onCloseNotificationSidebar = () => {
    setIsNotificationSidebar(false);
  };
  const logOut = (e) => {
    auth.signOut();
    localStorage.removeItem("userToken");
    localStorage.removeItem("name");
    localStorage.removeItem("user-login-in");
  };
  /* Const - Screen - My booking */
  const [isOpenSidebarMyBookingUser, setsetIsSidebarMyBookingUser] =
    useState(false);
  const onOpenSidebarMyBookingUser = () => {
    setsetIsSidebarMyBookingUser(true);
  };

  const onCloseOpenSidebarMyBookingUser = () => {
    setsetIsSidebarMyBookingUser(false);
  };

  const getCategories = async () => {
    setIsLoading(true);
    const res = await getAllCategoryWithPaths();
    if (res.success) {
      getArticles(Object.values(res.data).map((cat) => cat.id));
      console.log("res.data", res.data);
    } else {
      console.log("error");
    }
    setIsLoading(false);
  };
  const getArticles = async (ids) => {
    const res = await Promise.all(
      ids.map(async (id) => {
        const res = await getAllArticlesWithCategory(id);
        if (res.success) {
          return Object.values(res.data);
        } else {
          return null;
        }
      })
    );

    const nRes = [].concat(...res).filter((item) => item !== null);
    setArticles(nRes);
  };
  /* Const - Screen - Account */
  const [isAccountDrawer, setIsAccountDrawer] = useState(false);
  const openAccountDrawer = (e) => {
    setIsAccountDrawer(true);
  };

  const closeAccountDrawer = (e) => {
    setIsAccountDrawer(false);
  };

  /* Const - Screen - Payment method */
  const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);
  const onOpenPaymentSidebar = () => {
    setIsPaymentSidebar(true);
  };
  const onClosePaymentSidebar = () => {
    setIsPaymentSidebar(false);
  };
  const [formType, setFormType] = useState("reserve");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const toggleAuthModal = () => {
    setIsLoginModal((prevState) => !prevState);
    setIsLoggedIn(true);
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const event = useSelector((state) => state.event.event);
  const events = useSelector((state) => state.allEvents.events);

  useEffect(() => {
    if (Object?.keys(event)?.length === 0) {
      if (events?.length === 0) {
        dispatch(getEvents());
      } else {
        let filteredData = events?.filter(x => x?.title === props?.match?.params?.name);
        if (filteredData?.length > 0) {
          dispatch(getEvent(filteredData[0]?.id));
        }
      }
    }
  }, [event, history, events]);

  useEffect(() => {
    setCurrentEvent(event?.dataValues);
    console.log('event data values', event.dataValues)
  }, [event]);



  const location = useLocation();
  const section2 = event?.dataValues?.detailsSection2
    ? JSON.parse(event?.dataValues.detailsSection2)
    : null;
  const section3 = event?.dataValues?.detailSection3
    ? JSON.parse(event?.dataValues.detailSection3)
    : null;

  const section4 = event?.dataValues?.detailsSection4
    ? JSON.parse(event?.dataValues.detailsSection4)
    : null;

  const Categorysection5 = event?.categories;

  const section6Topics = event?.dataValues?.eventTopics;

  const section6DetailCategory = event?.detailCategory;
  const initialsortSec6 = section6DetailCategory?.sort(
    (a, b) => a.order - b.order
  );
  const sortedsection6DetailCategory = section6DetailCategory?.map(
    (category) => {
      return {
        ...category,
        rules: category.rules.sort((a, b) => a.order - b.order),
      };
    }
  );

  const myGallery1 = event?.eventGaller;
  const { isLoggedIn: authLogin, user } = useAuth();

  const [personalAccount, setPersonalAccount] = useState({
    first_name: user?.firstName || "",
    name: authLogin ? user?.lastName : "",
    phone: user?.phone || "",
    email: user?.email || "",
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [eventTypeParent, setEventTypeParent] = useState("");
  const [isPriceSimulatorModal, setIsPriceSimulatorModal] = useState(false);
  // const [eventType, setEventType] = useState("");
  const [guestCount, setGuestCount] = useState(null);
  const [message, setMessage] = useState(null);
  const [bottomUp, setBottomUp] = useState(false);
  const [isFormModal, setIsFormModal] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const [isProductsSidebarOpen, setIsProductsSidebarOpen] = useState(false);
  const [isProductsSidebarList, setIsProductsSidebarList] = useState(false);
  const [isProductsSiderbarDetail, setIsProductsSiderbarDetail] = useState(false);
  
  const [isEventSidebarOpen, setIsEventSidebarOpen] = useState(false);
  const [isEventSidebarList, setIsEventSidebarList] = useState(false);
  const [isEventSidebarDetail, setIsEventSidebarDetail] = useState(false);


  const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] =
    useState(false);
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);
  const [productDetail, setProductDetail] = useState({});
  const DetailMainSliderArrows3 = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
          <p className="aaclt_admin_dash_timeslot_edit_box_subheading mt-1">{rest.subtitle} </p>
      </div>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
        <h2 className="clt-detail-section-7142">{rest.title}</h2>
        <div>
          <Button
            className={currentSlide === 0
              ? "disable clt-detail-left-section-2-cards-arrowBtn me-2"
              : "clt-detail-left-section-2-cards-arrowBtn me-2"}
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={currentSlide === disableState
              ? "disable clt-detail-left-section-2-cards-arrowBtn"
              : "clt-detail-left-section-2-cards-arrowBtn"}
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
      
    );
  };
  const handleProductSlider3 = () => {
    setIsEventSidebarOpen(!isEventSidebarOpen);
    setIsEventSidebarList(true);
    setIsEventSidebarDetail(false);
  };


  const staticImages = [
    { src: clt_gallery1, id: 1, name: "Château" },
    { src: clt_gallery2, id: 2, name: "Cour"},
    { src: clt_gallery3, id: 3, name: "Salle de réception"},
    { src: clt_gallery20, id: 4, name: "Suite"},
    { src: clt_gallery4, id: 5, name: "Chambre"},
    { src: clt_gallery27, id: 6, name: "Terrasse"},
    { src: clt_gallery28, id: 7, name: "Terrasse"},
    { src: clt_gallery29, id: 8, name: "Terrasse"},
    { src: clt_gallery30, id: 9, name: "Terrasse"},
    { src: clt_gallery36, id: 10, name: "Cour" },
    { src: clt_gallery33, id: 11, name: "Cour" },
    { src: clt_gallery37, id: 12, name: "Cour" },
    { src: clt_gallery22, id: 13, name: "Cour" },
    { src: clt_gallery31, id: 14, name: "Cour" },
    { src: clt_gallery26, id: 15, name: "Salle de réception"},
    { src: clt_gallery23, id: 16, name: "Salle de réception"},
    { src: clt_gallery6, id: 17, name: "Salle d'eau"},
    { src: clt_gallery7, id: 18, name: "Salle d'eau"},
    { src: clt_gallery10, id: 19, name: "Salle d'eau"},
    { src: clt_gallery11, id: 20, name: "Salle d'eau"},
    
    

  ];

  const [mainGallery, setMainGallery] = useState([
    {
      src: DetailSlider_1_1,
      name: "Image 1 name",
      width: 1950,
      height: 1300,
    },
    {
      src: DetailSlider_1_2,
      name: "Image 2 name",
      width: 800,
      height: 1300,
    },
    {
      src: DetailSlider_1_3,
      name: "Image 3 name",
      width: 1950,
      height: 1300,
    },
    {
      src: DetailSlider_1_4,
      name: "Image 4 name",
      width: 800,
      height: 1300,
    },
  ]);
  const [secondaryGallery, setSecondaryGallery] = useState([
    {
      src: DetailSlider_1_1,
      name: "Image 1 name",
      width: 1950,
      height: 1300,
    },
    {
      src: DetailSlider_1_2,
      name: "Image 2 name",
      width: 1950,
      height: 1300,
    },
    {
      src: DetailSlider_1_3,
      name: "Image 3 name",
      width: 1950,
      height: 1300,
    },
    {
      src: DetailSlider_1_4,
      name: "Image 4 name",
      width: 1950,
      height: 1300,
    },
  ]);
  {/* const [section4Products, setSection4Products] = useState([
    {
      img: DetailSection4_1,
      name: "Product 1.1",
      secondary_text: "Secondary text",
      description: "Description",
    },
    {
      img: DetailSection4_1,
      name: "Product 1.2",
      secondary_text: "Secondary text",
      description: "Description",
    },
    {
      img: DetailSection4_1,
      name: "Product 2.1",
      secondary_text: "Secondary text",
      description: "Description",
    },
    {
      img: DetailSection4_1,
      name: "Product 2.2",
      secondary_text: "Secondary text",
      description: "Description",
    },
    {
      img: DetailSection4_1,
      name: "Product 3.1",
      secondary_text: "Secondary text",
      description: "Description",
    },
  ]);
  const [section4ProductsSidebar, setSection4ProductsSidebar] = useState([
    {
      category: "Category 1",
      products: [
        {
          img: DetailSection4_1,
          name: "Product 1.1",
          secondary_text: "Secondary text",
          description: "Description",
          subTexts: [
            {
              title: "Title 1",
              subText: "Subtext 1",
            },
            {
              title: "Title 2",
              subText: "Subtext 2",
            },
          ],
        },
        {
          img: DetailSection4_1,
          name: "Product 1.2",
          secondary_text: "Secondary text",
          description: "Description",
          subTexts: [
            {
              title: "Title 1",
              subText: "Subtext 1",
            },
            {
              title: "Title 2",
              subText: "Subtext 2",
            },
          ],
        },
      ],
    },
    {
      category: "Category 2",
      products: [
        {
          img: DetailSection4_1,
          name: "Product 2.1",
          secondary_text: "Secondary text",
          description: "Description",
          subTexts: [
            {
              title: "Title 1",
              subText: "Subtext 1",
            },
            {
              title: "Title 2",
              subText: "Subtext 2",
            },
          ],
        },
        {
          img: DetailSection4_1,
          name: "Product 2.2",
          secondary_text: "Secondary text",
          description: "Description",
          subTexts: [
            {
              title: "Title 1",
              subText: "Subtext 1",
            },
            {
              title: "Title 2",
              subText: "Subtext 2",
            },
          ],
        },
      ],
    },
    {
      category: "Category 3",
      products: [
        {
          img: DetailSection4_1,
          name: "Product 3.1",
          secondary_text: "Secondary text",
          description: "Description",
          subTexts: [
            {
              title: "Title 1",
              subText: "Subtext 1",
            },
            {
              title: "Title 2",
              subText: "Subtext 2",
            },
          ],
        },
      ],
    },
  ]);*/}



  /* Const - Handle the screen size to make page responsive */
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setPersonalAccount({
      first_name: user?.firstName || "",
      name: authLogin ? user?.lastName : "",
      phone: user?.phone || "",
      email: user?.email || "",
    });
  }, [authLogin]);

  const openGallery = async (type, index) => {
    const lightboxGallery = staticImages.map((item) => {
      const img = new Image();
      img.src = item.src; // Ensure this src is a valid URL
      return img.decode().then(() => {
        return {
          src: img.src,
          name: item.name, // Using the name from the staticImages object
          width: window.innerWidth,
          height: (img.height / img.width) * window.innerWidth,
        };
      });
    });
  
    const resolvedGallery = await Promise.all(lightboxGallery);
  
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#gallery",
      children: "a",
      dataSource: resolvedGallery,
      pswpModule: () => import("photoswipe"),
    });
  
    lightbox.init();
    lightbox.loadAndOpen(index);
  };
  





  const openForm = (type) => {
    setBottomUp(false);
    setIsFormModal(true);
    setFormType(type);
  };
  const toggleBottomUp = () => {
    setBottomUp((prevState) => !prevState);
  };
  const toggleForm = () => {
    setIsFormModal((prevState) => !prevState);
  };
  const handleProductSlider = () => {
    setIsProductsSidebarOpen(!isProductsSidebarOpen);
    setIsProductsSidebarList(true);
    setIsProductsSiderbarDetail(false);
  };

  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
          <p className="aaclt_admin_dash_timeslot_edit_box_subheading mt-1">{rest.subtitle} </p>
      </div>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
        <h2 className="clt-detail-left-section-2-h2">{rest.title}</h2>
        <div>
          <Button
            className={currentSlide === 0
              ? "disable clt-detail-left-section-2-cards-arrowBtn me-2"
              : "clt-detail-left-section-2-cards-arrowBtn me-2"}
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={currentSlide === disableState
              ? "disable clt-detail-left-section-2-cards-arrowBtn"
              : "clt-detail-left-section-2-cards-arrowBtn"}
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
      
    );
  };

  const DetailMainSliderArrows2 = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
        <h2 className="clt-detail-left-section-2-h2">{rest.title}</h2>
        <div>
          <Button
            className={currentSlide === 0
              ? "disable clt-detail-left-section-2-cards-arrowBtn me-2"
              : "clt-detail-left-section-2-cards-arrowBtn me-2"}
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={currentSlide === disableState
              ? "disable clt-detail-left-section-2-cards-arrowBtn"
              : "clt-detail-left-section-2-cards-arrowBtn"}
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
      
    );
  };
  const currentMetaData = metaData[currentEvent?.title] || metaData.default;
  return (
    <div className="clt-detail-div">
      <Helmet>
        <title>Detail</title>
        <meta name="title" content="SearchTitleDetail" />
        <meta name="description" content="SearchSubTitleDetail" />
        <link rel="icon" href={logo3} />
        <meta property="og:title" content={currentMetaData.title} />
        <meta property="og:description" content={currentMetaData.description} />
        <meta property="og:image" content={currentMetaData.image} />
        <meta property="og:url" content={currentMetaData.url} />
        <meta name="keywords" content={currentMetaData.keywords} />
        {/* <meta property="og:title" content="SearchTitleDetail" />
        <meta property="og:description" content="This is Detail Page description" />
        <meta property="og:image" content="image_url" />
        <meta property="og:url" content="https://example.com/detail-page" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" /> */}
        <script type="application/ld+json">
        {`{"@context": "https://schema.org","@type": "WebPage","mainEntity": {  "@type": "ItemList",  "itemListElement": 
        {"@type": "WebPage","name": "Mariage","description": "Retrouvez le détail d'un évenement 'Mariage' au Château Latournelle.","image": "../../../assets/img/icon-bar-1-active.png","url": "https://chateaulatournelle.com/detail/Mariage"},
        {"@type": "WebPage","name": "Séminaire","description": "Retrouvez le détail d'un évenement 'Séminaire' au Château Latournelle.","image": "../../../assets/img/icon-bar-2-active.png","url": "https://chateaulatournelle.com/detail/S%C3%A9minaire"},
        {"@type": "WebPage","name": "Repas noël","description": "Retrouvez le détail d'un évenement 'Repas de noël' au Château Latournelle.","image": "../../../assets/img/icon-bar-noel.png","url": "https://chateaulatournelle.com/detail/Repas%20no%C3%ABl"},
        {"@type": "WebPage","name": "Anniversaire","description": "Retrouvez le détail d'un évenement 'Anniversaire' au Château Latournelle.","image": "../../../assets/img/icon-bar-3-active.png","url": "https://chateaulatournelle.com/detail/Anniversaire"},
        {"@type": "WebPage","name": "Baptème","description": "Retrouvez le détail d'un évenement 'Baptème' au Château Latournelle.","image": "../../../assets/img/icon-bar-4-active.png","url": "https://chateaulatournelle.com/detail/Baptême"},
        {"@type": "WebPage","name": "Convention","description": "Retrouvez le détail d'un évenement 'Convention' au Château Latournelle.","image": "../../../assets/img/conference-de-presse.png","url": "https://chateaulatournelle.com/detail/Convention"},
        {"@type": "WebPage","name": "Cousinade","description": "Retrouvez le détail d'un évenement 'Cousinade' au Château Latournelle.","image": "../../../assets/img/icon-bar-cousinade.png","url": "https://chateaulatournelle.com/detail/Convention"},
        {"@type": "WebPage","name": "Repas","description": "Retrouvez le détail d'un évenement 'Repas' au Château Latournelle.","image": "../../../assets/img/icon-bar-repas.png","url": "https://chateaulatournelle.com/detail/Convention"},
        {"@type": "WebPage","name": "Soirée","description": "Retrouvez le détail d'un évenement 'Soirée' au Château Latournelle.","image": "../../../assets/img/icon-party.png","url": "https://chateaulatournelle.com/detail/Soirée"    }  
        ]}}`}
        </script>

      </Helmet>
      {Object.keys(event)?.length > 0 && (
        <>
          <InnerNavbar
            title="Détail des événements"
            isDetail={true}
            backClick="/"
            // titleClick={`/details/${eventweddingData.detail_page}`}
            shareTitle="Château Latournelle"
            shareLink={`${config.baseUrl}/details/${eventweddingData.detail_page}`}
            shareText="Château Latournelle"
          />
          <Container>
            <Row>
              <Col className="clt-detail-slider-maindiv">
              <CustomCarousel
            responsive={detailMainSliderResponsive}
            swipeable={true}
            draggable={true}
            showDots={false}
            arrows={false}
            infinite={true}
            shouldResetAutoplay={false}
            autoPlay={false}
            className="clt-detail-slider-main showMobile"
          >
            {staticImages.map((image, index) => (
              <div
                key={image.id} // Use a unique identifier for the key, such as an id
                className="clt-detail-slider-main-imgdiv"
                style={{
                  backgroundImage: `url(${image.src})`,
                  backgroundColor: "#f0f0f0",
                }}
                onClick={() => openGallery("main", index, staticImages)}
              >
                <span>{`${index + 1}/${staticImages.length}`}</span>
              </div>
            ))}
          </CustomCarousel>
              </Col>
            </Row>
          </Container>
          <DetailSection1
            titleSection1="Château Latournelle"
            subTitleSection1="42 Rue Seveso, 31150 Fenouillet"
            imgIconSection1={DetailIcon1}
          />
          {Object.keys(event).length > 0 && (
            <DetailEventsNavbar eventType={eventType} presentEvent={event} />
          )}
          <Container>
            <Row className="clt-detail-sections-div">
              <Col lg={8} xs={12}>
                <Row>
                  <Col className="clt-detail-slider-maindiv">
                  <CustomCarousel
  responsive={detailMainSliderResponsive}
  swipeable={true}
  draggable={true}
  showDots={false}
  arrows={true}
  infinite={true}
  shouldResetAutoplay={false}
  autoPlay={false}
  className="clt-detail-slider-main hideMobile"
>
  {staticImages.map((image, index) => (
    <div
      key={image.id} // Use a unique identifier for the key
      className="clt-detail-slider-main-imgdiv cursor-zoom-in"
      style={{
        backgroundImage: `url(${image.src})`,
        backgroundColor: "#f0f0f0",
      }}
      onClick={() => openGallery("main", index)}
    >
      <span>{`${index + 1}/${staticImages.length}`}</span>
    </div>
  ))}
</CustomCarousel>

                  </Col>
                </Row>
                <CustomHr
                  rowClass="hideMobile"
                  colClass="clt-section-hr-pd"
                  hrClass="p-0 m-0 clt-hr"
                />

                <DetailSection2
                  headTitleSection2={section2?.heading}
                  imgIconSection2_1={section2?.image1}
                  innerTitlesSection2={[
                    {
                      imgIcon: section2?.image1,
                      title: section2?.subtext1?.title,
                      subTitle: section2?.subtext1?.subtitle,
                    },
                    {
                      imgIcon: section2?.image2,
                      title: section2?.subtext2?.title,
                      subTitle: section2?.subtext2?.subtitle,
                    },
                  ]}
                  innerSubTextsSection2={[
                    section2?.subtext3?.title1,
                    section2?.subtext3?.title2,
                    section2?.subtext3?.title3,
                    section2?.subtext3?.title4,
                    section2?.subtext3?.title5,
                    section2?.subtext3?.title6,
                  ]}
                />
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <Row className="showMobile">
                  <Col className="clt-detail-left-section-2-h2-mt clt-section-row-col-pd">
                    <h2 className="clt-detail-left-section-2-h2">
                      Visite du château
                    </h2>
                  </Col>
                </Row>



                <Row className="clt-detail-left-section-2-row showMobile">
                  <Col>
                    <Container className="clt-detail-left-section-2-row-container">
                      <Row>
                        <Col className="clt-detail-left-section-2-row-col">
                          <div className="d-flex justify-content-start align-items-center clt-detail-left-section-2">
                          {staticImages
  .map((image, index) => {
    return (
      <div key={image.id}>
        <div
          className="d-flex justify-content-start align-items-start clt-detail-left-section-2-cards clt-detail-left-section-2-cards-main"
          style={{
            backgroundImage: `url(${image.src})`,
          }}
          onClick={() => openGallery("secondary", index)}
        >
          <span>{image.name}</span> {/* Directly using the name from each image object */}
        </div>
      </div>
    );
  })}


                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>



                <Row className="hideMobile">
                  <Col className="clt-detail-left-section-2-col">
                    <div className="d-flex flex-column-reverse">
                      <CustomCarousel
                        responsive={detailSecondarySliderResponsive}
                        arrows={false}
                        shouldResetAutoplay={false}
                        renderButtonGroupOutside={true}
                        customButtonGroup={
                          <DetailMainSliderArrows2 title="Visite du château" />
                        }
                        className="clt-detail-left-section-2-cards-div"
                      >
                        {staticImages
  .map((image, index) => {
    return (
      <div
        key={image.id} // Using image.id as key for uniqueness
        className="d-flex justify-content-start align-items-start clt-detail-left-section-2-cards clt-detail-left-section-2-cards-main cursor-zoom-in"
        style={{
          backgroundImage: `url(${image.src})`, // Updated to use src from staticImages
        }}
        onClick={() => openGallery("secondary", index)}
      >
        <span>{image.name}</span> 
      </div>
    );
  })}

                      </CustomCarousel>
                    </div>
                  </Col>
                </Row>
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <DetailSection3
                  imgIconSection3={img_section_info}
                  titleSection3={section3?.heading}
                  subTextSection3={section3?.subtext}
                />
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />


                {/* Mariage */}
                {currentEvent?.title === "Mariage" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Mariage" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }



                {/* Séminaire */}
                {currentEvent?.title === "Séminaire" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Séminaire" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }







                {/* Repas noël */}
                {currentEvent?.title === "Repas noël" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Repas noël" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }






                {/* Anniversaire */}
                {currentEvent?.title === "Anniversaire" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Anniversaire" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }














                {/* Baptème */}
                {currentEvent?.title === "Baptème" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Baptème" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }












                {/* Convention */}
                {currentEvent?.title === "Convention" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Convention" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }











                {/* Soirée */}
                {currentEvent?.title === "Soirée" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Soirée" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }













                {/* Cousinade */}
                {currentEvent?.title === "Cousinade" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Cousinade" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }












                {/* Afterwork */}
                {currentEvent?.title === "Afterwork" &&
                  <ProductsPrestataireMariage
                    products={section4ProductsPrestataireMariage}
                    handleProductSlider={handleProductSlider}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Prestataire" subtitle="Parcourez notre liste de prestataires suggérés pour votre événement" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Prestataire"
                  />
                }

                {currentEvent?.title === "Afterwork" &&
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4ProductsOptionMariage}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="Option"
                    />
                  </>
                }

















                {/* This section should appear only if the EventType "Mariage" is selected 
                {currentEvent?.title === "Mariage" && (
                  <>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <ProductsOptionMariage
                      products={section4Products}
                      handleProductSlider={handleProductSlider}
                      sliderProps={{
                        responsive: section4SliderResponsive,
                        arrows: false,
                        shouldResetAutoplay: false,
                        renderButtonGroupOutside: true,
                        customButtonGroup: <DetailMainSliderArrows title="This section appear only when 'Mariage' event is select" />,
                        className: "clt-detail-left-section-4-cards-div",
                      }}
                      customButtonGroupTitle="This section appear only when 'Mariage' event is select"
                    />
                  </>
                )}*/}



                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <DetailSection5
                  headTitleSection5="Équipement"
                  sectionTextList={Categorysection5}
                />
                
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <DetailSection6
                  title="Condition réservation"
                  subTitle="Prenez connaissance des termes et conditions liés à votre réservation"
                  columnsData={[
                    {
                      title: section6Topics?.topicOne?.title,
                      subTitle: section6Topics?.topicOne?.subTitle,
                    },
                    {
                      title: section6Topics?.topicTwo?.title,
                      subTitle: section6Topics?.topicTwo?.subTitle,
                    },
                    {
                      title: section6Topics?.topicThree?.title,
                      subTitle: section6Topics?.topicThree?.subTitle,
                    },
                    {
                      title: section6Topics?.topicFour?.title,
                      subTitle: section6Topics?.topicFour?.subTitle,
                    },
                  ]}
                  onClick={() => setIsSection6SiderbarDetail(true)}
                />
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <NewSection5
                  products={section4NewSection5}

                  handleProductSlider={handleProductSlider3}
                  isDetail={true}
                  sliderProps={{
                    responsive: section4SliderResponsive,
                    arrows: false,
                    shouldResetAutoplay: false,
                    renderButtonGroupOutside: true,
                    customButtonGroup: <DetailMainSliderArrows3 title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
                    className: "clt-detail-left-section-4-cards-div",
                  }}
                  customButtonGroupTitle="Option"
                />
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <NewSection6 setBottomUp={setBottomUp} isDetail={true} />
                <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                <DetailSection7
                  title="Localisation"
                  subTitle="Le château est situé à 20 minutes de Toulouse au 42 Rue Seveso, 31150 Fenouillet."
                  imageDesktop={DetailSection7ImageDesktop}
                  imageMobile={DetailSection7ImageMobile}
                  isDetail={true}
                />
                {/* Last section bottom */}
                <Row className="clt-detail-footer-mb" />
              </Col>
              {Object.keys(event).length > 0 && (
                <Col lg={4} xs={12} className="hideMobile hideTablet">
                  <DetailSectionRight
                    formType={formType}
                    eventType={eventType}
                    setFormType={setFormType}
                    formTitle={formType === "contact" ? "Title" : "Title"}
                    formSubtitle={formType === "contact" ? "subtitle" : "subtitle"}
                    setIsBookingSidebar={setIsBookingSidebar}
                    setEventType={setEventType}
                    event={event?.dataValues}
                  />
                </Col>
              )}
            </Row>
          </Container>








          <DetailFooter
            footerTitleSection3={section3?.footerTitle}
            footerSubtitleSection3={section3?.footerSubtitle}
            setBottomUp={() => setBottomUp(!bottomUp)}
            eventType={eventType}
            currentEvent={event.dataValues}
          />
          <FooterBottomUp
            bottomUp={bottomUp}
            toggleBottomUp={toggleBottomUp}
            centered={true}
            size={"lg"}
            fullscreen={true}
            className="clt_footer_bottom_up_modal"
            contentClassName="clt_footer_bottom_up_modal_content"
            dialogClassName="clt_footer_bottom_up_modal_dailog"
          >
            <Row>
              <Col lg={12} className="">
                <h2 className="clt-detail-footer-bottom-up-h2">
                  Que voulez-vous faire?
                </h2>
              </Col>
              <Col lg={12} className="clt-detail-footer-bottom-up-menus-div">
                <div
                  className="d-flex justify-content-start align-items-center clt-detail-footer-bottom-up-menus"
                  onClick={() => {
                    // setTimeout(() => {
                    //   // Function to translate abbreviated English to French
                    //   function translateToFrench(text) {
                    //     const translations = {
                    //       'Sun': 'Dim',
                    //       'Mon': 'Lun',
                    //       'Tue': 'Mar',
                    //       'Wed': 'Mer',
                    //       'Thu': 'Jeu',
                    //       'Fri': 'Ven',
                    //       'Sat': 'Sam',
                    //       'Jan': 'Jan',
                    //       'Feb': 'Fév',
                    //       'Mar': 'Mar',
                    //       'Apr': 'Avr',
                    //       'May': 'Mai',
                    //       'Jun': 'Jui',
                    //       'Jul': 'Jui',
                    //       'Aug': 'Aoû',
                    //       'Sep': 'Sep',
                    //       'Oct': 'Oct',
                    //       'Nov': 'Nov',
                    //       'Dec': 'Déc',
                    //     };

                    //     return text.replace(/\b\w{3}\b/g, match => translations[match] || match);
                    //   }

                    //   // Get all elements with the class "selected-date"
                    //   const selectedDateElements = document.querySelectorAll('.selected-date');

                    //   // Iterate through the elements and translate their innerHTML
                    //   selectedDateElements.forEach(element => {
                    //     element.innerHTML = translateToFrench(element.innerHTML);
                    //   });
                    // }, 300);
                    setIsBookingSidebar(true)
                  }}
                >
                  <img src={home_searchbar_icon} alt={IMG_ALT} />
                  <span>Réserver</span>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center clt-detail-footer-bottom-up-menus"
                  onClick={(e) => { e.preventDefault(); setIsPriceSimulatorModal(true) }}
                >
                  <img src={drawer_m8} alt={IMG_ALT} />
                  <span>Voir les prix</span>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center clt-detail-footer-bottom-up-menus"
                  // onClick={() => isLoggedIn ? openForm("contact") : toggleAuthModal()}
                  onClick={() => openForm("contact")}
                >
                  <img src={IconContactUs} alt={IMG_ALT} />
                  <span>Nous contacter</span>
                </div>
              </Col>
            </Row>
          </FooterBottomUp>
          <CustomModal
            show={isFormModal}
            onHide={toggleForm}
            title={
              (formType === "contact" && "Nous contacter") ||
              (formType === "reserve" && "Réserver")
            }
            className="clt-detail-custom-modal-class"
            contentClassName="clt-detail-custom-modal-content-class"
            dialogClassName="clt-detail-custom-modal-dialog-class"
          >
            {formType === "contact" && (
              <DetailContactForm
                formTitle="Formulaire de contacttt"
                formSubtitle="subtitle"
                formEventType={event?.dataValues?.title}
                isModal={true}
                customFooterButton="clt-custom-modal-footer-btn"
                // eventType={eventType}
                // setEventType={setEventType}
                isMobile
                data={{
                  personalAccount,
                  setPersonalAccount,
                  setSelectedMonth,
                  selectedMonth,
                  setSelectedYear,
                  selectedYear,
                  setEventType: setEventTypeParent,
                  eventType: eventTypeParent,
                  guestCount,
                  setGuestCount,
                  message,
                  setMessage,
                }}
              />
            )}
            {formType === "reserve" && (
              <DetailReserveForm
                formTitle="Title"
                formSubtitle="subtitle"
                formEventType={event?.dataValues?.title}
                isModal={true}
                customFooterButton="clt-custom-modal-footer-btn"
                eventType={eventType}
                onClick={() => {

                  setIsBookingSidebar(true)

                }}
                setEventType={setEventType}
              />
            )}
          </CustomModal>
          

          <PriceSimulatorModal
            isShow={isPriceSimulatorModal}
            toggleModal={() => setIsPriceSimulatorModal(false)}
          />

          {/* {isGalleryOpen && (
        <DetailGallery
          galleryHeaderTitle="1.1"
          galleryFooterTitle="1.1"
          setIsGalleryOpen={() => setIsGalleryOpen(!isGalleryOpen)}
          responsive={detailMainSliderResponsive}
          isBrowser={isBrowser || isTablet}
          fullScreenGallery={fullScreenGallery}
        />
      )} */}


          {Object.keys(event)?.length > 0 && (
            <SidebarRuleAndCondition
              isSection6SiderbarDetail={isSection6SiderbarDetail}
              setIsSection6SiderbarDetail={() => setIsSection6SiderbarDetail(false)}
              headerTitle="Header title"
              category1={
                sortedsection6DetailCategory?.length > 0
                  ? sortedsection6DetailCategory[0]?.name
                  : ""
              }
              column1_1={{
                title: sortedsection6DetailCategory[0]?.rules[0]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[0]?.subTitle,
              }}
              column1_2={{
                title: sortedsection6DetailCategory[0]?.rules[1]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[1]?.subTitle,
              }}
              column1_3={{
                title: sortedsection6DetailCategory[0]?.rules[2]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[2]?.subTitle,
              }}
              column1_4={{
                title: sortedsection6DetailCategory[0]?.rules[3]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[3]?.subTitle,
              }}
              column1_5={{
                title: sortedsection6DetailCategory[0]?.rules[4]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[4]?.subTitle,
              }}
              column1_6={{
                title: sortedsection6DetailCategory[0]?.rules[5]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[5]?.subTitle,
              }}
              column1_7={{
                title: sortedsection6DetailCategory[0]?.rules[6]?.title,
                subTitle: sortedsection6DetailCategory[0]?.rules[6]?.subTitle,
              }}


              category2={sortedsection6DetailCategory[1]?.name}
              column2_1={{
                title: sortedsection6DetailCategory[1]?.rules[0]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[0]?.subTitle,
              }}
              column2_2={{
                title: sortedsection6DetailCategory[1]?.rules[1]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[1]?.subTitle,
              }}
              column2_3={{
                title: sortedsection6DetailCategory[1]?.rules[2]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[2]?.subTitle,
              }}
              column2_4={{
                title: sortedsection6DetailCategory[1]?.rules[3]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[3]?.subTitle,
              }}
              column2_5={{
                title: sortedsection6DetailCategory[1]?.rules[4]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[4]?.subTitle,
              }}
              column2_6={{
                title: sortedsection6DetailCategory[1]?.rules[5]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[5]?.subTitle,
              }}

              column2_7={{
                title: sortedsection6DetailCategory[1]?.rules[6]?.title,
                subTitle: sortedsection6DetailCategory[1]?.rules[6]?.subTitle,
              }}

              
              category3={sortedsection6DetailCategory[2]?.name}
              column3_1={{
                  title: sortedsection6DetailCategory[2]?.rules[0]?.title,
                  subTitle: sortedsection6DetailCategory[2]?.rules[0]?.subTitle,
              }}
              column3_2={{
                  title: sortedsection6DetailCategory[2]?.rules[1]?.title,
                  subTitle: sortedsection6DetailCategory[2]?.rules[1]?.subTitle,
              }}
              column3_3={{
                  title: sortedsection6DetailCategory[2]?.rules[2]?.title,
                  subTitle: sortedsection6DetailCategory[2]?.rules[2]?.subTitle,
              }}
              column3_4={{
                  title: sortedsection6DetailCategory[2]?.rules[3]?.title,
                  subTitle: sortedsection6DetailCategory[2]?.rules[3]?.subTitle,
              }}

              category4={sortedsection6DetailCategory[3]?.name}
              column4_1={{
                  title: sortedsection6DetailCategory[3]?.rules[0]?.title,
                  subTitle: sortedsection6DetailCategory[3]?.rules[0]?.subTitle,
              }}
              column4_2={{
                  title: sortedsection6DetailCategory[3]?.rules[1]?.title,
                  subTitle: sortedsection6DetailCategory[3]?.rules[1]?.subTitle,
              }}
              column4_3={{
                  title: sortedsection6DetailCategory[3]?.rules[2]?.title,
                  subTitle: sortedsection6DetailCategory[3]?.rules[2]?.subTitle,
              }}
              column4_4={{
                  title: sortedsection6DetailCategory[3]?.rules[3]?.title,
                  subTitle: sortedsection6DetailCategory[3]?.rules[3]?.subTitle,
              }}

            />
          )}
          <Booking
            openBookingSidebar={isBookingSidebar}
            onCloseBookingSidebar={() => setIsBookingSidebar(false)}
            // eventType={eventType}
            eventType={event?.dataValues}
            setEventType={(event) => setEventType(event)}
          />
          <AuthModal
            toggleAuthModal={toggleAuthModal}
            isLoginModal={isLoginModal}
          />

          <Article
            article={selectedArticle}
            isOpenArticle={isOpenArticle}
            onClose={() => setIsOpenArticle(false)}
          />

          {isNotificationSidebar ? (
            <Notification
              isNotificationSidebar={isNotificationSidebar}
              onCloseNotificationSidebar={() => onCloseNotificationSidebar()}
            />
          ) : null}

          {isLoggedIn ? (
            <Account
              isOpenSidebar={isAccountDrawer}
              onCloseSidebar={() => closeAccountDrawer()}
            />
          ) : null}

          <Payment
            isPaymentSidebar={isPaymentSidebar}
            onClosePaymentSidebar={() => onClosePaymentSidebar()}
          />

        </>
      )}
    </div>
  );
};
export default DetailPageWedding;


function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}