import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import IconAccept from "../../../assets/img/icon_accept.png";
import ProfilePictureAdmin from "../../../assets/img/detail_slider_1_1.png";
import { IMG_ALT } from "../../../constants";
import { Link } from 'react-router-dom'
const SectionInformationMessageRequestAccept = ({adminMessage}) => {
  return (
    <Row className="clt-detail-section-6">
      <Col>
        <Row>
          <Col>
            <div className="d-flex justify-content-start align-items-center clt-contact-request-box-answer-message ">
              <div className="clt_messages_container_detail_contact_request">
                <img src={ProfilePictureAdmin} alt={IMG_ALT} />
                <div className="clt_messages_container_accountSubDetail">
                  <div className="clt_messages_container_message_detail">
                    <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                    {/* <p className='overflow-wrap'>01/01/23 - 20:00</p> */}
                  </div>
                  <p className="clt_messages_container_message_text">
                    {adminMessage}
                  </p>
                </div>
              </div>
              
            </div>
            <div className="clt_cookie_modal_button_wrapper2">
              <div className="main">
                  <Link to='/inbox' className="clt_cookie_link">
                      Répondre
                  </Link>
              </div>
            </div>
                  
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SectionInformationMessageRequestAccept;
