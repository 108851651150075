import React from 'react'
import './Section.css'
import { IMG_ALT } from "../../../constants";
import SuccessIcon from "../../../assets/img/success_icon.png";
import PhoneIcon from "../../../assets/img/mybooking_icon_phone.png";
import MessageIcon from "../../../assets/img/mybooking_icon_chat.png";
import AddressIcon from "../../../assets/img/mybooking_icon_ping_address.png";
import { toast, cssTransition } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const slide = cssTransition({
    enter: "slide-bottom",
    exit: "slide-top"
});

const SectionContact = () => {


    const showSuccessToast = (title) => {
        toast.success("Copié dans le presse papier",
            {
                autoClose: 2000,
                transition: slide,
                className: "toast-success",
                closeButton: false,
                hideProgressBar: true,
                theme: 'dark',
                icon: ({ theme, type }) => <img src={SuccessIcon} alt='success-icon' className="toast-icon" />
            }
        )
    }


    return (
        <div className="contactSection">
            <a href='tel:+923404637465' className="contactBox">
                <img className="icon" src={PhoneIcon} alt={IMG_ALT} />
                <p>Appeler</p>
            </a>
            <CopyToClipboard text="Near Cafe bar" onCopy={showSuccessToast}>
                <div className="contactBox">
                    <img className="icon" src={AddressIcon} alt={IMG_ALT} />
                    <p>Adresse</p>
                </div>
            </CopyToClipboard>
        </div>
    )
}

export default SectionContact