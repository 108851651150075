// Import
import React, { useContext, useEffect, useState } from 'react'
import { Container, Col, Navbar as AdminNavbar, Row, } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import './admin_navbar.css';
import { ToastContainer } from "react-toastify";
import MenuBlack from "../../../../assets/img/menu_black.png";
import Logoclt from "../../../../assets/img/Logoclt.png";
import { IMG_ALT } from "../../../../constants";
import AuthModal from "../../auth/AuthModal";
import { auth } from "../../auth/firebase";
import { AuthContext, useAuth } from "../../auth/authContext";
import Account from "../../account/Account";
import BusinessInformation from "../AdminDashboardMenu/AdminDashboardBusinessInformation/BusinessInformation";
import Notification from "../AdminDashboardNotification/admin_notification";
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { SuccessToast, ErrorToast } from "../../../reusable/Toast";


/* Const */
const Navbar = ({ setIsOpenSidebar, isOpenSidebar, adminNavLinks, setAdminNavLinks }) => {

    const { isNewNotification, setNewNotification } = useContext(AuthContext);

    /* Const - Sidebar */
    const openSideBar = () => {
        setIsSideBar(true);
    };
    const [isSideBar, setIsSideBar] = useState(false);
    const toggleSidebar = () => {
        setIsOpenSidebar(!isOpenSidebar)
    }

    /* Const - Screen */

    /* Const - Screen - Auth */
    const [isLoginModal, setIsLoginModal] = useState(false);
    const { isLoggedIn, user } = useAuth();
    const toggleAuthModal = () => {
        setIsLoginModal((prevState) => !prevState);
    };
    const [name, setName] = useState("");
    const logOut = (e) => {
        auth.signOut();
        localStorage.removeItem("userToken");
        localStorage.removeItem("name");
        localStorage.removeItem('user-login-in')
        localStorage.removeItem('selectedUserId');
        setIsSideBar(false);
        SuccessToast('Déconnexion de votre compte');
    };


    /* Const - Screen - Personal/Profesional - Profile */
    const [isAccountDrawer, setIsAccountDrawer] = useState(false);
    const openAccountDrawer = (e) => {
        setIsAccountDrawer(true);
        setIsSideBar(false);
        setIsOpenSidebar(false);
    };
    const closeAccountDrawer = (e) => {
        setIsAccountDrawer(false);
        setIsSideBar(true);
    };


    /* Const - Screen - Admin - Profile */
    const [isBusinessInformationInfo, setIsBusinessInformationInfo] =
        useState(false);


    /* Const - Screen - Notification */
    const [isNotificationSidebar, setIsNotificationSidebar] = useState(false);
    const onOpenNotificationSidebar = () => {
        setIsNotificationSidebar(true);
        setIsSideBar(false);
        setNewNotification(false);
    };
    const onCloseNotificationSidebar = () => {
        setIsNotificationSidebar(false);
        setIsSideBar(true);
        setNewNotification(false);

    };

    /* UseEffect */

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const param = searchParams.get("notification");

    /* UseEffect - Account data */
    useEffect(() => {
        if (param) {
            setNewNotification(true);
        }
        if (isLoggedIn && user) {
            console.log("running");
            setName(user?.firstName);
        } else {
            setName("");
        }
        // Listen for 'notificationSent' event from the server
        const socket = io(); // Initialize the socket connection
        socket.on('notificationSent', ({ message }) => {
            setNewNotification(true);
            console.log('This is notification sent', message);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, user]);


    /* UseEffect - Account data */
    React.useEffect(() => {
        if (adminNavLinks == 3)
            setIsOpenSidebar(false)
    }, [adminNavLinks])

    return (
        <>

            {/* Navbar */}
            <AdminNavbar className="clt_admin_sidebar_navbarNav">

                {/* Navbar - Container */}
                <Container fluid className="clt_admin_sidebar_innerNav_container">

                    {/*  Navbar - Button menu */}
                    <div className="clt_admin_sidebar_navbar ">
                        <div className="clt_admin_navbarToggle d-none d-md-flex" onClick={toggleSidebar}>
                            <img src={MenuBlack} className="" alt={IMG_ALT} />
                        </div>
                        <div className="pfr_navbarToggle1" href="/" onClick={openSideBar}>
								<img src={Logoclt} className="" alt={IMG_ALT} />
						</div>
                        {/* 
                        <div className="d-flex clt_admin_navbarLogo d-none d-md-flex">
                            {adminNavLinks == 1 ? "Accueil"
                                : adminNavLinks == 2 ? "Réservation"
                                    : adminNavLinks == 3 ? "Message"
                                        : adminNavLinks == 4 ? "Vitrine"
                                            : "Menu"}
                        </div> */}
                    </div>


                    {/*  Navbar - Button User - New to integrate
                    <div className="clt_admin_sidebar_user">
                        <div className="clt_admin_sidebar_user_letter"> 
                            M
                        </div>
                    </div> */}


                    {/*  Navbar - Button User - Current */}



                    {localStorage.getItem('user-login-in') ? (
                        <AdminNavbar.Text className="pfr_navbarDropDownMenu" key="loggedInText">
                            <Row>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <DropdownButton
                                        size="sm"
                                        align="end"
                                        title={name}
                                    >
                                        <p className="pfr_drawermenuHead2">Hello {name}</p>


                                        <div onClick={onOpenNotificationSidebar} className="pfr_drawermenuitem">
                                            <div className="pfr_drawermenuitemdiv">
                                                <div className={isNewNotification ? "pfr_drawermenuitem12Active" : "pfr_drawermenuitem12"}></div>
                                                <span>Notification</span>
                                            </div>
                                        </div>

                                        {/* <div onClick={openAccountDrawer} className="pfr_drawermenuitem">
                                            <div className="pfr_drawermenuitemdiv">
                                                <div className="pfr_drawermenuitem6"></div>
                                                <span>Personal / Profesionnal - Profile</span>
                                            </div>
                                        </div> */}

                                        <div onClick={setIsBusinessInformationInfo} className="pfr_drawermenuitem">
                                            <div className="pfr_drawermenuitemdiv">
                                                <div className="pfr_drawermenuitem13"></div>
                                                <span>Profile du château</span>
                                            </div>
                                        </div>

                                        {isLoggedIn ? (
                                            <div onClick={logOut} className="pfr_drawermenuitem">
                                                <div className="pfr_drawermenuitemdivred">
                                                    <div className="pfr_drawermenuitem11"></div>
                                                    <span>Déconnexion</span>
                                                </div>
                                            </div>
                                        ) : null}


                                    </DropdownButton>
                                </Col>
                            </Row>
                        </AdminNavbar.Text>
                    ) : (
                        "")}











                </Container>
            </AdminNavbar>

            <ToastContainer className="toast-container" />

            <AuthModal
                toggleAuthModal={toggleAuthModal}
                isLoginModal={isLoginModal}
                // variant can have two possible type either first or second. (first: sidebar/user can close it at anytime) (second: full screen/user must authenticate to close the screens)
                variant="first"
            // variant="second"
            />
            {
                isNotificationSidebar ?
                    <Notification
                        isNotificationSidebar={isNotificationSidebar}
                        onCloseNotificationSidebar={() => onCloseNotificationSidebar()}
                        setAdminNavLinks={setAdminNavLinks}
                    />
                    : null
            }

            {isLoggedIn ? (
                <Account
                    isOpenSidebar={isAccountDrawer}
                    onCloseSidebar={() => closeAccountDrawer()}
                />
            ) : null}

            <BusinessInformation
                isOpenSidebar={isBusinessInformationInfo}
                onCloseSidebar={() => {
                    setIsBusinessInformationInfo(false);
                }}
            />

            
        </>
    )
}

export default Navbar