import React, { useState, useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import FooterBottomUp from "./FooterBottomUp";
import CustomCarousel from "./CustomCarousel";
import CustomOffCanvas from "./CustomOffCanvas";
import BackArrow from "../../assets/img/back_arrow.png";
import icon_calendar from "../../assets/img/icon_calendar.png";

import "../screens/detail/detail.css";
import {
  detailMainSliderResponsive,
  detailSecondarySliderResponsive,
  IMG_ALT,
} from "../../constants";

const NewSection6 = ({props, isDetail}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const fetchGoogleReviews = () => {
    // Check if Google Maps API is loaded
    if (window.google && window.google.maps && window.google.maps.places) {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
  
      service.getDetails(
        {
          placeId: placeId,
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const reviewsWithTime = (place.reviews || []).map(review => {
              const timestamp = review.time ? review.time * 1000 : Date.now();
              return { ...review, time: timestamp };
            });
  
            console.log("Google Reviews data:", reviewsWithTime);
            setGoogleReviews(reviewsWithTime);
          }
        }
      );
    } else {
      console.error('Google Maps API is not loaded');
    }
  };
  const placeId = 'ChIJITYKcHelrhIRI8WmJahNTVc';
  const [useFooterBottomUp, setUseFooterBottomUp] = useState(true);
  const centerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const toggleBottomUp = () => {
    setBottomUp((prevState) => !prevState);
  };
  const [googleReviews, setGoogleReviews] = useState([]);
  const loadMoreReviews = () => {
    setDisplayedReviews(prevCount => prevCount + 5);
  };
  const [displayedReviews, setDisplayedReviews] = useState(5); // Number of reviews to display

  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(null);
  const arrowBtnClass = isDetail ? 'clt-detail-left-section-2-cards-arrowBtn' : 'clt-section-3-cards-arrowBtn';
  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
          <p className="aaclt_admin_dash_timeslot_edit_box_subheading mt-1">{rest.subtitle} </p>
      </div>
      <div className={isDetail ? 'd-flex justify-content-between clt-home-visite-h2-header23535454' : 'd-flex justify-content-between clt-home-visite-h2-header2'}>
        <h2 className={isDetail ? 'clt-detail-section-7142' : 'clt-home-visite-h2'} >{rest.title}</h2>

        <div>
          <Button
            className={
              currentSlide === 0
                ? `disable ${arrowBtnClass} me-2`
                : `${arrowBtnClass} me-2`
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? `disable ${arrowBtnClass}`
                : arrowBtnClass
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
    );
  };

  const handleSeeMoreClick = (index) => {
  console.log(`See more clicked for review at index ${index}`);
};

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  /*
  const openGoogleReviewsGallery = async (index) => {
    const lightboxGallery = await Promise.all(
      googleReviews.map(async (review, reviewIndex) => {
        const reviewPhotos = review.photos || [];
        const imgPromises = reviewPhotos.map(async (photo, photoIndex) => {
          const img = new Image();
          img.src = photo.getUrl({ maxWidth: 1000, maxHeight: 1000 });
          await img.decode();
          return {
            src: img.src,
            name: `Review Photo ${photoIndex + 1}`,
            width: window.innerWidth,
            height: (img.height / img.width) * window.innerWidth,
          };
        });
        return await Promise.all(imgPromises);
      })
    );

    const lightbox = new PhotoSwipeLightbox({
      gallery: `#gallery-reviews-${index}`,
      children: `a`,
      dataSource: lightboxGallery.flat(),
      pswpModule: () => import('photoswipe'),
    });

    lightbox.init();
    lightbox.loadAndOpen(0);
  }; */

  

  const renderStars = (rating) => {
    const formattedRating = parseFloat(rating).toFixed(1); // Ensure rating is a valid number
    if (isNaN(formattedRating)) {
      return null; // or handle the case where the rating is not valid
    }
  
    return (
      <>
        <i className="fas fa-star"></i>
        <span className={isDetail ? 'clt-detail-left-section-4-card-item-ratingkjdcnlksdn' : 'clt-detail-left-section-4-card-item-rating'}>{formattedRating}</span>
        
      </>
    );
  };
  
  const formatAuthorName = (authorName) => {
    if (!authorName) {
      return 'Unknown Author';
    }
  
    const names = authorName.split(' ');
    const firstName = names[0];
    const lastNameInitial = names.length > 1 ? `${names[1].charAt(0)}.` : '';
    return `${firstName} ${lastNameInitial}`;
  };
  
  const formatDate = (timestamp) => {
    const date = new Date(parseFloat(timestamp));
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  
    const monthNames = [
      "Janvier", "Février", "Mars",
      "Avril", "Mai", "juin", "Juillet",
      "Août", "Septembre", "Octobre",
      "Novembre", "Décembre"
    ];
  
    const month = date.getMonth();
    const year = date.getFullYear();
  
    // Format the month and year
    return `${monthNames[month]} ${year}`;
  };
  
  
  
  const [customOffCanvasOpen, setCustomOffCanvasOpen] = useState(false); 
  const [bottomUp, setBottomUp] = useState(false);
  
  const [showSeeMoreLink, setShowSeeMoreLink] = useState(false); // New state for showing "See more" link

  // ... (existing functions)

  const handleSeeMore = (index) => {
    // Set the selected review and its index in the state
    const clickedReview = googleReviews[index];
  
    // Check if the clickedReview exists and has a time property
    if (clickedReview && typeof clickedReview.time === 'number') {
      setSelectedReview(clickedReview);
      setSelectedReviewIndex(index);
  
      // Decide whether to use FooterBottomUp or CustomOffCanvas based on window width
      const isMobile = window.innerWidth < 768; // You can adjust the breakpoint as needed
      setUseFooterBottomUp(isMobile);
  
      // Open the desired component
      if (isMobile) {
        setBottomUp(true);
      } else {
        setCustomOffCanvasOpen(true);
        // Open the CustomOffCanvas (you may need to pass additional props if required)
        // Additional logic to open CustomOffCanvas
        // Example: setCustomOffCanvasOpen(true);
      }
    } else {
      console.error("Invalid or missing time property in the selected review:", clickedReview);
      // Optionally, you can add an error message or handle the case where time is missing
    }
  };
  
  

  useEffect(() => {
    fetchGoogleReviews();
  }, [placeId]);

  useEffect(() => {
    console.log("Reviews with 5/5 rating:", googleReviews.filter(review => review.rating === 5));
    // Set showSeeMoreLink to true for the 6th slide
    setShowSeeMoreLink(true);
  }, [googleReviews]);

  useEffect(() => {
    console.log("Reviews with 5/5 rating:", googleReviews.filter(review => review.rating === 5));
  }, [googleReviews]);
  return (
    <>
      <Container className={isDetail ? 'jdnlcnldzincid' : 'clt-homepage-container'}>
        <Row className="showMobile">
          <Col className={isDetail ? '' : 'clt-section-row-col-pd'}>
            <h2 className={isDetail ? 'clt-detail-section-7142' : 'clt-home-visite-h2174'}>Avis sur le château</h2>
            <p className="abaclt_admin_dash_timeslot_edit_box_subheading mt-1">
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="showMobile" style={{ flexDirection: 'row' }}>
        <Row className="clt-detail-left-section-2-row showMobile">
          <Col>
            <Container className="clt-detail-left-section-2-row-container">
              
              <Row>
                <Col className={isDetail ? '' : 'clt-detail-left-section-2-row-col'}>
                  <div className={isDetail ? 'd-flex justify-content-start align-items-center clt-detail-left-section-2 clt-detail-left-section-21745' : 'd-flex justify-content-start align-items-center clt-detail-left-section-2'}>
                  {googleReviews.map((review, index) => (
                    <div key={index}>
                      <div className="clt-detail-left-section-4-card-item cursor-zoom-in" onClick={() => handleSeeMore(index)}>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="clt-detail-left-section-4-card-item-div174" style={{ backgroundImage: `url(${review.profile_photo_url})` }}></div>
                          <h2 className="clt-detail-left-section-4-card-item-h2">{formatAuthorName(review.author_name)}</h2>

                          <div className="clt-detail-left-section-4-card-item-stars">
                            {renderStars(review.rating)}
                          </div>

                          <p className={`clt-detail-left-section-4-card-item-desc ${review.text.split('\n').length > 4 ? 'truncated' : ''}`}>
                            {review.text}
                          </p>


                          {review.text.length > 80 && (
                            <Button
                            className="clt-button-text"
                            onClick={() => handleSeeMore(index)}
                          >
                            En savoir plus
                          </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  {displayedReviews === 5 && (
                    <div>
                      <div
                        className="clt-detail-left-section-4-card-item174 cursor-zoom-in"
                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                        onClick={() => window.open('https://maps.app.goo.gl/JMC5BdoNoXEJk3g76', '_blank')}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="more-review-text">
                            Afficher tous les avis
                          </p>
                        </div>
                      </div>
                    </div>
                  )}





                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
        

      <Container fluid className={isDetail ? 'clt-detail-left-section-2-col674677 hideMobile' : 'clt-homepage-container hideMobile'}>
        <Row className="hideMobile" style={{ flexDirection: 'row', marginBottom: '6px' }}>
          <Col className="clt-detail-left-section-2-col">
            
            <div className="d-flex flex-column-reverse">
              {googleReviews.length > 0 ? (
               <CustomCarousel
               responsive={detailSecondarySliderResponsive}
               arrows={false}
               shouldResetAutoplay={false}
               
               renderButtonGroupOutside={true}
               customButtonGroup={<DetailMainSliderArrows title="Avis sur le château" />}
               className={`clt-detail-left-section-2-cards-div my-carousel174`}
             >
               {googleReviews.map((review, index) => (
                    <div key={index}>
                      <div className="clt-detail-left-section-4-card-item cursor-zoom-in" onClick={() => handleSeeMore(index)}>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="clt-detail-left-section-4-card-item-div174" style={{ backgroundImage: `url(${review.profile_photo_url})` }}></div>
                          <h2 className={isDetail ? 'clt-detail-left-section-4-card-item-h25345453' : 'clt-detail-left-section-4-card-item-h2'}>{formatAuthorName(review.author_name)}</h2>
                          
                          <div className="clt-detail-left-section-4-card-item-stars">
                            {renderStars(review.rating)}
                          </div>
                          
                          <p className={`clt-detail-left-section-4-card-item-desc${isDetail ? '31351' : ''} ${review.text.split('\n').length > 5 ? 'truncated' : ''}`}>
                            {review.text}
                          </p>


                          {review.text.length > 80 && (
                            <Button
                            className={isDetail ? 'clt-button-text45453' : 'clt-button-text'} 
                            onClick={() => handleSeeMore(index)}
                          >
                            En savoir plus
                          </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  {displayedReviews === 5 && (
                    <div
                      className="clt-detail-left-section-4-card-item174 cursor-zoom-in"
                      style={{ display: 'flex', justifyContent: 'center' }}
                      onClick={() => window.open('https://maps.app.goo.gl/JMC5BdoNoXEJk3g76', '_blank')}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="more-review-text">
                          Afficher tous les avis
                        </p>
                      </div>
                    </div>
                  )}



             </CustomCarousel>
             
             
              ) : (
                <p></p>
              )}
            </div>
          </Col>
        </Row>
      </Container>




      {useFooterBottomUp && (
         <FooterBottomUp
         bottomUp={bottomUp}
         toggleBottomUp={toggleBottomUp}
         centered={true}
         size={"lg"}
         fullscreen={true}
         className="clt_footer_bottom_up_modal"
         contentClassName="clt_footer_bottom_up_modal_content"
         dialogClassName="clt_footer_bottom_up_modal_dailog"
       >
         {bottomUp && selectedReview && (
             <div className="d-flex justify-content-between align-items-start clt-modal-body-review" style={{ flexDirection: 'column' }}>
             <div className="clt-detail-left-section-4-card-item-div174" style={{ backgroundImage: `url(${selectedReview.profile_photo_url})` }}></div>
             <h2 className="clt-detail-left-section-4-card-item-h2">{selectedReview.author_name}</h2>
       
             <div className="clt-detail-left-section-4-card-item-stars">
                            {renderStars(selectedReview.rating)}
                            <span className="calendar-icon">
                              <img className="icon-calendar-review" src={icon_calendar} alt="Custom Calendar Icon" />
                            </span>
                            <span className={isDetail ? 'clt-detail-left-section-4-card-item-ratingkjdcnlksdn calendar-text' : 'clt-detail-left-section-4-card-item-rating calendar-text'} >
                              {formatDate(selectedReview.time)}
                            </span>
                          </div>
       
             <p className={`clt-detail-left-section-4-card-item-desc clt-detail-left-section-4-card-item-desc174 ${selectedReview.text.split('\n').length > 5 ? 'truncated' : ''}`}>
               {selectedReview.text}
             </p>
       
           </div>
         )}
       
       </FooterBottomUp>
      )}

{!useFooterBottomUp && selectedReview && (
  <Modal centered show={customOffCanvasOpen} onHide={() => setCustomOffCanvasOpen(false)}>
    
    <div className="justify-content-start clt_products_offcanvas_header offcanvas-header">
      <button
        type="button"
        className="btn-close shadow-none"
        aria-label="Close"
        onClick={() => setCustomOffCanvasOpen(false)}
        style={{ marginLeft: '22px' }}
      ></button>
      <div className="text-center flex-grow-1"> {/* Use flex-grow-1 to make the content in the center */}
        
      <h2 className="clt-detail-left-section-4-card-item-h2" style={{ marginRight: '30px', marginBottom: '8px', marginTop: '6px' }}>Avis</h2>


      </div>
    </div>

    <Modal.Body>
      <div className="d-flex justify-content-between align-items-start" style={{ flexDirection: 'column', padding: '24px' }}>
        <div className="clt-detail-left-section-4-card-item-div174" style={{ backgroundImage: `url(${selectedReview.profile_photo_url})` }}></div>
        <h2 className="clt-detail-left-section-4-card-item-h2">{formatAuthorName(selectedReview.author_name)}</h2>

        <div className="clt-detail-left-section-4-card-item-stars">
                            {renderStars(selectedReview.rating)}
                            <span className="calendar-icon">
                              <img className="icon-calendar-review" src={icon_calendar} alt="Custom Calendar Icon" />
                            </span>
                            <span className={isDetail ? 'clt-detail-left-section-4-card-item-ratingkjdcnlksdn calendar-text' : 'clt-detail-left-section-4-card-item-rating calendar-text'}>
                              {formatDate(selectedReview.time)}
                            </span>
                          </div>

        <p className={`clt-detail-left-section-4-card-item-desc clt-detail-left-section-4-card-item-desc174 ${selectedReview.text.split('\n').length > 5 ? 'truncated' : ''}`}>
          {selectedReview.text}
        </p>
      </div>
    </Modal.Body>
  </Modal>
)}








    </>
  );
};

function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}

export default NewSection6;
