import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import BackArrowRight from "../../assets/img/back_arrow_right.png";
import HelpIcon from "../../assets/img/admin_icon_menu_3.png";
import "./helpCenter.css";
import ContactUsScreen from "../../components/screens/home/ContactUs/ContactUsScreen";
import SectionSearchHelpCenter from "../../components/reusable/search/Search - Helpcenter/[SEARCH] Helpcenter";
import { getAllArticlesWithCategory, getAllCategoryWithPaths } from "./api";
import Article from "../../components/screens/helpCenter/Article";

import { Helmet } from "react-helmet";
import { LoaderDots } from "@thumbtack/thumbprint-react";
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from "../../redux/actions/avent";
import { useHistory } from "react-router-dom";

function HelpCenter() {
  const [isLoading, setIsLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [articles, setArticles] = useState({});
  const [selectedType, setSelectedType] = useState("");

  const [article, setArticle] = useState(null);
  const [isOpenArticle, setIsOpenArticle] = useState(false);
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const event = useSelector((state) => state.event.event);
  const events = useSelector((state) => state.allEvents.events);


  const handleArticle = (article, content) => {
    setArticle({ ...article, content: content });
    setIsOpenArticle(true);
  };

  const handleType = (cat) => {
    setSelectedType(cat);
  };
  const getCategories = async () => {
    setIsLoading(true);
    const res = await getAllCategoryWithPaths();
    if (res.success) {
      setCategories(Object.values(res.data));
      setSelectedType(Object.values(res.data)[0]);
      getArticles(Object.values(res.data).map((cat) => cat.id));
      console.log("res.data", res.data);
    } else {
      console.log("error");
    }
    setIsLoading(false);
  };
  const getArticles = async (ids) => {
    const res = await Promise.all(
      ids.map(async (id) => {
        const res = await getAllArticlesWithCategory(id);
        if (res.success) {
          return { [id]: Object.values(res.data) };
        } else {
          console.log("error");
        }
      })
    );
    const convertedObject = res.reduce((result, item) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});

    setArticles(convertedObject);
  };

  useEffect(() => {
    getCategories();

    // getArticles();
  }, []);

  useEffect(() => {
    if (Object?.keys(event)?.length === 0) {
      if (events?.length === 0) {
        dispatch(getEvents());
      }
    }
  }, [event, history, events]);

  return (
    <div>
      <Helmet>
        <title>Centre aide</title>
        <meta name="title" content="Centre aide" />
        <meta name="description" content="Appeler le château / Poser une question en ligne / Guide" />
        <link rel="icon" href={HelpIcon} />
        <meta property="og:title" content="Obtenir de l'aide - Centre aide" />
        <meta
          property="og:description"
          content="Appeler le château / Poser une question en ligne / Guide"
        />
        <meta property="og:image" content="clt_gallery1" />
        <meta property="og:url" content="https://chateaulatournelle.com/aide" />
        <meta name="keywords" content="Château Latournelle, Toulouse, Mariage, Aide, assistance, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire" />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "mainEntity": {
              "@type": "ItemList",
              "itemListElement": {
                "@type": "WebPage",
                "name": "Obtenir de l'aide - Centre aide",
                "description": "Appeler le château / Poser une question en ligne / Guide",
                "image": "../../assets/img/admin_icon_menu_3.png",
                "url": "https://chateaulatournelle.com/aide"
              }
            }
          }`}
        </script>
      </Helmet>
      {/* <Navbar title="Help Center"/> */}
      <div className="overflow-auto clt-helpcenter-min-h-100">
        <div className="container py-4">
          <h2 className="clt-helpcenter-title-1">Bienvenue dans le centre d'aide</h2>
          <SectionSearchHelpCenter
            articles={
              articles &&
              selectedType &&
              selectedType.id &&
              articles[selectedType.id]
            }
          />

          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "50vh" }}
            >
              <LoaderDots theme="muted" />
            </div>
          ) : (
            <>
              <ul className="clt-helpcenter-tabBar nav nav-pills">
                <li className="nav-item">
                  {categories?.map((category) => {
                    return (
                      <button
                        className={`btn ${selectedType.id === category.id && "active"
                          }`}
                        onClick={() => handleType(category)}
                      >
                        {category.name}
                      </button>
                    );
                  })}
                </li>
              </ul>
              <c className="clt-helpcenter-h4-c">Aide</c>
              <h4 className="clt-helpcenter-h4">Comment pouvons-nous vous aider ?</h4>
              <b className="clt-helpcenter-text-dark">
                {selectedType?.subtitle}
              </b>
              {selectedType?.paths && selectedType?.paths.length > 0 && (
                <div className="clt-helpcenter-pathList mt-3">
                  {selectedType?.paths?.map((path) => {
                    return path.articleId ? (
                      <a
                        href={`#`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleArticle(path.article, path.articLeContent);
                        }}
                        className="myItem"
                      >
                        {path.path}
                        <img
                          className="clt-helpcenter-right-arrow"
                          alt="clt-helpcenter-right-arrow-path-1.1"
                          src={BackArrowRight}
                        />
                      </a>
                    ) : (
                      <a
                        href={`/aide/path?path=${path.path}&id=${path.id}`}
                        className="myItem"
                      >
                        {path.path}
                        <img
                          className="clt-helpcenter-right-arrow"
                          alt="clt-helpcenter-right-arrow-path-1.1"
                          src={BackArrowRight}
                        />
                      </a>
                    );
                  })}
                </div>
              )}
              <div className="border_top_light">
                <div
                  className="clt-section-help-contact"
                  style={{
                    backgroundColor: `#fff`,
                  }}
                >
                  <c>Contact</c>
                  <h2>Nous contacter</h2>
                  <p>Obtenir de l'aide avec l'équipe du château</p>
                  <Button
                    onClick={() => setOpen(true)}
                    className="clt-button-text21245 shadow-none"
                  >
                    <span>Nous contacter</span>
                  </Button>
                </div>
              </div> 
              {/*
                <div className="border_top_light">
                  <div className="clt-section-7">
                    <h2 className="clt-helpcenter-h4">Nous contacter</h2>
                    <p classname="clt-helpcenter-text-dark">
                      Le château est disponible pour répondre à vos questions.
                    </p>
                    <Button
                      onClick={() => console.log("clicked!")}
                      className="clt-section-7-btn shadow-none"
                    >
                      <span>
                        Nous contacter{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </Button>
                  </div>
                </div>*/}
            </>
          )}
        </div>
      </div>
      {article && (
        <Article
          article={article}
          isOpenArticle={isOpenArticle}
          onClose={() => setIsOpenArticle(false)}
        />
      )}
      { open ? <ContactUsScreen isOpen={open} setIsOpen={setOpen} /> : null }
    </div>
  );
}

export default HelpCenter;
