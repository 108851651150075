import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";

import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import ArticlesList from "./ArticlesList";
import EditArticle from "./EditArticle";
import CreateArticle from "./CreateArticle";
import {
  createArticle,
  deleteArticle,
  getAllArticles,
  getAllCategories,
  updateArticle,
} from "../api";
import { ErrorToast } from "../../../../../reusable/Toast";

function ManageArticle({ isOpen, onClose, heading, category }) {
  const [articles, setArticles] = useState([
    // { name: "Help article 1", tag: "Group tag" },
    // { name: "Help articl 2", tag: "Group tag" },
  ]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [openEditArticle, setOpenEditArticle] = useState(false);
  const [openCreateArticle, setOpenCreateArticle] = useState(false);

  useEffect(() => {
    console.log("TEST", category);
    if (category) {
      getArticles(category.id);
    }
  }, [category]);

  const createNewArticle = async (article) => {
    const res = await createArticle(article);
    if (res.success) {
      getArticles(category.id);
      setOpenCreateArticle(false);
    } else {
      ErrorToast("Une erreur est survenue", "Veuillez essayé de supprimer l'article à nouveau");
      
    }
  };
  const updateArt = async (id, article) => {
    const res = await updateArticle(id, article);
    if (res.success) {
      getArticles(category.id);
      setOpenEditArticle(false);
    } else {
      ErrorToast("Une erreur est survenue", "Veuillez essayé d'enregister l'article à nouveau");
    }
  };
  const deleteArt = async (article) => {
    const res = await deleteArticle(article.id);
    if (res.success) {
      getArticles(category.id);
    } else {
      ErrorToast("Une erreur est survenue", "Veuillez essayé de supprimer l'article à nouveau");
    }
  };

  const getArticles = async (id) => {
    const res = await getAllArticles(id);
    console.log("AMMAR", res);
    if (res.success) {
      setArticles(Object.values(res.data));
    } else {
      ErrorToast("Unable to fetch articles");
    }
  };
  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      backdrop={false}
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Article - {heading}</Offcanvas.Title>

            <Button
              className="clt_admin_dash_manage_access_header_button shadow-none"
              onClick={() => setOpenCreateArticle(true)}
            >
              Add
            </Button>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className="clt_help_article_offcanvas_body">
        <ArticlesList
          list={articles}
          deleteArt={deleteArt}
          openEditArticle={(article) => {
            setSelectedArticle(article);
            setOpenEditArticle(true);
          }}
        />
        <EditArticle
          isOpen={openEditArticle}
          onClose={() => setOpenEditArticle(false)}
          article={selectedArticle}
          updateArt={updateArt}
        />
        <CreateArticle
          isOpen={openCreateArticle}
          onClose={() => setOpenCreateArticle(false)}
          category={category}
          createNewArticle={createNewArticle}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ManageArticle;
