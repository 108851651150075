import axios from "axios";
import { API_ENDPOINT } from "../../../../../constants";
import { auth } from "../../../auth/firebase";

export const PROFILE_KEY = "profile";

export const updateBusinessInformationRequest = async (data) => {
	const token = await auth.currentUser.getIdToken();
	console.log({data});
	return axios.patch(API_ENDPOINT + "/admins/profile", data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const getAdminProfile = async () => {
	const token = await auth.currentUser.getIdToken();
	return axios.get(API_ENDPOINT + "/admins/me",  {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const uploadProfilePicRequest = async (data) => {
	const token = await auth.currentUser.getIdToken();
	return axios.post(
		API_ENDPOINT + "/admins/image",
		data,
		{ headers: { Authorization: `Bearer ${token}` } }
	);
}