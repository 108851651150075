import React, { useEffect, useState } from "react";
import "./helpCenter.css";
import BackArrowRight from "../../assets/img/back_arrow_right.png";
import InnerNavbar from "../../layouts/InnerNavbar";
import Article from "../../components/screens/helpCenter/Article";
import {
  useLocation,
  useParams,
  useNavigate,
  useHistory,
} from "react-router-dom";
import { getPathById } from "./api";
import { LoaderDots } from "@thumbtack/thumbprint-react";

function PathDetail() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const nameIdPairs = [];
  queryParams?.forEach((value, key) => {
    nameIdPairs.push(key);
    nameIdPairs.push(value);
  });

  const [isLoading, setIsLoading] = useState(false);

  const [pathDetails, setPathDetails] = useState(null);

  const [article, setArticle] = useState(null);
  const [isOpenArticle, setIsOpenArticle] = useState(false);

  const getDetail = async (id) => {
    setIsLoading(true);
    const res = await getPathById(id);
    if (res.success) {
      setPathDetails(res.data);
    } else {
      console.log("error");
    }
    setIsLoading(false);
  };

  const handleArticle = (article) => {
    console.log("TEST", article);
    setArticle({ ...article, content: article.articleContent });
    setIsOpenArticle(true);
  };

  useEffect(() => {
    getDetail(parseInt(nameIdPairs[nameIdPairs.length - 1]));
  }, []);

  return (
    <div>
      <InnerNavbar
        title={pathDetails?.path}
        backClick="/aide"
        navigate={history}
      />
      <div className="overflow-auto clt-helpcenter-min-h-100">
        <div className="container py-4">
          <h4 className="clt-helpcenter-h4">{pathDetails?.article?.name}</h4>
          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "50vh" }}
            >
              <LoaderDots theme="muted" />
            </div>
          ) : (
            pathDetails?.subpaths &&
            pathDetails?.subpaths.length > 0 && (
              <div className="clt-helpcenter-pathList mt-3">
                {pathDetails?.subpaths?.map((subpath) => {
                  return subpath.articleId ? (
                    <p
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleArticle({
                          ...subpath.article,
                          articleContent: subpath.articleContent,
                        });
                      }}
                      className="myItem"
                    >
                      {subpath.path}{" "}
                      <img
                        className="clt-helpcenter-right-arrow"
                        alt="back-arrow-right-path-1"
                        src={BackArrowRight}
                      />
                    </p>
                  ) : (
                    <a
                      href={`${location.search}&path=${subpath.path}&id=${subpath.id}`}
                      className="myItem"
                    >
                      {subpath.path}{" "}
                      <img
                        className="clt-helpcenter-right-arrow"
                        alt="back-arrow-right-path-1"
                        src={BackArrowRight}
                      />
                    </a>
                  );
                })}
              </div>
            )
          )}
        </div>
      </div>
      <Article
        article={article}
        isOpenArticle={isOpenArticle}
        onClose={() => setIsOpenArticle(false)}
      />
    </div>
  );
}

export default PathDetail;
