import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import icon from '../../../assets/img/icon_euro.png';
const SectionRemainingPayment = ({
  image,
  title,
  paymentTexts,
  onClick,
  buttonText,
}) => {
  return (
    <Row>
      <Col onClick={onClick} className="clt-booking-cp-div4">
        <div className="d-flex justify-content-start align-items-center clt-booking-cp-div3">
        <i class="fa-solid fa-euro-sign" style={{fontSize:'24px'}}></i>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>{title}</h2>
              {paymentTexts?.map((paymentText, index) => {
                return <p key={index}>{paymentText}</p>;
              })}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionRemainingPayment;
