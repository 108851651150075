import React, { useRef, useState } from "react";
import {Button} from "react-bootstrap";
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {saveCard} from "../../../redux/actions/booking";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import { useDispatch } from "react-redux";

const CheckoutForm = ({savecardcomplete}) => {
    const stripe = useStripe();
    const [isLoadingDots, setLoadingDots] = useState(false);
    const elements = useElements();

    const dispatch = useDispatch()
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements || isLoadingDots) {
          return;
        }
    
        try {
          // Mettez isLoadingDots à true avant de démarrer la requête
          setLoadingDots(true);
    
          const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: "https://example.com/account/payments/setup-complete",
            },
            redirect: "if_required",
          });
    
          if (result.error) {
            console.log(result.error.message);
          } else {
            console.log(result);
            await dispatch(saveCard(result));
            savecardcomplete();
          }
        } catch (error) {
          console.error("Erreur lors de la confirmation de la configuration de Stripe", error);
        } finally {
          // Quelle que soit la fin de la requête (réussie ou échouée), mettez à jour isLoadingDots
          setLoadingDots(false);
        }
      };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="pfr_payment_space_bottom" />
            <div className="clt_payment_footer">
                <Button type="submit" className="shadow-none" disabled={isLoadingDots}>
                    {isLoadingDots ? (
						<LoaderDots /> 
					) : (
						"Ajouter"
					)}
                </Button>
            </div>
        </form>
    );
};

export default CheckoutForm;