import React, { useContext, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import "./event.css";
import BackArrow from "../../../assets/img/back_arrow.png";
import {
  eventbirthdayData,
  IMG_ALT,
  eventweddingData,
  eventprofessionalData,
  eventreligiousData,
} from "../../../constants";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEvent } from "../../../redux/actions/avent";
import { AuthContext } from "../auth/authContext";

const Event = ({ show, onHide, eventType, onConfirmEvent, allEvents, handleDateEdit }) => {
  const [eventData, setEventData] = useState([]);
  const { setSelectedDate, setSelectedPrice, setSelectedTimeSlot, setBookingScreens, guestCount, setGuestCount, guestCountInput, setGuestCountInput, setSelectedProfile, setIsPaymentCardSelected, setSelectedPlan, setBedCount, setSelectTotalRooms, selectedRoomData, setSelectedRoomData } = useContext(AuthContext);
  // eslint-disable-next-line no-unused-vars
  const [eventList, setEventList] = useState([
    eventweddingData,
    eventbirthdayData,
    eventprofessionalData,
    eventreligiousData,
  ]);
  const [selectedEvent, setSelectedEvent] = useState(eventType);
  const handleEventConfirm = () => {
    onConfirmEvent(selectedEvent);
    handleDateEdit();
    onHide();
    handleGetEvent(eventData);
    setSelectedDate({
      start: null,
      end: null,
    });
    setSelectedPrice(null);
    setSelectedTimeSlot({})
    //do handler
    setGuestCount(0);
    setGuestCountInput(0);
    // window.location.reload();
    setSelectedProfile({
      type: "",
      detail: "",
    })
    setBedCount([]);
    setSelectedPlan({})
    setIsPaymentCardSelected({})
    setSelectedRoomData([]);
  };

  const history = useHistory();
  const dispatch = useDispatch();


  const handleGetEvent = (event) => {
    dispatch(getEvent(event.id));

    history.push(`/detail/${event.title}`);
    window.scrollTo(0, 0);

  };


  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_products_offcanvas"
        show={show}
        onHide={onHide}
      >
        <Offcanvas.Header className="justify-content-start clt_products_offcanvas_header">
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onHide}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <Offcanvas.Title>Event</Offcanvas.Title>
          </React.Fragment>
        </Offcanvas.Header>
        <Offcanvas.Body className="clt_products_offcanvas_body clt-datepicker-sidebar">
          <>
            <Row>
              <Col>
                <h2 className="clt-datepicker-sidebar-h2">
                  Sélectionnez un évenement
                </h2>
              </Col>
            </Row>
            {allEvents?.filter(x => (x.isActive === true)).map((eventData, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <div
                      className="d-flex flex-row align-items-center justify-content-between clt-event-sidebar-eventSelect-mainDiv"
                      onClick={() => {

                        setEventData(eventData)
                        setSelectedEvent(eventData)
                      }}
                      style={
                        isEqual(selectedEvent, eventData)
                          ? {
                            borderColor: `#000`,
                          }
                          : {
                            borderColor: `#ddd`,
                          }
                      }
                    >
                      <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                        <h2>{eventData.title}</h2>
                      </div>
                      <div
                        className="inner-div-circle"
                        style={
                          isEqual(selectedEvent, eventData)
                            ? {
                              borderColor: `#000`,
                            }
                            : {
                              borderColor: `#ddd`,
                            }
                        }
                      >
                        <div
                          className="subDiv"
                          style={
                            isEqual(selectedEvent, eventData)
                              ? {
                                background: `#000`,
                              }
                              : {
                                background: `transparent`,
                              }
                          }
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
            <Row className="clt-datepicker-sidebar-footer-space" />
          </>
        </Offcanvas.Body>
        <div>
          <div className="clt-datepicker-sidebar-footer">
            <div className="inner-div clt-booking-gc-div2-btn">
              <Button onClick={handleEventConfirm} className="shadow-none">
                <span>Confirmer</span>
              </Button>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default Event;
