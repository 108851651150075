// metaData.js
const metaData = {
    default: {
      title: 'Default',
      description: 'Default Description',
      image: '../../../assets/img/icon-bar-1-active.png',
      url: 'https://chateaulatournelle.com/detail',
      keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Mariage:{
        title: 'Mariage',
        description: "Retrouvez le détail d'un évenement 'Mariage' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-1-active.png',
        url: 'https://chateaulatournelle.com/detail/Mariage',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Séminaire:{
        title: 'Séminaire',
        description: "Retrouvez le détail d'un évenement 'Séminaire' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-2-active.png',
        url: 'https://chateaulatournelle.com/detail/Séminaire',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    'Repas noël':{
        title: 'Repas noël',
        description: "Retrouvez le détail d'un évenement 'Repas noël' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-noel.png',
        url: 'https://chateaulatournelle.com/detail/Repas%20noël',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Anniversaire:{
        title: 'Anniversaire',
        description: "Retrouvez le détail d'un évenement 'Anniversaire' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-3-active.png',
        url: 'https://chateaulatournelle.com/detail/Anniversaire',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Baptème:{
        title: 'Baptème',
        description: "Retrouvez le détail d'un évenement 'Baptème' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-4-active.png',
        url: 'https://chateaulatournelle.com/detail/Baptême',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Convention:{
        title: 'Convention',
        description: "Retrouvez le détail d'un évenement 'Convention' au Château Latournelle.",
        image: '../../../assets/img/conference-de-presse.png',
        url: 'https://chateaulatournelle.com/detail/Convention',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Soirée:{
        title: 'Soirée',
        description: "Retrouvez le détail d'un évenement 'Soirée' au Château Latournelle.",
        image: '../../../assets/img/icon-party.png',
        url: 'https://chateaulatournelle.com/detail/Soirée',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Repas:{
        title: 'Repas',
        description: "Retrouvez le détail d'un évenement 'Repas' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-repas.png',
        url: 'https://chateaulatournelle.com/detail/Repas',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
    Cousinade:{
        title: 'Cousinade',
        description: "Retrouvez le détail d'un évenement 'Cousinade' au Château Latournelle.",
        image: '../../../assets/img/icon-bar-cousinade.png',
        url: 'https://chateaulatournelle.com/detail/Cousinade',
        keywords: "Château Latournelle, Toulouse, Mariage, Aide, Séminaire, Seminaire, Convention, Soirée, Baptème, Repas, Cousinade, assistance, noël, Lieu de Mariage, Événements, Célébrations, Luxe, Historique, Château Français, Réception, Mariages dans le Jardin, Élégance, Lieu Romantique, Événements Exclusifs, Mariage de Destination, Location de Château, Planification d'Événements, Location de Salle, Beaux Environs, Cérémonie de Château, Cadre Pittoresque, Forfaits Mariage, Hébergement au Château, Événements Spéciaux, Mariages en Plein Air, Événements en Intérieur, Fine Cuisine, Histoire du Château, Atmosphère Élégante, Mariages de Conte de Fées, Jardins du Château, Lieu Unique, Grandeur, Événements Culturels, Occasions Mémorables, Point de Repère à Toulouse, Célébrations Privées, Événements d'Anniversaire",
    },
  };
  
  export default metaData;
  