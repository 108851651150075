import React, { useState, useEffect, useMemo, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { Offcanvas, Button, Spinner, Row, Col } from 'react-bootstrap';
import BackArrow from "../../../../assets/img/back_arrow.png";
import '../[SIDEBAR] Search.css';
import SearchIcon from "../../../../assets/img/home_searchbar_icon.png";
import { IMG_ALT } from "../../../../constants";
import { useDetectClickOutside } from 'react-detect-click-outside';
import debounce from "lodash.debounce";
import axios from 'axios';
import { API_ENDPOINT } from '../../../../constants';
import { auth } from '../../../screens/auth/firebase';
import { LoaderDots } from '@thumbtack/thumbprint-react';
import Account from '../../../screens/account/Account';

const SidebarSearchUser = ({ usersData, openSidebarSearchUserList, onCloseSidebarSearchUserList }) => {
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTrue, setIsTrue] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const ref = useDetectClickOutside({ onTriggered: () => setIsSearch(false) });
    const [data, setData] = useState([]);
    const formRef = useRef(null);

    const search = async (criteria) => {
        try {
            setIsLoading(true);
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + `/users/search/${encodeURIComponent(criteria)}`, { headers: { Authorization: `Bearer ${token}` } });
            setData(response.data.data);
            setIsLoading(false);
        } catch (error) {
            console.log('error: ', error);
            setIsLoading(false);
        }
    }

    const debouncedSearch = useRef(
        debounce((criteria) => {
            search(criteria);
        }, 500)
    ).current;

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        // You can add any additional logic here if needed
    }

    const handleChange = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if (e.target.value.trim()) {
            debouncedSearch(e.target.value.trim());
        } else {
            setData([]);
        }
    }

    const handleProfileUpdate = (val) => {
        setSelectedUser({ User: val });
        setData([]);
        formRef.current.reset();
    }

    return (
        <Offcanvas
            placement="end"
            className="clt_search_chat_user_offcanvas"
            show={openSidebarSearchUserList}
            onHide={onCloseSidebarSearchUserList}
        >
            <Offcanvas.Header className="justify-content-start clt_search_chat_user_offcanvas_header">
                <Button
                    type="button"
                    className="shadow-none btn-close btn-back"
                    aria-label="Close"
                    onClick={() => onCloseSidebarSearchUserList()}
                >
                    <img src={BackArrow} alt={IMG_ALT} />
                </Button>
                <div className="w-100">
                    <Form ref={formRef} onSubmit={handleSubmit} className="clt_search_chat_user_field_2">
                        <Form.Group className="clt_search_chat_user_field">
                            <Form.Control name='search' type="text" className={`form-control-search`} placeholder="Search" onChange={handleChange} />
                        </Form.Group>
                    </Form>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body className={`clt_search_chat_user_offcanvas_body ${(isLoading || data.length == 0) && `d-flex flex-column justify-content-center align-items-center`}`}>
                { /* Sidebar - Body - Result */}
                {
                    isLoading ?
                        <Row>
                            <Col>
                                <div className="d-flex align-items-center justify-content-center">
                                    <LoaderDots theme="muted" />
                                </div>
                            </Col>
                        </Row>
                    :
                    !isLoading && data.length == 0 ?
                        <Row>
                            <Col>
                                <div className="d-flex align-items-center justify-content-center">
                                    <h3>Aucun résultat</h3>
                                </div>
                            </Col>
                        </Row>
                    :
                    data.map(item => (
                        <div key={item.id}>
                            {/* Result - Container */}
                            <div onClick={() => { setSelectedUser({ User: item}); setIsTrue(true); }} className="clt_search_sidebar_container_box p-3 cursor-pointer">
                                {/* Result - Container - Avatar */}
                                <div className='clt_search_avatar_wrapper'>
                                {
                                    item?.profilePictureUrl ?
                                        <div className="clt_admin_dash_booking_image">
                                            <img src={item?.profilePictureUrl} alt={item?.firstName} />
                                        </div>
                                    :
                                        <div className="clt_admin_dash_booking_avatar">
                                            <div className='clt_admin_dash_booking_avatar_name'>{`${item.firstName[0].toUpperCase()}`}</div>
                                        </div>
                                }
                                </div>
                                {/* Result - Container - Detail */}
                                <div className="clt_admin_dash_booking_container_detail">
                                    <div className="clt_search_sidebar_container_box_name">{`${item.firstName} ${item.lastName}`}</div>
                                    <div className="subtitle">{`${item.phone} - ${item.email}`}</div>
                                </div>
                            </div>
                            <div className="clt_searchbar_hr"></div>
                        </div>
                    ))
                }   
            </Offcanvas.Body>

            {isTrue ? <Account
                isOpenSidebar={isTrue}
                setIsOpen={() => {
                    setIsTrue(true);
                }}
                onCloseSidebar={() => {
                    setIsTrue(false);
                }}
                selectProfile="admin"
                parentComp="userSearch"
                handleProfileUpdate={handleProfileUpdate}
                // selectedProfileData={selectedProfile}
                // setSelectedProfileData={setSelectedProfile}
                isTrue={isTrue}
                dataa={selectedUser}
            /> : null}
        </Offcanvas>
    );
}

export default SidebarSearchUser;
