import React, { useState, useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { Button, Col, Container, Row } from "react-bootstrap";
import clt_gallery1 from "../../assets/img/clt_gallery1.png";
import clt_gallery2 from "../../assets/img/clt_gallery2.png";
import clt_gallery3 from "../../assets/img/clt_gallery3.png";
import clt_gallery4 from "../../assets/img/clt_gallery4.jpg";
import clt_gallery5 from "../../assets/img/clt_gallery5.jpg";
import clt_gallery6 from "../../assets/img/clt_gallery6.jpg";
import clt_gallery7 from "../../assets/img/clt_gallery7.jpg";
import clt_gallery8 from "../../assets/img/clt_gallery8.jpg";
import clt_gallery9 from "../../assets/img/clt_gallery9.jpg";
import clt_gallery20 from "../../assets/img/clt_gallery20.jpg";
import clt_gallery21 from "../../assets/img/clt_gallery21.jpg";
import clt_gallery22 from "../../assets/img/clt_gallery22.jpg";
import clt_gallery23 from "../../assets/img/clt_gallery23.jpg";
import clt_gallery24 from "../../assets/img/clt_gallery24.jpg";
import clt_gallery25 from "../../assets/img/clt_gallery25.jpg";
import clt_gallery26 from "../../assets/img/event_laurene_steeve_4.png";
import clt_gallery27 from "../../assets/img/clt_gallery27.jpg";
import clt_gallery28 from "../../assets/img/clt_gallery28.jpg";
import clt_gallery29 from "../../assets/img/clt_gallery29.jpg";
import clt_gallery30 from "../../assets/img/clt_gallery30.jpg";
import clt_gallery31 from "../../assets/img/clt_gallery31.jpg";
import clt_gallery32 from "../../assets/img/clt_gallery32.jpg";
import clt_gallery33 from "../../assets/img/clt_gallery33.jpg";
import clt_gallery34 from "../../assets/img/clt_gallery34.jpg";
import clt_gallery35 from "../../assets/img/clt_gallery35.jpg";
import clt_gallery36 from "../../assets/img/clt_gallery36.jpg";
import clt_gallery37 from "../../assets/img/clt_gallery37.jpg";

import CustomCarousel from "./CustomCarousel";
import "../screens/detail/detail.css";
import { detailSecondarySliderResponsive } from "../../constants";

const NewSection2 = () => {
  const [secondaryGallery, setSecondaryGallery] = useState([
    { src: clt_gallery1, title: "Château", width: 1950, height: 1300 },
    { src: clt_gallery2, title: "Cour", width: 1950, height: 1300 },
    { src: clt_gallery3, title: "Salle de réception", width: 1950, height: 1300 },
    { src: clt_gallery20, title: "Suite", width: 1950, height: 1300 },
    { src: clt_gallery4, title: "Chambre", width: 1950, height: 1300 },
    { src: clt_gallery27, title: "Terasse", width: 1950, height: 1300 },
    { src: clt_gallery28, title: "Terasse", width: 1950, height: 1300 },
    { src: clt_gallery29, title: "Terasse", width: 1950, height: 1300 },
    { src: clt_gallery30, title: "Terasse", width: 1950, height: 1300 },
    { src: clt_gallery36, title: "Cour", width: 1950, height: 1300 },
    { src: clt_gallery33, title: "Cour", width: 1950, height: 1300 },
    { src: clt_gallery37, title: "Cour", width: 1950, height: 1300 },
    { src: clt_gallery31, title: "Cour", width: 1950, height: 1300 },
    { src: clt_gallery23, title: "Salle de réception", width: 1950, height: 1300 },
    { src: clt_gallery26, title: "Salle de réception", width: 1950, height: 1300 },
    { src: clt_gallery6, title: "Salle d'eau", width: 1950, height: 1300 },
    { src: clt_gallery7, title: "Douche", width: 1950, height: 1300 },
  ]);

  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <div className="d-flex justify-content-between clt-home-visite-h2-header dadzadas">
        <div>
          <h2 className="clt-home-visite-h23546534">{rest.head}</h2>
          <h2 className="clt-home-visite-h2">{rest.title}</h2>
        </div>
        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-section-3-cards-arrowBtn me-2"
                : "clt-section-3-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left zczdzzsce"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? "disable clt-section-3-cards-arrowBtn"
                : "clt-section-3-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right zczdzzsce"></i>
          </Button>
        </div>
      </div>
    );
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const openGallery = async (type, index) => {
    const lightboxGallery = await Promise.all(
      secondaryGallery.map(async (item) => {
        const img = new Image();
        img.src = item.src;
        await img.decode();
        return {
          src: img.src,
          name: item.name,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };

  return (
    <>
      <Container className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd dnedzkfesdndze">
            <h2 className="clt-home-visite-h2354653422">Introduction</h2>
            <h2 className="clt-home-visite-h2">Le château</h2>
          </Col>
        </Row>
      </Container>
      <Container className="clt-homepage-container border-bottomxx">
        <Row className="clt-detail-left-section-2-row" style={{ flexDirection: "row", marginBottom: "38px" }}>
          <Col className="clt-detail-left-section-2-col">
            <div className="d-flex flex-column">
              <CustomCarousel
                responsive={detailSecondarySliderResponsive}
                arrows={false}
                shouldResetAutoplay={false}
                slidesToSlide={1} // Adjust this value to control scroll distance
                renderButtonGroupOutside={true}
                customButtonGroup={<DetailMainSliderArrows />}
                draggable={true}
                swipeable={true}
                className="clt-detail-left-section-2-cards-div my-carousel"
              >
                {secondaryGallery.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start align-items-start clt-home-visite-h2-cards clt-detail-left-section-2-cards-main cursor-zoom-in"
                    style={{
                      backgroundImage: `url(${item.src})`,
                    }}
                    onClick={() => openGallery("secondary", index)}
                  >
                    <span>{item.title}</span>
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}

export default NewSection2;
