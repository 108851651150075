import React from 'react'
import { Button } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import SectionDepositRefund from '../../../../../reusable/booking/SectionDepositRefund';
import CustomHr from "../../../../../reusable/CustomHr";
import SectionSlotRefund from '../../../../../reusable/booking/SectionSlotRefund';
// import CustomHr from '../../../reusable/CustomHr';
import './[SIDEBAR] Admin - Booking - Cancellation.css'
import {damageCharge, refundCharge} from "../../../../../../redux/actions/avent";
import {SuccessToast, ErrorToast} from "../../../../../reusable/Toast";
import {useDispatch} from "react-redux";
import { API_ENDPOINT } from '../../../../../../constants';
import { auth } from '../../../../auth/firebase';
import axios from 'axios';


const CancelBooking = ({ booking, oriavailRefundamount,isOpenSidebarCancelBooking, setIsOpenSidebarCancelBooking, onCloseOpenSidebarCancelBooking,bookingId,orirefundamount, parent, setIsLoading, onCloseOpenSidebarCancelRequestSentAdmin }) => {

    const [selectedEvent, setSelectedEvent] = React.useState(0);
    const [amount, setAmount] = React.useState(false);
    const [refundamount = orirefundamount, setRefundAmount] = React.useState(false);
    const [availRefundamount = oriavailRefundamount, setavailRefundamount] = React.useState(false);
    const [selectedSubPayment,setSelectedSubPayment] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const handleInputChange = (event) => {
        setAmount(event.target.value)
    };
    const calldamageCharge = ()=>{
        // damageCharge
        dispatch(damageCharge(JSON.stringify({
            bookingId:bookingId,
            price:amount
        })));
        // setIsOpen();
    }

    const updateRecurringRefundPayment = (item,amount) =>{
        let currentData = selectedSubPayment;
        setSelectedSubPayment(updateOrPush(currentData ? currentData : [], item, amount) );
    }

    const updateOrPush = (array, idToUpdate, newName) => {
        const index = array.findIndex(obj => obj.item.invoice === idToUpdate.invoice);
        if (index !== -1) {
            array[index].amount = newName;
        } else {
            array.push({ item: idToUpdate, amount: newName });
        }
        return array;
    };

    const changeRefundAmount = (event) => {
        setRefundAmount(event.target.value)
    }
    const changeSubRefundAmount = (event,sub_payment) => {
        setRefundAmount(event.target.value);
        updateRecurringRefundPayment(sub_payment,event.target.value);
    }

    async function getAuthObject(){
        const token = await auth.currentUser.getIdToken();

        return { Authorization: `Bearer ${token}` }
    }

    const cancelBooking = async () => {
        console.log(selectedEvent);
        setLoading(true);
        let refData = {
            bookingId:bookingId,
            amount:selectedEvent===1?orirefundamount:refundamount,
            type:selectedEvent===1?'full':'partial',
            notificationData: {
                title: "Notification: Booking - Cancel",
                message: "The booking is cancel"
            }
        }
        console.log(selectedSubPayment);
        // throw new Error();
        if (booking.payment_id.startsWith("sub_") && selectedEvent === 2){
            const subs = [];
            for(let i=0; i < (selectedSubPayment?.length) ; i++) {
                subs.push({
                    invoice:selectedSubPayment[i]?.item?.invoice,
                    amount:selectedSubPayment[i]?.amount,
                });
            }
            refData.sub = subs;
        }

        if (booking.payment_id.startsWith("sub_") && selectedEvent === 1) {
            const subs = [];
            for(let i=0; i < (booking.subscription_payments?.length) ; i++) {
                subs.push({
                    invoice: booking.subscription_payments[i].invoice,
                    amount: booking.subscription_payments[i]?.subtotal
                });
            }
            refData.sub = subs;
        }
        if (selectedEvent===1){
            console.log(orirefundamount);
            setRefundAmount(orirefundamount);
        }
        const response = await fetch(`${API_ENDPOINT}/booking/refund-charge`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                ...( await getAuthObject())
            },
            body: (JSON.stringify(refData))
        });
        const result = await response.json();
        booking.refund_data = result.message.refund_data;
        // setBooking(booking);
        setavailRefundamount(result.message.refund_data);
        console.log('PCCC');
        console.log(availRefundamount);
        if (amount)
        calldamageCharge();
        SuccessToast("Remboursement effectué");
        if (parent && Object.keys(parent).length) {
            try {
                setIsLoading(true);
                
                const token = await auth.currentUser.getIdToken();
                const response = await axios.patch(API_ENDPOINT + `/booking/request/update`, 
                  { 
                    id: parent.id,
                    status: 'Accept',
                    notificationData: {
                      title: "Notification: Cancel Request - Accept",
                      message: "The request is accepted"
                    }
                  },
                  { headers: { Authorization: `Bearer ${token}` } }
                );
                setIsLoading(false);
                setLoading(false);
                SuccessToast("The request has been accepted");
                onCloseOpenSidebarCancelRequestSentAdmin();
            } catch (error) {
                setIsLoading(false);
                setLoading(false);
                console.log('location on here: ', error);
                ErrorToast(error?.response?.data?.message);
            }
        } else {
            setLoading(false);
            onCloseOpenSidebarCancelBooking();
        }
    }

    // setRefundAmount(orirefundamount);

    const onBack = () => {
        onCloseOpenSidebarCancelBooking()
    }

    return (
        <div>
            <Offcanvas
                placement="end"
                className="clt_admin_dash_select_refund_booking_offcanvas"
                show={isOpenSidebarCancelBooking}
                onHide={onCloseOpenSidebarCancelBooking}
            >
                <Offcanvas.Header className="justify-content-start clt_admin_dash_select_refund_booking_offcanvas_header">

                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={onCloseOpenSidebarCancelBooking}
                    >
                        <img
                            src={BackArrow}
                            className=""
                            alt={IMG_ALT}
                        />
                    </button>

                    <Offcanvas.Title>Annuler une réservation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="clt_admin_dash_select_refund_booking_offcanvas_body">
                    <div className='clt_admin_dash_select_refund_booking_box_field'>
                        {/* <SectionDepositRefund
                        title={"Caution"}
                        handleInputChange={handleInputChange}
                        />
                        <CustomHr colClass="clt-admin-booking-admin-booking-hr-pd" hrClass="p-0 m-0 clt-admin-booking-hr" /> */}
                        <SectionSlotRefund
                            booking={booking}
                            orirefundamount={orirefundamount}
                            availRefundamount={availRefundamount}
                            setSelectedSubPayment={changeSubRefundAmount}
                            changeRefundAmount={changeRefundAmount}
                            selectedEvent={selectedEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </div>
                    <div className="customFooterButton w-100">
                        <hr />
                        <Button
                            onClick={() => cancelBooking()}
                            className="clt_admin_dash_select_refund_booking_formButton shadow-none"
                            disabled={selectedEvent === 0 || loading ? true : false}
                        >
                            <span>Annuler réservation</span>
                        </Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </div>
    )
}

export default CancelBooking