import React, { useState, useEffect } from "react";
import {
    // Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { API_ENDPOINT, IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] Admin - Contact request.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import MessageIcon from "../../../../../../assets/img/chat_icon.png";
import { auth } from '../../../../auth/firebase';
// import ConfirmPay4 from "../../../../assets/img/admin_icon_booking_detail_3.png";
import CustomHr from "../../../../../reusable/CustomHr";
import axios from "axios";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionContactRequestDate from "../../../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestEvent from "../../../../../reusable/contactrequest/SectionContactRequestEvent";
import SectionContactRequestMessage from "../../../../../reusable/contactrequest/SectionContactRequestMessage";
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import SectionInformationMessageRequestSent from "../../../../../reusable/SectionInformationMessageRequestSent.js";
import { ErrorToast, SuccessToast } from "../../../../../reusable/Toast";
import SectionEvent from "../../../../../reusable/booking/SectionEvent";
import SectionUser from "../../../../../reusable/booking/SectionUser";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetail";
// import SectionDamageDeposit from "../../../reusable/booking/SectionDamageDeposit";
// import { Link } from "react-router-dom";
import Guest from "../../../../guest/Guest";
import Account from "../../../../account/Account";
import Booking from "../../../../detail/booking/booking_user";
// import SectionContact from "../../../reusable/booking/SectionContact";
import { useAuth } from "../../../../auth/authContext";

const initialBookingScreens = {
    noBooking: true,
    bookingList: false,
    bookingDetail: false,
    cancelBooking: false,
};

const ChatSidebarContactRequestInProgressAdmin = ({ hideSidebar, socket, contactRequest }) => {

    const [latestContactRequest, setLatestContactRequest] = useState(null);
    const [eventType, setEventType] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [guestCount, setGuestCount] = useState(null);

    const [, setBookingScreens] = useState(initialBookingScreens);

    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [showAccountSidebar, setShowAccountSidebar] = useState(false);
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);

    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com",
        profilePic: null,
        name: "John Doe",
    });

    const [chatUser, setChatUser] = useState(null);

    const handleDateEdit = () => {
        setIsBookingSidebar(true);
    };

    useEffect(() => {
        setLatestContactRequest(contactRequest)
        setSelectedProfile({ 
            type: "Personal", 
            detail: `${contactRequest?.User?.firstName} ${contactRequest?.User?.lastName} · ${contactRequest?.User?.phone} · ${contactRequest?.User?.email}`, 
            profilePic: contactRequest?.User?.profilePictureUrl, 
            name: `${contactRequest?.User?.firstName} ${contactRequest?.User?.lastName}`})

        setChatUser(contactRequest?.User)
    }, [contactRequest])

    useEffect(() => {

        const handleContactRequestUpdated = (request) => {
            if(latestContactRequest && latestContactRequest.id !== request.id) {
                return;
            }
            setLatestContactRequest(request);
        }

        socket.on("contactRequestUpdated", handleContactRequestUpdated)

        return () => {
            socket.off("contactRequestUpdated", handleContactRequestUpdated)
        }
    }, [socket])

    const handleCancelBooking = () => {
        setBookingScreens((prevState) => ({
            ...prevState,
            bookingDetail: false,
            cancelBooking: true,
        }));
    };

    const handleRequestEdit = async (dataToSend) => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    ...dataToSend
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast(" La demande a été mise à jour");
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    const handleRefuse = async () => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    status: "Cancel",
                    notificationData: {
                        title: `Notification: Demande de contact annulée par ${latestContactRequest?.User?.firstName} ${latestContactRequest?.User?.lastName}`,
                        message: `La demande de contact a été annulée par le château ou ${latestContactRequest?.User?.firstName}`,
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("La demande a été annulé")
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    const handleTicketResolve = async () => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    status: "Accept",
                    notificationData: {
                        title: "Notification: Contact request - Accept",
                        message: "The ticket is solve"
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    return (
        <>
            <div className="clt_message_user_chat_offcanvas">
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        Demande de contact
                    </div>
                </div>

                <div className="clt_message_user_request_offcanvas_body">

                    {latestContactRequest ? (
                        <>
                        <h2 className="clt-mybooking-title">Utilisateur</h2>
                        <SectionUser
                            handleClick={() => setShowAccountSidebar(true)}
                            text={`${selectedProfile.type} profile`}
                            title="User"
                            subText={
                                <span>{selectedProfile.detail}</span>
                            }
                            profilePic={selectedProfile.profilePic}
                            name={selectedProfile.name}
                            isClassActive={true}
                        />
                        </>
                    ) : null}
                </div>
                <CustomHr
                rowClass="p-0 m-0"
                colClass="clt-booking-hr-pd p-0 w-100"
                hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_message_user_request_offcanvas_body">
                    <>
                        <h2 className="clt-booking-cp-div1-title">Demande de contact</h2>
                        <SectionTop
                            image={ConfirmPayProfilePicture1}
                            title="Lieu"
                            subTitle="Château Latournelle"
                        />

                        {latestContactRequest ? (
                            <>
                                <SectionContactRequestEvent
                                    image={ConfirmPay1}
                                    subText={`${latestContactRequest.Event.title}`}
                                    buttonText="Edit"
                                    IsEventButtonDropdown={true}
                                    eventType={eventType}
                                    event={latestContactRequest.Event}
                                    setEventType={setEventType}
                                    handleRequestEdit={handleRequestEdit}
                                />

                                <SectionContactRequestDate
                                    image={ConfirmPay2}
                                    subText={`${latestContactRequest.month} ${latestContactRequest.year}`}
                                    IsDateButtonDropdown={true}
                                    selectedMonth={selectedMonth}
                                    setSelectedMonth={setSelectedMonth}
                                    selectedYear={selectedYear}
                                    setSelectedYear={setSelectedYear}
                                    handleRequestEdit={handleRequestEdit}
                                />

                                <SectionContactRequestGuest
                                    image={ConfirmPay3}
                                    subText={`${latestContactRequest.guest_number}`}
                                    IsGuestButtonDropdown={true}
                                    guestCount={guestCount}
                                    setGuestCount={setGuestCount}
                                    handleRequestEdit={handleRequestEdit}
                                // subText={`${guestCount} guests`}
                                // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                                // buttonText="Edit"
                                />

                                <SectionContactRequestMessage
                                    image={MessageIcon}
                                    subText={`${latestContactRequest.message}`}
                                />

                                <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                                <Row>
                                    <Col>
                                        <div className="clt-booking-btn-refuse-div">
                                            <Button
                                                className="clt-booking-btn-refuse"
                                                onClick={handleTicketResolve}
                                            >
                                                <span>Demande résolue</span>
                                            </Button>
                                        </div>
                                    
                                        <div className="clt-booking-btn-refuse-div">
                                            <Button
                                                className="clt-booking-btn-refuse"
                                                onClick={handleRefuse}
                                            >
                                                <span>Annuler demande</span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                        
                    </>
                </div>
            </div>

            {showAccountSidebar && 
                <Account
                    isOpenSidebar={showAccountSidebar}
                    setIsOpen={() => {
                        setShowAccountSidebar(true);
                    }}
                    onCloseSidebar={() => {
                        setShowAccountSidebar(false);
                    }}
                    selectProfile="admin"
                    isTrue={true}
                    dataa={{ User: chatUser}}
                />
            }

            {/* <Guest
                isGuestSidebar={isGuestSidebar}
                editGuestValue={guestCount}
                onCloseGuestSidebar={(guestCount) => {
                    setIsGuestSidebar(false);
                    setGuestCount(guestCount);
                }}
            />

            <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="admin"
                selectedProfileData={selectedProfile}
                setSelectedProfileData={setSelectedProfile}
                setIsUserSidebar={() => setIsBookingSidebar(true)}
            />

            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={() => setIsBookingSidebar(false)}
                eventType={eventType}
                isFromBooking={true}
                isFromBookingDate={{
                    startDate: new Date(),
                    endDate: new Date(),
                }}
                isFromBookingTimeSlot={{
                    id: 1,
                    title: "Title",
                    subTitle: "subTitle",
                    time: "time",
                }}
            /> */}
        </>
    );
};

export default ChatSidebarContactRequestInProgressAdmin;
