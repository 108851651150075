import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthModal from "../../auth/AuthModal";
import DetailContactForm from "../components/DetailContactForm";
import DetailReserveForm from "../components/DetailReserveForm";
import { useAuth } from "../../auth/authContext";
import SeparateLine from "../../../reusable/SeparateLine";
import Box from "../../../reusable/Box";
import icon_coupon from "../../../../assets/img/icon_coupon.png";
import PriceSimulatorModal from "../../../reusable/PriceSimulatorModel";

const DetailSectionRight = (props) => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isPriceSimulatorModal, setIsPriceSimulatorModal] = useState(false);

  const { isLoggedIn, user } = useAuth();
  const [personalAccount, setPersonalAccount] = useState({
    first_name: user?.firstName || "",
    name: isLoggedIn ? user?.lastName : "",
    phone: user?.phone || "",
    email: user?.email || "",
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [eventType, setEventType] = useState("");
  const [guestCount, setGuestCount] = useState(null);
  const [message, setMessage] = useState(null);
  const toggleAuthModal = () => {
    setIsLoginModal((prevState) => !prevState);
    // setIsLoggedIn(true);
  };

  React.useEffect(() => {
    if (isLoggedIn && user) {
      setPersonalAccount(
        {
          first_name: user?.firstName || "",
          name: isLoggedIn ? user?.lastName : "",
          phone: user?.phone || "",
          email: user?.email || "",
        }
      )
    }

  }, [user, isLoggedIn])

  return (
    <>
      <Row className="clt-sidebar-forms stickySideBar">
        <Col>
          <div className="clt-detail-right-main">
            <div className="clt-detail-right-toggle">
              <span
                className={
                  props.formType === "reserve"
                    ? "clt-detail-right-toggle-tab active"
                    : "clt-detail-right-toggle-tab"
                }
                onClick={() => props.setFormType("reserve")}
              >
                Réserver
              </span>
              <span
                className={
                  props.formType === "contact"
                    ? "clt-detail-right-toggle-tab active"
                    : "clt-detail-right-toggle-tab"
                }
                // onClick={() => isLoggedIn ? props.setFormType("contact") : toggleAuthModal() }
                onClick={() => props.setFormType("contact")}
              >
                Nous contacter
              </span>
            </div>

            {props.formType === "contact" && (
              <DetailContactForm
                formTitle={props.formTitle}
                formSubtitle={props.formSubtitle}
                formEventType={props.event}
                eventType={props.eventType}
                setEventType={(event) => {
                  props.setEventType(event);
                }}
                data={{
                  personalAccount,
                  setPersonalAccount,
                  setSelectedMonth,
                  selectedMonth,
                  setSelectedYear,
                  selectedYear,
                  setEventType,
                  eventType,
                  guestCount,
                  setGuestCount,
                  message,
                  setMessage,
                }}
              />
            )}
            {props.formType === "reserve" && (
              <DetailReserveForm
                formTitle={props.formTitle}
                formSubtitle={props.formSubtitle}
                formEventType={props.event}
                eventType={props.eventType}
                setEventType={(event) => {
                  props.setEventType(event);
                }}
                onClick={() => {
                  // setTimeout(() => {
                  //   // Function to translate abbreviated English to French
                  //   function translateToFrench(text) {
                  //     const translations = {
                  //       'Sun': 'Dim',
                  //       'Mon': 'Lun',
                  //       'Tue': 'Mar',
                  //       'Wed': 'Mer',
                  //       'Thu': 'Jeu',
                  //       'Fri': 'Ven',
                  //       'Sat': 'Sam',
                  //       'Jan': 'Jan',
                  //       'Feb': 'Fév',
                  //       'Mar': 'Mar',
                  //       'Apr': 'Avr',
                  //       'May': 'Mai',
                  //       'Jun': 'Jui',
                  //       'Jul': 'Jui',
                  //       'Aug': 'Aoû',
                  //       'Sep': 'Sep',
                  //       'Oct': 'Oct',
                  //       'Nov': 'Nov',
                  //       'Dec': 'Déc',
                  //     };

                  //     return text.replace(/\b\w{3}\b/g, match => translations[match] || match);
                  //   }

                  //   // Get all elements with the class "selected-date"
                  //   const selectedDateElements = document.querySelectorAll('.selected-date');

                  //   // Iterate through the elements and translate their innerHTML
                  //   selectedDateElements.forEach(element => {
                  //     element.innerHTML = translateToFrench(element.innerHTML);
                  //   });
                  // }, 300);
                  props.setIsBookingSidebar(true)
                }}
              />
            )}
          </div>
          {props.formType === "reserve" && (
            <>
              <SeparateLine
                separateClass={"clt_detail_reserve_form_separate_line"}
              />
              <Box
                icon={icon_coupon}
                title={"Combien coûte une réservation ?"}
                subtitle={"Estimé votre réservation à l'aide de l'outil d'estimation des prix"}
                text_link={"Voir les prix"}
                onPress={(e) => { e.preventDefault(); setIsPriceSimulatorModal(true) }}
              />
            </>
          )}
        </Col>
      </Row>
      <AuthModal
        toggleAuthModal={toggleAuthModal}
        isLoginModal={isLoginModal}
      />

      <PriceSimulatorModal
        isShow={isPriceSimulatorModal}
        toggleModal={() => setIsPriceSimulatorModal(false)}
      />

    </>
  );
};

export default DetailSectionRight;
