import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { IMG_ALT, API_ENDPOINT } from "../../../../constants";
import PersonalSignup from "./steps/PersonalSignup";
import PhoneSignup from "./steps/PhoneSignup";
import PhoneVerification from "./steps/PhoneVerification";
import EmailSignUp from "./steps/EmailSignup";
import EmailVerification from "./steps/EmailVerification";
import axios from "axios";
import "../auth-modal.css";
import { useAuth, Actions } from "../authContext";
import { EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { auth } from "../firebase";
import { SuccessToast } from "../../../reusable/Toast";
import { ErrorToast } from "../../../reusable/Toast";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function AuthSignup({
	backSignUp, isSignUpStep, resetRegistration,continueSignUp, goToLoginScreen, toggleAuthModal, variant,updatePhoneVerification,numberTempRef, authenticationFlow
}) {
  const [verifier, setVerifier] = useState(null);
  const [numberTemp,setNumberTemp]=useState("");
  const [error, setError] = useState(false);

  const handleError = (value) => {
    setError(value);
  };

	const {
		email, password, firstName, lastName, phone, dispatch, facebookId, googleId, getSidebarBookingUsers
	} = useAuth();

	const personalSignupHandler = (firstName, lastName) => {
		dispatch({ type: Actions.START_REGISTRATION });
		dispatch({ type: Actions.SET_DATA, payload: { firstName, lastName } });
		continueSignUp();
	};

	const phoneSignupHandler = (phone, verifier) => {
		setVerifier(verifier);
		dispatch({ type: Actions.SET_DATA, payload: { phone } });
    	setNumberTemp(phone)
		continueSignUp(phone);
	};

	const phoneVerificationHandler = async (code) => {
		try {
			if (verifier === null)
				return;
			await verifier.confirm(code);
      		updatePhoneVerification(numberTemp);
			continueSignUp();
		} catch (error) {
			ErrorToast("Mauvais code", "Veuillez rectifier le code de verification.")
			console.log(error);
		}
	};

	const emailSignupHandler = () => {
		continueSignUp();
	};

	const finalSignupHandler = async () => {
		try {
			// debugger;
			const emailAuthCredentials = EmailAuthProvider.credential(
				email,
				password
			);
			await linkWithCredential(auth.currentUser, emailAuthCredentials);
			const data = {
				firstName,
				lastName,
				email,
				phone,
				uid: auth.currentUser.uid,
				googleId: googleId ?? undefined,
				facebookId: facebookId ?? undefined,
				isContactRequest: authenticationFlow ? true : false
			};
			console.log("Registering user with data ==== ", data);
			const token = await auth.currentUser.getIdToken();
			const response = await axios.post(API_ENDPOINT + "/auth/register", data, {
				headers: { Authorization: `Bearer ${token}` },
			});
			localStorage.setItem('user-login-in', true)
			
			await getSidebarBookingUsers(auth.currentUser).then(() => {
				dispatch({ type: Actions.USER_LOGGED_IN, payload: null });
			});
			SuccessToast(response.data.message);
			resetRegistration();
			toggleAuthModal();
		} catch (error) {
			auth.currentUser.delete();
			goToLoginScreen();
			localStorage.removeItem('user-login-in', true)
			
		} finally {
			dispatch({ type: Actions.DELETE_DATA });
			dispatch({ type: Actions.END_REGISTRATION });
		}
	};

	const canvasSteps = {
		1: {
			title: "Créer un compte (1/3)",
			element: <PersonalSignup authenticationFlow={authenticationFlow} handleContinue={personalSignupHandler} />,
		},
		2: {
			title: "Créer un compte (2/3)",
			element: <PhoneSignup authenticationFlow={authenticationFlow} handleContinue={phoneSignupHandler} />,
		},
		3: {
			title: "Verification code",
			element: (
				<PhoneVerification
					authenticationFlow={authenticationFlow}
					phone={phone}
					handleContinue={phoneVerificationHandler}
					handleSendCode={(result) => setVerifier(result)} />
			),
		},
		4: {
			title: "Créer un compte (3/3)",
			element: <EmailSignUp authenticationFlow={authenticationFlow} handleContinue={emailSignupHandler} error={error} handleError={handleError}/>,
		},
		5: {
			title: "Verification code",
			element: (
				<EmailVerification authenticationFlow={authenticationFlow} email={email} handleFinal={finalSignupHandler} />
			),
		},
	};

	return (
		<>
			<Offcanvas.Header className="justify-content-start clt_auth_user_offcanvas_header">
				<button
					type="button"
					onClick={backSignUp}
					className={`btn-close btn-back shadow-none d-flex`}
					aria-label="Close"
				>
					<img src={BackArrow} className="" alt={IMG_ALT} />
				</button>
				<Offcanvas.Title>
					{isSignUpStep in canvasSteps ? canvasSteps[isSignUpStep].title : ""}
				</Offcanvas.Title>
			</Offcanvas.Header>

			<Offcanvas.Body className="clt_auth_user_offcanvas_body">
				{isSignUpStep in canvasSteps ? (
					canvasSteps[isSignUpStep].element
				) : (
					<></>
				)}
				<ToastContainer className="toast-container" />
			</Offcanvas.Body>
		</>
	);
}

export default AuthSignup;
