import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Offcanvas, Button } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import "../[SIDEBAR] Search.css";
import SearchIcon from "../../../../assets/img/search_icon_light.png";
import { IMG_ALT } from "../../../../constants";
import Article from "../../../screens/helpCenter/Article";

const SidebarSearchHelpcenter = ({ articles, searchHandler }) => {
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [isSearchCanvas, setIsSearchCanvas] = useState(false);
  const [article, setArticle] = useState(null);
  const [isOpenArticle, setIsOpenArticle] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isSearchCanvas) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [isSearchCanvas]);

  useEffect(() => {
    setFilteredArticles(articles);
  }, [articles]);

  const onChange = async (e) => {
    const value = e.target.value.toLowerCase();

    setFilteredArticles(
      articles.filter((art) => art.name.toLowerCase().includes(value))
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleArticleClick = (article) => {
    setArticle(article);
    setIsOpenArticle(true);
  };

  return (
    <div className="clt_search_helpcenter_container">
      <div className="clt_search_chat_user_button">
        <div className="clt_search_chat_user_field">
          <Button
            onClick={() => setIsSearchCanvas(true)}
            type="text"
            className="shadow-none"
          >
            {'Rechercher'}
          </Button>
          <span className="clt_search_chat_user_icon_div">
            <img
              className="clt_search_chat_user_icon"
              src={SearchIcon}
              alt={IMG_ALT}
            />
          </span>
        </div>
      </div>

      <Offcanvas
        placement="end"
        className="clt_search_chat_user_offcanvas"
        show={isSearchCanvas}
        onHide={() => setIsSearchCanvas(false)}
      >
        <Offcanvas.Header className="justify-content-start clt_search_chat_user_offcanvas_header">
          <>
            <Button
              type="button"
              className="shadow-none btn-close btn-back"
              aria-label="Close"
              onClick={() => setIsSearchCanvas(false)}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </Button>

            <div className="w-100">
              <Form
                className="clt_search_chat_user_field_2"
                onSubmit={handleFormSubmit}
                autoFocus
              >
                <Form.Group className="clt_search_chat_user_field">
                  <Form.Control
                    type="text"
                    className="form-control-search"
                    placeholder="Essayer 'Compte'"
                    onChange={onChange}
                    ref={inputRef}
                  />
                </Form.Group>
              </Form>
            </div>
          </>
        </Offcanvas.Header>

        <Offcanvas.Body className="clt_search_chat_user_offcanvas_body">
          <div className="clt_search_chat_user_datalist_2">
            <div>
              {filteredArticles &&
                filteredArticles.map((art, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleArticleClick(art)}
                      className="clt_search_sidebar_container_box md:px-3 p-3 cursor-pointer"
                    >
                      <div className="clt_search_sidebar_avatar_wrapper">
                        <div className="clt_admin_dash_booking_avatar">
                          <div className="clt_admin_dash_booking_avatar_name">
                            ?
                          </div>
                        </div>
                      </div>
                      <div className="clt_search_sidebar_container_detail">
                        <div className="clt_search_sidebar_container_box_name">
                          {art.name}
                        </div>
                        <div className="subtitle">{art.groupTag || ''}</div>
                      </div>
                    </div>
                    {index !== 3 && (
                      <div className="clt_search_chat_user_hr"></div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {article && (
        <Article
          article={article}
          isOpenArticle={isOpenArticle}
          onClose={() => setIsOpenArticle(false)}
        />
      )}
    </div>
  );
};

export default SidebarSearchHelpcenter;
