// ProtectedRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./components/screens/auth/authContext";
import PublicMainLayoutRoute from "./layouts/PublicMainLayoutRoute";
import AuthModal from "./components/screens/auth/AuthModal";

const ProtectedRoute = ({ path, component, header }) => {
  const { user } = useAuth(); // Use your authentication context
  // const isLoggedIn = localStorage.getItem("user-login-in");

  console.log("user", user , localStorage.getItem("user-login-in") );

  return user || localStorage.getItem("user-login-in")  ? (
    <PublicMainLayoutRoute
      exact
      path={path}
      component={component}
      header={header}
    />
  ) : (
    <AuthModal
      toggleAuthModal={() => { }}
      isLoginModal={localStorage.getItem('user-login-in') ? false : true}
      variant="second"
    />
  );
};

export default ProtectedRoute;
