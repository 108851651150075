import { getAuth, browserLocalPersistence } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyA_Q2Wi0ImoYiIeu51_-t7VBIbWPKYJHJc",
	authDomain: "clt-360314.firebaseapp.com",
	projectId: "clt-360314",
	storageBucket: "clt-360314.appspot.com",
	messagingSenderId: "249770451021",
	appId: "1:249770451021:web:14428c547d17a12cd07c04",
};
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);
