//import
import React, { useState, useEffect, useContext, useRef } from "react";
import Event from "../../event/Event";
import { Button, Col, Form, Row } from "react-bootstrap";
import './Detail.css'
import { AuthContext } from "../../auth/authContext";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEvent } from "../../../../redux/actions/avent";
import { ErrorToast } from "../../../reusable/Toast";
import moment from "moment";
const DetailReserveForm = (props) => {
  const { selectedDate, selectedPrice, setSelectedPrice } = useContext(AuthContext);

  console.log('this is selected date', selectedDate);

  const dropdownRef = useRef(null); // Ref to the dropdown container

  useEffect(() => {
    // Add mousedown event listener to the document
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  const [isEventSidebar, setIsEventSidebar] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track whether the dropdown is open
  console.log(props);
  const { allEvents, setSelectedDate } = useContext(AuthContext);
  console.log('Here is all events', allEvents);


  const history = useHistory();
  const dispatch = useDispatch();

  const handleGetEvent = (event) => {
    dispatch(getEvent(event.id));
    if (props.parent === "details") {
      history.push(`/detail/${event.title}`);
      window.scrollTo(0, 0);
    }
  };



  function formatCombinedInput(combinedInput) {

    if (!combinedInput) {
      return ''; // Handle the case when combinedInput is not provided
    }

    const [inputDate, inputTime] = combinedInput?.split(', ');

    if (!inputDate || !inputTime) {
      return ''; // Handle the case when inputDate or inputTime is missing
    }

    const dateObj = moment.utc(inputDate).local().format('YY/MM/DD');
    const timeObj = moment(inputTime, 'HH:mm:ss').format('HH:mm');

    const formattedDateTime = `${dateObj?.split('/').reverse()?.join('/')} ${timeObj}`;
    return formattedDateTime;
  }
  const [arrivalError, setArrivalError] = useState(false);
  const [returnError, setReturnError] = useState(false);

  const checkAndReserve = () => {
    const isNullOrUndefinedOrEmpty = (value) => value === undefined || value === null || value === '';

    if (isNullOrUndefinedOrEmpty(selectedDate.start) || isNullOrUndefinedOrEmpty(selectedDate.end)) {
      if(isNullOrUndefinedOrEmpty(selectedDate.start)){
        setArrivalError(true);
      }
      if(isNullOrUndefinedOrEmpty(selectedDate.end)){
        setReturnError(true);
      }
      console.log('not selected');
      ErrorToast("Information manquante","Ajouter une date d'arrivée et de retour");
    } else {
      // Reset errors
      setArrivalError(false);
      setReturnError(false);
      props.onClick && props.onClick();
    }
  };
  useEffect(()=>{
    setArrivalError(false);
    setReturnError(false);
  },[selectedDate,props.formEventType])






  {/* Form - Reserve */ }
  return (
    <div>

      {/* Heading */}
      <div className="clt-detail-right-head-div">
        {props.isModal ? (
          <div className="clt-detail-right-head-sub-div">
            <span>{props.formTitle}</span>
          </div>
        ) : (
          <>

            {/* Heading - Span "Event type" */}
            <h2
              className="clt-detail-right-head-heading"
              style={{ color: props?.formEventType?.color }}
            >
              {props?.formEventType?.title}
            </h2>

            {/* Heading - Title / Subtitle */}
            <div className="clt-detail-right-head-sub-div">
              <span>{selectedPrice === null ? 'Ajouter des dates pour réserver' : `Réserver`}</span>
              {/* <span>{selectedPrice === null ? 'Add dates to calculate the price' : `Heading ${selectedPrice}€`}</span>*/}
              {/* <span>{props.formTitle}</span>/ {props.formSubtitle} */}
            </div>
          </>
        )}
      </div>

      {/* --------------------------------------------------------------------- */}

      {/* Body */}
      <div className="clt-detail-right-form-reserve">

        {/* Input - Event type */}
        <div className="clt_inputFloatDetailDiv" >
          <Form.Floating className="clt_inputFloatDetail">
            <Form.Control
              className="caret-color-transparent cursor-pointer"
              id="floatingInput1"
              type="text"
              placeholder="Event"
              readOnly
              onChange={(event) => console.log(event.target.value)}
              value={props.formEventType.title ? props.formEventType.title : null}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle the dropdown state
            />
            <label htmlFor="floatingInput1">Évenement</label>
          </Form.Floating>
          <i className="fa fa-angle-down clt_input_icon_angle_down"></i>
        </div>
        {isDropdownOpen && ( // Render the dropdown when isDropdownOpen is true
          <div ref={dropdownRef} className="pfr_dropdown_menu2" style={{ position: 'absolute', zIndex: '2000' }} >
            {/* Dropdown options */}
            {
              allEvents?.filter(x => (x.isActive === true)).map(events => <p
                onClick={() => {
                  handleGetEvent(events)
                  setIsDropdownOpen(!isDropdownOpen)
                  setSelectedDate('')
                  setSelectedPrice(null)
                }}

                key={events.id}
                className="m-2 cursor-pointer">{events.title}</p>)
            }

          </div>
        )}

        {/* <div className="clt_inputFloatDetailDiv">
          <Row> */}
            {/* Input - First name */}
        <div className="clt_inputFloatDetailDiv">
          <Row>
            {/* Input - First name */}
            <Col className="pe-0">
              <Form.Floating className="clt_inputFloatDetail clt_inputFloatDetail_left_reserve">
                <Form.Control
                  id="floatingInput2"
                  type="text"
                  placeholder="Arrival"
                  value={selectedDate.start === null ? '' : formatCombinedInput(selectedDate.start)}
                  onChange={(event) => console.log(event.target.value)}
                  onClick={props.onClick && props.onClick}
                  style={{border:arrivalError?'1px solid #d32f2f':'',
                  backgroundColor:arrivalError?'#f2e4e4':''
                }}
                />
                {/* <label htmlFor="floatingInput1"style={{color:arrivalError?'#d32f2f':''}}>Arrival</label> */}
                <label htmlFor="floatingInput1"style={{color:arrivalError?'#d32f2f':''}}>Arrivée</label>

              </Form.Floating>
            </Col>

            {/* Input - Name */}
            <Col className="ps-0">
              <Form.Floating className="clt_inputFloatDetail clt_inputFloatDetail_right_reserve">
                <Form.Control
                  id="floatingInput3"
                  type="text"
                  value={selectedDate.end === null ? '' : formatCombinedInput(selectedDate.end)}
                  placeholder="Return"
                  onChange={(event) => console.log(event.target.value)}
                  onClick={props.onClick && props.onClick}
                  style={{border:returnError?'1px solid #d32f2f':'',
                  backgroundColor:returnError?'#f2e4e4':''
                }}
                />
                <label htmlFor="floatingInput2" style={{color:returnError?'#d32f2f':''}}>Départ</label>
              </Form.Floating>
            </Col>
          </Row>
        </div>





        {/* Button - Confirm form */}
        {props.isModal && <div className="clt-form-space-bottom" />}
        <div className={props.customFooterButton}>
          <Button
            onClick={checkAndReserve}
            className="clt_formButton shadow-none"
            style={{
              background: props.formEventType.background,
              border: `1px solid ${props.eventType.background}`,
            }}
          >
            <span>Réserver</span>
          </Button>

        </div>
      </div>

      <Event
        show={isEventSidebar}
        onHide={() => setIsEventSidebar(false)}
        eventType={props.eventType}
        onConfirmEvent={(event) => {
          props.setEventType(event);
        }}
      />
    </div>
  );
};

export default DetailReserveForm;
