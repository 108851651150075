import { isEqual } from "lodash";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";

const SectionPaymentPlan = ({
  title,
  paymentPlanList,
  selectedPlan,
  setSelectedPlan,
  moreInfo,
  priceListData,
  selectedTimeSlot,
    isChange
}) => {
  const [combinePrice, setCombinePrice] = useState(0);
  const [totDays,setTotDays] = useState(4);
  useEffect(() => {
      let onePrice = 0
    for(let i=0; i < (priceListData?.length - 1) ; i++) {
      if (priceListData[i]?.price) {
        const price = parseFloat(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
        onePrice += price
      }
    }
    setCombinePrice((prevCombinePrice) => {
      return onePrice;
    });
    if (selectedTimeSlot){
      var today = new Date();
      var date_to_reply = new Date(selectedTimeSlot?.startDate);
      var timeinmilisec = date_to_reply.getTime() - today.getTime();
      var totalDays = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
      setTotDays(totalDays);
    }
  }, [priceListData])

  const calculateInstallements = (installment) =>{
    var today = new Date();
    var date_to_reply = new Date(selectedTimeSlot?.startDate);
    var timeinmilisec = date_to_reply.getTime() - today.getTime();
    var totalDays = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
    var slotsround = Math.round(totalDays/3);
    // setTotDays(totalDays);
    if (installment === 2){
      return slotsround;
    }else {
      return slotsround *2;
    }
  }
  return (
    <Row>
      <Col>
        {title && <h2 className="clt-booking-cp-div6-title">{title}</h2>}
        {!isChange?
        <>
          {paymentPlanList.map((paymentPlan, index) => {
            return (
                <>{(paymentPlan.title === 'Pay in 1 time')?<Row key={index}>
                  <Col>
                    <div
                        className={`d-flex flex-row align-items-center justify-content-between clt-paymentplan-select-mainDiv ${
                            !index && "clt-payment-plan-div"
                        }`}
                        onClick={() => setSelectedPlan(paymentPlan)}
                        style={
                          isEqual(selectedPlan, paymentPlan)
                              ? {
                                borderColor: `#000`,
                                background: '#f6f6f6',
                              }
                              : {
                                borderColor: `#ddd`,
                                background: '#fff',
                              }
                        }
                    >
                      <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                        <h2>Paiement en 1 fois</h2>
                        {/*{paymentPlan.plan.map((plan, index) => {*/}
                        {/*  return (*/}
                        {/*    <p className="clt-payment-plan-p" key={index}>*/}
                        {/*      {plan}*/}
                        {/*    </p>*/}
                        {/*  );*/}
                        {/*})}*/}
                        {paymentPlan.title === 'Pay in 1 time'?

                            <p className="clt-payment-plan-p" key={index}>
                              {combinePrice}€
                            </p>:
                            <p className="clt-payment-plan-p" key={index}>
                              {Math.round((combinePrice/3 + Number.EPSILON) * 100) / 100}€ maintenant <br/>
                              {Math.round((combinePrice/3 + Number.EPSILON) * 100) / 100}€ dans {calculateInstallements(2)} jours <br/>
                              {Math.round((combinePrice/3 + Number.EPSILON) * 100) / 100}€ dans {calculateInstallements(3)} jours <br/>
                            </p>
                        }
                      </div>
                      <div
                          className="inner-div-circle"
                          style={
                            isEqual(selectedPlan, paymentPlan)
                                ? {
                                  borderColor: `#000`,
                                }
                                : {
                                  borderColor: `#ddd`,
                                }
                          }
                      >
                        <div
                            className="subDiv"
                            style={
                              isEqual(selectedPlan, paymentPlan)
                                  ? {
                                    background: `#000`,
                                  }
                                  : {
                                    background: `transparent`,
                                  }
                            }
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>:<></> }</>

            );
          })}
          {paymentPlanList.map((paymentPlan, index) => {
            return (
                <>{(paymentPlan.title !== 'Pay in 1 time' && totDays > 3)?<Row key={index}>
                  <Col>
                    <div
                        className={`d-flex flex-row align-items-center justify-content-between clt-paymentplan-select-mainDiv ${
                            !index && "clt-payment-plan-div"
                        }`}
                        onClick={() => setSelectedPlan(paymentPlan)}
                        style={
                          isEqual(selectedPlan, paymentPlan)
                              ? {
                                borderColor: `#000`,
                                background: '#f6f6f6',
                              }
                              : {
                                borderColor: `#ddd`,
                                background: '#fff',
                              }
                        }
                    >
                      <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                        <h2>Paiement en 3 fois</h2>
                        {/*{paymentPlan.plan.map((plan, index) => {*/}
                        {/*  return (*/}
                        {/*    <p className="clt-payment-plan-p" key={index}>*/}
                        {/*      {plan}*/}
                        {/*    </p>*/}
                        {/*  );*/}
                        {/*})}*/}
                        {paymentPlan.title === 'Pay in 1 time'?

                            <p className="clt-payment-plan-p" key={index}>
                              {combinePrice}€
                            </p>:
                            <p className="clt-payment-plan-p" key={index}>
                              {Math.round((combinePrice/3 + Number.EPSILON) * 100) / 100}€ maintenant <br/>
                              {Math.round((combinePrice/3 + Number.EPSILON) * 100) / 100}€ dans {calculateInstallements(2)} jours <br/>
                              {Math.round((combinePrice/3 + Number.EPSILON) * 100) / 100}€ dans {calculateInstallements(3)} jours <br/>
                            </p>
                        }
                      </div>
                      <div
                          className="inner-div-circle"
                          style={
                            isEqual(selectedPlan, paymentPlan)
                                ? {
                                  borderColor: `#000`,
                                }
                                : {
                                  borderColor: `#ddd`,
                                }
                          }
                      >
                        <div
                            className="subDiv"
                            style={
                              isEqual(selectedPlan, paymentPlan)
                                  ? {
                                    background: `#000`,
                                  }
                                  : {
                                    background: `transparent`,
                                  }
                            }
                        ></div>
                      </div>
                    </div>
                  </Col>
                </Row>:<></> }</>

            );
          })}
        </>:
            <>
              {paymentPlanList.map((paymentPlan, index) => {
                return (
                    <Row key={index}>
                      <Col>
                        <div
                            className={`d-flex flex-row align-items-center justify-content-between clt-paymentplan-select-mainDiv ${
                                !index && "clt-payment-plan-div"
                            }`}
                            onClick={() => setSelectedPlan(paymentPlan)}
                            style={
                              isEqual(selectedPlan, paymentPlan)
                                  ? {
                                    borderColor: `#000`,
                                    background: '#f6f6f6',
                                  }
                                  : {
                                    borderColor: `#ddd`,
                                    background: '#fff',
                                  }
                            }
                        >
                          <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                            <h2>{paymentPlan.title}</h2>
                            {/*{paymentPlan.plan.map((plan, index) => {*/}
                            {/*  return (*/}
                            {/*    <p className="clt-payment-plan-p" key={index}>*/}
                            {/*      {plan}*/}
                            {/*    </p>*/}
                            {/*  );*/}
                            {/*})}*/}
                            <p className="clt-payment-plan-p" key={index}>
                              {paymentPlan.plan}€
                            </p>
                          </div>
                          <div
                              className="inner-div-circle"
                              style={
                                isEqual(selectedPlan, paymentPlan)
                                    ? {
                                      borderColor: `#000`,
                                    }
                                    : {
                                      borderColor: `#ddd`,
                                    }
                              }
                          >
                            <div
                                className="subDiv"
                                style={
                                  isEqual(selectedPlan, paymentPlan)
                                      ? {
                                        background: `#000`,
                                      }
                                      : {
                                        background: `transparent`,
                                      }
                                }
                            ></div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                );
              })}
            </>
        }
        {moreInfo && <p className="clt-payment-plan-more-info">{moreInfo}</p>}
      </Col>
    </Row>
  );
};

export default SectionPaymentPlan;
