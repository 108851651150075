/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
// import SectionTop from "../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] Admin - Just chatting.css";
import SectionUser from "../../../../../reusable/booking/SectionUser";
// import SectionDamageDeposit from "../../../reusable/booking/SectionDamageDeposit";
// import { Link } from "react-router-dom";
import Guest from "../../../../guest/Guest";
import Account from "../../../../account/Account";
import Booking from "../../../../detail/booking/booking_user";
import { useAuth } from "../../../../auth/authContext";
// import SectionContact from "../../../reusable/booking/SectionContact";

const initialBookingScreens = {
    noBooking: true,
    bookingList: false,
    bookingDetail: false,
    cancelBooking: false,
};

const ChatSidebarJustChattingAdmin = ({ hideSidebar, chatUser }) => {

    const { isLoggedIn, user } = useAuth();
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com",
        profilePic: null,
        name: "John Doe",
    })
    const [showAccountSidebar, setShowAccountSidebar] = useState(false);

    useEffect(() => {
        if (chatUser)
            setSelectedProfile({ type: "Personal", detail: `${chatUser?.firstName} ${chatUser?.lastName} · ${chatUser?.phone} · ${chatUser?.email}`, profilePic: chatUser?.profilePictureUrl ? chatUser?.profilePictureUrl : null, name: `${chatUser?.firstName} ${chatUser?.lastName}` })
    }, [chatUser])

    return (
        <>

            {/* Sidebar - Just chatting - Admin */}
            <div
                className="clt_message_user_chat_offcanvas"
            >

                {/* Header */}
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        Discussion
                    </div>

                </div>

                {/* Body */}
                <div
                    className="clt_message_user_chat_offcanvas_body"
                >
                    <>
                        <h2 className="clt-booking-cp-div1-title">Utilisateur</h2>
                        <SectionUser
                            handleClick={() => setShowAccountSidebar(true)}
                            text={
                                selectedProfile.type === "Personal" ? "Personnel" :
                                selectedProfile.type === "Professional" ? "Professionnel" :
                                `Profil ${selectedProfile.type}`
                            }
                            title="User"
                            subText={
                                <span>{selectedProfile.detail}</span>
                            }
                            profilePic={selectedProfile.profilePic}
                            name={selectedProfile.name}
                            isClassActive={true}
                        />
                    </>


                </div>
            </div>

            {showAccountSidebar && 
                <Account
                    isOpenSidebar={showAccountSidebar}
                    setIsOpen={() => {
                        setShowAccountSidebar(true);
                    }}
                    onCloseSidebar={() => {
                        setShowAccountSidebar(false);
                    }}
                    selectProfile="admin"
                    isTrue={true}
                    dataa={{ User: chatUser}}
                />
            }
            
            {/* <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="admin"
                selectedProfileData={selectedProfile}
                setSelectedProfileData={setSelectedProfile}
            /> */}
        </>
    );
};

export default ChatSidebarJustChattingAdmin;
