import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Offcanvas,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import "../../../AdminDashboardBooking/admin_dash_booking_component/component_amount_paid/component_amount_paid.css";
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import {
  IMG_ALT, IMG_BASEURL,
} from "../../../../../../constants";
import { Link } from "react-router-dom";
import CustomHr from "../../../../../reusable/CustomHr";
import { useDispatch } from "react-redux";
import { createRoomTimeSlot, getSlotRooms, updateRoomTimeSlot } from "../../../../../../redux/actions/timeSlot";
import { useSelector } from "react-redux";
import { getRooms } from "../../../../../../redux/actions/room";
// import ManageName from "./ManageName";
// import AddSection from "./integration_section3_home";
// import IntegrationSection5 from "./integration_section5_detail";
// import IntegrationSection6 from "./integration_section6_detail";


/* Const - Screen - Inital  */
const initialSelectOptionRoom = {
  listPriceAndNight: true,
  AddPriceAndNight: false,
};

/* Const - Screen */
const SelectedOptionRoom = ({
  isSidebar,
  onCloseSidebar,
  isNumberOfNightMax,
  setSectionAmountList,
  setIsRoomPricePerNight,
  roomList,
  createdTimeSlot,
  isEdit,
  selectedTimeSlot,
  setSelectedSlotRoomsTotal,
  onClickSave
}) => {
  /* Const - Screen */
  const [selectOptionRoom, setSelectOptionRoom] = useState(
    initialSelectOptionRoom
  );

  /* Const - Initial type */
  const initialType = {
    type: isNumberOfNightMax[0].title,
    amount: null,
  };
//  start --------------------------------
  const selectedSlotRooms = useSelector((state) => state.allSlotRooms.slotRooms);
  const [allRooms, setAllRooms] = useState(roomList)
  const [isRoomCreated, setIsRoomCreated] = useState(false);
  const myAllRooms = useSelector((state) => state.allRooms.rooms);
  const [innerSelectedClick, setInnerSelectedClick] = useState(false)
  const [currentSelectedSlotRoomsTotal, setCurrentSelectedSlotRoomsTotal] = useState(0)
  const [rooms, setRooms] = useState([])
  useEffect(() => {
    if (selectedTimeSlot?.id) {
      dispatch(getSlotRooms(selectedTimeSlot?.id))
    }
    if (createdTimeSlot?.id) {
      dispatch(getSlotRooms(createdTimeSlot?.id))
    }
  },[selectedTimeSlot, createdTimeSlot, isRoomCreated])

  useEffect(() => {
    setRooms(selectedSlotRooms)
  }, [selectedSlotRooms])

  useEffect(() => {
    dispatch(getRooms())
  }, [isRoomCreated])

  useEffect(() => {
    setAllRooms(myAllRooms)
  }, [createdTimeSlot])

  // ------------------------------------- end

  /* ------------------------------------------------------------ */

  /* Const - Screen - List Price and night  */

  /* Const - Identify room */
  const [selectedId, setSelectedId] = useState("");

  /* Const - Set value of a form */
  const [formValues, setFormValues] = useState(initialType);

  /* Const - Component - List - Threedot */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));


  /* ------------------------------------------------------------ */

  /* Const - Screen - Add Price and night  */

  const [selectedMainRoom ,setSelectedMainRoom] = useState("")
  /* Const - Open screen  */
  const OpenAddPriceAndNight = (id) => {
    setSelectedId(id);
    handleBack("listPriceAndNight", "AddPriceAndNight");
  };
  const OpenEditPriceAndNight = (slotroom) => {
    setSelectedId(slotroom?.selectedroom?.id);
    setSelectedMainRoom(slotroom?.room?.id)
    handleBack("listPriceAndNight", "AddPriceAndNight");
  };

  const dispatch = useDispatch();
  /* Const - Button - Save */
  const handleSavePayment = async () => {
    let timeSlotId
    if (createdTimeSlot.id) {
      timeSlotId = createdTimeSlot.id
    } else {
      timeSlotId = selectedTimeSlot?.id
    }
    const dataObj = {
      roomId : selectedId,
      timeSlotId : timeSlotId,
      pricePerNight : parseInt(formValues.amount),
      maxQuantity : formValues.type === 'undefined night' ? 1 : parseInt(formValues.type.split(' ')[0]),
    }
    const updatedData = JSON.stringify(dataObj);
    const response = dispatch(createRoomTimeSlot(updatedData))
    if (response.error) {
      alert('error while adding Room to TimeSlot')
    } else {
      setCurrentSelectedSlotRoomsTotal(currentSelectedSlotRoomsTotal + 1)
      setIsRoomCreated(!isRoomCreated)
      // dispatch(getRooms())
      const filteredRooms = allRooms.filter((room) => room.id !== selectedId)
      setAllRooms(filteredRooms);
       const index = roomList.findIndex((room) => room.id === selectedId);
      if (formValues.type !== "") roomList[index].night_quantity_limit = formValues.type;
      roomList[index].amount = formValues.amount;
      setFormValues(initialType);
      handleBack("AddPriceAndNight", "listPriceAndNight");
      setIsRoomPricePerNight(true);
    }
  };

  const handleEditPayment = async () => {
    console.log("__MAX");
    console.log(parseInt(formValues.type.split(' ')[0]));
    console.log(isNumberOfNightMax);
    const dataObj = {
      roomId : selectedMainRoom,
      timeSlotId : selectedTimeSlot.id,
      pricePerNight : parseInt(formValues.amount),
      maxQuantity : !isNaN(parseInt(formValues.type.split(' ')[0]))?parseInt(formValues.type.split(' ')[0]):parseInt(isNumberOfNightMax[0].title.split(' ')[0]),
    }
    const updatedData = JSON.stringify(dataObj);
    const response = dispatch(updateRoomTimeSlot(updatedData, selectedId))
    if (response.error) {
      alert('error while adding Room to TimeSlot')
    } else {
      setIsRoomCreated(!isRoomCreated)
       const index = roomList.findIndex((room) => room.id === selectedId);
      dispatch(getSlotRooms(selectedTimeSlot?.id));
      // if (formValues.type !== "") roomList[index].night_quantity_limit = formValues.type;
      // roomList[index].amount = formValues.amount;
      setFormValues(initialType);
      handleBack("AddPriceAndNight", "listPriceAndNight");
      setIsRoomPricePerNight(true);
    }
  };

  /* ------------------------------------------------------------ */

  /* Const - Component */

  /* Const - Component - Button back */
  const handleBack = (from, to) => {
    setSelectOptionRoom((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };




  /* ------------------------------------------------------------------------------------------------------------------------------- */

  /* Screen 1 : Manage room - List - Price and night */
  /* Screen 2 : Manage room - Add - Price and night */

  /* ------------------------------------------------------------------------------------------------------------------------------- */


  console.log("All Rooms List");
  console.log(allRooms);
  console.log("Selected Rooms")
  console.log(rooms);
  console.log(currentSelectedSlotRoomsTotal);

  /* Screen */
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_products_offcanvas"
        show={isSidebar}
        onHide={
          // selectOptionRoom.confirmBooking
          //     ? handleClose
          //     :
          onCloseSidebar
        }
      >

         {/* Screen - Header */}
        <Offcanvas.Header
          className={`justify-content-start clt_products_offcanvas_header_two ${
            selectOptionRoom.SectionSelectOptionRoom && "border-bottom-none"
          }`}
        >

          {/* Screen 1 - List - Price and night - Header*/}
          {selectOptionRoom.listPriceAndNight && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => onCloseSidebar(roomList, currentSelectedSlotRoomsTotal)}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>{`Option : Room`}</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}

          {/* Screen 2 - Add - Price and night - Header*/}
          {selectOptionRoom.AddPriceAndNight && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => {
                  handleBack("AddPriceAndNight", "listPriceAndNight");
                  setFormValues({ type: "", amount: null });
                }}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Room {selectedId}</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}
        </Offcanvas.Header>


        {/* ------------------------------------------- */}
        {/* ------------------------------------------- */}
        {/* ------------------------------------------- */}


        {/* Screen - Body*/}
        <Offcanvas.Body
          className={`clt_products2_offcanvas_body ${
            selectOptionRoom.SectionSelectOptionRoom &&
            "clt-amount-paid-admin-event-body-no-padding"
          }`}
        >

          {/* Screen 1 - List - Price and night - Body */}
          {selectOptionRoom.listPriceAndNight && (
            <>
              <Row className="clt-amount-paid-detail-section-6">
                <Col className="clt-amount-paid-admin-event-s1">
                  <Row className="clt-amount-paid-detail-section-6-header">
                    <Col>
                      <h5>{`Select the number of night and price for each room(s)`}</h5>
                    </Col>
                  </Row>
                  {isEdit ?
                      <>
                  <Row className="clt-amount-paid-admin-event-list-div-body m-0">
                    {rooms?.map((eventData, index) => {
                    // {allRooms?.map((eventData, index) => {
                      return (
                        <Col
                          className="clt-amount-paid-admin-event-list-div"
                          lg={12}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div
                              className="clt-admin-room-div-img"
                              style={{
                                backgroundImage: `url(${IMG_BASEURL}${eventData?.room?.roomgalleries && eventData?.room?.roomgalleries[0]?.ImageUrl})`,
                              }}
                            ></div>
                            <div
                              className="inner-icon-title-div"
                              onClick={() => {
                                setFormValues((existingValues) => ({
                                  type: `${eventData?.selectedroom?.maxQuantity} night`,
                                  amount: eventData?.selectedroom?.pricePerNight,
                                }));
                                OpenEditPriceAndNight(eventData)
                              }
                              }
                            >
                              <div className="d-flex flex-column align-items-start justify-content-center">
                                <h2>{eventData?.room?.name}</h2>
                                <p>
                                  {eventData?.selectedroom?.pricePerNight
                                    ? `${eventData?.selectedroom?.pricePerNight}€/night - ${eventData?.selectedroom?.maxQuantity} max.`
                                    : "Add price and amount"}
                                </p>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                              style={{
                                width: "10%",
                              }}
                            >
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu
                                  size="sm"
                                  title=""
                                  className="pfr_dropdown_menu"
                                >
                                  <Dropdown.Item
                                    onClick={() => {

                                      setFormValues((existingValues) => ({
                                        type: `${eventData?.selectedroom?.maxQuantity} night`,
                                        amount: eventData?.selectedroom?.pricePerNight,
                                      }));
                                      OpenEditPriceAndNight(eventData)
                                    }
                                  }
                                    className="pfr_payment_cards3dots_options"
                                  >
                                    Add price and night
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>

                      <Row className="clt-amount-paid-admin-event-list-div-body m-0">
                        {allRooms
                            .map((eventData, index) => {
                              let flag = true;
                              rooms?.map((rData, index) => {
                                if (eventData.id === rData.room.id){
                                  flag = false;
                                }
                              });
                              if (flag){

                                return (
                                    <Col
                                        className="clt-amount-paid-admin-event-list-div"
                                        lg={12}
                                    >
                                      <div className="d-flex align-items-center w-100">
                                        <div
                                            className="clt-admin-room-div-img"
                                            style={{
                                              backgroundImage: `url(${IMG_BASEURL}${eventData?.roomgalleries[0]?.ImageUrl})`,
                                            }}
                                        ></div>
                                        <div
                                            className="inner-icon-title-div"
                                            onClick={() =>
                                            {
                                              setInnerSelectedClick(false)
                                              setFormValues((existingValues) => ({
                                                type: `${eventData.maxQuantity} night`,
                                                amount: eventData.pricePerNight,
                                              }));
                                              OpenAddPriceAndNight(eventData.id);
                                              console.log("Clicked event");
                                              console.log(eventData);
                                              setSelectedMainRoom(eventData.id);
                                            }
                                            }
                                        >
                                          <div className="d-flex flex-column align-items-start justify-content-center">
                                            <h2>{eventData.name}</h2>
                                            <p>
                                              {eventData.pricePerNight
                                                  ? `${eventData.pricePerNight}€/night - ${eventData.maxQuantity} max.`
                                                  : "Add price and amount"}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                            className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                            style={{
                                              width: "10%",
                                            }}
                                        >
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu
                                                size="sm"
                                                title=""
                                                className="pfr_dropdown_menu"
                                            >
                                              <Dropdown.Item
                                                  onClick={() =>
                                                  {
                                                    setInnerSelectedClick(false)
                                                    setFormValues((existingValues) => ({
                                                      type: `${eventData.maxQuantity} night`,
                                                      amount: eventData.pricePerNight,
                                                    }));
                                                    OpenAddPriceAndNight(eventData.id)
                                                  }
                                                  }
                                                  className="pfr_payment_cards3dots_options"
                                              >
                                                Add price and night
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                );
                              }else {
                                return (<></>);
                              }
                            })}
                      </Row>
                      </>
                  :
                  <div>
                    <Row className="clt-amount-paid-admin-event-list-div-body m-0">
                      {selectedSlotRooms?.length > 0 &&
                        selectedSlotRooms?.map((eventData, index) => {
                            return (
                              <Col
                                className="clt-amount-paid-admin-event-list-div"
                                lg={12}
                                >
                                <div className="d-flex align-items-center w-100">
                                  <div
                                    className="clt-admin-room-div-img"
                                    style={{
                                      backgroundImage: `url(${IMG_BASEURL}${eventData?.room?.roomgalleries && eventData?.room?.roomgalleries[0]?.ImageUrl})`,
                                    }}
                                  ></div>
                                  <div
                                    className="inner-icon-title-div"
                                    onClick={() =>
                                      {
                                        setInnerSelectedClick(true)
                                        setFormValues((existingValues) => ({
                                          type: `${eventData?.selectedroom?.maxQuantity} night`,
                                          amount: eventData?.selectedroom?.pricePerNight,
                                        }));
                                        OpenEditPriceAndNight(eventData)
                                      }
                                    }
                                  >
                                    <div className="d-flex flex-column align-items-start justify-content-center">
                                      <h2>{eventData?.room?.name}</h2>
                                      <p>
                                      {eventData?.selectedroom?.pricePerNight
                                          ? `${eventData?.selectedroom?.pricePerNight}€/night - ${eventData?.selectedroom?.maxQuantity} max.`
                                          : "Add price and amount"}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                    style={{
                                      width: "10%",
                                    }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu
                                        size="sm"
                                        title=""
                                        className="pfr_dropdown_menu"
                                      >
                                        <Dropdown.Item
                                          onClick={() =>
                                            {
                                              setInnerSelectedClick(true)
                                              setFormValues((existingValues) => ({
                                                type: `${eventData?.selectedroom?.maxQuantity} night`,
                                                amount: eventData?.selectedroom?.pricePerNight,
                                              }));
                                              OpenEditPriceAndNight(eventData)
                                            }
                                          }
                                          className="pfr_payment_cards3dots_options"
                                        >
                                          Add price and night
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </Col>
                            );
                      })}
                    </Row>
                    <Row className="clt-amount-paid-admin-event-list-div-body m-0">
                    {allRooms
                      .map((eventData, index) => {
                        return (
                          <Col
                            className="clt-amount-paid-admin-event-list-div"
                            lg={12}
                            >
                            <div className="d-flex align-items-center w-100">
                              <div
                                className="clt-admin-room-div-img"
                                style={{
                                  backgroundImage: `url(${IMG_BASEURL}${eventData?.roomgalleries[0]?.ImageUrl})`,
                                }}
                              ></div>
                              <div
                                className="inner-icon-title-div"
                                onClick={() =>
                                  {
                                    setInnerSelectedClick(false)
                                    setFormValues((existingValues) => ({
                                      type: `${eventData.maxQuantity} night`,
                                      amount: eventData.pricePerNight,
                                    }));
                                    OpenAddPriceAndNight(eventData.id)
                                  }
                                }
                              >
                                <div className="d-flex flex-column align-items-start justify-content-center">
                                  <h2>{eventData.name}</h2>
                                  <p>
                                  {eventData.pricePerNight
                                      ? `${eventData.pricePerNight}€/night - ${eventData.maxQuantity} max.`
                                      : "Add price and amount"}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                style={{
                                  width: "10%",
                                }}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu
                                    size="sm"
                                    title=""
                                    className="pfr_dropdown_menu"
                                  >
                                    <Dropdown.Item
                                      onClick={() =>
                                        {
                                          setInnerSelectedClick(false)
                                          setFormValues((existingValues) => ({
                                            type: `${eventData.maxQuantity} night`,
                                            amount: eventData.pricePerNight,
                                          }));
                                          OpenAddPriceAndNight(eventData.id)
                                        }
                                      }
                                      className="pfr_payment_cards3dots_options"
                                    >
                                      Add price and night
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                  }
                </Col>
              </Row>
            </>
          )}


          {/* Screen 2 - Add - Price and night - Body */}
          {selectOptionRoom.AddPriceAndNight && (
            <>

              {/* Section - Price per night */}
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Price per night</h2>
                </Col>
              </Row>
              <Row>
                <Col className="clt-amount-paid-admin-event-info-input-div">
                  <Form.Floating className="pfr_inputFloat pfr_inputFloat_number ">
                    <Form.Control
                      id="floatingInput2"
                      type="number"
                      placeholder="Amount"
                      value={formValues.amount}
                      onChange={(e) => {
                        setFormValues((existingValues) => ({
                          ...existingValues,
                          amount: e.target.value,
                        }));
                      }}
                    />
                    <label htmlFor="floatingInput2">Amount</label>
                    <span className="last-input-indicator">€</span>
                  </Form.Floating>
                </Col>
              </Row>


              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />


              {/* Section - Number of night limit */}
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2 className="mb-2">Number of night limit</h2>
                </Col>
              </Row>
              <Row>
                <Col className="clt-amount-paid-admin-event-info-input-div selectField">
                  <p class="clt_admin_dash_menu_container_name3 mt-1 ">
                    Set the number of night maximum that can be select by the
                    user
                  </p>
                  <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                    <Form.Select
                      id="floatingInput1"
                      type="select"
                      placeholder="Events"
                      value={formValues.type}
                      onChange={(e) => {
                        setFormValues((existingValues) => ({
                          ...existingValues,
                          type: e.target.value,
                        }));
                      }}
                    >
                      {isNumberOfNightMax.map((room, index) => (
                        <option key={index} value={room.title}>
                          {room.title}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInput1">Number of night</label>
                  </Form.Floating>
                </Col>
              </Row>
              <div className="pfr_payment_space_bottom" />
              <div className="clt_payment_footer">
              {(!isEdit && innerSelectedClick) ?
                <Button className="shadow-none" onClick={handleEditPayment}>
                  Save
                </Button>
              :
              isEdit ?
                <Button className="shadow-none" onClick={handleEditPayment}>
                  Save
                </Button>
              :
              <Button className="shadow-none" onClick={handleSavePayment}>
                 Save
              </Button>
               }
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SelectedOptionRoom;
