import React, { useState, useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { Button, Col, Container, Row } from "react-bootstrap";
import clt_gallery1 from "../../assets/img/clt_gallery1.png";
import clt_gallery2 from "../../assets/img/clt_gallery2.png";
import clt_gallery3 from "../../assets/img/clt_gallery3.png";
import clt_gallery4 from "../../assets/img/clt_gallery4.jpg";
import clt_gallery5 from "../../assets/img/clt_gallery5.jpg";
import clt_gallery6 from "../../assets/img/clt_gallery6.jpg";
import clt_gallery7 from "../../assets/img/clt_gallery7.jpg";
import clt_gallery8 from "../../assets/img/clt_gallery8.jpg";
import clt_gallery9 from "../../assets/img/clt_gallery9.jpg";
import illustrationwedding from "../../assets/img/illustration_wedding.jpg";
import illustrationseminar from "../../assets/img/illustration_seminar.jpg";
import illustrationsoirée from "../../assets/img/illustration_soirée.jpg";
import illustrationrepasnoel from "../../assets/img/illustration_repasnoel.jpg";
import illustrationbapteme from "../../assets/img/illustration_bapteme.jpg";
import illustrationbirthday from "../../assets/img/illustration_birthday.jpg";
import illustrationconvention from "../../assets/img/illustration_convention.jpg";
import illustrationcousinade from "../../assets/img/illustration_cousinade.jpg";
import illustrationafterwork from "../../assets/img/illustration_afterwork.jpg";

import { Link } from 'react-router-dom';
import CustomHr from "./CustomHr";
import CustomCarousel from "./CustomCarousel";
import "../screens/detail/detail.css";
import {
  detailMainSliderResponsive,
  detailSecondarySliderResponsive,
  IMG_ALT,
  eventweddingData,
  section4SliderResponsive,
  IMG_BASEURL,
  selectRoomSliderResponsive
} from "../../constants";


const NewSection2 = () => {
  const [secondaryGallery, setSecondaryGallery] = useState([
    {
      title: "Mariage",
      subtitle: "Desc",
      width: 1950,
      height: 1300,
      link: "/detail/Mariage",
      src: illustrationwedding,
    },
    {
      title: "Soirée",
      width: 1950,
      height: 1300,
      link: "/detail/Soirée",
      src: illustrationsoirée,
    },
    {
      title: "Convention",
      width: 1950,
      height: 1300,
      link: "/detail/Convention",
      src: illustrationconvention,
    },
    {
      title: "Afterwork",
      width: 1950,
      height: 1300,
      link: "/detail/Afterwork",
      src: illustrationafterwork,
    },
    {
      title: "Anniversaire",
      width: 1950,
      height: 1300,
      link: "/detail/Anniversaire",
      src: illustrationbirthday,
    },
    {
      title: "Séminaire",
      width: 1950,
      height: 1300,
      link: "/detail/Séminaire",
      src: illustrationseminar,
    },

    {
      title: "Baptême",
      width: 1950,
      height: 1300,
      link: "/detail/Baptême",
      src: illustrationbapteme,
    },
    
    
    {
      title: "Cousinade",
      width: 1950,
      height: 1300,
      link: "/detail/Cousinade",
      src: illustrationcousinade,
    },
    
    
    {
     title: "Repas noël",
     width: 1950,
      height: 1300,
      link: "/detail/Repas%20noël",
      src: illustrationrepasnoel,
     },
  ]);
  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
          <p className="aaclt_admin_dash_timeslot_edit_box_subheading mt-1">{rest.subtitle} </p>
      </div>
      <div className="d-flex justify-content-between clt-home-visite-h2-header23">
        <div>
          <h2 className="clt-home-visite-h23546534">{rest.head}</h2>
          <h2 className="clt-home-visite-h2">{rest.title}</h2>
        </div>
        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-section-3-cards-arrowBtn me-2"
                : "clt-section-3-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? "disable clt-section-3-cards-arrowBtn"
                : "clt-section-3-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
    );
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const openGallery = async (type, index) => {
    const lightboxGallery = await Promise.all(
      secondaryGallery.map(async (item) => {
        const img = new Image();
        img.src = item.src;
        await img.decode();
        return {
          src: img.src,
          name: item.name,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };

  const handleClick = (event, link) => {
    event.preventDefault(); // Prevent the default link behavior
    window.location.href = link; // Manually navigate to the desired page
  };
  
  return (
    <>
      <Container className="clt-homepage-container ">
        <Row className="showMobile">
          <Col className="clt-section-row-col-pd">
            <h2 className="clt-home-visite-h2">Événement</h2>
            <p className="abaclt_admin_dash_timeslot_edit_box_subheading mt-1">
              Parcourez notre liste d'événement
            </p>
          </Col>
        </Row>
      </Container>


      
      <Container fluid className="showMobile" style={{ flexDirection: 'row' }}>
      <Row className="clt-detail-left-section-2-row showMobile">
        <Col>
          <Container className="clt-detail-left-section-2-row-container">
            <Row>
              <Col className="clt-detail-left-section-2-row-col">
                <div className="d-flex justify-content-start align-items-center clt-detail-left-section-2">
                  {secondaryGallery.map((item, index) => (
                    <div key={index} className="clt-detail-left-section-2-card-container">
                      <Link
                        to={`${item.link}`}
                        onClick={(event) => handleClick(event, item.link)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        <div
                          className="d-flex flex-column justify-content-start align-items-center clt-detail-left-section-2-cards clt-detail-left-section-2-cards-main clt-detail-left-section-3-cards clt-detail-left-section-3-cards-main"
                          style={{
                            backgroundImage: `url(${item.src})`,
                          }}
                        >
                          {/* Image content */}
                        </div>
                        {/* Span content */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span
                          className="clt-detail-left-section-4-card-item-h2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.location.href = `${item.link}`}
                        >
                          {item.title}
                        </span>
                        <span
                          className="clt-detail-left-section-4-card-item-h2-sub"
                          style={{ cursor: 'pointer' }}
                          onClick={() => window.location.href = `${item.link}`}
                        >
                          Événement
                        </span>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>

      
    <Container className="clt-homepage-container border-bottomxx hideMobile">
      <Row className="hideMobile" style={{ flexDirection: 'row', marginBottom: '25px' }}>
        <Col className="clt-detail-left-section-2-col">
          <div className="d-flex flex-column-reverse">
            <CustomCarousel
              responsive={detailSecondarySliderResponsive}
              arrows={false}
              shouldResetAutoplay={false}
              renderButtonGroupOutside={true}
              customButtonGroup={
                <DetailMainSliderArrows title="Organisez un évenement"  head="Événement"/>
              }
              className={`clt-detail-left-section-2-cards-div my-carousel4`}
            >
              {secondaryGallery.map((item, index) => (
                <div key={index} className="clt-detail-left-section-2-card-container">
                  <div
                    className="d-flex flex-column justify-content-start align-items-center clt-home-visite-h2-cards clt-home-visite-h3-cards clt-detail-left-section-2-cards-main clt-detail-left-section-3-cards clt-detail-left-section-3-cards-main cursor-zoom-in"
                    style={{
                      backgroundImage: `url(${item.src})`,
                    }}
                    onClick={(event) => handleClick(event, item.link)}
                  >
                    {/* Image content */}
                  </div>
                  {/* Span content */}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    className="clt-detail-left-section-4-card-item-h2"
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => handleClick(event, item.link)}
                  >
                    {item.title}
                  </span>
                  <span
                    className="clt-detail-left-section-4-card-item-h2-sub"
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => handleClick(event, item.link)}
                  >
                    Événement
                  </span>
                  </div>
                </div>
              ))}
            </CustomCarousel>
          </div>
        </Col>
      </Row>
    </Container>



    </>
  );
};

function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}

export default NewSection2;
