import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  eventbirthdayData,
  eventweddingData,
  eventprofessionalData,
  eventreligiousData,
} from "../../../../constants";
import EventsNavbar from "../../../reusable/EventsNavbar";
import { isMobile, isTablet } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getEvent, getEvents } from "../../../../redux/actions/avent";

const DetailEventsNavbar = (props) => {
  const history = useHistory();
  const [stickyBarTop, setstickyBarTop] = useState(undefined);
  const [stickySidebar, setStickySidebar] = useState(undefined);

  useEffect(() => {
    const stickyBarEl = document
      .querySelector(".stickyBar")
      .getBoundingClientRect();
    setstickyBarTop(stickyBarEl.top);
  }, []);
  useEffect(() => {
    const stickySideBarEl = document
      .querySelector(".stickySideBar")
      .getBoundingClientRect();
    setStickySidebar(stickySideBarEl.top);
  }, []);

  useEffect(() => {
    if (!stickyBarTop) return;
    window.addEventListener("scroll", isSticky);

    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyBarTop]);

  const isSticky = (e) => {
    const stickyBarEl = document.querySelector(".stickyBar");
    const scrollTop = window.scrollY;
    let check;
    if (isTablet) {
      check = scrollTop >= stickyBarTop - 10;
    } else if (isMobile) {
      check = scrollTop >= stickyBarTop + 200;
    } else {
      check = scrollTop >= stickyBarTop - 10;
    }
    if (scrollTop < 1) {
      check = false;
    }
    if (check) {
      stickyBarEl.classList.add("is-sticky");
    } else {
      stickyBarEl.classList.remove("is-sticky");
    }
  };

  useEffect(() => {
    const stickyBarEl = document.querySelector(".clt-section-2-divs.active");
    stickyBarEl?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "end",
    });
  }, []);
  useEffect(() => {
    if (!stickySidebar) return;

    window.addEventListener("scroll", isSidebarSticky);
    return () => {
      window.removeEventListener("scroll", isSidebarSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickySidebar]);

  const isSidebarSticky = (e) => {
    const stickySideBarEl = document.querySelector(".stickySideBar");
    const scrollTop = window.scrollY;
    let check;
    if (isTablet) {
      check = scrollTop >= stickyBarTop - 10;
    } else if (isMobile) {
      check = false;
    } else {
      check = scrollTop >= stickySidebar - 10;
    }
    if (scrollTop < 1) {
      check = false;
    }
    if (check) {
      stickySideBarEl.classList.add("is-sticky");
    } else {
      stickySideBarEl.classList.remove("is-sticky");
    }
  };
  const allEvents = useSelector(state => state.allEvents.events);
  const event = useSelector(state => state.event.event)

  return (
    <EventsNavbar
      eventType={props.eventType}
      myAllEvents = {allEvents}
      currenEVENT = {event?.dataValues?.title}
      parent = 'details'
    />
  );
};

export default DetailEventsNavbar;
