import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Offcanvas, Button } from 'react-bootstrap';
import BackArrow from "../../../../../assets/img/back_arrow.png";
import '../../[SIDEBAR] Search.css'
import SearchIcon from "../../../../../assets/img/home_searchbar_icon.png";
import wall_color4 from "../../../../../assets/img/wall-color4.png";
import { IMG_ALT } from "../../../../../constants";
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

/* Const - User data */ 



/* Const - Search */ 
const SearchSidebarChat = ({usersData, searchHandler}) => {


    const [isSearch, setIsSearch] = React.useState(false);
    const [isSearchCanvas, setIsSearchCanvas] = React.useState(false);
    const [selectedUser, setSelectedUser] = useState(null)
    const ref = useDetectClickOutside({ onTriggered: () => setIsSearch(false) });
    
    const onChange = async (e) => {
        if (e.target.value.length > 0) {
            await searchHandler(e.target.value)
           
        }
        else setIsSearch(false)
    }
    
    const openUserSelected = (item) => {
        setSelectedUser(item);
        setIsSearchCanvas(false);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
    };
    


console.log("usersData", usersData);

    /* Search - Select or Invite - User*/ 
    return (


        /* Section */ 
        <div className="clt_search_chat_user_container" >
            <div className="clt_search_chat_user_button">
                <div className="clt_search_chat_user_field" >
                    <Button
                        onClick={() => setIsSearchCanvas(true)}
                        type="text" className="shadow-none">
                        {selectedUser !== null ? selectedUser.name : 'Search'}
                    </Button>
                    <span className="clt_search_chat_user_icon_div">
                        <img className="clt_search_chat_user_icon" src={SearchIcon} alt={IMG_ALT} />
                    </span>
                </div>
            </div>


            { /* Sidebar */ }
            <Offcanvas
                placement="end"
                className="clt_search_chat_user_offcanvas"
                show={isSearchCanvas}
                onHide={() => setIsSearchCanvas(false)}
            >


                { /* Sidebar - Header */ }
                <Offcanvas.Header className="justify-content-start clt_search_chat_user_offcanvas_header " >
                    <>


                        { /* Sidebar - Header - Close button */ }
                        <Button
                            type="button"
                            className="shadow-none btn-close btn-back"
                            aria-label="Close"
                            onClick={() => setIsSearchCanvas(false)}
                        >
                            <img src={BackArrow} alt={IMG_ALT} />
                        </Button>


                        { /* Sidebar - Header - Search button */ }
                        <div className="w-100">
                            <Form className="clt_search_chat_user_field_2" onSubmit={handleFormSubmit}>
                                <Form.Group className="clt_search_chat_user_field">
                                    <Form.Control type="text" placeholder="Search user or email e.g Jhon" onChange={onChange} onBlur={() => setIsSearch(false)} />
                                </Form.Group>
                            </Form>
                        </div>
                    </>
                </Offcanvas.Header>


                { /* Sidebar - Body */ }
                <Offcanvas.Body className="clt_search_chat_user_offcanvas_body">


                    { /* Sidebar - Body - Result */ }
                    <div className="clt_search_chat_user_datalist_2">
                        <div>
                            {usersData.map((item, index) =>
                                <div key={index}>
                                    {item.type === "user" ?


                                        /* Sidebar - Body - Result : Existing user */     
                                        <div onClick={() => openUserSelected(item)} className="clt_search_sidebar_container_box md:px-3 p-3 cursor-pointer">
                                            <div className='clt_search_avatar_wrapper'>
                                                <div className="clt_admin_dash_booking_avatar">
                                                    <div className='clt_admin_dash_booking_avatar_name'>{item.name[0].toUpperCase()} </div>
                                                </div>
                                            </div>
                                            <div className="clt_admin_dash_booking_container_detail">
                                                <div className="clt_search_sidebar_container_box_name">{item.name}</div>
                                                <div className="subtitle">{item.detail}</div>
                                            </div>
                                        </div>

                                        :

                                        /* Sidebar - Body - Result : Invite someone by email */ 
                                        
                                        <div onClick={() => openUserSelected(item)} className="clt_search_sidebar_container_box md:px-3 p-3 cursor-pointer">
                                            <div className='clt_search_avatar_wrapper mb-1'>
                                                <div className="clt_admin_dash_booking_avatar">
                                                    <div className='clt_admin_dash_booking_avatar_name'> {item.name[0].toUpperCase()} </div>
                                                </div>
                                            </div>
                                            <div className="clt_admin_dash_booking_container_detail">
                                                <div className="clt_search_sidebar_container_box_name">Invite {`<<`}{item.name}{`>>`} by email</div>
                                            </div>
                                        </div>
                                    }
                                    {index !== 3 && (
                                        <div className="clt_search_chat_user_hr"></div>
                                    )}

                                </div>
                            )}

                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default SearchSidebarChat