import React, { useEffect } from "react";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import NextArrow from "../../../assets/img/back_arrow_right.png";
import NextArrowWhite from "../../../assets/img/back_arrow_right_white_thin.png";

const SectionContactRequestDate = ({ props, image, subText, IsDateButtonDropdown, buttonText, selectedMonth, selectedYear, setSelectedMonth, setSelectedYear, handleRequestEdit }) => {

  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  useEffect(() => {
    const month = subText.split(" ")[0];
    const year = subText.split(" ")[1];

    setMonth(month);
    setYear(year);
  }, [subText]);

  const CustomToggleSecond = React.forwardRef(({ children, onClick }, ref) => (
    
    <p
      className="clt_right_form_filter_chip"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        if (!selectedMonth && !selectedYear) {
          setSelectedMonth(month);
          setSelectedYear(year);
        }
      }}
    >
      {selectedMonth || selectedYear
        ? "Modifier" //`${selectedMonth || ""} ${selectedYear || ""}`
        : "Modifier" }
      <img
        src={NextArrow}
        className="clt_admin_arrow down"
        alt={IMG_ALT}
      />
    </p>
  ));

  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]; // Update with desired year range

  const randomMonth = months[new Date().getMonth()];
  
  const handleMonthSelection = (month) => {
    setSelectedMonth(month);
    if (!selectedMonth && !selectedYear) {
      handleRequestEdit({ month: month, year: new Date().getFullYear() });
    } else {
      handleRequestEdit({ month: month });
    }
  };

  const handleYearSelection = (year) => {
    setSelectedYear(year);
    if (!selectedMonth && !selectedYear) {
      handleRequestEdit({ month: randomMonth, year: year });
    } else {
      handleRequestEdit({ year: year });
    }
  };

  // Check if the props object and the required properties are defined
  // const data = props && props.data;
  // const setSelectedMonth = data && data.setSelectedMonth;
  // const selectedMonth = data && data.selectedMonth;
  // const setSelectedYear = data && data.setSelectedYear;
  // const selectedYear = data && data.selectedYear;
  

  return (
    <Row className="clt-booking-mb">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Date</h2>
              <p>{!selectedMonth && !selectedYear ? subText : `${selectedMonth} ${selectedYear}`}</p>
            </div>

            {/* Dropdown - Date */}
            { IsDateButtonDropdown && (
              <div className="clt_right_form_filter_chip_container">
                <Dropdown className={""}>
                  <Dropdown.Toggle as={CustomToggleSecond} />
                  <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu_month_year">
                    <div className="d-flex">
                      {/* Month column */}
                      <div className="mr-3">
                        {months.map((month) => (
                          <Dropdown.Item
                            key={month}
                            onClick={() => handleMonthSelection(month)}
                          >
                            {month}
                          </Dropdown.Item>
                        ))}
                      </div>

                      {/* Year column */}
                      <div>
                        {years.map((year) => (
                          <Dropdown.Item
                            key={year}
                            onClick={() => handleYearSelection(year)}
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionContactRequestDate;
