import React from "react";
import { Button, Col, Row } from "react-bootstrap";
const DetailSection6 = (props) => {
  return (
    <Row className="clt-detail-section-6">
      <Col className="clt-detail-left-section-1">
        <Row className="clt-detail-section-6-header">
          <Col>
            <h2>{props.title}</h2>
            <p>{props.subTitle}</p>
          </Col>
        </Row>
        <Row className="clt-detail-section-6-body m-0">
          <Col>
            {props.columnsData.map((column, index) => {
              return (
                <Row key={index}>
                  <Col className="clt-detail-section-6-body-col">
                    <h2>{column.title}</h2>
                    <p>{column.subTitle}</p>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col className="clt-detail-section-6-body-col">
                <Button onClick={props.onClick} className="shadow-none">
                  <span>Tout voir</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DetailSection6;
