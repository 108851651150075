
/* eslint-disable no-unused-vars */
import React, { useEffect, useState ,useContext} from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../assets/img/back_arrow.png";
import { IMG_ALT, API_ENDPOINT } from "../../../constants";
import "./account.css";
import IconAddProfessional from "../../../assets/img/icon_professional_profile.png";
import IconTrashIcon from "../../../assets/img/icon_trash_icon.png";
import CustomHr from "../../reusable/CustomHr";
import AccountInputText from "./sections/AccountInputText";
import AccountInputPhone from "./sections/AccountInputPhone";
import AccountInputEmail from "./sections/AccountInputEmail";
import AccountInputAddress from "./sections/AccountInputAddress";
import AccountInputProfilePicture from "./sections/AccountInputProfilePicture";
import { isEqual, result } from "lodash";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { ErrorToast, SuccessToast } from "../../reusable/Toast";
import {
  PROFILE_KEY,
  deleteProfessionalProfileRequest,
  addProfessionalProfileRequest,
  getProfessionalProfilesRequest,
  getProfessionalProfilesRequestAdmin,
  updatePersonalProfileRequest,
  uploadProfilePicRequest,
  updateProfessionalProfileRequest,
  deleteProfilePicRequest,
} from "./api";
import { useAuth,AuthContext} from "../auth/authContext";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import ReactCodeInput from "react-verification-code-input";
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from "firebase/auth";
import { auth } from "../auth/firebase";
import ConfirmPay from "../../reusable/SidebarMessageContinueConfirm&Pay";

const initialAccountScreens = {
  accountsList: true,
  profileInformation: false,
  professionalProfile: false,
  selectProfile: false,
  confirm: false,
};

// const isAddressEmpty = (address) => {
// 	return (
// 		!address.address &&
// 		!address.city &&
// 		!address.zip_code &&
// 		!address.state_region &&
// 		!address.country
// 	);
// };
const isAddressEmpty = (address) => {
  return (
    !address ||
    (!address.address &&
      !address.city &&
      !address.zip_code &&
      !address.state_region &&
      !address.country)
  );
};

export default function Account(
  { dataa,
    isOpenSidebar,
    onCloseSidebar,
    selectProfile,
    selectedProfileData,
    setSelectedProfileData,
    setIsUserSidebar,
    onCloseProfile,
    isTrue,
    handleProfileUpdate,
    parentComp=null
  }) {
    const {setProfilePictureUrl}=useContext(AuthContext);

  const queryClient = useQueryClient();

  const addProfessionalProfileSuccess = (result) => {
    SuccessToast(result.data.message);
    getProf();
    queryClient.invalidateQueries(PROFILE_KEY);
  };

  const addProfessionalProfileError = (error) => {
    debugger;
    // ErrorToast(error.response.data.message)
    ErrorToast("Une erreur est survenue", "Veuillez essayer à nouveau.");
  }

  const addProfessionalProfile = useMutation(addProfessionalProfileRequest, {
    onSuccess: addProfessionalProfileSuccess,
    onError: addProfessionalProfileError,
  });

  const updateProfessionalProfileSuccess = (result) => {
    SuccessToast(result.data.message);
    setSelectedProfessionalProfile(result.data.data);
    queryClient.invalidateQueries(PROFILE_KEY);
  };
  console.log("This is dataa", dataa);
  console.log("selectedProfileData", selectedProfileData);
  const updateProfessionalProfile = useMutation(
    updateProfessionalProfileRequest,
    {
      onSuccess: updateProfessionalProfileSuccess,
    }
  );

  const deleteProfessionalProfileSuccess = (result) => {
    SuccessToast(result.data.message);
    getProf();
    queryClient.invalidateQueries(PROFILE_KEY);
    if (selectProfile !== 'select') {
      setAccountScreens((prevState) => ({
        ...prevState,
        accountsList: true,
        professionalProfile: false,
      }));
    }
    if (selectProfile === 'select') {
      setAccountScreens((prevState) => ({
        ...prevState,
        accountsList: false,
        professionalProfile: false,
        selectProfile: true,
      }));
    }

    setSelectedProfessionalProfile({});
  };

  const deleteProfessionalProfile = useMutation(
    deleteProfessionalProfileRequest,
    {
      onSuccess: deleteProfessionalProfileSuccess,
    }
  );

  const handleUploadProfilePic = (file) => {
    const data = new FormData();
    data.append("profile_image", file);
    uploadProfilePic.mutate(data);
  };

  const handleDeleteProfilePic = () => {
    deleteProfilePic.mutate()
  }

  const updatePersonalProfileSuccess = (result) => {
    const user = result.data.data;
    setPersonalAccount({
      first_name: user.firstName,
      name: user.lastName,
      email: user.email,
      phone: user.phone,
      billing_address: {
        address: user?.billingAddress ?? "",
        city: user?.billingCity ?? "",
        zip_code: user?.billingZipCode ?? "",
        state_region: user?.billingState ?? "",
        country: user?.billingCountry ?? "",
      },
      profile_picture: user?.profilePictureUrl ?? null,
    });
    dataa.User = user;
    if (parentComp == "userSearch") {
      handleProfileUpdate(result.data.data);
    }
    SuccessToast(result.data.message);
  };

  const updatePersonalProfile = useMutation(updatePersonalProfileRequest, {
    onSuccess: updatePersonalProfileSuccess,
  });

  const uploadProfilePic = useMutation(uploadProfilePicRequest, {
    onSuccess: (result) => {
      if (parentComp == "userSearch") {
        handleProfileUpdate({ ...dataa.User, profilePictureUrl: result.data.data });
      }
      // debugger;
      setPersonalAccount((prevData) => {
        return {
          ...prevData,
          profile_picture: result.data.data,
        };
      });
      SuccessToast(result.data.message);
      setIsProfilePicInputSidebar(false);
    },
  });

  const deleteProfilePic = useMutation(deleteProfilePicRequest, {
    onSuccess: (result) => {
      // debugger;
      setPersonalAccount((prevData) => {
        return {
          ...prevData,
          profile_picture: null
        }
      })
      SuccessToast(result.data.message)
      setIsProfilePicInputSidebar(false)
    }
  })

  const [accountScreens, setAccountScreens] = useState(initialAccountScreens);
  const [isAccount, setIsAccount] = useState({
    personal: true,
    professional: false,
  });

  const [selectedProfile, setSelectedProfile] = useState("");
  const [profileselected, setprofileselected] = useState(null);

  const { user, socket } = useAuth();

  const [personalAccount, setPersonalAccount] = useState({
    first_name: user?.firstName,
    name: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    billing_address: {
      address: user?.billingAddress ?? "",
      city: user?.billingCity ?? "",
      zip_code: user?.billingZipCode ?? "",
      state_region: user?.billingState ?? "",
      country: user?.billingCountry ?? "",
    },
    profile_picture: null,
  });
  const [businessBilling, setbusinessBilling] = useState({
    billing_address: {
      address: dataa?.User?.billingAddress ?? "",
      city: dataa?.User?.billingCity ?? "",
      zip_code: dataa?.User?.billingZipCode ?? "",
      state_region: dataa?.User?.billingState ?? "",
      country: dataa?.User?.billingCountry ?? "",
    },
  });
  useEffect(() => {

    if (user) {
      console.log('THIS IS USER++++++++++++++++', user);
      localStorage.setItem('selectedUserId', user.id);
      setPersonalAccount({
        first_name: user.firstName,
        name: user.lastName,
        email: user.email,
        phone: user.phone,
        billing_address: {
          address: user?.billingAddress ?? "",
          city: user?.billingCity ?? "",
          zip_code: user?.billingZipCode ?? "",
          state_region: user?.billingState ?? "",
          country: user?.billingCountry ?? "",
        },
        profile_picture: user?.profilePictureUrl ?? null,
      });
    }
    getProf();
  }, [user]);

  const [isTextInputSidebar, setIsTextInputSidebar] = useState(false);
  const [isPhoneInputSidebar, setIsPhoneInputSidebar] = useState(false);
  const [isEmailInputSidebar, setIsEmailInputSidebar] = useState(false);
  const [isAddressInputSidebar, setIsAddressInputSidebar] = useState(false);
  const [isProfilePicInputSidebar, setIsProfilePicInputSidebar] =
    useState(false);
  const [isSidebarTitle, setIsSidebarTitle] = useState("");
  const [isSidebarInputData, setIsSidebarInputData] = useState({});
  const [professionalProfiles, setProfessionalProfiles] = useState([]);
  const [selectedProfessionalProfile, setSelectedProfessionalProfile] =
    useState({});
  const [verificationData, setVerificationData] = useState({
    name: "",
    value: "",
  });

  useEffect(() => {
    if (selectProfile === 'admin') {
      getProfAdmin(dataa?.User?.id);
    } else {
      getProf();
    }
    if (socket) {
      socket.on('profileUpdate', async function (dataComing, callback) {
        if (dataComing.data === dataa?.User?.id) {
          setProfessionalProfiles(dataComing.profiles);
        }
      });
    }
  }, [selectedProfessionalProfile]);

  const getProfAdmin = async (id) => {
    const response = await getProfessionalProfilesRequestAdmin(id);
    if (response.success) {
      setProfessionalProfiles(response.data);
    } else {
      console.log('error getting profiles admin', response);
    }
  }

  const getProf = async () => {
    const response = await getProfessionalProfilesRequest();
    if (response.success) {
      setProfessionalProfiles(response.data);
    } else {
      console.log('error getting profiles admin', response);
    }
  }

  const handleBack = (from, to) => {
    console.log("@@@@@@@@@@@@@@@@@@@@@@", from, to);
    setAccountScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };
  console.log("@@@@@@@@@@@@@@@@@@@@@@", accountScreens.profileInformation, accountScreens.accountsList);

  const handleEditPersonalProfile = () => {
    setIsAccount((prevState) => ({
      ...prevState,
      personal: true,
      professional: false,
    }));
    setAccountScreens((prevState) => ({
      ...prevState,
      selectProfile: false,
      profileInformation: true,
    }));
  };

  const handleEditProfessionalProfile = (profile) => {
    setIsAccount((prevState) => ({
      ...prevState,
      personal: false,
      professional: true,
    }));
    setSelectedProfessionalProfile(profile);
    setAccountScreens((prevState) => ({
      ...prevState,
      selectProfile: false,
      professionalProfile: true,
    }));
  };

  const handlePersonalProfile = () => {
    setIsAccount((prevState) => ({
      ...prevState,
      personal: true,
      professional: false,
    }));
    setAccountScreens((prevState) => ({
      ...prevState,
      accountsList: false,
      profileInformation: true,
    }));
  };

  const handleProfessionalProfile = (profile) => {
    setIsAccount((prevState) => ({
      ...prevState,
      personal: false,
      professional: true,
    }));
    setSelectedProfessionalProfile(profile);
    setAccountScreens((prevState) => ({
      ...prevState,
      accountsList: false,
      professionalProfile: true,
    }));
  };

  const handleAddProfessionalProfile = () => {
    addProfessionalProfile.mutate();
  };

  const handleInputData = async (data) => {
    if (data.name === "email") {
      setPersonalAccount((prevState) => ({
        ...prevState,
        email: data.value,
      }));
      return;
    }
    // 		if (data.name === "phone") {
    // 			const applicationVerifier = new RecaptchaVerifier('recaptcha-container');
    // const provider = new PhoneAuthProvider(auth);
    // const verificationId = await provider.verifyPhoneNumber('+16505550101', applicationVerifier);
    // 			updatePhoneNumber()
    // 		}

    // 		if (["email", "phone"].includes(data.name)) {
    // 			setVerificationData({ name: data.name, value: data.value })
    // 			setAccountScreens(prevState => ({
    // 				...prevState,
    // 				accountsList: false,
    // 				professionalProfile: false,
    // 				profileInformation: false,
    // 				verification: true
    // 			}))
    // 			return;
    // 		}

    if (isAccount.personal || data.name === "phone") {
      return updatePersonalProfile.mutate({ [data.name]: data.value, isAdmin: selectProfile === 'admin' ? dataa?.User?.id : undefined });
    }

    if (isAccount.professional) {
      return updateProfessionalProfile.mutate({
        data: selectProfile === 'admin' ? { [data.name]: data.value, isAdmin: true } : { [data.name]: data.value },
        id: selectedProfessionalProfile.id,
      });
    }
  };

  useEffect(() => {
    if (selectProfile == "select") {
      setAccountScreens((prevState) => ({
        accountsList: false,
        profileInformation: false,
        professionalProfile: false,
        selectProfile: true,
      }));
    }
  }, [selectProfile]);

  useEffect(() => {
    if (selectedProfileData && selectProfile === "update") {
      if (selectedProfileData?.type === "Professional") {
        setSelectedProfessionalProfile(selectedProfileData.data);
      }
      setAccountScreens((prevState) => ({
        accountsList: false,
        profileInformation:
          selectedProfileData?.type === "Personal" ? true : false,
        professionalProfile: selectedProfileData?.type === "Professional" ? true : false,
        // professionalProfile: false,
        selectProfile: false,
      }));
      setSelectedProfile(selectedProfileData?.type);
    }
  }, [selectProfile, selectedProfileData]);

  useEffect(() => {
    if (isTrue == "true") {
      handleBack("profileInformation", "accountsList");
    }
  }, [isTrue]);

  useEffect(() => {
    if (selectedProfileData && selectProfile === "admin") {
      if (selectedProfileData?.type === "Professional") {
        setSelectedProfessionalProfile(selectedProfileData.data);
      }
      setAccountScreens((prevState) => ({
        accountsList: false,
        // profileInformation:
        //   selectedProfileData?.type === "Personal" ? true : false,
        // professionalProfile:
        //   selectedProfileData?.type === "Professional" ? true : false,
        // professionalProfile: false,

        profileInformation: selectedProfileData?.type === "Personal" || dataa?.profile_type === "personal" ? true : false,
        professionalProfile: selectedProfileData?.type === "Professional" || dataa?.profile_type === "professional" ? true : false,
        selectProfile: false,
      }));

      setSelectedProfile(selectedProfileData?.type);
    }
  }, [selectedProfileData, selectProfile]);

  const handleCheck = () => {
    if (selectProfile === "admin" && isTrue == "false") {
      setAccountScreens((prevState) => ({
        accountsList: false,
        profileInformation:
          selectedProfileData?.type === "Personal" ? true : false,
        professionalProfile:
          selectedProfileData?.type === "Professional" ? true : false,
      }));
    } else {
      onCloseSidebar();
    }
  };
  // const handleCheck = () => {
  //   if (selectProfile === "admin" && isTrue === false) {
  //     setAccountScreens((prevState) => ({
  //       accountsList: false,
  //       profileInformation:
  //         selectedProfileData?.type === "Personal" || selectedProfileData?.type === "personal" ? true : false,
  //       professionalProfile:
  //         selectedProfileData?.type === "Professional" || selectedProfileData?.type === "professional" ? true : false,
  //     }));
  //   } else {
  //     onCloseSidebar();
  //   }
  // };

  useEffect(() => {
    if (dataa == "undefined") {
      setPersonalAccount({ first_name: null });
    }
  }, [dataa]);

  useEffect(()=>{
    setProfilePictureUrl(personalAccount.profile_picture);
  },[personalAccount])

  useEffect(() => {
    if(selectProfile === 'admin' && selectedProfileData?.type === 'Professional'){
      setIsAccount((prevState) => ({
        ...prevState,
        personal: false,
        professional: true,
      }))
    }
    else if(selectProfile === 'admin' && selectedProfileData?.type === 'Personal'){
      setIsAccount((prevState) => ({
        ...prevState,
        personal: true,
        professional: false,
      }))
    }
  }, [selectedProfileData])

  const [isID, setID] = useState();
  const [isPersonalProfile, setIsPersonalProfile] = useState(false);
  const [isProfessionalProfile, setIsProfessionalProfile] = useState(false);

  useEffect(() => {
    setIsPersonalProfile(false);
    setIsProfessionalProfile(false);
  }, [profileselected, personalAccount]);

  const isRequired = (value) => value === '' || value === null;
  const applyRedColor = (value) => {
    return isRequired(value) ? { color: 'red' } : {};
  };

  const handleErrFlag = () => {
    let isError = false;
    if (selectedProfile === 'Personal') {
      isError = (
        isRequired(personalAccount.first_name) ||
        isRequired(personalAccount.phone) ||
        isRequired(personalAccount.email) ||
        isAddressEmpty(personalAccount.billing_address))
    }
    if (selectedProfile === 'Professional' && profileselected.id === isID) {
      isError = (
        isRequired(profileselected?.phone) ||
        isRequired(profileselected?.business_name) ||
        isAddressEmpty(profileselected?.head_office_address) ||
        isRequired(profileselected?.email) ||
        isRequired(profileselected?.tva_number) ||
        isAddressEmpty(profileselected?.billing_address) ||
        isRequired(profileselected?.defrayment_note))
    }
    setIsPersonalProfile(selectedProfile === 'Personal' && isError);
    setIsProfessionalProfile(selectedProfile === 'Professional' && isError);
    return isError;

  }

  const ifErrorFalse = () => {
    if (selectedProfile === 'Professional') {
      setSelectedProfileData({
        type: "Professional",
        detail: `${profileselected.business_name
          ? profileselected.business_name
          : "Business name: Requis"
          }. ${profileselected.phone} . ${profileselected.email}`,
      })
    }
    if (selectedProfile === 'Personal') {
      setSelectedProfileData({
        type: "Personal",
        detail: `${personalAccount.first_name} . ${personalAccount.phone} . ${personalAccount.email}`,
      })
    }
    onCloseProfile(professionalProfiles, selectedProfile)

  }
  const ifErrorTrue = () => {
    ErrorToast("Information manquante ou incomplète", "Veuillez ajouté les informations nécessaires.");

    if (selectedProfile === 'Professional' || selectedProfile === 'Personal') {
      setSelectedProfileData({
        type: "",
        detail: "",
      })
    }
  }
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebar}
        onHide={onCloseSidebar}
      >
        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_account_offcanvas_header">
          {accountScreens.accountsList && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={isTrue === "true" ? onCloseSidebar : handleCheck}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Profil</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}
          {accountScreens.profileInformation && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => {
                  selectProfile == "select"
                    ? handleBack("profileInformation", "selectProfile")
                    : selectProfile == "update" || selectProfile == "admin"
                      ? onCloseSidebar()
                      : handleBack("profileInformation", "accountsList");
                }}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Profil Personnel</Offcanvas.Title>
                {/* {selectProfile == "update" && (
									 <Button
										className="clt_header_edit_button"
										onClick={() =>
											handleBack("profileInformation", "selectProfile")
										}
									>
										Edit1
									</Button>
									)} */}
                {selectProfile == "admin" && (
                  <Button
                    className="clt_payment_header_button shadow-none px-3 w-fit-content"
                    onClick={() =>
                      handleBack("profileInformation", "accountsList")
                    }
                  >
                    Voir tout les profils
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
          {accountScreens.professionalProfile && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => {
                  selectProfile == "select"
                    ? handleBack("professionalProfile", "selectProfile")
                    : selectProfile == "update" || selectProfile == "admin"
                      ? onCloseSidebar()
                      : handleBack("professionalProfile", "accountsList");
                }}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Profil Professionnel</Offcanvas.Title>

                {/*
                {selectProfile == "update" ? (
                  <p></p>
                  //   <Button
                  // 	className="clt_header_edit_button shadow-none clt-header-trash-can-btn"
                  // 	onClick={() =>
                  // 		handleBack("professionalProfile", "selectProfile")
                  // 	}
                  // >
                  // 	Edit2
                  // </Button>
                ) : selectProfile == "admin" ? null : (
                  <Button
                    className="clt_header_delete_button shadow-none clt-header-trash-can-btn"
                    onClick={() =>
                      deleteProfessionalProfile.mutate({
                        id: selectedProfessionalProfile.id,
                      })
                    }
                  >
                    <img src={IconTrashIcon} alt={IMG_ALT} />
                    Supprimer
                  </Button>
                  )}*/}
              </div>
            </React.Fragment>
          )}
          {accountScreens.selectProfile && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseSidebar}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Profil</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}
          {accountScreens.confirm && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => handleBack("selectProfile", "confirm")}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Confirmer et payer</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}
        </Offcanvas.Header>

        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body`}>
          {accountScreens.accountsList && (
            <>
              <Row>
                <Col className="clt-account-screen">
                  <h2 className="clt-account-screen-title">Profils</h2>
                  <div
                    className="d-flex justify-content-between align-items-start clt-account-screen-div"
                    onClick={() => {
                      // if (selectProfile !== "admin") {
                      handlePersonalProfile();
                      // } else return null;
                    }}
                  >
                    <div className="d-flex flex-row justify-content-start align-items-start clt-account-screen-inner-div">
                      <div className="clt-account-screen-inner-div-img">
                        {/* <div
													className="inner-div"
													style={
														personalAccount.profile_picture && {
															backgroundImage: `url(${personalAccount.profile_picture})`,
														}	
													}
												>
													{!personalAccount.profile_picture && (
														<span>{personalAccount.first_name?.charAt(0)}</span>
													)}
												</div> */}
                        <div
                          className="inner-div"
                          style={
                            isTrue
                              ? dataa?.User?.profilePictureUrl
                                ? {
                                  backgroundImage: `url(${dataa.User.profilePictureUrl})`,
                                }
                                : {}
                              : personalAccount.profile_picture
                                ? {
                                  backgroundImage: `url(${personalAccount.profile_picture})`,
                                }
                                : {}
                          }
                        >
                          {isTrue
                            ? dataa?.User?.profilePictureUrl === null && (
                              <span>{dataa.User?.firstName?.charAt(0)}</span>
                            )
                            : !personalAccount.profile_picture && (
                              <span>
                                {personalAccount.first_name?.charAt(0)}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="clt-account-screen-inner-div-text">
                        <h2>Profil Personnel</h2>
                        {isTrue ? (
                          <p>
                            {dataa?.User?.firstName} {dataa?.User?.lastName}
                          </p>
                        ) : (
                          <p>
                            {personalAccount.first_name}
                            {personalAccount.name}
                          </p>
                        )}
                        {/* <p>
													{personalAccount.first_name}{personalAccount.name}
												</p> */}
                        {isTrue ? (
                          <p>{dataa?.User?.phone}</p>
                        ) : (
                          <p>{personalAccount.phone}</p>
                        )}
                        {isTrue ? (
                          <p>{dataa?.User?.email}</p>
                        ) : (
                          <p>{personalAccount.email}</p>
                        )}
                        {/* <p>{personalAccount.phone}</p>
												<p>{personalAccount.email}</p> */}
                        {/* {isTrue ? (
                          <p>{`${dataa?.User?.billingAddress!==null?dataa?.User?.billingAddress:'Requis'},
                           ${dataa?.User?.billingZipCode!==null?dataa?.User?.billingZipCode:'Requis'}, 
                           ${dataa?.User?.billingCity!==null?dataa?.User?.billingCity:'Requis'}`}</p>
                        ) : isAddressEmpty(personalAccount.billing_address) ? (
                          <p>Requis</p>
                        ) : (
                          <p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
                        )} */}
                        {isTrue && dataa?.User !== null && !isAddressEmpty(dataa?.User) ? (
                          <p>
                            {`${dataa?.User?.billingAddress !== null ? dataa?.User?.billingAddress : 'Requis'}, ${dataa?.User?.billingZipCode !== null ? dataa?.User?.billingZipCode : 'Requis'
                              }, ${dataa?.User?.billingCity !== null ? dataa?.User?.billingCity : 'Requis'}`}
                          </p>
                        ) : (
                          isAddressEmpty(personalAccount.billing_address) ? (
                            <p>Adresse facturation: Requis</p>
                          ) : (
                            <p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
                          )
                        )}
                        {/* {isAddressEmpty(personalAccount.billing_address) ? (
													<p>Billing Address: Requis</p>
												) : (
													<p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
												)} */}
                      </div>
                    </div>
                    <div
                      className={`clt-account-screen-inner-div-i ${selectProfile !== "admin"
                        }`}
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              {professionalProfiles.length > 0 &&
                professionalProfiles.map((profile, index) => {
                  return (
                    <Row key={index}>
                      <Col>
                        <div
                          className="d-flex justify-content-between align-items-start clt-account-screen-div clt-account-screen-div-mb"
                          onClick={() => {
                            // if (selectProfile !== "admin") {
                            handleProfessionalProfile(profile);
                            // } else return null;
                          }}
                        >
                          <div className="d-flex flex-row justify-content-center align-items-start clt-account-screen-inner-div">
                            <div className="clt-account-screen-inner-div-img">
                              <img src={IconAddProfessional} alt={IMG_ALT} />
                            </div>
                            <div className="clt-account-screen-inner-div-text">
                              <h2>Professional Profile</h2>
                              <p>
                                {
                                  profile.business_name !== null ? profile.business_name : "Nom entreprise: Requis"
                                }

                                {/* {profile.business_name
																	? profile.business_name
																	: "Business name: Requis"} */}
                              </p>
                              <p>
                                {
                                  profile.phone !== null ? profile.phone : "Téléphone: Requis"
                                }

                                {/* {profile.phone
																	? profile.phone
																	: "Phone: Requis"} */}
                              </p>
                              <p>
                                {
                                  profile.email !== null ? profile.email : "Email: Requis"
                                }
                                {/* {profile.email
																	? profile.email
																	: "Email: Requis"} */}
                              </p>
                              <p>
                                {!isAddressEmpty(profile.head_office_address)
                                  ? `${profile.head_office_address.address !== null ? profile.head_office_address.address : 'Requis'}, 
                                         ${profile.head_office_address.zip_code !== null ? profile.head_office_address.zip_code : 'Requis'},
                                         ${profile.head_office_address.city !== null ? profile.head_office_address.city : 'Requis'}`
                                  : "Siège social: Requis"
                                }
                              </p>


                              {/* <p>
																{!isAddressEmpty(profile.head_office_address)
																	? `${profile.head_office_address.address}, ${profile.head_office_address.zip_code} ${profile.head_office_address.city}`
																	: "Head office address: Requis"}
															</p> */}
                              {/* <p>
																{!isAddressEmpty(profile.billing_address)
																	? `${profile.billing_address.address}, ${profile.billing_address.zip_code} ${profile.billing_address.city}`
																	: "Billing address: Requis"}
															</p> */}
                              <p>
                                {!isAddressEmpty(profile.billing_address)
                                  ? `${profile.billing_address.address !== null ? profile.billing_address.address : 'Requis'}, 
                                  ${profile.billing_address.zip_code !== null ? profile.billing_address.zip_code : 'Requis'},
                                  ${profile.billing_address.city !== null ? profile.billing_address.city : 'Requis'}`
                                  : "Adresse facturation: Requis"
                                }
                              </p>

                              {/* <p>
																{profile.tva_number
																	? profile.tva_number
																	: "TVA number: Requis"}
															</p> */}
                              <p>
                                {profile?.tva_number
                                  ? profile?.tva_number
                                  : "Numéro de TVA : Requis"}
                              </p>

                              {/* <p>
																{profile.defrayment_note
																	? profile.defrayment_note
																	: "No defrayment code"}
															</p> */}
                              <p>
                                {profile?.defrayment_note
                                  ? profile?.defrayment_note
                                  : "Aucune note de défraiement"}
                              </p>
                            </div>
                          </div>
                          <div
                            className={`clt-account-screen-inner-div-i ${selectProfile === "admin"
                              }`}
                          >
                            <i className="fa-solid fa-chevron-right"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              {selectProfile !== "admin" ? (
                <Row>
                  <Col>
                    <button
                      className="clt-account-screen-btn"
                      onClick={handleAddProfessionalProfile}
                    >
                      <img src={IconAddProfessional} alt={IMG_ALT} /> Ajouter un profil professionnel
                    </button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          )}

          {/* Screen - Profile - Personnal - Manage1 */}
          {accountScreens.profileInformation && (
            <>
              <Row className="clt-account-section-offcanvas-row">
                <Col className="p-0">
                  {/* Heading */}
                  <Row>
                    <Col>
                      <h2 className="clt-detail-section-6-offcanvas-cat-h2">
                        Information du profil
                      </h2>
                    </Col>
                  </Row>

                  {/* List - Data */}
                  {selectProfile == "update" || selectProfile == "admin" ? (
                    <Row className="clt-detail-section-6-offcanvas-data-row m-0">
                      <Col>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Prénom");
                                setIsSidebarInputData({
                                  // value: personalAccount.first_name,
                                  // value: isTrue ? (dataa?.User?.firstName) : (personalAccount.first_name),
                                  value: dataa?.User?.firstName,
                                  placeholder: "Prénom",
                                  name: "firstName",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>First Namee</h2>
                              <p>
                                {isTrue
                                  ? dataa?.User?.firstName
                                  : personalAccount.first_name}
                              </p>
                              {/* <p>{dataa?.User.firstName}</p> */}
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Name");
                                setIsSidebarInputData({
                                  // value: personalAccount.name,
                                  value: dataa?.User?.lastName,
                                  placeholder: "Name",
                                  name: "lastName",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Name</h2>
                              <p>
                                {isTrue
                                  ? dataa?.User?.lastName
                                  : personalAccount.name}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "update") {
                                setIsSidebarTitle("Phone");
                                setIsSidebarInputData({
                                  // value: personalAccount.phone,
                                  value: dataa?.User?.phone,
                                  placeholder: "Phone",
                                  name: "phone",
                                  type: "phone",
                                });
                                setIsPhoneInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Phone</h2>
                              <p>
                                {isTrue
                                  ? dataa?.User?.phone
                                  : personalAccount.phone}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "update") {
                                setIsSidebarTitle("Email");
                                setIsSidebarInputData({
                                  value: dataa?.User?.email,
                                  // value: personalAccount.email,
                                  placeholder: "Email",
                                  name: "email",
                                  type: "email",
                                });
                                setIsEmailInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Email</h2>
                              <p>
                                {isTrue
                                  ? dataa?.User?.email
                                  : personalAccount.email}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Billing address");
                                setIsSidebarInputData({
                                  // value: personalAccount.billing_address,
                                  value: selectProfile !== "update" ? businessBilling?.billing_address : personalAccount.billing_address,
                                  placeholder: "Billing address",
                                  name: "billing",
                                  type: "billing_address",
                                });
                                setIsAddressInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Billing address</h2>
                              {/* {isAddressEmpty(personalAccount.billing_address) ? (
																<p>Requis</p>
															) : (
																<p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
															)} */}
                              {/* {isTrue ? (
                                <p>{`${dataa?.User.billingAddress}, ${dataa?.User.billingZipCode}, ${dataa?.User.billingCity}`}</p>
                              ) : isAddressEmpty(
                                  personalAccount.billing_address
                                ) ? (
                                <p>Requis</p>
                              ) : (
                                <p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
                              )} */}
                              {isTrue && !isAddressEmpty(businessBilling.billing_address) ? (
                                <p>
                                  {`${dataa?.User?.billingAddress !== null ? dataa?.User?.billingAddress : 'Requis'}, ${dataa?.User?.billingZipCode !== null ? dataa?.User?.billingZipCode : 'Requis'
                                    }, ${dataa?.User?.billingCity !== null ? dataa?.User?.billingCity : 'Requis'}`}
                                </p>
                              ) : (isAddressEmpty(personalAccount.billing_address) ? (
                                <p>Requis</p>
                              ) : (
                                <p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
                              )
                              )}

                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Profile picture");
                                setIsSidebarInputData({
                                  value: personalAccount.profile_picture,
                                  placeholder: "Profile picture",
                                  name: "profile_picture",
                                  type: "file",
                                });
                                setIsProfilePicInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Profile picture</h2>
                              <p>
                                {/* {personalAccount.profile_picture ? (
																	<img
																		className="clt-account-profile-pic"
																		src={personalAccount.profile_picture}
																		alt={IMG_ALT}
																	/>
																) : (
																	"No profile picture"
																)} */}
                                {isTrue ? (
                                  dataa?.User ? (
                                    <img
                                      className="clt-account-profile-pic"
                                      src={
                                        dataa?.User?.profilePictureUrl === null
                                          ? "No profile picture"
                                          : dataa?.User?.profilePictureUrl
                                      }
                                      alt={IMG_ALT}
                                    />
                                  ) : (
                                    "No profile picture"
                                  )
                                ) : personalAccount.profile_picture ? (
                                  <img
                                    className="clt-account-profile-pic"
                                    src={
                                      personalAccount.profile_picture === null
                                        ? "No profile picture"
                                        : personalAccount.profile_picture
                                    }
                                    alt={IMG_ALT}
                                  />
                                ) : (
                                  "No profile picture"
                                )}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="clt-detail-section-6-offcanvas-data-row m-0">
                      <Col>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Prénom");
                                setIsSidebarInputData({
                                  value: personalAccount.first_name,
                                  placeholder: "Prénom",
                                  name: "firstName",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Prénom</h2>
                              <p>{personalAccount.first_name}</p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Nom");
                                setIsSidebarInputData({
                                  value: personalAccount.name,
                                  placeholder: "Nom",
                                  name: "lastName",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Nom</h2>
                              <p>{personalAccount.name}</p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Téléphone");
                                setIsSidebarInputData({
                                  value: personalAccount.phone,
                                  placeholder: "Téléphone",
                                  name: "phone",
                                  type: "phone",
                                });
                                setIsPhoneInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Téléphone</h2>
                              <p>{personalAccount.phone}</p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Email");
                                setIsSidebarInputData({
                                  value: personalAccount.email,
                                  placeholder: "Email",
                                  name: "email",
                                  type: "email",
                                });
                                setIsEmailInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Email</h2>
                              <p>{personalAccount.email}</p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Adresse de facturation");
                                setIsSidebarInputData({
                                  value: personalAccount.billing_address,
                                  placeholder: "Adresse de facturation",
                                  name: "billing",
                                  type: "billing_address",
                                });
                                setIsAddressInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Adresse de facturation</h2>
                              {isAddressEmpty(
                                personalAccount.billing_address
                              ) ? (
                                <p>Requis</p>
                              ) : (
                                <p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
                              )}
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Photo de profil");
                                setIsSidebarInputData({
                                  value: personalAccount.profile_picture,
                                  placeholder: "Photo de profil",
                                  name: "profile_picture",
                                  type: "file",
                                });
                                setIsProfilePicInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Photo de profil</h2>
                              <p>
                                {personalAccount.profile_picture ? (
                                  <img
                                    className="clt-account-profile-pic"
                                    src={personalAccount.profile_picture}
                                    alt={IMG_ALT}
                                  />
                                ) : (
                                  "Aucune photo de profil"
                                )}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}

          {/* Screen - Profile - Profesionnal - Manage1 */}
          {accountScreens.professionalProfile && (
            <>
              <Row className="clt-account-section-offcanvas-row">
                <Col className="p-0">
                  {/* Heading */}
                  <Row>
                    <Col>
                      <h2 className="clt-detail-section-6-offcanvas-cat-h2">
                        Information du profil
                      </h2>
                    </Col>
                  </Row>

                  {/* List - Data */}
                  {selectProfile == "update" || selectProfile == "admin" ? (
                    <Row className="clt-detail-section-6-offcanvas-data-row m-0">
                      <Col>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              // if (selectProfile !== "admin") {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Nom entreprise");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.business_name,
                                  placeholder: "Nom entreprise",
                                  name: "businessName",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Nom entreprise</h2>
                              <p>
                                {/* { dataa?.profile?
                               dataa?.profile?.businessName :
                               (dataa?.Profile?
                                dataa?.Profile?.businessName : 'Requis')} */}
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.business_name !== null
                                  ? selectedProfessionalProfile?.business_name
                                  : "Requis"
                                }
                              </p>
                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "update") {
                                setIsSidebarTitle("Téléphone");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.phone,
                                  // value: dataa?.User.phone,
                                  placeholder: "Téléphone",
                                  name: "phone",
                                  type: "phone",
                                });
                                setIsPhoneInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Téléphone</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.phone !== null
                                  ? selectedProfessionalProfile?.phone
                                  : "Requis"
                                }
                                {/* {dataa?.User
                                  ? dataa.User.phone
                                  : "Requis"} */}
                              </p>
                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "update") {
                                setIsSidebarTitle("Email");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.email,
                                  // value: dataa.User.email,
                                  placeholder: "Email",
                                  name: "email",
                                  type: "email",
                                });
                                setIsEmailInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Email</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.email !== null
                                  ? selectedProfessionalProfile?.email
                                  : "Requis"}
                              </p>
                              {/* <p>
                                {dataa?.User.email
                                  ? dataa.User.email
                                  : "Requis"}
                              </p> */}
                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Siège social");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.head_office_address,
                                  // value: dataa?.Profile.headOfficeAddress,
                                  placeholder: "Siège social",
                                  name: "headOffice",
                                  type: "head_office_address",
                                });
                                setIsAddressInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Siège social</h2>
                              <p>
                                {!isAddressEmpty(
                                  selectedProfessionalProfile?.head_office_address
                                ) && selectedProfessionalProfile !== null
                                  ? `${selectedProfessionalProfile?.head_office_address.address !== null ? selectedProfessionalProfile?.head_office_address.address : 'Requis'},
                                   ${selectedProfessionalProfile?.head_office_address.zip_code !== null ? selectedProfessionalProfile?.head_office_address.zip_code : 'Requis'} 
                                   ${selectedProfessionalProfile?.head_office_address.city !== null ? selectedProfessionalProfile?.head_office_address.city : 'Requis'}`
                                  : "Requis"}
                              </p>

                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Adresse de facturation");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.billing_address,
                                  // value:selectedProfessionalProfile.billing_address,
                                  placeholder: "Adresse de facturation",
                                  name: "billing",
                                  type: "billing_address",
                                });
                                setIsAddressInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Adresse de facturation</h2>
                              <p>
                                {!isAddressEmpty(
                                  selectedProfessionalProfile?.billing_address
                                ) && selectedProfessionalProfile !== null
                                  ? `${selectedProfessionalProfile?.billing_address.address !== null ? selectedProfessionalProfile?.billing_address.address : 'Requis'}, 
                                  ${selectedProfessionalProfile?.billing_address.zip_code !== null ? selectedProfessionalProfile?.billing_address.zip_code : 'Requis'}
                                   ${selectedProfessionalProfile?.billing_address.city !== null ? selectedProfessionalProfile?.billing_address.city : 'Requis'}`
                                  : "Requis"}
                              </p>
                              {/* <p>
                                           { dataa?.profile?
                               dataa?.profile?.billingAddress :
                               (dataa?.Profile?
                                dataa?.Profile?.billingAddress : 'Requis')}

                              </p> */}

                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Numéro de TVA");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.tva_number,
                                  // value: dataa?.Profile?.tvaNumber,
                                  placeholder: "Numéro de TVA",
                                  name: "tvaNumber",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Numéro de TVA</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.tva_number !== null
                                  ? selectedProfessionalProfile?.tva_number
                                  : "Requis"}
                              </p>
                              {/* <p>
                                       { dataa?.profile?
                               dataa?.profile?.tvaNumber :
                               (dataa?.Profile?
                                dataa?.Profile?.tvaNumber : 'Requis')}
                              </p> */}
                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile == "admin" || selectProfile == "update") {
                                setIsSidebarTitle("Note de defraiement");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.defrayment_note,
                                  // value:dataa?.Profile?.defraymentNote,
                                  placeholder: "Note de defraiement",
                                  name: "defraymentNote",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Note de defraiement</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.defrayment_note !== null
                                  ? selectedProfessionalProfile?.defrayment_note
                                  : "Aucune note de defraiement"}
                              </p>
                              {/* <p>
                                       { dataa?.profile?
                               dataa?.profile?.defraymentNote :
                               (dataa?.Profile?
                                dataa?.Profile?.defraymentNote : 'Requis')}
                              </p> */}
                            </div>
                            {/* {selectProfile !== "admin" && ( */}
                            <i className="fa-solid fa-chevron-right"></i>
                            {/* )} */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="clt-detail-section-6-offcanvas-data-row m-0">
                      <Col>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Nom entreprise");
                                setIsSidebarInputData({
                                  value:
                                    selectedProfessionalProfile?.business_name,
                                  placeholder: "Nom entreprise",
                                  name: "businessName",
                                  type: "text",
                                });
                                setIsTextInputSidebar(true);
                              } else return null;
                            }}
                          >
                            <div className="w-80">
                              <h2>Nom entreprise</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.business_name !== null
                                  ? selectedProfessionalProfile?.business_name
                                  : "Requis"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Téléphone");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.phone,
                                  placeholder: "Téléphone",
                                  name: "phone",
                                  type: "phone",
                                });
                                setIsPhoneInputSidebar(true);
                              }
                            }}
                          >
                            <div className="w-80">
                              <h2>Téléphone</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.phone !== null
                                  ? selectedProfessionalProfile?.phone
                                  : "Requis"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              if (selectProfile !== "admin") {
                                setIsSidebarTitle("Email");
                                setIsSidebarInputData({
                                  value: selectedProfessionalProfile?.email,
                                  placeholder: "Email",
                                  name: "email",
                                  type: "email",
                                });
                                setIsEmailInputSidebar(true);
                              }
                            }}
                          >
                            <div className="w-80">
                              <h2>Email</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.email !== null
                                  ? selectedProfessionalProfile?.email
                                  : "Requis"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              setIsSidebarTitle("Siège social");
                              setIsSidebarInputData({
                                value:
                                  selectedProfessionalProfile?.head_office_address,
                                placeholder: "Siège social",
                                name: "headOffice",
                                type: "head_office_address",
                              });
                              setIsAddressInputSidebar(true);
                            }}
                          >
                            <div className="w-80">
                              <h2>Siège social</h2>
                              <p>
                                {!isAddressEmpty(
                                  selectedProfessionalProfile?.head_office_address
                                ) && selectedProfessionalProfile !== null
                                  ? `${selectedProfessionalProfile?.head_office_address.address !== null ? selectedProfessionalProfile?.head_office_address.address : 'Requis'},
                                   ${selectedProfessionalProfile?.head_office_address.zip_code !== null ? selectedProfessionalProfile?.head_office_address.zip_code : 'Requis'}
                                   ${selectedProfessionalProfile?.head_office_address.city !== null ? selectedProfessionalProfile?.head_office_address.city : 'Requis'}`
                                  : "Requis"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              setIsSidebarTitle("Adresse de facturation");
                              setIsSidebarInputData({
                                value:
                                  selectedProfessionalProfile?.billing_address,
                                placeholder: "Adresse de facturation",
                                name: "billing",
                                type: "billing_address",
                              });
                              setIsAddressInputSidebar(true);
                            }}
                          >
                            <div className="w-80">
                              <h2>Adresse de facturation</h2>
                              <p>
                                {!isAddressEmpty(
                                  selectedProfessionalProfile?.billing_address
                                ) && selectedProfessionalProfile !== null
                                  ? `${selectedProfessionalProfile?.billing_address.address !== null ? selectedProfessionalProfile?.billing_address.address : 'Requis'},
                                   ${selectedProfessionalProfile?.billing_address.zip_code !== null ? selectedProfessionalProfile?.billing_address.zip_code : 'Requis'}
                                    ${selectedProfessionalProfile?.billing_address.city !== null ? selectedProfessionalProfile?.billing_address.city : 'Requis'}`
                                  : "Requis"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              setIsSidebarTitle("Numéro de TVA");
                              setIsSidebarInputData({
                                value: selectedProfessionalProfile?.tva_number,
                                placeholder: "Numéro de TVA",
                                name: "tvaNumber",
                                type: "text",
                              });
                              setIsTextInputSidebar(true);
                            }}
                          >
                            <div className="w-80">
                              <h2>Numéro de TVA</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.tva_number !== null
                                  ? selectedProfessionalProfile?.tva_number
                                  : "Requis"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                            onClick={() => {
                              setIsSidebarTitle("Note de defraiement");
                              setIsSidebarInputData({
                                value:
                                  selectedProfessionalProfile?.defrayment_note,
                                placeholder: "Note de defraiement",
                                name: "defraymentNote",
                                type: "text",
                              });
                              setIsTextInputSidebar(true);
                            }}
                          >
                            <div className="w-80">
                              <h2>Note de defraiement</h2>
                              <p>
                                {selectedProfessionalProfile !== null && selectedProfessionalProfile?.defrayment_note !== null
                                  ? selectedProfessionalProfile?.defrayment_note
                                  : "Aucune note de defraiement"}
                              </p>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}
          {accountScreens.selectProfile && (
            <>
              <div>
                <Row>
                  <Col className="clt-account-screen">
                    <h2 className="clt-account-screen-title">
                      Profil pour votre réservation
                    </h2>
                    <div
                      className="d-flex justify-content-between align-items-start clt-account-screen-div"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedProfile("Personal");
                        setprofileselected(personalAccount);
                        setSelectedProfileData({
                          type: "Personal",
                          detail: `${personalAccount.first_name} . ${personalAccount.phone} . ${personalAccount.email}`,
                        });
                      }}
                      style={
                        isPersonalProfile && selectedProfile === "Personal"
                          ? {
                            borderColor: `#d32f2f`,
                            background: "#f2e4e4",
                          }
                          : selectedProfile === "Personal"
                            ? {
                              borderColor: `#000`,
                              background: "#f6f6f6",
                            }
                            : {
                              borderColor: `#ddd`,
                              background: "#fff",
                            }
                      }
                    >
                      <div className="d-flex flex-row justify-content-start align-items-start clt-account-screen-inner-div">
                        <div className="clt-account-screen-inner-div-img">
                          <div
                            className="inner-div"
                            style={
                              personalAccount.profile_picture && {
                                backgroundImage: `url(${personalAccount.profile_picture})`,
                              }
                            }
                          >
                            {!personalAccount.profile_picture && (
                              <span>
                                {personalAccount.first_name?.charAt(0)}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="clt-account-screen-inner-div-text">
                          <h2>Profil Personnel</h2>
                          <p style={isPersonalProfile && selectedProfile === "Personal" ? applyRedColor(personalAccount.first_name) : {}}>
                            {personalAccount.first_name} {personalAccount.name}
                          </p>
                          <p style={isPersonalProfile && selectedProfile === "Personal" ? applyRedColor(personalAccount.phone) : {}}>{personalAccount.phone}</p>
                          <p style={isPersonalProfile && selectedProfile === "Personal" ? applyRedColor(personalAccount.email) : {}}>{personalAccount.email}</p>
                          <p>
                            {isAddressEmpty(personalAccount.billing_address) ? (
                              <p style={isPersonalProfile && selectedProfile === "Personal" ? applyRedColor(personalAccount.billing_address.address) : {}}>Adresse facturation: Requis</p>
                            ) : (
                              <p>{`${personalAccount.billing_address.address}, ${personalAccount.billing_address.zip_code} ${personalAccount.billing_address.city}`}</p>
                            )}
                          </p>
                          <p
                            className="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditPersonalProfile();
                            }}
                          >
                            Modifier
                          </p>
                        </div>
                      </div>
                      <div className="clt-account-screen-inner-div-i">
                        <div
                          className="inner-div-circle"
                          style={
                            selectedProfile === "Personal"
                              ? {
                                borderColor: `#000`,
                              }
                              : {
                                borderColor: `#ddd`,
                              }
                          }
                        >
                          <div
                            className="subDiv"
                            style={
                              selectedProfile === "Personal"
                                ? {
                                  backgroundColor: `#000`,
                                }
                                : {
                                  backgroundColor: `white`,
                                }
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <CustomHr
                  colClass="clt-booking-hr-pd"
                  hrClass="p-0 m-0 clt-hr"
                />
                {professionalProfiles.length > 0 &&
                  professionalProfiles.map((profile, index) => {
                    return (
                      <Row key={index}>
                        <Col>
                          <div
                            className="d-flex justify-content-between align-items-start clt-account-screen-div clt-account-screen-div-mb"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedProfile("Professional");
                              setprofileselected(profile);
                              setID(profile.id);
                              // setSelectedProfileData({
                              //   type: "Professional",
                              //   detail: `${
                              //     profile.business_name
                              //       ? profile.business_name
                              //       : "Business name: Requis"
                              //   }. ${profile.phone} . ${profile.email}`,
                              // });
                            }}
                            style={
                              isProfessionalProfile === true && profileselected === profile
                                ? {
                                  borderColor: `#d32f2f`,
                                  background: "#f2e4e4",
                                }
                                : selectedProfile === "Professional" && profileselected === profile
                                  ? {
                                    borderColor: `#000`,
                                    background: "#f6f6f6",
                                  }
                                  : {
                                    borderColor: `#ddd`,
                                    background: "#fff",
                                  }
                            }
                          >
                            <div className="d-flex flex-row justify-content-center align-items-start clt-account-screen-inner-div">
                              <div className="clt-account-screen-inner-div-img">
                                <img src={IconAddProfessional} alt={IMG_ALT} />
                              </div>
                              <div className="clt-account-screen-inner-div-text">
                                <h2>Profil Professionnel </h2>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile?.business_name) : {}}>
                                  {profile.business_name
                                    ? profile.business_name
                                    : "Nom entreprise: Requis"}
                                </p>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile?.phone) : {}}>
                                  {profile?.phone
                                    ? profile.phone
                                    : "Téléphone: Requis"}
                                </p>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile.email) : {}}>
                                  {profile.email
                                    ? profile.email
                                    : "Email: Requis"}
                                </p>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile.head_office_address.address) : {}}>
                                  {!isAddressEmpty(profile.head_office_address)
                                    ? `${profile.head_office_address.address}, ${profile.head_office_address.zip_code} ${profile.head_office_address.city}`
                                    : "Siège sociale: Requis"}
                                </p>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile.billing_address.address) : {}}>
                                  {!isAddressEmpty(profile.billing_address)
                                    ? `${profile.billing_address.address}, ${profile.billing_address.zip_code} ${profile.billing_address.city}`
                                    : "Adresse de facturation: Requis"}
                                </p>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile.tva_number) : {}}>
                                  {profile?.tva_number
                                    ? profile.tva_number
                                    : "Numéro de TVA: Requis"}
                                </p>
                                <p style={isProfessionalProfile === true && profileselected === profile ? applyRedColor(profile.defrayment_note) : {}}>
                                  {profile?.defrayment_note
                                    ? profile.defrayment_note
                                    : "Aucune note de défraiement"}
                                </p>
                                <p
                                  className="link"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditProfessionalProfile(profile);
                                  }}
                                >
                                  Modifier
                                </p>
                              </div>
                            </div>
                            <div className="clt-account-screen-inner-div-i">
                              <div
                                className="inner-div-circle"
                                style={
                                  selectedProfile === "Professional" && profileselected === profile
                                    ? {
                                      borderColor: `#000`,
                                    }
                                    : {
                                      borderColor: `#ddd`,
                                    }
                                }
                              >
                                <div
                                  className="subDiv"
                                  style={
                                    selectedProfile === "Professional" && profileselected === profile
                                      ? {
                                        backgroundColor: `#000`,
                                      }
                                      : {
                                        backgroundColor: `white`,
                                      }
                                  }
                                ></div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                <Row>
                  <Col>
                    <button
                      className="clt-account-screen-btn"
                      onClick={handleAddProfessionalProfile}
                    >
                      <img src={IconAddProfessional} alt={IMG_ALT} /> Ajouter un profil professionnel
                    </button>
                  </Col>
                </Row>
                <div className="pfr_payment_space_bottom" />
              </div>
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none clt_payment_header_button_black"
                  disabled={selectedProfile == "" ? true : false}
                  onClick={() => {
                    selectProfile == "select"
                      ? onCloseProfile(professionalProfiles, selectedProfile)
                      : selectProfile == "update"
                        ? handleBack("confirm", "selectProfile")
                        : onCloseSidebar();
                  }}
                >
                  {selectProfile == "select"
                    ? "Confirmer profile"
                    : selectProfile == "update"
                      ? "Enregistrer"
                      : "Continuer"}
                </Button>
              </div>
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none clt_payment_header_button_black"
                  disabled={selectedProfile == "" ? true : false}
                  onClick={() => {
                    selectProfile === "select" && handleErrFlag() === false
                      ? ifErrorFalse()
                      : selectProfile === "select" && handleErrFlag() === true
                        ? ifErrorTrue()
                        : selectProfile == "update"
                          ? handleBack("confirm", "selectProfile")
                          : onCloseSidebar();
                  }}
                >
                  {selectProfile == "select"
                    ? "Confirmer profil"
                    : selectProfile == "update"
                      ? "Enregister"
                      : "Continuer"}
                </Button>
              </div>
            </>
          )}
          {accountScreens.confirm && (
            <ConfirmPay
              onClick={() => {
                onCloseSidebar();
                setIsUserSidebar();
              }}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <AccountInputText
        title={isSidebarTitle}
        inputData={isSidebarInputData}
        isSidebar={isTextInputSidebar}
        onCloseSidebar={() => setIsTextInputSidebar(false)}
        onUpdateData={(data) => {
          handleInputData(data);
        }}
      />
      <AccountInputPhone
        title={isSidebarTitle}
        inputData={isSidebarInputData}
        isSidebar={isPhoneInputSidebar}
        onCloseSidebar={() => setIsPhoneInputSidebar(false)}
        onUpdateData={(data) => {
          handleInputData(data);
        }}
      />
      <AccountInputEmail
        title={isSidebarTitle}
        inputData={isSidebarInputData}
        isSidebar={isEmailInputSidebar}
        onCloseSidebar={() => setIsEmailInputSidebar(false)}
        onUpdateData={(data) => {
          handleInputData(data);
        }}
      />
      <AccountInputAddress
        title={isSidebarTitle}
        inputData={isSidebarInputData}
        isSidebar={isAddressInputSidebar}
        onCloseSidebar={() => setIsAddressInputSidebar(false)}
        onUpdateData={(data) => {
          handleInputData(data);
        }}
      />
      <AccountInputProfilePicture
        title={isSidebarTitle}
        inputData={isSidebarInputData}
        isSidebar={isProfilePicInputSidebar}
        onCloseSidebar={() => setIsProfilePicInputSidebar(false)}
        onUpdateData={(data) => {
          handleUploadProfilePic(data.value);
        }}
        onDeleteProfilePic={() => {
          handleDeleteProfilePic()
        }}
      />
    </>
  );
}
