import axios from "axios";
import { API_ENDPOINT } from "../../../../constants";
import { auth } from "../../../screens/auth/firebase";


export const userlist = async (query) => {
	const queryString = new URLSearchParams(query).toString();
	const token = await auth.currentUser.getIdToken();
	return axios.get(API_ENDPOINT + `/admins/users/list?${queryString}`, {
		params: {},
		headers: { Authorization: `Bearer ${token}` },
	});
};
