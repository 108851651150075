import axios, { AxiosError } from "axios"
import { API_ENDPOINT } from "../../../constants"
import { ErrorToast } from "../../reusable/Toast"

export const AxiosErrorHandler = (error) => {
  if (error instanceof AxiosError) {
    ErrorToast(error.response.data.message)
    return;
  } 
}

export const checkUser = async (data) => {
  try {
    const { data: response } = await axios.post(API_ENDPOINT + '/auth/check', data)
    return response
  } catch (error) {
    AxiosErrorHandler(error)
  }
}