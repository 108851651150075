import React from "react";
import { Link } from "react-router-dom";


const TextLink = (props) => {
  return (
    <Link onClick={props.onPress} className={props.textLinkClass} to={props.to}>{props.text}</Link>
  );
};

export default TextLink;