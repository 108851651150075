// Import
import React, { useState, useEffect, useContext } from "react";
import { Offcanvas, Form, Row, Col, Button, Dropdown } from "react-bootstrap";
import {
  eventbirthdayData,
  IMG_ALT,
  eventweddingData,
  eventprofessionalData,
  eventreligiousData,
  section4SliderResponsive,
} from "../../../constants";
import CustomHr from "../../reusable/CustomHr";
import { Link, useHistory } from "react-router-dom";
import "./user_mybooking.css";
import { useSelector } from "react-redux";

// Import - Icon / Image
import BackArrow from "../../../assets/img/back_arrow.png";
import ConfirmPayProfilePicture1 from "../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../assets/img/confirm_pay_3.png";
import ConfirmPay5 from "../../../assets/img/confirm_pay_5.png";
import img_section_info from "../../../assets/img/img_section_info.png";
import IconRemainingPayment from "../../../assets/img/remaining_payment.png";

// import - Booking - Screens
import Booking from "../detail/booking/booking_user";

// ------------------------------------------------------------------------------

// import - Section

// import - Section - RequestPending
import SectionRequestPending from "../../reusable/SectionRequestPending";

// import - Section - Top
import SectionTop from "../../reusable/booking/SectionTop";

// import - Section - SectionRequired
import SectionRequired from "../../reusable/booking/SectionRequired";

// import - Section - Event
import SectionEvent from "../../reusable/booking/SectionEvent";
import Event from "../event/Event";

// import - Section - Date
import SectionDate from "../../reusable/booking/SectionDate";

// import - Section - Guest
import SectionGuest from "../../reusable/booking/SectionGuest";
import Guest from "../guest/Guest";

// import - Section - User
import SectionProfile from "../../reusable/booking/SectionProfile";
import Account from "../account/Account";
import { AuthContext, useAuth } from "../auth/authContext";

// import - Section - Room
import SectionBed from "../../reusable/booking/SectionRoom";
import Bed from "./bed/[SIDEBAR] User - My Booking - Option room";

// import - Section - Call / Message / Adress
import SectionContact from "../../reusable/booking/SectionContact";

// import - Section - Rule and condition
import SidebarRuleAndCondition from "../../reusable/booking/SidebarRuleAndCondition";

// import - Section - Access
import SectionAccess from "../../reusable/SectionAccess";

// import - Section - Receipt
import InvoiceOffcanvas from "../../reusable/invoice/SidebarInvoiceList";

// import - Section - Access
import SidebarAccess from "../../reusable/access/SidebarAccess";

// import - Section - Item Additional
import SectionItemAdditional from "../../reusable/booking/SectionItemAdditional";
import ProductsPrestataireMariage from "../../reusable/packoption/ProductsPrestataireMariage"; // Replace with the actual path

// import - Section - Receipt
import SidebarDocumentRequire from "../../reusable/documentrequire/SidebarDocumentRequire";

// import - Sidebar - Edit request list
import SidebarUserBookingRequest from "./user_booking_request/[SIDEBAR] User - Booking Request";

// import - Section - Price Detail
import SectionPriceDetail from "../../reusable/booking/SectionPriceDetail";

// import - Section - Payment method
import Payment from "../payment/Payment";

// import - Section - Remaining payment
import SectionRemainingPayment from "../../reusable/booking/SectionRemainingPayment";
import RemainingPayment from "./RemainingPayment";

// import - Section - Clickable
import SectionClickable from "../../reusable/SectionClickable";
import SectionClickable2 from "../../reusable/SectionClickable2";
import SectionClickable3 from "../../reusable/SectionClickable3";
import SectionClickable4 from "../../reusable/SectionClickable4";
import ConfirmAndPayOptionRoom from "./ConfirmAndPayOption";
import { ErrorToast, SuccessToast } from "../../reusable/Toast";
import axios from "axios";
import { auth } from "../auth/firebase";
import { API_ENDPOINT } from "../../../constants";
import moment from "moment";
import jwt_decode from "jwt-decode";

// import - Make a cancellation request
import TextEditor from "../../reusable/TextEditor";
import { useDispatch } from "react-redux";
import { getsubscription } from "../../../redux/actions/booking";
import { LoaderDots } from "@thumbtack/thumbprint-react";
import ProductsOptionMariage, { section4ProductsOptionMariage } from "../../reusable/packoption/ProductsOptionMariage";
/* Const - Initial screen that open - My booking - Screens */
const initialBookingScreens = {
  noBooking: true,
  bookingList: false,
  bookingDetail: false,
  confirmContinueAndPay: false,
  cancelBooking: false,
};

let actualResponse = [];

/* Const */
const MyBooking = ({
  isOpenSidebarMyBookingUser,
  onCloseOpenSidebarMyBookingUser,
  setIsOpenSidebarMyBookingUser,
  isFromMakeNewRequest,
  bookingSidebarDetail,
  isNotification,
  props,
  getBookingId,
  hel,
  setIsAccountDrawer
}) => {

  /* const {
    message,
    setMessage,
  } = props.data; */

  /* Const - My booking - Screens */
  const [bookingScreens, setBookingScreens] = useState(initialBookingScreens);
  const [message, setMessage] = useState('');
  const [reason, setReason] = useState('Event is not planned anymore');


  /* ------------------------------------------------------------ */

  /* Const - My booking - Screen : Booking list */

  /* Const - Header - Button event filter : Order State */
  const CustomEventToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="clt_mybooking_header_dropdown"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {eventFilter} <i className="fas fa-chevron-down"></i>
    </Link>
  ));

  const [eventFilter, setEventFilter] = useState('All events');
  const allEvents = useSelector((state) => state.allEvents.events);

  /* Const - Header - Button filter : Order State */
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="clt_mybooking_header_dropdown"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {filter} <i className="fas fa-chevron-down"></i>
    </Link>
  ));


  /* Const - Booking list - Button filter : Order State */
  const [bookingList, setBookingList] = useState([
    {
      type: "Ongoing",
      bookings: [
        {
          id: 1,
          image: ConfirmPayProfilePicture1,
          eventType: eventweddingData,
        },

      ],
    },
    {
      type: "Coming",
      bookings: [
        {
          id: 1,
          image: ConfirmPayProfilePicture1,
          eventType: eventweddingData,
        },
        {
          id: 1,
          image: ConfirmPayProfilePicture1,
          eventType: eventweddingData,
        },
        {
          id: 1,
          image: ConfirmPayProfilePicture1,
          eventType: eventprofessionalData,
        },
      ],
    },
    {
      type: "Finish",
      bookings: [
        {
          id: 1,
          image: ConfirmPayProfilePicture1,
          eventType: eventreligiousData,
        },
      ],
    },
    {
      type: "Cancelled",
      bookings: [
        {
          id: 1,
          image: ConfirmPayProfilePicture1,
          eventType: eventbirthdayData,
        },
      ],
    },
  ]);




  /* Const - My booking - Screen : Booking detail */

  /* Const - Section - Request  */
  const [isOpenSidebarUserBookingRequest, setIsOpenSidebarUserBookingRequest] = useState(false);

  /* Const - Section - Event */
  const [eventType, setEventType] = useState(eventweddingData);
  const [isEventSidebar, setIsEventSidebar] = useState(false);
  const [currentBooking, setcurrentBooking] = useState(false);

  /* Const - Section - Date */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);
  let bookingProps = {
    isFromBooking: true,
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };
  const handleDateEdit = () => {
    setIsBookingSidebar(true);
    setIsFromEditRequest(true)
    setBookingScreens((prevState) => ({
      ...prevState,
      dateAndTimeSlots: true,
      confirmContinueAndPay: false,
      confirmAndPay: false,
    }));

  };

  const dispatch = useDispatch();

  /* Const - Section - Guest */
  const [isGuestSidebar, setIsGuestSidebar] = useState(false);
  const [guestCount, setGuestCount] = useState(10);

  /* Const - Section - Option */
  // Define state to manage the visibility of the products sidebar list
  const [isProductsSidebarListOpen, setIsProductsSidebarListOpen] = useState(false);
  const { isProductsSidebarOpen, setIsProductsSidebarOpen } = useContext(AuthContext);
  // Function to open the products sidebar list
  const openProductsSidebarList = () => {
    setIsProductsSidebarListOpen(true);
    console.log(selectedBooking?.Event?.title)

    if (
      selectedBooking?.Event?.title === 'Mariage' ||
      selectedBooking?.Event?.title === 'Séminaire' ||
      selectedBooking?.Event?.title === 'Repas noël' ||
      selectedBooking?.Event?.title === 'Anniversaire' ||
      selectedBooking?.Event?.title === 'Baptème' ||
      selectedBooking?.Event?.title === 'Convention' ||
      selectedBooking?.Event?.title === 'Soirée' ||
      selectedBooking?.Event?.title === 'Cousinade' ||
      selectedBooking?.Event?.title === 'Afterwork'
    ) {
      setIsProductsSidebarOpen(true);
    }
  };

  /* Const - Section room */
  const [isBedSidebar, setIsBedSidebar] = useState(false);
  const [confirmPayOptionRoom, setConfirmPayOptionRoom] = useState(false);
  const [bedCount, setBedCount] = useState([
    { roomTitle: "Room 1", nights: "1 night (10 €)" },
    { roomTitle: "Room 2", nights: "2 night (20 €)" },
  ]);
  const [editBedSettings, setEditBedSettings] = useState(false)
  const handleBed = () => {
    setIsBedSidebar(true);
    setEditBedSettings(true)
  };


  /* Const - Section room - Is from room */
  const [isFromRoom, setIsFromRoom] = useState(false);
  isFromRoom && (bookingProps.isFromRoom = true);
  const [priceDetailsRooms, setpriceDetailsRooms] = useState([]);
  const [selectedRoomsDetail, setSelectedRoomsDetail] = useState([]);
  const [roomRefundDetail, setRoomRefundDetail] = useState([]);

  /* Const - Section - Remaining payment */
  const [isRemainingSidebar, setIsRemainingSidebar] = useState(false);
  const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);


  const [isFromEditRequest, setIsFromEditRequest] = useState(false);

  /* Const - Section - User profile */
  const [isAccountInfo, setIsAccountInfo] = useState(false);
  const { isLoggedIn, user, socket } = useAuth();
  const [selectedProfile, setSelectedProfile] = useState({
    type: "Personal",
    detail: "John Doe . 055555555 . johndoe@gmail.com",
  });


  const [bookingData, setBookingData] = useState({});
  const [selectedBooking, setSelectedBooking] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isRemainingLoading, setIsRemainingLoading] = useState(false);
  const [filter, setFilter] = useState('See All');

  const [subscription, setSubscription] = useState({});
  useEffect(() => {
    if (user) {
      setSelectedProfile({
        type: "Personal",
        detail: `${user?.firstName} . ${user?.phone} . ${user?.email}`,
      });
    }
  }, [user]);

  useEffect(() => {
    if (auth.currentUser) {
      if (isNotification) {
        setIsLoading(false);
        setSelectedBooking(bookingSidebarDetail);
        setBookingScreens(() => ({
          bookingDetail: true,
        }));
      } else {
        getBookings(true);
      }
      if (socket) {
        socket.on('cancelRequestSent', function (dataComing, callback) {
          setSelectedBooking(dataComing.data);
          if (actualResponse.length) {
            const newStateToGroup = actualResponse.map(obj =>
              obj.id === dataComing.data.id ? dataComing.data : obj
            );
            actualResponse = newStateToGroup;
            const groups = newStateToGroup.reduce((groups, item) => ({
              ...groups,
              [item.status]: [...(groups[item.status] || []), item]
            }), {});
            setBookingData(groups);
          }
          setBookingScreens(() => ({
            cancelBooking: false,
            bookingDetail: true,
          }))
        });
      }
    }
  }, [auth.currentUser]);

  useEffect(() => {
    let check = localStorage.getItem('user-login-in');
    if (check && check == 'true') {
      console.log('user loggedIn');
    } else {
      let decode = jwt_decode(hel);
      if (decode.userId) {
        setIsAccountDrawer();
      } else {
        setIsAccountDrawer();
        ErrorToast("Note", "Votre demande est associée à aucun compte, vous devez en créer un.");
      }
      onCloseOpenSidebarMyBookingUser();
    }
  }, []);

  /* Const - Section - Invoice */
  const [isInvoiceDetail, setIsInvoiceDetail] = useState(false);

  /* Const - Section - Document Require */
  const [isSidebarDocumentRequire, setIsSidebarDocumentRequire] = useState(false);

  /* Const - Section - Access */
  const [isSidebarAccess, setIsSidebarAccess] = useState(false);

  const history = useHistory();

  /* Const - Section - Rules and conditions */
  const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] = useState(false);

  const [selectedCard, setselectedCard] = useState();

  /* Const - Make a cancellation request */
  const [content, setContent] = useState("");

  /* Const - Frontend - Navigation between screen - Back button */
  const handleBack = (from, to) => {
    setBookingScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };

  const paymentCard = (card) => {
    console.log("SELECTED PAYMENT CARD");
    console.log(card);
    setselectedCard(card);
  }

  const getBookings = async (load = false) => {
    setIsLoading(load)
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(API_ENDPOINT + "/booking/user/list",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      actualResponse = response.data;
      if (response.data.length) {
        const groups = response.data.reduce((groups, item) => ({
          ...groups,
          [item.status]: [...(groups[item.status] || []), item]
        }), {});
        setBookingData(groups);
        if (load) {
          if (getBookingId) {
            let filtered = response.data.filter(x => x.id == getBookingId);
            if (filtered.length) {
              setSelectedBooking(filtered[0]);
              setBookingScreens((prevState) => ({
                ...prevState,
                noBooking: false,
                bookingDetail: true,
              }));
              history.replace('/');
            } else {
              setBookingScreens((prevState) => ({
                ...prevState,
                noBooking: false,
                bookingList: true,
              }));
              history.replace('/');
              ErrorToast("Note", "La réservation n'existe pas.");
            }
          } else {
            setBookingScreens((prevState) => ({
              ...prevState,
              noBooking: false,
              bookingList: true,
            }));
          }
        }
      } else {
        if (load) {
          setBookingScreens((prevState) => ({
            ...prevState,
            noBooking: true,
            bookingList: false,
          }));
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ErrorToast(error?.response?.data?.message);
    }
  }
  const openRemainingPayment = async (selectedBooking) => {
    if (selectedBooking?.subscription_payments?.length !== 3) {
      setIsRemainingLoading(true);
      console.log("OPENING REMAINING 34");
      const token = await auth.currentUser.getIdToken();
      // const subscription = await dispatch(getsubscription(selectedBooking.id));
      const subscription = await axios.get(API_ENDPOINT + "/booking/getsubscription/" + selectedBooking.id,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("OPENING REMAINING 3");
      setSubscription(subscription);
      paymentCard(subscription?.data?.message?.card);
      console.log("OPENING REMAINING 2");
      setIsRemainingLoading(false);
      console.log("OPENING REMAINING 1");
      setIsRemainingSidebar(true);
      console.log("OPENING REMAINING");
      console.log(isRemainingSidebar);
    }
  }


  /* Const - Frontend - Navigation between screen - No Booking to Booking list */
  const handleAddBooking = () => {
    history.push('/detail');
  };

  /* Const - Frontend - Navigation between screen - Booking List to Booking Detail */
  const handleBookingDetail = (data) => {
    setSelectedBooking(data);
    setBookingScreens((prevState) => ({
      ...prevState,
      bookingList: false,
      bookingDetail: true,
    }));
  };
  console.log('this is selected boooking', selectedBooking);
  /* Const - Button - Sent a cancellation request */
  const handleCancelBooking = () => {
    setBookingScreens((prevState) => ({
      ...prevState,
      bookingDetail: false,
      cancelBooking: true,
    }));
  };

  /* Const - Button - Sent a cancellation request */
  const handleSentCancelRequest = async () => {
    setIsLoading(true);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(API_ENDPOINT + "/booking/request",
        {
          reason: reason,
          message: message,
          bookingId: selectedBooking.id,
          status: 'Sent',
          notificationData: {
            title: "Demande annulation envoyé",
            message: "Votre demande d'annulation est envoyé, suivez l'avancé depuis le détail de votre réservation. "
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLoading(false);
      getBookings();
      setSelectedBooking(response.data.data);
      setBookingScreens((prevState) => ({
        ...prevState,
        bookingDetail: true,
        cancelBooking: false,
      }));
    } catch (error) {
      setIsLoading(false);
      SuccessToast(error?.response?.data?.message);
      setBookingScreens((prevState) => ({
        ...prevState,
        bookingDetail: true,
        cancelBooking: false,
      }));
    }




    //here code to cancel the booking

  };

  /* Const - Use effect - Open directly sidebar booking detail */
  useEffect(() => {
    if (isFromMakeNewRequest) {
      setBookingScreens(() => ({
        bookingDetail: true,
      }));
    }
  }, [])

  /* Const - Refuse request */
  const OnCloseFromMakeNewRequest = () => {
    onCloseOpenSidebarMyBookingUser(true);
  };

  const calculatePriceList = (val) => {
    let priceList = [
      { title: "Créneau", tooltipMsg: null, price: `${selectedBooking.ChildTimeSlot?.price}€` },
    ];
    if (val?.BookingRooms?.length) {
      for (let index = 0; index < val?.BookingRooms?.length; index++) {
        const element = val.BookingRooms[index];
        let totalPrice = element.quantity * element.price;
        // priceList.push({ title: `${element.title} (${element.quantity} night)`, tooltipMsg: null, price: `${totalPrice}€` });
        priceList.push({ title: `${element.title} (${element.quantity} nuit)`, tooltipMsg: null, price: `${element.price}€` });
      }
    }
    return priceList;
  }


  const handleGuestUpdate = async (guestCount) => {
    if (guestCount != selectedBooking.guest) {
      setIsLoading(true);
      try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.patch(API_ENDPOINT + `/booking/${selectedBooking.id}`,
          {
            guest: guestCount
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        getBookings();
        setIsLoading(false);
        setIsGuestSidebar(false);
        setGuestCount(guestCount);
        setSelectedBooking({
          ...selectedBooking,
          guest: guestCount
        });
        SuccessToast("Enregistré", "Votre réservation a été mise à jour");
      } catch (error) {
        setIsLoading(false);
        ErrorToast(error?.response?.data?.message);
      }
    } else {
      setIsGuestSidebar(false);
      setGuestCount(guestCount);
    }

  }

  const calculateTot = (selectedBooking) => {
    console.log(selectedBooking.subscription_payments)
    let tot = 0;
    for (let i = 0; i < selectedBooking?.subscription_payments?.length; i++) {
      console.log("Athulee");
      console.log(selectedBooking);
      tot += (selectedBooking?.subscription_payments[i]?.subtotal / 100);
    }
    return tot;
  }

  const calculateInstallements = (installment) => {
    var today = new Date();
    var date_to_reply = new Date(selectedBooking?.ChildTimeSlot?.startDate);
    var timeinmilisec = date_to_reply.getTime() - today.getTime();
    var totalDays = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
    var slotsround = Math.round(totalDays / 3);
    // setTotDays(totalDays);
    if (installment === 2) {
      return slotsround;
    } else {
      return slotsround * 2;
    }
  }

  const getBalancePayment = (selectedBooking) => {
    const price = Math.round((selectedBooking.price / 3 + Number.EPSILON) * 100) / 100;
    let two = `${price}€ dans ${calculateInstallements(2)} jours`;
    let three = `${price}€ dans ${calculateInstallements(3)} jours`;
    if (selectedBooking?.subscription_payments?.length === 1) {
      return [two, three];
    } else if (selectedBooking?.subscription_payments?.length === 2) {
      return [three];
    } else {
      return ["Aucun paiement restant"];
    }
  }

  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
        <h2 className="clt-detail-left-section-2-h2">{rest.title}</h2>
        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-detail-left-section-2-cards-arrowBtn me-2"
                : "clt-detail-left-section-2-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? "disable clt-detail-left-section-2-cards-arrowBtn"
                : "clt-detail-left-section-2-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarMyBookingUser}
        onHide={onCloseOpenSidebarMyBookingUser}
      >

        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">

          {/* Header - Screen - No booking */}
          {bookingScreens.noBooking && (
            <div className="d-flex justify-content-start align-items-center">
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseOpenSidebarMyBookingUser}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between">
                <Offcanvas.Title>Mes réservations</Offcanvas.Title>
              </div>
            </div>
          )}

          {/* Header - Screen - Booking list */}
          {bookingScreens.bookingList && (
            <div className="d-flex justify-content-start align-items-center">
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseOpenSidebarMyBookingUser}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between">
                <Offcanvas.Title>Mes réservations</Offcanvas.Title>
              </div>
            </div>
          )}

          {/* Header - Screen - Booking detail */}
          {bookingScreens.bookingDetail && (
            <div className="d-flex justify-content-start align-items-center">
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => {
                  if (isFromMakeNewRequest) {
                    OnCloseFromMakeNewRequest(true);
                  } else if (isNotification) {
                    onCloseOpenSidebarMyBookingUser();
                  }
                  else handleBack("bookingDetail", "bookingList")
                }}

              >


                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>


              <div className="d-flex align-items-center justify-content-between">
                <Offcanvas.Title>
                  {selectedBooking.status === 'comming'
                    ? 'A venir'
                    : selectedBooking.status === 'Ongoing'
                      ? 'En cours'
                      : selectedBooking.status === 'Finish'
                        ? 'Terminé'
                        : selectedBooking.status === 'Cancel'
                          ? 'Annulé'
                          : `${selectedBooking.status}`} · #{`${selectedBooking.id}`}
                </Offcanvas.Title>
              </div>
            </div>
          )}

          {/* Header - Screen - Continue to confirm and pay */}
          {bookingScreens.confirmContinueAndPay && (
            <div className="d-flex justify-content-start align-items-center">
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() =>
                  handleBack("confirmContinueAndPay", "bookingDetail")
                }
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between">
                <Offcanvas.Title>Continuer à confirmer et payer</Offcanvas.Title>
              </div>
            </div>
          )}

          {/* option see */}
          {
            isProductsSidebarOpen && (
              <>
                {selectedBooking?.Event?.title === "Mariage" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Séminaire" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Repas noël" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Anniversaire" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Baptème" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Convention" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Soirée" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Cousinade" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}
                {selectedBooking?.Event?.title === "Afterwork" && (
                  <ProductsOptionMariage
                    products={section4ProductsOptionMariage}
                    handleProductSlider={isProductsSidebarOpen}
                    sliderProps={{
                      responsive: section4SliderResponsive,
                      arrows: false,
                      shouldResetAutoplay: false,
                      renderButtonGroupOutside: true,
                      customButtonGroup: <DetailMainSliderArrows title="Option" />,
                      className: "clt-detail-left-section-4-cards-div",
                    }}
                    customButtonGroupTitle="Option"
                  />
                )}

              </>
            )
          }

          {/*  */}

          {/* Header - Screen - Cancel booking */}
          {bookingScreens.cancelBooking && (
            <div className="d-flex justify-content-start align-items-center">
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => handleBack("cancelBooking", "bookingDetail")}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between">
                <Offcanvas.Title>Envoyé une demande d'annulation</Offcanvas.Title>
              </div>
            </div>
          )}

          {/* Header - Screen - Cancel booking - Button event filter : State 
          {!bookingScreens.noBooking &&
          !bookingScreens.bookingDetail &&
          !bookingScreens.confirmContinueAndPay &&
          !bookingScreens.cancelBooking && (
            <Dropdown>
              <Dropdown.Toggle as={CustomEventToggle}>
                {eventFilter === 'All events' ? 'Tout les évenements' : eventFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                <Dropdown.Item
                  onClick={() => setEventFilter('All events')}
                  className={`clt_mybooking_header_dropdown_option ${
                    eventFilter === 'All events' ? 'active' : ''
                  }`}
                >
                  Tout les évenements
                </Dropdown.Item>
                {allEvents?.filter((x) => x.isActive === true).map((event, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setEventFilter(event.title)}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    {event.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}*/}

          {/* Header - Screen - Cancel booking - Button filter : State 
          {!bookingScreens.noBooking &&
            !bookingScreens.bookingDetail &&
            !bookingScreens.confirmContinueAndPay &&
            !bookingScreens.cancelBooking && (
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                  <Dropdown.Item
                    onClick={() => setFilter('See All')}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    See All
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setFilter("Ongoing")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Ongoing
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setFilter("Comming")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Comming
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setFilter("Finished")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Finished
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setFilter("Cancelled")}
                    className="clt_mybooking_header_dropdown_option"
                  >
                    Cancelled
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}*/}
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body
          className={`clt_mybooking_offcanvas_body ${(bookingScreens.noBooking || bookingScreens.confirmContinueAndPay) && `d-flex flex-column justify-content-center align-items-center`} 
          ${bookingScreens.bookingDetail && "p-0"}`}
        >
          {/* Body - Screen - No booking */}
          {!isLoading && bookingScreens.noBooking && (
            <Row>
              <Col>
                <div className="clt_mybooking_offcanvas_screen1_div d-flex flex-column justify-content-center align-items-center">
                  <h2>Aucune réservation</h2>
                  <p>Vous n'avez aucune réservation pour le moment</p>
                  <a href="https://chateaulatournelle.com/detail/Anniversaire">
                    <Button className="shadow-none">
                      <span>Faire une réservation</span>
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          )}
          {isLoading && bookingScreens.noBooking && (
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-center">
                  <LoaderDots theme="muted" />
                </div>
              </Col>
            </Row>
          )}
          {/* Body - Screen - Booking list */}
          {!isLoading && bookingScreens.bookingList && Object.keys(bookingData).map((key, index) => (
            <Row key={index}>
              <Col lg={12} cs={12}>
                <h2 className="clt-mybooking-list-h2">{filter === 'See All' ? `${key}` : `${filter}`}</h2>
                {(key === filter.toLowerCase() || filter === 'See All') &&
                  bookingData[key]?.map((bkData, i) => (
                    (eventFilter === 'All events' || (bkData?.Event?.title === eventFilter)) && (
                      <div
                        key={i}
                        className="d-flex justify-content-start align-items-center clt-mybooking-list-div"
                        onClick={() => handleBookingDetail(bkData)}
                      >
                        <img src={ConfirmPayProfilePicture1} alt={IMG_ALT} />
                        <div className="d-flex flex-column justify-content-center align-items-start inner-div">
                          <h2>CLT</h2>
                          <p>{`${moment(bkData?.ChildTimeSlot?.startDate).format("DD MMM YYYY")} - ${moment(bkData?.ChildTimeSlot?.startHour, "HH:mm:ss").format("HH:mm")} ➔`}</p>
                          <p>{`${moment(bkData?.ChildTimeSlot?.endDate).format("DD MMM YYYY")} - ${moment(bkData?.ChildTimeSlot?.endHour, "HH:mm:ss").format("HH:mm")}`}</p>
                          <div className="clt-mybooking-list-span-div">
                            <span
                              style={{
                                backgroundColor: `${bkData?.Event?.background}30`,
                                color: bkData?.Event?.color,
                              }}
                            >
                              {bkData?.Event?.title}
                            </span>
                            <span
                              style={{
                                backgroundColor: `${bkData?.Event?.background}30`,
                                color: bkData?.Event?.color,
                              }}
                            >
                              {`#${bkData?.id}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  ))
                }
              </Col>
            </Row>
          ))}







          {/* Body - Screen - Booking detail */}
          {bookingScreens.bookingDetail && Object.keys(selectedBooking).length && (
            <>

              {/* Section - Top */}
              <div className="clt_mybooking_offcanvas_body">
                <SectionTop
                  image={ConfirmPayProfilePicture1}
                  title="Château Latournelle"
                  subTitle={`Réservation pour un évenement ${selectedBooking?.Event?.title}`}
                />
                {
                  (selectedBooking?.CancelBookings?.some(x => x.status === 'Sent' || x.status === 'Accept') &&
                  selectedBooking?.status !== 'Ongoing' &&
                  selectedBooking.status !== 'Finish' &&
                  selectedBooking.status !== 'Cancel') ? (
                    <>
                      <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                      <SectionRequestPending
                        innerTitle="Demande"
                        innerSubTitle={`Vous avez ${selectedBooking?.CancelBookings?.filter(x => x.status === 'Sent' || x.status === 'Accept').length} demande(s) concernant cette réservation`}
                        onClick={() => setIsOpenSidebarUserBookingRequest(true)}
                      />
                    </>
                  ) : null
                }
              </div>

              {selectedBooking?.status === 'comming' || selectedBooking?.status === 'Ongoing' ? (
                <>
                  {!selectedBooking?.BookingDocuments?.some(x => x.type === 'docA') ||
                    !selectedBooking?.BookingDocuments?.some(x => x.type === 'docB') ||
                    !selectedBooking?.BookingDocuments?.some(x => x.type === 'docC') ||
                    selectedBooking?.subscription_payment_failed ? (
                      <>
                        <CustomHr
                          rowClass="p-0 m-0"
                          colClass="clt-booking-hr-pd p-0 w-100"
                          hrClass="p-0 m-0 clt-hr-2"
                        />

                        <div className="clt_mybooking_offcanvas_body">
                          <h2 className="clt-mybooking-title2">Requis</h2>

                          {selectedBooking?.subscription_payment_failed ? (
                            <SectionRequired
                              innerTitle="Paiement manquant"
                              innerSubTitle="Action requise pour effectuer la réservation "
                              onClick={() => openRemainingPayment(selectedBooking)}
                              ButtonText="Payer"
                            />
                          ) : null}

                          {!selectedBooking?.BookingDocuments?.some(x => x.type === 'docA') ||
                            !selectedBooking?.BookingDocuments?.some(x => x.type === 'docC') &&
                            selectedBooking?.status === 'comming' ? (
                              <SectionRequired
                                innerTitle="Carte identité requise"
                                innerSubTitle="Action requise pour effectuer la réservation "
                                onClick={() => setIsSidebarDocumentRequire(true)}
                                ButtonText="Ajouter document"
                              />
                            ) : null}

                          {!selectedBooking?.BookingDocuments?.some(x => x.type === 'docB') &&
                            selectedBooking?.status === 'comming' ? (
                              <SectionRequired
                                innerTitle="Attestation assurance requise"
                                innerSubTitle="Action requise pour effectuer la réservation"
                                onClick={() => setIsSidebarDocumentRequire(true)}
                                ButtonText="Ajouter document"
                              />
                            ) : null}
                        </div>
                      </>
                    ) : null}
                </>
              ) : null}

              <CustomHr
                rowClass="p-0 m-0"
                colClass="clt-booking-hr-pd p-0 w-100"
                hrClass="p-0 m-0 clt-hr-2"
              />



              {/* Section - Booking detail */}
              <div className="clt_mybooking_offcanvas_body">
                <h2 className="clt-mybooking-title">Votre réservation</h2>
                <SectionEvent
                  image={`${selectedBooking?.Event?.icon}`}
                  subText={selectedBooking?.Event?.title}
                // setIsEventSidebar={() => setIsEventSidebar(true)}
                // buttonText="Edit"
                />
                <SectionDate
                  image={ConfirmPay2}
                  // handleDateEdit={handleDateEdit}
                  subText1={selectedBooking.ChildTimeSlot?.startDate}
                  subText2={selectedBooking.ChildTimeSlot?.endDate}
                  isDateSelect={true}
                  selectedTimeSlot={{ startHour: selectedBooking.ChildTimeSlot?.startHour, endHour: selectedBooking.ChildTimeSlot?.endHour }}
                />
                <SectionGuest
                  image={ConfirmPay3}
                  subText={`${selectedBooking.guest} invités`}
                  setIsGuestSidebar={() => setIsGuestSidebar(true)}
                  buttonText="Modifier"
                />
                <SectionItemAdditional
                  image={img_section_info}
                  subText="Aucune option"
                  optionDetail={true}
                  handleSidebarOpen={openProductsSidebarList}
                  buttonText="Voir  option"
                  hideButton={true}
                />

                {/*
              
                {
                  selectedBooking.BookingRooms.length == 0 ?
                    <SectionBed
                      title="Chambre"
                      image={ConfirmPay5}
                      subText={selectedBooking.BookingRooms.length == 0 ? "Aucune chambre" : null}
                    //roomSelected={bedCount}
                    // setIsBedSidebar={handleBed}
                    // buttonText={bedCount.length > 0 ? "Edit" : "Add"}
                    />
                    :
                    <SectionBed
                      title="Chambre"
                      image={ConfirmPay5}
                      roomSelected={selectedBooking.BookingRooms}
                    // setIsBedSidebar={handleBed}
                    // buttonText={bedCount.length > 0 ? "Edit" : "Add"}
                    />
                }
              */}
              </div>
              <CustomHr
                rowClass="p-0 m-0"
                colClass="clt-booking-hr-pd p-0 w-100"
                hrClass="p-0 m-0 clt-hr-2"
              />

              {/* ---------------------- */}
              {/* Section - Price detail */}

              <div className="clt_mybooking_offcanvas_body">
                <SectionPriceDetail
                  title="Détail prix"
                  priceListData={calculatePriceList(selectedBooking)}
                  parent="user_mybooking"
                  totalPrice={selectedBooking.price}
                />
              </div>
              <CustomHr
                rowClass="p-0 m-0"
                colClass="clt-booking-hr-pd p-0 w-100"
                hrClass="p-0 m-0 clt-hr-2"
              />

              {/* ----------------------------- */}
              {/* Section - Information réservation */}

              <div className="clt_mybooking_offcanvas_body">
                <SectionProfile
                  title="Information réservation"
                  handleClick={() => setIsAccountInfo(true)}
                  text={selectedBooking?.profile_type === 'professional'
                    ? "Profil professionnel" : "Profil personnel"}
                  subText={
                    <span>
                      {selectedBooking?.Profile && selectedBooking?.profile_type === "professional"
                        ? `${selectedBooking?.Profile.businessName !== null ? selectedBooking?.Profile.businessName : 'Requis'}. ${user.phone || ""}. ${user.email || ""}`
                        : selectedBooking?.User && selectedBooking.profile_type === "personal"
                          ? `${user?.firstName || ""}. ${user?.phone || ""}. ${user?.email || ""}`
                          : "Requis"}
                    </span>
                  }
                />
                {
                  selectedBooking?.payment_id?.startsWith("sub_") ?
                    <SectionRemainingPayment
                      isLoading={isRemainingLoading}
                      image={IconRemainingPayment}
                      title={`Paiement restant: ` + `${selectedBooking?.subscription_payments === null ? '0' : (3 - selectedBooking?.subscription_payments?.length)}`}
                      // paymentTexts={["No remaining payment"]}
                      paymentTexts={getBalancePayment(selectedBooking)}
                      selectedBooking={selectedBooking}
                      onClick={() => openRemainingPayment(selectedBooking)}
                    /> :
                    <></>
                }
                <SectionClickable4
                  innerTitle="Document obligatoire "
                  innerSubTitle="Afficher document obligatoire "
                  onClick={() => setIsSidebarDocumentRequire(true)}
                />
                <SectionClickable2
                  innerTitle="Conditions réservation"
                  innerSubTitle="Afficher conditions de réservation"
                  onClick={() => setIsSection6SiderbarDetail(true)}
                />
                <SectionAccess
                  innerTitle="Remise des clés"
                  innerSubTitle="Remise en main propre"
                  onClick={() => setIsSidebarAccess(true)}
                />
                <SectionClickable
                  innerTitle="Facture"
                  innerSubTitle="Afficher facture"
                  onClick={() => setIsInvoiceDetail(true)}
                />
              </div>

              <CustomHr
                rowClass="p-0 m-0"
                colClass="clt-booking-hr-pd p-0 w-100"
                hrClass="p-0 m-0 clt-hr-2"
              />

              {
                selectedBooking.status !== 'Ongoing' && selectedBooking.status !== 'Finish' && selectedBooking.status !== 'Cancel' ?
                  <div className="clt_mybooking_offcanvas_body">
                    <Row>
                      <Col>
                        <div className="clt-booking-cp-div7-btn mb-5">
                          <Button
                            className="shadow-none clt-booking-btn-red"
                            onClick={handleCancelBooking}
                          >
                            <span>Annuler réservation</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  : null
              }
            </>
          )}

          {/* Body - Screen - Continue to confirm and pay */}
          {bookingScreens.confirmContinueAndPay && (
            <>
              <Row>
                <Col>
                  <div className="d-flex flex-column justify-content-center align-items-center clt-cancel-booking">
                    <i className="fa fa-info clt-cancel-booking"></i>
                    <h2>Are you sure ?</h2>
                    <p>
                      Subtext with <Link to={"/"}>text Link</Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/* Body - Screen - Cancel booking */}
          {bookingScreens.cancelBooking && (
            <>
              <Row>
                <Col>
                  {/* Section - Cancellation Request - Information message 
                  <div className="d-flex justify-content-start align-items-center clt-booking-cb-div2">
                    <i className="fa fa-info-circle" aria-hidden="true" />
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cb-div2-text">
                      <h2>Cancellation request</h2>
                      <p>
                        For cancel your booking, you have to make a request of cancellation.
                      </p>
                    </div>
                  </div>

                  <CustomHr
                    colClass="clt-booking-hr-pd"
                    hrClass="p-0 m-0 clt-hr"
                  />*/}

                  <h2 class="clt_admin_dash_timeslot_edit_box_heading_2">
                    Demande d'annulation
                  </h2>
                  <p class="clt_admin_dash_timeslot_edit_box_subheading"> Pour annuler votre réservation, vous devez en faire la demande en indiquant le motif de votre annulation et un message. Le château répondra à votre demande, suivez l'état de votre demande depuis votre réservation. </p>


                  {/* Section - Input select */}
                  <div className="clt_admin_dash_timeslot_edit_box">
                    <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                      <Form.Select
                        id="floatingInput1"
                        type="select"
                        placeholder="Reason of the cancellation"
                        //value={reason}
                        onChange={e => {
                          setReason(e.target.value);
                        }}
                      >
                        <option value="Event is not planned anymore">L'événement n'est plus prévu</option>
                        <option value="Erreur de réservation">Erreur de réservation</option>
                        <option value="Modification des préférences">Modification des préférences</option>
                        <option value="Autres">Autres</option>
                      </Form.Select>
                      <label htmlFor="floatingInput1">Raison de l'annulation</label>
                    </Form.Floating>
                  </div>

                  {/*----------------------------- */}
                  {/* Section - Cancellation Request - Reason */}
                  <div className="clt_inputFloatDetailDiv">
                    <Form.Floating className="clt_inputFloatDetail">
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        style={{ height: "100px" }}
                        id="floatingInput1"
                        type="text"
                        // Bellow, two line useful permitting to edit and pull value (You can search "props.data" in this file)
                        onChange={(event) => setMessage(event.target.value)}
                        value={message}
                      />
                      <label htmlFor="floatingInput1">Message</label>
                    </Form.Floating>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Offcanvas.Body>



        {/* Footer */}
        <div>
          {/* Footer - Screen - Continue to confirm and pay */}
          {bookingScreens.confirmContinueAndPay && (
            <>
              <div className="clt-datepicker-sidebar-footer">
                <div className="inner-div">
                  <Button
                    onClick={() => {

                      if (editBedSettings) {
                        setConfirmPayOptionRoom(true);
                      }
                      else {
                        setIsFromRoom(true);
                        setIsBookingSidebar(true);
                      }
                    }}
                    className="clt-datepicker-sidebar-footer-btn black_btn shadow-none"
                  >
                    <span>Continuer</span>
                  </Button>
                </div>
              </div>
            </>
          )}

          {/* Footer - Screen - Cancel booking */}
          {bookingScreens.cancelBooking && (
            <>
              <div className="clt_admin_dash_footer">
                <Button
                  disabled={isLoading}
                  onClick={handleSentCancelRequest}
                  className="clt_admin_dash_timeslot_formButton shadow-none"
                >
                  <span>Envoyé demande</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </Offcanvas>



      {/* Function */}

      {/* Function - Booking - Screens */}
      {/* <Booking
        openBookingSidebar={isBookingSidebar}
        onCloseBookingSidebar={(data) => {
          setIsFromRoom(false);
          setIsBookingSidebar(false);
          setIsFromEditRequest(false);
          if (data === "Booked") {
            setBookingScreens({
              noBooking: false,
              bookingList: true,
              bookingDetail: false,
              confirmContinueAndPay: false,
              cancelBooking: false,
            });
          }
        }}
        eventType={eventType}
        {...bookingProps}
        isFromEditRequest={isFromEditRequest}
      /> */}


      {/* Function - Section - Event */}
      <Event
        show={isEventSidebar}
        onHide={() => setIsEventSidebar(false)}
        eventType={eventType}
        onConfirmEvent={(event) => {
          if (event !== eventType) {
            setIsBookingSidebar(true);
          }
          setEventType(event);
        }}
      />

      {/* Function - Section - Guest */}
      {Object.keys(selectedBooking).length && isGuestSidebar ? <Guest
        isGuestSidebar={isGuestSidebar}
        editGuestValue={selectedBooking.guest}
        selectedTimeSlot={selectedBooking.ChildTimeSlot}
        isLoading={isLoading}
        onCloseGuestSidebar={(guestCount) => {
          // handleGuestUpdate(guestCount);
          setIsGuestSidebar(false);
        }}
        handleGuestUpdate={handleGuestUpdate}
      /> : null}

      {/* Function - Section - Room */}
      <Bed
        isBedSidebar={isBedSidebar}
        onCloseBedSidebar={() => setIsBedSidebar(false)}
        onConfirm={(val) => {
          setpriceDetailsRooms(
            val.map((room) => {
              return {
                title: `${room.title} (${room.night_quantity} nuit)`,
                tooltipMsg: null,
                price: `${room.night_total_price}€`,
              };
            })
          );
          setBedCount(val);
          setSelectedRoomsDetail(val)
          setConfirmPayOptionRoom(true);
        }}
        isComingFromMyBooking={false}
        selectedRoomsDetail={selectedRoomsDetail}
        setRoomRefundDetail={setRoomRefundDetail}
        roomRefundDetail={roomRefundDetail}
      />

      {/* Function - Section - Remaining Payment */}
      <RemainingPayment
        isSidebar={isRemainingSidebar}
        selectedBooking={selectedBooking}
        setSelectedBooking={setSelectedBooking}
        onCloseSidebar={() => setIsRemainingSidebar(false)}
        isPaymentCardsOpen={() => setIsPaymentSidebar(true)}
        selectedCard={selectedCard}
        subscription={subscription?.data?.message}
      />

      <ConfirmAndPayOptionRoom
        isSidebar={confirmPayOptionRoom}
        onCloseSidebar={() => {
          setConfirmPayOptionRoom(false)
          if (editBedSettings) {
            setEditBedSettings(false);
            SuccessToast('Success')
            setBookingScreens({
              noBooking: false,
              bookingList: false,
              bookingDetail: true,
              confirmContinueAndPay: false,
              cancelBooking: false,
            });
            setRoomRefundDetail([])
          }
        }}
        setpriceDetailsRooms={setpriceDetailsRooms}
        priceDetailsRooms={priceDetailsRooms}
        isPaymentCardsOpen={() => setIsPaymentSidebar(true)}
        setBedCount={setBedCount}
        bedCount={bedCount}
        roomRefundDetail={roomRefundDetail}
        setRoomRefundDetail={setRoomRefundDetail}
        selectedRoomsDetail={selectedRoomsDetail}
        isFromRoom={true}
      />

      {/* Function - Section - Pay Remaining Payment */}
      <Payment
        selectedBooking={selectedBooking}
        isPaymentSidebar={isPaymentSidebar}
        onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
        isFromBooking={true}
        getPaymentCard={(val) => paymentCard(val)}
      />

      {/* Function - Section - User profile */}
      <Account
        isOpenSidebar={isAccountInfo}
        setIsOpen={() => {
          setIsAccountInfo(true);
        }}
        onCloseSidebar={() => {
          setIsAccountInfo(false);
        }}
        selectProfile="update"
        selectedProfileData={{
          type: selectedBooking.profile_type === "professional" ? 'Professional' : 'Personal',
          data: selectedBooking.Profile ? {
            id: selectedBooking.Profile.id,
            business_name: selectedBooking.Profile.businessName,
            phone: user.phone,
            email: user.email,
            head_office_address: {
              city: selectedBooking.Profile.headOfficeCity,
              country: selectedBooking.Profile.headOfficeCountry,
              address: selectedBooking.Profile.headOfficeAddress,
              zip_code: selectedBooking.Profile.headOfficeZipCode,
              state_region: selectedBooking.Profile.headOfficeState,
            },
            billing_address: {
              city: selectedBooking.Profile.billingCity,
              country: selectedBooking.Profile.billingCountry,
              address: selectedBooking.Profile.billingAddress,
              zip_code: selectedBooking.Profile.billingZipCode,
              state_region: selectedBooking.Profile.billingState,
            },
            tva_number: selectedBooking.Profile.tvaNumber,
            defrayment_note: selectedBooking.Profile.defraymentNote,
            created_at: selectedBooking.Profile.createdAt,
            updated_at: selectedBooking.Profile.updatedAt,
          } : null
        }}
        setSelectedProfileData={setSelectedProfile}
        setIsUserSidebar={() => setIsBookingSidebar(true)}
        dataa={selectedBooking}
      />

      {/* Function - Section - invoice */}
      <InvoiceOffcanvas
        isInvoiceSidebar={isInvoiceDetail}
        onCloseInvoiceSidebar={setIsInvoiceDetail}
        booking={selectedBooking}
      />

      {/* Function - Section - Access */}
      {
        <SidebarAccess
          isSidebarAccess={isSidebarAccess}
          onCloseSidebarAccess={setIsSidebarAccess}
          booking={selectedBooking}
        />
      }

      {/* Function - Section - Document */}
      {
        isSidebarDocumentRequire ? <SidebarDocumentRequire
          isSidebarDocumentRequire={isSidebarDocumentRequire}
          onCloseSidebarDocumentRequire={setIsSidebarDocumentRequire}
          booking={selectedBooking}
        /> : null
      }

      {/* Function - Booking request - User */}
      {
        isOpenSidebarUserBookingRequest ?
          <SidebarUserBookingRequest
            data={selectedBooking}
            isOpenSidebarUserBookingRequest={isOpenSidebarUserBookingRequest}
            onCloseOpenSidebarUserBookingRequest={() => setIsOpenSidebarUserBookingRequest(false)}
          />
          : null
      }



      {/* Function - Section - Rule and condition */}

      {(Object.keys(selectedBooking)?.length > 0 && selectedBooking?.Event?.EventDetailsCategories?.length > 0) && (
        <SidebarRuleAndCondition
          isSection6SiderbarDetail={isSection6SiderbarDetail}
          setIsSection6SiderbarDetail={() => setIsSection6SiderbarDetail(false)}
          headerTitle="Header title"
          category1={
            selectedBooking?.Event?.EventDetailsCategories?.length > 0
              ? selectedBooking?.Event?.EventDetailsCategories[0]?.name
              : ""
          }
          column1_1={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[0]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[0]?.subTitle,
          }}
          column1_2={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[1]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[1]?.subTitle,
          }}
          column1_3={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[2]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[2]?.subTitle,
          }}
          column1_4={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[3]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[3]?.subTitle,
          }}
          column1_5={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[4]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[4]?.subTitle,
          }}
          column1_6={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[5]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[5]?.subTitle,
          }}
          column1_7={{
            title: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[6]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[0]?.EventRules[6]?.subTitle,
          }}


          category2={selectedBooking?.Event?.EventDetailsCategories[1]?.name}
          column2_1={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[0]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[0]?.subTitle,
          }}
          column2_2={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[1]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[1]?.subTitle,
          }}
          column2_3={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[2]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[2]?.subTitle,
          }}
          column2_4={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[3]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[3]?.subTitle,
          }}
          column2_5={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[4]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[4]?.subTitle,
          }}

          column2_6={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[5]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[5]?.subTitle,
          }}

          column2_7={{
            title: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[6]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[1]?.EventRules[6]?.subTitle,
          }}



          category3={selectedBooking?.Event?.EventDetailsCategories[2]?.name}
          column3_1={{
            title: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[0]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[0]?.subTitle,
          }}
          column3_2={{
            title: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[1]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[1]?.subTitle,
          }}
          column3_3={{
            title: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[2]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[2]?.subTitle,
          }}
          column3_4={{
            title: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[3]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[2]?.EventRules[3]?.subTitle,
          }}

          category4={selectedBooking?.Event?.EventDetailsCategories[3]?.name}
          column4_1={{
            title: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[0]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[0]?.subTitle,
          }}
          column4_2={{
            title: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[1]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[1]?.subTitle,
          }}
          column4_3={{
            title: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[2]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[2]?.subTitle,
          }}
          column4_4={{
            title: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[3]?.title,
            subTitle: selectedBooking?.Event?.EventDetailsCategories[3]?.EventRules[3]?.subTitle,
          }}

        />
      )}
    </>
  );
};

export default MyBooking;
