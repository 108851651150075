import axios from "axios";
import { API_ENDPOINT } from "../../../../../constants";
import { auth } from "../../../auth/firebase";

export const PROFILE_KEY = "profile";

export const updateBusinessInformationRequest = async (data) => {
	const token = await auth.currentUser.getIdToken();
	return axios.patch(API_ENDPOINT + "/admins/update-permission", data, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const getAllAdmin = async () => {
	const token = await auth.currentUser.getIdToken();
	
	return axios.get(API_ENDPOINT + "/admins/all-admin", {
		headers: { Authorization: `Bearer ${token}` },
	});
};

export const uploadProfilePicRequest = async (data) => {
	const token = await auth.currentUser.getIdToken();
	return axios.post(
		API_ENDPOINT + "/users/image",
		data,
		{ headers: { Authorization: `Bearer ${token}` } }
	);
}