import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";

const SectionPaymentMethod = ({
  title,
  image,
  handleClick,
  text,
  subText,
  buttonText,
}) => {
  return (
    <Row>
      <Col>
        {title && <h2 className="clt-booking-cp-div6-title">{title}</h2>}
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div6">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div6-text">
              <h2>{text}</h2>
              <p>{subText}</p>
            </div>
            <Button className="shadow-none" onClick={handleClick}>
              <span>{buttonText}</span>
            </Button>
          </div>
        </div>
        
      </Col>
    </Row>
  );
};

export default SectionPaymentMethod;
