import React, { useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import "../../auth-modal.css";

import axios from 'axios'
import { API_ENDPOINT } from '../../../../../constants'
import { Actions, useAuth } from "../../authContext";
import { ErrorToast,SuccessToast } from "../../../../reusable/Toast";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EmailSignin = ({ handleContinue, createAccount }) => {
	const [email, setEmail] = useState("");

	const { dispatch } = useAuth()

  const handleClick = async () => {
    if (email === "" ) {
			ErrorToast("Email manquant", "Veuillez ajouté l'information manquante.");
      return
    }
		dispatch({ type: Actions.END_REGISTRATION })

    const url = API_ENDPOINT + "/auth/check";
    const checkUserResponse = await axios.post(url, {
      email,
    });
    if (!checkUserResponse.data.userExists) {
      createAccount();
	  SuccessToast("Bienvenue", "Commençons la création de votre compte.")
			return;
    }
    handleContinue(email)
  }

	return (
		<>
			<div className="pfr_loginModal_Body">
				<Row>
					<Col>
						<p className="pfr_loginModalH2">
							Entrez une adresse email pour vous connecter ou créer un compte
						</p>
					</Col>
				</Row>
			</div>
			<div className="pfr_loginModal_Body">
				<Row>
					<Col className="pfr_loginModalEmailDiv">
						<Row>
							<Col className="pfr_inputFloatDiv">
								<Form.Floating className="pfr_inputFloat">
									<Form.Control
										id="floatingInput1"
										type="email"
										placeholder="Adresse e-mail"
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
										}}
									/>
									<label htmlFor="floatingInput1">Adresse e-mail</label>
								</Form.Floating>
							</Col>
						</Row>

						{/* <Row>
							<Col>
								<div className="d-flex justify-content-end pfr_loginForgotPassDiv">
									<span onClick={this.forgotPass}>Password forgot ?</span>
								</div>
							</Col>
						</Row> */}
					</Col>
				</Row>
				<Row>
					<Col>
						<Button
							className="pfr_loginModalBtn shadow-none"
							onClick={handleClick}
						>
							Continuer
						</Button>
					</Col>
				</Row>
			</div>
			<ToastContainer className="toast-container" />
		</>
	);
};

export default EmailSignin;
