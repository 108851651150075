// import
import React, { useState, useEffect } from "react";
import {
    // Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import "./[CHAT-SIDEBAR] Admin - Booking.css";
import CustomHr from "../../../../../reusable/CustomHr";
// import { Link } from "react-router-dom";
import { useAuth } from "../../../../auth/authContext";
import { SuccessToast } from "../../../../../reusable/Toast";

// ------------------------------------------------------------------------------

// import - Image/icon
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../../../assets/img/admin_icon_booking_detail_3.png";
import ConfirmPay5 from "../../../../../../assets/img/confirm_pay_5.png";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";

// ------------------------------------------------------------------------------

// import - Section - RequestPending
import SectionRequestPending from "../../../../../reusable/SectionRequestPending";

// import - Section - Top

// import - Section - Event
import SectionEvent from "../../../../../reusable/booking/SectionEvent";

// import - Section - Date
import SectionDate from "../../../../../reusable/booking/SectionDate";
import Booking from "../../../../detail/booking/booking_user";

// import - Section - Guest
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import Guest from "../../../../guest/Guest";

// import - Section - User
import SectionUser from "../../../../../reusable/booking/SectionUser";
import Account from "../../../../account/Account";

// import - Section - Room
import SectionBed from "../../../../../reusable/booking/SectionRoom";
// import - Section - Rule and condition
import SidebarRuleAndCondition from "../../../../../reusable/booking/SidebarRuleAndCondition";

// import - Section - Damage deposit
import SectionDamageDeposit from "../../../../../reusable/booking/SectionDamageDeposit";
import DamageDeposit from "../../../../adminDashboard/AdminDashboardBooking/admin_dash_booking_component/component_damage_deposit/component_damage_deposit";

// import - Section - invoice
import InvoiceOffcanvas from "../../../../../reusable/invoice/SidebarInvoiceList";

// import - Section - Price Detail
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetail";

// import - Section - Clickable
import SectionClickable from "../../../../../reusable/SectionClickable";

// import - Button - Cancel booking
import CancelBooking from "../../../../adminDashboard/AdminDashboardBooking/admin_dash_booking_component/component_cancel_booking/[SIDEBAR] Admin - Booking - Cancellation";











// Const
const ChatSidebarBookingAdmin = ({ hideSidebar }) => {

    {/* Const - Request Pending */}

    {/* Const - Event */}
    const [eventType, setEventType] = useState(eventweddingData);
    const [isEventSidebar, setIsEventSidebar] = useState(false);

    {/* Const - Date */}
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);

    {/* Const - Guest */}
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [guestCount, setGuestCount] = useState(10);

    {/* Const - User */}
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const { isLoggedIn, user } = useAuth();
    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com"
    });
    useEffect(() => {
        if (user)
            setSelectedProfile({ type: "Personal", detail: `${user?.firstName} ${user?.lastName} · ${user?.phone} · ${user?.email}` })
    }, [user])

    {/* Const - Room */}
    const [isBedSidebar, setIsBedSidebar] = useState(false);
    const [bedCount, setBedCount] = useState(10);

    {/* Const - Rule and condition */}
    const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] =
        useState(false);

    {/* Const - Remaining payment */}
    const [isRemainingSidebar, setIsRemainingSidebar] = useState(false);
    
    {/* Const - Damage deposit */}
    const [isDamageDeposit, setIsDamageDeposit] = useState(false);

    {/* Const - invoice */}
    const [isInvoiceDetail, setIsInvoiceDetail] = useState(false);

    /* Const - Section - Request  */
    const [isSidebarBookingCancellation, setIsSidebarBookingCancellation] = useState(false);

    
    /* Const - Cancel request */
    const handleCancelBooking = () => {
        SuccessToast("Select the amount to refund");
        setIsSidebarBookingCancellation(true);
    };

    // ------------------------------------------------------------------------------

    // Body
    return (
        <>
            <div
                className="clt_message_user_request_offcanvas"
            >

                {/* Header */}
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                       [CHAT-SIDEBAR] Admin - Booking
                    </div>

                </div>



                {/* Body */}
                <div
                    className="clt_message_user_chat_offcanvas_body"
                >

                    <>
                        <div className="clt_message_admin_top_section">
                        </div>
                        <SectionUser
                            handleClick={() => setIsAccountInfo(true)}
                            text={`${selectedProfile.type} profile`}
                            title="User"
                            subText={
                                <span>{selectedProfile.detail}</span>
                            }
                        />
                        

                    </>
                </div>
                <CustomHr
                    rowClass="p-0 m-0"
                    colClass="clt-booking-hr-pd p-0 w-100"
                    hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_message_user_request_offcanvas_body" >   
                    <>
                        <SectionRequestPending
                            innerTitle="Request"
                            innerSubTitle="You have 1 request(s) concerning this booking"
                            onClick={() => setIsInvoiceDetail(true)}
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <h2 className="clt-booking-cp-div1-title">Booking</h2>
                        
                        <SectionEvent
                            image={eventType.iconActive}
                            subText={eventType.title}
                        />
                        <SectionDate
                            image={ConfirmPay2}
                            // handleDateEdit={handleDateEdit}
                            subText1="Subtext 1"
                            subText2="Subtext 2"
                            isEditRequest={true}
                        />
                        <SectionGuest
                            image={ConfirmPay3}
                            subText={`${guestCount} guests`}
                            // setIsGuestSidebar={() => setIsGuestSidebar(true)}
                            buttonText="Edit"
                        />
                        <SectionBed
                            title="Room"
                            image={ConfirmPay5}
                            subText={"Add room"}
                            roomSelected={bedCount}
                            /*setIsBedSidebar={() => setIsBedSidebar(true)}
                            buttonText={bedCount.length > 0 ? "Edit" : "Add"} */
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionDamageDeposit
                            image={ConfirmPay4}
                            subText="Subtext"
                            handleDamageEdit={() => setIsDamageDeposit(true)}
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionClickable
                            title="Rules and conditions"
                            innerTitle="Rules and conditions"
                            innerSubTitle="Here are the rules and conditions about your booking"
                            onClick={() => setIsSection6SiderbarDetail(true)}
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionClickable
                            title="Invoice"
                            innerTitle="Invoice"
                            innerSubTitle="Find the invoices of this reservation"
                            onClick={() => setIsInvoiceDetail(true)}
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionPriceDetail
                            title="Price detail"
                            priceListData={[
                                { title: "CLT", tooltipMsg: null, price: "15€" },
                                { title: "Room 1 (1 night)", tooltipMsg: null, price: "10€" },
                                {
                                    title: "Deposit",
                                    tooltipMsg:
                                        "Damage deposit: An inventory will be made before and after your reservation. A deposit may be automatically deducted for the damage incurred.",
                                    price: "You will be charged automatically in case of damage.",
                                },
                            ]}
                            totalPrice="25€"
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <Row>
                            <Col>
                                <div className="clt-booking-btn-refuse-div mb-5">
                                    <Button
                                        className="clt-booking-btn-refuse"
                                        onClick={handleCancelBooking}
                                    >
                                        <span>Cancel Booking</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>


                </div>
            </div>

            {/* ------------------------------ */}

            {/* Function */}

            {/* Function - Date */}
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={() => setIsBookingSidebar(false)}
                eventType={eventType}
                isFromBooking={true}
                isFromBookingDate={{
                    startDate: new Date(),
                    endDate: new Date(),
                }}
                isFromBookingTimeSlot={{
                    id: 1,
                    title: "Title",
                    subTitle: "subTitle",
                    time: "time",
                }}
            />

            {/* Function - Guest */}
            <Guest
                isGuestSidebar={isGuestSidebar}
                editGuestValue={guestCount}
                onCloseGuestSidebar={(guestCount) => {
                    setIsGuestSidebar(false);
                    setGuestCount(guestCount);
                }}
            />

            {/* Function - User */}
            <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="admin"
                selectedProfileData={selectedProfile}
                setSelectedProfileData={setSelectedProfile}
            />

            {/* Function - Damage deposit */}
            <DamageDeposit
                isOpen={isDamageDeposit}
                setIsOpen={() => setIsDamageDeposit(false)}
            />

            {/* Function - Receipt */}
            <InvoiceOffcanvas
                isInvoiceSidebar={isInvoiceDetail}
                onCloseInvoiceSidebar={setIsInvoiceDetail}
            />

            {/* Function - Cancel booking */}
            <CancelBooking
                isOpen={isSidebarBookingCancellation}
                setIsOpen={() => setIsSidebarBookingCancellation(false)}
            />

        </>
    );
};

export default ChatSidebarBookingAdmin;
