import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Offcanvas, Button } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import "../[SIDEBAR] Search.css";
import SearchIcon from "../../../../assets/img/home_searchbar_icon.png";
import { IMG_ALT } from "../../../../constants";
import { useDetectClickOutside } from "react-detect-click-outside";
import { LoaderDots } from "@thumbtack/thumbprint-react";

/* Const - Search */
const SidebarSearchChat = ({
  usersData,
  searchHandler,
  handleCreateNewChat,
  loading,
}) => {
  const [isSearch, setIsSearch] = React.useState(false);
  const [isSearchCanvas, setIsSearchCanvas] = React.useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsSearch(false) });
  const [isShowSearch, setIsShowSearch] = useState(false);

  const onChange = async (e) => {
    if (e.target.value === "") {
      setIsShowSearch(false);
    } else {
      setIsShowSearch(true);
      await searchHandler(e.target?.value);
    }
    setIsSearch(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  };

  /* Const - Select Result */
  const handleRefuse = (user_id) => {
    handleCreateNewChat(user_id);
    setIsSearchCanvas(false);
  };

  console.log("usersData", usersData);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isSearchCanvas) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [isSearchCanvas]);


  /* Section & Sidebar */
  return (
    /* Section  */
    <div className="clt_search_chat_user_container">
      <div className="clt_search_chat_user_button">
        <div className="clt_search_chat_user_field">
          <Button
            onClick={() => setIsSearchCanvas(true)}
            type="text"
            className="shadow-none"
          >
            {"Recherche"}
          </Button>
          <span className="clt_search_chat_user_icon_div">
            <img
              className="clt_search_chat_user_icon"
              src={SearchIcon}
              alt={IMG_ALT}
            />
          </span>
        </div>
      </div>

      {/* Sidebar */}
      <Offcanvas
        placement="start"
        className="clt_search_chat_user_offcanvas"
        show={isSearchCanvas}
        onHide={() => setIsSearchCanvas(false)}
      >
        {/* Sidebar - Header */}
        <Offcanvas.Header className="justify-content-start clt_search_chat_user_offcanvas_header ">
          <>
            {/* Sidebar - Header - Close button */}
            <Button
              type="button"
              className="shadow-none btn-close btn-back"
              aria-label="Close"
              onClick={() => setIsSearchCanvas(false)}
            >
              <img src={BackArrow} alt={IMG_ALT} />
            </Button>

            {/* Sidebar - Header - Search button */}
            <div className="w-100">
              <Form
                className="clt_search_chat_user_field_2"
                onSubmit={handleFormSubmit}
              >
                <Form.Group className="clt_search_chat_user_field">
                  <Form.Control
                    name="search"
                    type="text"
                    ref={inputRef}
                    class="form-control-search"
                    placeholder="Recherche"
                    onChange={onChange}
                    onBlur={() => setIsSearch(false)}
                  />
                </Form.Group>
              </Form>
            </div>
          </>
        </Offcanvas.Header>

        {/* Sidebar - Body */}
        <Offcanvas.Body className="clt_search_chat_user_offcanvas_body">
          {/* Sidebar - Body - Result */}
          <div className="clt_search_chat_user_datalist_2">
            {loading && usersData.length == 0 ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ marginTop: "20px" }}
              >
                <LoaderDots theme="muted" />
              </div>
            ) : (
              <>
                {isShowSearch && (
                  <div>
                    {usersData.map((item, index) => (
                      <div key={index}>
                        <div
                          onClick={() => handleRefuse(item?.uid)}
                          className="clt_search_sidebar_container_box md:px-3 p-3 cursor-pointer"
                        >
                          <div className="clt_search_avatar_wrapper">
                            <div className="clt_admin_dash_booking_avatar">
                            {item?.profile_picture_url ? (
                                <img
                                  src={item?.profile_picture_url}
                                  alt={IMG_ALT}
                                  width={40}
                                  height={40}
                                  style={{ borderRadius: "50%" }}
                                />
                              ) : (
                                <div className="clt_admin_dash_booking_avatar_name">
                                  {item?.name[0]?.toUpperCase()}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="clt_search_sidebar_container_detail">
                            <div className="clt_search_sidebar_container_box_name">
                              {item?.name}
                            </div>
                            <div className="subtitle">{item?.detail}</div>
                          </div>
                        </div>
                        {index !== usersData?.length - 1 && (
                          <div className="clt_search_chat_user_hr"></div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SidebarSearchChat;
