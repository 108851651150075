import React from "react";
import { Col, Row } from "react-bootstrap";
import ConfirmPay1 from "../../assets/img/confirm_pay_1.png";
import { IMG_ALT } from "../../constants";
const SectionRequestPending = (props) => {
  return (
    <Row className="clt-detail-section-6">
      <Col>
        <Row>
          <Col>
            <div 
              className="d-flex align-items-center clt-booking-cp-div1-section-request-pending clt-clickable-chevron-div-section-request-pending"
              onClick={props.onClick}
            >
              <img src={ConfirmPay1} alt={IMG_ALT} />
              <div className="inner-div-1-section-request-pending">
                <h2>{props.innerTitle}</h2>
                <p>{props.innerSubTitle}</p>
              </div>
              <div className="inner-div-2-section-request-pending">
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SectionRequestPending;
