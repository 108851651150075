// Import
import React, { useContext, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import { AuthContext } from "../../screens/auth/authContext";
import './Section.css';

/* Const */
const SectionDate = ({
  image,
  subText1,
  subText2,
  handleDateEdit,
  isDateSelect,
  isEditRequest,
  isCancelRequest,
  oldSubText1,
  oldSubText2,
  selectedTimeSlot,
}) => {
  const seperator = '->'

  console.log(selectedTimeSlot);
  // const { selectedDate, setSelectedDate } = useContext(AuthContext);
  // useEffect(() => {
  //   setSelectedDate(
  //     {
  //       start: selectedTimeSlot.startDate,
  //       end: selectedTimeSlot.endDate,
  //     }
  //   )
  // }, [selectedTimeSlot.endDate, selectedTimeSlot.startDate, setSelectedDate])

  return (



    /* Section */
    <Row className="clt-booking-mb Your notranslate">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">

          {/* Section - Icon */}
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">

            {/* Section - Text/Subtext */}
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">

              {/* Text */}
              <h2>Date</h2>

              {/* Subtext */}
              <p className={`${isDateSelect && "text-green"} ${isEditRequest && "text-green"}  ${isCancelRequest && "text-red"}`}>{`${isDateSelect && new Date(subText1).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" }).replace(/\//g, '/')}`} - {selectedTimeSlot?.startHour?.slice(0, 5)} {seperator}</p>
              <p className={`${isDateSelect && "text-green"} ${isEditRequest && "text-green"}  ${isCancelRequest && "text-red"}`}>{`${isDateSelect && new Date(subText2).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" }).replace(/\//g, '/')}`} - {selectedTimeSlot?.endHour?.slice(0, 5)}</p>
              {isCancelRequest | isEditRequest | isDateSelect && (
                <>
                  <p className="text-decoration-line-through">{oldSubText1}</p>
                  <p className="text-decoration-line-through">{oldSubText2}</p>
                </>
              )}
            </div>

            {/* Section - Button */}
            {handleDateEdit && (
              <Button className="shadow-none" onClick={handleDateEdit}>
                <span>Modifier</span>
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionDate;
