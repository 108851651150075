// import
import React, { useState } from "react";
import { Button, Col, Dropdown, Offcanvas, Row, Form } from "react-bootstrap";
import "photoswipe/style.css";
import "./[SIDEBAR] User - My Booking - Option room.css";
import { IMG_ALT, selectRoomSliderResponsive } from "../../../../constants";
import CustomCarousel from "../../../reusable/CustomCarousel";
import PhotoSwipeLightbox from "photoswipe/lightbox";

// import - Image / icon
import DetailSlider_1_1 from "../../../../assets/img/detail_slider_1_1.png";
import DetailSlider_1_2 from "../../../../assets/img/detail_slider_1_2.png";
import DetailSlider_1_3 from "../../../../assets/img/detail_slider_1_3.png";
import DetailSlider_1_4 from "../../../../assets/img/detail_slider_1_4.png";
import DetailIcon1 from "../../../../assets/img/detail_icon_1.jpg";
import BackArrow from "../../../../assets/img/back_arrow.png";





/* Const */
const Bed = ({
  isBedSidebar,
  onCloseBedSidebar,
  onConfirm,
  selectedRoomsDetail,
  setRoomRefundDetail,
  roomRefundDetail,
}) => {

  /* Const - Room - List*/
  const [rooms, setRooms] = useState([
    {
      id: 1,
      title: "Room title 1",
      subTitle: "subTitle1 - subTitle2",
      gallery: [
        {
          src: DetailSlider_1_1,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_2,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_3,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
      ],
      subTags: [
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
      ],
      nightsDetails: [
        {
          id: 1,
          night_quantity: 1,
          night_price: 10,
          night_total_price: 10,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 2,
          night_quantity: 2,
          night_price: 10,
          night_total_price: 20,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 3,
          night_quantity: 3,
          night_price: 10,
          night_total_price: 30,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 4,
          night_quantity: 4,
          night_price: 10,
          night_total_price: 40,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 5,
          night_quantity: 5,
          night_price: 10,
          night_total_price: 50,
          night_quantity_limit: 5,
          checked: false,
        },
      ],
    },
    {
      id: 2,
      title: "Room title 2",
      subTitle: "subTitle1 - subTitle2",
      gallery: [
        {
          src: DetailSlider_1_1,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_2,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_3,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
      ],
      subTags: [
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
      ],
      nightsDetails: [
        {
          id: 1,
          night_quantity: 1,
          night_price: 10,
          night_total_price: 10,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 2,
          night_quantity: 2,
          night_price: 10,
          night_total_price: 20,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 3,
          night_quantity: 3,
          night_price: 10,
          night_total_price: 30,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 4,
          night_quantity: 4,
          night_price: 10,
          night_total_price: 40,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 5,
          night_quantity: 5,
          night_price: 10,
          night_total_price: 50,
          night_quantity_limit: 5,
          checked: false,
        },
      ],
    },
  ]);

  console.log("selectedRoomsDetail", selectedRoomsDetail)
  console.log("roomRefundDetail", roomRefundDetail)

  /* Const - List - Dropdown - Select room*/
  const handleChecked = (roomId, nightDetailId) => {
    let roomsTemp = [...rooms];
    roomsTemp.map((room) => {
      if (room.id === roomId) {
        room.nightsDetails.map((nigthDetail) => {
          nigthDetail.checked =
            nigthDetail.id === nightDetailId ? !nigthDetail.checked : false;
          return nigthDetail;
        });
      }
      return room;
    });

    
    setRooms(roomsTemp);

    if(selectedRoomsDetail.length !== 0){
    
      const room = rooms?.filter(room => room.id === roomId)[0];
    
      const currentRoom = room?.nightsDetails?.find((i) => i.checked)
    
      const selectedRoom = selectedRoomsDetail?.filter(item => item.title == room?.title)[0]

      let night_variation = '';
      
      if (currentRoom?.night_quantity > selectedRoom?.night_quantity) {
        night_variation = `+${currentRoom?.night_quantity - selectedRoom?.night_quantity}`
      }
      else {
        night_variation = `-${selectedRoom?.night_quantity - currentRoom?.night_quantity}`
      }
  
      const isRoomRefundExist = roomRefundDetail?.filter(item => item.title == room?.title)[0]

      if(currentRoom?.night_quantity == selectedRoom?.night_quantity) {
          setRoomRefundDetail(roomRefundDetail.filter(item => item.title !== room?.title))
      }

      else {

        if (isRoomRefundExist) {
           const roomRefund = roomRefundDetail?.map(obj =>
            obj.title === room?.title ? { ...obj, night_quantity_variation: night_variation } : obj
            );
            setRoomRefundDetail(roomRefund);
          } else {
            const roomRefund = {
              ...selectedRoom,
              night_quantity_variation: night_variation
            }
            setRoomRefundDetail([...roomRefundDetail, roomRefund]);
          }
      }
        
    }
      
  };


  

  /* Const - List - Confirm selection */
  const handleConfirm = () => {
    let selectedRooms = [];
    let roomsTemp = [...rooms];
    roomsTemp.forEach((room) => {
      room.nightsDetails.forEach((nightDetail) => {
        nightDetail.checked &&
          (selectedRooms = [
            ...selectedRooms,
            {
              id: room?.id,
              title: room.title,
              night_quantity: nightDetail.night_quantity,
              night_price: nightDetail.night_price,
              night_total_price: nightDetail.night_total_price,
              night_quantity_limit: nightDetail.night_quantity_limit,
            },
          ]);
      });
    });
    onConfirm(selectedRooms);
    onCloseBedSidebar();
  };

  /* Const - List - Open Gallery*/
  const openGallery = (gallery, index) => {
    let lightbox = {};
    lightbox = new PhotoSwipeLightbox({
      gallery: "#gallery",
      children: "a",
      dataSource: gallery,
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
    lightbox.loadAndOpen(index);
  };


  const handlePreviousRoomDetail = (room) => {
    const selectedRoom = selectedRoomsDetail?.filter(item => item.title == room?.title)

    const currentRoom = room.nightsDetails.find((i) => i.checked)

    if (selectedRoom[0]?.title == room?.title && currentRoom?.night_quantity !== selectedRoom[0]?.night_quantity) {

      return <span className="line-through">
        {selectedRoom[0]?.night_quantity} night ${selectedRoom[0]?.night_total_price}€
      </span>

    }

    else return ''
  }


  const CustomToggle = React.forwardRef(({ children, onClick, room }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="clt-room-dropdown-btn"
    >
      {children} {handlePreviousRoomDetail(room)}
    </div>
  ));

  return (
    <Offcanvas
      placement="end"
      className="clt_products_offcanvas"
      show={isBedSidebar}
      onHide={onCloseBedSidebar}
    >

      {/* Screen - Header */}
      <Offcanvas.Header className="justify-content-start clt_products_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onCloseBedSidebar}
          >
            <img src={BackArrow} className="" alt={IMG_ALT} />
          </button>
          <Offcanvas.Title>Room</Offcanvas.Title>
        </React.Fragment>
      </Offcanvas.Header>


      {/* Screen - Body */}
      <Offcanvas.Body className="clt_products_offcanvas_body clt-datepicker-sidebar">
        <>
          <Row>
            <Col>
              <h2 className="clt-datepicker-sidebar-h2">Select room</h2>
            </Col>
          </Row>

          {/* List */}
          {rooms.map((room, rIndex) => {
            return (
              <Row key={rIndex}>
                <Col>

                  {/* Room - Card */}
                  <div className="clt-room-divs">

                    {/* Gallery - Slider */}
                    <CustomCarousel
                      responsive={selectRoomSliderResponsive}
                      arrows={true}
                      shouldResetAutoplay={false}
                      renderButtonGroupOutside={true}
                      showDots={true}
                      swipeable={true}
                      className="clt-room-slider"
                    >

                      {/* Gallery */}
                      {room.gallery.map((galleryPic, gIndex) => {
                        return (
                          <div
                            key={gIndex}
                            className="d-flex justify-content-start align-items-start clt-room-slider-img-div cursor-zoom-in"
                            style={{
                              backgroundImage: `url(${galleryPic.src})`,
                            }}
                            onClick={() => openGallery(room.gallery, gIndex)}
                          />
                        );
                      })}
                    </CustomCarousel>


                    <div className="clt-room-text-div">

                      {/* Room - Title */}
                      <h2 className="clt-room-text-h2">{room.title}</h2>

                      {/* Room - Subtitle */}
                      <p className="clt-room-text-p">{room.subTitle}</p>

                      {/* Room - Tag */}
                      <div className="d-flex justify-content-start align-items-center clt-room-text-tags">
                        {room.subTags.map((tag, tIndex) => {
                          return (
                            <span
                              key={tIndex}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <img src={tag.icon} alt={IMG_ALT} />
                              {tag.title}
                            </span>
                          );
                        })}
                      </div>
                      <div className="text-end">

                        {/* Button */}
                        <Dropdown className="clt-room-dropdown-btn-div">
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            className="clt-room-dropdown-btn"
                            as={CustomToggle}
                            room={room}
                          >
                            {room.nightsDetails.find((i) => i.checked)
                              ?
                              `${room.nightsDetails.find((i) => i.checked)
                                .night_quantity
                              } night ${room.nightsDetails.find((i) => i.checked)
                                .night_total_price
                              }€`

                              : "Add"}
                          </Dropdown.Toggle>

                          {/* Dropdown */}
                          <Dropdown.Menu
                            size="sm"
                            className="pfr_dropdown_menu"
                          >
                            {room.nightsDetails.map((nightDetail, nIndex) => {
                              return (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleChecked(room.id, nightDetail.id)
                                  }
                                  className="d-flex flex-row align-items-start clt-room-dropdown-items"
                                  key={nIndex}
                                >
                                  <div
                                    className="inner-div-circle"
                                    style={
                                      nightDetail.checked
                                        ? {
                                          borderColor: `#000`,
                                        }
                                        : {
                                          borderColor: `#ddd`,
                                        }
                                    }
                                  >
                                    <div
                                      className="subDiv"
                                      style={
                                        nightDetail.checked
                                          ? {
                                            background: `#000`,
                                          }
                                          : {
                                            background: `transparent`,
                                          }
                                      }
                                    ></div>
                                  </div>
                                  <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                                    <h2>{nightDetail.night_quantity} night</h2>
                                    <span>
                                      {nightDetail.night_total_price}€
                                    </span>
                                  </div>
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </>
      </Offcanvas.Body>

      {/* Footer */}
      <div>
        <div className="clt-datepicker-sidebar-footer">
          <div className="inner-div">
            <Button
              onClick={handleConfirm}
              className="clt-datepicker-sidebar-footer-btn black_btn shadow-none"
            >
              <span>Confirm</span>
            </Button>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};

export default Bed;

