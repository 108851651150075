// Import
import React, { useEffect, useRef, useState } from "react";
import { API_ENDPOINT, IMG_ALT } from "../../../../constants";
import "./chat.css";
import TextareaAutosize from "react-textarea-autosize";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { Form, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

// Import - Icon
import image from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import ImageIcon from "../../../../assets/img/admin_icon_booking_detail_2_dark.png";
import call from "../../../../assets/img/mybooking_icon_phone.png";
import list from "../../../../assets/img/icon_inbox_show_information.png";
import cancel from "../../../../assets/img/cancel_icon.png";
import send from "../../../../assets/img/icon_message_send_no_active.png";
import sendActive from "../../../../assets/img/icon_send_active.png";
import info_icon from "../../../../assets/img/info_icon.png";


// Import - Chat - Component - Just chatting
import ChatComponentJustChatting from "./chat_component/just_chatting/[CHAT-COMPONENT] Just chatting";

// Import - Chat - Component - Just chatting
import ChatComponentPreferenceContact from "./chat_component/preference_contact/[CHAT-COMPONENT] Preference contact";

// Import - Chat - Component - Booking
import ChatComponentBooking from "./chat_component/booking/[CHAT-COMPONENT] Booking";

// Import - Chat - Component - Information request
// import ChatComponentInformationRequest from './chat_component/information_request/[CHAT-COMPONENT] Information request';
// Import - Chat - Component - Contact request
import ChatComponentContactRequest from "./chat_component/contact_request/[CHAT-COMPONENT] Contact request";

// Import - Chat - Component - Image view
import ChatComponentImageView from "./chat_component/image_view/[CHAT-COMPONENT] Image view";

// ------------------------------------

// Import - Chat - Component - Edit Request

// Import - Chat - Component - Edit Request - Accept
import ChatComponentEditRequestAccept from "./chat_component/edit_request/[CHAT-COMPONENT] Edit request - Accept";

// Import - Chat - Component - Edit Request - Sent
import ChatComponentEditRequestSent from "./chat_component/edit_request/[CHAT-COMPONENT] Edit request - Sent";

// Import - Chat - Component - Edit request - Pending
import ChatComponentEditRequestPending from "./chat_component/edit_request/[CHAT-COMPONENT] Edit request - Pending";

// Import - Chat - Component - Edit request - Reject
import ChatComponentEditRequestCancel from "./chat_component/edit_request/[CHAT-COMPONENT] Edit request - Reject";

// ------------------------------------

// Import - Chat - Component - Cancel Request

// Import - Chat - Component - Cancel Request - Accept
import ChatComponentCancelRequestAccept from "./chat_component/cancel_request/[CHAT-COMPONENT] Cancel request - Accept";

// Import - Chat - Component - Cancel Request - Sent
import ChatComponentCancelRequestSent from "./chat_component/cancel_request/[CHAT-COMPONENT] Cancel request - Sent";

// Import - Chat - Component - Cancel request - Pending
import ChatComponentCancelRequestPending from "./chat_component/cancel_request/[CHAT-COMPONENT] Cancel request - Pending";
import { auth } from "../../auth/firebase";

import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SuccessToast, ErrorToast } from "../../../reusable/Toast";
import { getAdminProfile } from "../../adminDashboard/AdminDashboardMenu/AdminDashboardBusinessInformation/api";
import "moment/locale/fr";
import { LoaderDots } from "@thumbtack/thumbprint-react";

import { useSelector, useDispatch } from "react-redux";
import ChatSidebarContactRequestUser from "../sidebar/sidebar_contact_request/user/[CHAT-SIDEBAR] User - Contact Request";

// --------------------------------------------------------------

// Const

/* Const - Picture in the chat 1  */
const gallery = [
  {
    src: image,
    name: "Image name",
    width: 1950,
    height: 1300,
  },
  {
    src: image,
    name: "Image name",
    width: 1950,
    height: 1300,
  },
];

/* Const - Picture in the chat 2  */
const gallery2 = [
  {
    src: image,
    name: "Image name",
    width: 1950,
    height: 1300,
  },
];

/* Const - Inbox  */
const Inbox = ({
  openChat,

  userType,
  socket,
  selectedChatUser,
  requestStatus,
  setRequestStatus,
  closeChat,

  showSidebar,

  showSidebarJustChatting,
  hideSidebarJustChatting,

  showSidebarContactRequestInProgress,
  hideSidebarContactRequestInProgress,

  showSidebarContactRequest,
  hideSidebarContactRequest,

  showSidebarBooking,
  hideSidebarBooking,

  showSidebarAcceptEditRequest,
  hideSidebarAcceptEditRequest,

  showSidebarSentEditRequest,
  hideSidebarSentEditRequest,

  showSidebarPendingEditRequest,
  hideSidebarPendingEditRequest,

  showSidebarRejectEditRequest,
  hideSidebarRejectEditRequest,

  showSidebarAcceptCancelRequest,
  hideSidebarAcceptCancelRequest,

  showSidebarSentCancelRequest,
  hideSidebarSentCancelRequest,

  showSidebarPendingCancelRequest,
  hideSidebarPendingCancelRequest,

  messagesLoading,
  setMessagesLoading,

  handleContactRequestSidebar,

  currentChat
}) => {
  const [isSending, setIsSending] = useState(false);
  const [messageDetail, setMessageDetail] = useState([]);
  const currentOpenChatId = useRef();
  const [currentUser, setCurrentUser] = useState([]);
  const [uid, setUID] = useState("");
  const endRef = useRef(null);
  const config = require("../../../../config"); // Adjust the path as needed
  const defaultLanguage = config.defaultLanguage;

  useEffect(() => {
    const receiveMessageListener = (msg) => {
      if (currentOpenChatId.current !== msg?.chatId) {
        console.log("NOT THE SAME CHAT");
        return;
      }

      setMessageDetail((prevState) => {
        const prevMessageDate = prevState?.messages?.length
          ? moment(prevState.messages[prevState.messages.length - 1].createdAt)
          : null;

        const currentDate = moment(msg.createdAt);

        const dateChanged = !prevMessageDate
          ? true
          : !prevMessageDate.isSame(currentDate, "day");

        const newMessage = {
          ...msg,
          dateChanged: dateChanged,
          currentDate: msg.createdAt,
        };
        return {
          ...prevState,
          messages: [...prevState.messages, newMessage],
        };
      });

      scrollToBottom();

      socket.emit("message_read", { chatId: msg.chatId }, (error) => {
        if (error) {
          console.log("Error sending messageRead: ", error);
        }
      });
    };

    const messageSentListener = (msg) => {
      // if(currentOpenChatId.current !== msg?.chatId) {
      //   console.log('NOT THE SAME CHAT')
      //   return
      // }

      setMessageDetail((prevState) => {
        const prevMessageDate = prevState?.messages?.length
          ? moment(prevState.messages[prevState.messages.length - 1].createdAt)
          : null;

        const currentDate = moment(msg.createdAt);

        const dateChanged = !prevMessageDate
          ? true
          : !prevMessageDate.isSame(currentDate, "day");

        const newMessage = {
          ...msg,
          dateChanged: dateChanged,
          currentDate: msg.createdAt,
        };
        return {
          ...prevState,
          messages: [...prevState.messages, newMessage],
        };
      });

      scrollToBottom();
      setMessage("");
      setIsSending(false);
    };

    const chatMessagesListener = (response) => {
      setMessagesLoading(false);
      setUID(response?.user?.uid);

      let prevMessageDate = null;
      const messagesWithDateInfo = response?.messages
        ?.slice()
        ?.reverse()
        ?.map((message, index) => {
          const currentDate = moment(message?.createdAt);
          const dateChanged =
            prevMessageDate == null
              ? true
              : !prevMessageDate.isSame(currentDate, "day");
          if (dateChanged) {
            prevMessageDate = moment(message?.createdAt);
          }

          return {
            ...message,
            currentDate: message?.createdAt,
            dateChanged,
          };
        });

      setMessageDetail({ ...response, messages: messagesWithDateInfo });
      scrollToBottom();
    };

    socket?.on("receiveMessage", receiveMessageListener);

    socket?.on("messageSent", messageSentListener);

    socket?.on("chatMessages", chatMessagesListener);

    return () => {
      socket?.off("receiveMessage", receiveMessageListener);
      socket?.off("messageSent", messageSentListener);
      socket?.off("chatMessages", chatMessagesListener);
    };
  }, [socket]);

  const [AdminProfile, setAdminProfile] = useState({
    // name: user?.lastName,
    // email: user?.email,
    // phone: user?.phone,
    // business_address: {
    // 	address: user?.businessAddress ?? "",
    // 	city: user?.businessCity ?? "",
    // 	zip_code: user?.businessZipCode ?? "",
    // 	state_region: user?.businessState ?? "",
    // 	country: user?.businessCountry ?? "",
    // },
    // billing_address: {
    // 	address: user?.billingAddress ?? "",
    // 	city: user?.billingCity ?? "",
    // 	zip_code: user?.billingZipCode ?? "",
    // 	state_region: user?.billingState ?? "",
    // 	country: user?.billingCountry ?? "",
    // },
    // commercial_name: user.commercial_name,
    // capital_social: user.capital_social,
    // siret: user.siret,
    // tva_number: user.tva_number,

    name: "",
    profile_picture_url: null,
    email: "",
    phone: "",
    business_address: {
      address: "",
      city: "",
      zip_code: "",
      state_region: "",
      country: "",
    },
    billing_address: {
      address: "",
      city: "",
      zip_code: "",
      state_region: "",
      country: "",
    },
    commercial_name: "",
    capital_social: "",
    siret: "",
    tva_number: "",
  });

  console.log("admin profile", AdminProfile)

  useEffect(() => {
    const bootstrap = async () => {
      const req = await getAdminProfile();
      const user = req.data.data;
      console.log("user", user)
      setAdminProfile({
        name: user?.name || "",
        commercial_name: user?.commercialName || "",
        capital_social: user?.capitalSocial || "",
        siret: user?.siret || "",
        tva_number: user?.tvaNumber || "",
        email: user?.email || "",
        phone: user?.phone || "",
        business_address: {
          address: user?.businessAddress ?? "",
          city: user?.businessCity ?? "",
          zip_code: user?.businessZipCode ?? "",
          state_region: user?.businessState ?? "",
          country: user?.businessCountry ?? "",
        },
        billing_address: {
          address: user?.billingAddress ?? "",
          city: user?.billingCity ?? "",
          zip_code: user?.billingZipCode ?? "",
          state_region: user?.billingState ?? "",
          country: user?.billingCountry ?? "",
        },
        profile_picture_url: user?.profilePictureUrl ?? null,
      });
    };
    bootstrap();
  }, [selectedChatUser]);

  const scrollToBottom = () => {
    if (endRef.current) {
      const { scrollHeight, clientHeight } = endRef.current;
      endRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    currentOpenChatId.current = openChat;
  }, [openChat]);

  const [message, setMessage] = useState("");

  /* Const - Message - Image */
  const [imageList, setImageList] = useState([]);
  const imageRef = useRef([]);

  /* Const - Message - Check option */
  const [selectedType, setSelectedType] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCheckChange = (type) => {
    if (selectedType == type) setIsChecked(!isChecked);
    else setIsChecked(true);

    setSelectedType(type);
  };

  const changeImages = async (e) => {
    for (let i = 0; i < e.target.files?.length; i++) {
      let image = {
        id: uuid(),
        progress: 0,
        uploadId: null,
        file: e.target.files[i],
      };
      imageRef.current = [...imageRef.current, image];
      imageUploading(image);
    }
    setImageList([...imageRef.current]);
  };

  const imageUploading = async (image) => {
    let data = new FormData();
    data.append("image", image.file);

    try {
      const token = await auth.currentUser.getIdToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          const newImages = imageRef.current.map((obj) =>
            obj.id === image.id
              ? {
                  ...obj,
                  progress: percentCompleted == 100 ? 99 : percentCompleted,
                }
              : obj
          );
          imageRef.current = newImages;
          setImageList(newImages);
        },
      };

      const response = await axios.post(
        API_ENDPOINT + "/message/images",
        data,
        config
      );

      const newImages = imageRef.current.map((obj) =>
        obj.id === image.id
          ? { ...obj, uploadId: response.data.data.id, progress: 100 }
          : obj
      );
      imageRef.current = newImages;
      setImageList(newImages);
    } catch (error) {
      console.log("Error in uploading images: ", error);
    }
  };




  const removeImage = (i) => {
    if (imageList.some((x) => x.uploadId == null)) {
      ErrorToast(
        "Veuillez patienter",
        "Le fichier est en actuellement en cours d'importation, veuillez attendre la fin pour pouvoir supprimer."
      );
      return;
    }
    let images = [...imageList];
    images.splice(i, 1);
    imageRef.current = images;
    setImageList(images);
  };

  // Chat header - Admin tool - Select status "Pending" or "bookin"
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <>
      {openChat == 1 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
            console.log(e);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.first == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.first}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 2 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
            console.log(e);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.second == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.second}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 3 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.third == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.third}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 4 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.fourth == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.fourth}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 5 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.five == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.five}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 6 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.six == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.six}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 7 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.seven == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.seven}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 8 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.eight == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.eight}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 9 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.nine == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.nine}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : openChat == 10 ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            // console.loge);
          }}
          className={`d-flex clt_message_navbarRightTxt ${
            requestStatus.ten == "Pending" ? "booking" : "information"
          }`}
        >
          {children}
          <span>
            {requestStatus.ten}
            <i className="fas fa-chevron-down" aria-hidden="true"></i>
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  ));

  const sendMessage = async () => {
    setIsSending(true);
    if (imageList.length) {
      if (imageList.some((x) => x.uploadId == null)) {
        ErrorToast(
          "Veuillez patienter",
          "Le fichier est en actuellement en cours d'importation, veuillez attendre la fin pour pouvoir envoyer."
        );
        return;
      }
      let dataToEmit = {
        chatId: messageDetail?.chatId,
        content: message.length ? message : " ",
      };
      let imagesToLink = [];
      for (let index = 0; index < imageList.length; index++) {
        const element = imageList[index];
        imagesToLink.push(element.uploadId);
      }

      imageRef.current = [];
      setImageList([]);

      socket.emit(
        "send_message",
        {
          chatId: dataToEmit.chatId,
          content: dataToEmit.content,
          imagesToLink: imagesToLink,
        },
        (error) => {
          if (error) {
            setIsSending(false);
            console.error("Error sending message: ", error);
          }
        }
      );
      // setIsSending(false);
    } else {
      socket.emit(
        "send_message",
        { chatId: messageDetail?.chatId, content: message },
        (error) => {
          if (error) {
            setIsSending(false);
            console.error("Error sending message: ", error);
          }
        }
      );
      // setIsSending(false);
    }
  };

  const showSideBar = () => {
    switch (currentChat.chatState) {
      case 'just chatting':
        showSidebarJustChatting()
        break;
      case 'contact request sent':
        showSidebarContactRequest()
        break;
      case 'contact request in progress':
        showSidebarContactRequestInProgress()
        break;
      case 'booking confirm':
        showSidebarBooking()
        break;
      case 'edit request accept':
        showSidebarAcceptEditRequest()
        break;
      case 'edit request sent':
        showSidebarSentEditRequest()
        break;
      case 'edit request reject':
        showSidebarRejectEditRequest()
        break;
      case 'edit request pending':
        showSidebarPendingEditRequest()
        break;
      default:
        console.log('Chat state not found', currentChat.chatState)
        showSidebarJustChatting()
    }
  }

  const hideSidebar = () => {
    switch (currentChat.chatState) {
      case 'just chatting':
        hideSidebarJustChatting()
        break;
      case 'contact request sent':
        hideSidebarContactRequest()
        break;
      case 'contact request in progress':
        hideSidebarContactRequestInProgress()
        break;
      case 'booking confirm':
        hideSidebarBooking()
        break;
      case 'edit request accept':
        hideSidebarAcceptCancelRequest()
        break;
      case 'edit request sent':
        hideSidebarSentEditRequest()
        break;
      case 'edit request reject':
        hideSidebarSentEditRequest()
        break;
      case 'edit request pending':
        hideSidebarPendingCancelRequest()
        break;
      default:
        console.log('Chat state not found', currentChat.chatState)
        hideSidebarJustChatting()
    }
  }

  return (
    // Chat header
    <div className="clt_message_inbox_container">
      <div className="clt_message_inbox_wrapper">
        {selectedChatUser?.length !== 0 && (
          <div className="clt_message_accountDetail_wrapper">
            <div className="clt_message_accountDetail">
              <button
                type="button"
                className="back-button d-block d-sm-none"
                aria-label="Close"
                onClick={closeChat}
              >
                <img src={BackArrow} className="" alt={IMG_ALT} />
              </button>

              <>
                
                {selectedChatUser?.role === "admin_profile" || selectedChatUser?.role === 'admin' ? (
                  <>
                    {AdminProfile?.profile_picture_url ? (
                      <img src={AdminProfile?.profile_picture_url} alt={AdminProfile?.name} />
                    ): (
                      <div className="clt_messages_container_detail_icon_header">
                        {AdminProfile?.name[0]?.toUpperCase()}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {selectedChatUser?.profilePictureUrl ? (
                      <img
                        src={selectedChatUser?.profilePictureUrl}
                        alt={selectedChatUser?.firstName}
                      />
                    ) : (
                      <div className="clt_messages_container_detail_icon_header">
                        {selectedChatUser?.firstName[0]?.toUpperCase()}
                      </div>
                    )}
                  </>
                )}

                <div className="clt_message_accountSubDetail">
                  <h2 className="overflow-wrap">
                    {selectedChatUser?.role === "admin_profile"
                      ? AdminProfile.name
                      : selectedChatUser?.firstName +
                        " " +
                        selectedChatUser?.lastName}
                  </h2>
                  <p>
                    {selectedChatUser?.role === "admin_profile"
                      ? AdminProfile.phone
                      : selectedChatUser?.phone}{" "}
                    .{" "}
                    {selectedChatUser?.role === "admin_profile"
                      ? AdminProfile.email
                      : selectedChatUser?.email}
                  </p>
                </div>
              </>
            </div>

            {userType == "admin" ? (
              <>
                {/* Chat header - Admin tool - Select status "Pending" or "Treated" */}
                {openChat == 1 ? (
                  <>
                    {/*<Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              second: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              second: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </>
                ) : openChat == 3 ? (
                  <>
                    {/* <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              third: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              third: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>*/}
                  </>
                ) : openChat == 4 ? (
                  <>
                    {/*<Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              fourth: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              fourth: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>*/}
                  </>
                ) : openChat == 5 ? (
                  <>
                    {/*<Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              five: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              five: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>*/}
                  </>
                ) : openChat == 6 ? (
                  <>
                    {/*<Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              six: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              six: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>*/}
                  </>
                ) : openChat == 7 ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              seven: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              seven: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : openChat == 8 ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              eight: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              eight: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : openChat == 9 ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              nine: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              nine: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : openChat == 10 ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu
                        size="sm"
                        title=""
                        className="pfr_dropdown_menu_inbox"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              ten: "Pending",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setRequestStatus((prevState) => ({
                              ...prevState,
                              ten: "Treated",
                            }))
                          }
                          className="pfr_payment_cards3dots_options"
                        >
                          Treated
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}

        {/* Chat Body */}

        {messagesLoading ? (
          <div className="clt_messages_container" ref={endRef}>
            <div className="d-flex align-items-center justify-content-center">
              <LoaderDots theme="muted" />
            </div>
          </div>
        ) : (
          <div className="clt_messages_container" ref={endRef}>

            
            {/* Conditional rendering for no messages */}
            {messageDetail?.messages?.length === 0 && (
              <div className="clt_message_input_component_inner3">
                <div className="">
                  <div className="clt_messages_container_detail2" style={{ width: "100%", height: "auto", padding: "24px", backgroundColor: "#eee", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {/* Square with text "Information Message" */}
                    <img src={info_icon} alt={IMG_ALT} style={{ width: "24px", marginRight: ""}}/>
                    <p style={{ marginLeft: "0", marginRight: "22px", marginTop: "0", marginBottom: "0",fontSize: "15px", fontWeight: "500", fontFamily: "Inter", color: "#6A6A6A" }}>Vous pouvez discuter avec l'équipe du château depuis ce fil de discussion. Envoyez-nous un message, et nous reviendrons prochainement.</p>
                  </div>
                </div>
              </div>
            )}


            {/* Mapping over messages */}
            <></>
            {messageDetail?.messages?.map((message, index) => (
              <div key={index}>
                {/* Chat 1 - Just chatting - Content */}
                {/* chat data */}
                {message?.dateChanged && (
                  <div className="clt_messages_container_wrapper_date">
                    <p className="clt_messages_container_wrapper_date_p">
                      {/* {message?.currentDate?.format('MMMM DD')} */}
                      {moment(message?.currentDate).format("DD MMMM")}
                    </p>
                  </div>
                )}
                <div className="clt_messages_container_wrapper_text">
                  <div className="clt_messages_container_detail">
                    {/* users detail start */}

                    {message?.User?.role === "admin" || message?.User?.role === "admin_profile" ? (
                      <>
                        {AdminProfile?.profile_picture_url ? (
                          <img src={AdminProfile?.profile_picture_url} alt={AdminProfile?.name} />
                        ) : (
                          <div className="clt_messages_container_detail_icon_header">
                            {AdminProfile?.name[0]?.toUpperCase()}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {message?.User?.profilePictureUrl ? (
                          <img
                            src={message?.User?.profilePictureUrl}
                            alt={message?.User?.firstName}
                          />
                        ) : (
                          <div className="clt_messages_container_detail_icon_header">
                            {message?.User?.firstName[0]?.toUpperCase()}
                          </div>
                        )}
                      </>
                    )}


                    {/* {message?.User?.profilePictureUrl ||
                    message?.User?.role === "admin_profile" ? (
                      <img
                        src={
                          message?.User?.role === "admin" ||
                          message?.User.role === "admin_profile"
                            ? AdminProfile.profile_picture_url
                            : message?.User?.profilePictureUrl
                        }
                        alt={
                          message?.User?.role === "admin" ||
                          message?.User.role === "admin_profile"
                            ? AdminProfile.name
                            : message?.User?.firstName
                        }
                      />
                    ) : (
                      <div className="clt_messages_container_detail_icon_header">
                        {message?.User?.firstName[0].toUpperCase()}
                      </div>
                    )} */}

                    <div className="clt_messages_container_accountSubDetail">
                      <div className="clt_messages_container_message_detail">
                        <h2
                          style={{ maxWidth: "150px" }}
                          className="overflow-wrap overflow-hidden"
                        >
                          {message?.User?.role === "admin" ||
                          message?.User.role === "admin_profile"
                            ? AdminProfile.name
                            : message?.User?.firstName +
                              " " +
                              message?.User?.lastName}
                        </h2>
                        <p className="overflow-wrap">
                          {moment(message?.createdAt).format(
                            "DD MMM YYYY · HH:mm"
                          )}
                        </p>
                      </div>
                      {/* users detail start end */}

                      
                        <ChatComponentJustChatting
                          detail={message?.content}
                        />
                        {message?.MessageImages?.length > 0 ? (
                          <div className="clt_messages_container_images_container">
                            <ChatComponentImageView
                              images={message?.MessageImages}
                              IMG_ALT={IMG_ALT}
                            />
                          </div>
                        ) : null}

                        {message?.messageType === 'contact_request' && 
                          <ChatComponentContactRequest 
                            onClick={handleContactRequestSidebar}
                            contactRequest={message?.ContactRequest}
                            socket={socket}
                          />
                        }

                        {message?.messageType === 'conatct_preference' &&
                          <ChatComponentPreferenceContact
                            contactRequest={message?.ContactRequest}
                            socket={socket}
                          />
                        }
                      
                    </div>
                  </div>
                </div>

                {/* {message?.Chat?.chatState == 'just chatting' && (
                <>
                  <div className="clt_messages_container_wrapper_text">
                    <div className="clt_messages_container_detail">
                      <img src={image} alt={IMG_ALT} />
                      <div className="clt_messages_container_accountSubDetail">
                        <div className="clt_messages_container_message_detail">
                          <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                          <p className='overflow-wrap'>01/01/23 - 20:01</p>
                        </div>

                        <ChatComponentJustChatting detail=" How can we help you ?" />
                      </div>
                    </div>
                  </div>
                  <div className="clt_messages_container_wrapper_text">
                    <div className="clt_messages_container_detail">
                      <div className="clt_messages_container_detail_icon">J</div>
                      <div className="clt_messages_container_accountSubDetail">
                        <div className="clt_messages_container_message_detail">
                          <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>John doe</h2>
                          <p className='overflow-wrap'>01/01/23 - 23:59</p>
                        </div>


                        <ChatComponentJustChatting detail="Hello, can you check this picture ?" />
                        <div className="clt_messages_container_images_container">
                          <ChatComponentImageView images={gallery} IMG_ALT={IMG_ALT} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clt_messages_container_wrapper_date">
                    <p className="clt_messages_container_wrapper_date_p">2 january</p>
                  </div>
                  <div className="clt_messages_container_wrapper_text">
                    <div className="clt_messages_container_detail">
                      <img src={image} alt={IMG_ALT} />
                      <div className="clt_messages_container_accountSubDetail">
                        <div className="clt_messages_container_message_detail">
                          <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                          <p className='overflow-wrap'>02/01/23 - 08:00</p>
                        </div>
                        <ChatComponentJustChatting detail="Thanks for waiting one day, the solution is ..." />
                        <div className="clt_messages_container_images_container">
                          <ChatComponentImageView images={gallery2} IMG_ALT={IMG_ALT} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}

                {/* Chat 2 - Information request - Content */}
                {/* {openChat == 2 && (
            <> */}

                {/* Chat - Chronolgical date */}
                {/* <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div> */}

                {/* Chat - Container - Message */}
                {/* <div className="clt_messages_container_wrapper_text"> */}
                {/* <div className="clt_messages_container_detail"> */}

                {/* Component - User - First letter of firstname or Profile picture */}
                {/* <div className="clt_messages_container_detail_icon">J</div> */}
                {/* Pull - Profile picture - User: personalAccount.profile_picture */}
                {/* Pull - First letter of first name - User: personalAccount.first_name?.charAt(0) */}

                {/* Component - User first name and name / Date delivery message*/}
                {/* <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>John doe </h2>
                      <p className='overflow-wrap'>01/01/23 - 20:00</p>
                    </div> */}

                {/* Component - Message - Text only */}
                {/* <ChatComponentJustChatting detail="Hello" /> */}

                {/* Component - Message - Information request  */}
                {/* <ChatComponentInformationRequest onClick={showSidebarInformationRequest} />
                  </div>
                </div>
              </div> */}

                {/* Chat - Container - Check option */}
                {/* <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail"> */}

                {/* Component - Admin - Profile picture or First letter */}
                {/* <img src={image} alt={IMG_ALT} /> */}
                {/* Pull - Profile picture - Admin: <img className="clt-account-profile-pic" src={adminAccount.profile_picture} alt={IMG_ALT}/> */}
                {/* Pull - First letter of name - Admin: adminAccount.name?.charAt(0) */}

                {/* Component - Admin name / Date delivery message */}
                {/* <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 20:00</p>
                    </div> */}

                {/* Component - Message - Text only */}
                {/* <ChatComponentJustChatting detail=" Hello, this is an automatic message, how would you like to be contacted ?" /> */}

                {/* Component - Message - Check option */}
                {/* <div className="clt_messages_container_contact_message"> */}
                {/* <div onClick={() => onCheckChange("phone")} className="clt_messages_container_contact_message_type">
                        <p>Phone</p>
                        <Form.Check checked={selectedType == "phone" ? isChecked : false} type="radio" aria-label="radio 1" />
                      </div>
                      <div onClick={() => onCheckChange("email")} className="clt_messages_container_contact_message_type">
                        <p>Email</p>
                        <Form.Check checked={selectedType == "email" ? isChecked : false} type="radio" aria-label="radio 1" />
                      </div>
                      <div onClick={() => onCheckChange("chat")} className="clt_messages_container_contact_message_type">
                        <p>Chat</p>
                        <Form.Check checked={selectedType == "chat" ? isChecked : false} type="radio" aria-label="radio 1" />
                      </div> */}
                {/* </div>
                  </div>
                </div>
              </div> */}
                {/* </>
           )} */}

                {/* Chat 3 - Booking - Content */}
                {/* {openChat == 3 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Booking confirm"`} />
                    <ChatComponentBooking
                      title="Wedding"
                      subTitle="Booking"
                      amount="$25"
                      onClick={showSidebarBooking} />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 4 - Edit request - Content */}
                {/* {openChat == 4 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Edit request - Accept"`} />
                    <ChatComponentEditRequestAccept onClick={showSidebarAcceptEditRequest} />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 5 - Edit request - Sent - Content*/}
                {/* {openChat == 5 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Edit request - Sent"`} />
                    <ChatComponentEditRequestSent onClick={showSidebarSentEditRequest} />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 6 - Edit request - Pending - Content */}
                {/* {openChat == 6 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Edit request - Pending"`} />
                    <ChatComponentEditRequestPending onClick={showSidebarPendingEditRequest} />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 7 - Edit request - Reject - Content */}
                {/* {openChat == 7 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Edit request - Reject"`} />
                    <ChatComponentEditRequestCancel onClick={showSidebarRejectEditRequest} />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 8 - Cancel request - Accept - Content */}
                {/* {openChat == 8 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Cancel request - Accept"`} />
                    <ChatComponentCancelRequestAccept
                      title="Cancel"
                      subTitle="Booking"
                      onClick={showSidebarAcceptCancelRequest}
                    />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 9 - Cancel request - Sent - Content */}
                {/* {openChat == 9 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Cancel request - Sent"`} />
                    <ChatComponentCancelRequestSent
                      title="Request to cancel"
                      subTitle="Booking"
                      onClick={showSidebarSentCancelRequest}
                    />
                  </div>
                </div>
              </div>
            </>
          )} */}

                {/* Chat 10 - Cancel request - Pending - Content */}
                {/* {openChat == 10 && (
            <>
              <div className="clt_messages_container_wrapper_date">
                <p className="clt_messages_container_wrapper_date_p">1 january</p>
              </div>
              <div className="clt_messages_container_wrapper_text">
                <div className="clt_messages_container_detail">
                  <img src={image} alt={IMG_ALT} />
                  <div className="clt_messages_container_accountSubDetail">
                    <div className="clt_messages_container_message_detail">
                      <h2 style={{ maxWidth: "150px" }} className='overflow-wrap overflow-hidden'>Admin</h2>
                      <p className='overflow-wrap'>01/01/23 - 08:00</p>
                    </div>
                    <ChatComponentJustChatting detail={`Hello, this component appear for state "Cancel request - Pending"`} />
                    <ChatComponentCancelRequestPending
                      title="Request to cancel"
                      subTitle="Booking"
                      onClick={showSidebarPendingCancelRequest}
                    />
                  </div>
                </div>
              </div>
              </div>
            </>
          )} */}
              </div>
            ))}
          </div>
        )}

        {/* ---------------------------------------------------------- */}

        {/*  Chat - Footer */}

        {/*  Chat - Footer - Button - Call / Open chat state */}

        {/* Chat 1 - Just chatting - Button "Call" and "See Information" */}
        <div className="clt_message_input_component">
          <div className="clt_message_input_component_tag_wrapper">
            {/*
            <a
              href="tel:+33531802525"
              className="clt_message_input_component_tag"
            >
              <img
                class="clt_message_input_component_tag_image"
                src={call}
                alt={IMG_ALT}
                width="12px"
                height="15px"
              />
              <p>Appeler</p>
            </a> */}
            {showSidebar ? (
              <div
                onClick={hideSidebar}
                className="clt_message_input_component_tag"
              >
                <img
                  class="clt_message_input_component_tag_image"
                  src={list}
                  alt={IMG_ALT}
                  width="15px"
                  height="15px"
                />
                <p>Masquer détail</p>
              </div>
            ) : (
              <div
                onClick={showSideBar}
                className="clt_message_input_component_tag"
              >
                <img
                  class="clt_message_input_component_tag_image"
                  src={list}
                  alt={IMG_ALT}
                  width="15px"
                  height="15px"
                />
                <p>Ouvrir détail</p>
              </div>
            )}{/* 
            {openChat == 1 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarJustChatting}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask detaill</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarJustChatting}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Open detail</p>
                  </div>
                )}
              </>
            )}*/}

            {/* Chat 2 - Information request - Button "Call" and "See information request" */}
            {openChat == 2 ? (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarJustChatting}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask detailll</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarJustChatting}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Open detail</p>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {/* Chat 3 - Booking - Button "Call" and "See booking detail" */}
            {openChat == 3 ? (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarBooking}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask booking detail</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarBooking}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See booking detail</p>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {/* Chat 4 - Edit request - Button "Call" and "See edit request" */}
            {openChat == 4 ? (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarAcceptEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarAcceptEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {/* Chat 5 - Edit request - Sent - Button "Call" and "See edit request" */}
            {openChat == 5 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarSentEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarSentEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            )}

            {/* Chat 6 - Edit request - Pending - Button "Call" and "See edit request" */}
            {openChat == 6 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarPendingEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarPendingEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            )}

            {/* Chat 7 - Edit request - Reject - Button "Call" and "See edit request" */}
            {openChat == 7 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarRejectEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarRejectEditRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            )}

            {/* Chat 8 - Cancel request - Accept - Button "Call" and "See edit request" */}
            {openChat == 8 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarAcceptCancelRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarAcceptCancelRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            )}

            {/* Chat 9 - Cancel request - Sent - Button "Call" and "See edit request" */}
            {openChat == 9 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarSentCancelRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarSentCancelRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            )}

            {/* Chat 10 - Cancel request - Pending - Button "Call" and "See edit request" */}
            {openChat == 10 && (
              <>
                {showSidebar ? (
                  <div
                    onClick={hideSidebarPendingCancelRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>Mask edit request</p>
                  </div>
                ) : (
                  <div
                    onClick={showSidebarPendingCancelRequest}
                    className="clt_message_input_component_tag"
                  >
                    <img
                      class="clt_message_input_component_tag_image"
                      src={list}
                      alt={IMG_ALT}
                      width="15px"
                      height="15px"
                    />
                    <p>See edit request</p>
                  </div>
                )}
              </>
            )}

            {/* Chat - Footer - Button - Insert image */}
          </div>

          <div className="clt_message_input_component_inner">
            <div className="clt_message_input_component_inner_upload">
              <label for="imageUpload">
                <img
                  src={ImageIcon}
                  class="clt_message_input_component_inner_upload_icon"
                  alt={IMG_ALT}
                  width="20px"
                  height="20px"
                />
              </label>
              <input
                name="imageUpload"
                id="imageUpload"
                type="file"
                multiple
                accept="image/*"
                onChange={changeImages}
                onClick={(e) => (e.target.value = "")}
                style={{ display: "none" }}
              />
            </div>

            {/* Chat - Footer - Input message */}

            <div className="clt_message_textarea">
              {/* Chat - Footer - Input message : Image insert */}
              {imageList !== undefined && imageList?.length !== 0 ? (
                <div className="clt_message_textarea_images">
                  {imageList.map((item, index) => (
                    <div
                      className="clt_message_textarea_images_wrapper"
                      key={index}
                    >
                      <img
                        src={URL.createObjectURL(item.file)}
                        alt={IMG_ALT}
                        width="60px"
                        height="60px"
                        style={{ borderRadius: "10px" }}
                      />
                      <div
                        className="clt_message_textarea_images_wrapper_del"
                        onClick={() => removeImage(index)}
                      >
                        <img
                          src={cancel}
                          class="clt_message_textarea_images_wrapper_del_icon"
                          alt={IMG_ALT}
                        />
                      </div>

                      {item.progress < 100 ? (
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                          }}
                        >
                          <CircularProgressbar
                            value={item.progress}
                            text={`${item.progress}%`}
                          />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}

              {/* Chat - Footer - Input message : Text insert */}
              <TextareaAutosize
                minRows={1}
                maxRows={4}
                placeholder="Écrire un message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className={`clt_message_textarea_send`}>
                <div className="clt_message_textarea_send_btn">
                  {isSending == false ? (
                    <>
                      {message?.length > 0 || imageList?.length > 0 ? (
                        imageList.length ? (
                          imageList.some((x) => x.uploadId != null) ? (
                            <img
                              src={sendActive}
                              alt={IMG_ALT}
                              class="clt_message_textarea_send_btn_active"
                              onClick={() =>
                                message?.length !== 0 || imageList.length !== 0
                                  ? sendMessage()
                                  : null
                              }
                            />
                          ) : (
                            <img
                              src={send}
                              alt={IMG_ALT}
                              class="clt_message_textarea_send_btn_not_active"
                            />
                          )
                        ) : (
                          <img
                            src={sendActive}
                            alt={IMG_ALT}
                            class="clt_message_textarea_send_btn_active"
                            onClick={() =>
                              message?.length !== 0 || imageList.length !== 0
                                ? sendMessage()
                                : null
                            }
                          />
                        )
                      ) : (
                        <img
                          src={send}
                          alt={IMG_ALT}
                          class="clt_message_textarea_send_btn_not_active"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={send}
                      alt={IMG_ALT}
                      class="clt_message_textarea_send_btn_not_active"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
