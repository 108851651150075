// Import
import React, { useState, useEffect } from 'react'
import './message.css'
import Inbox from './Chat/chat';
import InboxListContainer from './inbox/inbox';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import {SOCK_ENDPOINT} from "../../../constants" ;

// Import - Chat Sidebar

// Import - Chat Sidebar - Just Chatting
import SidebarJustChattingAdmin from './sidebar/sidebar_just_chatting/admin/[CHAT-SIDEBAR] Admin - Just chatting';
import SidebarJustChattingUser from './sidebar/sidebar_just_chatting/user/[CHAT-SIDEBAR] User - Just chatting';

// Import - Chat Sidebar - Booking Detail
import ChatSidebarBookingAdmin from './sidebar/sidebar_booking/admin/[CHAT-SIDEBAR] Admin - Booking';
import SidebarBookingUser from './sidebar/sidebar_booking/user/[CHAT-SIDEBAR] User - Booking';

// Import - Chat Sidebar - Contact request

// Import - Chat Sidebar - Contact request - Sent
import SidebarContactRequestAdmin from './sidebar/sidebar_contact_request/admin/[CHAT-SIDEBAR] Admin - Contact request';
import SidebarContactRequestUser from './sidebar/sidebar_contact_request/user/[CHAT-SIDEBAR] User - Contact Request';

// Import - Chat Sidebar - Contact request - In Progress
import SidebarContactRequestInProgressAdmin from './sidebar/sidebar_contact_request/admin/[CHAT-SIDEBAR] Admin - Contact request InProgress';
import SidebarContactRequestInProgressUser from './sidebar/sidebar_contact_request/user/[CHAT-SIDEBAR] User - Contact request InProgress';

// Import - Chat Sidebar - Edit request

// Import - Chat Sidebar - Edit Request - Accept
import SidebarAcceptEditRequestAdmin from './sidebar/sidebar_edit_request_accept/admin/[CHAT-SIDEBAR] Admin - Edit Request - Accept';
import SidebarAcceptEditRequestUser from './sidebar/sidebar_edit_request_accept/user/[CHAT-SIDEBAR] User - Edit Request - Accept';



// Import - Chat Sidebar - Edit Request - Sent
import SidebarSentEditRequestAdmin from './sidebar/sidebar_edit_request_sent/admin/[CHAT-SIDEBAR] Admin - Edit Request - Sent';
import SidebarSentEditRequestUser from './sidebar/sidebar_edit_request_sent/user/[CHAT-SIDEBAR] User - Edit Request - Sent';

// Import - Chat Sidebar - Edit request - Pending
import SidebarPendingEditRequestAdmin from './sidebar/sidebar_edit_request_pending/admin/[CHAT-SIDEBAR] Admin - Edit Request - Pending';
import SidebarPendingEditRequestUser from './sidebar/sidebar_edit_request_pending/user/[CHAT-SIDEBAR] User - Edit Request - Pending';

// Import - Chat Sidebar - Edit request - Reject
import SidebarRejectEditRequestAdmin from './sidebar/sidebar_edit_request_reject/admin/[CHAT-SIDEBAR] Admin - Edit Request - Reject';
import SidebarRejectEditRequestUser from './sidebar/sidebar_edit_request_reject/user/[CHAT-SIDEBAR] User - Edit Request - Reject';

// Import - Chat Sidebar - Cancel request

// Import - Chat Sidebar - Cancel request - Accept
import SidebarAcceptCancelRequestAdmin from './sidebar/sidebar_cancel_request_accept/admin/[CHAT-SIDEBAR] Admin - Cancel Request - Accept';
import SidebarAcceptCancelRequestUser from './sidebar/sidebar_cancel_request_accept/user/[CHAT-SIDEBAR] User - Cancel Request - Accept';

// Import - Chat Sidebar - Cancel request - Sent 
import SidebarSentCancelRequestUser from './sidebar/sidebar_cancel_request_sent/user/[CHAT-SIDEBAR] User - Cancel Request - Sent';

// Import - Chat Sidebar - Cancel request - Pending 
import SidebarPendingCancelRequestAdmin from './sidebar/sidebar_cancel_request_pending/admin/[CHAT-SIDEBAR] Admin - Cancel Request - Pending';

import SidebarContactRequestUserSent from '../contactRequest/user/[SIDEBAR] User - Contact Request - Sent';
import SidebarContactRequestAdminSent from '../contactRequest/admin/[SIDEBAR] Admin - Contact Request - Sent'
import SidebarContactRequestUserInProgress from '../contactRequest/user/[SIDEBAR] User - Contact Request - InProgress'
import SidebarContactRequestAdminInProgress from '../contactRequest/admin/[SIDEBAR] Admin - Contact Request - InProgress'
import ChatSidebarContactRequestInProgressAdmin from './sidebar/sidebar_contact_request/admin/[CHAT-SIDEBAR] Admin - Contact request InProgress'
import ChatSidebarContactRequestInProgressUser from './sidebar/sidebar_contact_request/user/[CHAT-SIDEBAR] User - Contact request InProgress'

import { useAuth } from '../auth/authContext';
import { auth } from '../auth/firebase';
import io from 'socket.io-client';

const initialSidebar = {
    SidebarJustChatting: true,
    SidebarContactRequestSent: false,
    SidebarContactRequestInProgress: false,
    SidebarBooking: false,
    SidebarAcceptEditRequest: false,
    SidebarSentEditRequest: false,
    SidebarPendingEditRequest: false,
    SidebarRejectEditRequest: false,
    SidebarAcceptCancelRequest: false,
    SidebarSentCancelRequest: false,
    SidebarPendingCancelRequest: false
};

function Message({ userType }) {


    const { isLoggedIn } = useAuth();
    // socket implementation
    const [socket, setSocket] = useState(null);

    const connectSocket = async () => {
        const userToken = await auth.currentUser.getIdToken();
		const newSocket = io(SOCK_ENDPOINT , {
            query: {
                token: userToken
            }
        });

        setSocket(newSocket);
    };

    useEffect(() => {
        if (isLoggedIn) {
            connectSocket()
        }
    }, [isLoggedIn]);


    /* Const - Inbox */

    /* Const - Inbox - Show  */
    const [showInbox, setShowInbox] = useState(false)

    /* Const - Inbox Detail - Show */
    const showInboxDetail = () => {
        setShowInbox(true)
        setshowInboxList(false)
    }

    /* Const - Inbox List - Show */
    const [showInboxList, setshowInboxList] = useState(true)

    /* Const - Selected inbox list */
    const [selectedInbox, setSelectedInbox] = useState([])


    // ----------------------


    /* Const - Chat */

    /* Const - Chat - Open */
    

    /* Const - Chat - Header - Admin tool - Select status "Pending" or "Treated */
    const [requestStatus, setRequestStatus] = useState({
        first: "Treated",
        second: "Treated",
        third: "Treated",
        fourth: "Treated",
        five: "Pending",
        six: "Pending",
        seven: "Pending",
        eight: "Pending",
        nine: "Pending",
        ten: "Pending",
    });

    /* Const - Close - Chat */
    const closeChat = () => {
        setShowInbox(false)
        setshowInboxList(true)
    }


    // ----------------------


    /* Const - Sidebar */

    /* Const - Sidebar */
    const [sidebarScreens, setSidebarScreens] = useState(initialSidebar);

    /* Const - Sidebar - Show */
    const [showSidebar, setShowSidebar] = useState(false)

    /* Const - Sidebar - Detail - Show */
    const showSidebarDetail = () => {
        setShowInbox(false)
        setShowSidebar(true)
    }

    /* Const - Sidebar - Active by openning chat */
    // useEffect(() => {
        // if (openChat == 1) {
        //     handleOpenJustChattingSidebar()
        // }
        // else if (openChat == 2) {
        //     handleOpenContactRequestSidebar()
        // }
        // else if (openChat == 3) {
        //     handleOpenBookingSidebar()
        // }
        // else if (openChat == 4) {
        //     handleOpenAcceptEditRequestSidebar()
        // }
        // else if (openChat == 5) {
        //     handleOpenSentEditRequestSidebar()
        // }
        // else if (openChat == 6) {
        //     handleOpenPendingEditRequestSidebar()
        // }
        // else if (openChat == 7) {
        //     handleOpenRejectEditRequestSidebar()
        // }
        // else if (openChat == 8) {
        //     handleOpenAcceptCancelRequestSidebar()
        // }
        // else if (openChat == 9) {
        //     handleOpenSentCancelRequestSidebar()
        // }
        // else if (openChat == 10) {
        //     handleOpenPendingCancelRequestSidebar()
        // }
    // }, [openChat])


    /* Const - Sidebar - Just Chatting */
    const showSidebarJustChatting = () => {
        setShowInbox(false)
        setShowSidebar(true)
        setSidebarScreens({
            SidebarJustChatting: true,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }
    const hideSidebarJustChatting = () => {
        setShowInbox(true)
        setShowSidebar(false)
        handleOpenJustChattingSidebar()
    }

    const handleOpenJustChattingSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: true,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Contact request - Sent */
    const showSidebarContactRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: true,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    const hideSidebarContactRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
        handleOpenContactRequestSidebar()
    }

    const handleOpenContactRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: true,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Contact request - InProgress */
    const showSidebarContactRequestInProgress = () => {
        setShowInbox(false)
        setShowSidebar(true)
        setSidebarScreens({
            SidebarJustChatting: false,
            SSidebarContactRequestSent: false,
            SidebarContactRequestInProgress: true,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    const hideSidebarContactRequestInProgress = () => {
        setShowInbox(true)
        setShowSidebar(false)
        handleOpenContactRequestInProgressSidebar()
    }

    const handleOpenContactRequestInProgressSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: true,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Booking */
    const showSidebarBooking = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenBookingSidebar()
    }

    const hideSidebarBooking = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenBookingSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: true,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Edit Request - Accept */
    const showSidebarAcceptEditRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenAcceptEditRequestSidebar()
    }
    const hideSidebarAcceptEditRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenAcceptEditRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: true,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Edit Request - Sent */
    const showSidebarSentEditRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenSentEditRequestSidebar()
    }
    const hideSidebarSentEditRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenSentEditRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: true,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Edit request - Pending */
    const showSidebarPendingEditRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenPendingEditRequestSidebar()
    }
    const hideSidebarPendingEditRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenPendingEditRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: true,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Edit request - Reject */
    const showSidebarRejectEditRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenRejectEditRequestSidebar()
    }
    const hideSidebarRejectEditRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenRejectEditRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: true,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Cancel request - Accept */
    const showSidebarAcceptCancelRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenAcceptCancelRequestSidebar()
    }
    const hideSidebarAcceptCancelRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenAcceptCancelRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: true,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Cancel request - Sent */
    const showSidebarSentCancelRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenSentCancelRequestSidebar()
    }
    const hideSidebarSentCancelRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }
    const handleOpenSentCancelRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: true,
            SidebarPendingCancelRequest: false
        });
    }

    /* Const - Sidebar - Cancel request - Pending */
    const showSidebarPendingCancelRequest = () => {
        setShowInbox(false)
        setShowSidebar(true)
        handleOpenPendingCancelRequestSidebar()
    }
    const hideSidebarPendingCancelRequest = () => {
        setShowInbox(true)
        setShowSidebar(false)
    }

    const handleOpenPendingCancelRequestSidebar = () => {
        setSidebarScreens({
            SidebarJustChatting: false,
            SidebarContactRequestSent: false,
            SidebarContactRequestInProgress: false,
            SidebarBooking: false,
            SidebarAcceptEditRequest: false,
            SidebarSentEditRequest: false,
            SidebarPendingEditRequest: false,
            SidebarRejectEditRequest: false,
            SidebarAcceptCancelRequest: false,
            SidebarSentCancelRequest: false,
            SidebarPendingCancelRequest: true
        });
    }


    /* Const - Handle the screen size to make page responsive */
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (windowSize > 900)
            setShowSidebar(true)
    }, [windowSize])


    const [selectedChatUser, setSelectedChatUser] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(false)
    const [openChat, setOpenChat] = useState(1)
    const [isSidebarContactRequestUserSentOpen, setIsSidebarContactRequestUserSentOpen] = useState(false)
    const [isSidebarContactRequestAdminSentOpen, setIsSidebarContactRequestAdminSentOpen] = useState(false)
    const [isSidebarContactRequestUserInProgressOpen, setIsSidebarContactRequestUserInProgressOpen] = useState(false)
    const [isSidebarContactRequestAdminInProgressOpen, setIsSidebarContactRequestAdminInProgressOpen] = useState(false)
    const [contactRequest, setContactRequest] = useState(null)
    const [sideBarContactRequest, setSideBarContactRequest] = useState(null)
    const [chatUser, setChatUser] = useState(null)
    const [currentChat, setCurrentChat] = useState(null)

    const { user } = useAuth()

    useEffect(() => {
        if (socket) {
            socket?.on('connect', () => {
                console.log('socket Connected!');
            });

            socket?.on('connect_error', (error) => {
                console.error('socket Connection Error: ', error);
            });

            socket?.on('connect_timeout', () => {
                console.error('socket Connection Timeout');
            });

            socket?.on('error', (error) => {
                console.error('socket Received error: ', error);
            });
        }
    }, [socket]);


    const handleSideBar = (chat) => {

        const isMobile = window.innerWidth < 768;

        setCurrentChat(chat)

        switch (chat.chatState) {
            case 'just chatting':
                if(user.role === 'admin') {
                    chat?.User1.role === 'admin_profile' || chat?.User1.role === 'admin' ? setChatUser(chat?.User2) : setChatUser(chat?.User1)
                }
                else {
                    chat?.User1.role === 'user' ? setChatUser(chat?.User2) : setChatUser(chat?.User1)
                }
                if (!isMobile) {showSidebarJustChatting()}
                break;
            case 'contact request sent':
                setSideBarContactRequest(chat?.contactRequest)
                if (!isMobile) {showSidebarContactRequest()}
                break;
            case 'contact request in progress':
                setSideBarContactRequest(chat?.contactRequest)
                if (!isMobile) {showSidebarContactRequestInProgress()}
                break;
            case 'booking confirm':
                if (!isMobile) {showSidebarBooking()}
                break;
            case 'edit request accept':
                if (!isMobile) {showSidebarAcceptEditRequest()}
                break;
            case 'edit request sent':
                if (!isMobile) {showSidebarSentEditRequest()}
                break;
            case 'edit request reject':
                if (!isMobile) {showSidebarRejectEditRequest()}
                break;
            case 'edit request pending':
                if (!isMobile) {showSidebarPendingEditRequest()}
                break;
            default:
                console.log('Chat state not found', chat.chatState)
                if (!isMobile) {showSidebarJustChatting()}

        }
    }

    const handleContactRequestSidebar = (request) => { 
        setContactRequest(request)
        if (user?.role === 'admin') {

            switch (request?.status) {
                case 'Sent':
                    setIsSidebarContactRequestAdminSentOpen(true)
                    break;
                case 'In Progress':
                    setIsSidebarContactRequestAdminInProgressOpen(true)
                    break;
                default:
                    break
            }
        }
        else {
            switch (request?.status) {
                case 'Sent':
                    setIsSidebarContactRequestUserSentOpen(true)
                    break
                case 'In Progress':
                    setIsSidebarContactRequestUserInProgressOpen(true)
                    break
            }
            
        }
    }

    const handleContactRequestSidebarUserSentClose = () => {
        setIsSidebarContactRequestUserSentOpen((prev) => !prev)
        setContactRequest(null)
    }

    const handleContactRequestSidebarAdminSentClose = () => {
        setIsSidebarContactRequestAdminSentOpen((prev) => !prev)
        setContactRequest(null)
    }

    const handleContactRequestSidebarUserInProgressClose = () => {
        setIsSidebarContactRequestUserInProgressOpen((prev) => !prev)
        setContactRequest(null)
    }

    const handleContactRequestSidebarAdminInProgressClose = () => {
        setIsSidebarContactRequestAdminInProgressOpen((prev) => !prev)
        setContactRequest(null)
    }


    return (

        /* Wrapper */
        <div className="clt_message_wrapper">
            <div className="clt_message_inner_wrapper">

                {windowSize > 600 ?
                    <div className={windowSize < 1100 && windowSize > 600 ? `${showSidebar ? 'slideOut2' : 'slideIn2'}` : ''}>

                        {/* Inbox - List - Show Desktop  */}
                        <div className={`clt_inbox  ${!showInboxList ? 'd-none d-sm-block' : ''}`}>
                            <InboxListContainer
                                userType={userType}
                                showInboxDetail={showInboxDetail}
                                setOpenChat={setOpenChat}
                                openChat={openChat}
                                requestStatus={requestStatus}
                                socket={socket}
                                setSelectedChatUser={setSelectedChatUser}
                                setMessagesLoading={setMessagesLoading}
                                selectedChatUser={selectedChatUser}
                                // chatsDetail={chatsDetail}
                                // chatLoading={chatLoading}
                                // joinChat={joinChat}
                                // getChats={getChats}
                                handleSidebar={handleSideBar}
                            />
                        </div>
                    </div>

                    :

                    /* Inbox - List - Show Mobile */
                    <div className={`clt_inbox  ${!showInboxList ? 'd-none d-sm-block' : ''}`}>
                        <InboxListContainer
                            userType={userType}
                            showInboxDetail={showInboxDetail}
                            setOpenChat={setOpenChat}
                            openChat={openChat}
                            requestStatus={requestStatus}
                            socket={socket}
                            setSelectedChatUser={setSelectedChatUser}
                            setMessagesLoading={setMessagesLoading}
                            selectedChatUser={selectedChatUser}
                            // chatsDetail={chatsDetail}
                            // chatLoading={chatLoading}
                            // joinChat={joinChat}
                            // getChats={getChats}
                            handleSidebar={handleSideBar}
                        />
                    </div>
                }


                {/* Inbox and Chat - Show */}
                <div className={`clt_message_inbox_detail ${!showInbox ? 'd-none d-sm-block' : ''}`}>
                    {currentChat && <Inbox
                        requestStatus={requestStatus}
                        setRequestStatus={setRequestStatus}
                        openChat={openChat}
                        closeChat={closeChat}
                        socket={socket}
                        userType={userType}
                        selectedChatUser={selectedChatUser}

                        showSidebar={showSidebar}
                        showSidebarDetail={showSidebarDetail}

                        showSidebarJustChatting={showSidebarJustChatting}
                        hideSidebarJustChatting={hideSidebarJustChatting}

                        showSidebarContactRequest={showSidebarContactRequest}
                        hideSidebarContactRequest={hideSidebarContactRequest}

                        showSidebarContactRequestInProgress={showSidebarContactRequestInProgress}
                        hideSidebarContactRequestInProgress={hideSidebarContactRequestInProgress}


                        showSidebarBooking={showSidebarBooking}
                        hideSidebarBooking={hideSidebarBooking}

                        showSidebarAcceptEditRequest={showSidebarAcceptEditRequest}
                        hideSidebarAcceptEditRequest={hideSidebarAcceptEditRequest}

                        showSidebarSentEditRequest={showSidebarSentEditRequest}
                        hideSidebarSentEditRequest={hideSidebarSentEditRequest}

                        showSidebarPendingEditRequest={showSidebarPendingEditRequest}
                        hideSidebarPendingEditRequest={hideSidebarPendingEditRequest}

                        showSidebarRejectEditRequest={showSidebarRejectEditRequest}
                        hideSidebarRejectEditRequest={hideSidebarRejectEditRequest}

                        showSidebarAcceptCancelRequest={showSidebarAcceptCancelRequest}
                        hideSidebarAcceptCancelRequest={hideSidebarAcceptCancelRequest}

                        showSidebarSentCancelRequest={showSidebarSentCancelRequest}
                        hideSidebarSentCancelRequest={hideSidebarSentCancelRequest}

                        showSidebarPendingCancelRequest={showSidebarPendingCancelRequest}
                        hideSidebarPendingCancelRequest={hideSidebarPendingCancelRequest}

                        messagesLoading={messagesLoading}
                        setMessagesLoading={setMessagesLoading}

                        handleContactRequestSidebar={handleContactRequestSidebar}

                        currentChat={currentChat}
                    />}
                </div>



                {/* Sidebar - Show */}
                <div className={`${!showSidebar ? 'd-none d-sm-block slideOut' : 'slideIn'}`}>
                    <div className={`clt_message_inbox_user_detail`}>

                        {/* Sidebar - Just Chatting */}
                        {sidebarScreens.SidebarJustChatting &&  (
                            <>
                                {userType !== "admin" ?

                                    /* User */
                                    <SidebarJustChattingUser userType={userType} hideSidebar={hideSidebarJustChatting} />

                                    :

                                    /* Admin */
                                    <SidebarJustChattingAdmin hideSidebar={hideSidebarJustChatting} chatUser={chatUser} />
                                }
                            </>
                        )}


                        {/* Sidebar - Contact request - Sent*/}
                        {sidebarScreens.SidebarContactRequestSent && sideBarContactRequest && showSidebar ? (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarContactRequestAdmin 
                                        hideSidebar={hideSidebarContactRequest} 
                                        socket={socket} 
                                        contactRequest={sideBarContactRequest}
                                    />

                                    :

                                    /* User */
                                    <SidebarContactRequestUser 
                                        hideSidebar={hideSidebarContactRequest} 
                                        socket={socket} 
                                        contactRequest={sideBarContactRequest}
                                    />
                                }
                            </>
                        ) : null}

                        {sidebarScreens.SidebarContactRequestInProgress && sideBarContactRequest ? (
                            <>
                                {userType == "admin" ?

                                    <ChatSidebarContactRequestInProgressAdmin
                                        hideSidebar={hideSidebarContactRequest} 
                                        socket={socket} 
                                        contactRequest={sideBarContactRequest}
                                    />

                                    :

                                    <ChatSidebarContactRequestInProgressUser
                                        hideSidebar={hideSidebarContactRequest} 
                                        socket={socket} 
                                        contactRequest={sideBarContactRequest}
                                    />

                                }
                            </>
                        ) : null}


                        {/* Sidebar - Booking Detail */}
                        {sidebarScreens.SidebarBooking && (
                            <>
                                {userType !== "admin" ?

                                    /* User */
                                    <SidebarBookingUser userType={userType} hideSidebar={hideSidebarBooking} />

                                    :

                                    /* Admin */
                                    <ChatSidebarBookingAdmin hideSidebar={hideSidebarBooking} />
                                }
                            </>
                        )}


                        {/* Sidebar - Edit request - Accept */}
                        {sidebarScreens.SidebarAcceptEditRequest && (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarAcceptEditRequestAdmin userType={userType} hideSidebar={hideSidebarAcceptEditRequest} />

                                    :

                                    /* User */
                                    <SidebarAcceptEditRequestUser hideSidebar={hideSidebarAcceptEditRequest} />
                                }
                            </>
                        )}

                        {/* Sidebar - Edit request - Sent  */}
                        {sidebarScreens.SidebarSentEditRequest && (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarSentEditRequestAdmin userType={userType} hideSidebar={hideSidebarSentEditRequest} />

                                    :

                                    /* User */
                                    <SidebarSentEditRequestUser hideSidebar={hideSidebarSentEditRequest} />
                                }
                            </>
                        )}

                        {/* Sidebar - Edit request - Pending  */}
                        {sidebarScreens.SidebarPendingEditRequest && (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarPendingEditRequestAdmin userType={userType} hideSidebar={hideSidebarPendingEditRequest} />

                                    :

                                    /* User */
                                    <SidebarPendingEditRequestUser hideSidebar={hideSidebarPendingEditRequest} />
                                }
                            </>
                        )}

                        {/* Sidebar - Edit request - Reject  */}
                        {sidebarScreens.SidebarRejectEditRequest && (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarRejectEditRequestAdmin userType={userType} hideSidebar={hideSidebarRejectEditRequest} />

                                    :

                                    /* User */
                                    <SidebarRejectEditRequestUser hideSidebar={hideSidebarRejectEditRequest} />
                                }
                            </>
                        )}

                        {/* Sidebar - Cancel request - Accept  */}
                        {sidebarScreens.SidebarAcceptCancelRequest && (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarAcceptCancelRequestAdmin userType={userType} hideSidebar={hideSidebarAcceptCancelRequest} />

                                    :

                                    /* User */
                                    <SidebarAcceptCancelRequestUser hideSidebar={hideSidebarAcceptCancelRequest} />

                                }
                            </>
                        )}

                        {/* Sidebar - Cancel request - Sent  */}
                        {sidebarScreens.SidebarSentCancelRequest && (
                            <>
                                {userType == "admin" ?

                                    ""

                                    :

                                    /* User */
                                    <SidebarSentCancelRequestUser hideSidebar={hideSidebarSentCancelRequest} />

                                }
                            </>
                        )}

                        {/* Sidebar - Cancel request - Pending  */}
                        {sidebarScreens.SidebarPendingCancelRequest && (
                            <>
                                {userType == "admin" ?

                                    /* Admin */
                                    <SidebarPendingCancelRequestAdmin userType={userType} hideSidebar={hideSidebarPendingCancelRequest} />

                                    : ""

                                }
                            </>
                        )}


                    </div>
                </div>

            </div>

            {isSidebarContactRequestUserSentOpen && <SidebarContactRequestUserSent
                data={{ContactRequest: contactRequest}}
                isOpenSidebarContactRequestUserSent={isSidebarContactRequestUserSentOpen}
                onCloseOpenSidebarContactRequestUserSent={handleContactRequestSidebarUserSentClose}
            />}

            {isSidebarContactRequestAdminSentOpen && <SidebarContactRequestAdminSent
                data={{ContactRequest: contactRequest}}
                isOpenSidebarContactRequestAdminSent={isSidebarContactRequestAdminSentOpen}
                onCloseOpenSidebarContactRequestAdminSent={handleContactRequestSidebarAdminSentClose}
            />}

            {isSidebarContactRequestUserInProgressOpen && <SidebarContactRequestUserInProgress
                data={{ContactRequest: contactRequest}}
                isOpenSidebarContactRequestUserInProgress={isSidebarContactRequestUserInProgressOpen}
                onCloseOpenSidebarContactRequestUserInProgress={handleContactRequestSidebarUserInProgressClose}
            />}

            {isSidebarContactRequestAdminInProgressOpen && <SidebarContactRequestAdminInProgress
                data={{ContactRequest: contactRequest}}
                isOpenSidebarContactRequestAdminInProgress={isSidebarContactRequestAdminInProgressOpen} 
                onCloseOpenSidebarContactRequestAdminInProgress={handleContactRequestSidebarAdminInProgressClose}
            />}
        </div >

        
    )

    
}

export default Message

function getWindowSize() {
    const { innerWidth } = window;
    return innerWidth;
}

