// import
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import "./[CHAT-SIDEBAR] User - Booking.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay5 from "../../../../../../assets/img/confirm_pay_5.png";
import CustomHr from "../../../../../reusable/CustomHr";

// import - Booking - Screens
import Booking from "../../../../detail/booking/booking_user";

// ------------------------------------------------------------------------------

// import - Section

// import - Section - Request Pending
import SectionRequestPending from "../../../../../reusable/SectionRequestPending";

// import - Section - Top
import SectionTop from "../../../../../reusable/booking/SectionTop";

// import - Section - Event
import SectionEvent from "../../../../../reusable/booking/SectionEvent";
import Event from "../../../../event/Event";

// import - Section - Date
import SectionDate from "../../../../../reusable/booking/SectionDate";

// import - Section - Guest
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import Guest from "../../../../guest/Guest";

// import - Section - User
import SectionUser from "../../../../../reusable/booking/SectionUser";
import Account from "../../../../account/Account";
import { useAuth } from "../../../../auth/authContext";

// import - Section - Room
import SectionBed from "../../../../../reusable/booking/SectionRoom";
import Bed from "../../../../bed/OptionRoom";

// import - Section - Access
import SectionAccess from "../../../../../reusable/SectionAccess";

// import - Section - Rule and condition
import SidebarRuleAndCondition from "../../../../../reusable/booking/SidebarRuleAndCondition";

// import - Section - Call / Message / Adress
import SectionContact from "../../../../../reusable/booking/SectionContact";

// import - Section - invoice
import InvoiceOffcanvas from "../../../../../reusable/invoice/SidebarInvoiceList";

// import - Section - Price Detail
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetail";

// import - Section - Remaining payment
import SectionRemainingPayment from "../../../../../reusable/booking/SectionRemainingPayment";
import RemainingPayment from "../../../../myBooking/RemainingPayment";

// import - Section - Payment method
import Payment from "../../../../payment/Payment";

// import - Section - Clickable
import SectionClickable from "../../../../../reusable/SectionClickable";

// import SectionDamageDeposit from "../../../reusable/booking/SectionDamageDeposit";
// import { Link } from "react-router-dom";

/* Const - Initial screen that open */
const initialBookingScreens = {
    bookingDetail: true,
    cancelBooking: false,
};

/* Const */
const ChatSidebarBookingUser = ({ hideSidebar }) => {

    /* Const - My booking - Screens */
    const [bookingScreens, setBookingScreens] = useState(initialBookingScreens);

    /* Const - My booking - Screen : Booking detail */

    /* Const - Section - Event */
    const [eventType, setEventType] = useState(eventweddingData);
    const [isEventSidebar, setIsEventSidebar] = useState(false);

    /* Const - Section - Date */
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);
    const handleDateEdit = () => {
        setIsBookingSidebar(true);
        // setBookingScreens((prevState) => ({
        //   ...prevState,
        //   dateAndTimeSlots: true,
        //   confirmAndPay: false,
        // }));
    };

    /* Const - Section - Guest */
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [guestCount, setGuestCount] = useState(10);

    /* Const - Section room */
    const [isBedSidebar, setIsBedSidebar] = useState(false);
    const [bedCount, setBedCount] = useState([
        { roomTitle: "Room 1", nights: "1 night (10 €)" },
        { roomTitle: "Room 2", nights: "2 night (20 €)" },
      ]);
    const handleBed = () => {
      setIsBedSidebar(true);
    };

    /* Const - Section - Remaining payment */
    const [isRemainingSidebar, setIsRemainingSidebar] = useState(false);
    const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);

    /* Const - Section - User profile */
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const { isLoggedIn, user } = useAuth();
    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com",
    });
    useEffect(() => {
        if (user)
        setSelectedProfile({
            type: "Personal",
            detail: `${user?.firstName} . ${user?.phone} . ${user?.email}`,
        });
    }, [user]);

    /* Const - Section - Damage deposit */
    const [isDamageDeposit, setIsDamageDeposit] = useState(false);
    
    

    /* Const - Section - invoice */
    const [isInvoiceDetail, setIsInvoiceDetail] = useState(false);

    /* Const - Section - Rules and conditions */
    const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] = useState(false);

    /* Const - Button - Cancel booking */
    const handleCancelBooking = () => {
        setBookingScreens((prevState) => ({
            ...prevState,
            bookingDetail: false,
            cancelBooking: true,
        }));
    };





    return (
        <>


            {/* Sidebar - Booking detail - User */}
            <div
                className="clt_message_user_request_offcanvas"
            >


                {/* Header */}
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                       [CHAT-SIDEBAR] User - Booking
                    </div>

                </div>

                


                {/* Body */}
                <div
                    className="clt_message_user_request_offcanvas_body"
                >
                    <>
                        <SectionRequestPending
                            innerTitle="Request pending"
                            innerSubTitle="You have 1 request(s) concerning this booking"
                            onClick={() => setIsInvoiceDetail(true)}
                        />
                        <CustomHr
                            colClass="clt-booking-hr-pd"
                            hrClass="p-0 m-0 clt-hr"
                        />
                        <SectionTop
                            image={ConfirmPayProfilePicture1}
                            title="CLT"
                            imageSize="large"
                            subTitle="Wedding · 25€ · #111111"
                        />
                        <CustomHr
                            colClass="clt-booking-hr-pd"
                            hrClass="p-0 m-0 clt-hr"
                        />
                        <SectionContact/>
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionAccess
                            handleClick={() => console.log("clicked")}
                            innerTitle="Key handhover"
                            innerSubTitle="Subtext"
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <h2 className="clt-mybooking-title">Your booking</h2>
                        <SectionEvent
                            image={eventType.iconActive}
                            subText={eventType.title}
                            // setIsEventSidebar={() => setIsEventSidebar(true)}
                            // buttonText="Edit"
                        />
                        <SectionDate
                            image={ConfirmPay2}
                            handleDateEdit={handleDateEdit}
                            subText1="Subtext 1"
                            subText2="Subtext 2"
                            isDateSelect={true}
                        />

                        <SectionGuest
                            image={ConfirmPay3}
                            subText={`${guestCount} guests`}
                            setIsGuestSidebar={() => setIsGuestSidebar(true)}
                            buttonText="Edit"
                        />

                        <SectionBed
                            title="Room"
                            image={ConfirmPay5}
                            subText={"Add room"}
                            roomSelected={bedCount}
                            setIsBedSidebar={handleBed}
                            buttonText={bedCount.length > 0 ? "Edit" : "Add"}
                        />

                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionUser
                            handleClick={() => setIsAccountInfo(true)}
                            text="User"
                            title="Personal Information"
                            subText={<span>John Doe . 055555555 . johndoe@gmail.com</span>}
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionClickable
                            title="Invoice"
                            innerTitle="Invoice"
                            innerSubTitle="Find the invoices of this reservation"
                            onClick={() => setIsInvoiceDetail(true)}
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionClickable
                            title="Rules and conditions"
                            innerTitle="Rules and conditions"
                            innerSubTitle="Here are the rules and conditions about your booking"
                            onClick={() => setIsSection6SiderbarDetail(true)}
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionPriceDetail
                            title="Price detail"
                            priceListData={[
                                { title: "CLT", tooltipMsg: null, price: "15€" },
                                { title: "Room 1 (1 night)", tooltipMsg: null, price: "10€" },
                                {
                                    title: "Deposit",
                                    tooltipMsg:
                                        "Damage deposit: An inventory will be made before and after your reservation. A deposit may be automatically deducted for the damage incurred.",
                                    price:
                                        "You will be charged automatically in case of damage.",
                                },
                            ]}
                            totalPrice="25€"
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <Row>
                            <Col>
                                <div className="clt-booking-btn-refuse-div mb-5">
                                    <Button
                                        className="clt-booking-btn-refuse"
                                        onClick={handleCancelBooking}
                                    >
                                        <span>Cancel Booking</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>


                </div>
            </div>

            {/* Function */}
            

            {/* Function - Section - Booking */}
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={() => setIsBookingSidebar(false)}
                eventType={eventType}
                isFromBooking={true}
                isFromBookingDate={{
                    startDate: new Date(),
                    endDate: new Date(),
                }}
                isFromBookingTimeSlot={{
                    id: 1,
                    title: "Title",
                    subTitle: "subTitle",
                    time: "time",
                }}
            />

            {/* Function - Section - Event */}
            <Event
                show={isEventSidebar}
                onHide={() => setIsEventSidebar(false)}
                eventType={eventType}
                onConfirmEvent={(event) => {
                if (event !== eventType) {
                    setIsBookingSidebar(true);
                }
                setEventType(event);
                }}
            />

            {/* Function - Section - Guest */}
            <Guest
                isGuestSidebar={isGuestSidebar}
                editGuestValue={guestCount}
                onCloseGuestSidebar={(guestCount) => {
                    setIsGuestSidebar(false);
                    setGuestCount(guestCount);
                }}
            />


            {/* Function - Section - Room */}
            <Bed
                isBedSidebar={isBedSidebar}
                onCloseBedSidebar={() => setIsBedSidebar(false)}
                onConfirm={(val) => {
                setBedCount(val);
                setBookingScreens((prevState) => ({
                    ...prevState,
                    confirmContinueAndPay: true,
                    bookingDetail: false,
                }));
                }}
                isComingFromMyBooking={true}
            />

            {/* Function - Section - Remaining Payment */}
            <RemainingPayment
                isSidebar={isRemainingSidebar}
                onCloseSidebar={() => setIsRemainingSidebar(false)}
                isPaymentCardsOpen={() => setIsPaymentSidebar(true)}
            />

            {/* Function - Section - Pay Remaining Payment */}
            <Payment
                isPaymentSidebar={isPaymentSidebar}
                onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
                isFromBooking={true}
                getPaymentCard={(val) => console.log(val)}
            />
            
            {/* Function - Section - User profile */}
            <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="update"
                selectedProfileData={selectedProfile}
                setSelectedProfileData={setSelectedProfile}
                setIsUserSidebar={() => setIsBookingSidebar(true)}
            />

            {/* Function - Section - invoice */}
            <InvoiceOffcanvas
                isInvoiceSidebar={isInvoiceDetail}
                onCloseInvoiceSidebar={setIsInvoiceDetail}
            />

            
        </>
    );
};

export default ChatSidebarBookingUser;
