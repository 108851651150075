import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] Admin - Contact request.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import MessageIcon from "../../../../../../assets/img/chat_icon.png";
import CustomHr from "../../../../../reusable/CustomHr";
import Account from "../../../../account/Account";
import { useAuth } from "../../../../auth/authContext";
import SectionInformationMessageRequestSent from "../../../../../reusable/SectionInformationMessageRequestSent";
import SectionContactRequestMessagAnswer from "../../../../../reusable/contactrequest/SectionContactRequestMessagAnswer"
import SectionContactRequestEvent from "../../../../../reusable/contactrequest/SectionContactRequestEvent";
import SectionContactRequestDate from "../../../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestMessage from "../../../../../reusable/contactrequest/SectionContactRequestMessage";
import axios from "axios";
import SectionUser from "../../../../../reusable/booking/SectionUser";
import { API_ENDPOINT } from "../../../../../../constants";
import { ErrorToast, SuccessBookingToast, SuccessToast } from "../../../../../reusable/Toast";
import { auth } from "../../../../auth/firebase";
const initialBookingScreens = {
    noBooking: true,
    bookingList: false,
    bookingDetail: false,
    cancelBooking: false,
};

const ChatSidebarContactRequestAdmin = ({ hideSidebar, socket, contactRequest}) => {
    const [latestContactRequest, setLatestContactRequest] = useState(null);
    const [eventType, setEventType] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [guestCount, setGuestCount] = useState(null);

    const [, setBookingScreens] = useState(initialBookingScreens);
    
    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com",
        profilePic: null,
        name: "John Doe",
    })

    const [chatUser, setChatUser] = useState(null);

    const { isLoggedIn, user } = useAuth();

    const [showAccountSidebar, setShowAccountSidebar] = useState(false);

    useEffect(() => {
        setLatestContactRequest(contactRequest)
        setSelectedProfile({ 
            type: "Personal", 
            detail: `${contactRequest?.User?.firstName} ${contactRequest?.User?.lastName} · ${contactRequest?.User?.phone} · ${contactRequest?.User?.email}`, 
            profilePic: contactRequest?.User?.profilePictureUrl, 
            name: `${contactRequest?.User?.firstName} ${contactRequest?.User?.lastName}`})

        setChatUser(contactRequest?.User)
    }, [contactRequest])

    useEffect(() => {

        const handleContactRequestUpdated = (request) => {
            if(latestContactRequest && latestContactRequest.id !== request.id) {
                return;
            }
            setLatestContactRequest(request);
        }

        socket.on("contactRequestUpdated", handleContactRequestUpdated)

        return () => {
            socket.off("contactRequestUpdated", handleContactRequestUpdated)
        }
    }, [socket])

    const handleCancelBooking = () => {
        setBookingScreens((prevState) => ({
            ...prevState,
            bookingDetail: false,
            cancelBooking: true,
        }));
    };

    const handleRequestEdit = async (dataToSend) => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    ...dataToSend
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("La demande a été mise à jour");
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    const HandleStateInProgress = async () => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    status: "In Progress",
                    notificationData: {
                        title: "Notification: Contact request - In Progress",
                        message: "The ticket has been reply by admin"
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("The state of the request is change to 'In progress'")
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    const handleRefuse = async () => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    status: "Cancel",
                    notificationData: {
                        title: `Notification: Demande de contact annulée par ${latestContactRequest?.User?.firstName} ${latestContactRequest?.User?.lastName}`,
                        message: `La demande de contact a été annulée par le château ou ${latestContactRequest?.User?.firstName}`,
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            SuccessToast("La demande a été annulé")
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    const handleTicketResolve = async () => {
        const token = await auth.currentUser.getIdToken();
        try {
            await axios.patch(API_ENDPOINT + "/contact/request",
                {
                    id: latestContactRequest.id,
                    status: "Accept",
                    notificationData: {
                        title: "Notification: Contact request - Accept",
                        message: "The ticket is solve"
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
        } catch (error) {
            ErrorToast(error?.response?.data?.message);
        }
    };

    return (
        <>
            <div
                className="clt_message_user_request_offcanvas"
            >
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        Demande de contact
                    </div>

                </div>

                <div className="clt_message_user_request_offcanvas_body">

                    {latestContactRequest ? (
                        <>
                        <h2 className="clt-mybooking-title">Utilisateur</h2>
                        <SectionUser
                            handleClick={() => setShowAccountSidebar(true)}
                            text={
                                selectedProfile.type === "Personal" ? "Personnel" :
                                selectedProfile.type === "Professional" ? "Professionnel" :
                                `Profil ${selectedProfile.type}`
                            }
                            title="User"
                            subText={
                                <span>{selectedProfile.detail}</span>
                            }
                            profilePic={selectedProfile.profilePic}
                            name={selectedProfile.name}
                            isClassActive={true}
                        />
                        </>
                    ) : null}
                </div>
                <CustomHr
                rowClass="p-0 m-0"
                colClass="clt-booking-hr-pd p-0 w-100"
                hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_message_user_request_offcanvas_body">

                    {latestContactRequest && (
                        <>
                        { latestContactRequest ? (
                            <>  
                                <h2 className="clt-mybooking-title">Demande de contact</h2>
                                <SectionInformationMessageRequestSent
                                    innerTitle="Reçu"
                                    innerSubTitle="La demande à était envoyée par l'utilisateur"
                                />
                            </>
                        ) : null}
                        </>
                    )}

                    <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <h2 className="clt-mybooking-title">Detail</h2>
                    <SectionTop
                        image={ConfirmPayProfilePicture1}
                        title="Lieu"
                        subTitle="Château Latournelle"
                    />
                    {latestContactRequest ? (
                        <>
                            <SectionContactRequestEvent
                                image={ConfirmPay1}
                                subText={`${latestContactRequest.Event.title}`}
                                buttonText="Modifier"
                                IsEventButtonDropdown={true} 
                                eventType={eventType}
                                event={latestContactRequest.Event}
                                setEventType={setEventType}
                                handleRequestEdit={handleRequestEdit}
                            />

                            <SectionContactRequestDate
                                image={ConfirmPay2}
                                subText={`${latestContactRequest.month} ${latestContactRequest.year}`}
                                IsDateButtonDropdown={true} 
                                selectedMonth={selectedMonth}
                                setSelectedMonth={setSelectedMonth}
                                selectedYear={selectedYear}
                                setSelectedYear={setSelectedYear}
                                handleRequestEdit={handleRequestEdit}
                            />

                            <SectionContactRequestGuest
                                image={ConfirmPay3}
                                subText={`${latestContactRequest.guest_number}`}
                                IsGuestButtonDropdown={true}
                                guestCount={guestCount}
                                setGuestCount={setGuestCount}
                                handleRequestEdit={handleRequestEdit}
                                // subText={`${guestCount} guests`}
                                // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                                // buttonText="Edit"
                            />

                            <SectionContactRequestMessage
                                image={MessageIcon}
                                subText={`${latestContactRequest.message}`}
                            />
                            <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                            <Row>
                                <Col>

                                    <div className="clt-booking-btn-refuse-div">
                                    <Button
                                        className="shadow-none clt-booking-btn-refuse"
                                        onClick={handleRefuse}
                                    >
                                        <span>Annuler demande</span>
                                    </Button>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : null }
                </div>
            </div>
            {showAccountSidebar && 
                <Account
                    isOpenSidebar={showAccountSidebar}
                    setIsOpen={() => {
                        setShowAccountSidebar(true);
                    }}
                    onCloseSidebar={() => {
                        setShowAccountSidebar(false);
                    }}
                    selectProfile="admin"
                    isTrue={true}
                    dataa={{ User: chatUser}}
                />
            }
        </>
    );
};

export default ChatSidebarContactRequestAdmin;
