/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] User - Just chatting.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import CustomHr from "../../../../../reusable/CustomHr";

import Guest from "../../../../guest/Guest";
import Account from "../../../../account/Account";
import Booking from "../../../../detail/booking/booking_user";
import SectionContact from "../../../../../reusable/booking/SectionContact";




{/* Const */}
const initialBookingScreens = {
    noBooking: true,
    bookingList: false,
    bookingDetail: false,
    cancelBooking: false,
};


const ChatSidebarJustChattingUser = ({ hideSidebar }) => {
    const [eventType, setEventType] = useState(eventweddingData);
    const [bookingScreens, setBookingScreens] = useState(initialBookingScreens);
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [isDamageDeposit, setIsDamageDeposit] = useState(false);
    const [guestCount, setGuestCount] = useState(10);
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const [isSection6SiderbarDetail, setIsSection6SiderbarDetail] =
        useState(false);
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);
    const handleBack = (from, to) => {
        setBookingScreens((prevState) => ({
            ...prevState,
            [from]: !prevState[from],
            [to]: !prevState[to],
        }));
    };



    return (
        <>
            {/* Sidebar - Just chatting - User */}
            <div className="clt_message_user_chat_offcanvas">


                {/* Header */}
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                       Discussion
                    </div>

                </div>


                {/* Body */}
                <div
                    className="clt_message_user_chat_offcanvas_body"
                >
                    <>
                        
                        <div className="clt_message_user_chat_top_section">
                            <SectionTop
                                image={ConfirmPayProfilePicture1}
                                title="Château Latournelle"
                                imageSize="large"
                            />
                        </div>
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionContact/>
                    </>
                </div>
            </div>




            {/* Const */}
            <Guest
                isGuestSidebar={isGuestSidebar}
                editGuestValue={guestCount}
                onCloseGuestSidebar={(guestCount) => {
                    setIsGuestSidebar(false);
                    setGuestCount(guestCount);
                }}
            />
            <Account
                isOpen={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(false);
                }}
                closeSideBar={() => {
                    setIsAccountInfo(false);
                }}
            />
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={() => setIsBookingSidebar(false)}
                eventType={eventType}
                isFromBooking={true}
                isFromBookingDate={{
                    startDate: new Date(),
                    endDate: new Date(),
                }}
                isFromBookingTimeSlot={{
                    id: 1,
                    title: "Title",
                    subTitle: "subTitle",
                    time: "time",
                }}
            />
        </>
    );
};

export default ChatSidebarJustChattingUser;
