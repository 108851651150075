// import
import React, { useEffect, useState, useRef  } from "react";
import { Box } from "@mui/material";
import { Col, Container, Row,Button } from "react-bootstrap";
import { Button as MuiButton } from "@mui/material";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import PhotoSwipeLightbox from "photoswipe/lightbox";

import CustomOffCanvas from "./CustomOffCanvas";
import CustomHr from "./CustomHr";
import image1 from "../../assets/img/img_new_section_1_uni.jpg";
import image2 from "../../assets/img/img_new_section_2_uni.jpg";
import image3 from "../../assets/img/img_new_section_3_uni.jpg";
import image4 from "../../assets/img/img_new_section_4_uni.jpg";
import image5 from "../../assets/img/img_new_section_5.jpg";
import image6 from "../../assets/img/img_new_section_6_uni.jpg";
import image7 from "../../assets/img/img_new_section_7_uni.jpg";
import image8 from "../../assets/img/img_new_section_8_uni.jpg";
import image9 from "../../assets/img/img_new_section_9_uni.jpg";
import illustrationwedding from "../../assets/img/illustration_wedding.jpg";
import illustrationseminar from "../../assets/img/illustration_seminar.jpg";
import illustrationsoirée from "../../assets/img/illustration_soirée.jpg";
import illustrationrepasnoel from "../../assets/img/illustration_repasnoel.jpg";
import illustrationbapteme from "../../assets/img/illustration_bapteme.jpg";
import illustrationbirthday from "../../assets/img/illustration_birthday.jpg";
import illustrationconvention from "../../assets/img/illustration_convention.jpg";
import illustrationcousinade from "../../assets/img/illustration_cousinade.jpg";
import illustrationafterwork from "../../assets/img/illustration_afterwork.jpg";
import { section4NewSection5, NewSection5 } from "./packoption/NewSection5"; // Import the data and component

import event_WeddingPrestaTime_1 from '../../assets/img/event_WeddingPrestaTime_1.jpg';
import event_WeddingPrestaTime_2 from '../../assets/img/event_WeddingPrestaTime_2.jpg';
import event_WeddingPrestaTime_3 from '../../assets/img/event_WeddingPrestaTime_3.jpg';
import event_WeddingPrestaTime_4 from '../../assets/img/event_WeddingPrestaTime_4.jpg';
import event_WeddingPrestaTime_5 from '../../assets/img/event_WeddingPrestaTime_5.jpg';
import event_WeddingPrestaTime_6 from '../../assets/img/event_WeddingPrestaTime_6.jpg';

import event_mdm_1 from '../../assets/img/event_mdm_1.jpg';
import event_mdm_2 from '../../assets/img/event_mdm_2.jpg';
import event_mdm_3 from '../../assets/img/event_mdm_3.jpg';
import event_mdm_4 from '../../assets/img/event_mdm_4.jpg';
import event_mdm_5 from '../../assets/img/event_mdm_5.jpg';
import event_mdm_6 from '../../assets/img/event_mdm_6.jpg';
import event_mdm_7 from '../../assets/img/event_mdm_7.jpg';
import event_mdm_8 from '../../assets/img/event_mdm_8.jpg';
import event_mdm_9 from '../../assets/img/event_mdm_9.jpg';
import event_mdm_10 from '../../assets/img/event_mdm_10.jpg';

import BackArroww from '../../assets/img/back_arrow.png';
import BottomArroww from '../../assets/img/bottom_arrow.png';
import TopArroww from '../../assets/img/top_arrow.png';

import event_cn_traiteur_1 from '../../assets/img/event_cn_traiteur_1.jpg';
import event_cn_traiteur_2 from '../../assets/img/event_cn_traiteur_2.jpg';
import event_cn_traiteur_3 from '../../assets/img/event_cn_traiteur_3.jpg';
import event_cn_traiteur_4 from '../../assets/img/event_cn_traiteur_4.jpg';
import event_cn_traiteur_5 from '../../assets/img/event_cn_traiteur_5.jpg';
import event_cn_traiteur_6 from '../../assets/img/event_cn_traiteur_6.jpg';
import event_cn_traiteur_7 from '../../assets/img/event_cn_traiteur_7.jpg';
import event_cn_traiteur_8 from '../../assets/img/event_cn_traiteur_8.jpg';
import event_cn_traiteur_9 from '../../assets/img/event_cn_traiteur_9.jpg';
import event_cn_traiteur_10 from '../../assets/img/event_cn_traiteur_10.jpg';
import event_cn_traiteur_11 from '../../assets/img/event_cn_traiteur_11.jpg';
import event_cn_traiteur_12 from '../../assets/img/event_cn_traiteur_12.jpg';
import event_cn_traiteur_13 from '../../assets/img/event_cn_traiteur_13.jpg';
import event_cn_traiteur_14 from '../../assets/img/event_cn_traiteur_14.jpg';
import event_cn_traiteur_15 from '../../assets/img/event_cn_traiteur_15.jpg';
import event_cn_traiteur_16 from '../../assets/img/event_cn_traiteur_16.jpg';
import event_cn_traiteur_17 from '../../assets/img/event_cn_traiteur_17.jpg';
import event_cn_traiteur_18 from '../../assets/img/event_cn_traiteur_18.jpg';
import event_cn_traiteur_19 from '../../assets/img/event_cn_traiteur_19.jpg';
import event_cn_traiteur_20 from '../../assets/img/event_cn_traiteur_20.jpg';
import event_cn_traiteur_21 from '../../assets/img/event_cn_traiteur_21.jpg';
import event_cn_traiteur_22 from '../../assets/img/event_cn_traiteur_22.jpg';

import event_laurene_steeve_1 from '../../assets/img/event_laurene_steeve_1.png';
import event_laurene_steeve_2 from '../../assets/img/event_laurene_steeve_2.png';
import event_laurene_steeve_3 from '../../assets/img/event_laurene_steeve_3.png';
import event_laurene_steeve_4 from '../../assets/img/event_laurene_steeve_4.png';
import event_laurene_steeve_5 from '../../assets/img/event_laurene_steeve_5.png';
import event_laurene_steeve_6 from '../../assets/img/event_laurene_steeve_6.png';
import event_laurene_steeve_7 from '../../assets/img/event_laurene_steeve_7.png';
import event_laurene_steeve_8 from '../../assets/img/event_laurene_steeve_8.png';
import event_laurene_steeve_9 from '../../assets/img/event_laurene_steeve_9.png';
import event_laurene_steeve_10 from '../../assets/img/event_laurene_steeve_10.png';

import event_rolls_royce_1 from '../../assets/img/event_rolls_royce_1.png';
import event_rolls_royce_2 from '../../assets/img/event_rolls_royce_2.png';
import event_rolls_royce_3 from '../../assets/img/event_rolls_royce_3.png';
import event_rolls_royce_4 from '../../assets/img/event_rolls_royce_4.png';
import event_rolls_royce_5 from '../../assets/img/event_rolls_royce_5.png';
import event_rolls_royce_6 from '../../assets/img/event_rolls_royce_6.png';
import event_rolls_royce_7 from '../../assets/img/event_rolls_royce_7.png';
import event_rolls_royce_8 from '../../assets/img/event_rolls_royce_8.png';
import event_rolls_royce_9 from '../../assets/img/event_rolls_royce_9.png';
import event_rolls_royce_10 from '../../assets/img/event_rolls_royce_10.png';
import event_rolls_royce_11 from '../../assets/img/event_rolls_royce_11.png';
import event_rolls_royce_12 from '../../assets/img/event_rolls_royce_12.png';
import PriceSimulatorModal from "./PriceSimulatorModel";

import CustomCarousel from "./CustomCarousel";
import "../screens/detail/detail.css";
import {
  detailSecondarySliderResponsive,
} from "../../constants";
const NewSection = ({isDetail}) => {
  const [isPriceSimulatorModal, setIsPriceSimulatorModal] = useState(false);
  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    
    return (
      <div className="d-flex justify-content-between clt-home-visite-h2-header clt-home-visite-h2-header9654852 dadzadas">
        <div>
          <h2 className="clt-home-visite-h23546534">{rest.head}</h2>
          <h2 className="clt-home-visite-h2">{rest.title}</h2>
        </div>
        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-section-3-cards-arrowBtn me-2"
                : "clt-section-3-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left zczdzzsce"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? "disable clt-section-3-cards-arrowBtn"
                : "clt-section-3-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right zczdzzsce"></i>
          </Button>
        </div>
      </div>
    );
  };
  const DetailMainSliderArrows11111 = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <div className="d-flex justify-content-between clt-home-visite-h2-header2321354 clt-home-visite-h2-header2321354344343 clt-home-visite-h2-header232135342154344343 mb-0 ">
        <div>
          <h2 className="clt-home-visite-h23546534">{rest.head}</h2>
          <h2 className="clt-home-visite-h2">{rest.title}</h2>
        </div>

        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-section-3-cards-arrowBtn me-2"
                : "clt-section-3-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
              ? "disable clt-section-3-cards-arrowBtn"
              : "clt-section-3-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
    );
  };
  const carouselData = [
    { illustrationwedding: illustrationwedding, text1: "Mariage", link1: "https://chateaulatournelle.com/detail/Mariage" ,illustrationseminar: illustrationseminar, text2: "Séminaire", link2: "https://chateaulatournelle.com/detail/Séminaire" },
    { illustrationwedding:  illustrationbirthday, text1: "Anniversaire", link1: "https://chateaulatournelle.com/detail/Anniversaire" ,illustrationseminar: illustrationconvention, text2: "Convention", link2: "https://chateaulatournelle.com/detail/Convention"       },
    { illustrationwedding: illustrationsoirée, text1: "Soirée", link1: "https://chateaulatournelle.com/detail/Soirée", illustrationseminar: illustrationafterwork, text2: "Afterwork", link2: "https://chateaulatournelle.com/detail/Afterwork" },
    { illustrationwedding: illustrationcousinade, text1: "Cousinade", link1: "https://chateaulatournelle.com/detail/Cousinade" ,illustrationseminar: illustrationbapteme, text2: "Fête religieuse", link2: "https://chateaulatournelle.com/detail/Fête%20religieuse"  },
    { illustrationwedding: illustrationrepasnoel, text1: "Repas noël  ", link1: "https://chateaulatournelle.com/detail/Repas%20noël" },
  ];
  const [faqs, setFaqs] = useState([
    {
      question: 'Détail des événements',
      answer: (
        <>
          <p>
            Découvrez une présentation complète de chaque type d'événement sur notre page dédiée. Vous y trouverez toutes les informations nécessaires, les descriptions détaillées. {' '}
            <a
              className="text_link_product_page_question fnjsnksjn"
              href="https://chateaulatournelle.com/detail/Mariage"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              Détail des événements
            </a>
          </p>
        </>
      ),
      open: false,
    },
    {
      question: "Prix d'une réservation",
      answer: (
        <>
          <p>
            Estimer le prix d'une réservation grâce à notre simulateur de prix.{' '}
            <a 
              onClick={(e) => { 
                e.stopPropagation(); 
                setIsPriceSimulatorModal(true); 
              }} 
              className="fnjsnksjn"
              target="_blank"
            >
              Simulé le prix
            </a>
          </p>
        </>
      ),
      open: false,
    },
    {
      question: 'Centre aide',
      answer: (
        <>
         <p>
  Le château est disponible pour répondre à vos questions. {' '}
  <a
    className="text_link_product_page_question fnjsnksjn"
    href="https://chateaulatournelle.com/aide"
    onClick={(e) => {
      const element = document.getElementById('cecczcezjce');
      if (element) {
        element.click();
      } else {
        console.warn('Element with ID cecczcezjce not found.');
      }
    }}
  >
    Visitez le centre d'aide
  </a>
</p>


        </>
      ),
      open: false,
    },
  ]);
  
  const toggleAnswer = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, open: !faq.open } : faq
      )
    );
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleProductSlider = () => {
    setIsProductsSidebarOpen(!isProductsSidebarOpen);
    setIsProductsSidebarList(true);
    setIsProductsSiderbarDetail(false);
  };

  const [isEventSidebarOpen, setIsEventSidebarOpen] = useState(false);
  const [isEventSidebarList, setIsEventSidebarList] = useState(true);
  const [isEventSidebarDetail, setIsEventSidebarDetail] = useState(false);
  const [isProductsSidebarOpen, setIsProductsSidebarOpen] = useState(false);
  const [isProductsSidebarList, setIsProductsSidebarList] = useState(false);

  const [secondaryGallery, setSecondaryGallery] = useState([
    
    {
      title: "C&N Traiteur",
      subtitle: "Soirée · 22 photos",
      width: 1950,
      height: 1300,
      src: event_cn_traiteur_1,
    },
    {
      title: "Rolls-Royce",
      subtitle: "Soirée · 12 photos",
      width: 1950,
      height: 1300,
      src: event_rolls_royce_1,
    },
    {
      title: "Laurène & Steeve",
      subtitle: "Mariage · 10 photos",
      width: 1950,
      height: 1300,
      src: event_laurene_steeve_1,
    },
    {
      title: "Maison du Mariage",
      subtitle: "Noël · 6 photos",
      width: 1950,
      height: 1300,
      src: event_mdm_1,
    },
    {
      title: "Wedding Presta Time",
      subtitle: "Afterwork · 10 photos",
      width: 1950,
      height: 1300,
      src: event_WeddingPrestaTime_5,
    },
    
    
  ]);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const openGallery = async (type, index, myGallery) => {
    const lightboxGallery = await Promise.all(
      myGallery.map(async (item) => {
        const img = new Image();
        img.src = item?.ImageUrl;
        await img.decode();
        return {
          src: img.src,
          name: item.title,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };

  const handleSidebarOpen = (event) => {
    setIsEventSidebarOpen(true);
    // Assuming this condition checks are correct
    if (event.title === "C&N Traiteur") {
      setSelectedEvent({
        title: "C&N Traiteur",
        subtitle: "Soirée · 22 photos",
        description: "Plongez dans une expérience envoûtante au pays des merveilles avec C&N Traiteur lors du lancement de leur toute nouvelle gamme printemps/été 2024. Une soirée enchanteresse orchestrée par Nathalie FAIDHERBE et son équipe, alliant découverte et délice. Applaudissements à BPM AGENCY & KRD Audiovisuel pour une mise en scène spectaculaire au Château Latournelle à Fenouillet !",
        images: [event_cn_traiteur_1, event_cn_traiteur_2, event_cn_traiteur_3, event_cn_traiteur_4, event_cn_traiteur_5, event_cn_traiteur_6, event_cn_traiteur_7, event_cn_traiteur_8, event_cn_traiteur_9, event_cn_traiteur_10, event_cn_traiteur_11, event_cn_traiteur_12, event_cn_traiteur_13, event_cn_traiteur_14, event_cn_traiteur_15, event_cn_traiteur_16, event_cn_traiteur_17, event_cn_traiteur_18, event_cn_traiteur_19, event_cn_traiteur_20, event_cn_traiteur_21, event_cn_traiteur_22],
      });
    } else if (event.title === "Laurène & Steeve") {
      setSelectedEvent({
        title: "Laurène & Steeve",
        subtitle: "Mariage · 10 photos",
        images: [event_laurene_steeve_1, event_laurene_steeve_2, event_laurene_steeve_3, event_laurene_steeve_4, event_laurene_steeve_5, event_laurene_steeve_6, event_laurene_steeve_7, event_laurene_steeve_8, event_laurene_steeve_10],
      });
    } else if (event.title === "Rolls-Royce") {
      setSelectedEvent({
        title: "Rolls-Royce",
        subtitle: "Soirée · 12 photos",
        description: "Pour célébrer leurs 30 ans, l'équipe de support technique de Rolls-Royce a choisi le somptueux Château Latournelle. Une soirée mémorable au cœur de l'excellence, marquée par la reconnaissance des succès passés et l'anticipation d'un avenir prometteur.",
        images: [event_rolls_royce_1, event_rolls_royce_2, event_rolls_royce_3, event_rolls_royce_4, event_rolls_royce_5, event_rolls_royce_6, event_rolls_royce_7, event_rolls_royce_8, event_rolls_royce_9, event_rolls_royce_10, event_rolls_royce_11, event_rolls_royce_12],
      });
    } else if (event.title === "Maison du Mariage") {
      setSelectedEvent({
        title: "Maison du Mariage",
        subtitle: "Noël · 6 photos",
        description: "Le Château a ouvert ses portes avec joie pour accueillir l'équipe du Groupe TANGRAM, de La Maison du Mariage et de SO Connect pour une Christmas Party empreinte de magie. Dans l'écrin de nos murs chargés d'histoire, l'atmosphère s'est parée de convivialité et de partage. À tous ceux qui ont contribué à la réussite de cette soirée, notre plus profonde gratitude.",
        images: [event_mdm_1, event_mdm_2, event_mdm_3, event_mdm_4, event_mdm_5, event_mdm_6, event_mdm_7, event_mdm_8, event_mdm_9, event_mdm_10],
      });
    } else if (event.title === "Wedding Presta Time") {
      setSelectedEvent({
        title: "Wedding Presta Time",
        subtitle: "Afterwork · 10 photos",
        description: "Le château a ouvert ses portes avec enchantement pour recevoir l'équipe de Wedding Presta Time lors d'un after work sensationnel. Leur confiance nous touche profondément, et nous sommes impatients de revivre cette expérience avec tout autant d'enthousiasme !",
        images: [event_WeddingPrestaTime_5, event_WeddingPrestaTime_1, event_WeddingPrestaTime_2, event_WeddingPrestaTime_3, event_WeddingPrestaTime_4, event_WeddingPrestaTime_6],
      });
    } else {
      setSelectedEvent(event);
    }
  };
  

  
  

  
  const [isProductsSiderbarDetail, setIsProductsSiderbarDetail] = useState(false);
  const [productDetail, setProductDetail] = useState({ name: '' });

  return (
    <>
      <Container className="dazdqsdzadazd showMobile" style={{ flexDirection : "column"}}>
        <Container fluid className="showMobile" style={{ padding : "0px"}}>
          <Row className="clt-section-3-rowczcszc">
            <Col>
              <Container className="clt-section-3-row-container">
                <Row>
                  <Col className="clt-section-3-row-col">
                    <h2 className="clt-home-visite-h2knkjbjkbh">Organisez un événement</h2>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>



        <Container className="clt-homepage-container">
                <Row>
                  <Col className="clt-section-row-col-pddkslnksdkj dnedzkndze">
                    <h2 className="clt-home-visite-h2354653422">Événement</h2>
                    <h2 className="clt-home-visite-h2">Liste d'événement</h2>
                  </Col>
           </Row>
        </Container>

        <Container fluid className="showMobile" style={{ padding : "0px"}}>
          <Row className="clt-section-3-row">
            <Col>

              
              <Container className="clt-section-3-row-container">
                <Row>
                  <Col className="clt-section-3-row-col">
                    <div className="d-flex justify-content-start align-items-center clt-section-3-event cdcdscwxcxwcd">
                      <div>
                        <div className="d-flex flex-column justify-content-start align-items-start clt-section-Btn">
                          <div className="clt-section-3-cardsImages">
                            <Box
                              component="a"
                              href="https://chateaulatournelle.com/detail/Mariage"
                              className="clt_homepage_section_event_box"
                            >
                              <div>
                                <img
                                  src={illustrationwedding}
                                  className="clt_homepage_section_event_image"
                                />
                              </div>
                              <div className="clt_homepage_section_event_text">
                                <MuiButton
                                  variant="text"
                                  disableRipple
                                  className="clt_homepage_section_event_text"
                                >
                                  Mariage
                                </MuiButton>
                              </div>
                            </Box>

                            <Box
                              component="a"
                              href="https://chateaulatournelle.com/detail/Séminaire"
                              className="clt_homepage_section_event_box"
                            >
                              <div>
                                <img
                                  src={illustrationseminar}
                                  className="clt_homepage_section_event_image"
                                />
                              </div>
                              <div className="clt_homepage_section_event_text">
                                <MuiButton
                                  variant="text"
                                  disableRipple
                                  className="clt_homepage_section_event_text"
                                >
                                  Séminaire
                                </MuiButton>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex flex-column justify-content-start align-items-start clt-section-Btn">
                          <div className="clt-section-3-cardsImages">

                          


                            <Box
                              component="a"
                              href="https://chateaulatournelle.com/detail/Anniversaire"
                              className="clt_homepage_section_event_box"
                            >
                              <div>
                                <img
                                  src={illustrationbirthday}
                                  className="clt_homepage_section_event_image"
                                />
                              </div>
                              <div className="clt_homepage_section_event_text">
                                <MuiButton
                                  variant="text"
                                  disableRipple
                                  className="clt_homepage_section_event_text"
                                >
                                  Anniversaire
                                </MuiButton>
                              </div>
                            </Box>
                            

                        

                            <Box
                              component="a"
                              href="https://chateaulatournelle.com/detail/Convention"
                              className="clt_homepage_section_event_box"
                            >
                              <div>
                                <img
                                  src={illustrationconvention}
                                  className="clt_homepage_section_event_image"
                                />
                              </div>
                              <div className="clt_homepage_section_event_text">
                                <MuiButton
                                  variant="text"
                                  disableRipple
                                  className="clt_homepage_section_event_text"
                                >
                                  Convention
                                </MuiButton>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex flex-column justify-content-start align-items-start clt-section-Btn">
                          <div className="clt-section-3-cardsImages">
                        


                            


                            <Box
                              component="a"
                              href="https://chateaulatournelle.com/detail/Soirée"
                              className="clt_homepage_section_event_box"
                            >
                              <div>
                                <img
                                  src={illustrationsoirée}
                                  className="clt_homepage_section_event_image"
                                />
                              </div>
                              <div className="clt_homepage_section_event_text">
                                <MuiButton
                                  variant="text"
                                  disableRipple
                                  className="clt_homepage_section_event_text"
                                >
                                  Soirée 
                                </MuiButton>
                              </div>
                            </Box>
                            

                            <Box
                                component="a"
                                href="https://chateaulatournelle.com/detail/Afterwork"
                                className="clt_homepage_section_event_box"
                              >
                                <div>
                                  <img
                                    src={illustrationafterwork}
                                    className="clt_homepage_section_event_image"
                                  />
                                </div>
                                <div className="clt_homepage_section_event_text">
                                  <MuiButton
                                    variant="text"
                                    disableRipple
                                    className="clt_homepage_section_event_text"
                                  >
                                    Afterwork
                                  </MuiButton>
                                </div>
                              </Box>
                          </div>
                        </div>
                      </div>
                        <div>
                          <div className="d-flex flex-column justify-content-start align-items-start clt-section-Btn">
                            <div className="clt-section-3-cardsImages">
                              <Box
                                component="a"
                                href="https://chateaulatournelle.com/detail/Fête%20religieuse"
                                className="clt_homepage_section_event_box"
                              >
                                <div>
                                  <img
                                    src={illustrationbapteme}
                                    className="clt_homepage_section_event_image"
                                  />
                                </div>
                                <div className="clt_homepage_section_event_text">
                                  <MuiButton
                                    variant="text"
                                    disableRipple
                                    className="clt_homepage_section_event_text"
                                  >
                                    Fête religieuse
                                  </MuiButton>
                                </div>
                              </Box>

                              

                            <Box
                                component="a"
                                href="https://chateaulatournelle.com/detail/Cousinade"
                                className="clt_homepage_section_event_box"
                              >
                                <div>
                                  <img
                                    src={illustrationcousinade}
                                    className="clt_homepage_section_event_image"
                                  />
                                </div>
                                <div className="clt_homepage_section_event_text">
                                  <MuiButton
                                    variant="text"
                                    disableRipple
                                    className="clt_homepage_section_event_text"
                                  >
                                    Cousinade
                                  </MuiButton>
                                </div>
                              </Box>
                              



                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex flex-column justify-content-start align-items-start clt-section-Btn">
                            <div className="clt-section-3-cardsImages">
                            <Box
                              component="a"
                              href="https://chateaulatournelle.com/detail/Repas%20noël"
                              className="clt_homepage_section_event_box"
                            >
                              <div>
                                <img
                                  src={illustrationrepasnoel}
                                  className="clt_homepage_section_event_image"
                                />
                              </div>
                              <div className="clt_homepage_section_event_text">
                                <MuiButton
                                  variant="text"
                                  disableRipple
                                  className="clt_homepage_section_event_text"
                                >
                                  Repas noël
                                </MuiButton>
                              </div>
                            </Box>
                            </div>
                          </div>
                        </div>



                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="clt-homepage-container">
  <Row>
    <Col className="clt-section-row-col-pddksln436545ksdkj dnedzkndze">
      <div className="d-flex flex-column" style={{ width: '100%' }}>
        <h2 className="clt-home-visite-h2354653422">Information</h2>
        <h2 className="clt-home-visite-h2">Pour un événement</h2>
        <div className="faq-section">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item" onClick={() => toggleAnswer(index)}>
              <div className="faq-question-wrapper">
                <div className="faq-text">
                  <p className="faq-question_subtitle">Guide</p>
                  <p className="faq-question">{faq.question}</p>
                </div>
                <img
                  src={faq.open ? TopArroww : BottomArroww}
                  className="faq-arrow"
                  alt="Arrow"
                />
              </div>
              {faq.open && <div className="faq-answer">{faq.answer}</div>}
            </div>
          ))}
        </div>
      </div>
    </Col>
  </Row>
</Container>



      <Container className=" clt-homepage-container  inideznidnez">
          <Row>
            <Col className="clt-section-row-col-pd dezkjdezkjdnz dnedzkndfecze">
              <h2 className="clt-home-visite-h2354653422">Recemment</h2>
              <h2 className="clt-home-visite-h2">Retrouvez les derniers événements</h2>
            </Col>
          </Row>
      </Container>

      <Container className={isDetail ? 'clt-detail-page-container clt-homepage-container showMobile' : 'clt-homepage-container  showMobile'} style={{ flexDirection: 'row' }}>
        <Row className="clt-detail-left-section-2-row showMobile">
          <Col>
            <Container className="clt-detail-left-section-2-row-container">
              
              <Row>
                
                <Col className={isDetail ? 'clt-detail-left-section-2-row-col' : ' jnelkdjnzljdnze clt-detail-left-section-2-row-col'}>
                  <div className={isDetail ? 'd-flex justify-content-start align-items-center clt-detail-left-section-2 clt-detail-left-section-21745' : 'd-flex justify-content-start align-items-center clt-detail-left-section-2'}>
                    {secondaryGallery.map((value, index) => (
                      <Col key={index}>
                        <div
                          onClick={() => handleSidebarOpen(value)}
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                        >
                          <div
                            className="d-flex flex-column justify-content-start align-items-center clt-detail-left-section-2-cards clt-detail-left-section-2-cards-main clt-detail-left-section-3-cards clt-detail-left-section-3-cards-main"
                            style={{
                              backgroundImage: `url(${value.src})`,
                            }}
                          >
                            {/* Image content */}
                          </div>
                          {/* Span content */}
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span
                              className="clt-detail-left-section-4-card-item-h2"
                              style={{ cursor: 'pointer' }}
                            >
                              {value.title}
                            </span>
                            <span
                              className="clt-detail-left-section-4-card-item-h2-sub"
                              style={{ cursor: 'pointer' }}
                            >
                              {value.subtitle}
                            </span>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
        </Col>
      </Row>
    </Container>
      

        

      </Container>

      {/* <Container className="clt-homepage-container hideMobile">
        <Row>
          <Col>
            <div className="clt-section-3-event">
              <div className="d-flex">
                <div className="clt-section-Btn">
                  <div className="clt-section-3-cardsImages">
                    <Box
                      component="a"
                      href="https://chateaulatournelle.com/detail/Mariage"
                      className="clt_homepage_section_event_box"
                    >
                      <div>
                        <img
                          src={illustrationwedding}
                          className="clt_homepage_section_event_image"
                        />
                      </div>
                      <div className="clt_homepage_section_event_text">
                        <MuiButton
                          variant="text"
                          disableRipple
                          className="clt_homepage_section_event_text"
                        >
                          Mariage
                        </MuiButton>
                      </div>
                    </Box>

                    <Box
                      component="a"
                      href="https://chateaulatournelle.com/detail/Professionnel"
                      className="clt_homepage_section_event_box"
                    >
                      <div>
                        <img
                          src={illustrationseminar}
                          className="clt_homepage_section_event_image"
                        />
                      </div>
                      <div className="clt_homepage_section_event_text">
                        <MuiButton
                          variant="text"
                          disableRipple
                          className="clt_homepage_section_event_text"
                        >
                          Professionnel
                        </MuiButton>
                      </div>
                    </Box>
                  </div>
                </div>
                <div className="clt-section-Btn">
                  <div className="clt-section-3-cardsImages">
                    <Box
                      component="a"
                      href="https://chateaulatournelle.com/detail/Anniversaire"
                      className="clt_homepage_section_event_box"
                    >
                      <div>
                        <img
                          src={illustrationsoirée}
                          className="clt_homepage_section_event_image"
                        />
                      </div>
                      <div className="clt_homepage_section_event_text">
                        <MuiButton
                          variant="text"
                          disableRipple
                          className="clt_homepage_section_event_text"
                        >
                          Anniversaire
                        </MuiButton>
                      </div>
                    </Box>
                    <Box
                      component="a"
                      href="https://chateaulatournelle.com/detail/Repas%20noël"
                      className="clt_homepage_section_event_box"
                    >
                      <div>
                        <img
                          src={illustrationbapteme}
                          className="clt_homepage_section_event_image"
                        />
                      </div>
                      <div className="clt_homepage_section_event_text">
                        <MuiButton
                          variant="text"
                          disableRipple
                          className="clt_homepage_section_event_text"
                        >
                          Repas noël
                        </MuiButton>
                      </div>
                    </Box>
                  </div>
                </div>
                <div className="clt-section-Btn">
                  <div className="clt-section-3-cardsImages">
                    <Box
                      component="a"
                      href="https://chateaulatournelle.com/detail/Religieux"
                      className="clt_homepage_section_event_box"
                    >
                      <div>
                        <img
                          src={illustrationrepasnoel}
                          className="clt_homepage_section_event_image"
                        />
                      </div>
                      <div className="clt_homepage_section_event_text">
                        <MuiButton
                          variant="text"
                          disableRipple
                          className="clt_homepage_section_event_text"
                        >
                          Religieux
                        </MuiButton>
                      </div>
                    </Box>

                    <Box
                      component="a"
                      href="https://chateaulatournelle.com/detail/Soirée"
                      className="clt_homepage_section_event_box"
                    >
                      <div>
                        <img
                          src={illustrationbirthday}
                          className="clt_homepage_section_event_image"
                        />
                      </div>
                      <div className="clt_homepage_section_event_text">
                        <MuiButton
                          variant="text"
                          disableRipple
                          className="clt_homepage_section_event_text"
                        >
                          Soirée
                        </MuiButton>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container className="dazdqsdzadazd hideMobile">
      <Container className="clt-homepage-container hideMobile">
        <Row>
          <Col>
            <Container className="clt-homepage-containerfezfdsfzeez hideMobile">
              <Row >
                <Col className="clt-section-row-col-pd zadazdqdzdzdqad">
                  <h2 className="clt-home-visite-h2354653423558">Organisez un événement</h2>
                </Col>
              </Row>
            </Container>
        <div className="djazdzas">
          <Container style={{ width : "40%"}} className="clt-homepage-container hideMobile">
            <Row >
              <Col className="clt-section-row-col-pd zadazdqdqad">
                <h2 className="clt-home-visite-h23546534">Événement</h2>
                <h2 className="clt-home-visite-h2">Liste d'évenement</h2>
              </Col>
            </Row>
          </Container>
          <div style={{ width : "60%"}} className=" clt-section-3-event d-flex flex-column">
            <CustomCarousel
                responsive={detailSecondarySliderResponsive}
                arrows={false}
                shouldResetAutoplay={false}
                renderButtonGroupOutside={true}
                customButtonGroup={
                  <DetailMainSliderArrows11111/>
                }
                className={`my-carousel3`}
            >
              {carouselData.map((item, index) => (
                 <div className="d-flex">
                 <div className="clt-section-Btn">
                   <div className="clt-section-3-cardsImages">
                <Box
                  key={index}
                  component="a"
                  href={item.link1}
                  className="clt_homepage_section_event_box"
                >
                  <div>
                    <img
                      src={item.illustrationwedding}
                      className="clt_homepage_section_event_image"
                    />
                  </div>
                  <div className="clt_homepage_section_event_text">
                    <MuiButton
                      variant="text"
                      disableRipple
                      className="clt_homepage_section_event_text"
                    >
                      {item.text1}
                    </MuiButton>
                  </div>
                </Box>
                <Box
                  key={index}
                  component="a"
                  href={item.link2}
                  className="clt_homepage_section_event_box"
                >
                  <div>
                    <img
                      src={item.illustrationseminar}
                      className="clt_homepage_section_event_image"
                    />
                  </div>
                  <div className="clt_homepage_section_event_text">
                    <MuiButton
                      variant="text"
                      disableRipple
                      className="clt_homepage_section_event_text"
                    >
                      {item.text2}
                    </MuiButton>
                  </div>
                </Box>
                </div>
                </div>
                </div>
              ))}
            </CustomCarousel>
            </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="clt-homepage-container hideMobile">
  <Row>
    <Col>
      <div className="aclt-section-3">
        <div className="clt-section-3-h2-div fixed-height" style={{ width: "40%" }}>
          <h2 className="clt-home-visite-h23546534">Information</h2>
          <h2 className="clt-section-3-h2">Pour un événement</h2>

          {/*
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h2 className="clt-section-3-h2-second">tEST</h2>
          </div> */}
        </div>
        <div className="dzajdnlziajd fsvdssdx" style={{ width: "60%" }}>
          <div className="d-flex flex-column-reverse">
            <div className="faq-section">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item" onClick={() => toggleAnswer(index)}>
                  <div className="faq-question-wrapper">
                    <div>
                      <p className="faq-question_subtitle">Guide</p>
                      <p className="faq-question">{faq.question}</p>
                    </div>
                    <img src={faq.open ? TopArroww : BottomArroww} className="faq-arrow" alt="Arrow" />
                  </div>
                  {faq.open && <div className="faq-answer">{faq.answer}</div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Col>
  </Row>
</Container>

      <div class="clt-homepage-container hideMobile container djazdzas" > 
      <Container style={{ width: "40%" }} className=" hideMobile">
        <Row>
          <Col className="clt-section-row-col-pd dnedzsqfsqfqskndze">
            <h2 className="clt-home-visite-h2354653422">Recemment</h2>
            <h2 className="clt-home-visite-h2">Retrouvez les derniers événements</h2>
          </Col>
        </Row>
    </Container>
      
    <Container style={{ width: "60%" }} className={isDetail ? 'clt-detail-page-container clt-homepage-container hideMobile' : 'clt-homepage-container hideMobile'}>
      <Row className="hideMobile" style={{ flexDirection: 'row' }}>
        <Col className="clt-detail-left-section-2-col">
          <div className="d-flex flex-column">
            <CustomCarousel
              responsive={detailSecondarySliderResponsive}
              arrows={false}
              shouldResetAutoplay={false}
              renderButtonGroupOutside={true}
              customButtonGroup={
                <DetailMainSliderArrows />
              }
              className={`clt-detail-left-section-2-cards-div my-carousel4`}
            >
                {secondaryGallery.map((item, index) => (
                  <div
                    key={index}
                    className="clt-detail-left-section-2-card-container"
                    onClick={() => handleSidebarOpen(item)}
                  >
                    <div
                      className="d-flex flex-column justify-content-start align-items-center clt-home-visite-h2-cards clt-home-visite-h3-cards clt-detail-left-section-2-cards-main clt-detail-left-section-3-cards clt-detail-left-section-3-cards-main cursor-zoom-in"
                      style={{
                        backgroundImage: `url(${item.src})`,
                      }}
                    >
                      {/* Image content */}
                    </div>
                    {/* Span content*/}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span
                        className="clt-detail-left-section-4-card-item-h2"
                        style={{ cursor: 'pointer' }}
                      >
                        {item.title}
                      </span>
                      <span
                        className="clt-detail-left-section-4-card-item-h2-sub"
                        style={{ cursor: 'pointer' }}
                      >
                        {item.subtitle}
                      </span>
                    </div>
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      </Container>
      <CustomOffCanvas
        placement="end"
        className="clt_products_offcanvas2"
        show={isEventSidebarOpen}
        onHide={() => setIsEventSidebarOpen(false)}
        headerClassName="justify-content-start clt_products_offcanvas_header"
        bodyClassName="clt_products_offcanvas_body clt_products_offcanvas_body-p-0"
        headerTitle={selectedEvent ? selectedEvent.title : "Option"}
        isBackBtn={true}
        handleBack={() => setIsEventSidebarOpen(false)}
      >
        {selectedEvent && (
          <Container className="clt_product_detail_offcanvas_body clt_product_detail_offcanvas_body_eventrecent">
            <Row>
              <Col className="header_text_div">
                <h2>{selectedEvent.title}</h2>
                <p>{selectedEvent.description}</p>
              </Col>
            </Row>
           <Row>
            <Col className="p-0">
              <PhotoProvider>
                {selectedEvent.images.map((galleryPic, gIndex) => (
                  <div key={gIndex} className="clt-room-slider-img-div2 cursor-zoom-in">
                    <PhotoView src={galleryPic}>
                      <img
                        src={galleryPic}
                        alt={`Gallery ${gIndex + 1}`}
                        className="img-fluid"
                        onClick={() => openGallery("main", gIndex, selectedEvent.images)}
                      />
                    </PhotoView>
                  </div>
                ))}
              </PhotoProvider>
            </Col>
          </Row>

            
            {/* Additional content for the selected event */}
          </Container>
        )}
      </CustomOffCanvas>

      <PriceSimulatorModal 
        isShow={isPriceSimulatorModal}
        toggleModal={() => setIsPriceSimulatorModal(false)}
      />
    </>
  );
};

export default NewSection;
function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}