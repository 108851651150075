import React, { useState, useEffect } from "react";
import {
    // Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] Admin - Edit Request - Sent.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../../../assets/img/admin_icon_booking_detail_3.png";
import ConfirmPay5 from "../../../../../../assets/img/confirm_pay_5.png";
import CustomHr from "../../../../../reusable/CustomHr";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionUser from "../../../../../reusable/booking/SectionUser";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetailPendingEditRequest.js";
// import { Link } from "react-router-dom";
import Guest from "../../../../guest/Guest";
import Account from "../../../../account/Account";
import Booking from "../../../../detail/booking/booking_user";
// import SectionContact from "../../../reusable/booking/SectionContact";
import CancelBooking from "../../../../adminDashboard/AdminDashboardBooking/admin_dash_booking_component/component_cancel_booking/[SIDEBAR] Admin - Booking - Cancellation";
import DamageDeposit from "../../../../adminDashboard/AdminDashboardBooking/admin_dash_booking_component/component_damage_deposit/component_damage_deposit";
import { useAuth } from "../../../../auth/authContext";
import InvoiceOffcanvas from "../../../../../reusable/invoice/SidebarInvoiceList";
import { SuccessToast } from "../../../../../reusable/Toast";
import SectionInformationMessageRequestSent from "../../../../../reusable/SectionInformationMessageRequestSent.js";
import SidebarBookingAdmin from "../../../../adminDashboard/AdminDashboardBooking/admin_dash_booking_detail/sidebar/AdminDashboardBookingDetail";

const ChatSidebarEditRequestSentAdmin = ({ hideSidebar }) => {

    /* Const - Booking */
    const [eventType, setEventType] = useState(eventweddingData);

    /* Const - Booking */
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);

    /* Const - Section - Date */
    const handleDateEdit = () => {
        setIsBookingSidebar(true);
    };
    let bookingProps = {
        isFromBookingDate: {
            startDate: new Date(),
            endDate: new Date(),
        },
        isFromBookingTimeSlot: {
            id: 1,
            title: "Title",
            subTitle: "subTitle",
            time: "time",
        },
    };

    /* Const - Section User */
    const [isAccountInfo, setIsAccountInfo] = useState(false);
    const { isLoggedIn, user } = useAuth();
    const [selectedProfile, setSelectedProfile] = useState({
        type: "Personal",
        detail: "John Doe . 055555555 . johndoe@gmail.com"
    });
    useEffect(() => {
        if (user)
            setSelectedProfile({ type: "Personal", detail: `${user?.firstName} ${user?.lastName} · ${user?.phone} · ${user?.email}` })
    }, [user])  

    /* Const - Section - Price detail*/
    const [priceDetailsSlots, setpriceDetailsSlots] = useState([
        { title: "Date change", tooltipMsg: null, price: "10€" },
    ]);

    /* Const - Accept request */
    const handleAccept = () => {
        SuccessToast("Edited");
        hideSidebar();
    };

    /* Const - Make a new request  */
    const handleMakeNewRequest = () => {
        SuccessToast("There is the user booking, you can edit what you need");
        setIsOpenSidebarBookingAdmin(true);
    };

    /* Const - Sidebar - Booking */
    const [isOpenSidebarBookingAdmin, setIsOpenSidebarBookingAdmin] = useState(false);


    return (
        <>
            <div
                className="clt_message_user_chat_offcanvas"
            >
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        [CHAT-SIDEBAR] Admin - Edit Request - Sent
                    </div>

                </div>
                <div className="clt_message_user_chat_offcanvas_body" >
                    <>
                        <SectionUser
                            handleClick={() => setIsAccountInfo(true)}
                            text={`${selectedProfile.type} profile`}
                            title="User"
                            subText={
                                <span>{selectedProfile.detail}</span>
                            }
                        />
                    </>
                </div>
                <CustomHr
                    rowClass="p-0 m-0"
                    colClass="clt-booking-hr-pd p-0 w-100"
                    hrClass="p-0 m-0 clt-hr-2"
                />
                <div className="clt_message_user_request_offcanvas_body" >   
                    <>
                        <h2 className="clt-booking-cp-div1-title">Edit request - Sent</h2>
                        <SectionInformationMessageRequestSent
                            innerTitle="Sent"
                            innerSubTitle="The request is sent, the user can accept or refuse it"
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        
                        <h2 className="clt-booking-cp-div1-title">Detail</h2>
                        <SectionDate
                            image={ConfirmPay2}
                            subText1="Subtext 1"
                            subText2="Subtext 2"
                            isEditRequest={true}
                            oldSubText1="Subtext 1"
                            oldSubText2="Subtext 2"
                            handleDateEdit={handleMakeNewRequest}
                        />
                        
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        
                        <SectionPriceDetail
                            title="Price"
                            priceListData={priceDetailsSlots}
                            totalPrice="10€"
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <Row>
                            <Col>
                                <div className="clt-booking-btn-refuse-div mb-5">
                                    <Button
                                        className="shadow-none clt-booking-btn-refuse"
                                        onClick={handleAccept}
                                    >
                                        <span>Cancel request sent</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>


                </div>
            </div>

            

            {/* Function - Booking : Sidebar date and Accept request*/}
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={(data) => {
                    if (data === "Notification") {
                        SuccessToast("Edited");
                        hideSidebar();
                    }
                    setIsBookingSidebar(false);
                }}
                eventType={eventType}
                setEventType={(event) => setEventType(event)}
                isFromEditRequest={true}
                {...bookingProps}
            />

            {/* Function - Sidebar - Booking - Admin */}
            <SidebarBookingAdmin
                isOpenSidebarBookingAdmin={isOpenSidebarBookingAdmin}
                onCloseOpenSidebarBookingAdmin={() => setIsOpenSidebarBookingAdmin(false)}
            />


            {/* Function -  Section User */}
            <Account
                isOpenSidebar={isAccountInfo}
                setIsOpen={() => {
                    setIsAccountInfo(true);
                }}
                onCloseSidebar={() => {
                    setIsAccountInfo(false);
                }}
                selectProfile="admin"
                selectedProfileData={selectedProfile}
                setSelectedProfileData={setSelectedProfile}
            />

        </>
    );
};

export default ChatSidebarEditRequestSentAdmin;
