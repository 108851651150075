import weddingImg from "../assets/img/icon-card-1.png";
import birthdayImg from "../assets/img/icon-card-2.png";
import profesionnelImg from "../assets/img/icon-card-3.png";
import religiousImg from "../assets/img/icon-card-4.png";
import weddingIconActive from "../assets/img/icon-bar-1-active.png";
import birthdayIconActive from "../assets/img/icon-bar-2-active.png";
import profesionnelIconActive from "../assets/img/icon-bar-3-active.png";
import religiousIconActive from "../assets/img/icon-bar-4-active.png";
import newEventIconActive from "../assets/img/admin_icon_manage_event_1.png";
import { v4 as uuid } from "uuid";
console.log({ mode: process.env.REACT_APP_EP });
export const API_ENDPOINT =
  process.env.REACT_APP_EP === "production"
    ? "https://api.chateaulatournelle.com/api/v1"
    // ?  "https:///www.api.chateaulatournelle.com/api/v1/"
    : "http://localhost:5000/api/v1";
// : "http://chateaulatournelle.com/api/v1/";
export const STRIPE_PROMISE =
  process.env.REACT_APP_EP === "production"
    ? "pk_live_51NHB2lIsqurXT5hXLItJPy5YOcT1dpvYXOu08uqMgHPpxuYpM9aB1MuikDFgh1thhmo75aeQxTrYc1QHhCXJGN1K00WJEmDpOX"
    : "pk_test_51NHB2lIsqurXT5hX48TVgKU96SvZUNmuQ0DFYRzK8k6mYGSqlproIpAYglph0lKsDSgB59RDyfhDXplhcLCarky800JOYm9vW2";
// : "http://chateaulatournelle.com/api/v1/";

export const SOCK_ENDPOINT =
  process.env.REACT_APP_EP === "production"
    ? "https://api.chateaulatournelle.com"
    // ?  "https:///www.api.chateaulatournelle.com/api/v1/"
    : "http://localhost:5000";
// : "http://chateaulatournelle.com/api/v1/";

export const IMG_BASEURL =
  process.env.REACT_APP_EP === "production"
    ? "https://chateaulatournelle.com"
    // ?  "https:///www.api.chateaulatournelle.com"
    : "http://localhost:5000";
// process.env.REACT_APP_EP === "development"
//   ? "https://www.api.chateaulatournelle.com/api/v1/"
//   : "http://localhost:5000/api/v1/";

export const IMG_ALT = "Chateau La Tournelle";
export const REPONSIVE_MODES = {
  landscape: "landscape-primary",
  portrait: "portrait-primary",
};
export const BookingSliderLandscapeResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 2.5,
  },
  tablet: {
    breakpoint: { max: 1200, min: 997 },
    items: 1.5,
  },
  mobile: {
    breakpoint: { max: 996, min: 0 },
    items: 1.5,
  },
};

export const BookingSliderResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 465 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.1,
  },
};
export const homeSliderResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 465 },
    items: 2.4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.1,
  },
};
export const homeSliderLandscapeResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3.8,
  },
  tablet: {
    breakpoint: { max: 1200, min: 997 },
    items: 3.2,
  },
  mobile: {
    breakpoint: { max: 996, min: 0 },
    items: 2.5,
  },
};
export const newEventData = {
  title: "New",
  type: 0,
  color: "#189f6e",
  background: "#189f6e",
  backgroundLight: "rgb(24 159 110 / 10%)",
  img: null,
  detail_page: null,
  iconActive: newEventIconActive,
};
export const eventweddingData = {
  id: uuid(),
  title: "Wedding",
  type: 1,
  color: "#189f6e",
  background: "#189f6e",
  backgroundLight: "rgb(24 159 110 / 10%)",
  img: weddingImg,
  detail_page: "wedding",
  iconActive: weddingIconActive,
};
export const eventprofessionalData = {
  id: uuid(),
  title: "Profesionnel",
  type: 2,
  color: "#4285f4",
  background: "#4285f4",
  backgroundLight: "rgb(66 133 244 / 10%)",
  img: profesionnelImg,
  detail_page: "profesionnel",
  iconActive: profesionnelIconActive,
};
export const eventbirthdayData = {
  id: uuid(),
  title: "Birthday",
  type: 3,
  color: "#c72fc3",
  background: "#c72fc3",
  backgroundLight: "rgb(199 47 195 / 10%)",
  img: birthdayImg,
  detail_page: "birthday",
  iconActive: birthdayIconActive,
};
export const eventreligiousData = {
  id: uuid(),
  title: "Religious",
  type: 4,
  color: "#d2997e",
  background: "#d2997e",
  backgroundLight: "rgb(210 153 126 / 10%)",
  img: religiousImg,
  detail_page: "religious",
  iconActive: religiousIconActive,
};
export const detailMainSliderResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const detailSecondarySliderResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2.1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 0.72, // Reduced scroll distance
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 0.72, // Reduced scroll distance
  },
};


export const selectRoomSliderResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const section4SliderResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2.9,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.9,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.9,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.1,
    slidesToSlide: 1,
  },
};
