import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Offcanvas,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./admin-dashboard-event.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { IMG_ALT } from "../../../../../constants";
import AdminIconDragDrop1 from "../../../../../assets/img/admin_icon_dragdrop_1.png";
import { useSelector } from "react-redux";
import { deleteAmenity, deleteCategory, editAmenity, editAmenityOrder, editCategory, getEvent } from "../../../../../redux/actions/avent";
import { useDispatch } from "react-redux";

const IntegrationSection5 = ({
  screens,
  setScreens,
  setCategory,
  category,
  sectionCategoryList,
  setSectionCategoryList,
  sectionAmenitiesList,
  setSectionAmenitiesList,
  onBack,
  eventId,
  onSelectEminity
}) => {
  const [uploadImage, setUploadImage] = useState(null);
  const event = useSelector(state => state.event.event)
  const [myCategories, setMyCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedcategoryName, setSelectedCategoryName] = useState('')
  const [currentCategoryAmunities, setCurrentCategoryAmunities] = useState([])
  const [currentCategory, setCurrentCategory] = useState({})
  const [selectedAminity,setSelectedAminity] = useState({})
  const [aminityName, setAminityName] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    const currentCategory = event?.categories
    const allCategory = currentCategory.find((myCategory) => myCategory.id === category);
    if (allCategory) {
      setCurrentCategoryAmunities(allCategory.amenity)
      const amenities = allCategory.amenity;
    }
    setMyCategories(event?.categories)
  }, [event, category])

  //TODO: Set position of Categories in section 5
  const onDragEndEvent = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...myCategories];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    copiedItems.forEach((eventData, index) => {
      eventData.order = index;
    });
    copiedItems.map(async (eventData, index) => {
      const data = { order: index };
      const updatedEventCategory = JSON.stringify(data);
      const result = await dispatch(editCategory(eventId,eventData.id, updatedEventCategory));
    });
    const updatedCategories = copiedItems.map((eventData, index) => {
      return { ...eventData, order: index };
    });
    setMyCategories(updatedCategories);
  };

  //TODO: Set position of Category Amenities in section 5
  const onDragEndEventAmenity = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...currentCategoryAmunities];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    copiedItems.forEach((eventData, index) => {
      eventData.order = index;
    });
    copiedItems.map(async (eventData, index) => {
      const data = { order: index };
      const updatedEventCategoryAminities = JSON.stringify(data);
      const result = await dispatch(editAmenityOrder(category, eventData.id, updatedEventCategoryAminities))
    });
    const updatedCategoryAmities = copiedItems.map((eventData, index) => {
      return { ...eventData, order: index };
    });
    setCurrentCategoryAmunities(updatedCategoryAmities);
  };

  const handleEventClick = (data) => {
    setScreens((prevState) => ({
      ...prevState,
      generalAndParameter: true,
      manageEvent: false,
    }));
  };

  const handleEventEditName = () => {
    setScreens((prevState) => ({
      ...prevState,
      manageCategory: false,
      editName: true,
    }));
  };

  const handleEventEditCategoryName = (data) => {
    setSelectedCategory(data)
    setSelectedCategoryName(data.title)
    setScreens((prevState) => ({
      ...prevState,
      manageCategory: false,
      editName: true,
    }));
  };


  const handleEventDeleteCategory = async (data) => {
    const response = await dispatch(deleteCategory(eventId, data.id));
    if (response.error) {
      console.error("Delete Gallery error :", response.message);
    } else {
      dispatch(getEvent(eventId))
    }
  }
  // handleDeleteAminity

  const handleDeleteAminity = async (data) => {
    const response = await dispatch(deleteAmenity(category, data.id));
    if (response.error) {
      console.error("Delete Aminity error:", response.message);
    } else {
      dispatch(getEvent(eventId))
    }
  }
  const handleEventManageAmenities = (data) => {
    setCategory(data.id);
    setCurrentCategory(data);
    onSelectEminity(data);
    setScreens((prevState) => ({
      ...prevState,
      manageCategory: false,
      manageAmenities: true,
    }));
  };

  const handleEventEditAmenities = () => {
    setScreens((prevState) => ({
      ...prevState,
      manageAmenities: false,
      editAmenities: true,
      editRules: false,
    }));
  };

  const handleEditCategoryAmenities = (data) => {
    setSelectedAminity(data)
    setAminityName(data.title)
    setScreens((prevState) => ({
      ...prevState,
      manageAmenities: false,
      editAmenities: true,
      editRules: false,
    }));
  }

  // onBack("editAmenities", "manageAmenities")
  // const
  const handleSaveAminity = async () => {
    const formData = new FormData();
    formData.append('title', aminityName);
    formData.append('image', uploadImage);
    const response = await dispatch(editAmenity(category, selectedAminity.id, formData));
    if (response.error) {
      console.error("Edit aminity error:", response.message);
    } else {
      setUploadImage(null)
      dispatch(getEvent(eventId))
      onBack()
    }
  }

  const handleEditCategory = async () => {
    const myEditCategory = {title: selectedcategoryName}
    const myData = JSON.stringify(myEditCategory)
    const response = await dispatch(editCategory(eventId,selectedCategory.id, myData));
    if (response.error) {
      console.error("Edit Category error:", response.message);
    } else {
      dispatch(getEvent(eventId))
      onBack("editName", "manageCategory")
    }
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));

  return (
    <>
      {screens.manageCategory && (
        <Row className="clt-detail-section-6">
          <Col className="clt-admin-event-s1">
            <Row className="clt-detail-section-6-header">
              <Col>
                <h2>Category</h2>
                {/* <p>Manage events offered to users</p> */}
              </Col>
            </Row>
            <Row className="clt-admin-event-list-div-body m-0">
              <Col>
                <DragDropContext
                 onDragEnd={onDragEndEvent}
                 >
                  <Droppable droppableId="events">
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {myCategories?.sort((a, b) => a.order - b.order).map((eventData, index) => {
                            return (
                              <Draggable
                                key={eventData.id}
                                draggableId={eventData.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Row
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        backgroundColor: snapshot.isDragging
                                          ? "#fff"
                                          : "transparent",
                                        border: snapshot.isDragging
                                          ? "1px solid #dedddd"
                                          : "none",
                                        borderRadius: snapshot.isDragging
                                          ? "10px"
                                          : "0",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Col
                                        className="clt-admin-event-list-div"
                                        style={{
                                          borderBottom:
                                            snapshot.isDragging && "none",
                                        }}
                                      >
                                        <div className="d-flex align-items-center w-100">
                                          <img
                                            src={AdminIconDragDrop1}
                                            alt={IMG_ALT}
                                            className="img-dragdrop"
                                          />
                                          <span className="dragdrop-counter">
                                            {index + 1}
                                          </span>
                                          <div
                                            className="inner-icon-title-div"
                                            onClick={() => handleEventEditCategoryName(eventData)}
                                          >
                                            <div className="d-flex flex-column align-items-start justify-content-center">
                                              <h2>{eventData.title}</h2>
                                              {/* {!eventData.isActive && (
                                                                                                <p>Pause</p>
                                                                                            )} */}
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                            style={{
                                              width: "10%",
                                            }}
                                          >
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                as={CustomToggle}
                                              />
                                              <Dropdown.Menu
                                                size="sm"
                                                title=""
                                                className="pfr_dropdown_menu"
                                              >
                                                <Dropdown.Item
                                                  onClick={() => handleEventEditCategoryName(eventData)}
                                                  className="pfr_payment_cards3dots_options"
                                                >
                                                  Edit Name
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleEventManageAmenities(
                                                      eventData
                                                    )
                                                  }
                                                  className="pfr_payment_cards3dots_options"
                                                >
                                                  Manage Amenities
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleEventDeleteCategory(eventData)
                                                  }
                                                  className="pfr_payment_cards3dots_options"
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {screens.editName && (
        <>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Category name</h2>
            </Col>
          </Row>
          <Row>
            <Col className="clt-admin-event-info-input-div">
              <Form.Floating className="pfr_inputFloat">
                <Form.Control
                  id="floatingInput1"
                  type="text"
                  placeholder="category"
                  value={selectedcategoryName}
                  onChange={(event) => setSelectedCategoryName(event.target.value)}
                />
                <label htmlFor="floatingInput1">Name</label>
              </Form.Floating>
            </Col>
          </Row>
          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button
              className="shadow-none"
              onClick={handleEditCategory}
            >
              Save
            </Button>
          </div>
        </>
      )}

      {screens.manageAmenities && (
        <Row className="clt-detail-section-6">
          <Col className="clt-admin-event-s1">
            <Row className="clt-detail-section-6-header">
              <Col>
                <h2>Manage Amenities</h2>
                {/* <p>Manage events offered to users</p> */}
              </Col>
            </Row>
            <Row className="clt-admin-event-list-div-body m-0">
              <Col>
                <DragDropContext
                  onDragEnd={onDragEndEventAmenity}
                >
                  <Droppable droppableId="events">
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {/* currentCategoryAmunities */}
                          {currentCategoryAmunities.sort((a, b) => a.order - b.order).map((eventData, index) => {
                            return (
                              <Draggable
                                key={eventData.id}
                                draggableId={eventData.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <Row
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        backgroundColor: snapshot.isDragging
                                          ? "#fff"
                                          : "transparent",
                                        border: snapshot.isDragging
                                          ? "1px solid #dedddd"
                                          : "none",
                                        borderRadius: snapshot.isDragging
                                          ? "10px"
                                          : "0",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Col
                                        className="clt-admin-event-list-div"
                                        style={{
                                          borderBottom:
                                            snapshot.isDragging && "none",
                                        }}
                                      >
                                        <div className="d-flex align-items-center w-100">
                                          <img
                                            src={AdminIconDragDrop1}
                                            alt={IMG_ALT}
                                            className="img-dragdrop"
                                          />
                                          <span className="dragdrop-counter mx-1">
                                            {category}.{index + 1}
                                          </span>
                                          <div
                                            className="inner-icon-title-div"
                                            onClick={() => handleEditCategoryAmenities(eventData)}
                                          >
                                            <div className="d-flex flex-column align-items-start justify-content-center">
                                              <h2>{eventData.title}</h2>
                                              {/* {!eventData.isActive && (
                                                                                                <p>Pause</p>
                                                                                            )} */}
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                            style={{
                                              width: "10%",
                                            }}
                                          >
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                as={CustomToggle}
                                              />
                                              <Dropdown.Menu
                                                size="sm"
                                                title=""
                                                className="pfr_dropdown_menu"
                                              >
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleEditCategoryAmenities(eventData)
                                                  }
                                                  className="pfr_payment_cards3dots_options"
                                                >
                                                  Edit Amenities
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleDeleteAminity(eventData)
                                                  }
                                                  className="pfr_payment_cards3dots_options"
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {screens.editAmenities && (
        <>
          <Row className="clt_admin_dash_manage_event_header">
            <Col>
              <h2>Amenities</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name">NAME</p>
              <Col className="clt-admin-event-info-input-div">
                <Form.Floating className="pfr_inputFloat">
                  <Form.Control
                    id="floatingInput1"
                    type="text"
                    placeholder="name"
                    value={aminityName}
                    onChange={(event) => setAminityName(event.target.value)}
                  />
                  <label htmlFor="floatingInput1">Name</label>
                </Form.Floating>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <p class="clt_admin_dash_menu_container_name mt-3">IMAGE</p>
              <div className="clt-admin-event-fileinput-div">
                <i className="fa-solid fa-plus"></i>
                <span>{uploadImage ? uploadImage.name : "Add an file"}</span>
                <div className="opacity-0 div-hidden">
                  <input
                    type="file"
                    onChange={(e) => setUploadImage(e.target.files[0])}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="pfr_payment_space_bottom" />
          <div className="clt_payment_footer">
            <Button
              className="shadow-none"
              onClick={handleSaveAminity}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default IntegrationSection5;
