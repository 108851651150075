import React from "react";
import BackArrow from "../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../constants";
import { Offcanvas } from "react-bootstrap";
import "./auth-modal.css";
import "react-phone-number-input/style.css";

const AuthUser = () => {
	return (
		<Offcanvas
			placement="start"
			className="clt_auth_user_offcanvas"
			show={false}
		>
			<Offcanvas.Header className="justify-content-start clt_auth_user_offcanvas_header">
				<button
					type="button"
					className="btn-close btn-back shadow-none d-flex"
					aria-label="Close"
				>
					<img src={BackArrow} className="" alt={IMG_ALT} />
				</button>
				<Offcanvas.Title>Account</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body className="clt_auth_user_offcanvas_body"></Offcanvas.Body>
		</Offcanvas>
	);
};

export default AuthUser;
