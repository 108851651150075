import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchSidebarChat from "../sidebar/search_sidebar_select_or_invite_user";

/* Const */ 
const SectionUser = ({ title, handleClick, text, subText, usersData, searchHandler }) => {
  /* Section with button "Search*/ 
  return (
    <Row>
      <Col className="clt-booking-cp-div4">
        {title && (
          <h2 className="clt-booking-cp-div4-title">{title}</h2>
        )}
        <div
          className="w-100 d-flex justify-content-between align-items-center "
          onClick={handleClick}
        >
          <div className="w-100 d-flex flex-column justify-content-center align-items-start clt-booking-cp-div4-text">
            <h2>{text}</h2>
            <div className="w-100 mt-3">
            <SearchSidebarChat usersData={usersData} searchHandler={searchHandler} />
            </div>
          </div>

        </div>
      </Col>
    </Row>
  );
};

export default SectionUser;
