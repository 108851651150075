import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useAuth } from "../../authContext";
import { ErrorToast } from "../../../../reusable/Toast";
import "../../auth-modal.css";
const PersonalSignup = ({ handleContinue, authenticationFlow }) => {
	const { firstName, lastName } = useAuth();

	const [data, setData] = useState({
		firstName: authenticationFlow ? authenticationFlow.first_name : firstName,
		lastName: authenticationFlow ? authenticationFlow.name : lastName,
	});

	const handleChange = (e) => {
		setData((s) => ({ ...s, [e.target.name]: e.target.value }));
	};

	const handleClick = (e) => {
		if (data.firstName === "") {
			ErrorToast("Prénom manquant", "Veuillez ajouté l'information manquante.");
			return;
		}

		if (data.lastName === "") {
			ErrorToast("Nom manquant", "Veuillez ajouté l'information manquante.");
			return;
		}

		handleContinue(data.firstName, data.lastName);
	};

	return (
		<div className="pfr_loginModalVerify">
			<div className="pfr_loginModalVerifyDiv pfr_loginModal_Body">
				<Row>
					<Col>
						<h2 className="pfr_loginModalH2">
							Quel est votre nom ?
						</h2>
					</Col>
				</Row>
				<Row>
					<Col className="pfr_signupModalNameDiv">
						<Row>
							<Col className="pfr_inputFloatDiv">
								<Form.Floating className="pfr_inputFloat">
									<Form.Control
										id="floatingInput1"
										type="text"
										placeholder="Prénom"
										value={data.firstName}
										onChange={handleChange}
										name="firstName"
									/>
									<label htmlFor="floatingInput1">Prénom</label>
								</Form.Floating>
							</Col>
							<Col className="pfr_inputFloatDiv">
								<Form.Floating className="pfr_inputFloat">
									<Form.Control
										id="floatingInput2"
										type="text"
										placeholder="Nom"
										value={data.lastName}
										onChange={handleChange}
										name="lastName"
									/>
									<label htmlFor="floatingInput2">Nom</label>
								</Form.Floating>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<div className="pfr_loginModalVerifyDiv">
				<Row className="pfr_loginModalSeparatorDiv">
					<div className="pfr_loginModalSeparator" />
				</Row>
				<div className="pfr_loginModal_footer">
					<Col>
						<Button
							className="pfr_loginModalBtn shadow-none"
							onClick={handleClick}
						>
							Continuer
						</Button>
					</Col>
				</div>
			</div>
		</div>
	);
};

export default PersonalSignup;
