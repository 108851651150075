// Import
import React, { useState, useEffect } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../../../../assets/img/back_arrow.png";
import { IMG_ALT, eventweddingData } from "../../../../../../../constants";
import "../../../../../notification/user_notification.css";
import ConfirmPay2 from "../../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay4 from "../../../../../../../assets/img/confirm_pay_4.png";
import CustomHr from "../../../../../../reusable/CustomHr";
import SectionDate from "../../../../../../reusable/booking/SectionDate";
import SectionInformationMessageRequestCancel from "../../../../../../reusable/SectionInformationMessageRequestCancel.js";
import SectionUser from "../../../../../../reusable/booking/SectionUser";
import Booking from "../../../../../detail/booking/booking_user";
import SectionPriceDetail from "../../../../../../reusable/booking/SectionPriceDetailCancelEditRequest.js";
import Account from "../../../../../account/Account";
import { useAuth } from "../../../../../auth/authContext";
import { SuccessToast } from "../../../../../../reusable/Toast";
import SidebarBookingAdmin from "../../../admin_dash_booking_detail/sidebar/AdminDashboardBookingDetail";

/* Const */
const SidebarEditRequestRejectAdmin = ({ isOpenSidebarEditRequestRejectAdmin, onCloseOpenSidebarEditRequestRejectAdmin, setIsOpenSidebarEditRequestRejectAdmin }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(eventweddingData);

  /* Const - Section - Date */
  const handleDateEdit = () => {
    setIsBookingSidebar(true);
  };
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);
  let bookingProps = {
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - User */
  const [isAccountInfo, setIsAccountInfo] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const [selectedProfile, setSelectedProfile] = useState({
    type: "Personal",
    detail: "John Doe . 055555555 . johndoe@gmail.com"
  });
  useEffect(() => {
    if (user)
      setSelectedProfile({ type: "Personal", detail: `${user?.firstName} ${user?.lastName} · ${user?.phone} · ${user?.email}` })
  }, [user])  

  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Button - Make a new request */
  const handleMakeNewRequest = () => {
    SuccessToast("There is the user booking, you can edit what you need");
    onCloseOpenSidebarEditRequestRejectAdmin(true);
    setIsOpenSidebarBookingAdmin(true);
  };

  /* Const - Sidebar - Booking */
  const [isOpenSidebarBookingAdmin, setIsOpenSidebarBookingAdmin] = useState(false);



  {/* Sidebar - Admin - Edit request - Pending : Date */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarEditRequestRejectAdmin}
        onHide={onCloseOpenSidebarEditRequestRejectAdmin}
      >


        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarEditRequestRejectAdmin}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>[SIDEBAR] Admin - Edit Request - Reject</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          <>
            <div className="clt_mybooking_offcanvas_body">

              <SectionUser
                handleClick={() => setIsAccountInfo(true)}
                text={`${selectedProfile.type} profile`}
                title="Profile"
                subText={
                  <span>{selectedProfile.detail}</span>
                }
              />
              
            </div>
            <CustomHr
              rowClass="p-0 m-0"
              colClass="clt-booking-hr-pd p-0 w-100"
              hrClass="p-0 m-0 clt-hr-2"
            />
            <div className="clt_mybooking_offcanvas_body">
              <h2 className="clt-mybooking-title">Edit request - Cancel</h2>
              <SectionInformationMessageRequestCancel
                innerTitle="Pending"
                innerSubTitle="The request has been reject or is no longer available"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <h2 className="clt-booking-cp-div1-title">Detail</h2>
              <SectionDate
                image={ConfirmPay2}
                subText1="Subtext 1"
                subText2="Subtext 2"
                isCancelRequest={true}
                oldSubText1="Subtext 1"
                oldSubText2="Subtext 2"
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />



              {/* Footer */}
              <Row>
                <Col>

                  <div className="clt-booking-btn-refuse-div">
                    <Button
                      className="shadow-none clt-booking-btn-refuse"
                      onClick={handleMakeNewRequest}
                    >
                      <span>Make a new request</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>



      {/* Function - Booking : Sidebar date and Accept request*/}
      <Booking
        openBookingSidebar={isBookingSidebar}
        onCloseBookingSidebar={(data) => {
          if (data === "Notification") {
            SuccessToast("Edited");
            onCloseOpenSidebarEditRequestRejectAdmin();
          }
          setIsBookingSidebar(false);
        }}
        eventType={eventType}
        setEventType={(event) => setEventType(event)}
        isFromEditRequest={true}
        {...bookingProps}
      />

      {/* Function - Sidebar - Booking - Admin */}
      <SidebarBookingAdmin
        isOpenSidebarBookingAdmin={isOpenSidebarBookingAdmin}
        onCloseOpenSidebarBookingAdmin={() => setIsOpenSidebarBookingAdmin(false)}
      />


      {/* Function -  Section User */}
      <Account
        isOpenSidebar={isAccountInfo}
        setIsOpen={() => {
          setIsAccountInfo(true);
        }}
        onCloseSidebar={() => {
          setIsAccountInfo(false);
        }}
        selectProfile="admin"
        selectedProfileData={selectedProfile}
        setSelectedProfileData={setSelectedProfile}
      />
    </>
  );
};

export default SidebarEditRequestRejectAdmin;
