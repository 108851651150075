// import
import React, { useEffect, useState, useRef  } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import "./home.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  homeSliderLandscapeResponsive,
  homeSliderResponsive,
  IMG_ALT,
  IMG_BASEURL,
  REPONSIVE_MODES,
  section4SliderResponsive,
  eventweddingData
} from "../../../constants";
import HomeSection4Desktop from "../../../assets/img/home-section-4-pro.png";
import HomeSection4Mobile from "../../../assets/img/home-section-4-mbl-wedding.png";
import HomeSection6 from "../../../assets/img/home-section-6.png";
import icon_question from "../../../assets/img/icon_question.png";
import wall_color from "../../../assets/img/wall-color.png";
import wall_color1 from "../../../assets/img/wall-color1.png";
import wall_color2 from "../../../assets/img/wall-color2.png";
import wall_color3 from "../../../assets/img/wall-color3.png";
import wall_color4 from "../../../assets/img/wall-color4.png";
import wall_color5 from "../../../assets/img/wall-color5.png";
import wall_color6 from "../../../assets/img/wall-color6.png";
import icon_account from "../../../assets/img/icon_account.png";
import illustration_pourunevent_1 from "../../../assets/img/illustration_pourunevent_1.png";
import illustration_pourunevent_2 from "../../../assets/img/illustration_pourunevent_2.png";
import illustration_pourunevent_3 from "../../../assets/img/illustration_pourunevent_3.png";

import adsmobile from "../../../assets/img/adsmobile.png";
import adsmobile2 from "../../../assets/img/adsmobile2.png";
import icon_contact from "../../../assets/img/icon_contact.png";
import kjnkjnlkjnkjj from "../../../assets/img/kjnkjnlkjnkjj.png";

import DetailSection7ImageMobile from "../../../assets/img/detail_section_7_img_mobile.jpg";
import DetailSection7ImageDesktop from "../../../assets/img/detail_section_77_img_desktop.jpg";
import CustomHr from "../../reusable/CustomHr";

import useScreenOrientation from "react-hook-screen-orientation";
import ContactUsScreen from "./ContactUs/ContactUsScreen";
import { useHistory } from "react-router-dom";
import { useAuth } from "../auth/authContext";
import { auth } from "../auth/firebase";
import EventsNavbar from "../../reusable/EventsNavbar";
import { Helmet } from 'react-helmet';

// Import - Screen - Auth
import AuthUser from "../auth/AuthUser";
import AuthModal from "../auth/AuthModal";

// Import - Screen - Account
import Account from "../account/Account";

// Import - Screen - Payment method
import Payment from "../payment/Payment";

// Import - Screen - Notification
import Notification from "../notification/user_notification";

// Import - Screen - My booking
import MyBooking from "../myBooking/user_mybooking";
import {
  getAllArticlesWithCategory,
  getAllCategoryWithPaths,
} from "../../../containers/helpCenterPages/api";
import Article from "../helpCenter/Article";
import { getArticlesByName } from "../../../utils/filters";
import PriceSimulatorModal from "../../reusable/PriceSimulatorModel";
import NewSection6 from "../../reusable/NewSection6";
import { section4NewSection5, NewSection5 } from "../../reusable/packoption/NewSection5"; // Import the data and component
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getEvent, getEvents } from "../../../redux/actions/avent";
import DetailSection77 from "../detail/sections/DetailSection77";

/* Const */
const HomeWedding = (props) => {
  
  /* Const - ??? */
  const history = useHistory();

  /* Const - ??? */
  const [askedQuestions, setAskedQuestions] = useState(null);

  const { isLoggedIn } = useAuth();


  /* Const - Section 3 - Main slider, step slider, final slider */
  const sliders = props.myCurrentEvent?.dataValues?.sliders
    ? JSON.parse(props.myCurrentEvent.dataValues.sliders)
    : null;

  /* Const - Section 4 - Heading - Image different for mobile and desktop */
  const section4 = props.myCurrentEvent?.dataValues?.section4
    ? JSON.parse(props.myCurrentEvent.dataValues.section4)
    : null;

  /* Const - Section 5 - FAQ */
  const questions = props.myCurrentEvent?.dataValues?.questions
    ? JSON.parse(props.myCurrentEvent.dataValues.questions)
    : null;

  const [isContactScreenOpen, setIsContactScreenOpen] = useState(false);
  const screenOrientation = useScreenOrientation();
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <div className="d-flex justify-content-between mt-3 mb-3">
        <h2 className="clt-section-3-h2">Pour un événement {sliders?.heading}</h2>
        <div>
          <Button
            className={
              currentSlide === 0
                ? "disable clt-section-3-cards-arrowBtn me-2"
                : "clt-section-3-cards-arrowBtn me-2"
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? "disable clt-section-3-cards-arrowBtn"
                : "clt-section-3-cards-arrowBtn"
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
    );
  };

  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isPriceSimulatorModal, setIsPriceSimulatorModal] = useState(false);
  // articles
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [faqs, setFaqs] = useState([
    {
      question: 'Les tailles de lunettes de soleil sont-elles universelles ?',
      answer: (
        <>
          <p>Nos lunettes de soleil sont de taille standard et conviennent généralement aux personnes âgées de <span><b>14 à 99 ans.</b></span></p>
          <p>Pour garantir un ajustement parfait, veuillez consulter les dimensions spécifiques de chaque modèle dans la description du produit.</p>
          <p> ㅤ</p>
          <p><span><b>Taille du produit actuellement affiché</b></span></p>
          <p id="largeur">Largeur :</p>
          <p id="hauteur">Hauteur :</p>
          <p id="longueur-branche">Longueur de la branche :</p>
          <p id="largeur-lentilles">Largeur des lentilles :</p>
          <p id="entre-lentilles">Entre les lentilles :</p>
          <p> ㅤ</p>
          <p>Si vous avez des questions ou besoin de conseils, notre service client est à votre disposition.</p>
        </>
      ),
      open: false
    },
    {
      question: 'Prix d\'une réservation',
      answer: (
        <>
          <p>Le produit actuellement afficher à une protection <span id="uv-value"><b>data_uv</b></span> permettant de protéger les yeux des rayons ultraviolets nocifs du soleil, qui peuvent causer des dommages à long terme comme les cataractes et la dégénérescence maculaire.</p>
        </>
      ),
      open: false
    },
    {
      question: 'Comment choisir la forme de lunettes de soleil qui convient à mon visage ?',
      answer: (
        <>
          <p>Nous proposons une fonctionnalité qui vous permet de trouver les lunettes de soleil parfaites. En répondant à un questionnaire, nous identifions la forme de votre visage, vos couleurs préférées et vos préférences personnelles pour vous suggérer les modèles qui vous conviennent le mieux. <a className="text_link_product_page_question" href="#" onClick={(e) => { e.preventDefault(); document.getElementById('cecczcezjce').click(); }}>Faire le test</a></p>
        </>
      ),
      open: false
    }
  ]);

  const toggleAnswer = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, open: !faq.open } : faq
      )
    );
  };
  const [isOpenArticle, setIsOpenArticle] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [stickyBarTop, setstickyBarTop] = useState(undefined);
  const [eventType, setEventType] = useState(eventweddingData);
  const dispatch = useDispatch();
  const allEvents = useSelector(state => state.allEvents.events);
  const event = useSelector(state => state.event.event)
  const [myCurrentEvent, setMyCurrentEvent] = useState({})
  const [myAllEvents, setMyAllEvents] = useState([])
  const [isStick, setIsStick] = useState(false);
  useEffect(() => {
    setMyAllEvents(allEvents)
    const sortedEvents = allEvents?.sort((a, b) => a.eventType - b.eventType);
    let firstEvent
    if (sortedEvents) {
      firstEvent = sortedEvents[0];
    }
    if (firstEvent) {
      dispatch(getEvent(firstEvent.id));
    }
  }, [allEvents]);

  useEffect(() => {
    if (event) {
      setMyCurrentEvent(event);
    }
  }, [event]);

  const logOut = (e) => {
    auth.signOut();
    localStorage.removeItem("userToken");
    localStorage.removeItem("name");
    localStorage.removeItem("user-login-in");
  };

  const handleProductSlider = () => {
    setIsProductsSidebarOpen(!isProductsSidebarOpen);
    setIsProductsSidebarList(true);
    setIsProductsSiderbarDetail(false);
  };

  const [isProductsSidebarOpen, setIsProductsSidebarOpen] = useState(false);
  const [isProductsSidebarList, setIsProductsSidebarList] = useState(false);
  const [isProductsSiderbarDetail, setIsProductsSiderbarDetail] =
    useState(false);

  
  const toggleAuthModal = () => {
    setIsLoginModal((prevState) => !prevState);
  };

  /* Const - Screen - Account */
  const [isAccountDrawer, setIsAccountDrawer] = useState(false);
  const openAccountDrawer = (e) => {
    setIsAccountDrawer(true);
  };

  const closeAccountDrawer = (e) => {
    setIsAccountDrawer(false);
  };

  /* Const - Screen - Payment method */
  const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);
  const onOpenPaymentSidebar = () => {
    setIsPaymentSidebar(true);
  };
  const onClosePaymentSidebar = () => {
    setIsPaymentSidebar(false);
  };

  /* Const - Screen - Notification */
  const [isNotificationSidebar, setIsNotificationSidebar] = useState(false);
  const onOpenNotificationSidebar = () => {
    setIsNotificationSidebar(true);
  };
  const onCloseNotificationSidebar = () => {
    setIsNotificationSidebar(false);
  };

  
  /* Const - Screen - My booking */
  const [isOpenSidebarMyBookingUser, setsetIsSidebarMyBookingUser] =
    useState(false);
  const onOpenSidebarMyBookingUser = () => {
    setsetIsSidebarMyBookingUser(true);
  };

  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
          <p className="aaclt_admin_dash_timeslot_edit_box_subheading mt-1">{rest.subtitle} </p>
      </div>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
        <h2 className="clt-detail-left-section-2-h2">{rest.title}</h2>
        <div>
          <Button
            className={currentSlide === 0
              ? "disable clt-detail-left-section-2-cards-arrowBtn me-2"
              : "clt-detail-left-section-2-cards-arrowBtn me-2"}
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={currentSlide === disableState
              ? "disable clt-detail-left-section-2-cards-arrowBtn"
              : "clt-detail-left-section-2-cards-arrowBtn"}
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
      
    );
  };

  const onCloseOpenSidebarMyBookingUser = () => {
    setsetIsSidebarMyBookingUser(false);
  };


  const fetchArticle = (articleName) => {
    const article = getArticlesByName(articles || [], [articleName])[0];
    if (article) {
      setSelectedArticle(article);
      setIsOpenArticle(true);
    } else {
      console.error(`Article "${articleName}" not found.`);
    }
  };

  const placeId = 'ChIJITYKcHelrhIRI8WmJahNTVc';
  const [bottomUp, setBottomUp] = useState(false);
  const apiKey = 'AIzaSyA_Q2Wi0ImoYiIeu51_-t7VBIbWPKYJHJc';

  const getCategories = async () => {
    setIsLoading(true);
    const res = await getAllCategoryWithPaths();
    if (res.success) {
      getArticles(Object.values(res.data).map((cat) => cat.id));
      console.log("res.data", res.data);
    } else {
      console.log("error");
    }
    setIsLoading(false);
  };
  const getArticles = async (ids) => {
    const res = await Promise.all(
      ids.map(async (id) => {
        const res = await getAllArticlesWithCategory(id);
        if (res.success) {
          return Object.values(res.data);
        } else {
          return null;
        }
      })
    );

    const nRes = [].concat(...res).filter((item) => item !== null);
    setArticles(nRes);
  };

  useEffect(() => {
    const stickyBarEl = document?.querySelector(".stickyBar")?.getBoundingClientRect();
    setstickyBarTop(stickyBarEl?.top);
  }, []);

  useEffect(() => {
    if (!stickyBarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyBarTop]);

  const isSticky = (e) => {
    const stickyBarEl = document.querySelector(".stickyBar");
    const scrollTop = window.scrollY;
    if (scrollTop >= stickyBarTop - 10) {
      stickyBarEl?.classList.add("A");
      setIsStick(true);
    } else {
      stickyBarEl?.classList.remove("A");
      setIsStick(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    // Log the articles for debugging
    console.log("Articles:", articles);
  }, [articles]);

  useEffect(() => {
    // Optionally, you can initialize the ElfSight widget after the script has loaded.
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.dataset.useServiceCore = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  console.log("All articles:", articles);
  console.log("Filtered articles:", getArticlesByName(articles || [], ["Politique de cookies"]));

  const mainCardRef = useRef();

  useEffect(() => {
    // Calculate the maximum height of all cards in the carousel
    const carouselCards = document.querySelectorAll('.clt-section-3-cards');
    let maxHeight = 0;

    carouselCards.forEach((card) => {
      const cardHeight = card.clientHeight;
      maxHeight = Math.max(maxHeight, cardHeight);
    });

    // Set the height of the main card to match the maximum height
    if (mainCardRef.current) {
      mainCardRef.current.style.height = `${maxHeight}px`;
    }
  }, [props]); // Include any relevant dependencies


  return (
    <React.Fragment>

      {/*
      <Container className="showMobile clt-homepage-container">
        <Row>
          <Col className="clt-section-3-h2-mt clt-section-row-col-pd">
            <h2 className="clt-section-3-h2">{sliders?.heading}</h2>
          </Col>
        </Row>
      </Container> */}
{/*
      <Container className="showMobile aabbbb clt-homepage-container">
        <Row>
          <Col className="clt-section-3-h2-mt clt-section-row-col-pd">
            <div >
              <h2 className="clt-section-3-h2">Pour un événement</h2>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h2 className="clt-section-3-h2-second">{myCurrentEvent?.dataValues?.title}</h2>
                <img className="aaaaaab4 hideMobile" src={wall_color4} alt={IMG_ALT} />
                <img className="aaaaaab4 showMobile" src={wall_color4} alt={IMG_ALT} />
              </div>
            </div>
          </Col>
        </Row>
        
      </Container>

      <Container className="showMobile aabbb clt-homepage-container">
        {myAllEvents &&
          <EventsNavbar
            eventType={eventType}
            myAllEvents={myAllEvents}
            currenEVENT={myCurrentEvent?.dataValues?.title}
            isHomePage={true}
            isStick={isStick}
          />
        }
      </Container>*/}
      
      {/* Slider - Mobile 
      <Container fluid className="showMobile aabbbbb clt-homepage-container">
        <Row className="clt-section-3-row">
          <Col>
            <Container className="clt-section-3-row-container">
              <Row>
                <Col className="clt-section-3-row-col">
                  <div className="d-flex justify-content-start align-items-start aclt-section-3">
                    {/*<div
                        ref={mainCardRef}
                        className="d-flex flex-column justify-content-end align-items-start clt-section-3-cards clt-section-3-cards-end"
                        style={{
                          backgroundColor: `${props?.myCurrentEvent?.dataValues?.background}`,
                        }}
                        
                      >
                      <h2>Votre {props?.myCurrentEvent?.dataValues?.title}</h2>
                      
                      </div>*/}
                    

                    {/*
                    <div className="d-flex flex-column clt-section-3-cards clt-section-3-cards-secondary">
                      <div style={{ width: '100%', overflow: 'hidden' }}>
                        <img src={illustration_pourunevent_1}  style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div style={{ height: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ padding: '16px' }} >
                          <span>{props?.myCurrentEvent?.dataValues?.title}</span>
                          <p>Célébrer votre événement de {props?.myCurrentEvent?.dataValues?.title} avec jusqu'à 160 invités</p>
                          <a href={sliders?.sliderFirst?.textlinkUrl} target="_blank">En savoir plus</a>
                        </div>
                        
                      </div>
                    </div>
                    <div className="d-flex flex-column clt-section-3-cards clt-section-3-cards-secondary">
                      <div style={{  width: '100%', overflow: 'hidden' }}>
                        <img src={illustration_pourunevent_2}  style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div style={{ height: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ padding: '16px' }} >
                          <span>Prix d'une réservation</span>
                          <p>Estimer le prix d'une réservation grâce à notre simulateur de prix</p>
                          <a onClick={() => setIsPriceSimulatorModal(true)} target="_blank">Simulé le prix</a>
                        </div>
                        
                      </div>
                    </div>



                    <div className="d-flex flex-column clt-section-3-cards clt-section-3-cards-secondary">
                      <div style={{  width: '100%', overflow: 'hidden' }}>
                         
                        <img src={illustration_pourunevent_3}  style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      </div>
                      <div style={{ height: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ padding: '16px' }} >
                          <span>Centre aide</span>
                          <p>Le château est disponible pour répondre à vos questions</p>
                          <a href="https://chateaulatournelle.com/aide" target="_blank">Visitez le centre d'aide</a>
                        </div>
                        
                      </div>
                    </div>*/}

                    

                    {/*<div>
                      <div
                        ref={mainCardRef}
                        className="d-flex flex-column justify-content-start align-items-start clt-section-3-cards clt-section-3-cards-end"
                        style={{
                          backgroundColor: `${props?.myCurrentEvent?.dataValues?.background}`,
                        }}
                        
                      >
                        <h2>En savoir plus sur le château</h2>
                        <Button
                          onClick={() =>
                            history.push(sliders?.sliderLast?.buttonURL)
                          }
                          className="clt-section-3-cards-button shadow-none"
                        >
                          <span>
                            Continuer{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>*/}


      {/* Slider - Desktop
      <Container className="aab">
        <Container className="hideMobile clt-homepage-container22345">
          <Row>
            <Col>
              <div className="aclt-section-3">
                <div className="clt-section-3-h2-div fixed-height" style={{ width: "40%" }}>
                  <h2 className="clt-section-3-h2">Pour un événement</h2>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h2 className="clt-section-3-h2-second">{myCurrentEvent?.dataValues?.title}</h2>
                    <img className="aaaaaab4 hideMobile" src={wall_color4} alt={IMG_ALT} />
                  </div>
                </div>
                <div className="dzajdnlziajd" style={{ width: "60%" }}>
                  {myAllEvents && (
                    <EventsNavbar
                      eventType={eventType}
                      myAllEvents={myAllEvents}
                      currenEVENT={myCurrentEvent?.dataValues?.title}
                      isHomePage={true}
                      isStick={true}
                    />
                  )}
                  <div className="d-flex flex-column-reverse">
                    <div className="faq-section">
                      {faqs.map((faq, index) => (
                        <div key={index} className="faq-item" onClick={() => toggleAnswer(index)}>
                          <div className="faq-question-wrapper">
                            <div>
                              <p className="faq-question_subtitle">Question sur le produit</p>
                              <p className="faq-question">{faq.question}</p>
                            </div>
                            <img src="../../../assets/img/back_arrow.png" className="faq-arrow" alt="Arrow" />
                          </div>
                          {faq.open && <div className="faq-answer">{faq.answer}</div>}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>*/}

      {/* Slider - Desktop
      <Container className="clt-homepage-container hideMobile">
          <Row>
            <Col>
              <div className="aclt-section-3">
                <div className="clt-section-3-h2-div fixed-height" style={{ width: "40%" }}>
                <h2 className="clt-home-visite-h23546534">Information</h2>
                  <h2 className="clt-section-3-h2">Pour un événement</h2>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h2 className="clt-section-3-h2-second">{myCurrentEvent?.dataValues?.title}</h2>
                    <img className="aaaaaab4 hideMobile" src={wall_color4} alt={IMG_ALT} />
                  </div>
                </div>
                <div className="dzajdnlziajd" style={{ width: "60%" }}>
                  {myAllEvents && (
                    <EventsNavbar
                      eventType={eventType}
                      myAllEvents={myAllEvents}
                      currenEVENT={myCurrentEvent?.dataValues?.title}
                      isHomePage={true}
                      isStick={true}
                    />
                  )}
                  <div className="d-flex flex-column-reverse">
                    <div className="faq-section">
                      {faqs.map((faq, index) => (
                        <div key={index} className="faq-item" onClick={() => toggleAnswer(index)}>
                          <div className="faq-question-wrapper">
                            <div>
                              <p className="faq-question_subtitle">Question sur le produit</p>
                              <p className="faq-question">{faq.question}</p>
                            </div>
                            <img src="../../../assets/img/back_arrow.png" className="faq-arrow" alt="Arrow" />
                          </div>
                          {faq.open && <div className="faq-answer">{faq.answer}</div>}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
      </Container>*/}

      {/* 
      <Container className="aab">
        <Container className="clt-homepage-container">
          <Row>
            <Col className="">
              <div className="clt-section-5">
                <Accordion className="clt-section-5-accordion">
                  <Accordion.Item
                    eventKey="0"
                    className="clt-section-5-accordion-item"
                  >
                    <Accordion.Header className="clt-section-5-accordion-header">
                      Comment réserver pour un {props?.myCurrentEvent?.dataValues?.title} ?
                    </Accordion.Header>
                    <Accordion.Body className="clt-section-5-accordion-body">
                      <p>Pour réserver un événement, vous pouvez effectuer une réservation depuis notre site internet.</p>
                      <a href={questions?.ask1.textlinkUrl} target="_blank">
                        Réserver
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="1"
                    className="clt-section-5-accordion-item"
                  >
                    <Accordion.Header className="clt-section-5-accordion-header">
                      Pouvons-nous faire une visite ?
                    </Accordion.Header>
                    <Accordion.Body className="clt-section-5-accordion-body">
                      <p>Oui, vous pouvez programmer une visite sur demande en nous contactant.</p>
                      <a onClick={() => setOpen(true)} target="_blank">
                        Nous contacter
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="2"
                    className="clt-section-5-accordion-item"
                  >
                    <Accordion.Header className="clt-section-5-accordion-header">
                      Combien d'invités ?
                    </Accordion.Header>
                    <Accordion.Body className="clt-section-5-accordion-body">
                      <p>Pour un évenement, le château peut accueillir jusqu'à 200 personnes.</p>
                      <a href={questions?.ask3.textlinkUrl} target="_blank">
                      En savoir plus
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="3"
                    className="clt-section-5-accordion-item"
                  >
                    <Accordion.Header className="clt-section-5-accordion-header">
                      Est-il possible de dormir sur place ?
                    </Accordion.Header>
                    <Accordion.Body className="clt-section-5-accordion-body">
                      <p>Le château possède des chambres permettant de faire dormir jusqu'à 30 personnes.</p>
                      <a href={questions?.ask4.textlinkUrl} target="_blank">
                        En savoir plus
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="4"
                    className="clt-section-5-accordion-item"
                  >
                    <Accordion.Header className="clt-section-5-accordion-header">
                      Qu'est-ce que le centre d'aide ?
                    </Accordion.Header>
                    <Accordion.Body className="clt-section-5-accordion-body">
                      <p>Le centre d'aide regroupe toutes les questions liées au château</p>
                      <a href="https://chateaulatournelle.com/aide" target="_blank">
                        Visitez le centre d'aide
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>*/}
      
    


      {/*<Container className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd">
            <div className="border_bottom_light">
              <div className="clt-section-7">
                <h2>Besoin d’aide ?</h2>
                <p>
                  Résolvez votre problème ici. Nous vous mettrons en relation
                  avec le support téléphonique si besoin.
                </p>
                <Button
                  onClick={() => console.log("clicked!")}
                  className="clt-section-7-btn shadow-none"
                >
                  <span>
                    Visitez le centre d’aide{" "}
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>*/}
      {/*
        <NewSection5
          products={section4NewSection5}
          handleProductSlider={handleProductSlider}
          isDetail={false}
          sliderProps={{
            responsive: section4SliderResponsive,
            arrows: false,
            shouldResetAutoplay: false,
            renderButtonGroupOutside: true,
            customButtonGroup: <DetailMainSliderArrows title="Option" subtitle="Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant" />,
            className: "clt-detail-left-section-4-cards-div",
          }}
          customButtonGroupTitle="Option"
      />*/}

      

      
      
    <Container className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd">
            <h2 className="clt-section-home-aide-h2aa"></h2>
            <div className="border_bottom_light clt-section-6" style={{ position: 'relative', overflow: 'hidden', }}>
              {/* Text on the left */}
              <img src={adsmobile} className="img-fluid hideMobile" alt={IMG_ALT} style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
              paddingLeft: '60%',
              objectFit: 'cover',
            }}  />
              <img src={adsmobile2} className="img-fluid showMobile" alt={IMG_ALT} style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
              paddingLeft: '36%',
              objectFit: 'cover',
            }} />
              <div className="text-left" style={{ float: 'left', width: '50%', position: 'relative', zIndex: 2 }}>
                <h2>Réalisez votre événement idéal</h2>
              </div>
              <div style={{ clear: 'both' }}></div>
            </div>
          </Col>
        </Row>
      </Container>  
      <CustomHr className="showMobile" colClass="clt-section-hr-pd221" hrClass="p-0 m-0 clt-hr" />
      <Container className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd" > 
            <div>
              <h2 className="clt-section-home-aide-h2b">Notre page instagram</h2>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="clt-homepage-container">
        <Row>
            <Col className="clt-section-row-col-pd" > 
              <div>
        <Helmet>
          <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer />
        </Helmet>
        </div>
          </Col>
        </Row>
      </Container>
      <div className="elfsight-app-2d68cffa-73bb-4ca0-8e51-8261b3e49378" data-elfsight-app-lazy />
      <NewSection6 setBottomUp={setBottomUp} isDetail={false}  />

      






      <Container className="clt-homepage-container">
  <Row>
    <Col className="clt-section-row-col-pd">
      <div className="d-flex justify-content-between align-items-center clt-div-histoire-2124">
        <div className="d-flex flex-column justify-content-center align-items-start ">
          <h3 className="clt-home-visite-h23546534">Histoire</h3>
          <h3 className="clt-home-visite-h2124">Histoire du château</h3>
          <p className="clt-button-text2125">Explorez l'histoire d'un monument de France, du début des années 1900 à aujourd'hui.</p>
          <Button className="clt-button-text2124" onClick={() => fetchArticle("Histoire du château")}>Lire histoire</Button>
          <p className="clt-button-text-duration2124">Durée : 2 minutes</p>
        </div>
        <div className='clt_messages_container_request_state'>
          <img className='clt_section_information_contact2124img' src={kjnkjnlkjnkjj} alt={IMG_ALT} />
        </div>
      </div>
    </Col>
  </Row>
</Container>





      
      

      <Container className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd" > 
            <div>
              <h2 className="clt-home-visite-h2174">Aide</h2>
              <div>
                {!isLoggedIn && (
                  <div className="d-flex justify-content-start align-items-center clt_section_information_contact2">
                    <div className='clt_messages_container_request_state'>
                      <img src={icon_account} alt={IMG_ALT} />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                      <h2>Compte</h2>
                      <p>Utiliser un compte pour gérez vos réservations, communiquez avec le château via le chat, obtenir des offres exclusives et bien plus encore !</p>
                      <div className="clt-button-text-divabc">
                        <Button className="clt-button-text" onClick={toggleAuthModal}>Connexion/</Button>
                        <Button className="clt-button-text" onClick={toggleAuthModal}>Inscription</Button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-start align-items-center clt_section_information_contact2 ">
                  <div className='clt_messages_container_request_state'>
                      <img src={icon_question} alt={IMG_ALT} />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                    <h2>Centre aide</h2>
                    <p>Le centre d'aide permet de résoudre vos problèmes et trouver des réponses.</p>
                    <Button className="clt-button-text" href="https://chateaulatournelle.com/aide" >Visitez le centre d'aide</Button>
                  </div>
                </div>
                
                <div className="d-flex justify-content-start align-items-center clt_section_information_contact ">
                  <div className='clt_messages_container_request_state'>
                      <img src={icon_contact} alt={IMG_ALT} />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                    <h2>Nous contacter</h2>
                    <p>Le château est disponible pour répondre à vos questions</p>
                    <Button className="clt-button-text" onClick={() => setOpen(true)}>Nous contacter</Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/*<Container className="aaaaaab">
        <div className='clt_messages_container_request_state2'>
            <img src={wall_color} alt={IMG_ALT} />
        </div>
      </Container>*/}
      <Container className="aaaaaab">
        <Container className="clt-homepage-container">
          <div className='clt_messages_container_request_state3'>
              <img className="aaaaaab3 hideMobile" src={wall_color1} alt={IMG_ALT} />
              <img className="aaaaaab1 showMobile" src={wall_color6} alt={IMG_ALT} />
          </div>
        </Container>
      </Container>

      <Container className="aaaaaab">
        <Container className="clt-homepage-container">
          <Row>
            <Col className="clt-section-row-col-pd">
              <div className="">
                <div className="clt-section-8">
                  {/* <div className="footer-container"> */}
                  <div className="container clt_home_footer_container">
                    <div className="row">
                      <div className="clt-columns col-sm-3">
                        <p>CHÂTEAU</p>
                        <div className="div-a-tag">
                          <a href="https://chateaulatournelle.com/detail/Mariage">Événement</a>
                        </div>
                        <div className="div-a-tag">
                          <a href="https://chateaulatournelle.com/detail/Mariage">Réserver</a>
                        </div>
                        <div className="div-a-tag">
                          <a onClick={() => setIsPriceSimulatorModal(true)} >Simulé le prix</a>
                        </div>
                        {/* <span><a href="#">TextLink</a></span> */}
                      </div>
                      <div className="clt-columns col-sm-3">
                        <p>COMPTE</p>
                        {isLoggedIn ? (
                          <>
                            <div
                              onClick={onOpenNotificationSidebar}
                              className="div-a-tag"
                            >
                              <a>Notification</a>
                            </div>
                            <div
                              onClick={onOpenSidebarMyBookingUser}
                              className="div-a-tag"
                            >
                              <a>Mes réservations</a>
                            </div>
                            <div className="div-a-tag">
                              <a href="/inbox">Message</a>
                            </div>
                            <div
                              onClick={openAccountDrawer}
                              className="div-a-tag"
                            >
                              <a>Profile</a>
                            </div>
                            <div
                              onClick={onOpenPaymentSidebar}
                              className="div-a-tag"
                            >
                              <a>Moyen de paiement</a>
                            </div>
                            <div onClick={logOut} className="div-a-tag">
                              <a>Déconnexion</a>
                            </div>
                          </>
                        ) : (
                          <>
                            <div onClick={toggleAuthModal} className="div-a-tag">
                              <a>Connexion</a>
                            </div>
                            <div onClick={toggleAuthModal} className="div-a-tag">
                              <a>Inscription</a>
                            </div>
                          </>
                        )}
                      </div>



                      <div className="clt-columns col-sm-3">
                        <p>À PROPOS</p>
                        

                        <div className="div-a-tag">
                          <a href="https://www.instagram.com/chateaulatournelle/">Instagram</a>
                        </div>

                        
                      </div>
                      
                      
                      
                      <div className="clt-columns col-sm-3">
                        <p>AIDE</p>
                        <div className="div-a-tag">
                          <a href="https://chateaulatournelle.com/aide">Centre d'aide</a>
                        </div>
                        <div className="div-a-tag">
                          <a onClick={() => setOpen(true)}>Nous contacter</a>
                        </div>

                        {getArticlesByName(articles || [], ["Pourquoi créer un compte ?"])?.map((art) => (
                          <div className="div-a-tag" key={art.id}>
                            <a
                              onClick={() => {
                                setSelectedArticle(art);
                                setIsOpenArticle(true);
                              }}
                            >
                              {art.name}
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>


                    <div>
                      <p>
                        <a href="https://docs.google.com/document/d/1VtDKrwp799abiw2n2xT8GikhOCu67X2BB1qimL5g_EM/edit?usp=sharing" target="_blank">
                          Conditions générales de ventes
                        </a> ·{" "}

                        <a href="https://docs.google.com/document/d/1R_w9Qi3Hpjb5uj4DU_OK_LQrW8-AldrPgIqSUdIYbjw/edit?usp=sharing" target="_blank">
                          Mentions légales
                        </a> ·{" "}
                        {getArticlesByName(articles || [], ["Politique de cookies"])?.map((art) => (
                          <a
                            key={art.id}
                            onClick={() => {
                              setSelectedArticle(art);
                              setIsOpenArticle(true);
                            }}
                          >
                            Politique de cookies
                          </a>
                        ))} ·{" "}

                        <a href="https://docs.google.com/document/d/1PGPWZ2yanKtJmtrDcTY9ZL1tLtBq9ul29Re5qo6uZhQ/edit?usp=sharing" target="_blank">
                          Credits
                        </a> ·{" "}


                        <a
                          className="cursor-pointer"
                          onClick={() => props.setIsOpen(true)}
                        >
                          Préférence cookie
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="aaaaaab2">
        <div className='clt_messages_container_request_state3'>
            <img  src={wall_color2} alt={IMG_ALT} />
        </div>
      </Container>
      {/* last modified black footer */}
      {/* <Container  className="clt-homepage-container">
        <Row>
          <Col className="clt-section-row-col-pd">
            <div className="">
              <div className="clt-section-8">
                <div classname="rowtest">
                  <div className="d-flex flex-column mb-5">
                    <a href="/#">Réserver</a>
                    <span
                      className="span"
                      onClick={() => setIsContactScreenOpen(true)}
                    >
                      Nous contacter
                    </span>
                    <a href="#"></a>
                    <a href="#"></a>
                    <a href="#"></a>
                    <a href="/#">Connexion</a>
                    <a href="/#">Créer un compte</a>
                    <a href="#"></a>
                    <a href="#"></a>
                    <a href="#"></a>
                    <a href="/#">Centre d’aide</a>
                  </div>
                </div>
                <div>
                  <p>@2023 Château la tournelle</p>
                  <p>
                    <a href="/#">Conditions générales de ventes</a> ·{" "}
                    <a href="/#">Mentions légales</a> ·{" "}
                    <a
                      href="/#"
                      className="cursor-pointer"
                      onClick={() => props.setIsOpen(true)}
                    >
                      Cookie preferences
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}

      <AuthUser />

      <AuthModal
        toggleAuthModal={toggleAuthModal}
        isLoginModal={isLoginModal}
        // variant can have two possible type either first or second. (first: sidebar/user can close it at anytime) (second: full screen/user must authenticate to close the screens)
        variant="first"
        // variant="second"
      />

      <PriceSimulatorModal 
        isShow={isPriceSimulatorModal}
        toggleModal={() => setIsPriceSimulatorModal(false)}
      />

      {isLoggedIn ? (
        <Account
          isOpenSidebar={isAccountDrawer}
          onCloseSidebar={() => closeAccountDrawer()}
        />
      ) : null}

      <Payment
        isPaymentSidebar={isPaymentSidebar}
        onClosePaymentSidebar={() => onClosePaymentSidebar()}
      />

      <Article
        article={selectedArticle}
        isOpenArticle={isOpenArticle}
        onClose={() => setIsOpenArticle(false)}
      />
      
      { open ? <ContactUsScreen isOpen={open} setIsOpen={setOpen} /> : null }

      {isNotificationSidebar ? (
        <Notification
          isNotificationSidebar={isNotificationSidebar}
          onCloseNotificationSidebar={() => onCloseNotificationSidebar()}
        />
      ) : null}

      {isOpenSidebarMyBookingUser ? (
        <MyBooking
          isOpenSidebarMyBookingUser={isOpenSidebarMyBookingUser}
          onCloseOpenSidebarMyBookingUser={() =>
            onCloseOpenSidebarMyBookingUser()
          }
        />
      ) : null}
    </React.Fragment>
  );
};
export default HomeWedding;
