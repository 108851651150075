import React from 'react'
import { cssTransition, toast } from "react-toastify";
import SuccessIcon from "../../assets/img/success_icon.png";
import { Row, Col, Button } from "react-bootstrap";
import InfoIcon from '../../assets/img/info_icon.png';
import "./SidebarMessageContinueConfirm&Pay.css"
import icon_notification_transactionnal_information from '../../assets/img/icon_notification_transactionnal_information.png';

const slide = cssTransition({
	enter: "slide-bottom",
	exit: "slide-top",
});

export const SuccessToast = (message, subDetail) => {
	toast.success(
		<>
			<p className="clt-toast-success-title-dark"><>{message}</></p>
			{subDetail && (
				<p className="clt-toast-success-subtitle-dark">{subDetail}</p>
			)}
		</>

		, {
			autoClose: 4500,
			transition: slide,
			className: "toast-success",
			closeButton: false,
			hideProgressBar: true,
			theme: "dark",
			icon: ({ theme, type }) => (
				<img src={SuccessIcon} alt="success" className="toast-icon" />
			),
		});
};

export const SuccessBookingToast = (picture, name, title, message) => {
	toast.success(
		<>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ marginRight: 20, marginLeft: -5 }}>
					{picture ?
						<div style={{ display: 'flex' }}>
							<div className='clt_toast_message_confirm_booking_badge_container'>
								<img
									src={icon_notification_transactionnal_information}
									className="clt_toast_message_confirm_booking_badge_container_img"
									alt="Image"
								/>
							</div>
							<img src={picture} alt={name} className='clt_toast_message_confirm_booking_profile_img' />
						</div>
						:
						<div style={{ display: 'flex' }}>
							<div className='clt_toast_message_confirm_booking_badge_container'>
								<img
									src={icon_notification_transactionnal_information}
									className="clt_toast_message_confirm_booking_badge_container_img"
									alt="Image"
								/>
							</div>
							<div className="clt_toast_message_confirm_booking_profile_img">
								<div style={{ color: 'white' }}>{name[0].toUpperCase()}</div>
							</div>
						</div>
					}
				</div>
				<div style={{ flex: 1 }}>
					<h3 className="clt_reusable_notification_cardnumber">
						{title}
					</h3>
					<p className="clt_reusable_notification_cardname">
						{message}
					</p>
				</div>
			</div>
		</>

		, {
			autoClose: 4500,
			transition: slide,
			className: "toast-success-booking",
			closeButton: false,
			hideProgressBar: true,
			theme: "light",
			icon: false,
		});
};


export const ErrorToast = (message, subDetail) => {
	toast.error(
		<>
			<p className="clt-toast-success-title"><>{message}</></p>
			{subDetail && (
				<p className="clt-toast-success-subtitle">{subDetail}</p>
			)}
		</>
		
		, {
		autoClose: 4500,
		transition: slide,
		className: "toast-error",
		closeButton: false,
		hideProgressBar: true,
		theme: "colored",
		icon: ({ theme, type }) => (
			<img src={require("../../assets/img/img_section_info.png")} alt="error" className="toast-icon" />
		),
	});
}

export const InfoToast = (message, subDetail) => {
	toast.info(
		<>
			<p className="clt-toast-success-title"><>{message}</></p>
			{subDetail && (
				<p className="clt-toast-success-subtitle">{subDetail}</p>
			)}
		</>
		, {
		autoClose: 4500,
		transition: slide,
		className: "toast-error",
		closeButton: false,
		hideProgressBar: true,
		theme: "dark",
		icon: ({ theme, type }) => (
			<img src={InfoIcon} alt="info" className="toast-icon" />
		),
	});
}