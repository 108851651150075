import React, { useContext, useEffect, useRef, useState } from "react";
import HomePage from "./HomePage/clt_admin_homepage";
import Footer from "./Footer/Footer";
import AdminDashboardStore from "./AdminDashboardStore/AdminDashboardStore";
import AdminDashboardMenu from "./AdminDashboardMenu/AdminDashboardMenu";
import AdminDashboardBooking from "./AdminDashboardBooking/AdminDashboardBooking";
import Message from "../messages/message";
import { Button } from "react-bootstrap";
import "./index.css";
import AddBtn from "../../../assets/img/cancel_icon.png";
import { API_ENDPOINT, IMG_ALT, eventweddingData } from "../../../constants"
import { useScrollDirection } from "../../../utils/ScrollDirection";
import AddAdminBooking from "./AdminDashboardBooking/admin_dash_booking_add/admin_dash_booking_add";
import { dropRight } from "lodash";
import { Helmet } from 'react-helmet';
import { auth } from "../auth/firebase";
import axios from "axios";
import { AuthContext } from "../auth/authContext";
const AdminDashboard = ({ adminNavLinks }) => {

	const [isBookingSidebar, setIsBookingSidebar] = useState(false);
	const [eventType, setEventType] = useState(eventweddingData);
	const { setNewNotification } = useContext(AuthContext);
	// const[isOpenSidebar, setIsOpenSidebar] = useState(false)

	const getNotifications = async (load = false) => {

		const token = await auth.currentUser.getIdToken();
		try {
			const response = await axios.get(API_ENDPOINT + "/admin/notifications",
				{ headers: { Authorization: `Bearer ${token}` } }
			);


			const notificationData = response?.data?.data;

			const notificationLenght = parseInt(response?.data?.count);
			const isNewNotification = localStorage.getItem('newNotificationAdmin');
			localStorage.setItem('unreadNotificationAdmin', notificationLenght);
			const count = parseInt(localStorage.getItem('unreadNotificationAdmin')) - parseInt(localStorage.getItem('newNotificationAdmin'));
			localStorage.setItem('newNotificationCountAdmin', count);
			const newNotificationCount = parseInt(localStorage.getItem('newNotificationCountAdmin'));

			if (newNotificationCount > 0) {
				setNewNotification(true);
			}

		} catch (error) {

		}

	}

	useEffect(() => {
		getNotifications();
		const newNotification = localStorage.getItem('newNotificationAdmin');
		if (newNotification === null) {
			if (newNotification === null) {
				localStorage.setItem('newNotificationAdmin', '0');
			}
		}
	}, [])

	const adminComponent = {
		1: <HomePage />,
		2: <AdminDashboardBooking />,
		3: <Message userType="admin"/>,
		4: <AdminDashboardStore />,
		5: <AdminDashboardMenu />,
	};

	const ref = useRef()

	const scrollDirection = useScrollDirection(ref)

	return (
		<>
			<Helmet>
				<title>Tableau de bord</title>
				<meta name="title" content="SearchTitleAdmin" />
				<meta name="description" content="SearchSubTitleAdmin" />
				<link rel="icon" href="admin_url.png" />
				<meta property="og:title" content="SearchTitleAdmin" />
				<meta property="og:description" content="This is AdminPage description" />
				<meta property="og:image" content="image_url" />
				<meta property="og:url" content="https://example.com/admin-page" />
				<meta name="keywords" content="keyword1, keyword2, keyword3" />
			</Helmet>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
				}}
				ref={ref}
			>
				{adminNavLinks in adminComponent
					? adminComponent[+adminNavLinks]
					: "Coming Soon"}



				{/*
				{adminNavLinks == 2 ?
					<div className='clt_admin_dash_booking_speeddial'>
						<div className='clt_admin_dash_booking_speeddial_container'>
							<button className='speeddial-btn' onClick={() => setIsBookingSidebar(true)}>
								<span className="">+</span>
								<span
									style={{
										width: scrollDirection !== "up" ? "0px" : "140px"
									}}
									className="clt_admin_dash_booking_speeddial_button_text">Ajouter une réservation</span>
							</button>
						</div>
					</div>
								: ''} */}
			</div>

			<AddAdminBooking
				openBookingSidebar={isBookingSidebar}
				onCloseBookingSidebar={() => setIsBookingSidebar(false)}
				eventType={eventType}
				setEventType={(event) => setEventType(event)}
			/>

		</>
	);
};

export default AdminDashboard;
