import React, { useEffect, useState } from 'react';
import './clt_admin_homepage.css';
import NextArrowWhite from "../../../../assets/img/back_arrow_right_white.png";
import { IMG_ALT } from "../../../../constants";
import { API_ENDPOINT } from '../../../../constants';
import axios from 'axios';
import { auth } from '../../auth/firebase';
import moment from 'moment';
import { LoaderDots } from '@thumbtack/thumbprint-react';
import AdminDashboardBookingDetail from '../AdminDashboardBooking/admin_dash_booking_detail/sidebar/AdminDashboardBookingDetail';
import NotificationOffcanvas from '../AdminDashboardNotification/admin_notification'; // Import Notification component
import { useAuth } from "../../auth/authContext";
import Skeleton from 'react-loading-skeleton'; // Import skeleton loader

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setIsBooking] = useState(null);
  const [isOpenSidebarBookingAdmin, setisOpenSidebarBookingAdmin] = useState(false);
  const [isNotificationSidebar, setIsNotificationSidebar] = useState(false); // Notification sidebar state
  const [notifications, setNotifications] = useState([]); // Notification data
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [pendingNotificationsCount, setPendingNotificationsCount] = useState(0);
  const [doneNotificationsCount, setDoneNotificationsCount] = useState(0);
  const { socket } = useAuth(); // Using socket from auth context
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true); // Loading state for notifications

  // Function to open booking sidebar
  const onOpenAdminBookingDetailSidebar = () => {
    setisOpenSidebarBookingAdmin(true);
  };

  // Function to close booking sidebar
  const onCloseOpenSidebarBookingAdmin = () => {
    setisOpenSidebarBookingAdmin(false);
  };

  // Function to open notification sidebar
  const onOpenNotificationSidebar = () => {
    setIsNotificationSidebar(true);
  };

  // Function to close notification sidebar
  const onCloseNotificationSidebar = () => {
    setIsNotificationSidebar(false);
  };

  // Fetch booking details
  useEffect(() => {
    getBooking();
    getNotifications();
    socket.on('notificationSent', () => {
      getNotifications();
    });
  }, []);

  // Fetch booking data
  const getBooking = async () => {
    try {
      setIsLoading(true);
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(API_ENDPOINT + "/booking/dashboard/home", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsBooking(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log('location error: ', error);
      setIsLoading(false);
    }
  };

  // Fetch notifications and group them
  const getNotifications = async () => {
    const token = await auth.currentUser.getIdToken();
    try {
      setIsLoadingNotifications(true); // Set loading state
      const response = await axios.get(`${API_ENDPOINT}/admin/notifications`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const notificationData = response.data.data;
      setNotifications(notificationData);
      groupNotifications(notificationData);
      setIsLoadingNotifications(false); // Data is loaded, disable loading state
    } catch (error) {
      console.log('Error fetching notifications:', error);
      setIsLoadingNotifications(false); // Error occurred, disable loading state
    }
  };

  // Function to group notifications and update the counters
  const groupNotifications = (data) => {
    const newNotifications = data.filter(item => !item.is_read_admin);
    const pendingNotifications = data.filter(item => item.is_read_admin && !item.is_read_done);
    const doneNotifications = data.filter(item => item.is_read_done);
    
    // Adjust pending notifications count by subtracting 21
    const adjustedPendingNotificationsCount = Math.max(0, pendingNotifications.length - 21);

    setNewNotificationsCount(newNotifications.length);
    setPendingNotificationsCount(adjustedPendingNotificationsCount); // Set the adjusted count
    setDoneNotificationsCount(doneNotifications.length);
  };

  // Handle notification read status change
  const handleIsRead = async (item, is_read_done) => {
    try {
      const token = await auth.currentUser.getIdToken();
      await axios.patch(`${API_ENDPOINT}/notifications/${item.id}`, {
        is_read_admin: true,
        is_read_done
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      getNotifications(); // Refresh notifications after update
    } catch (error) {
      console.log('Error updating notification status:', error);
    }
  };
  const updateCounters = () => {
    getNotifications(); // This will refresh notification data
  };

  useEffect(() => {
    getNotifications(); // Fetch notifications on load
  }, []);

  return (
    <div className="clt_admin_dashboard-wrapper">
      {/* Wrapping both sections in the general flex direction column */}
      <div className="clt_admin_dashboard_content">
        <div className='clt_admin_admin-dash-parent2edzzed'>
          <section className='clt_admin_admin-dash-div_4'>
            <section className='clt_admin_dashboard-text-container'>
            </section>
          </section>
        </div>
        <div className='clt_admin_admin-dash-parent2'>
          <section className='clt_admin_admin-dash-div_5'>
            <section className='clt_admin_dashboard-text-containercdcsdc'>
              <h3 className='czededzedze'>Accueil</h3>
              <h3 className='czededzeddezdezze'>Tableau de bord</h3>
            </section>
          </section>
        </div>
        <div className='clt_admin_admin-dash-parent'>
          <section className='clt_admin_admin-dash-div'>
            <section className='clt_admin_dashboard-text-container'>
              <h3 className='clt_admin_dash-text-heading'>Bienvenue sur le tableau de bord</h3>
              <section className='clt_admin_next-booking' onClick={() => { if (booking) { onOpenAdminBookingDetailSidebar(); } } }>
                {isLoading ? (
                  <section className='clt_admin_next-booking-text-parent'>
                    <span><LoaderDots /></span>
                  </section>
                ) : booking?.status === 'Ongoing' ? (
                  <section className='clt_admin_next-booking-text-parent'>
                    <span>Réservation en cours</span>
                    <span>{`${booking?.User?.firstName}, ${moment(booking?.ChildTimeSlot?.startDate).format('DD MMM')}`}</span>
                  </section>
                ) : booking?.status === 'comming' ? (
                  <section className='clt_admin_next-booking-text-parent'>
                    <span>Prochaine réservation</span>
                    <span>{`${moment(booking?.ChildTimeSlot?.startDate).format('DD MMM')}`}</span>
                  </section>
                ) : (
                  <section className='clt_admin_next-booking-text-parent'>
                    <span>Prochaine réservation</span>
                    <span>{`Aucune réservation à venir`}</span>
                  </section>
                )}
                <span className='clt_admin_opacity'>
                  <img src={NextArrowWhite} className="clt_admin_arrow" alt={IMG_ALT} />
                </span>
              </section>
            </section>
          </section>

          <section className='clt_admin_admin-dash-div_2'>
            <div className="clt_admin_hr"></div>
          </section>

          {/* Contact request section with dynamic counters */}
          <section className='clt_admin_admin-dash-div_3'>
            <section className='clt_admin_dashboard-text-containerrgregr'>
              <div className="clt_admin_dashboard_contact-header">
                <h3 className='clt_admin_dash-text-headingeczczec'>Demande de contact</h3>
                <p className="clt_admin_dash-booking-show" onClick={onOpenNotificationSidebar}>Afficher</p>
              </div>

              <section className='clt_admin_contact-stats-row'>
                <div className="clt_admin_contact-stat">
                  <h4>Nouvelle demande</h4>
                  <p>{isLoadingNotifications ? <Skeleton width={60} /> : newNotificationsCount}</p> {/* Skeleton while loading */}
                </div>
                <div className="clt_admin_contact-stat">
                  <h4>En attente</h4>
                  <p>{isLoadingNotifications ? <Skeleton width={60} /> : pendingNotificationsCount}</p> {/* Skeleton while loading */}
                </div>
                <div className="clt_admin_contact-stat">
                  <h4>Traitée</h4>
                  <p>{isLoadingNotifications ? <Skeleton width={60} /> : doneNotificationsCount}</p> {/* Skeleton while loading */}
                </div>
              </section>
            </section>
          </section>

          {/* Booking sidebar */}
          {isOpenSidebarBookingAdmin ? (
            <AdminDashboardBookingDetail
              onCloseOpenSidebarBookingAdmin={onCloseOpenSidebarBookingAdmin}
              isOpenSidebarBookingAdmin={isOpenSidebarBookingAdmin}
              data={booking} />
          ) : null}

          {/* Notification sidebar */}
          {isNotificationSidebar && (
            <NotificationOffcanvas
              isNotificationSidebar={isNotificationSidebar}
              onCloseNotificationSidebar={onCloseNotificationSidebar}
              updateCounters={updateCounters} // Pass updateCounters here
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
