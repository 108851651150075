import React, { useEffect, useState,useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuth, AuthContext} from "../../screens/auth/authContext";
import { useMutation, useQueryClient, useQuery } from "react-query";
import {
	PROFILE_KEY,
	uploadProfilePicRequest,
} from "../../screens/account/api";
import profileImg from '../../../assets/img/admin_icon_menu_1.png';

const SectionProfile = ({ title,addedText, subTitle, handleClick, text, subText, data}) => {
  const { user } = useAuth();
  const {profilePictureUrl}=useContext(AuthContext);

  const [personalAccount, setPersonalAccount] = useState({
		// first_name: data?.User?.firstName,
		// name: data?.User?.lastName,
		// email: data?.User?.email,
		// phone: data?.User?.phone,
		// billing_address: {
		// 	address: data?.User?.billingAddress ?? "",
		// 	city: data?.User?.billingCity ?? "",
		// 	zip_code: data?.User?.billingZipCode ?? "",
		// 	state_region: data?.User?.billingState ?? "",
		// 	country: data?.User?.billingCountry ?? "",
		// },
		// profile_picture:  data?.User?.profilePictureUrl ?? null,
		profile_picture:  profilePictureUrl ?? null,
	});

	useEffect(() => {
		if (user) {
			setPersonalAccount({
				// first_name: user?.firstName,
				// name: user?.lastName,
				// email: user?.email,
				// phone: user?.phone,
				// billing_address: {
				// 	address: user?.billingAddress ?? "",
				// 	city: user?.billingCity ?? "",
				// 	zip_code: user?.billingZipCode ?? "",
				// 	state_region: user?.billingState ?? "",
				// 	country: user?.billingCountry ?? "",
				// },
				profile_picture: profilePictureUrl ?? null,
				// profile_picture: user?.profilePictureUrl ?? null,
			});
		}
	}, [user,profilePictureUrl]);
  return (
    <Row>
      <Col className="clt-booking-cp-div4">
       
        {title && (
          <h2 className="clt-booking-cp-div4-title">{title}</h2>
        )}
        {subTitle && (
          <h2 className="clt_admin_dash_timeslot_edit_box_subheading2">{subTitle}</h2>
        )}
        {/* <div
          className="d-flex justify-content-between align-items-center clt-booking-cp-div4-inner"
          onClick={handleClick}
        >
          <div>
          <div className="d-flex flex-column justify-content-center align-items-start ">
            <h2>{text}</h2>
            <p>John Doe</p>
          </div>
            <i className="fa-solid fa-chevron-right"></i>
        </div>
        </div> */}

        <div
            className="clt-booking-cp-div1Box clt-booking-hoverActive"
            onClick={handleClick}
          >
            <div className="d-flex align-items-center justify-content-between flex-row w-100">
            <div className="clt-booking-div1" style={{marginLeft:0,width:'calc(100% - 10px)'}}>
                {/* <div className="clt-booking-innner-div1 clt-booking-bgImg1"></div> */}
                 {/* <div className="clt-account-screen-inner-div-img"> */}
                 <div className="clt-account-screen-inner-div-img clt-booking-innner-div1 ">
          {/* <div
            className="inner-div"
            style={
              personalAccount.profile_picture && {
                backgroundImage: `url(${personalAccount.profile_picture})`,
              }
            }
          >
            {!personalAccount.profile_picture && (
              <span>{personalAccount.first_name?.charAt(0)}</span>
            )}
 </div> */}
          {text === 'Professional profile' || text==='Profil professionnel' || text==='Professional profil' || text==='Profil professionnel'? (
            <div className="inner-div" style={{ color: 'white' }}>
              {subText && subText.props.children && subText.props.children.length > 0
              ? subText.props.children.charAt(0)
              : 'R'}
            </div>
          ) : text === 'Profile' || text==='Profil' ? (
            <div>
              <img src={profileImg}/>
            </div>
          ) : text === 'Profil Personnel'|| text === 'Profil personnel' || text==='Profil personnelle' || text==='Profil Personnel' ? (
            <div
            className="inner-div"
              style={
                personalAccount.profile_picture
                  ? { backgroundImage: `url(${personalAccount.profile_picture})` }
                  : null
              }
            >
              {!personalAccount.profile_picture && (
                <span>{subText.props.children.charAt(0)}</span>
                // <span>{personalAccount.first_name?.charAt(0)}</span>
              )}
            </div>
          ) : null} 



        </div>
                <div className="inner-div-1 clt-booking-cp-div3-text" style={{marginLeft:0,width:'calc(100% - 40px)'}}>
                <h2>{addedText}{text}</h2>
                {/* <p>{subText}</p> */}
                <p style={{overflow:'hidden',whiteSpace:'nowrap',overflowWrap:'break-word'}}>{subText}</p>
                </div>
              </div>
              <div>
              <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
      </Col>
    </Row>
    
  );
};

export default SectionProfile;
