import React, { useState, useRef, useEffect, useContext } from "react";
import { Offcanvas, Row, Col } from "react-bootstrap";
import { Slider } from '@mui/material';
import "../screens/auth/auth-modal.css";
import "./priceSimulatorModel.css";

import CancelIcon from '../../assets/img/cancel_icon.png';
import BackArrow from "../../assets/img/back_arrow.png";
import iconbar3active from "../../assets/img/icon-bar-3-active.png";
import ConfirmPay1 from "../../assets/img/icon-event-wedding-detail.png";
import ConfirmPayProfilePicture1 from "../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../assets/img/confirm_pay_2.png";
import { IMG_ALT } from "../../constants";
import SectionTop from "./booking/SectionTop";
import SectionContactRequestEvent from "./contactrequest/SectionContactRequestEvent";
import SectionContactRequestDate from "./contactrequest/SectionContactRequestDate";
import priceSimulatorObject from "./priceSimulatorModelJson";
import CustomHr from "./CustomHr";

export default function PriceSimulatorModal({
    isShow,
    toggleModal,
}) {

    const [eventType, setEventType] = useState('Anniversaire');
    const [eventIcon, setEventIcon] = useState(iconbar3active);
    const [selectedMonth, setSelectedMonth] = useState('Janvier');
    const [selectedYear, setSelectedYear] = useState('2023');
    const [sliderValue, setSliderValue] = useState(Object.keys(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'])[0]);
    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        console.log("changing")
        console.log(eventType)
        setCalculatedPrice(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'][`${sliderValue}`]);
        var count = Object.keys(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price']).length;
        setMaxValue(count)
        console.log("max Val "+ sliderValue);
        console.log("max Val 2");
        console.log(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price']);
        console.log(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price']);
    },[sliderValue, selectedMonth, selectedYear, eventType])


    const changeSliderValue = (val) => {
        const objKey = Object.keys(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'])[val-1];
        console.log(objKey);

        console.log(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'][`${objKey}`]);
        console.log('awaa etype'+eventType);
        setSliderValue(objKey);
        // setCalculatedPrice(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'][`${objKey}`]);
        console.log('awaa nir'+objKey);
        console.log(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price']);
        console.log(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'][`${objKey}`]);
        console.log(calculatedPrice);
        // return  priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'].length;
    }

    const changeEvent = (eventtype) =>{
        const objKey = Object.keys(priceSimulatorObject[eventtype]['data'][`${selectedMonth}-${selectedYear}`]['price'])[0];
        // console.log(objKey);
        //
        // console.log(priceSimulatorObject[eventType]['data'][`${selectedMonth}-${selectedYear}`]['price'][`${objKey}`]);
        // console.log('awaa etype'+eventType);
        setSliderValue(objKey);
        console.log(eventtype);
        setEventType(eventtype);
        // changeSliderValue(1);
    }

    

	return (
		<Offcanvas
			placement={"bottom"}
			show={isShow}
			onHide={toggleModal}
			className={`clt_price_simulator_offcanvas`}
		>
            <Offcanvas.Header className="justify-content-start clt_price_simulator_offcanvas_header">
				<button
					type="button"
					onClick={toggleModal}
					className={`btn-close btn-back shadow-none d-`}
					aria-label="Close"
				>
                    <img src={BackArrow} className="" alt={IMG_ALT} />
					{/* <img style={{ width: '12px' }} src={CancelIcon} className="" alt={IMG_ALT} /> */}
				</button>
				<Offcanvas.Title>Prix</Offcanvas.Title>
			</Offcanvas.Header>
            <Offcanvas.Body className="clt_auth_user_offcanvas_body variant_2nd">
                <div className="pfr_loginModal_Body">
                    <Row>
                        <Col className="">
                            <div className="border_bottomk_ight">
                                <div className="clt-price-simulator-section-1">
                                    <h2>Combien coûte une réservation ?</h2>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <CustomHr colClass="clt-section-hr-pd" hrClass="p-0 m-0 clt-hr" />
                    <Row>
                        <Col className="">
                            <div className="clt-price-simulator-section-2 border_light">
                                <SectionTop
                                    heading={"Voir le prix d'une réservation"}
                                    subHeading={"Sélectionner votre type d'événement, vos dates puis définissez la durée de votre réservation pour connaître le prix"}
                                    image={ConfirmPayProfilePicture1}
                                    title="Chateau Latournelle"
                                />
                                <SectionContactRequestEvent
                                    image={eventIcon}
                                    subText={`Select Event`}
                                    buttonText="Modifier"
                                    IsEventButtonDropdown={true}
                                    setEventType={changeEvent}
                                    // event={currentEvent}
                                    eventType={eventType}
                                    handleRequestEdit={(val) => { setEventIcon(val.icon)}}
                                />
                                
                                <SectionContactRequestDate
                                    image={ConfirmPay2}
                                    subText={`Select date`}
                                    IsDateButtonDropdown={true}
                                    selectedMonth={selectedMonth}
                                    setSelectedMonth={setSelectedMonth}
                                    selectedYear={selectedYear}
                                    setSelectedYear={setSelectedYear}
                                    handleRequestEdit={() => {}}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: 30,
                                        marginBottom: 0 
                                    }}
                                >
                                    <div className="clt_price_simulator_detail_price_div">
                                        <h1 className="clt_price_simulator_detail_price" style={{ textAlign: 'center' }}>{`${calculatedPrice}€`}</h1>
                                        <h1 className="clt_price_simulator_detail_price2" style={{ textAlign: 'center' }}>TTC</h1>
                                    </div>
                                    <Slider
                                        aria-label="price"
                                        defaultValue={0}
                                        min={1}
                                        max={maxValue}
                                        sx={{
                                            color: '#242424'
                                        }}
                                        onChange={(e, val) => changeSliderValue(val)}
                                    />
                                    <p  style={{ textAlign: 'center', marginTop: 10 }}>Pour une réservation de <b>{`${sliderValue}`}</b></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Offcanvas.Body>
		</Offcanvas>
	);
}

