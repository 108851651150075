import React from 'react'

const ChatComponentJustChatting = ({detail}) => {
  return (
    <p className="clt_messages_container_message_text">
      {detail}
    </p>
  )
}

export default ChatComponentJustChatting