export const getArticlesByName = (allArticles, selectedArticles) => {
  if (selectedArticles.length === 0) return allArticles;
  // Create a set from array2 for faster lookup
  const namesSet = new Set(selectedArticles);

  // Use the filter method to filter articles in array1
  const filteredArticles = allArticles.filter((article) => {
    return namesSet.has(article.name);
  });

  return filteredArticles;
};
