import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import icon from "../../../../../../assets/img/admin_icon_dragdrop_1.png";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const contentStyle = {
  backgroundColor: "#fff",
  borderRadius: "50px",
};
const contentInnerStyle = {
  display: "flex",
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    className="clt_admin_dash_manage_access_card3dot"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
      console.log(e);
    }}
  >
    {children}
    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
  </Link>
));

function PathList({
  list,
  openEditPath,
  openManageSubpath,
  deletePath,
  onDragEndEvent,
}) {
  return (
    <Row className="clt_help_article_offcanvas_body">
      <Col className="p-0">
        <Row>
          <Col>
            <h2 className="clt_admin_dash_timeslot_event_detail_heading">
              Manage Path
            </h2>
          </Col>
        </Row>
        <div
          style={{
            marginTop: "-18px",
          }}
        >
          <Row className="clt-detail-section-6-offcanvas-data-row m-0 mt-0">
            <DragDropContext onDragEnd={(result) => onDragEndEvent(result)}>
              <Droppable droppableId="events">
                {(provided, snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {list?.map((content, i) => (
                        <Draggable
                          key={content.id}
                          draggableId={content.id + ""}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            return (
                              <Row
                                key={i}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  userSelect: "none",
                                  backgroundColor: snapshot.isDragging
                                    ? "#fff"
                                    : "transparent",
                                  border: snapshot.isDragging
                                    ? "1px solid #dedddd"
                                    : "none",
                                  borderRadius: snapshot.isDragging
                                    ? "10px"
                                    : "0",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <>
                                  <Col>
                                    <Row>
                                      <Col
                                        className="d-flex justify-content-between align-items-center clt-detail-section-6-offcanvas-data-row-col cursor-pointer"
                                        onClick={() => {}}
                                        style={contentStyle}
                                      >
                                        <div
                                          className="w-80"
                                          style={contentInnerStyle}
                                        >
                                          <img
                                            src={icon}
                                            alt="icon"
                                            style={{
                                              marginTop: "2px",
                                              height: "8px",
                                              width: "12px",
                                            }}
                                          />

                                          <h2 style={{ margin: "0 16px" }}>
                                            {i + 1}
                                          </h2>
                                          <div>
                                            <h2>{content?.path}</h2>
                                            <p
                                              className="clt_admin_dash_invoice_cardname"
                                              style={{
                                                margin: 0,
                                                padding: 0,
                                                marginLeft: "0px",
                                              }}
                                            >
                                              {content?.article?.name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end clt_admin_dash_manage_access_cards3dots">
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              as={CustomToggle}
                                            />
                                            <Dropdown.Menu
                                              size="sm"
                                              title=""
                                              className="pfr_dropdown_menu"
                                            >
                                              <Dropdown.Item
                                                className="clt_admin_dash_manage_access_cards3dots_options"
                                                onClick={() =>
                                                  openManageSubpath(content)
                                                }
                                              >
                                                Manage subpath
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                className="clt_admin_dash_manage_access_cards3dots_options"
                                                onClick={() =>
                                                  openEditPath(content)
                                                }
                                              >
                                                Edit path
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                className="clt_admin_dash_manage_access_cards3dots_options"
                                                onClick={() =>
                                                  deletePath(content)
                                                }
                                              >
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  {i !== list.length - 1 && (
                                    <hr class="p-0 m-0 clt-hr" />
                                  )}
                                </>
                              </Row>
                            );
                          }}
                        </Draggable>
                      ))}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default PathList;
