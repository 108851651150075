import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";

import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import CustomHr from "../../../../../reusable/CustomHr";

function EditCategory({ isOpen, onClose, category, updateCategory }) {
  const [categoryName, setCategoryName] = useState("");
  const [categoryInfo, setCategoryInfo] = useState({
    title: "",
    subtitle: "",
  });

  useEffect(() => {
    setCategoryName(category?.name || "");
    setCategoryInfo({
      title: category?.title,
      subtitle: category?.subtitle,
    });
  }, [category]);

  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      backdrop={false}
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Edit category of helpcenter</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col className="clt-admin-event-info-input-div">
            <h2
              class="clt_admin_dash_menu_container_name"
              style={{ fontSize: "20px", color: "#000", fontWeight: "bold" }}
            >
              Category name
            </h2>
            <p
              class="clt_admin_dash_menu_container_name"
              style={{ fontSize: "15px", color: "#000" }}
            >
              The category will appear in help center and will have its own
              search result, article and path
            </p>
            <Form.Floating className="pfr_inputFloat">
              <Form.Control
                id="floatingInput124224"
                type="text"
                placeholder="Description"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
              <label htmlFor="floatingInput124224">Name</label>
            </Form.Floating>
          </Col>
        </Row>
        <CustomHr colClass="clt-booking-hr-pd pb-0" hrClass="p-0 m-0 clt-hr" />
        <Row>
          <Col className="clt-admin-event-info-input-div">
            <h2
              class="clt_admin_dash_menu_container_name"
              style={{
                fontSize: "20px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Title and Subtitle
            </h2>

            <Form.Floating className="pfr_inputFloat">
              <Form.Control
                id="floatingInput1242249"
                type="text"
                placeholder="Title"
                value={categoryInfo.title}
                onChange={(e) =>
                  setCategoryInfo((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />
              <label htmlFor="floatingInput1242249">Title</label>
            </Form.Floating>
            <Form.Floating
              className="pfr_inputFloat"
              style={{ marginTop: "20px" }}
            >
              <Form.Control
                id="floatingInput1242242"
                type="text"
                placeholder="Subtitle"
                value={categoryInfo.subtitle}
                onChange={(e) =>
                  setCategoryInfo((prev) => ({
                    ...prev,
                    subtitle: e.target.value,
                  }))
                }
              />
              <label htmlFor="floatingInput1242242">Subtitle</label>
            </Form.Floating>
          </Col>
        </Row>

        <div className="clt_payment_footer">
          <Button
            className="shadow-none clt_payment_header_button_black"
            disabled={categoryName === ""}
            onClick={() =>
              updateCategory(category.id, {
                ...category,
                name: categoryName,
                title: categoryInfo.title,
                subtitle: categoryInfo.subtitle,
              })
            }
          >
            Save
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default EditCategory;
