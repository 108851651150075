import React, { useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import "../../auth-modal.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { API_ENDPOINT } from "../../../../../constants";
import { Actions, useAuth } from "../../authContext";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import { ErrorToast, SuccessToast } from "../../../../reusable/Toast";
import { LoaderDots } from '@thumbtack/thumbprint-react'; // Importez le composant LoaderDots

const PhoneSignin = ({ createAccount, handleContinue }) => {
	const { phone: userphone, dispatch } = useAuth();
	const [phone, setPhone] = useState(userphone);
	const [disabled, setDisabled] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const recaptchaRef = useRef(null);

	const handleClick = async () => {
		setDisabled(true);
		setLoading(true);

		if (phone === "") {
			ErrorToast("Téléphone manquant", "Veuillez ajouté l'information manquante");
			setDisabled(false);
			setLoading(false);
			return;
		}

		dispatch({ type: Actions.SET_DATA, payload: { phone } });
		dispatch({ type: Actions.END_REGISTRATION });

		try {
			const url = API_ENDPOINT + "/auth/check";
			const checkUserResponse = await axios.post(url, {
				phone,
			});

			if (!checkUserResponse.data.userExists) {
				createAccount();
				SuccessToast("Bienvenue", "Commençons la création de votre compte.");
				setDisabled(false);
				setLoading(false);
				return;
			}

			if (!recaptchaRef.current) {
				setDisabled(false);
				setLoading(false);
				recaptchaRef.current = new RecaptchaVerifier(
					"recaptcha-container",
					{
						size: "invisible",
					},
					auth
				);
			}

			const verifier = await signInWithPhoneNumber(auth, phone, recaptchaRef.current);
			handleContinue(phone, verifier);

			SuccessToast("Code envoyé", "Un code à 6 chiffres est envoyé sur votre téléphone.");

		} catch (error) {
			if (error instanceof Error) {
				if (error.code === 'auth/captcha-check-failed') {
					ErrorToast("Erreur de vérification", "Une erreur a empêché le traitement de votre demande, veuillez vérifier les informations saisies et essayer à nouveau.");
				} else if (error.code === 'auth/too-many-requests') {
					ErrorToast("Trop de tentatives de connexion", "Le nombre de demandes de connexion que vous avez fait est trop élevé, veuillez réessayer plus tard");
				} else if (error.code === 'auth/requires-recent-login') {
					ErrorToast("Session expirée", "Il semble que vous ayez mis trop de temps à réaliser votre demande, veuillez réessayer à nouveau");
				} else if (error.code === 'auth/user-token-expired') {
					ErrorToast("Session expirée", "Il semble que vous ayez mis trop de temps à réaliser votre demande, veuillez réessayer à nouveau.");
				} else {
					ErrorToast(error.message);
				}
			} else {
				ErrorToast(error.message);
			}
			setDisabled(false);
			setLoading(false);
		};
	}

	return (
		<>
			<div className="pfr_loginModal_Body">
				<Row>
					<Col>
						<p className="pfr_loginModalH2">
							Entrez un numéro de téléphone pour vous connecter ou créer un compte
						</p>
					</Col>
				</Row>
			</div>

			<div className="pfr_loginModal_Body">
				<Row>
					<Col className="pfr_loginModalTelInputDiv">
						<div className="pfr_loginModalTelInputInnerDiv">
							<PhoneInput
								// placeholder="Numéro de téléphone"
								international
								withCountryCallingCode
								defaultCountry="FR"
								countryCallingCodeEditable={false}
								value={phone}
								onCountryChange={(crt) => console.log("crt ", crt)}
								focusInputOnCountrySelection
								autoFocus
								onChange={(val) => setPhone(val)}
								className="pfr_loginModalTelInput"
							/>
							<span className="pfr_loginModalTelInputLabel toFloat">
								Numéro de téléphone
							</span>
						</div>
					</Col>
				</Row>
				<div id="recaptcha-container"></div>
				

				<Row>
					<Col>
						<Button
							disabled={disabled || isLoading}
							className={`pfr_loginModalBtn shadow-none ${disabled ? 'btn-opacity' : ''}`}
							onClick={handleClick}
						>
							{isLoading ? (
								<LoaderDots />
							) : (
								"Continuer"
							)}
						</Button>
					</Col>
				</Row>

			</div>
		</>
	);
};

export default PhoneSignin;
