import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import "../account.css";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { API_ENDPOINT, IMG_ALT } from "../../../../constants";
import ReactCodeInput from "react-verification-code-input";
import axios, { AxiosError } from "axios";
import { ErrorToast, SuccessToast } from "../../../reusable/Toast";
import { auth } from "../../auth/firebase";
import CustomButton from "../../../reusable/CustomButton"; // Import the CustomButton component
const COUNTER = 30;

const AccountInputEmail = ({
	isSidebar,
	onCloseSidebar,
	title,
	inputData,
	onUpdateData,
}) => {
	const [inputValue, setInputValue] = useState(inputData);
	const [inputScreens, setInputScreens] = useState({
		inputScreen: true,
		confirmScreen: false,
	});

	const [token, setToken] = useState("")
	const [code, setCode] = useState('')
	const [isCompleted, setIsCompleted] = useState(false)

	const [counter, setCounter] = useState(COUNTER);

	const counterRef = useRef(COUNTER);
	useEffect(() => {
		setCounter(COUNTER);
		counterRef.current = COUNTER
	}, []);


	useEffect(() => {
		const timer = setInterval(() => {
			if (inputScreens.confirmScreen) {
				console.log('confirm screen')
				if (counterRef.current === 0) {
					// console.log(counter)
					counterRef.current = -1
					setIsCompleted(true)
					clearInterval(timer);
					return;
				}
				if (!isCompleted) {
					console.log('counter')
					counterRef.current--;
					setCounter(c => c - 1);
				} else {
					setCounter(COUNTER);
					counterRef.current = COUNTER;
				}
			};
		}, 1000);
		return () => clearInterval(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCompleted, inputScreens]);


	const handleInputChange = (event) => {
		inputData.value = event.target.value;
		setInputValue((prevState) => ({
			...prevState,
			value: event.target.value,
		}));
	};
	const handleSave = async () => {
		if (code.length < 6) {
			ErrorToast("Code incorrect", "Veuillez ajouter les 6 chiffres du code.");
			
			return;
		}
		try {
			const firebaseToken = await auth.currentUser.getIdToken()
			const updateEmail = await axios.post(API_ENDPOINT + `/auth/verify-update-email/${token}`, { code }, {
				headers: {
					Authorization: `Bearer ${firebaseToken}`
				}
			})
			console.log({ data: updateEmail.data.data });
			SuccessToast(updateEmail.data.message);
			onUpdateData({ name: inputData.name, value: updateEmail.data.data.email })
			setInputScreens((prevState) => ({
				...prevState,
				inputScreen: true,
				confirmScreen: false,
			}));
			setInputValue({});
			onCloseSidebar();
		} catch (error) {
			if (error instanceof AxiosError) {
				ErrorToast(error.response.data.message);
			} else {
				ErrorToast(error.message);
			}
			// onCloseSidebar();
		}

	};

	const handleBack = (from, to) => {
		setInputScreens((prevState) => ({
			...prevState,
			[from]: !prevState[from],
			[to]: !prevState[to],
		}));
	};

	const sendCodeAgain = async () => {
		if (!isCompleted) return;

		const token = await auth.currentUser.getIdToken();
		// console.log(token);
		try {
			console.log(inputData.value);
			const updateEmailRequest = await axios.post(
				API_ENDPOINT + "/auth/update-email-request",
				{
					email: inputData.value,
				}, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
			);
			console.log('I need this thing', updateEmailRequest);
			setToken(updateEmailRequest.data.data.token)
			SuccessToast('Nouveau code envoyé')
			setIsCompleted(false)
		} catch (error) {
			if (error instanceof AxiosError) {
				ErrorToast(error.response.data.message);
			} else {
				ErrorToast(error.message);
			}
			// onCloseSidebar();
		}

	}

	const handleEmail = async () => {
		const token = await auth.currentUser.getIdToken();
		try {
			console.log(inputData.value);
			const updateEmailRequest = await axios.post(
				API_ENDPOINT + "/auth/update-email-request",
				{
					email: inputData.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			console.log('neeeeed thisssssssssssssss', updateEmailRequest)
			setToken(updateEmailRequest.data.data.token)
			SuccessToast(updateEmailRequest.data.message)
			setInputScreens((prevState) => ({
				...prevState,
				inputScreen: false,
				confirmScreen: true,
			}));

		} catch (error) {
			if (error instanceof AxiosError) {
				ErrorToast(error.response.data.message);
			} else {
				ErrorToast(error.message);
			}
			// onCloseSidebar();
		}

	};

	return (
		<Offcanvas
			placement="end"
			className="clt_payment_offcanvas"
			show={isSidebar}
			onHide={onCloseSidebar}
		>
			<Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
				<>
					{inputScreens.inputScreen && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={onCloseSidebar}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>{title}</Offcanvas.Title>
							</div>
						</React.Fragment>
					)}
					{inputScreens.confirmScreen && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={() => handleBack("confirmScreen", "inputScreen")}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>Verification code</Offcanvas.Title>
							</div>
						</React.Fragment>
					)}
				</>
			</Offcanvas.Header>
			<Offcanvas.Body className={`clt_payment_offcanvas_body`}>
				<>
					{inputScreens.inputScreen && (
						<>
							<div className="">
								<Row>
									<Col
										lg={12}
										md={12}
										sm={12}
										xs={12}
										className="pfr_inputFloatDiv"
									>
										<Form.Floating className="pfr_inputFloat">
											<Form.Control
												id="floatingInput1"
												name={inputData.name}
												type={inputData.type}
												placeholder={inputData.placeholder}
												value={inputData.value}
												onChange={(event) => handleInputChange(event)}
											/>
											<label htmlFor="floatingInput1">
												{inputData.placeholder}
											</label>
										</Form.Floating>
									</Col>
								</Row>
								<div className="pfr_payment_space_bottom" />
							</div>
							<div className="clt_payment_footer">
								<Button
									className="shadow-none clt_payment_header_button_black"
									disabled={inputData.value === "" ? true : false}
									onClick={handleEmail}
								>
									Enregistrer
								</Button>
							</div>
						</>
					)}
					{inputScreens.confirmScreen && (
						<>
							<div className="pfr_loginModalVerify">
								<div className="pfr_loginModalVerifyDiv">
									<Row>
										<Col>
											<h2 className="pfr_loginModalH2">
												Entrez le code envoyé à {inputData.value}
											</h2>
										</Col>
									</Row>
									<Row>
										<Col>
											<ReactCodeInput
												type="number"
												fields={6}
												onChange={(val) => setCode(val)}
												onComplete={(val) => console.log(val)}
												autoFocus={true}
												// loading
												className="pfr_signUpNumberVerify"
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<div style={{ opacity: !isCompleted ? 0.6 : 1 }} className="d-flex justify-content-start clt-account-timer-msg pfr_loginForgotPassDiv">
											<CustomButton
												onClick={sendCodeAgain}
												disabled={!isCompleted}
												text={!isCompleted ? `Je n'ai pas reçu de code (0:${counter})` : `Je n'ai pas reçu de code`}
											/>
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className="clt_payment_footer">
								<Button
									className="shadow-none clt_payment_header_button_black"
									onClick={handleSave}
								>
									Confirm
								</Button>
							</div>
						</>
					)}
				</>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

export default AccountInputEmail;
