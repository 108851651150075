import React from "react";
import { Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
const SectionTop = ({ image, heading, subHeading, title, subTitle, imageSize, subTitle1, subTitle2 }) => {
  return (
    <Row className={`${heading && "clt-booking-mc"}`}>
      <Col>
        {heading && <h2 className="clt-booking-cp-div5-title">{heading}</h2>}
        {subHeading && <p className="aaclt_admin_dash_timeslot_edit_box_subheading5421">{subHeading}</p>}
        <div className={`d-flex justify-content-start align-items-center ${imageSize !== "small" ? "clt-booking-cp-div2" : ""}`}>
          {imageSize === "small" ?
            <div style={{ border: "1px solid #dedddd", borderRadius: "9px" }}><img src={image} style={{ width: "30px", height: "30px", margin: '8px', borderRadius: "12px" }} alt={IMG_ALT} /></div>
            : <img src={image} alt={IMG_ALT} />
          }
          <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div2-text">
            {title && (<h2>{title}</h2>)}
            {subTitle && (<p>{subTitle}</p>)}
            {subTitle1 && (<p>{subTitle1} · {subTitle2}€</p>)}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionTop;
