import React from "react";
import { Col, Row } from "react-bootstrap";
import IconPending from "../../assets/img/icon_pending.png";
import { IMG_ALT } from "../../constants";
const SectionInformationMessageRequestPending = (props) => {
  return (
    <Row className="clt-detail-section-6">
      <Col>
        <Row>
          <Col>
            <div className="d-flex justify-content-start align-items-center clt-booking-cp-div1-request-pending ">
              <img src={IconPending} alt={IMG_ALT} />
              <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div1-text">
                <h2>{props.innerTitle}</h2>
                <p>{props.innerSubTitle}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SectionInformationMessageRequestPending;
