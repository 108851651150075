import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import { IMG_BASEURL } from "../../../constants";
const SectionEvent = ({ image, subText, setIsEventSidebar, buttonText, event }) => {
  const [imageUrl, setImageUrl] = useState(image)
  useEffect (() => {
    if(event) {
      const url = event?.icon
      setImageUrl(url)
    }
  },[event])
  return (
    <Row className="clt-booking-mb">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={imageUrl} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Évenement</h2>
              <p>{subText}</p>
            </div>
            {setIsEventSidebar && (
              <Button className="shadow-none" onClick={setIsEventSidebar}>
                <span>{buttonText}</span>
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionEvent;
