// import
import React, { useEffect, useRef, useState } from 'react'
import './AdminDashboardBooking.css'
import NextArrow from "../../../../assets/img/back_arrow_right.png";
import SidebarSearchBooking from '../../../reusable/search/Search - Booking - Sidebar/AdminDashboardBookingSearch';
import SearchBookingAdmin from '../../../reusable/search/Search - Booking/[SECTION] Admin - Search - Booking';
import AdminDashboardBookingDetail from './admin_dash_booking_detail/sidebar/AdminDashboardBookingDetail';
import { Tab, Tabs, Dropdown } from 'react-bootstrap';
import { IMG_ALT } from "../../../../constants"
import AdminDashboardBookingRequestSlider from './admin_dash_booking_request/[SECTION] Admin - Booking Request'
import { useDispatch } from 'react-redux';
import { getBookings } from '../../../../redux/actions/booking';
import { getEvents } from '../../../../redux/actions/avent';
import { userlist } from "../../../reusable/search/Search - API/[API] Seach a user or invite.js";
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { useAuth } from '../../auth/authContext';
import axios from 'axios';
import { auth } from '../../auth/firebase';
import { ErrorToast, SuccessToast } from '../../../reusable/Toast';
import { API_ENDPOINT } from '../../../../constants';
function Items({ currentItems, setBookingSidebarDetail, onOpenAdminBookingDetailSidebar }) {

    


return (
        <>
            {currentItems &&
                currentItems.map((item, index) => {
                    // Calculate the time difference between now and the start time of the booking
                    const timeDifference = moment(item.ChildTimeSlot.startDate).diff(moment(), 'seconds');
                    let timeMessage = '';
    
                    // Define time messages based on the time difference
                    if (timeDifference <= 0) {
                        timeMessage = 'En cours'; // Booking is currently ongoing
                    } else if (timeDifference < 60) {
                        timeMessage = 'Dans quelques secondes';
                    } else if (timeDifference < 3600) {
                        timeMessage = `Dans ${moment.duration(timeDifference, 'seconds').minutes()} minutes`;
                    } else if (timeDifference < 86400) {
                        timeMessage = `Dans ${moment.duration(timeDifference, 'seconds').hours()} heures`;
                    } else if (timeDifference < 604800) {
                        timeMessage = `Dans ${moment.duration(timeDifference, 'seconds').days()} jours`;
                    } else if (timeDifference < 31536000) {
                        const months = moment.duration(timeDifference, 'seconds').months();
                        if (months <= 0) {
                            timeMessage = `Dans ${moment.duration(timeDifference, 'seconds').days()} jours`;
                        } else {
                            timeMessage = `Dans ${months} mois`;
                        }
                    } else {
                        timeMessage = `Dans ${moment.duration(timeDifference, 'seconds').years()} ans`;
                    }
    
                    return (
                        <div key={index} onClick={() => { setBookingSidebarDetail(item); onOpenAdminBookingDetailSidebar() }} className={currentItems.length === 1 ? "clt_admin_dash_booking_container_wrapper" : index === 0 ? "clt_admin_dash_booking_container_wrapper_first" : (index + 1) === currentItems.length ? "clt_admin_dash_booking_container_wrapper_last" : "clt_admin_dash_booking_container_wrapper_center"}>
                            <div className="clt_admin_dash_booking_container_box">
                                <div className='clt_admin_dash_booking_avatar_wrapper'>
                                    {item?.User?.profilePictureUrl ?
                                        <div className="clt_admin_dash_booking_image">
                                            <img src={item?.User?.profilePictureUrl} alt={item?.User?.firstName} />
                                        </div>
                                        :
                                        <div className="clt_admin_dash_booking_avatar">
                                            <div className='clt_admin_dash_booking_avatar_name'>{item?.User?.firstName[0].toUpperCase()}</div>
                                        </div>
                                    }
                                </div>
                                <div className="clt_admin_dash_booking_container_detail">
                                    <div className="clt_admin_dash_booking_container_box_name">{item?.User?.firstName} {item?.User?.lastName}</div>
                                    <div className="subtitle">{moment(item?.ChildTimeSlot?.startDate).format("DD MMM YYYY")} {'-'} {moment(item?.ChildTimeSlot?.startHour, "HH:mm:ss").format("HH:mm")} {'➔'}</div>
                                    <div className="subtitle2"> {moment(item?.ChildTimeSlot?.endDate).format("DD MMM YYYY")} {'-'} {moment(item?.ChildTimeSlot?.endHour, "HH:mm:ss").format("HH:mm")}</div>
                                    
                                    <div className='row-span-booking-list'>
                                    <div className="event" style={{ color: `${item?.Event?.color}`, backgroundColor: `${item?.Event?.background}30` }}>{timeMessage}</div>
                                    <div className="event" style={{ color: `${item?.Event?.color}`, backgroundColor: `${item?.Event?.background}30` }}>{item?.Event?.title}</div>
                                    </div>
                                </div>
                                <div className="clt_admin_dash_booking_container_arrow">
                                    <img src={NextArrow} className="clt_admin_arrow" alt={IMG_ALT} />
                                </div>
                            </div>
                        </div>
                    );
                })}
        </>
    );
    
}

/* Const */
const AdminDashboardBooking = ({ adminNavLinks, sidebar, scrollDirection, setIsBookingSidebar }) => {

    /* Const - Sidebar - Booking Detail */

    const [isOpenSidebarBookingAdmin, setisOpenSidebarBookingAdmin] = useState(false);
    const onOpenAdminBookingDetailSidebar = () => {
        setisOpenSidebarBookingAdmin(true)
    }
    const onCloseOpenSidebarBookingAdmin = () => {
        setisOpenSidebarBookingAdmin(false)
    }

    /* Const - Navigation bar - Booking or request */
    const [activeTab, setActiveTab] = useState("booking");
    const [eventFilter, setEventFilter] = useState('All events');
    const [bookingFilterType, setBookingFilterType] = useState('comming');
    const [users, setUsers] = useState([]);
    const [docBoookings, setDocBookings] = useState([]);
    const [cancelReqBoookings, setCancelReqBookings] = useState([]);
    const [missingPaymentBoookings, setMissingPaymentBookings] = useState([]);

    /* Const - Navigation - Booking - Filter button : Order state / Event type  */
    const CustomToggleFirst = React.forwardRef(({ children, onClick }, ref) => (
        <p
          className="clt_admin_dash_booking_filter_chip"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
            console.log(e);
          }}
        >
          Tout les évenements
          <img src={NextArrow} className="clt_admin_arrow down" alt={IMG_ALT} />
        </p>
    ));
    
    // Define your custom toggle component
    const CustomToggleSecond = React.forwardRef(({ children, onClick }, ref) => (
    <p
        className="clt_admin_dash_booking_filter_chip"
        ref={ref}
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
        }}
    >
        {bookingFilterTypeToText(bookingFilterType)}
        <img src={NextArrow} className="clt_admin_arrow down" alt={IMG_ALT} />
    </p>
    ));

    const bookingFilterTypeToText = (filterType) => {
        switch (filterType) {
          case 'comming':
            return 'À venir';
          case 'Ongoing':
            return 'En cours';
          case 'Finish':
            return 'Terminé';
          case 'Cancel':
            return 'Annulé';
          default:
            return 'Tout les évenements'; // Default text
        }
    };

    const [loading, setLoading] = useState(false);

    /* Const - Navigation - Request - Request Counter */
    const [requestCounter, setRequestCounter] = useState(0)
    const itemsPerPage = 5
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const allEvents = useSelector(state => state.allEvents.events);
    const bookings = useSelector(state => state.bookings.allBookings);
    const totalCount = useSelector(state => state.bookings.totalCount);
    console.log("here is state", useSelector(state => state));

    const { socket } = useAuth();

    useEffect(() => {
        if (bookings?.length === 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [bookings?.length])

    const [bookingSidebarDetail, setBookingSidebarDetail] = useState([]);

    const dispatch = useDispatch()
    useEffect(() => {
        socket.on('cancelRequestSent', function (dataComing, callback) {
            setBookingSidebarDetail(dataComing.data);
            dispatch(getBookings(currentPage, itemsPerPage, eventFilter, bookingFilterType));
            getDocBookings();
            getCancelReqBookings();
            getMissingPaymentBookings();
        });
        dispatch(getBookings(currentPage, itemsPerPage, eventFilter, bookingFilterType));
    }, [currentPage, eventFilter, bookingFilterType]);

    useEffect(() => {
        getDocBookings();
        getCancelReqBookings();
        getMissingPaymentBookings();
    }, []);

    useEffect(() => {
        dispatch(getEvents());
    }, []);

    useEffect(() => {
        const totalPages = Math.ceil(totalCount / itemsPerPage);
        setTotalPages(totalPages);
    }, [totalCount, itemsPerPage]);

    const getDocBookings = async () => {
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + "/document",
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log(response.data);
            setDocBookings(response.data.data);

        } catch (error) {
            console.log('location on here: ', error);
            ErrorToast(error?.response?.data?.message);
        }
    }

    const getCancelReqBookings = async () => {
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + "/booking/cancel/request/list",
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setCancelReqBookings(response.data.data);
        } catch (error) {
            console.log('location on here: ', error);
            ErrorToast(error?.response?.data?.message);
        }
    }

    const getMissingPaymentBookings = async () => {
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await axios.get(API_ENDPOINT + "/booking/missing/payment/list",
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMissingPaymentBookings(response.data.data);
        } catch (error) {
            console.log('location on here: ', error);
            ErrorToast(error?.response?.data?.message);
        }
    }

    const handleEventFilterChange = (selectedEventFilter) => {
        setEventFilter(selectedEventFilter);
        setCurrentPage(1);
    };

    const handleBookingTypeFilterChange = (selectedBookingType) => {
        setBookingFilterType(selectedBookingType);
        setCurrentPage(1);
    };
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const currentItems = bookings;
    const loadingScreen = useSelector(state => state.loading.loading);
    useEffect(() => {
        setLoading(loadingScreen)
    }, [loadingScreen])

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {

        const selectedPage = event.selected + 1;
        setCurrentPage(selectedPage);
        setLoading(bookings?.loading);
        // Simulate an asynchronous operation with a setTimeout

    }

    const isEmail = (input) => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(input)
    }

    const searchHandler = async (input) => {
        try {
            setIsLoadingSearch(true);
            const response = await userlist({ search: input });
            const getUsers = response.data?.data.map((user) => {
                return {
                    id: `${user.id}`,
                    name: `${user.first_name} ${user.last_name}`,
                    type: "user",
                    detail: `${user.phone} ${user.phone ? " - " : ""} ${user.email}`
                }
            }) || []
            if (getUsers?.length === 0 && input?.length > 0 && isEmail(input)) getUsers.push({ name: input, type: "email", detail: "" })
            setUsers(getUsers);
        } catch (error) {
            console.log(error);
        }
    };
    const [isSearchCanvas, setIsSearchCanvas] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (isSearchCanvas) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 0);
        }
    }, [isSearchCanvas]);


    /* Screen */
    return (
        <div className='d-flex w-100 flex-column justify-center align-items-center relative'>
            <div className="clt_admin_dash_booking_container_outer_wrapper ">
                <div className="clt_admin_dash_booking_container">

                    {/* Section - Search */}
                    <div className='ctl_admin_search_container'>
                        <p className="clt_admin_dash_booking_container_name">Mes réservations</p>
                        {/*<SearchBookingAdmin onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} />*/}
                        <SidebarSearchBooking
                            onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar}
                            usersData={users}
                            setBookingSidebarDetail={setBookingSidebarDetail}
                            searchHandler={searchHandler}
                            isLoading={isLoadingSearch}
                            setIsLoading={setIsLoadingSearch}
                            ref={inputRef}
                        />
                    </div>

                    {/* Section - Navigation bar */}
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="clt-admin-booking-tabs"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        onOverflow={"scroll"}
                    >

                        {/* Navigation - Booking */}
                        <Tab
                            eventKey="booking"
                            title="Réservation"
                            className="clt-admin-booking-tabs-body ctl_admin_search_container"
                        >



                            {/* Section - Edit Request
                            <div>
                                <AdminDashboardBookingSlider
                                    sidebar={sidebar}
                                    title={`Booking`}
                                    type="booking"
                                />
                            </div>*/}

                            <h2 className="clt-admin-booking-section-3-h2">Réservation</h2>

                            {/* Section - Button filter */}
                            <div className="clt_admin_dash_booking_filter_chip_container">

                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggleFirst} />
                                    <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                        className="pfr_payment_cards3dots_options"
                                        onClick={() => handleEventFilterChange('All events')}
                                        >
                                        Tout les évenements
                                        </Dropdown.Item>
                                        {allEvents?.filter((x) => x.isActive === true).map((event, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => handleEventFilterChange(event.title)}
                                            className="pfr_payment_cards3dots_options"
                                        >
                                            {event.title}
                                        </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown>
                                <Dropdown.Toggle as={CustomToggleSecond} />
                                    <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                        className="pfr_payment_cards3dots_options"
                                        onClick={() => handleBookingTypeFilterChange('comming')}
                                        >
                                        À venir
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                        className="pfr_payment_cards3dots_options"
                                        onClick={() => handleBookingTypeFilterChange('Ongoing')}
                                        >
                                        En cours
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                        className="pfr_payment_cards3dots_options"
                                        onClick={() => handleBookingTypeFilterChange('Finish')}
                                        >
                                        Terminé
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                        className="pfr_payment_cards3dots_options"
                                        onClick={() => handleBookingTypeFilterChange('Cancel')}
                                        >
                                        Annulé
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>

                            {/* Section - Booking list */}
                            <div>
                                {/*<p className="clt_admin_dash_menu_container_name4">COMING</p>*/}


                                {
                                    loading ?

                                        <>
                                            <div style={{ border: '1px solid #eeeeee', margin: '0px', borderTopRightRadius: '15px', borderTopLeftRadius: '15px', }}>
                                                <div>
                                                    <div>
                                                        <div className='clt_booking_list_skeleton_container'>
                                                            <Skeleton
                                                                className='clt_booking_list_skeleton_profile_circle'
                                                                circle={true}
                                                                count={1} />

                                                            <div className='clt_booking_list_skeleton_container_shadow'>
                                                                <Skeleton className='clt_booking_list_skeleton_profile_grand_line' count={2} />
                                                                <Skeleton className='clt_booking_list_skeleton_profile_small_line' count={1} />
                                                            </div>
                                                            <div style={{ marginRight: '0px' }}>
                                                                <img className='clt_admin_arrow' src={NextArrow} alt="nextArrow" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div>
                                                    <div>
                                                        <div className='clt_booking_list_skeleton_container'>
                                                            <Skeleton
                                                                className='clt_booking_list_skeleton_profile_circle'
                                                                circle={true}
                                                                count={1} />

                                                            <div className='clt_booking_list_skeleton_container_shadow'>
                                                                <Skeleton className='clt_booking_list_skeleton_profile_grand_line' count={2} />
                                                                <Skeleton className='clt_booking_list_skeleton_profile_small_line' count={1} />
                                                            </div>
                                                            <div style={{ marginRight: '0px' }}>
                                                                <img className='clt_admin_arrow' src={NextArrow} alt="nextArrow" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div>
                                                    <div>
                                                        <div className='clt_booking_list_skeleton_container'>
                                                            <Skeleton
                                                                className='clt_booking_list_skeleton_profile_circle'
                                                                circle={true}
                                                                count={1} />

                                                            <div className='clt_booking_list_skeleton_container_shadow'>
                                                                <Skeleton className='clt_booking_list_skeleton_profile_grand_line' count={2} />
                                                                <Skeleton className='clt_booking_list_skeleton_profile_small_line' count={1} />
                                                            </div>
                                                            <div style={{ marginRight: '0px' }}>
                                                                <img className='clt_admin_arrow' src={NextArrow} alt="nextArrow" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div>
                                                    <div>
                                                        <div className='clt_booking_list_skeleton_container'>
                                                            <Skeleton
                                                                className='clt_booking_list_skeleton_profile_circle'
                                                                circle={true}
                                                                count={1} />

                                                            <div className='clt_booking_list_skeleton_container_shadow'>
                                                                <Skeleton className='clt_booking_list_skeleton_profile_grand_line' count={2} />
                                                                <Skeleton className='clt_booking_list_skeleton_profile_small_line' count={1} />
                                                            </div>
                                                            <div style={{ marginRight: '0px' }}>
                                                                <img className='clt_admin_arrow' src={NextArrow} alt="nextArrow" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div>
                                                    <div>
                                                        <div className='clt_booking_list_skeleton_container'>
                                                            <Skeleton
                                                                className='clt_booking_list_skeleton_profile_circle'
                                                                circle={true}
                                                                count={1} />

                                                            <div className='clt_booking_list_skeleton_container_shadow'>
                                                                <Skeleton className='clt_booking_list_skeleton_profile_grand_line' count={2} />
                                                                <Skeleton className='clt_booking_list_skeleton_profile_small_line' count={1} />
                                                            </div>
                                                            <div style={{ marginRight: '0px' }}>
                                                                <img className='clt_admin_arrow' src={NextArrow} alt="nextArrow" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </>
                                        :
                                        <Items currentItems={currentItems} setBookingSidebarDetail={setBookingSidebarDetail} onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} />
                                }


                                {/* <Items currentItems={currentItems} setBookingSidebarDetail={setBookingSidebarDetail} onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} /> */}


                                <div className='mt-3'>
                                    <ReactPaginate
                                        forcePage={currentPage - 1}
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageCount={totalPages}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                    />
                                </div>

                                {/* <div onClick={onOpenAdminBookingDetailSidebar} className="clt_admin_dash_booking_container_wrapper_center">

                                    <div className="clt_admin_dash_booking_container_box">
                                        <div className='clt_admin_dash_booking_avatar_wrapper'>
                                            <div className="clt_admin_dash_booking_avatar">
                                                <div className='clt_admin_dash_booking_avatar_name'>J</div>
                                            </div>
                                        </div>
                                        <div className="clt_admin_dash_booking_container_detail">
                                            <div className="clt_admin_dash_booking_container_box_name">Jhhon doe</div>
                                            <div className="subtitle">DD/MM/YY - 23:59 {'➔ '}</div>
                                            <div className="subtitle2">DD/MM/YY - 23:59</div>
                                            <div className="wedding">Wedding</div>
                                        </div>
                                        <div className="clt_admin_dash_booking_container_arrow">
                                            <img src={NextArrow} className="clt_admin_arrow" alt={IMG_ALT} />
                                        </div>
                                    </div>
                                </div>

                                <div onClick={onOpenAdminBookingDetailSidebar} className="clt_admin_dash_booking_container_wrapper_last">

                                    <div className="clt_admin_dash_booking_container_box">
                                        <div className='clt_admin_dash_booking_avatar_wrapper'>
                                            <div className="clt_admin_dash_booking_avatar">
                                                <div className='clt_admin_dash_booking_avatar_name'>J</div>
                                            </div>
                                        </div>
                                        <div className="clt_admin_dash_booking_container_detail">
                                            <div className="clt_admin_dash_booking_container_box_name">Jhoon doe</div>
                                            <div className="subtitle">DD/MM/YY - 23:59 {'➔ '}</div>
                                            <div className="subtitle2">DD/MM/YY - 23:59</div>
                                            <div className="wedding">Wedding</div>
                                        </div>
                                        <div className="clt_admin_dash_booking_container_arrow">
                                            <img src={NextArrow} className="clt_admin_arrow" alt={IMG_ALT} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>


                        </Tab>

                        {/* Navigation - Request
                        <Tab
                            eventKey="requestEdit"
                            title={`Edit request (${requestCounter})`}
                            className="clt-admin-booking-tabs-body"
                        >
                            <div>
                                <AdminDashboardBookingRequestSlider
                                    sidebar={sidebar}
                                    title={`Edit Request (${requestCounter})`}
                                    type="edit"
                                />
                            </div>

                        </Tab>*/}

                        <Tab
                            eventKey="requestCancel"
                            title={`Notification (${docBoookings.length})`}
                            className="clt-admin-booking-tabs-body"
                        >
                            <div>
                                {/* Section - Document require */}
                                {activeTab === 'requestCancel' ?
                                    <>
                                        <AdminDashboardBookingRequestSlider
                                            sidebar={sidebar}
                                            // title={`Document require (${docBoookings.length})`}
                                            title={`Document obligatoire`}
                                            // subtitle="Liste des réservations où un document est manquant"
                                            type="documentrequire"
                                            data={docBoookings}
                                            setBookingSidebarDetail={setBookingSidebarDetail}
                                            onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} />

                                        <AdminDashboardBookingRequestSlider
                                            sidebar={sidebar}
                                            title={`Demande annulation (${cancelReqBoookings.length})`}
                                            type="cancel"
                                            dataCancelReq={cancelReqBoookings}
                                            setBookingSidebarDetail={setBookingSidebarDetail}
                                            onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} />

                                        <AdminDashboardBookingRequestSlider
                                            sidebar={sidebar}
                                            title={`Paiement manquant (${missingPaymentBoookings.length})`}
                                            // subtitle="Liste des réservations où un paiement est manquant"
                                            type="missingpayment"
                                            dataMissingPayment={missingPaymentBoookings}
                                            setBookingSidebarDetail={setBookingSidebarDetail}
                                            onOpenAdminBookingDetailSidebar={onOpenAdminBookingDetailSidebar} />
                                    </>

                                    : null}
                                {/* Section - Cancel Request */}

                            </div>

                        </Tab>

                        {/* Section - Cancel Request
                        <Tab
                            eventKey="documentMissing"
                            title={`Document missing (${requestCounter})`}
                            className="clt-admin-booking-tabs-body"
                        >
                            <div>

                                <AdminDashboardBookingRequestSlider
                                    sidebar={sidebar}
                                    title={`Cancel Request (${requestCounter})`}
                                    type="cancel"
                                />
                            </div>

                        </Tab>

                        <Tab
                            eventKey="remainingPayment"
                            title={`Remaining payment (${requestCounter})`}
                            className="clt-admin-booking-tabs-body"
                        >
                            <div>
                                <AdminDashboardBookingRequestSlider
                                    sidebar={sidebar}
                                    title={`Cancel Request (${requestCounter})`}
                                    type="cancel"
                                />
                            </div>

                        </Tab>*/}
                    </Tabs>
                </div>


                {/* Button - Add a booking - Sticky to bottom 
                <div className='clt_admin_dash_booking_speeddial'>
                    <div className='clt_admin_dash_booking_speeddial_container'>
                        <button className='speeddial-btn' onClick={() => setIsBookingSidebar(true)}>
                            <span className="">+</span>
                            <span
                                style={{
                                    width: scrollDirection !== "up" ? "0px" : "140px"
                                }}
                                className="clt_admin_dash_booking_speeddial_button_text">Ajouter une réservation</span>
                        </button>
                    </div>
                </div>*/}
            </div>
            <div className="border-bottom"></div>

            {/* Hook - Sidebar - Booking Detail  */}

            {isOpenSidebarBookingAdmin ? <AdminDashboardBookingDetail
                onCloseOpenSidebarBookingAdmin={onCloseOpenSidebarBookingAdmin}
                isOpenSidebarBookingAdmin={isOpenSidebarBookingAdmin}
                data={bookingSidebarDetail}
                setData={setBookingSidebarDetail}
            /> : null}
        </div>

    )
}

export default AdminDashboardBooking
