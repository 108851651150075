import React, { useState, useEffect } from "react";
import { Button, Offcanvas } from "react-bootstrap";

import BackArrow from "../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../constants";
import CategoryList from "./ManageCategory/CategoryList";
import EditCategory from "./ManageCategory/EditCategory";
import ManageArticle from "./ManageArticle";
import ManagePath from "./ManagePath";
import CreateCategory from "./ManageCategory/CreateCategory";
import {
  createCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
} from "./api";
import { ErrorToast } from "../../../../reusable/Toast";

function AdminDashboardManageHelpcenter({ isOpen, onClose }) {
  // category state
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // cateogyr
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  //   articles state
  const [openManageArticles, setOpenManageArticles] = useState(false);
  // path states
  const [openManagePath, setOpenManagePath] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);
  // category
  const createNewCategory = async (categoryName, title, subtitle) => {
    const res = await createCategory(categoryName, title, subtitle);
    if (res.success) {
      getCategories();
      setOpenCreateCategory(false);
    } else {
      ErrorToast("Une erreur est survenue", "Veuillez ajouter à nouveau la catégorie");
    }
  };
  const getCategories = async () => {
    const res = await getAllCategories();
    setCategories(res);
  };
  const deleteCat = async (selectedCategory) => {
    const res = await deleteCategory(selectedCategory);
    if (res.success) {
      getCategories();
    } else {
      ErrorToast("Une erreur est survenue", "Veuillez essayé de supprimer la catégorie à nouveau");
    }
  };
  const updateCategoryName = async (categoryId, category) => {
    const res = await updateCategory(categoryId, category);
    if (res.success) {
      getCategories();
      setOpenEditCategory(false);
    } else {
      ErrorToast("Une erreur est survenue", "Veuillez essayé de supprimer la catégorie à nouveau");
    }
  };
  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Manage Helpcenter</Offcanvas.Title>

            <Button
              className="clt_admin_dash_manage_access_header_button shadow-none"
              onClick={() => setOpenCreateCategory(true)}
            >
              Add
            </Button>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className="clt_help_article_offcanvas_body">
        <CategoryList
          list={categories}
          openEditCategory={(category) => {
            setSelectedCategory(category);
            setOpenEditCategory(true);
          }}
          openManageArticles={(category) => {
            setSelectedCategory(category);
            setOpenManageArticles(true);
          }}
          openManagePath={(category) => {
            setSelectedCategory(category);
            setOpenManagePath(true);
          }}
          deleteCat={deleteCat}
        />
        <CreateCategory
          isOpen={openCreateCategory}
          onClose={() => setOpenCreateCategory(false)}
          createNewCategory={createNewCategory}
        />
        <EditCategory
          isOpen={openEditCategory}
          onClose={() => setOpenEditCategory(false)}
          updateCategory={updateCategoryName}
          category={selectedCategory}
        />
        <ManageArticle
          isOpen={openManageArticles}
          onClose={() => setOpenManageArticles(false)}
          heading={selectedCategory?.name || ""}
          category={selectedCategory}
        />
        <ManagePath
          isOpen={openManagePath}
          onClose={() => setOpenManagePath(false)}
          selectedCategory={selectedCategory}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default AdminDashboardManageHelpcenter;
