import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "../invoice/SidebarInvoiceList.css";
import BackArrow from "../../../assets/img/back_arrow.png";
import InvoiceIcon from "../../../assets/img/icon_invoice.png";
import SectionAccess2 from "../SectionAccess2.js"
import icon_key from "../../../assets/img/icon_key.png";
import {API_ENDPOINT, IMG_ALT} from "../../../constants";
import { Link } from "react-router-dom";
import {auth} from "../../screens/auth/firebase";
import axios from "axios";
import { SuccessToast, ErrorToast } from "../Toast";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const SidebarAccess = ({
    isSidebarAccess,
    onCloseSidebarAccess,
    booking
}) => {


    const handleCloseSidebar = () => {
        onCloseSidebarAccess()
    }


    /* Const - Handle the screen size to make page responsive */
    const [windowSize, setWindowSize] = React.useState(getWindowSize());
        React.useEffect(() => {
            function handleWindowResize() {
                setWindowSize(getWindowSize());
            }
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }, []);


    return (
        <Offcanvas
            placement="end"
            className="clt_reuseable_invoice_offcanvas"
            show={isSidebarAccess}
            onHide={onCloseSidebarAccess}
        >
            <Offcanvas.Header className="justify-content-start clt_reuseable_invoice_offcanvas_header">

                <React.Fragment>
                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={handleCloseSidebar}
                    >
                        <img src={BackArrow} alt={IMG_ALT} />
                    </button>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title className="clt_reuseable_invoice_title">Accès</Offcanvas.Title>
                        {/* export button  */}
                        {/* <Button className="clt_reuseable_invoice_header_button shadow-none">
                            Export
                        </Button> */}
                    </div>
                    
                </React.Fragment>


            </Offcanvas.Header>
            <Offcanvas.Body
                className="clt_reuseable_invoice_offcanvas_body"    
            >
                <>
                    <div className="clt_reuseable_invoice_innerdivs">
                        <h2 className="clt_reuseable_invoice_innerhead">Accès</h2>
                        {/* <p className="clt_admin_dash_timeslot_edit_box_subheading">Pour démarrer une réservation, vous devez importer la carte d'identité et l'attestation d'assurance personnelle ou professionnel selon la nature de votre réservation.</p> */}
                        <SectionAccess2>
                        </SectionAccess2>
                        

                        
                        {/*<div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-center justify-content-start"*/}
                        {/*        style={{ width: "20%" }}*/}
                        {/*    >*/}
                        {/*        <div className="clt_reuseable_invoice_imgdiv">*/}
                        {/*            <img*/}
                        {/*                src={InvoiceIcon}*/}
                        {/*                className=""*/}
                        {/*                alt="Image"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-start justify-content-center flex-column"*/}
                        {/*        style={{ width: "70%" }}*/}
                        {/*    >*/}
                        {/*        <h3 className="clt_reuseable_invoice_cardnumber">#F10000001</h3>*/}
                        {/*        <p className="clt_reuseable_invoice_cardname">Payment Booking - 01 Jan 23:59 </p>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"*/}
                        {/*        style={{ width: "10%" }}*/}
                        {/*    >*/}
                        {/*        <Dropdown>*/}
                        {/*            <Dropdown.Toggle as={CustomToggle} />*/}
                        {/*            <Dropdown.Menu size="sm" title=""*/}
                        {/*                className="pfr_dropdown_menu">*/}
                        {/*                <Dropdown.Item*/}
                        {/*                    className="clt_reuseable_invoice_cards3dots_options"*/}
                        {/*                >*/}
                        {/*                    View the receipt*/}
                        {/*                </Dropdown.Item>*/}
                        {/*            </Dropdown.Menu>*/}
                        {/*        </Dropdown>*/}

                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*
                        <div className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}>
                            <div className="d-flex align-items-center justify-content-start" style={{ width: "20%" }}>
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img src={InvoiceIcon} className="" alt="Image"/>
                                </div>
                            </div>
                            <div className="d-flex align-items-start justify-content-center flex-column" style={{ width: "70%" }}>
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000004</h3>
                                <p className="clt_reuseable_invoice_cardname">Refund - Booking - 01 Jan 23:59 </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots" style={{ width: "10%" }} >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div
                            className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000003</h3>
                                <p className="clt_reuseable_invoice_cardname">Refund - Edit - 01 Jan 23:59 </p>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div
                            className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000002</h3>
                                <p className="clt_reuseable_invoice_cardname">Payment - Edit - 01 Jan 23:59 </p>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        */}
                        

                    </div>
                </>

            </Offcanvas.Body>
        </Offcanvas >
    );
};

export default SidebarAccess;


function getWindowSize() {
    const { innerHeight, innerWidth } = window;
    return { innerHeight, innerWidth };
}