// Import
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
    Offcanvas,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/cancel_icon.png";
// import booking_imge_1 from "../../../../assets/img/booking_imge_1.jpg";
import { IMG_ALT, eventweddingData } from "../../../../../../constants";
import SectionTop from "../../../../../reusable/booking/SectionTop";
import "./[CHAT-SIDEBAR] User - Cancel Request - Sent.css";
import ConfirmPayProfilePicture1 from "../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../../../assets/img/confirm_pay_4.png";
import ConfirmPay5 from "../../../../../../assets/img/confirm_pay_5.png";
// import ConfirmPay4 from "../../../../assets/img/admin_icon_booking_detail_3.png";
import CustomHr from "../../../../../reusable/CustomHr";
import SectionDate from "../../../../../reusable/booking/SectionDate";
import SectionGuest from "../../../../../reusable/booking/SectionGuest";
import SectionUser from "../../../../../reusable/booking/SectionUser";
import SectionAccess from "../../../../../reusable/SectionAccess";
import SectionBed from "../../../../../reusable/booking/SectionRoom";
import Bed from "../../../../bed/OptionRoom";
// import SectionDamageDeposit from "../../../reusable/booking/SectionDamageDeposit";
// import { Link } from "react-router-dom";
import Guest from "../../../../guest/Guest";
import Account from "../../../../account/Account";
import Booking from "../../../../detail/booking/booking_user";
import SectionContact from "../../../../../reusable/booking/SectionContact";
import Event from "../../../../event/Event";
import SectionEvent from "../../../../../reusable/booking/SectionEvent";
import SectionPriceDetail from "../../../../../reusable/booking/SectionPriceDetailPendingEditRequest.js";
import Payment from "../../../../payment/Payment";
import SectionPaymentPlan from "../../../../../reusable/booking/SectionPaymentPlan";
import SectionPaymentMethod from "../../../../../reusable/booking/SectionPaymentMethod";
import { SuccessToast } from "../../../../../reusable/Toast";
import SectionInformationMessageRequestSent from "../../../../../reusable/SectionInformationMessageRequestSent";


{/* Const */}
const ChatSidebarCancelRequestSentUser = ({ hideSidebar }) => {

    /* Const - Section - Event */
    const [eventType, setEventType] = useState(eventweddingData);
    const [isEventSidebar, setIsEventSidebar] = useState(false);

    /* Const - Section - Date */
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);
    let bookingProps = {
        isFromBooking: true,
        isFromBookingDate: {
        startDate: new Date(),
        endDate: new Date(),
        },
        isFromBookingTimeSlot: {
        id: 1,
        title: "Title",
        subTitle: "subTitle",
        time: "time",
        },
    };

    /* Const - Section - Guest */
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [guestCount, setGuestCount] = useState(10);

    /* Const - Section room */
    const [isBedSidebar, setIsBedSidebar] = useState(false);
    const [confirmPayOptionRoom, setConfirmPayOptionRoom] = useState(false);
    const [bedCount, setBedCount] = useState([
        { roomTitle: "Room 1", nights: "1 night (10 €)" },
        { roomTitle: "Room 2", nights: "2 night (20 €)" },
    ]);
    const [editBedSettings, setEditBedSettings] = useState(false)
    const handleBed = () => {
        setIsBedSidebar(true);
        setEditBedSettings(true)
    };

    /* Const - Section - Price detail*/
    const [priceDetailsSlots, setpriceDetailsSlots] = useState([
        { title: "Booking refund", tooltipMsg: null, price: "10€" },
    ]);

    /* Const - Section - Payment plan */
    const [selectedPlan, setSelectedPlan] = useState({});

    /* Const - Section - Payment method */
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);

    /* Const - Cancel request */
    const handleRefuse = () => {
        SuccessToast("La demande a été annulé");
        hideSidebar();
    }




    




    return (
        <>


            {/* Sidebar - Edit request - Accept - User */}
            <div
                className="clt_message_user_chat_offcanvas"
            >


                {/* Header */}
                <div className="d-flex position-relative justify-content-center relative align-items-center clt_message_user_request_offcanvas_header">
                    <button
                        type="button"
                        className="back-button-2 back-button d-block"
                        aria-label="Close"
                        onClick={hideSidebar}
                    >
                        <img src={BackArrow} className="" alt={IMG_ALT} />
                    </button>
                    <div className="clt_message_user_information_request_title_header">
                        [CHAT-SIDEBAR] User - Cancel Request - Sent
                    </div>

                </div>

                
                {/* Body */}
                <div
                    className="clt_message_user_chat_offcanvas_body"
                >
                    <>
                        <SectionTop
                            image={ConfirmPayProfilePicture1}
                            title="CLT"
                            imageSize="large"
                            subTitle="Wedding · 25€ · #111111"
                        />
                        <CustomHr
                            colClass="clt-booking-hr-pd"
                            hrClass="p-0 m-0 clt-hr"
                        />
                        <SectionInformationMessageRequestSent
                            innerTitle="Sent"
                            innerSubTitle="The booking cancellation request has been sent"
                        />
                        <CustomHr colClass="clt_admin_dash_hr_pd" hrClass="p-0 m-0 clt-hr" />
                        <h2 className="clt-mybooking-title">Cancel request - Sent</h2>
                        <SectionEvent
                            image={eventType.iconActive}
                            subText={eventType.title}
                            // setIsEventSidebar={() => setIsEventSidebar(true)}
                            // buttonText="Edit"
                        />
                        <SectionDate
                            image={ConfirmPay2}
                            subText1="Subtext 1"
                            subText2="Subtext 2"
                            isEditRequest={true}
                            oldSubText1="Subtext 1"
                            oldSubText2="Subtext 2"
                        />
                        <SectionGuest
                            image={ConfirmPay3}
                            subText={`${guestCount} guests`}
                            buttonText="Edit"
                        />
                        <SectionBed
                            title="Room"
                            image={ConfirmPay5}
                            subText={"Add room"}
                            roomSelected={bedCount}
                            buttonText={bedCount.length > 0 ? "Edit" : "Add"}
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <SectionPriceDetail
                            title="Price detail"
                            priceListData={priceDetailsSlots}
                            totalPrice="10€"
                        />
                        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                        <Row>
                            <Col>
                                <div className="clt-booking-btn-refuse-div mb-5">
                                    <Button
                                        className="clt-booking-btn-refuse"
                                        onClick={handleRefuse}
                                    >
                                        <span>Cancel request</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>


                </div>
            </div>
            {/* Function - Booking : Sidebar date and Accept request*/}
            <Booking
                openBookingSidebar={isBookingSidebar}
                onCloseBookingSidebar={(data) => {
                setIsBookingSidebar(false);
                }}
                eventType={eventType}
                {...bookingProps}
            />


            {/* Function - Section - Event */}
            <Event
                show={isEventSidebar}
                onHide={() => setIsEventSidebar(false)}
                eventType={eventType}
                onConfirmEvent={(event) => {
                if (event !== eventType) {
                    setIsBookingSidebar(true);
                }
                setEventType(event);
                }}
            />

            {/* Function - Section - Guest */}
            <Guest
                isGuestSidebar={isGuestSidebar}
                editGuestValue={guestCount}
                onCloseGuestSidebar={(guestCount) => {
                setIsGuestSidebar(false);
                setGuestCount(guestCount);
                }}
            />

            {/* Function - Section - Room */}
            <Bed
                isBedSidebar={isBedSidebar}
                isComingFromMyBooking={false}
            />

            {/* Function - Section Payment method */}
            <Payment
                isPaymentSidebar={isPaymentSidebar}
                onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
                isFromBooking={true}
                getPaymentCard={(val) => setIsPaymentCardSelected(val)}
            />
            
        </>
    );
};

export default ChatSidebarCancelRequestSentUser;
