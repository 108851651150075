import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
const SectionDate = ({ image, subText, handleDamageEdit }) => {
    return (
        <Row>
            <Col>
                {/* <div className="d-flex justify-content-center align-items-center clt-booking-cp-div3 clt-booking-cp-div1Box" onClick={handleDamageEdit}>
                    <img src={image} alt={IMG_ALT} />
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                            <h2>Damage Deposit</h2>
                            <p>{subText}</p>
                        </div> */}
                        
                        {/* {handleDamageEdit && ( */}
                            {/* <Button className="shadow-none" onClick={handleDamageEdit}>
                                <span>See</span>
                            </Button> */}
                        {/* )} */}
                        {/* <div> */}
              {/* <i className="fa-solid fa-chevron-right"></i>
              </div>
                    </div>
                </div> */}


                <div
            className="clt-booking-cp-div1Box clt-booking-hoverActive"
            onClick={handleDamageEdit}
          >
            <div className="d-flex align-items-center justify-content-between flex-row w-100">
            <div className="clt-booking-div1" style={{marginLeft:0,width:'calc(100% - 10px)'}}>
                {/* <div className="clt-booking-innner-div1 clt-booking-bgImg1"></div> */}
                 {/* <div className="clt-account-screen-inner-div-img"> */}
                 <div className="clt-account-screen-inner-div-img clt-booking-innner-div1 ">
          <div
          >
            <img src={image} alt={IMG_ALT} />
          </div>
        </div>
                <div className="inner-div-1 clt-booking-cp-div3-text" style={{marginLeft:0,width:'calc(100% - 40px)'}}>
                <h2>Caution</h2>
                {/* <p>{subText}</p> */}
                <p style={{overflow:'hidden',whiteSpace:'nowrap',overflowWrap:'break-word'}}>{subText}</p>
                </div>
              </div>
              <div>
              <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
            </Col>
        </Row>
    );
};

export default SectionDate;
