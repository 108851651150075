import React from "react";
import { IMG_ALT } from "../../constants";
import TextLink from "./TextLink";

const Box = (props) => {
  return (

    <div className="clt_detail_reserve_form_box_container d-flex justify-content-start align-items-start">
        <div className="d-flex justify-content-center align-items-center">
            <img style={{ width: 54, height: 54 }} src={props.icon} alt={IMG_ALT} />
        </div>
        <div className="d-flex justify-content-between  flex-column align-items-left w-100">
            <p className="clt_detail_reserve_form_box_p">{props.title}</p>  
            <p className="clt_detail_reserve_form_box_p2">{props.subtitle} </p> 
            <TextLink onPress={props.onPress} textLinkClass={'clt_detail_reserve_form_text_link_box'} to={"/"} text={props.text_link} />
        </div>
    </div>
  );
};

export default Box;