import React, { useEffect, useState } from "react";
import "./AdminDashboardMenu.css";
import NextArrow from "../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../constants";
import Account from "../../../../components/screens/account/Account";
import AdminDashboardInvoice from "./AdminDashboardInvoice/admin_dash_invoice";
import AdminDashboardManageAccess from "./AdminDashboardManageAccess/AdminDashboardManageAccess";
import BusinessInformation from "./AdminDashboardBusinessInformation/BusinessInformation";
import { useAuth } from "../../../../components/screens/auth/authContext";
import AdminDashboardManageHelpcenter from "./AdminDashboardManageHelpcenter/AdminDashboardManageHelpcenter";
import SidebarSearchUser from '../../../reusable/search/Search - Users/AdminDashboardUserSearch';
// import BusinessInformation from '../../business.account/Business.Account';

const AdminDashboardMenu = () => {
  const [isSideBar, setIsSideBar] = useState(false);
  const [isAccountDrawer, setIsAccountDrawer] = useState(false);
  const [name, setName] = useState("");
  const { isLoggedIn, user } = useAuth();
  const openSideBar = () => {
    setIsSideBar(true);
  };
  const openAccountDrawer = (e) => {
    setIsAccountDrawer(true);
    setIsSideBar(false);
  };
  const closeAccountDrawer = (e) => {
    setIsAccountDrawer(false);
    setIsSideBar(true);
  };
  const [isBusinessInformationInfo, setIsBusinessInformationInfo] =
    useState(false);
  const [selectedProfile, setSelectedProfile] = useState({
    type: "Personal",
    detail: "John Doe . 055555555 . johndoe@gmail.com",
  });

  const [openDashboardInvoice, setOpenDashboardInvoice] = useState(false);
  const [isSidebarSearchUser , setIsSidebarSearchUser] = React.useState(false);

  const [openDashboardManageAccess, setOpenDashboardManageAccess] =
    useState(false);

  const [openManageHelpCenterDrawer, setOpenManageHelpCenterDrawer] =
    useState(false);

  useEffect(() => {
    if (isLoggedIn && user) {
      console.log("running");
      setName(user?.firstName + " " + user?.lastName);
    } else {
      setName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user]);

  return (
    <>
      <div className="clt_admin_dash_menu_container_outer_wrapper">


        {/* General 
        <div className="clt_admin_dash_menu_container">
          <p className="clt_admin_dash_menu_container_name">GENERAL</p>
          <div
            className="clt_admin_dash_menu_container_wrapper_first"
            onClick={() => setIsBusinessInformationInfo(true)}
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_2"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  All invoice
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
          <div
            className="clt_admin_dash_menu_container_wrapper_last"
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_1"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  All user
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
        </div>*/}

        {/* General 
        <div className="clt_admin_dash_menu_container">
          <p className="clt_admin_dash_menu_container_name">GENERAL</p>
          <div
            className="clt_admin_dash_menu_container_wrapper_first"
            onClick={() => setIsSidebarSearchUser(true)}
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_1"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Tout les utilisateurs 
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
          <div
            className="clt_admin_dash_menu_container_wrapper_last"
            onClick={() => setOpenDashboardInvoice(true)}
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_2"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Toutes les factures
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
        </div>*/}


        


        <div className="clt_admin_dash_menu_container">
          <p className="clt_admin_dash_menu_container_name">GÉRER</p>
          {/* help center */}
          <div
            className="clt_admin_dash_menu_container_wrapper_first"
            onClick={() => setOpenManageHelpCenterDrawer(true)}
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_3"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Centre aide
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
          <div
            onClick={() => setOpenDashboardManageAccess(true)}
            className="clt_admin_dash_menu_container_wrapper_last"
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_4"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Rôle
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
        
        </div>
        {/* Profile */}
        <div
          onClick={() => setIsBusinessInformationInfo(true)}
          className="clt_admin_dash_menu_container"
        >
          <p className="clt_admin_dash_menu_container_name">ACCOUNT</p>
          <div className="clt_admin_dash_menu_container_wrapper">
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_5"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Profile du Château  
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminDashboardInvoice
        isInvoiceSidebar={openDashboardInvoice}
        onCloseInvoiceSidebar={() => setOpenDashboardInvoice(false)}
      />

      <AdminDashboardManageAccess
        isManageAccessSidebar={openDashboardManageAccess}
        onCloseManageAccessSidebar={() => setOpenDashboardManageAccess(false)}
      />

      <AdminDashboardManageHelpcenter
        isOpen={openManageHelpCenterDrawer}
        onClose={() => setOpenManageHelpCenterDrawer(false)}
      />

      <BusinessInformation
        isOpenSidebar={isBusinessInformationInfo}
        onCloseSidebar={() => {
          setIsBusinessInformationInfo(false);
        }}
      />

      <AdminDashboardInvoice
        isInvoiceSidebar={openDashboardInvoice}
        onCloseInvoiceSidebar={() => setOpenDashboardInvoice(false)}
      />

      {isSidebarSearchUser ? <SidebarSearchUser
        openSidebarSearchUserList={isSidebarSearchUser}
        onCloseSidebarSearchUserList={() => setIsSidebarSearchUser(false)}
      /> : null}

      {isLoggedIn ? (
        <Account
          isOpenSidebar={isAccountDrawer}
          onCloseSidebar={() => closeAccountDrawer()}
        />
      ) : (
        <Account
          isOpenSidebar={isAccountDrawer}
          onCloseSidebar={() => closeAccountDrawer()}
        />
      )}

      
    </>
  );
};

export default AdminDashboardMenu;
