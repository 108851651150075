import React, { useState, useRef, useEffect, useContext } from "react";
import PriceSimulatorModal from "../../../../components/reusable/PriceSimulatorModel";
import { Button, Col, Container, Row } from "react-bootstrap";
const DetailFooter = (props) => {
  const [isPriceSimulatorModal, setIsPriceSimulatorModal] = useState(false);
  const [changeTransition, setChangeTransition] = React.useState(false)

  React.useEffect(() => {
    setTimeout(function () { setChangeTransition(!changeTransition) }, 3000);
  }, [changeTransition])


  return (
    <Container className="clt-detail-footer-mbl">
      <Row>
      <Col className="d-flex flex-column justify-content-center clt-detail-footer-overflow-hidden clt-detail-footer-h-div">
        <h2>{props.footerTitleSection3}</h2>
        <span onClick={(e) => { e.preventDefault(); setIsPriceSimulatorModal(true) }}>
          Voir les prix
        </span>
      </Col>
        <Col>
          <Button
            onClick={props.setBottomUp}
            className="clt-detail-footer-mblButton shadow-none"
            style={{
              // background: props?.eventType?.background,
              background: props?.currentEvent?.background,
              border: `1px solid ${props.currentEvent.background}`,
            }}
          >
            <span>
              {props.eventType.detail_page === "wedding" ?
                <>
                  {changeTransition ? "Nous contacter" : "Réserver"}
                </>
                : "Nous contacter"
              }
              <i className="fa-light fa-chevron-down"></i>
            </span>
          </Button>
        </Col>
      </Row>
      <PriceSimulatorModal
        isShow={isPriceSimulatorModal}
        toggleModal={() => setIsPriceSimulatorModal(false)}
      />
    </Container>
    
    
  );
};

export default DetailFooter;
