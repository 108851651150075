/* Import */

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Offcanvas,
  // OverlayTrigger,
  Row,
  // Tooltip,
} from "react-bootstrap";
import BackArrow from "../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../constants";
// import { Link } from "react-router-dom";
import SectionRemainingPayment from "../../reusable/booking/SectionRemainingPayment";
import ConfirmPay4 from "../../../assets/img/confirm_pay_4.png";
import ConfirmPay5 from "../../../assets/img/confirm_pay_5.png";
import IconRemainingPayment from "../../../assets/img/remaining_payment.png";
import CustomHr from "../../reusable/CustomHr";
import SectionPaymentMethod from "../../reusable/booking/SectionPaymentMethod";
import SectionPaymentPlan from "../../reusable/booking/SectionPaymentPlan";
import SectionPriceDetail from "../../reusable/booking/SectionPriceDetail";

// import - Section - Room
import SectionBed from "../../reusable/booking/SectionRoom";
import Bed from "./bed/[SIDEBAR] User - My Booking - Option room";


/* Const */
const ConfirmAndPayOptionRoom = ({
  isSidebar,
  onCloseSidebar,
  isPaymentCardsOpen,
  isFromRoom,
  setpriceDetailsRooms,
  priceDetailsRooms,
  setBedCount,
  bedCount,
  selectedRoomsDetail,
  setRoomRefundDetail,
  roomRefundDetail,
}) => {

  const [remainingPaymentScreens, setRemainingPaymentScreens] = useState({
    remainingPayment: false,
    bedCounter: false,
    confirmAndPayRemainingPayment: true,
  });
  const [selectedPlan, setSelectedPlan] = useState({});

  /* Const - Section room */
  const [isBedSidebar, setIsBedSidebar] = useState(false);


  /* Const - Section - Price detail */
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Slot", tooltipMsg: null, price: "15€" },
  ]);
  // const [priceDetailsRooms, setpriceDetailsRooms] = useState([]);

  const handleBack = (from, to) => {
    if (!isFromRoom)
      setRemainingPaymentScreens((prevState) => ({
        ...prevState,
        [from]: !prevState[from],
        [to]: !prevState[to],
      }));
    else onCloseSidebar()
  };

  const handleClose = () => {
    setBedCount([]);
  };

  const handlePayNow = () => {
    setRemainingPaymentScreens((prevState) => ({
      ...prevState,
      remainingPayment: false,
      confirmAndPayRemainingPayment: true,
    }));
  };



  {/* Sidebar : Remaining payment / Confirm and pay - Remaining payment */ }
  return (

    <>
      <Offcanvas
        placement="end"
        className="clt_payment_offcanvas"
        show={isSidebar}
        onHide={onCloseSidebar}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-start clt_payment_offcanvas_header">
          {remainingPaymentScreens.remainingPayment && (
            <React.Fragment>

              {/* Header - Remaining payment */}
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseSidebar}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Remaining payment</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}

          {/* Header - Confirm and pay - Remaining payment */}
          {remainingPaymentScreens.confirmAndPayRemainingPayment && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => handleBack("confirmAndPayRemainingPayment", "remainingPayment")}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>
                  Confirm and pay - Option
                </Offcanvas.Title>
              </div>
            </React.Fragment>
          )}
        </Offcanvas.Header>





        {/* Body */}
        <Offcanvas.Body className={`clt_payment_offcanvas_body`}>


          {/* Body - Remaining payment */}
          {remainingPaymentScreens.remainingPayment && (
            <>
              <h2 className="clt-mybooking-title">Automatic Payment</h2>
              <SectionPaymentMethod
                text="Credit Card"
                image={ConfirmPay4}
                subText={<span>**** 4947</span>}
                handleClick={isPaymentCardsOpen}
                buttonText={"Edit"} />

              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionRemainingPayment
                image={IconRemainingPayment}
                title={`Remaining payment: 2`}
                paymentTexts={["5$ in 30 days", "5$ in 60 days"]} />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <Row>
                <Col>
                  <div className="clt-booking-cp-div7-btn mb-5">
                    <Button
                      className="shadow-none clt-booking-btn-gray"
                      onClick={handlePayNow}
                    >
                      <span>Pay now</span>
                    </Button>
                    <p className="clt-after-btn-p">
                      You will be redirected to a page of payment with the
                      possibility of paying a part or all of the remaining
                      payments.
                    </p>
                  </div>
                </Col>
              </Row>
            </>
          )}


          {/* Body - Confirm and pay - Optiont */}
          {remainingPaymentScreens.confirmAndPayRemainingPayment && (
            <>
              <h2 className="clt-booking-cp-div1-title">Option</h2>
              <SectionBed
                title="Room"
                image={ConfirmPay5}
                subText={"No room add or edit"}
                roomSelected={roomRefundDetail.length !== 0 ? roomRefundDetail : bedCount}
                setIsBedSidebar={() => setIsBedSidebar(true)}
                buttonText={bedCount.length > 0 ? "Edit" : "Add"}
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              {roomRefundDetail.length !== 0 ?
                <>
                  <SectionPriceDetail
                    title="Price detail - Refund"
                    priceListData={roomRefundDetail.map((item) => ({
                      title: `${item?.title} (${item?.night_quantity_variation} night)`,
                      price: `${item?.night_total_price}€`
                    }))
                    }
                    totalPrice={`${roomRefundDetail.reduce((total, item) => total + item.night_total_price, 0)}€`}
                  />
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                  <SectionPriceDetail
                    title="Price detail - Payment"
                    priceListData={
                      roomRefundDetail !== 0 ?
                        roomRefundDetail.map((item) => ({
                          title: `${item?.title} (${item?.night_quantity_variation} night)`,
                          price: `${item?.night_total_price}€`
                        }))
                        :
                        priceDetailsSlots.concat(
                          priceDetailsRooms
                        )}
                    totalPrice="25€"
                  />
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <SectionPaymentMethod
                    text="Pay with"
                    image={ConfirmPay4}
                    subText={<span>Add a payment method</span>}
                    handleClick={isPaymentCardsOpen}
                    buttonText={"Add"} />
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                </>
              :''}
              <div className="pfr_payment_space_bottom" />
              <div className="clt_payment_footer">
                <Button
                  className="shadow-none"
                  disabled={bedCount == 0 ? true : false}
                  // onClick={() => handleBack("listRoom", "editRoom")}
                  onClick={onCloseSidebar}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Bed
        isBedSidebar={isBedSidebar}
        onCloseBedSidebar={() => setIsBedSidebar(false)}
        onConfirm={(val) => {
          setpriceDetailsRooms(
            val.map((room) => {
              return {
                title: `${room.title} (${room.night_quantity} night)`,
                tooltipMsg: null,
                price: `${room.night_total_price}€`,
              };
            })
          );
          setBedCount(val);
        }}
        roomRefundDetail={roomRefundDetail}
        setRoomRefundDetail={setRoomRefundDetail}
        selectedRoomsDetail={selectedRoomsDetail}
      />
    </>

  );
};

export default ConfirmAndPayOptionRoom;
