// Import
import React, { useState } from "react";
import { Offcanvas, Form, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../../../../assets/img/back_arrow.png";
import { IMG_ALT, eventweddingData } from "../../../../../../../constants";
import SectionTop from "../../../../../../reusable/booking/SectionTop";
import ConfirmPayProfilePicture1 from "../../../../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay2 from "../../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../../../../assets/img/confirm_pay_4.png";
import ConfirmPay5 from "../../../../../../../assets/img/confirm_pay_5.png";
import CustomHr from "../../../../../../reusable/CustomHr";
import SectionDate from "../../../../../../reusable/booking/SectionDate";
import SectionInformationMessageRequestAccept from "../../../../../../reusable/SectionInformationMessageRequestAccept.js";
import Payment from "../../../../../payment/Payment";
import SectionPriceDetail from "../../../../../../reusable/booking/SectionPriceDetailPendingEditRequest.js";
import SectionPaymentMethod from "../../../../../../reusable/booking/SectionPaymentMethod";
import { SuccessToast } from "../../../../../../reusable/Toast";
// import - Section - Event
import SectionEvent from "../../../../../../reusable/booking/SectionEvent";
import Event from "../../../../../event/Event";
// import - Section - Guest
import SectionGuest from "../../../../../../reusable/booking/SectionGuest";
import Guest from "../../../../../guest/Guest";
// import - Section - Room
import SectionBed from "../../../../../../reusable/booking/SectionRoom";
import Bed from "../../../../../bed/OptionRoom";



/* Const */
const SidebarCancelRequestAcceptAdmin = ({ isOpenSidebarCancelRequestAcceptAdmin, onCloseOpenSidebarCancelRequestAcceptAdmin, setIsOpenSidebarCancelRequestAcceptAdmin, data, selectedBooking }) => {
  

  /* Const - Section - Event */
  const [eventType, setEventType] = useState(eventweddingData);
  const [isEventSidebar, setIsEventSidebar] = useState(false);

  /* Const - Section - Date */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);
  let bookingProps = {
    isFromBooking: true,
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - Guest */
  const [isGuestSidebar, setIsGuestSidebar] = useState(false);
  const [guestCount, setGuestCount] = useState(10);

  /* Const - Section room */
  const [isBedSidebar, setIsBedSidebar] = useState(false);
  const [confirmPayOptionRoom, setConfirmPayOptionRoom] = useState(false);
  const [bedCount, setBedCount] = useState([
    { roomTitle: "Room 1", nights: "1 night (10 €)" },
    { roomTitle: "Room 2", nights: "2 night (20 €)" },
  ]);
  const [editBedSettings, setEditBedSettings] = useState(false)
  const handleBed = () => {
    setIsBedSidebar(true);
    setEditBedSettings(true)
  };

  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Booking refund", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Section - Payment plan */
  const [selectedPlan, setSelectedPlan] = useState({});

  /* Const - Section - Payment method */
  const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
  const [isPaymentSidebar, setIsPaymentSidebar] = useState(false);



  /* Const - Cancel request */
  const handleRefuse = () => {
    SuccessToast("La demande a été annulé");
    onCloseOpenSidebarCancelRequestAcceptAdmin();
  };
  let editrequestProps = {
  };



  {/* Sidebar - Admin - Edit request - Pending */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarCancelRequestAcceptAdmin}
        onHide={onCloseOpenSidebarCancelRequestAcceptAdmin}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarCancelRequestAcceptAdmin}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>Demande annulation acceptée</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          <>
            <div className="clt_mybooking_offcanvas_body">


            <SectionTop
                image={ConfirmPayProfilePicture1}
                title="CLT"
                subTitle={`Réservation pour un évenement ${selectedBooking?.Event?.title} · #${data.BookingId}`}
              />
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionInformationMessageRequestAccept
                innerTitle="Annulée"
                innerSubTitle="Votre réservation est désormais annulée"
              />
            </div>
            <CustomHr
              rowClass="p-0 m-0"
              colClass="clt-booking-hr-pd p-0 w-100"
              hrClass="p-0 m-0 clt-hr-2"
            />
            
            <div className="clt_mybooking_offcanvas_body">
              <h2 className="clt-mybooking-title">Demande annulation</h2>


              {/* Input - Reason of the cancellation */}
              <div className="clt_inputFloatCancellationDiv clt_inputFloatCancellation_top">
                <Form.Floating className="clt_inputFloatCancellation">
                  <Form.Control
                    id="floatingInput1"
                    type="text"
                    placeholder="Raison annulation"
                    value={`${data.reason}`}
                  />
                  <label htmlFor="floatingInput1">Raison annulation</label>
                </Form.Floating>
              </div>



              {/* Input - Message */}
              <div className="clt_inputFloatCancellationDiv">
                <Form.Floating className="clt_inputFloatCancellation clt_inputFloatCancellation_bottom ">
                  <Form.Control
                    id="floatingInput1"
                    type="text"
                    placeholder="Message"
                    value={`${data.message}`}
                  />
                  <label htmlFor="floatingInput1">Message</label>
                </Form.Floating>
              </div>

              {/* Section - Price detail 
              <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
              <SectionPriceDetail
                title="Detail refund"
                priceListData={priceDetailsSlots}
                totalPrice="10€"
              />*/}
              
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>





      {/* Function - Section - Event */}
      <Event
        show={isEventSidebar}
        onHide={() => setIsEventSidebar(false)}
        eventType={eventType}
        onConfirmEvent={(event) => {
          if (event !== eventType) {
            setIsBookingSidebar(true);
          }
          setEventType(event);
        }}
      />

      {/* Function - Section - Guest */}
      <Guest
        isGuestSidebar={isGuestSidebar}
        editGuestValue={guestCount}
        onCloseGuestSidebar={(guestCount) => {
          setIsGuestSidebar(false);
          setGuestCount(guestCount);
        }}
      />

      {/* Function - Section - Room */}
      <Bed
        isBedSidebar={isBedSidebar}
        isComingFromMyBooking={false}
      />

      {/* Function - Section Payment method */}
      <Payment
        isPaymentSidebar={isPaymentSidebar}
        onClosePaymentSidebar={() => setIsPaymentSidebar(false)}
        isFromBooking={true}
        getPaymentCard={(val) => setIsPaymentCardSelected(val)}
      />
    </>
  );
};

export default SidebarCancelRequestAcceptAdmin;
