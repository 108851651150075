import React from "react";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import NextArrow from "../../../assets/img/back_arrow_right.png";
import NextArrowWhite from "../../../assets/img/back_arrow_right_white_thin.png";

const SectionContactRequestGuest = ({ props, image, IsGuestButtonDropdown, subText, buttonText, guestCount, setGuestCount, handleRequestEdit }) => {

  const CustomToggleThird = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="clt_right_form_filter_chip"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      Modifier
      <img
        src={NextArrow}
        className="clt_admin_arrow down"
        alt={IMG_ALT}
      />
    </p>
  ));
  
  // Check if the props object and the required properties are defined
  // const guestCount = props && props.data ? props.data.guestCount : null;
  // const setGuestCount = props && props.data ? props.data.setGuestCount : null;

  const handleGuestNumber = (guest) => {
    setGuestCount(guest);
    handleRequestEdit({ guest_number: guest });
  }

  return (
    <Row className="clt-booking-mb">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Invité</h2>
              <p>{guestCount ? guestCount : subText} </p>
            </div>

            {/* Dropdown - Guest */}
            { IsGuestButtonDropdown && (
              <div className="clt_right_form_filter_chip_container">
                <Dropdown className={""}>
                  <Dropdown.Toggle as={CustomToggleThird} />
                  <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu_month_year">
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("0-20")}
                    >
                      0-20
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("20-40")}
                    >
                      20-40
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("60-80")}
                    >
                      60-80
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("80-100")}
                    >
                      80-100
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("100-120")}
                    >
                      100-120
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("120-140")}
                    >
                      120-140
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("140-160")}
                    >
                      140-160
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("160-180")}
                    >
                      160-180
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pfr_payment_cards3dots_options"
                      onClick={() => handleGuestNumber("180-200")}
                    >
                      180-200
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SectionContactRequestGuest;