import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import './Section.css';

const SectionItemAdditional = ({
  image,
  subText,
  buttonText,
  onClickSidebar,
  sectionItemAdditional,
  hideButton,
  optionDetail,
  handleSidebarOpen, // Added this prop
}) => {
  return (
    <Row className="clt-booking-mb notranslate">
      <Col>
        <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
          <img src={image} alt={IMG_ALT} />
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
              <h2>Option</h2>
              {sectionItemAdditional && sectionItemAdditional.length ? (
                sectionItemAdditional
                  .filter((item) => item.price !== null)
                  .map((item, index) => (
                    <p key={index}>{`${item.title}(${item.price}€)`}</p>
                  ))
              ) : (
                <p>{`Aucune option`}</p>
              )}
            </div>
          </div>
          {optionDetail && (
            <Button className="shadow-none" onClick={handleSidebarOpen}>
              <span>{buttonText}</span>
            </Button>
          )}
        </div>
        {!hideButton && (
          <div className="clt-booking-btn-refuse-div">
            <Button
              className="shadow-none clt-booking-btn-refuse mt-3"
              onClick={onClickSidebar}
            >
              <span>{buttonText || 'Gérer option'}</span>
            </Button>
          </div>
        )}
      </Col>
    </Row>
    
  );
};

export default SectionItemAdditional;
