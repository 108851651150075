import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import "../account.css";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { API_ENDPOINT, IMG_ALT } from "../../../../constants";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import ReactCodeInput from "react-verification-code-input";
import {
	PhoneAuthProvider,
	RecaptchaVerifier,
	updatePhoneNumber,
} from "firebase/auth";
import { auth } from "../../auth/firebase";
import { useAuth } from "../../auth/authContext";
import { ErrorToast } from "../../../reusable/Toast";
import CustomButton from "../../../reusable/CustomButton"; // Import the CustomButton component
import axios from "axios";
const COUNTER = 30;
const AccountInputPhone = ({
	isSidebar,
	onCloseSidebar,
	title,
	inputData,
	onUpdateData,
}) => {
	const { user } = useAuth();
	// eslint-disable-next-line no-unused-vars
	const [inputValue, setInputValue] = useState({ ...inputData });
	useEffect(() => {
		console.log("running", inputData);
		setInputValue(inputData);
	}, [inputData]);
	const [inputScreens, setInputScreens] = useState({
		inputScreen: true,
		confirmScreen: false,
	});

	const [isCompleted, setIsCompleted] = useState(false)

	const [counter, setCounter] = useState(COUNTER);

	const counterRef = useRef(COUNTER);
	useEffect(() => {
		setCounter(COUNTER);
		counterRef.current = COUNTER
	}, []);

	
	useEffect(() => {
		const timer = setInterval(() => {
			if (inputScreens.confirmScreen) {
				console.log('confirm screen')
				if (counterRef.current === 0) {
					console.log(counter)
					counterRef.current = -1
					setIsCompleted(true)
					clearInterval(timer);
					return;
				}		
				if (!isCompleted) {
					console.log('counter')
					counterRef.current--;
					setCounter(c => c - 1);
				} else {
					setCounter(COUNTER);
					counterRef.current = COUNTER;
				} 
			};
		}, 1000);
		return () => clearInterval(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCompleted, inputScreens]);


	const recaptchaRef = useRef(null)
	const [code, setCode] = useState("");
	const [verificationId, setVerificationId] = useState(null);
	const handleInputChange = (val) => {
		setInputValue((prevState) => ({
			...prevState,
			value: val,
		}));
	};
	const handleConfirm = async () => {
		try {
			const phoneCredentials = PhoneAuthProvider.credential(
				verificationId,
				code
			);
			await updatePhoneNumber(auth.currentUser, phoneCredentials);
		} catch (error) {
			ErrorToast(error.message);
			return;
		}

		onUpdateData({ name: inputValue.name, value: inputValue.value });
		setInputScreens((prevState) => ({
			...prevState,
			inputScreen: true,
			confirmScreen: false,
		}));
		setInputValue({});
		onCloseSidebar();
	};

	const handleBack = (from, to) => {
		setInputScreens((prevState) => ({
			...prevState,
			[from]: !prevState[from],
			[to]: !prevState[to],
		}));
	};

	const handleSave = async () => {
		if (inputValue.value === user.phone) {
			ErrorToast("Aucun changement de numéro de téléphone");
			
			onCloseSidebar();
			return;
		}

		try {
			const checkPhoneResponse = await axios.post(API_ENDPOINT + "/auth/check", {
				phone: inputValue.value,
			});
			if (checkPhoneResponse.data.userExists) {
				throw new Error(
					"Un compte avec ce numéro de téléphone a déjà été enregistré"
				);
			}
		} catch (error) {
			onCloseSidebar();
			ErrorToast(error.message);
			return;
		}

		if (!recaptchaRef.current) {
			let verifier = new RecaptchaVerifier(
				"recaptcha-container",
				{
					size: "invisible",
				},
				auth
			);
			recaptchaRef.current = verifier;
		}

		const provider = new PhoneAuthProvider(auth);

		try {
			var verificationId = await provider.verifyPhoneNumber(
				inputValue.value,
				recaptchaRef.current
			);
			setVerificationId(verificationId);
			setInputScreens((prevState) => ({
				...prevState,
				inputScreen: false,
				confirmScreen: true,
			}));
			recaptchaRef.current = null
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const sendCodeAgain = async () => {
		if (!isCompleted) return;

		try {
			if (!recaptchaRef.current) {
				let verifier = new RecaptchaVerifier(
					"recaptcha-container-confirm",
					{
						size: "invisible",
					},
					auth
				);
				recaptchaRef.current = verifier;
			}
	
			const provider = new PhoneAuthProvider(auth);
	
			var verificationId = await provider.verifyPhoneNumber(
				inputValue.value,
				recaptchaRef.current
			);
			setVerificationId(verificationId);
			setIsCompleted(false)
		} catch (error) {
			console.log(error);
			return;
		}
	}

	return (
		<Offcanvas
			placement="end"
			className="clt_payment_offcanvas"
			show={isSidebar}
			onHide={onCloseSidebar}
		>
			<Offcanvas.Header className="justify-content-start clt_account_offcanvas_header">
				<>
					{inputScreens.inputScreen && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={onCloseSidebar}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>{title}</Offcanvas.Title>
							</div>
						</React.Fragment>
					)}
					{inputScreens.confirmScreen && (
						<React.Fragment>
							<button
								type="button"
								className="btn-close btn-back shadow-none d-flex"
								aria-label="Close"
								onClick={() => handleBack("confirmScreen", "inputScreen")}
							>
								<img src={BackArrow} alt={IMG_ALT} />
							</button>
							<div className="d-flex align-items-center justify-content-between w-100">
								<Offcanvas.Title>Code Verification </Offcanvas.Title>
							</div>
						</React.Fragment>
					)}
				</>
			</Offcanvas.Header>
			<Offcanvas.Body className={`clt_payment_offcanvas_body`}>
				<>
					{inputScreens.inputScreen && (
						<>
							<Row>
								<Col className="pfr_loginModalTelInputDiv">
									<div className="pfr_loginModalTelInputInnerDiv">
										<PhoneInput
											international
											withCountryCallingCode
											defaultCountry="FR"
											countryCallingCodeEditable={false}
											value={inputValue.value}
											onCountryChange={(crt) => console.log(crt)}
											focusInputOnCountrySelection
											autoFocus
											onChange={(val) => handleInputChange(val)}
											className="pfr_loginModalTelInput"
										/>
										<span className="pfr_loginModalTelInputLabel toFloat">
											{inputData.placeholder}
										</span>
									</div>
								</Col>
							</Row>
							<div id="recaptcha-container"></div>
							<div className="clt_payment_footer">
								<Button
									className="shadow-none clt_payment_header_button_black"
									disabled={
										inputValue.value === "" || inputValue.value === undefined
											? true
											: false
									}
									onClick={handleSave}
								>
									Enregistrer
								</Button>
							</div>
						</>
					)}
					{inputScreens.confirmScreen && (
						<>
							<div className="pfr_loginModalVerify">
								<div className="pfr_loginModalVerifyDiv">
									<Row>
										<Col>
											<h2 className="pfr_loginModalH2">
												Entrez le code envoyé à{" "}
												{formatPhoneNumberIntl(inputValue.value)}
											</h2>
										</Col>
									</Row>
									<Row>
										<Col>
											<ReactCodeInput
												type="number"
												fields={6}
												onChange={(val) => setCode(val)}
												onComplete={(val) => console.log(val)}
												autoFocus={true}
												// loading
												className="pfr_signUpNumberVerify"
											/>
										</Col>
									</Row>
									<div id="recaptcha-container-confirm"></div>

									<Row>
										<Col>
											<div style={{ opacity: !isCompleted ? 0.6 : 1 }} className="d-flex justify-content-start clt-account-timer-msg pfr_loginForgotPassDiv">
											<CustomButton
												onClick={sendCodeAgain}
												disabled={!isCompleted}
												text={!isCompleted ? `Je n'ai pas reçu de code (0:${counter})` : `Je n'ai pas reçu de code`}
											/>
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<div className="clt_payment_footer">
								<Button
									className="shadow-none clt_payment_header_button_black"
									onClick={handleConfirm}
								>
									Confirmer
								</Button>
							</div>
						</>
					)}
				</>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

export default AccountInputPhone;
