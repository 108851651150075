/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Col, Row, Form, InputGroup } from "react-bootstrap";
import { IMG_ALT } from "../../../constants";
import Switch from "@mui/material/Switch";
import './Section.css'
import CustomHr from "../CustomHr";
import { useRadioGroup } from "@mui/material";
const SectionSlotRefund = ({ selectedEvent, setSelectedEvent,changeRefundAmount,availRefundamount,orirefundamount,booking,setSelectedSubPayment }) => {
    const calculateBalance = () =>{
        console.log("AVAILs");
        console.log(booking?.BookingRooms);
        let onePrice = 0;
        for (let i = 0; i<booking?.BookingRooms.length;i++){
            const bookingRoom = booking?.BookingRooms[i];
            onePrice+=bookingRoom.price;
        }
        // if (availRefundamount){
        //     let onePrice = 0;
        //     for (let i = 0; i < (availRefundamount?.length); i++) {
        //         console.log(availRefundamount[i]);
        //         console.log(availRefundamount[i]?.price?.replace(/[^0-9.-]+/g, ''));
        //         if (availRefundamount[i]?.amount) {
        //             const price = parseFloat(availRefundamount[i]?.amount?.replace(/[^0-9.-]+/g, ''));
        //             onePrice += price
        //         }
        //     }
        //     return orirefundamount-onePrice;
        // }else {
        //     return orirefundamount;
        // }
        return orirefundamount-onePrice;
    }

    const calculateSubscriptionBalance = (index) => {
        let onePrice = 0;
        for (let i = 0; i<booking?.BookingRooms.length;i++){
            const bookingRoom = booking?.BookingRooms[i];
            onePrice+=bookingRoom.price;
        }
        return Math.round(((orirefundamount-onePrice)/3 + Number.EPSILON) * 100) / 100;
        // if (availRefundamount){
        //     let onePrice = 0;
        //     for (let i = 0; i < (availRefundamount?.length); i++) {
        //         console.log(availRefundamount[i]);
        //         console.log(availRefundamount[i]?.price?.replace(/[^0-9.-]+/g, ''));
        //         if (availRefundamount[i]?.amount) {
        //             if (booking.subscription_payments[index].invoice  === availRefundamount[i].invoice){
        //                 const price = parseFloat(availRefundamount[i]?.amount?.replace(/[^0-9.-]+/g, ''));
        //                 onePrice += Math.round((price + Number.EPSILON) * 100) / 100;
        //             }
        //         }
        //     }
        //     return Math.round(((booking.subscription_payments[index].subtotal/100)-onePrice + Number.EPSILON) * 100) / 100;
        // }else {
        //     return booking.subscription_payments[index].subtotal/100;
        // }
    }

    const handleKeyDown = (event) => {
    // Allow only numeric values, backspace, delete, and the characters '.', ','
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", ".", ","];

    // Replace ',' with '.'
    if (event.key === ',') {
        event.preventDefault();
        const inputElement = event.target;
        const cursorPosition = inputElement.selectionStart;
        const updatedValue = inputElement.value.substring(0, cursorPosition) + '.' + inputElement.value.substring(cursorPosition + 1);
        inputElement.value = updatedValue;
    }

    // Prevent other characters that are not allowed
    if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
    }
    };

    
    return (
        <Row className="slot_refund">
            <Col>
                <div className="d-flex justify-content-start align-items-start ">
                    <div className="d-flex justify-content-between align-items-start w-100 flex-column">
                        <h2 className="clt-booking-cp-div5-title">Remboursement</h2>
                        <h2 className="clt-booking-cp-div7-title">Selectionner un plan de remboursement.</h2>
                        <div className="mt-2 d-flex w-100 justify-content-start align-items-start flex-row">
                            <div className="mb-3">
                                <Form className=" d-flex w-100 justify-content-start align-items-start flex-column ">
                                    <Form.Check
                                        onChange={(e) => setSelectedEvent(1)}
                                        label={
                                            <>
                                                <h3 className="h3">Complet</h3>
                                                <p>Remboursement complet</p>
                                            </>
                                        }
                                        name="group1"
                                        type="radio"
                                        id={`radio-1`}
                                        className="pb-3 border-bottom w-100 mb-3"
                                        checked={selectedEvent === 1 ? true : false}
                                    />

                                    <Form.Check
                                        onChange={(e) => setSelectedEvent(2)}
                                        name="group1"
                                        label={
                                            <>
                                                <h3 className="h3">Définir</h3>
                                                <p>Saisissez le montant à remboursé</p>
                                            </>
                                        }
                                        type="radio"
                                        id={`radio-3`}
                                        className="pb-3 w-100"
                                        checked={selectedEvent === 2 ? true : false}
                                    />
                                    {selectedEvent === 2 && (
                                        <>
                                            {
                                                booking.payment_id.startsWith("sub_")?
                                                    <>
                                                        {
                                                            booking.subscription_payments.map((item,index) =>
                                                            <>
                                                                <div className="w-100" style={{ paddingLeft: "30px" }}>
                                                                    <Form.Floating className="clt_deposit_dash_timeslot_inputFloat">
                                                                        <InputGroup>
                                                                        <Form.Control
                                                                            id="floatingInput1"
                                                                            type="number"
                                                                            onChange={(event) => setSelectedSubPayment(event, item)}
                                                                            onKeyDown={handleKeyDown} // Add this line
                                                                            max={calculateSubscriptionBalance(index)}
                                                                            placeholder=""
                                                                        />
                                                                            <InputGroup.Text id="inputGroup-sizing-sm">€</InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Form.Floating>
                                                                </div>
                                                                <div className="w-100" style={{ paddingLeft: "30px" }}>
                                                                    <h2 className="clt-admin-managebooking-text-custom-refund">(Paiement {index + 1} = {calculateSubscriptionBalance(index)}€)</h2>
                                                                </div>
                                                            </>
                                                            )
                                                        }
                                                    </>:
                                                    <>
                                                        <div className="w-100" style={{ paddingLeft: "30px" }}>
                                                            <Form.Floating className="clt_deposit_dash_timeslot_inputFloat">
                                                                <InputGroup>
                                                                <Form.Control
                                                                    id="floatingInput1"
                                                                    type="number"
                                                                    onChange={(event) => changeRefundAmount(event)}
                                                                    onKeyDown={handleKeyDown} // Add this line
                                                                    max={calculateBalance()}
                                                                    placeholder=""
                                                                />

                                                                    <InputGroup.Text id="inputGroup-sizing-sm">€</InputGroup.Text>
                                                                </InputGroup>
                                                            </Form.Floating>
                                                        </div>
                                                        <div className="w-100" style={{ paddingLeft: "30px" }}>
                                                            <h2 className="clt-admin-managebooking-text-custom-refund">(Paiement: {calculateBalance()}€)</h2>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                        
                                    )}
                                    
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

            </Col>
        </Row>
    );
};

export default SectionSlotRefund;