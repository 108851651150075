import React, { useState } from "react";
import { Button, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import "./SidebarInvoiceList.css";
import BackArrow from "../../../assets/img/back_arrow.png";
import InvoiceIcon from "../../../assets/img/icon_invoice.png";
import {API_ENDPOINT, IMG_ALT} from "../../../constants";
import { Link } from "react-router-dom";
import {auth} from "../../screens/auth/firebase";

const InvoiceOffcanvas = ({
    isInvoiceSidebar,
    onCloseInvoiceSidebar,
    booking
}) => {

    const [isInvoiceCardSelected, setIsInvoiceCardSelected] = useState(isInvoiceSidebar);
    console.log("My Bookings");
    console.log(booking);
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            className="clt_reuseable_invoice_card3dot"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                console.log(e);
            }}
        >
            {children}
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </Link>
    ));

    const handleCloseSidebar = () => {
        if (!isInvoiceCardSelected)
            onCloseInvoiceSidebar()
        else
            setIsInvoiceCardSelected(false)
    }

    const handleReceiptClick = async (id,time='') => {
        const token = await auth.currentUser.getIdToken();
        const newWindow = window.open(API_ENDPOINT + "/booking/receipt/" + token + "/" + btoa(id)+'/'+time, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    const handleRefundInvoice = async (id,invoice) =>{
        const token = await auth.currentUser.getIdToken();
        const newWindow = window.open(API_ENDPOINT + "/booking/refundInvoice/" + token + "/" + btoa(id)+'/'+invoice, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    const handleReceiptClickAdditional = async (id, aId) => {
        const token = await auth.currentUser.getIdToken();
        const newWindow = window.open(API_ENDPOINT + "/booking/receiptAdditional/" + token + "/" + btoa(id)+'/'+aId, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <Offcanvas
            placement="end"
            className="clt_reuseable_invoice_offcanvas"
            show={isInvoiceSidebar}
            onHide={onCloseInvoiceSidebar}
        >
            <Offcanvas.Header className="justify-content-start clt_reuseable_invoice_offcanvas_header">

                <React.Fragment>
                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={handleCloseSidebar}
                    >
                        <img src={BackArrow} alt={IMG_ALT} />
                    </button>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title className="clt_reuseable_invoice_title">Facture</Offcanvas.Title>
                        {/* export button  */}
                        {/* <Button className="clt_reuseable_invoice_header_button shadow-none">
                            Export
                        </Button> */}
                    </div>
                </React.Fragment>


            </Offcanvas.Header>
            <Offcanvas.Body
                className="clt_reuseable_invoice_offcanvas_body"
            >
                <>
                    <div className="clt_reuseable_invoice_innerdivs">
                        <h2 className="clt_reuseable_invoice_innerhead">Facture</h2>
                        {booking?.payment_id?.startsWith("sub_")?
                            <>{booking?.subscription_payments?.map((subscription, i) => <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                                <div
                                    className="d-flex align-items-center justify-content-start"
                                    style={{ width: "20%" }}
                                >
                                    <div className="clt_reuseable_invoice_imgdiv">
                                        <img
                                            src={InvoiceIcon}
                                            className=""
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-start justify-content-center flex-column"
                                    style={{ width: "70%" }}
                                >
                                    <h3 className="clt_reuseable_invoice_cardnumber">#F{zeroPad(subscription?.invoice,8)}</h3>
                                    <p className="clt_reuseable_invoice_cardname">Paiement ({i+1}/3) - {booking?.message?new Date(subscription?.created*1000).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false}):new Date(subscription?.created*1000).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                    {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                    style={{ width: "10%" }}
                                >
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title=""
                                                       className="pfr_dropdown_menu">
                                            <Dropdown.Item
                                                onClick={()=>{handleReceiptClick(booking?.message?booking?.message?.id:booking?.id,i+1)}}
                                                className="clt_reuseable_invoice_cards3dots_options"
                                            >
                                                Voir facture
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>)}</>
                            :<div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F{booking?.message?zeroPad(booking?.payment?.id,8):zeroPad(booking?.subscription_payments?.paymentId,8)}</h3>
                                <p className="clt_reuseable_invoice_cardname">Paiement (1/1) - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false}):new Date(booking?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                                   className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            onClick={()=>{handleReceiptClick(booking?.message?booking?.message?.id:booking?.id)}}
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            Voir facture
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>}
                        {
                            booking?.refund_data?.map((refund,i) => <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                            <div
                            className="d-flex align-items-center justify-content-start"
                            style={{ width: "20%" }}
                    >
                        <div className="clt_reuseable_invoice_imgdiv">
                            <img
                                src={InvoiceIcon}
                                className=""
                                alt="ParkingAeroPortFr"
                            />
                        </div>
                    </div>
                    <div
                        className="d-flex align-items-start justify-content-center flex-column"
                        style={{ width: "70%" }}
                    >
                        <h3 className="clt_reuseable_invoice_cardnumber">#FA{zeroPad(refund?.id,7)}</h3>
                        <p className="clt_reuseable_invoice_cardname">Annulation {booking?.message?new Date(refund?.created*1000).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false}):new Date(refund?.created*1000).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                        {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                    </div>
                    <div
                        className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                        style={{ width: "10%" }}
                    >
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title=""
                                           className="pfr_dropdown_menu">
                                <Dropdown.Item
                                    onClick={()=>{handleRefundInvoice(booking?.message?booking?.message?.id:booking?.id,refund?.invoice)}}
                                    className="clt_reuseable_invoice_cards3dots_options"
                                >
                                    Voir facture
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>)
                        }
                        {
                            booking?.Payments && booking?.Payments?.length ? 
                                booking?.Payments?.map((item, i) => (
                                    <div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">
                                        <div
                                            className="d-flex align-items-center justify-content-start"
                                            style={{ width: "20%" }}
                                        >
                                            <div className="clt_reuseable_invoice_imgdiv">
                                                <img
                                                    src={InvoiceIcon}
                                                    className=""
                                                    alt="Image"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex align-items-start justify-content-center flex-column"
                                            style={{ width: "70%" }}
                                        >
                                            <h3 className="clt_reuseable_invoice_cardnumber">#F{zeroPad(item?.id,8)}</h3>
                                            <p className="clt_reuseable_invoice_cardname">Payment additional item - {new Date(item?.createdAt).toLocaleDateString('en-NZ', {  day:"numeric",month:"short", hour:"2-digit",minute:"2-digit",hour12: false})} </p>
                                            {/*<p className="clt_reuseable_invoice_cardname">Payment Booking - {booking?.message?new Date(booking?.message?.createdAt).toLocaleDateString('en-eu', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"}):new Date(booking?.createdAt).toLocaleDateString('es-US', {  day:"numeric",month:"short", hour:"numeric",minute:"numeric"})} </p>*/}
                                        </div>
                                        <div
                                            className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                            style={{ width: "10%" }}
                                        >
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu size="sm" title=""
                                                            className="pfr_dropdown_menu">
                                                    <Dropdown.Item
                                                        onClick={()=>{handleReceiptClickAdditional(booking?.id, item?.id)}}
                                                        className="clt_reuseable_invoice_cards3dots_options"
                                                    >
                                                        Voir facture
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                ))
                            :
                              null
                        }

                        {/*<div className="d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv">*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-center justify-content-start"*/}
                        {/*        style={{ width: "20%" }}*/}
                        {/*    >*/}
                        {/*        <div className="clt_reuseable_invoice_imgdiv">*/}
                        {/*            <img*/}
                        {/*                src={InvoiceIcon}*/}
                        {/*                className=""*/}
                        {/*                alt="Image"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-start justify-content-center flex-column"*/}
                        {/*        style={{ width: "70%" }}*/}
                        {/*    >*/}
                        {/*        <h3 className="clt_reuseable_invoice_cardnumber">#F10000001</h3>*/}
                        {/*        <p className="clt_reuseable_invoice_cardname">Payment Booking - 01 Jan 23:59 </p>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"*/}
                        {/*        style={{ width: "10%" }}*/}
                        {/*    >*/}
                        {/*        <Dropdown>*/}
                        {/*            <Dropdown.Toggle as={CustomToggle} />*/}
                        {/*            <Dropdown.Menu size="sm" title=""*/}
                        {/*                className="pfr_dropdown_menu">*/}
                        {/*                <Dropdown.Item*/}
                        {/*                    className="clt_reuseable_invoice_cards3dots_options"*/}
                        {/*                >*/}
                        {/*                    View the receipt*/}
                        {/*                </Dropdown.Item>*/}
                        {/*            </Dropdown.Menu>*/}
                        {/*        </Dropdown>*/}

                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*
                        <div className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}>
                            <div className="d-flex align-items-center justify-content-start" style={{ width: "20%" }}>
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img src={InvoiceIcon} className="" alt="Image"/>
                                </div>
                            </div>
                            <div className="d-flex align-items-start justify-content-center flex-column" style={{ width: "70%" }}>
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000004</h3>
                                <p className="clt_reuseable_invoice_cardname">Refund - Booking - 01 Jan 23:59 </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots" style={{ width: "10%" }} >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div
                            className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000003</h3>
                                <p className="clt_reuseable_invoice_cardname">Refund - Edit - 01 Jan 23:59 </p>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div
                            className={`d-flex align-items-center justify-content-start clt_reuseable_invoice_cardsdiv }`}
                        >
                            <div
                                className="d-flex align-items-center justify-content-start"
                                style={{ width: "20%" }}
                            >
                                <div className="clt_reuseable_invoice_imgdiv">
                                    <img
                                        src={InvoiceIcon}
                                        className=""
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-start justify-content-center flex-column"
                                style={{ width: "70%" }}
                            >
                                <h3 className="clt_reuseable_invoice_cardnumber">#F10000002</h3>
                                <p className="clt_reuseable_invoice_cardname">Payment - Edit - 01 Jan 23:59 </p>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-end clt_reuseable_invoice_cards3dots"
                                style={{ width: "10%" }}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm" title=""
                                        className="pfr_dropdown_menu">
                                        <Dropdown.Item
                                            className="clt_reuseable_invoice_cards3dots_options"
                                        >
                                            View the receipt
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        */}
                        

                    </div>
                </>

            </Offcanvas.Body>
        </Offcanvas >
    );
};

export default InvoiceOffcanvas;
