import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";

import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import CustomHr from "../../../../../reusable/CustomHr";
import { Link } from "react-router-dom";
import TextEditor from "../../../../../reusable/TextEditor";
import { v4 as uuid } from "uuid";

const headingStyle = {
  fontSize: "18px",
  color: "#000",
};
const rowStyle = {
  marginTop: "-25px",
};
function CreateSection({ isOpen, onClose, addNewContent }) {
  const [title, setTile] = useState("");
  const [content, setContent] = useState("");

  return (
    <Offcanvas
      placement="end"
      className="clt_admin_dash_manage_access_offcanvas"
      backdrop={false}
      show={isOpen}
      onHide={onClose}
    >
      <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={onClose}
          >
            <img src={BackArrow} alt={IMG_ALT} />
          </button>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Offcanvas.Title>Create section</Offcanvas.Title>
          </div>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col className="clt-admin-event-info-input-div">
            <h2 class="clt_admin_dash_menu_container_name" style={headingStyle}>
              Title
            </h2>
            <Form.Floating className="pfr_inputFloat">
              <Form.Control
                id="title"
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTile(e.target.value)}
              />
              <label htmlFor="title">Title</label>
            </Form.Floating>
          </Col>
        </Row>
        <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
        <Row style={rowStyle}>
          <Col className="clt-admin-event-info-input-div">
            <h2 class="clt_admin_dash_menu_container_name" style={headingStyle}>
              Content
            </h2>
            <TextEditor
              value={content}
              setValue={(e) => setContent(e)}
              placeholder={"Type your content here"}
            />
          </Col>
        </Row>

        <div className="clt_payment_footer">
          <Button
            className="shadow-none clt_payment_header_button_black"
            disabled={title === "" || content === ""}
            onClick={() =>
              addNewContent({
                title,
                content,
                id: uuid(),
                isNew: true,
              })
            }
          >
            Save
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CreateSection;
