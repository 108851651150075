import React, { useContext, useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AuthContext } from "../../screens/auth/authContext";

const SectionPriceDetail = ({ title, priceListData, refundListData, totalPrice, parent }) => {
  const [combinePrice, setCombinePrice] = useState(0);
  const { selectedPrice, setSelectedPrice } = useContext(AuthContext);
  useEffect(() => {
    let onePrice = 0
    if (parent == 'user_mybooking') {
      for (let i = 0; i < priceListData?.length; i++) {
        if (priceListData[i]?.price) {
          const price = parseFloat(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
          onePrice += price
        }
      }
    } else {
      for (let i = 0; i < (priceListData?.length-1); i++) {
        if (priceListData[i]?.price) {
          const price = parseFloat(priceListData[i]?.price?.replace(/[^0-9.-]+/g, ''));
          onePrice += price
        }
      }
    }
    setCombinePrice(onePrice)
    setSelectedPrice(onePrice)
  }, [priceListData])
  return (
    <Row>
      <Col className="clt-booking-cp-div5">
        {title && <h2 className="clt-booking-cp-div5-title">{title}</h2>}
        {priceListData.map((priceData, index) => {
          return (
            <div
              key={index}
              className="w-100 d-flex justify-content-between align-items-center clt-booking-cp-div5-inner"
            >
              <div className="d-flex">
                <h2>{priceData.title}</h2>
                {priceData.tooltipMsg && (
                  <OverlayTrigger
                    placement="top-start"
                    overlay={
                      <Tooltip
                        id={`tooltip-top-start`}
                        className="clt-booking-cp-tooltip"
                      >
                        {priceData.tooltipMsg}
                      </Tooltip>
                    }
                  >
                    <i className="fa-solid fa-info"></i>
                  </OverlayTrigger>
                )}
              </div>
              <h2
                className="clt-booking-price-detail-right-column"
                style={{ textAlign: "end" }}
              >
                {priceData.price}
              </h2>
            </div>
          );
        })}
        <div className="d-flex justify-content-between align-items-center clt-booking-cp-div5-inner">
          <h2 className="clt-booking-cp-text-bold">Total</h2>
          <h2 className="clt-booking-cp-text-bold2">{totalPrice}€</h2>
        </div>
      </Col>
    </Row>
  );
};

export default SectionPriceDetail;
