import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomOffCanvas from "../CustomOffCanvas";
const SidebarRuleAndCondition = ({
  isSection6SiderbarDetail,
  setIsSection6SiderbarDetail,
  headerTitle,
  category1,
  column1_1,
  column1_2,
  column1_3,
  column1_4,
  column1_5,
  column1_6,
  column1_7,
  category2,
  column2_1,
  column2_2,
  column2_3,
  column2_4,
  column2_5,
  column2_6,
  column2_7,
  category3,
  column3_1,
  column3_2,
  column3_3,
  column3_4,
  category4,
  column4_1,
  column4_2,
  column4_3,
  column4_4,
}) => {
  return (
    <CustomOffCanvas
      placement="end"
      className="clt_products_offcanvas2"
      show={isSection6SiderbarDetail}
      onHide={setIsSection6SiderbarDetail}
      headerClassName="justify-content-start clt_products_offcanvas_header"
      bodyClassName="aclt_products_offcanvas_body"
      headerTitle="Conditions de réservation"
      isBackBtn={true}
      handleBack={setIsSection6SiderbarDetail}
    >
      <Row className="clt-detail-section-6-offcanvas-row-2">
        <Col className="p-0">
          <Row>
            <Col>
              <h2 className="clt-detail-section-6-offcanvas-cat-h2">
                {category1}
              </h2>
            </Col>
          </Row>
          <Row className="clt-detail-section-6-offcanvas-data-row m-0">
            <Col>

              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_1.title}</h2>
                  <p>{column1_1.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_2.title}</h2>
                  <p>{column1_2.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_3.title}</h2>
                  <p>{column1_3.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_4.title}</h2>
                  <p>{column1_4.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_5.title}</h2>
                  <p>{column1_5.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_6.title}</h2>
                  <p>{column1_6.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column1_7.title}</h2>
                  <p>{column1_7.subTitle}</p>
                </Col>
              </Row>
            </Col>
          </Row>



          <Row>
            <Col>
              <h2 className="clt-detail-section-6-offcanvas-cat-h2">
                {category2}
              </h2>
            </Col>
          </Row>

          <Row className="clt-detail-section-6-offcanvas-data-row m-0">
            <Col>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_1.title}</h2>
                  <p>{column2_1.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_2.title}</h2>
                  <p>{column2_2.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_3.title}</h2>
                  <p>{column2_3.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_4.title}</h2>
                  <p>{column2_4.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_5.title}</h2>
                  <p>{column2_5.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_6.title}</h2>
                  <p>{column2_6.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column2_7.title}</h2>
                  <p>{column2_7.subTitle}</p>
                </Col>
              </Row>

            </Col>
          </Row>



          <Row>
            <Col>
              <h2 className="clt-detail-section-6-offcanvas-cat-h2">
                {category3}
              </h2>
              <p className="aclt_admin_dash_timeslot_edit_box_subheading mt-1">
                Pour votre événement, les espaces inclus et optionnellement payant dans votre réservation sont répertoriés ci-dessous. (Vous pouvez faire une demande pour les options en contactant le château après avoir finalisé votre réservation, Il est à noter que les détails et tarifs spécifiques de chaque option sont disponibles sur la page dédiée à chaque événement sur le site internet)
              </p>
            </Col>
          </Row>

          <Row className="clt-detail-section-6-offcanvas-data-row m-0">
            <Col>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column3_1.title}</h2>
                  <p>{column3_1.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column3_2.title}</h2>
                  <p>{column3_2.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column3_3.title}</h2>
                  <p>{column3_3.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column3_4.title}</h2>
                  <p>{column3_4.subTitle}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <h2 className="clt-detail-section-6-offcanvas-cat-h2">
                {category4}
              </h2>
              <p className="aclt_admin_dash_timeslot_edit_box_subheading mt-1">
                Pour votre événement, les équipements inclus et optionnellement payant dans votre réservation sont répertoriés ci-dessous. (Vous pouvez faire une demande pour les options en contactant le château après avoir finalisé votre réservation, Il est à noter que les détails et tarifs spécifiques de chaque option sont disponibles sur la page dédiée à chaque événement sur le site internet)
              </p>
            </Col>
          </Row>

          <Row className="clt-detail-section-6-offcanvas-data-row m-0">
            <Col>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column4_1.title}</h2>
                  <p>{column4_1.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column4_2.title}</h2>
                  <p>{column4_2.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column4_3.title}</h2>
                  <p>{column4_3.subTitle}</p>
                </Col>
              </Row>
              <Row>
                <Col className="clt-detail-section-6-offcanvas-data-row-col">
                  <h2>{column4_4.title}</h2>
                  <p>{column4_4.subTitle}</p>
                </Col>
              </Row>
            </Col>
          </Row>





        </Col>
      </Row>
    </CustomOffCanvas>
  );
};

export default SidebarRuleAndCondition;
