import React, { useState } from "react";
import "./AdminDashboardStore.css";
import NextArrow from "../../../../assets/img/back_arrow_right.png";
import {API_ENDPOINT, IMG_ALT} from "../../../../constants";
import AdminDashboardTimeslot from "./AdminDashboardTimeslot/AdminDashboardTimeslot";
import AdminDashboardEvent from "./adminDashboardEvent/AdminDashboardEvent";
import AdminDashboardRoom from "./adminDashboardRoom/AdminDashboardRoom";
import BusinessInformation from "../AdminDashboardMenu/AdminDashboardBusinessInformation/BusinessInformation";
import AdminDashboardInvoice from "../AdminDashboardMenu/AdminDashboardInvoice/admin_dash_invoice";
import SidebarSearchUser from '../../../reusable/search/Search - Users/AdminDashboardUserSearch';
import {auth} from "../../auth/firebase";
import axios from "axios";
import {ErrorToast} from "../../../reusable/Toast";

const AdminDashboardStore = () => {
  const [isTimeSlotSidebar, setIsTimeSlotSidebar] = useState(false);
  const [isRoomSidebar, setIsRoomSidebar] = useState(false);
  const [isEventSidebar, setIsEventSidebar] = useState(false);
  const [isBusinessInformationInfo, setIsBusinessInformationInfo] = useState(false);
  const [openDashboardInvoice, setOpenDashboardInvoice] = useState(false);
  const [isSidebarSearchUser , setIsSidebarSearchUser] = React.useState(false);
  const [allInvoices , setAllInvoices] = React.useState(false);
  const [allRefunds , setAllRefunds] = React.useState(false);
  const [allAdditional , setAllAdditional] = React.useState([]);

  const getAllPayments = async () => {
    console.log("All Payments Starting");
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get(API_ENDPOINT + "/booking/all-payments",
          {headers: {Authorization: `Bearer ${token}`}}
      );
      const refundresponse = await axios.get(API_ENDPOINT + "/booking/all-refunds",
          {headers: {Authorization: `Bearer ${token}`}}
      );
      const additionalresponse = await axios.get(API_ENDPOINT + "/booking/all-additional-payments",
        {headers: {Authorization: `Bearer ${token}`}}
      );
      
      console.log("All Refunds");
      console.log(refundresponse.data.payments);
      setAllInvoices(response.data.payments);
      setAllRefunds(refundresponse.data.payments);
      setAllAdditional(additionalresponse.data.payments);
      setOpenDashboardInvoice(true)
    } catch (error) {
      console.log('location on here: ', error);
      ErrorToast(error?.response?.data?.message);
    }
  }

  return (
    <>
      <div className="clt_admin_dash_store_container_outer_wrapper">
        
        
        {/* General */}
        <div className="clt_admin_dash_menu_container">
          <p className="clt_admin_dash_menu_container_name">GENERAL</p>
          <div
            className="clt_admin_dash_menu_container_wrapper_first"
            onClick={() => setIsSidebarSearchUser(true)}
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_1"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Tout les utilisateurs 
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
          <div
            className="clt_admin_dash_menu_container_wrapper_last"
            onClick={() => getAllPayments()}
          >
            <div className="clt_admin_dash_menu_container_box">
              <div className="clt_admin_dash_menu_container_detail">
                <div className="clt_admin_dash_menu_container_box_icon clt_admin_dash_menu_container_box_icon_2"></div>
                <div className="clt_admin_dash_menu_container_box_name">
                  Toutes les factures
                </div>
              </div>
              <div className="clt_admin_dash_menu_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
        </div>


        <div className="clt_admin_dash_store_container">
          <p className="clt_admin_dash_store_container_name">Vitrine</p>
          <div
            className="clt_admin_dash_store_container_wrapper_first"
            onClick={() => setIsEventSidebar(true)}
          >
            <div className="clt_admin_dash_store_container_box">
              <div className="clt_admin_dash_store_container_detail">
                <div className="clt_admin_dash_store_container_box_icon clt_admin_dash_store_container_box_icon_1"></div>
                <div className="clt_admin_dash_store_container_box_name">
                  Évenement
                </div>
              </div>
              <div className="clt_admin_dash_store_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>

          <div
            className="clt_admin_dash_store_container_wrapper_middle"
            onClick={() => setIsTimeSlotSidebar(true)}
          >
            <div className="clt_admin_dash_store_container_box">
              <div className="clt_admin_dash_store_container_detail">
                <div className="clt_admin_dash_store_container_box_icon clt_admin_dash_store_container_box_icon_2"></div>
                <div className="clt_admin_dash_store_container_box_name">
                  Créneau
                </div>
              </div>
              <div className="clt_admin_dash_store_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>

          <div
            className="clt_admin_dash_store_container_wrapper_last"
            onClick={() => setIsRoomSidebar(true)}
          >
            <div className="clt_admin_dash_store_container_box">
              <div className="clt_admin_dash_store_container_detail">
                <div className="clt_admin_dash_store_container_box_icon clt_admin_dash_store_container_box_icon_3"></div>
                <div className="clt_admin_dash_store_container_box_name">
                  Chambre
                </div>
              </div>
              <div className="clt_admin_dash_store_container_arrow">
                <img
                  src={NextArrow}
                  className="clt_admin_arrow"
                  alt={IMG_ALT}
                />
              </div>
            </div>
          </div>
        </div>


        



      </div>

      <AdminDashboardEvent
        openAdminEventSidebar={isEventSidebar}
        onCloseAdminEventSidebar={() => setIsEventSidebar(false)}
      />
      <AdminDashboardTimeslot
        isTimeSlotSidebar={isTimeSlotSidebar}
        onCloseTimeSlotSidebar={() => setIsTimeSlotSidebar(false)}
      />
      <AdminDashboardRoom
        openAdminRoomSidebar={isRoomSidebar}
        onCloseAdminRoomSidebar={() => setIsRoomSidebar(false)}
      />

      <AdminDashboardInvoice
        isInvoiceSidebar={openDashboardInvoice}
        allInvoices={allInvoices}
        setAllInvoices={setAllInvoices}
        allRefunds={allRefunds}
        allAdditional={allAdditional}
        setAllRefunds={setAllRefunds}
        onCloseInvoiceSidebar={() => setOpenDashboardInvoice(false)}
      />

      <BusinessInformation
        isOpenSidebar={isBusinessInformationInfo}
        onCloseSidebar={() => {
          setIsBusinessInformationInfo(false);
        }}
      />

      {isSidebarSearchUser ? <SidebarSearchUser
        openSidebarSearchUserList={isSidebarSearchUser}
        onCloseSidebarSearchUserList={() => setIsSidebarSearchUser(false)}
      /> : null}

  
  
    </>
  );
};

export default AdminDashboardStore;
