/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import BackArrow from "../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../constants";
import { Chip } from "@mui/material";
import CustomHr from "../../reusable/CustomHr";
import ContactUsScreen from "../home/ContactUs/ContactUsScreen";
import kjnkjnlkjnkjj from "../../../assets/img/kjnkjnlkjnkjj.png";
import avant from "../../../assets/img/fenouillet-chateau-de-latournelle-labouche-1296.png";
import homesection1 from "../../../assets/img/home-section1.jpg";

const Article = ({ setIsOpenArticle, article, isOpenArticle, onClose }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_contact_offcanvas"
        show={isOpenArticle}
        onHide={onClose}
      >
        <Offcanvas.Header className="justify-content-start clt_contact_offcanvas_header">
          <>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onClose}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
          </>
          <Offcanvas.Title>{article?.name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="clt_help_article_offcanvas_body">
          <div>
            <Chip
              className="clt_help_article_chip"
              variant="outlined"
              color="primary"
              label={article?.groupTag}
            >
              {article?.groupTag}
            </Chip>
            {article?.content?.map((item, index) => (
              <div key={index}>
                {index === 1 && (
                  <><div
                    className="clt-account-section-offcanvas-row"
                    style={{
                      borderRadius: "10px",
                      padding: "15px",
                      marginBottom: "0px",
                    }}
                  >

                    <h1
                      className="clt-helpcenter-h4 nkjnezkjdnze"
                      style={{
                        fontSize: "22px",
                        marginLeft: "13px",
                        paddingTop: "15px",
                        marginBottom: "-14px",
                        color: "#4e4e4e",
                        fontWeight: 500,
                        fontFamily: 'Lora',

                      }}
                    >
                      Sommaire
                    </h1>
                    <ul className="mt-4" style={{}}>
                      {article?.content?.map((item, index) => (
                        <li key={index} className="mt-2 mb-2">
                          <p className="m-0 p-0 jcnzdcjkldc">{item.title}</p>
                        </li>
                      ))}
                    </ul>

                  </div><CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 clt-hr" /></>
                  
                )}
                
                <h1 className="clt-helpcenter-h4">{item.title}</h1>
                <p
                  className="clt_help_article_text_section p-0 clt_help_article_text_section_p"
                  style={{
                    marginBottom: "0px",
                  }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></p>
                {item.title === "Début des années 1900" && (
                  <a href={avant} target="_blank" rel="noopener noreferrer">
                      <img src={avant} className="article-image" alt="Image description" style={{ cursor: "zoom-in" }} />
                  </a>
                )}
                {item.title === "Aujourd'hui" && (
                  <a href={homesection1} target="_blank" rel="noopener noreferrer">
                      <img src={homesection1} className="article-image" alt="Image description" style={{ cursor: "zoom-in" }} />
                  </a>
                )}
                {item.title === "Aujourd'hui" && (
                  <p
                    className="clt_help_article_text_section p-0 clt_help_article_text_section_p"style={{ fontFamily: "inspiration",
                    fontSize: "80px",
                    letterSpacing: "3px",
                    marginBottom: "0px",
                    marginTop: "13px",
                    lineHeight: "100px",}}>Château Latournelle</p>
                )}

                
                <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 clt-hr" />
              </div>
            ))}
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
              className="clt-helpcenter-section-contact mb-4"
            >
              <h1 className="clt-helpcenter-h5">Contacter le château</h1>
              <span
                className="d-flex pfr_copy_Txt"
                onClick={() => setOpen(true)}
              >
                <span>Nous contacter</span>
              </span>
            </div>
          </div>
          {open ? <ContactUsScreen isOpen={open} setIsOpen={setOpen} /> : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Article;
