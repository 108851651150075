import React, { useContext, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import "./guest.css";
import BackArrow from "../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../constants";
import CustomHr from "../../reusable/CustomHr";
import { AuthContext } from "../auth/authContext";

const Guest = ({ isGuestSidebar, onCloseGuestSidebar, editGuestValue, selectedTimeSlot, isLoading, handleGuestUpdate }) => {
  // const [guestCount, setGuestCount] = useState(
  //   editGuestValue || 0
  // );
  const { guestCount, setGuestCount, guestCountInput, setGuestCountInput } = useContext(AuthContext);
  let count = Number(guestCountInput);
  let tempCount = Number(guestCountInput);

  const handleGuestCounter = (type, value) => {
    if (type === "minus") {
      if (tempCount > 1) {
        tempCount -= 1;
      }
    } else if (type === "plus") {
      if (tempCount < selectedTimeSlot?.guests) {
        tempCount += 1;
      }
    } else if (type === "input") {
      if (value <= selectedTimeSlot?.guests && value >= 1) {
        tempCount = value;
      }
    }
    setGuestCountInput(tempCount);

    // setGuestCount(count);

  };
  const handleGuestConfirm = () => {
    setGuestCount(count);
    onCloseGuestSidebar(count);
    if (window.location.pathname === '/') {
      handleGuestUpdate(count);
      return;
    }
  };
  return (
    <Offcanvas
      placement="end"
      className="clt_products_offcanvas"
      show={isGuestSidebar}
      onHide={() => onCloseGuestSidebar(guestCount)}
    >
      <Offcanvas.Header className="justify-content-start clt_products_offcanvas_header">
        <React.Fragment>
          <button
            type="button"
            className="btn-close btn-back shadow-none d-flex"
            aria-label="Close"
            onClick={() => {
              // setGuestCountInput(tempCount);

              // setGuestCount(count);
              onCloseGuestSidebar(tempCount)
            }}
          >
            <img src={BackArrow} className="" alt={IMG_ALT} />
          </button>
          <Offcanvas.Title>Invité</Offcanvas.Title>
        </React.Fragment>
      </Offcanvas.Header>
      <Offcanvas.Body className="clt_products_offcanvas_body clt-datepicker-sidebar">
        <>
          <Row>
            <Col>
              <h2 className="clt-datepicker-sidebar-h2">Sélectionnez le nombre d'invités</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="clt-booking-gc-div1 d-flex justify-content-center align-items-center">
                <i
                  className="fa-solid fa-minus"
                  onClick={() => handleGuestCounter("minus")}
                ></i>
                <Form.Control
                  className="shadow-none"
                  type="number"
                  onChange={(e) => handleGuestCounter("input", e.target.value)}
                  min={1}
                  max={100}
                  value={guestCountInput}
                  onKeyPress={(e) => e.code === "Minus" && e.preventDefault()}
                />
                <i
                  className="fa-solid fa-plus"
                  onClick={() => handleGuestCounter("plus")}
                ></i>
              </div>
            </Col>
          </Row>
          <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
          <Row>
            <Col>
              <div className="d-flex justify-content-start align-items-start clt-booking-gc-div2">
                <i className="fa-solid fa-info"></i>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="d-flex flex-column justify-content-center align-items-start clt-booking-gc-div2-text">
                    <h2>Maximum</h2>
                    <p>{selectedTimeSlot?.guests} invités debout</p>
                    <p>130 invités assis</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="clt-datepicker-sidebar-footer-space" />
        </>
      </Offcanvas.Body>
      <div>
        <div className="clt-datepicker-sidebar-footer">
          <div className="inner-div clt-booking-gc-div2-btn">
            <Button onClick={handleGuestConfirm} disabled={isLoading} className="shadow-none">
              <span>Confirmer</span>
            </Button>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};

export default Guest;
