// Import
import React, { useEffect, useState } from "react";
import { Offcanvas, Row, Col, Button } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import { API_ENDPOINT, IMG_ALT, eventweddingData } from "../../../../constants";
import SectionTop from "../../../reusable/booking/SectionTop";
import ConfirmPayProfilePicture1 from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import ConfirmPay1 from "../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../assets/img/confirm_pay_3.png";
import ConfirmPay4 from "../../../../assets/img/confirm_pay_4.png";
import MessageIcon from "../../../../assets/img/chat_icon.png";

import CustomHr from "../../../reusable/CustomHr";
import SectionDate from "../../../reusable/booking/SectionDate";
import SectionInformationMessageRequestSent from "../../../reusable/SectionInformationMessageRequestSent.js";
import SidebarContactRequestUserCancel from "./[SIDEBAR] User - Contact Request - Cancel";

import { ErrorToast, SuccessToast } from "../../../reusable/Toast";

import SectionContactRequestDate from "../../../reusable/contactrequest/SectionContactRequestDate";
import SectionContactRequestGuest from "../../../reusable/contactrequest/SectionContactRequestGuest";
import SectionContactRequestMessage from "../../../reusable/contactrequest/SectionContactRequestMessage";
import SectionContactRequestEvent from "../../../reusable/contactrequest/SectionContactRequestEvent";
import { auth } from "../../auth/firebase";
import axios from "axios";

/* Const */
const SidebarContactRequestUserSent = ({ data, isOpenSidebarContactRequestUserSent, onCloseOpenSidebarContactRequestUserSent, setIsOpenSidebarContactRequestUserSent, isFromMakeNewRequest }) => {
  
  /* Const - Event */
  const [eventType, setEventType] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [guestCount, setGuestCount] = useState(null);
  const [contactRequest, setContactRequest] = useState(null);

  useEffect(() => {
    if (data) {
      setContactRequest(data.ContactRequest)
    }
  }, [data]);

  /* Const - Booking */
  const [isBookingSidebar, setIsBookingSidebar] = useState(false);

  /* Const - Section - Date */
  

  let bookingProps = {
    isFromBookingDate: {
      startDate: new Date(),
      endDate: new Date(),
    },
    isFromBookingTimeSlot: {
      id: 1,
      title: "Title",
      subTitle: "subTitle",
      time: "time",
    },
  };

  /* Const - Section - Guest  */
  const handleRequestEdit = async (dataToSend) => {
    if (data.ContactRequest.status !== "Sent") {
      ErrorToast("Erreur", "Vous n'êtes pas autorisé à effectué cette action.")
    } else {
      const token = await auth.currentUser.getIdToken();
      try {
        const response = await axios.patch(API_ENDPOINT + "/contact/request",
          {
            id: data.ContactRequest.id,
            ...dataToSend
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setContactRequest(response.data.contactRequest)
        SuccessToast(" La demande a été mise à jour");
      } catch (error) {
        ErrorToast(error?.response?.data?.message);
      }
    }
    // setIsBookingSidebar(true);
  };

  /* Const - Section - Price detail*/
  const [priceDetailsSlots, setpriceDetailsSlots] = useState([
    { title: "Date change", tooltipMsg: null, price: "10€" },
  ]);

  /* Const - Section - Payment plan */
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  /* Const - Cancel request */
  const handleRefuse = async () => {
    setIsLoading(true);
    const token = await auth.currentUser.getIdToken();
    try {
      const response = await axios.patch(API_ENDPOINT + "/contact/request",
        {
          id: data.ContactRequest.id,
          status: "Cancel",
          notificationData: {
            title: `Demande de contact de ${data?.ContactRequest?.User?.firstName} ${data?.ContactRequest?.User?.lastName} annulée`,
            message: `La demande a été annulée`,
          }
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      setIsLoading(false);
      SuccessToast("La demande a été annulé");
      onCloseOpenSidebarContactRequestUserSent(true);
      setIsOpenSidebarContactRequestUserCancel(true);
      // SuccessToast(response.data.message);
    } catch (error) {
      setIsLoading(false);
      ErrorToast(error?.response?.data?.message);
    }
  };
  /* Const - Cancel Request - Sidebar */
  const [isOpenSidebarContactRequestUserCancel, setIsOpenSidebarContactRequestUserCancel] = useState(false);


  /* Const - Make a new request  */
  const handleMakeNewRequest = () => {
    SuccessToast("There is the user booking, you can edit what you need");
    setIsOpenSidebarMyBookingUser(true);
  };

  /* Const - Section - Date - Make a new request - Open Sidebar Booking */
  const [isOpenSidebarMyBookingUser, setIsOpenSidebarMyBookingUser] = useState(false);

  
  



  {/* Sidebar - User - Edit request - Sent */}
  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_mybooking_offcanvas"
        show={isOpenSidebarContactRequestUserSent}
        onHide={onCloseOpenSidebarContactRequestUserSent}
      >



        {/* Header */}
        <Offcanvas.Header className="justify-content-between clt_mybooking_offcanvas_header">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={onCloseOpenSidebarContactRequestUserSent}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <Offcanvas.Title>[SIDEBAR] User - Contact request - Sent</Offcanvas.Title>
            </div>
          </div>
        </Offcanvas.Header>



        {/* Body */}
        <Offcanvas.Body className={`clt_mybooking_offcanvas_body  p-0`}>
          {
            data && Object.keys(data).length ? 
              <>
                <div className="clt_mybooking_offcanvas_body">

                  <h2 className="clt-mybooking-title">Demande de contact</h2>
                  <SectionInformationMessageRequestSent
                    innerTitle="Envoyée"
                    innerSubTitle="Votre demande est envoyée, le château reviendra vers vous prochainement"
                  />

                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />
                  <h2 className="clt-mybooking-title">Request detail</h2>
                  <SectionTop
                    image={ConfirmPayProfilePicture1}
                    title="Place"
                    subTitle="CLT"
                  />
                  <SectionContactRequestEvent
                      image={`ConfirmPay1`}
                      subText={`${contactRequest?.Event.title}`}
                      buttonText="Edit"
                      IsEventButtonDropdown={true} 
                      setEventType={setEventType}
                      eventType={eventType}
                      event={contactRequest?.Event}
                      handleRequestEdit={handleRequestEdit}
                  />

                  <SectionContactRequestDate
                      image={ConfirmPay2}
                      subText={`${contactRequest?.month} ${contactRequest?.year}`}
                      IsDateButtonDropdown={true} 
                      selectedMonth={selectedMonth}
                      setSelectedMonth={setSelectedMonth}
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                      handleRequestEdit={handleRequestEdit}
                  />

                  <SectionContactRequestGuest
                      image={ConfirmPay3}
                      subText={`${contactRequest?.guest_number}`}
                      IsGuestButtonDropdown={true} 
                      // subText={`${guestCount} guests`}
                      // setIsGuestSidebar={() => setIsGuestSidebar(true)} 
                      // buttonText="Edit"
                      guestCount={guestCount}
                      setGuestCount={setGuestCount}
                      handleRequestEdit={handleRequestEdit}
                  />

                  <SectionContactRequestMessage
                      image={MessageIcon}
                      subText={`${contactRequest?.message}`}
                  />
                  <CustomHr colClass="clt-booking-hr-pd" hrClass="p-0 m-0 clt-hr" />

                  {/* Footer */}
                  <Row>
                    <Col>

                      <div className="clt-booking-btn-refuse-div">
                        <Button
                          className="shadow-none clt-booking-btn-refuse"
                          onClick={handleRefuse}
                          disabled={isLoading || contactRequest?.status !== "Sent"}
                        >
                          <span>Cancel Contact request</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            : null
          }
        </Offcanvas.Body>
      </Offcanvas>

      {/* Function - Contact Request - Cancel */}
      <SidebarContactRequestUserCancel
        data={data}
        isOpenSidebarContactRequestUserCancel={isOpenSidebarContactRequestUserCancel}
        onCloseOpenSidebarContactRequestUserCancel={() => setIsOpenSidebarContactRequestUserCancel(false)}
      />

    </>
  );
};

export default SidebarContactRequestUserSent;
