import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navbar from './user_navbar';

const PublicLayout = ({ children, header }) => (
  <Container fluid style={{ paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}>
    <Navbar page={header ? header : "Accueil"} />
    {children}
  </Container>
);

const PublicMainLayoutRoute = ({ setIsOpen, header, component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <PublicLayout header={header}>
        <Component {...matchProps} setIsOpen={setIsOpen} />
      </PublicLayout>
    )} />
  )
};

export default PublicMainLayoutRoute;