import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Offcanvas,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import "./component_amount_paid.css";
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import {
  eventbirthdayData,
  IMG_ALT,
  eventweddingData,
  newEventData,
  eventprofessionalData,
  eventreligiousData,
} from "../../../../../../constants";
import { Link } from "react-router-dom";
import AdminIconDragDrop1 from "../../../../../../assets/img/admin_icon_dragdrop_1.png";
import AdminIconManageEvent2 from "../../../../../../assets/img/admin_icon_manage_event_2.png";
import AdminIconManageEvent3 from "../../../../../../assets/img/admin_icon_manage_event_3.png";
import AdminIconManageEvent4 from "../../../../../../assets/img/admin_icon_manage_event_4.png";
import GalleryImage1 from "../../../../../../assets/img/detail_slider_1_1.png";
import GalleryImage2 from "../../../../../../assets/img/detail_slider_1_2.png";
import GalleryImage3 from "../../../../../../assets/img/detail_slider_1_3.png";
import NextArrow from "../../../../../../assets/img/back_arrow_right.png";
import CustomHr from "../../../../../reusable/CustomHr";
import Switch from "@mui/material/Switch";
import { v4 as uuid } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import ManageName from "./ManageName";
// import AddSection from "./integration_section3_home";
// import IntegrationSection5 from "./integration5_detail";
// import IntegrationSection6 from "./integration_section6_detail";

const initialAdminEventScreens = {
  manageEvent: true,
  addPaymentAmount: false,
};

const initialEventIntegrationSection5Screens = {
  manageCategory: true,
  manageAmenities: false,
  editName: false,
  editAmenities: false,
  editRules: false,
};

const AdminAmountPaid = ({
  isSidebar,
  onCloseSidebar,
  sectionAmountList,
  setSectionAmountList,
  setIsAmountPaid,
}) => {
  const [adminEventScreens, setAdminEventScreens] = useState(
    initialAdminEventScreens
  );

  const [amountPaidList, setAmountPaidList] = useState();

  const [selectedId, setSelectedId] = useState("");
  const [formValues, setFormValues] = useState({
    type: "",
    amount: null,
  });

  const handleAddCategory = () => {
    setSectionAmountList((prevState) => [
      ...prevState,
      {
        id: `${sectionAmountList.length + 1}`,
        title: "Payment Method",
        isActive: false,
        amount: null,
      },
    ]);
  };

  const handleDeleteCategory = (id) => {
    setSectionAmountList(sectionAmountList.filter((item) => item.id !== id));
  };

  const handleEventManageEntry = (id) => {
    setSelectedId(id);
    handleBack("manageEvent", "addPaymentAmount");
  };

  const handleSavePayment = () => {
    const index = sectionAmountList.findIndex((item) => item.id == selectedId);
    if (formValues.type !== "")
      sectionAmountList[index].title = formValues.type;
    sectionAmountList[index].amount = formValues.amount;
    setFormValues({ type: "", amount: null });
    handleBack("addPaymentAmount", "manageEvent");
    setIsAmountPaid(true);
  };

  const handleBack = (from, to) => {
    setAdminEventScreens((prevState) => ({
      ...prevState,
      [from]: !prevState[from],
      [to]: !prevState[to],
    }));
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      className="pfr_payment_card3dot"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        console.log(e);
      }}
    >
      {children}
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
    </Link>
  ));

  const onDragEndEvent = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const copiedItems = [...amountPaidList];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setAmountPaidList(copiedItems);
  };

  return (
    <>
      <Offcanvas
        placement="end"
        className="clt_products_offcanvas"
        show={isSidebar}
        onHide={
          // adminEventScreens.confirmBooking
          //     ? handleClose
          //     :
          onCloseSidebar
        }
      >
        <Offcanvas.Header
          className={`justify-content-start clt_products_offcanvas_header_two ${
            adminEventScreens.generalAndParameter && "border-bottom-none"
          }`}
        >
          {adminEventScreens.manageEvent && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={onCloseSidebar}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Amount Paid</Offcanvas.Title>
                <Button
                  className="clt_header_add_button shadow-none"
                  onClick={handleAddCategory}
                >
                  Add
                </Button>
              </div>
            </React.Fragment>
          )}
          {adminEventScreens.addPaymentAmount && (
            <React.Fragment>
              <button
                type="button"
                className="btn-close btn-back shadow-none d-flex"
                aria-label="Close"
                onClick={() => {
                  handleBack("addPaymentAmount", "manageEvent");
                  setFormValues({ type: "", amount: null });
                }}
              >
                <img src={BackArrow} alt={IMG_ALT} />
              </button>
              <div className="d-flex align-items-center justify-content-between w-100">
                <Offcanvas.Title>Payment {selectedId}</Offcanvas.Title>
              </div>
            </React.Fragment>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body
          className={`clt_products2_offcanvas_body ${
            adminEventScreens.generalAndParameter &&
            "clt-amount-paid-admin-event-body-no-padding"
          }`}
        >
          {adminEventScreens.manageEvent && (
            <>
              <Row className="clt-amount-paid-detail-section-6">
                <Col className="clt-amount-paid-admin-event-s1">
                  <Row className="clt-amount-paid-detail-section-6-header">
                    <Col>
                      <h5>
                        Enter amount(s) paid by user by chronological order
                      </h5>
                    </Col>
                  </Row>
                  <Row className="clt-amount-paid-admin-event-list-div-body m-0">
                    <Col>
                      <DragDropContext
                        onDragEnd={(result) => onDragEndEvent(result)}
                      >
                        <Droppable droppableId="events">
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {sectionAmountList.map((eventData, index) => {
                                  return (
                                    <Draggable
                                      key={eventData.id}
                                      draggableId={eventData.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <Row
                                            key={index}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              userSelect: "none",
                                              backgroundColor:
                                                snapshot.isDragging
                                                  ? "#fff"
                                                  : "transparent",
                                              border: snapshot.isDragging
                                                ? "1px solid #dedddd"
                                                : "none",
                                              borderRadius: snapshot.isDragging
                                                ? "10px"
                                                : "0",
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            <Col
                                              className="clt-amount-paid-admin-event-list-div"
                                              style={{
                                                borderBottom:
                                                  snapshot.isDragging && "none",
                                                borderRadius:
                                                  snapshot.isDragging
                                                    ? "10px"
                                                    : "14px",
                                              }}
                                            >
                                              <div className="d-flex align-items-center w-100">
                                                <img
                                                  src={AdminIconDragDrop1}
                                                  alt={IMG_ALT}
                                                  className="img-dragdrop"
                                                />
                                                <span className="dragdrop-counter">
                                                  {index + 1}
                                                </span>
                                                <div
                                                  className="inner-icon-title-div"
                                                  onClick={() =>
                                                    handleEventManageEntry(
                                                      eventData.id
                                                    )
                                                  }
                                                >
                                                  <div className="d-flex flex-column align-items-start justify-content-center">
                                                    <h2>{eventData.title}</h2>
                                                    <p>
                                                      {eventData.amount !== null
                                                        ? `${eventData.amount}€`
                                                        : "Amount"}
                                                    </p>
                                                    {/* {!eventData.isActive && (
                                                                                                <p>Pause</p>
                                                                                            )} */}
                                                  </div>
                                                </div>
                                                <div
                                                  className="d-flex align-items-center justify-content-end pfr_payment_cards3dots"
                                                  style={{
                                                    width: "10%",
                                                  }}
                                                >
                                                  <Dropdown>
                                                    <Dropdown.Toggle
                                                      as={CustomToggle}
                                                    />
                                                    <Dropdown.Menu
                                                      size="sm"
                                                      title=""
                                                      className="pfr_dropdown_menu"
                                                    >
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleEventManageEntry(
                                                            eventData.id
                                                          )
                                                        }
                                                        className="pfr_payment_cards3dots_options"
                                                      >
                                                        Manage entry
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleDeleteCategory(
                                                            eventData.id
                                                          )
                                                        }
                                                        className="pfr_payment_cards3dots_options"
                                                      >
                                                        Delete
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          {adminEventScreens.addPaymentAmount && (
            <>
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2>Moyen de paiement</h2>
                </Col>
              </Row>
              <Row>
                <Col className="clt-amount-paid-admin-event-info-input-div selectField">
                  <Form.Floating className="clt_admin_dash_timeslot_inputFloat">
                    <Form.Select
                      id="floatingInput1"
                      type="select"
                      placeholder="Events"
                      value={formValues.type}
                      onChange={(e) => {
                        setFormValues((existingValues) => ({
                          ...existingValues,
                          type: e.target.value,
                        }));
                      }}
                    >
                      {sectionAmountList.map((item, index) => (
                        <option key={index} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInput1">Payment method</label>
                  </Form.Floating>
                </Col>
              </Row>
              <CustomHr
                colClass="clt-admin-booking-admin-booking-hr-pd"
                hrClass="p-0 m-0 clt-admin-booking-hr"
              />
              <Row className="clt_admin_dash_manage_event_header">
                <Col>
                  <h2 className="mb-2">Amount</h2>
                </Col>
              </Row>
              <Row>
                <Col className="clt-amount-paid-admin-event-info-input-div">
                  <p class="clt_admin_dash_menu_container_name mt-1 ">
                    Set the amount paid
                  </p>
                  <Form.Floating className="pfr_inputFloat pfr_inputFloat_number ">
                    <Form.Control
                      id="floatingInput2"
                      type="number"
                      placeholder="Amount"
                      value={formValues.amount}
                      onChange={(e) => {
                        setFormValues((existingValues) => ({
                          ...existingValues,
                          amount: e.target.value,
                        }));
                      }}
                    />
                    <label htmlFor="floatingInput2">Amount</label>
                    <span className="last-input-indicator">€</span>
                  </Form.Floating>
                </Col>
              </Row>
              <div className="pfr_payment_space_bottom" />
              <div className="clt_payment_footer">
                <Button className="shadow-none" onClick={handleSavePayment}>
                  Save
                </Button>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdminAmountPaid;
