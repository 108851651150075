/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";
import "./AdminDashboardManageAccess.css";
import BackArrow from "../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../constants";
import { Link } from "react-router-dom";
import { RangeDatePicker } from "react-google-flight-datepicker";
import { getAllAdmin, updateBusinessInformationRequest } from "./api";
import { ErrorToast, SuccessToast } from "../../../../reusable/Toast";

const initialReserveDate = {
    startDate: null,
    endDate: null,
};

const AdminDashboardManageAccess = ({
    isManageAccessSidebar,
    onCloseManageAccessSidebar,
}) => {

    const [isAddAccessSelected, setIsAddAccessSelected] = useState(false);
    const [selectedAccessOption, setSelectedAccessOption] = useState(0);
    const [isSendEmail, setIsSendEmail] = useState(false);
    const [email, setEmail] = useState("")
    const [adminList, setAdminList] = useState([])
    const [updateList, setUpdateList] = useState(true)
    const [isReserveDate, setIsReserveDate] = useState(initialReserveDate);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            className="clt_admin_dash_manage_access_card3dot"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
                console.log(e);
            }}
        >
            {children}
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </Link>
    ));

    useEffect(()=>{
        console.log("from", {isAddAccessSelected});
    }, [isAddAccessSelected])
    
    useEffect(()=>{
        console.log("from", {selectedAccessOption});
    }, [selectedAccessOption])
    
    useEffect(()=>{
        console.log("from", {email});
    }, [email])

    useEffect(()=>{
        console.log("from", {isSendEmail});
    }, [isSendEmail])

    const handleAddAccess = async () =>{
        if(email) {
            try {
                const result = await updateBusinessInformationRequest({role: "admin", email})
                SuccessToast("Admin created successful")
                setUpdateList(prev => !prev)
                setIsAddAccessSelected(false)
                setEmail("")
                setIsSendEmail(false)
            } catch (error) {
                ErrorToast("Une erreur est survenue", "Veuillez essayé d'ajouter l'admin à nouveau");
            }
        }
    }

    const handleEditAccess = async (email) =>{
        setEmail(email)
        setIsAddAccessSelected(true)
    }

    const handleDeleteAccess = async (email) =>{
        try {
            await updateBusinessInformationRequest({role: "user", email})
            setUpdateList(prev => !prev)
            SuccessToast("Admin supprimé")
        } catch (error) {
            ErrorToast("Une erreur est survenue", "Veuillez essayé de supprimé l'admin à nouveau");

        }
    }
    
    
    const handleCloseSidebar = () => {
        if (!isAddAccessSelected)
            onCloseManageAccessSidebar()
        else {
            if (!isSendEmail)
                setIsAddAccessSelected(false)
            else
                setIsSendEmail(false)
        }
    }

    useEffect(() => {
        const bootstrap = async () => {
            const request = await getAllAdmin()
            console.log(request.data?.data);
            setAdminList(request.data?.data || [])


        }
        bootstrap()

    }, [updateList])

    const renderAdminList = adminList.map((admin) => {
        return (
            <div className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_cardsdiv ">
                <div
                    className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_account_circle"
                    style={{ width: "20%" }}
                >
                    <div className="clt_admin_dash_manage_access_imgdiv">
                        <div className="user">{ admin.firstName[0] }</div>
                    </div>
                </div>
                <div
                    className="d-flex align-items-start justify-content-center flex-column"
                    style={{ width: "70%" }}
                >
                    <h3 className="clt_admin_dash_manage_access_cardnumber"> {admin.firstName} {admin.lastName} </h3>
                    <p className="clt_admin_dash_manage_access_cardname"> {admin.email} </p>
                </div>
                <div
                    className="d-flex align-items-center justify-content-end clt_admin_dash_manage_access_cards3dots"
                    style={{ width: "10%" }}
                >
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} />
                        <Dropdown.Menu size="sm" title=""
                            className="pfr_dropdown_menu">
                            <Dropdown.Item
                                className="clt_admin_dash_manage_access_cards3dots_options"
                                onClick={() => handleEditAccess(admin.email)}
                            >
                                Edit Role
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="clt_admin_dash_manage_access_cards3dots_options"
                                onClick={() => handleDeleteAccess(admin.email)}

                            >
                                Delete
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </div >
        )
    })

    return (
        <Offcanvas
            placement="end"
            className="clt_admin_dash_manage_access_offcanvas"
            show={isManageAccessSidebar}
            onHide={onCloseManageAccessSidebar}
        >
            <Offcanvas.Header className="justify-content-start clt_admin_dash_manage_access_offcanvas_header">

                <React.Fragment>
                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={handleCloseSidebar}
                    >
                        <img src={BackArrow} alt={IMG_ALT} />
                    </button>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <Offcanvas.Title>{!isAddAccessSelected ? "Access" : isSendEmail ? "Who do you want to add" : "Select a permission"}</Offcanvas.Title>
                        {!isAddAccessSelected && (
                            <Button
                                className="clt_admin_dash_manage_access_header_button shadow-none"
                                onClick={() => setIsAddAccessSelected(true)}
                            >
                                Add
                            </Button>
                        )}

                    </div>
                </React.Fragment>


            </Offcanvas.Header>
            <Offcanvas.Body
                className={`${!isAddAccessSelected ? "clt_admin_dash_manage_access_offcanvas_body" : "clt_admin_dash_manage_access_offcanvas_body_2"}`}
            >
                {!isAddAccessSelected ?
                    <>
                        {/* pending access  
                        <div className="clt_admin_dash_manage_access_innerdivs">
                            <h2 className="clt_admin_dash_manage_access_innerhead">Pending</h2>

                            <div
                                className={`d-flex align-items-center justify-content-start clt_admin_dash_manage_access_cardsdiv }`}
                            // key={index}
                            // onClick={() =>
                            //     isFromBooking
                            //         ? setIsPaymentCardSelected(value)
                            //         : console.log("From Payment")
                            // }
                            >
                                <div
                                    className="d-flex align-items-center justify-content-start clt_admin_dash_manage_access_account_circle"
                                    style={{ width: "20%" }}
                                >
                                    <div className="clt_admin_dash_manage_access_imgdiv">
                                        <div className="pending">J</div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-start justify-content-center flex-column"
                                    style={{ width: "70%" }}
                                >
                                    <h3 className="clt_admin_dash_manage_access_cardnumber">Jhon Doe</h3>
                                    <p className="clt_admin_dash_manage_access_cardname">Pending</p>
                                </div>
                                <div
                                    className="d-flex align-items-center justify-content-end clt_admin_dash_manage_access_cards3dots"
                                    style={{ width: "10%" }}
                                >
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title=""
                                            className="pfr_dropdown_menu">
                                            <Dropdown.Item
                                                className="clt_admin_dash_manage_access_cards3dots_options"
                                            >
                                                Resend Invite
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="clt_admin_dash_manage_access_cards3dots_options"
                                            >
                                                Remove Access
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>


                        </div>*/}

                        {/* user access  */}
                        <div className="clt_admin_dash_manage_access_innerdivs" styles={{ marginTop: "20px !important" }}>
                            <h2 className="clt_admin_dash_manage_access_innerhead">Admin</h2>

                            
                           
                            
                                {renderAdminList}
                            


                        </div>
                    </>
                    :
                    <>
                        {isSendEmail === false ?
                            <>
                                <div className='clt_admin_dash_manage_access_refund_booking_box_field'>

                                    <Row>
                                        <Col>
                                            <h2 className="clt_admin_dash_manage_access_innerhead">Permission</h2>
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-start clt_admin_dash_manage_access_clt-datepicker-sidebar-timeslot-mainDiv"
                                                onClick={() => setSelectedAccessOption(1)}
                                            >
                                                <div
                                                    className="inner-div-circle"
                                                    style={
                                                        selectedAccessOption === 1
                                                            ? {
                                                                borderColor: `#000`,
                                                            }
                                                            : {
                                                                borderColor: `#ddd`,
                                                            }
                                                    }
                                                >
                                                    <div
                                                        className="subDiv"
                                                        style={
                                                            selectedAccessOption === 1
                                                                ? {
                                                                    borderColor: `#000`,
                                                                }
                                                                : {
                                                                    borderColor: `#ddd`,
                                                                }
                                                        }
                                                    ></div>
                                                </div>
                                                <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                                                    <h2>All Access</h2>
                                                    <h3>Total Access</h3>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div
                                                className="d-flex flex-row align-items-start justify-content-start clt_admin_dash_manage_access_clt-datepicker-sidebar-timeslot-mainDiv border-0"
                                                onClick={() => setSelectedAccessOption(2)}
                                            >
                                                <div
                                                    className="inner-div-circle"
                                                    style={
                                                        selectedAccessOption === 2
                                                            ? {
                                                                borderColor: `#000`,
                                                            }
                                                            : {
                                                                borderColor: `#ddd`,
                                                            }
                                                    }
                                                >
                                                    <div
                                                        className="subDiv"
                                                        style={
                                                            selectedAccessOption === 2
                                                                ? {
                                                                    borderColor: `#000`,
                                                                }
                                                                : {
                                                                    borderColor: `#ddd`,
                                                                }
                                                        }
                                                    ></div>
                                                </div>
                                                <div className="d-flex flex-column align-items-start justify-content-center inner-div-text w-100">
                                                    <h2>Test check button</h2>

                                                    {selectedAccessOption === 2 && (
                                                        <Form className="clt_admin_dash_manage_access_form ">

                                                            <div className="mt-2">
                                                                <Form.Check
                                                                    inline
                                                                    label="Test"
                                                                    name="access"
                                                                    type="checkbox"
                                                                    id="AccessBooking"
                                                                    className="mb-2 d-block"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Test"
                                                                    name="access"
                                                                    type="checkbox"
                                                                    id="AccessBooking"
                                                                    className="mb-2 d-block"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Test"
                                                                    name="access"
                                                                    type="checkbox"
                                                                    id="AccessBooking"
                                                                    className="mb-2 d-block"
                                                                />

                                                            </div>

                                                        </Form>
                                                    )}

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="w-100">
                                    <hr />
                                    <Button
                                        onClick={() => setIsSendEmail(true)}
                                        className="clt_admin_dash_manage_access_refund_booking_formButton shadow-none"
                                        disabled={selectedAccessOption === 0 ? true : false}
                                    >
                                        <span>Confirm</span>
                                    </Button>
                                </div>
                            </>
                            :
                            <>
                                <div className='clt_admin_dash_manage_access_refund_booking_box_field'>
                                    <p className="clt_admin_dash_manage_title">Enter the email address of the user</p>
                                    <p className="clt_admin_dash_manage_desc">The user will have the rôle "Admin" and will have directly access to admin dashboard.</p>
                                    <div className="clt_inputFloatDiv">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control
                                                id="floatingInput1"
                                                type="text"
                                                placeholder="Email Address"
                                                className="pfr_inputFloat_role_access"
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <hr />
                                    <Button
                                        onClick={() => handleAddAccess() }
                                        className="clt_admin_dash_manage_access_refund_booking_formButton shadow-none"
                                        disabled={email === "" ? true : false}
                                    >
                                        <span>Send</span>
                                    </Button>
                                </div>
                            </>
                        }
                    </>
                }

            </Offcanvas.Body>
        </Offcanvas >
    );
};

export default AdminDashboardManageAccess;
