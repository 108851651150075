import React from 'react'
import { Button } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";
import BackArrow from "../../../../../../assets/img/back_arrow.png";
import { IMG_ALT } from "../../../../../../constants";
import SectionDepositRefund from '../../../../../reusable/booking/SectionDepositRefund';
// import SectionSlotRefund from '../../../reusable/booking/SectionSlotRefund';
// import CustomHr from '../../../reusable/CustomHr';
import './component_damage_deposit.css'
import {damageCharge} from "../../../../../../redux/actions/avent";
import {useDispatch} from "react-redux";
import {SuccessToast} from "../../../../../reusable/Toast";


const DamageDeposit = ({ isOpen, setIsOpen,bookingId, setdamagePrice }) => {
    const [amount, setAmount] = React.useState(false);
    const onBack = () => {
        setIsOpen()
    }
    const handleInputChange = (event) => {
        setAmount(event.target.value)
    };
    const dispatch = useDispatch();
    const calldamageCharge = ()=>{
        // damageCharge
        dispatch(damageCharge(JSON.stringify({
            bookingId:bookingId,
            price:amount
        })));
        setdamagePrice(amount)
        SuccessToast("Montant prélevé");
        setIsOpen();
    }

    return (
        <div>
            <Offcanvas
                placement="end"
                className="clt_admin_dash_select_refund_booking_offcanvas"
                show={isOpen}
                onHide={setIsOpen}
            >
                <Offcanvas.Header className="justify-content-start clt_admin_dash_select_refund_booking_offcanvas_header">

                    <button
                        type="button"
                        className="btn-close btn-back shadow-none d-flex"
                        aria-label="Close"
                        onClick={onBack}
                    >
                        <img
                            src={BackArrow}
                            className=""
                            alt={IMG_ALT}
                        />
                    </button>

                    <Offcanvas.Title>Caution</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="clt_admin_dash_select_refund_booking_offcanvas_body">
                    <div className='clt_admin_dash_select_refund_booking_box_field'>
                        <SectionDepositRefund handleInputChange={handleInputChange}/>
                    </div>
                    <div className="customFooterButton w-100">
                        <hr />
                        <Button
                            // onClick={() => onSaveAccount("first name")}
                            onClick={calldamageCharge}
                            className="clt_admin_dash_damage_deposit_booking_formButton shadow-none"
                        >
                            <span>Enregistrer</span>
                        </Button>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>

        </div>
    )
}

export default DamageDeposit