// Import
import React, { useState, useEffect, useRef } from "react";
import "./inbox.css";
import { Button } from "react-bootstrap";
import SearchIcon from "../../../../assets/img/home_searchbar_icon.png";
import SectionSearchChat from "../../../reusable/search/Search - Chat/[SEARCH] Chat - User ";
import { IMG_ALT } from "../../../../constants";
import Form from "react-bootstrap/Form";
import image from "../../../../assets/img/confirm_pay_profil_picture_1.png";
import { userlist } from "../../../reusable/search/Search - API/[API] Seach a user or invite.js";
import { useAuth } from "../../auth/authContext";
import moment from "moment";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { createNewChat } from "../api";
import { ErrorToast } from "../../../reusable/Toast";
import "moment/locale/fr";
import data from "bootstrap/js/src/dom/data";
import { getAdminProfile } from "../../adminDashboard/AdminDashboardMenu/AdminDashboardBusinessInformation/api";
import { auth } from "../../auth/firebase";
import { LoaderDots } from "@thumbtack/thumbprint-react";

const isEmail = (input) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return emailRegex.test(input);
};

const InboxListContainer = ({
  userType,
  showInboxDetail,
  setOpenChat,
  openChat,
  requestStatus,
  socket,
  setSelectedChatUser,
  setMessagesLoading,
  handleSidebar,
  selectedChatUser,
}) => {
  const [users, setUsers] = useState([]);
  const queryClient = useQueryClient();
  const [chatsDetail, setChatsDetail] = useState([]);
  const [selectedChat, setSelectedChat] = useState([]);
  const [userFormData, setUserFormData] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [authUser, setAuthUser] = useState([]);
  const currentChatId = useRef(null);
  const { user } = useAuth()

  // const { socket, connected } = useSocket();

  // const user = useAuth();
  const [AdminProfile, setAdminProfile] = useState({

    name: "",
    profile_picture_url: null,
    email: "",
    phone: "",
    business_address: {
      address: "",
      city: "",
      zip_code: "",
      state_region: "",
      country: "",
    },
    billing_address: {
      address: "",
      city: "",
      zip_code: "",
      state_region: "",
      country: "",
    },
    commercial_name: "",
    capital_social: "",
    siret: "",
    tva_number: "",
    uid: "",
  });

  useEffect(() => {
    const bootstrap = async () => {
      const req = await getAdminProfile();
      const user = req.data.data;
      const currentUser = auth.currentUser;
      setCurrentUser(currentUser);
      setAdminProfile({
        name: user?.name || "",
        commercial_name: user?.commercialName || "",
        capital_social: user?.capitalSocial || "",
        siret: user?.siret || "",
        tva_number: user?.tvaNumber || "",
        email: user?.email || "",
        phone: user?.phone || "",
        business_address: {
          address: user?.businessAddress ?? "",
          city: user?.businessCity ?? "",
          zip_code: user?.businessZipCode ?? "",
          state_region: user?.businessState ?? "",
          country: user?.businessCountry ?? "",
        },
        billing_address: {
          address: user?.billingAddress ?? "",
          city: user?.billingCity ?? "",
          zip_code: user?.billingZipCode ?? "",
          state_region: user?.billingState ?? "",
          country: user?.billingCountry ?? "",
        },
        profile_picture_url: user?.profilePictureUrl ?? null,
        uid: user?.uid ?? "",
      });
    };
    bootstrap();
  }, [selectedChatUser]);

  useEffect(() => {
    getChats();

    listenReceiveMessage();
    listenMessageSent();
    listenReadStateChange()
    listenContactRequest()
    listenContactRequestUpdated()
  }, [socket]);

  const getChats = async () => {
    setChatLoading(true);
    socket?.emit("get_user_chats", (error, chats) => {
      if (error) {
        console.log(error);
        setChatLoading(false);
      } else {
        setChatsDetail(chats);
        setChatLoading(false);
        const sortChat = chats?.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        if (sortChat.length !== 0)
          joinChat(
            sortChat[0]?.id,
            sortChat[0]
          );
      }
    });
  };

  const translateState = (state) => {
    const translations = {
      'contact request sent': 'Demande contact - Envoyé',
      'contact request in progress': 'Demande contact - En cours',
      'just chatting': 'Discussion',
      // Add more translations as needed
    };
  
    return translations[state] || state;
  };

  console.log("chatDetails", chatsDetail.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)))

  const listenReceiveMessage = () => {
    socket?.on("newMessage", setChats)
  }

  const listenMessageSent = () => {
    socket?.on("messageSent", setChats)
  }

  const listenReadStateChange = () => {
    socket?.on("readStateChanged", setChats);
  };

  const listenContactRequest = () => {
    socket?.on("chatStateUpdated", handleChatStateChange)
  }

  const listenContactRequestUpdated = () => {
    socket?.on('contactRequestUpdated', setChats)
  }

  const setChats = (data) => {
    socket?.emit("get_user_chats", (error, chats) => {
      if (error) {
        console.log(error);
        setChatLoading(false);
      } else {
        setChatsDetail(chats);
        setChatLoading(false);
      }
    });
  };

  const handleChatStateChange = (data) => {
    console.log("chat state changed", data);

    if(currentChatId.current === data.id) {
      handleSidebar(data)
    }

    setChats()
  }

  const joinChat = async (chatId, chat) => {
    console.log("selected auth", auth.currentUser, chat);

    const currentUser = auth.currentUser;

    if(user.role === 'admin') {
      if(AdminProfile.uid === chat?.User2?.uid) {
        setSelectedChatUser(chat?.User1)
      }
      else {
        setSelectedChatUser(chat?.User2)
      }
    }
    else {
      if (currentUser?.uid === chat?.User1.uid) {
        setSelectedChatUser(chat?.User2);
      } else {
        setSelectedChatUser(chat?.User1);
      }
    }

    showInboxDetail()
    setMessagesLoading(true)
    setOpenChat(chatId)
    handleSidebar(chat)
    currentChatId.current = chatId

    socket?.emit("join_chat", { chatId }, (error) => {
      if (error) {
        console.log(error);
        setMessagesLoading(false);
      } else {
        setMessagesLoading(false);
      }
    });
  };

  const searchHandler = async (input) => {
    try {
      setSearchLoading(true);
      const response = await userlist({ search: input });
      console.log("RESS DAT");
      console.log(response.data?.data);
      const getUsers =
        response.data?.data.map((user) => {
          return {
            uid: user?.uid,
            name: `${user.first_name ? user.first_name : user.firstName} ${
              user.last_name ?? ""
            }`,
            // name: `${user.first_name?user.first_name:user.firstName} ${user.last_name?user.last_name:user.lastName}`,
            type: "user",
            detail: `${user.phone} ${user.phone ? " - " : ""} ${user.email}`,
            profile_picture_url: user.profile_picture_url || user.profilePictureUrl,
          };
        }) || [];
      if (getUsers?.length === 0 && input?.length > 0 && isEmail(input))
        getUsers.push({ name: input, type: "email", detail: "" });
      setUsers(getUsers);
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
      console.log(error);
    }
  };

  const handleCreateChatError = (error) => {
    if (
      error.response.status == 400 &&
      error.response.data.message === "chat already exists"
    ) {
      console.log("error.response.data.message", error.response);
    } else {
      ErrorToast(error.response.data.message);
    }
  };

  const handleGetAllChatsAgain = () => {
    getChats();
  };

  const createNewChatUser = useMutation(createNewChat, {
    onSuccess: handleGetAllChatsAgain,
    onError: handleCreateChatError,
  });

  const handleCreateNewChat = (id) => {
    const formData = {
      user: id,
      chat_state: 'just chatting',
    };
  
    if (chatsDetail.filter((chat) => chat?.userId2 === id).length !== 0) {
      const findChatDetail = chatsDetail.filter((chat) => chat?.userId2 === id);
      joinChat(findChatDetail[0]?.id, findChatDetail[0]);
    } else {
      setUserFormData(formData);
      console.log('creating new chat');
      // Créer une nouvelle discussion
      createNewChatUser.mutate({
        data: formData,
      });
    }
  };

  return (
    // Inbox
    <div className="clt_inbox_container ">
      {/* Inbox - Search bar */}
      <div className="clt_inbox_container_searchbar">
        {userType == "admin" && (
          <h2 className="clt_inbox_searchbar_h2">Messages</h2>
        )}
        <SectionSearchChat  chChat
          usersData={users}
          searchHandler={searchHandler}
          handleCreateNewChat={handleCreateNewChat}
          loading={searchLoading}
        />
      </div>

      <div className="clt_inbox_container_list">
        {chatLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <LoaderDots theme="muted" />
          </div>
        ) : (
          <>
            {chatsDetail
              ?.sort((a, b) =>
              moment(b.Messages[b.Messages.length - 1]?.createdAt).diff(moment(a.Messages[a.Messages.length - 1]?.createdAt), 'seconds')
            )?.map((chat, index) => (
                <>
                      {/* Inbox User - List - Container - Just chatting */}
                  <div
                    key={index}
                    onClick={() => joinChat(chat?.id, chat)}
                    className={`clt_inbox_container_detail ${
                      openChat == chat?.id ? "selected-inbox" : ""
                    }
                    ${
                      currentUser.uid === chat?.userId2 || (user.role == 'admin' && AdminProfile.uid == chat?.userId2) ? 
                        chat?.is_user2_read && chat?.is_user2_read === true ? "" : "unread-inbox"
                        :
                        chat?.is_user1_read && chat?.is_user1_read === true ? "" : "unread-inbox"
                    }
                    `}
                  >
                    {currentUser?.uid === chat?.User2?.uid || (user?.role === 'admin' && AdminProfile.uid === chat?.User2?.uid) ? (
                      <>
                        {chat?.User1?.role === "admin_profile" ? (
                          <>
                            {AdminProfile?.profile_picture_url ? (
                              <img
                                src={AdminProfile?.profile_picture_url}
                                alt={IMG_ALT}
                              />
                            ) : (
                              <div className="clt_messages_container_detail_icon">
                                {AdminProfile?.name[0]?.toUpperCase()}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {chat?.User1?.profilePictureUrl ? (
                              <img
                                src={chat?.User1?.profilePictureUrl}
                                alt={IMG_ALT}
                              />
                            ) : (
                              <div className="clt_messages_container_detail_icon">
                                {chat?.User1?.firstName[0]?.toUpperCase()}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {chat?.User2?.role === "admin_profile" ? (
                          <>
                            {AdminProfile?.profile_picture_url ? (
                              <img
                                src={AdminProfile?.profile_picture_url}
                                alt={IMG_ALT}
                              />
                            ) : (
                              <div className="clt_messages_container_detail_icon">
                                {AdminProfile?.name[0]?.toUpperCase()}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {chat?.User2?.profilePictureUrl ? (
                              <img
                                src={chat?.User2?.profilePictureUrl}
                                alt={IMG_ALT}
                              />
                            ) : (
                              <div className="clt_messages_container_detail_icon">
                                {chat?.User2?.firstName[0]?.toUpperCase()}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {/* Pull - Profile picture - Admin: <img className="clt-account-profile-pic" src={adminAccount.profile_picture} alt={IMG_ALT}/> */}
                    {/* Pull - First letter of name - Admin: adminAccount.name?.charAt(0) */}

                    {/* Component - Admin name and Date delivery of last message */}
                    <div className="clt_inbox_container_accountSubDetail">
                      <div className="clt_inbox_container_accountSubDetail_acc">
                        <h2 className={`${
                          currentUser.uid === chat?.userId2 || (user?.role === 'admin' && AdminProfile.uid === chat?.userId2 ) ? 
                          chat?.is_user2_read && chat?.is_user2_read === true ? "readMsg" : "boldMsg"
                          :
                          chat?.is_user1_read && chat?.is_user1_read === true ? "readMsg" : "boldMsg"
                        } overflow-wrap-ellipsis`}>

                          {currentUser?.uid === chat?.User2?.uid || (user?.role === 'admin' && AdminProfile.uid === chat?.User2?.uid) ? (
                            <>
                              {chat?.User1?.role === "admin_profile"
                                ? AdminProfile.name
                                : chat?.User1?.firstName +
                                  " " +
                                  chat?.User1?.lastName}
                            </>
                          ) : (
                            <>
                                {chat?.User2?.role === "admin_profile"
                                ? AdminProfile.name
                                : chat?.User2?.firstName +
                                  " " +
                                  chat?.User2?.lastName}
                            </>
                          )}
                          {/*{chat?.User2?.role === "admin_profile"*/}
                          {/*    ? AdminProfile.name*/}
                          {/*    : chat?.User2?.firstName +*/}
                          {/*    " " +JSON.stringify(currentUser?.uid)+" "+chat?.User2?.uid+*/}
                          {/*    chat?.User2?.lastName}*/}
                          {/* {chat?.User2?.firstName} {chat?.User2?.lastName} - {chat?.User1?.firstName} {chat?.User1?.lastName} */}
                        </h2>
                        {chat?.Messages.length !== 0 && (
                          <p className={`${
                            currentUser.uid === chat?.userId2 || (user?.role === 'admin' && AdminProfile.uid === chat?.userId2 ) ? 
                            chat?.is_user2_read && chat?.is_user2_read === true ? "readMsg" : "boldMsg"
                            :
                            chat?.is_user1_read && chat?.is_user1_read === true ? "readMsg" : "boldMsg"
                          } overflow-wrap`}>
                            {moment(
                              chat?.Messages[chat?.Messages.length - 1]
                                ?.createdAt
                            ).format("DD MMMM")}
                          </p>
                        )}
                      </div>

                      {/* Component - Message */}
                      {chat?.Messages.length !== 0 ? (
                        <p className={`${
                          currentUser.uid === chat?.userId2 || (user?.role === 'admin' && AdminProfile.uid === chat?.userId2 ) ? 
                          chat?.is_user2_read && chat?.is_user2_read === true ? "readMsg" : "boldMsg"
                          :
                          chat?.is_user1_read && chat?.is_user1_read === true ? "readMsg" : "boldMsg"
                        } clt_inbox_container_text_message overflow-wrap`}>
                          {
                            chat?.Messages[chat?.Messages.length - 1]
                              ?.content
                          }
                        </p>
                      ) : (
                        <p className={`${
                          currentUser.uid === chat?.userId2 || (user?.role === 'admin' && AdminProfile.uid === chat?.userId2 ) ? 
                          chat?.is_user2_read && chat?.is_user2_read === true ? "readMsg" : "boldMsg"
                          :
                          chat?.is_user1_read && chat?.is_user1_read === true ? "readMsg" : "boldMsg"
                        } clt_inbox_container_text_message overflow-wrap`}>
                          Commencez à discuter maintenant
                        </p>
                      )}

                      {/* Component - Container - List - Information request - Detail" */}
                      <div className="clt_inbox_container_contact_list">
                        <div className="eventDetail_user booking_user">
                        <p className={`${
                          currentUser.uid === chat?.userId2
                            ? chat?.is_user2_read && chat?.is_user2_read === true
                              ? "readMsg"
                              : "boldMsg"
                            : chat?.is_user1_read && chat?.is_user1_read === true
                            ? "readMsg"
                            : "boldMsg"
                        } overflow-wrap`}>
                          <span>{translateState(chat?.chatState)}</span>{" "}
                          {chat?.chatState === 'contact request sent' ||
                          chat?.chatState === 'contact request in progress' ? (
                            <>
                              <span>- </span>
                              <span className="contact_request">{chat?.contactRequest?.Event.title}</span>
                              <br />
                              <span className="contact_request">
                                {chat?.contactRequest?.month} {chat?.contactRequest?.year}
                              </span>
                            </>
                          ) : null}
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default InboxListContainer;
