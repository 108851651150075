// import
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Offcanvas, Row } from "react-bootstrap";
import "./OptionRoom.css";
import { IMG_ALT, IMG_BASEURL, selectRoomSliderResponsive } from "../../../constants";
import CustomCarousel from "../../reusable/CustomCarousel";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

// import - Image / icon
import DetailSlider_1_1 from "../../../assets/img/detail_slider_1_1.png";
import DetailSlider_1_2 from "../../../assets/img/detail_slider_1_2.png";
import DetailSlider_1_3 from "../../../assets/img/detail_slider_1_3.png";
import DetailSlider_1_4 from "../../../assets/img/detail_slider_1_4.png";
import DetailIcon1 from "../../../assets/img/detail_icon_1.jpg";
import BackArrow from "../../../assets/img/back_arrow.png";
import SectionSelectOption from "../adminDashboard/AdminDashboardStore/AdminDashboardTimeslot/section_select_option_room/SectionSelectOption";
import { AuthContext } from "../auth/authContext";








/* Const */
const Bed = ({
  isBedSidebar,
  onCloseBedSidebar,
  onConfirm,
  isComingFromMyBooking,
  allRooms,
  eventType,
  timeSelected
}) => {
  const [selecRoomQuantity, setSelectRoomQuantity] = useState([])
  const [selecRoomPrice, setSelecRoomPrice] = useState([])
  const { selectTotalRooms, setSelectTotalRooms, selectedRoomData, setSelectedRoomData } = useContext(AuthContext);

  console.log('this is selected room data', selectedRoomData);
  useEffect(() => {
    setSelectedRoomData([]);
  }, [timeSelected]);
  /* Const - Room - List*/
  const [rooms, setRooms] = useState([
    {
      id: 1,
      title: "Room title 1",
      subTitle: "subTitle1 - subTitle2",
      gallery: [
        {
          src: DetailSlider_1_1,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_2,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_3,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
      ],
      subTags: [
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
      ],
      nightsDetails: [
        {
          id: 1,
          night_quantity: 1,
          night_price: 10,
          night_total_price: 10,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 2,
          night_quantity: 2,
          night_price: 10,
          night_total_price: 20,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 3,
          night_quantity: 3,
          night_price: 10,
          night_total_price: 30,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 4,
          night_quantity: 4,
          night_price: 10,
          night_total_price: 40,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 5,
          night_quantity: 5,
          night_price: 10,
          night_total_price: 50,
          night_quantity_limit: 5,
          checked: false,
        },
      ],
    },
    {
      id: 2,
      title: "Room title 2",
      subTitle: "subTitle1 - subTitle2",
      gallery: [
        {
          src: DetailSlider_1_1,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_2,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_3,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
        {
          src: DetailSlider_1_4,
          name: "Image name",
          width: 1950,
          height: 1300,
        },
      ],
      subTags: [
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
        {
          id: 1,
          icon: DetailIcon1,
          title: "TagTitle",
        },
      ],
      nightsDetails: [
        {
          id: 1,
          night_quantity: 1,
          night_price: 10,
          night_total_price: 10,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 2,
          night_quantity: 2,
          night_price: 10,
          night_total_price: 20,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 3,
          night_quantity: 3,
          night_price: 10,
          night_total_price: 30,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 4,
          night_quantity: 4,
          night_price: 10,
          night_total_price: 40,
          night_quantity_limit: 5,
          checked: false,
        },
        {
          id: 5,
          night_quantity: 5,
          night_price: 10,
          night_total_price: 50,
          night_quantity_limit: 5,
          checked: false,
        },
      ],
    },
  ]);




  const [selectedCheck, setSelectedCheck] = useState(
    allRooms?.map((room) =>
      [])
  );
  // const [selectedCheck, setSelectedCheck] = useState(
  //   allRooms?.map((room) =>
  //     Array?.from({ length: room?.selectedroom?.maxQuantity }, () => false)
  //   )
  // );

  useEffect(() => {
    setSelectedCheck(
      allRooms?.map((room) =>
        Array?.from({ length: room?.selectedroom?.maxQuantity }, () => false)
      )
    );
  }, [eventType, timeSelected, allRooms]);
  /* Const - List - Dropdown - Select room*/
  const handleChecked = (nightQuantity, nightPrice, myroom, rIndex, index) => {
    // setSelectRoomQuantity(nightQuantity)
    const updatedCheckStates = selectedCheck?.map((roomCheckStates, rIdx) =>
      rIdx === rIndex
        ? roomCheckStates.map((isChecked, i) =>
          i === index ? !isChecked : false
        )
        : roomCheckStates
      // : roomCheckStates.map(() => false)
    );
    setSelectedCheck(updatedCheckStates.slice(0, updatedCheckStates.length));

    const updatedSelectedRoomData = [...selectedRoomData];
    updatedSelectedRoomData[rIndex] = {
      quantity: nightQuantity,
      price: nightPrice,
    };

    updatedSelectedRoomData[rIndex] = {
      quantity: updatedCheckStates[rIndex][index] ? nightQuantity : 0,
      price: updatedCheckStates[rIndex][index] ? nightPrice : 0,
    };
    setSelectedRoomData(updatedSelectedRoomData);

    // Check if all options are unchecked and update the selected room data accordingly
    const allUnchecked = updatedCheckStates[rIndex].every((isChecked) => !isChecked);
    if (allUnchecked) {
      updatedSelectedRoomData[rIndex] = {
        quantity: 0,
        price: 0,
      };
    }


    if (updatedCheckStates) {
      setSelectedRoomData(updatedSelectedRoomData);
    }

    ////
    let flag = true;
    for (let i = 0; i < selecRoomQuantity.length > 0; i++) {
      let selectroomObj = selecRoomQuantity[i];
      console.log("athule");
      console.log(selectroomObj);
      console.log(myroom);
      if (selectroomObj.id === myroom?.room?.id) {
        // selecRoomQuantity.
        selectroomObj.price = nightPrice;
        selectroomObj.quantity = nightQuantity;
        flag = false;
      }
    }
    if (flag) {
      selecRoomQuantity.push({
        id: myroom?.room?.id,
        price: nightPrice,
        quantity: nightQuantity,
      });
    }
    console.log(selecRoomQuantity);
    // setSelecRoomPrice(nightPrice)
    selecRoomPrice.push({
      id: myroom?.selectedroom?.id,
      price: nightPrice,
    })
    // const newObj = {
    //   id: myroom?.selectedroom?.id,
    //   price: nightPrice,
    //   quantity: nightQuantity,
    //   title: myroom?.room.name,
    // }
    // console.log('entered here first', selectTotalRooms,newObj)
    // setSelectTotalRooms(prevState => [...prevState, newObj]);
    const newObj = {
      id: myroom?.selectedroom?.id,
      price: nightPrice,
      quantity: nightQuantity,
      title: myroom?.room.name,
    };
    // Check if the room with the same id already exists in selectTotalRooms
    const existingRoomIndex = selectTotalRooms.findIndex(room => room.id === newObj.id);

    if (updatedCheckStates[rIndex][index]) {
      // Checkbox is checked, either update the existing room or add a new one
      if (existingRoomIndex !== -1) {
        // Replace the existing room with the new one
        const updatedRooms = [...selectTotalRooms];
        updatedRooms[existingRoomIndex] = newObj;
        setSelectTotalRooms(updatedRooms);
      } else {
        // Append the new object to selectTotalRooms
        setSelectTotalRooms(prevState => [...prevState, newObj]);
      }
    } else {
      // Checkbox is unchecked, remove the room from selectTotalRooms
      if (existingRoomIndex !== -1) {
        const updatedRooms = [...selectTotalRooms];
        updatedRooms.splice(existingRoomIndex, 1);
        setSelectTotalRooms(updatedRooms);
      }
    }
    // let roomsTemp = [...allRooms];
    // roomsTemp.map((room) => {
    //   if (room?.selectedroom.id === roomId) {
    //     room?.nightsDetails?.map((nigthDetail) => {
    //       nigthDetail.checked =
    //         nigthDetail.id === nightDetailId ? !nigthDetail.checked : false;
    //       return nigthDetail;
    //     });
    //   }
    //   return room;
    // });
    // setRooms(roomsTemp);
  };

  /* Const - List - Confirm selection */
  const handleConfirm = () => {
    let selectedRooms = [];
    let roomsTemp = [...rooms];
    roomsTemp.forEach((room) => {
      room.nightsDetails.forEach((nightDetail) => {
        nightDetail.checked &&
          (selectedRooms = [
            ...selectedRooms,
            {
              title: room.title,
              night_quantity: nightDetail.night_quantity,
              night_price: nightDetail.night_price,
              night_total_price: nightDetail.night_total_price,
              night_quantity_limit: nightDetail.night_quantity_limit,
            },
          ]);
      });
    });
    onConfirm(selectTotalRooms);
    onCloseBedSidebar();
  };








  /* Const - List - Open Gallery*/
  // const openGallery = (gallery, index, galleryPic) => {

  //   let lightbox = {};
  //   lightbox = new PhotoSwipeLightbox({
  //     gallery: "#gallery",
  //     children: "div",
  //     dataSource: gallery,
  //     pswpModule: () => import("photoswipe"),
  //   });
  //   lightbox.init();
  //   lightbox.loadAndOpen(index);
  // };


  /* Const - Handle the screen size to make page responsive */
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  const openGallery = async (type, index, myGallery) => {

    const lightboxGallery = await Promise.all(
      myGallery.map(async (item) => {
        const img = new Image();
        // img.src = IMG_BASEURL + item.galleryImageUrl;
        img.src = item?.ImageUrl;
        await img.decode();
        return {
          src: img.src,
          name: item.title,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };



  return (
    <div id="gallery" className="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
      <Offcanvas
        placement="end"
        className="clt_products_offcanvas"
        show={isBedSidebar}
        onHide={onCloseBedSidebar}
      >

        {/* Screen - Header */}
        <Offcanvas.Header className="justify-content-start clt_products_offcanvas_header">
          <React.Fragment>
            <button
              type="button"
              className="btn-close btn-back shadow-none d-flex"
              aria-label="Close"
              onClick={() => {
                onCloseBedSidebar();
                onConfirm(selectTotalRooms);


              }}
            >
              <img src={BackArrow} className="" alt={IMG_ALT} />
            </button>
            <Offcanvas.Title>Room</Offcanvas.Title>
          </React.Fragment>
        </Offcanvas.Header>


        {/* Screen - Body */}
        <Offcanvas.Body className="clt_products_offcanvas_body clt-datepicker-sidebar">
          <>
            <Row>
              <Col>
                <h2 className="clt-datepicker-sidebar-h2">Select room</h2>
              </Col>
            </Row>

            {/* List */}
            {allRooms?.map((room, rIndex) => {
              console.log("This is room", room)
              return (
                <Row key={rIndex}>
                  <Col>

                    {/* Room - Card */}
                    <div className="clt-room-divs">

                      {/* Gallery - Slider */}
                      <PhotoProvider>
                        <CustomCarousel
                          responsive={selectRoomSliderResponsive}
                          arrows={true}
                          shouldResetAutoplay={false}
                          renderButtonGroupOutside={true}
                          showDots={true}
                          swipeable={true}
                          className="clt-room-slider"
                        >

                          {/* Gallery */}
                          {room?.room?.roomgalleries.map((galleryPic, gIndex) => {
                            return (

                              // <PhotoView src={galleryPic?.ImageUrl}>
                              <div
                                key={gIndex}
                                className="d-flex justify-content-start align-items-start clt-room-slider-img-div cursor-zoom-in"
                                style={{
                                  backgroundImage: `url(${galleryPic?.ImageUrl})`,
                                }}
                                onClick={() => openGallery("main", gIndex, room?.room?.roomgalleries)}
                              // onClick={() => openGallery(room?.room?.roomgalleries, gIndex, galleryPic)}
                              />
                              // </PhotoView>

                            );
                          })}
                        </CustomCarousel>
                      </PhotoProvider>


                      <div className="clt-room-text-div">

                        {/* Room - Title */}
                        <h2 className="clt-room-text-h2">{room?.room?.name}</h2>

                        {/* Room - Subtitle */}
                        <p className="clt-room-text-p">{room?.room?.subTitle}</p>

                        {/* Room - Tag */}
                        <div className="d-flex justify-content-start align-items-center clt-room-text-tags">
                          <span
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img src={room?.room.image1} alt={IMG_ALT} />
                            {room?.room.subTag1Title}
                          </span>
                          <span
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img src={room?.room.image2} alt={IMG_ALT} />
                            {room?.room.subTag2Title}
                          </span>
                          <span
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img src={room?.room.image3} alt={IMG_ALT} />
                            {room?.room.subTag3Title}
                          </span>
                          <span
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img src={room?.room.image4} alt={IMG_ALT} />
                            {room?.room.subTag4Title}
                          </span>
                          <span
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img src={room?.room.image5} alt={IMG_ALT} />
                            {room?.room.subTag5Title}
                          </span>
                        </div>
                        <div className="text-end">

                          {/* Button */}
                          <Dropdown>
                            {/*<Dropdown.Toggle*/}
                            {/*  variant="primary"*/}
                            {/*  id="dropdown-basic"*/}
                            {/*  className="clt-room-dropdown-btn"*/}
                            {/*>*/}
                            {/*  {(selecRoomQuantity.length > 0 && selecRoomPrice.length > 0)*/}
                            {/*    ? `${*/}
                            {/*      selecRoomQuantity*/}
                            {/*      } night ${*/}
                            {/*        selecRoomPrice*/}
                            {/*      }€`*/}
                            {/*    : "Add"}*/}
                            {/*</Dropdown.Toggle>*/}
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="clt-room-dropdown-btn"
                            >
                              {selectedRoomData[rIndex]?.quantity > 0 && selectedRoomData[rIndex]?.price > 0 ? (
                                `${selectedRoomData[rIndex].quantity} night ${selectedRoomData[rIndex].price}€`
                              ) : (
                                "Add"
                              )}
                            </Dropdown.Toggle>


                            {/* Dropdown */}
                            <Dropdown.Menu
                              size="sm"
                              className="pfr_dropdown_menu"
                            >
                              {
                                Array.from({ length: room.selectedroom.maxQuantity }, (_, index) => {

                                  const nightDetail = {
                                    id: index + 1,
                                    night_quantity: index + 1,
                                    night_total_price: (index + 1) * room.selectedroom.pricePerNight
                                  };
                                  return (
                                    <Dropdown.Item
                                      onClick={() => handleChecked(nightDetail.night_quantity, nightDetail.night_total_price, room, rIndex, index)}
                                      className="d-flex flex-row align-items-start clt-room-dropdown-items"
                                      key={index}
                                    >

                                      <Form.Check
                                        style={{ pointerEvents: 'none' }}
                                        inline
                                        type="checkbox"
                                        checked={selectedCheck[rIndex][index]}
                                        onChange={(e) => e.preventDefault()}
                                      />
                                      <div className="d-flex flex-column align-items-start justify-content-center inner-div-text">
                                        <h2>{nightDetail.night_quantity} night</h2>
                                        <span>
                                          {nightDetail.night_total_price}€
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                  );
                                })
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </>
        </Offcanvas.Body>

        {/* Footer */}
        <div>
          <div className="clt-datepicker-sidebar-footer">
            <div className="inner-div">
              <Button
                onClick={handleConfirm}
                className="clt-datepicker-sidebar-footer-btn black_btn shadow-none"
              >
                <span>Confirm</span>
              </Button>
            </div>
          </div>
        </div>
      </Offcanvas>
    </div >
  );
};

export default Bed;


function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}