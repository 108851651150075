import React, { useState } from 'react'
import SectionEvent from '../../../../../../reusable/booking/SectionEvent';
import SectionGuest from '../../../../../../reusable/booking/SectionGuest';
import SectionDate from '../../../../../../reusable/booking/SectionDate';
import BackArrowRight from "../../../../../../../assets/img/back_arrow_right.png";
import IconPending from "../../../../../../../assets/img/icon_pending.png";
import IconCancel from "../../../../../../../assets/img/icon_cancel.png";
import ConfirmPay1 from "../../../../../../../assets/img/icon-event-wedding-detail.png";
import ConfirmPay2 from "../../../../../../../assets/img/confirm_pay_2.png";
import ConfirmPay3 from "../../../../../../../assets/img/confirm_pay_3.png";
import NextArrow from "../../../../../../../assets/img/back_arrow_right.png";
import { IMG_ALT } from "../../../../../../../constants";
import { Button } from "react-bootstrap";
// import - Sidebar - Edit request - Pending
import moment from 'moment';



const SlideMissingPayment = ({ key, dataItem, setBookingSidebarDetail, onOpenAdminBookingDetailSidebar }) => {
    const [openSidebar, setOpenSidebar] = React.useState(false)
    /* Const - Sidebar - Booking Detail */
    const [isSidebarCancelRequestPendingAdmin, setIsSidebarCancelRequestPendingAdmin] = useState(false);
    const onOpenSidebarCancelRequestPendingAdmin = () => {
        setIsSidebarCancelRequestPendingAdmin(true)
    }
    const onCloseSidebarCancelRequestPendingAdmin = () => {
        setIsSidebarCancelRequestPendingAdmin(false)
    }


  const handleDateFormat = (dateTime) => {
    const now = moment();
    const diffInMinutes = now.diff(dateTime, 'minutes');
  
    if (diffInMinutes < 1) {
      return 'Maintenant';
    } else if (diffInMinutes < 60) {
      return `Il y à ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'}`;
    } else if (diffInMinutes < 1440) {
      const hoursAgo = Math.floor(diffInMinutes / 60);
      return `Il y à ${hoursAgo} heure${hoursAgo === 1 ? '' : 's'}`;
    } else if (now.isSame(dateTime, 'day')) {
      return moment(dateTime).format('HH:mm');
    } else if (now.isSame(dateTime, 'year')) {
      return moment(dateTime).format('DD MMM HH:mm');
    } else {
      return moment(dateTime).format('DD MMM YYYY, HH:mm');
    }
  }

    return (
        <>
            <div key={dataItem?.id} onClick={()=> { setBookingSidebarDetail(dataItem?.Booking); onOpenAdminBookingDetailSidebar(); }} className="d-flex flex-column justify-content-start align-items-start clt-admin-booking-section-3-cards clt-admin-booking-section-3-cards-secondary" >
                <div className='w-100'>

                    <div className="d-flex justify-content-between align-center">

                        <div className="d-flex justify-content-center align-center">
                            {/* Name */}
                            <div className="d-flex flex-column justify-content-center align-items-start clt-admin-dash-booking-slider-text">
                                <h2>Paiement manquant</h2>
                            </div>
                        </div>
                        <div className='clt_slider_edit_request_container_request_state'>
                            <div className='clt_admin_dash_booking_avatar_wrapper_navigation_request mt-0'>
                                {dataItem?.Booking?.User?.profilePictureUrl ?
                                    <div className="clt_admin_dash_booking_avatar_container_booking_img">
                                        <img src={dataItem?.Booking?.User?.profilePictureUrl} alt={dataItem?.Booking?.User?.firstName} />
                                    </div>
                                    :
                                    <div className="clt_admin_dash_booking_avatar_container_booking">
                                        <div className='clt_admin_dash_booking_avatar_name_container_request'>{dataItem?.Booking?.User?.firstName[0].toUpperCase()}</div>
                                    </div>
                                }
                            </div>

                            {/* Button - arrow */}
                            <button
                                type="button"
                                className="back-button"
                                aria-label="Close"
                            >
                                <img src={BackArrowRight} className="back-button-img mt-2" alt={IMG_ALT} />
                            </button>
                        </div>
                    </div>
                    <div className='clt_slider_edit_request_container_request_state'>
                        <div className="d-flex flex-column justify-content-center align-items-start clt-admin-dash-booking-slider-text">
                            <p>{handleDateFormat(dataItem.updatedAt)}</p>
                        </div>
                    </div>
                    <div className="clt-booking-mb row">
                        <div className="clt_admin_dash_booking_cancel_container_detail">
                            <div className="wedding">{`${moment(dataItem?.Booking?.ChildTimeSlot?.startDate).format('DD')}-${moment(dataItem?.Booking?.ChildTimeSlot?.endDate).format('DD MMM')}`}</div>
                            <div className="wedding">{`#${dataItem?.Booking?.id}`}</div>
                            <div className="wedding">{`${dataItem?.Booking?.Event?.title}`}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<SidebarCancelRequestPendingAdmin
                isOpenSidebarCancelRequestPendingAdmin={openSidebar}
                onCloseOpenSidebarCancelRequestPendingAdmin={()=> setOpenSidebar(false)}
            />
             Hook - Sidebar - Booking Detail  
            <SidebarCancelRequestPending
                onCloseSidebarCancelRequestPending={onCloseSidebarCancelRequestPending}
                isSidebarCancelRequestPending={isSidebarCancelRequestPending}
            />*/}
        </>
    )
}

export default SlideMissingPayment