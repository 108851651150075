import React from 'react';
// import { useLocation } from 'react-router-dom';
import './Footer.css';
// import { GlobalContext } from '../../Context/Context';

const Footer = ({adminNavLinks, setAdminNavLinks}) => {
  
    // const [hideData, setHideData] = useState(true);
    // const { hideData } = useContext(GlobalContext);

    // const location = useLocation()

    // useEffect(()=>{
    //     let currentLocation = location.pathname.split("/")
    //     if(currentLocation.includes("admin") || currentLocation.includes("chat")){
    //         console.log(hideData)
    //         setHideData(true)
    //     }
    //   },[location.pathname])


    return (
        <div className="clt_admin_sidebar">

            {/* <div className="clt_admin_sidebar_header">
                <div>
                    <p>Admin</p>
                    <p>CLT</p>
                </div>
            </div> */}
            <div className="clt_admin_links">
                <section onClick={() => setAdminNavLinks(1)} className={adminNavLinks === 1 ? "clt_admin_sidebar_section-par-active" : "clt_admin_sidebar_section-par"}>
                    <div className={adminNavLinks === 1 ? "clt_admin_sidebar_drawermenuitema1a clt_admin_sidebar_drawermenuitem" : "clt_admin_sidebar_drawermenuitema1 clt_admin_sidebar_drawermenuitem"}></div>
                    <span className={adminNavLinks === 1 ? "clt_admin_sidebar_admin-span-text-active clt_admin_sidebar_f-txt" : "clt_admin_sidebar_f-txt"}>Accueil</span>
                </section>
                <section onClick={() => setAdminNavLinks(2)} className={adminNavLinks === 2 ? "clt_admin_sidebar_section-par-active" : "clt_admin_sidebar_section-par"} >
                    <div className={adminNavLinks === 2 ? "clt_admin_sidebar_drawermenuitema2a clt_admin_sidebar_drawermenuitem" : "clt_admin_sidebar_drawermenuitema2 clt_admin_sidebar_drawermenuitem"}></div>
                    <span className={adminNavLinks === 2 ? "clt_admin_sidebar_admin-span-text-active clt_admin_sidebar_f-txt" : "clt_admin_sidebar_f-txt"}>Réservation</span>
                </section>
                <section onClick={() => setAdminNavLinks(3)} className={adminNavLinks === 3 ? "clt_admin_sidebar_section-par-active" : "clt_admin_sidebar_section-par"} >
                    <div className={adminNavLinks === 3 ? "clt_admin_sidebar_drawermenuitema3a clt_admin_sidebar_drawermenuitem" : "clt_admin_sidebar_drawermenuitema3 clt_admin_sidebar_drawermenuitem"}></div>
                    <span className={adminNavLinks === 3 ? "clt_admin_sidebar_admin-span-text-active clt_admin_sidebar_f-txt" : "clt_admin_sidebar_f-txt"}>Message</span>
                </section>
                <section onClick={() => setAdminNavLinks(4)} className={adminNavLinks === 4 ? "clt_admin_sidebar_section-par-active" : "clt_admin_sidebar_section-par"} >
                    <div className={adminNavLinks === 4 ? "clt_admin_sidebar_drawermenuitema4a clt_admin_sidebar_drawermenuitem" : "clt_admin_sidebar_drawermenuitema4 clt_admin_sidebar_drawermenuitem"}></div>
                    <span className={adminNavLinks === 4 ? "clt_admin_sidebar_admin-span-text-active clt_admin_sidebar_f-txt" : "clt_admin_sidebar_f-txt"}>Vitrine</span>
                </section>
                <section onClick={() => setAdminNavLinks(5)} className={adminNavLinks === 5 ? "clt_admin_sidebar_section-par-active" : "clt_admin_sidebar_section-par"} >
                    <div className={adminNavLinks === 5 ? "clt_admin_sidebar_drawermenuitema5a clt_admin_sidebar_drawermenuitem" : "clt_admin_sidebar_drawermenuitema5 clt_admin_sidebar_drawermenuitem"}></div>
                    <span className={adminNavLinks === 5 ? "clt_admin_sidebar_admin-span-text-active clt_admin_sidebar_f-txt" : "clt_admin_sidebar_f-txt"}>Menu</span>
                </section>
                {/* <section onClick={() => setAdminNavLinks(6)} className={adminNavLinks === 6 ? "clt_admin_sidebar_section-par-active" : "clt_admin_sidebar_section-par"} >
                    <div className={adminNavLinks === 6 ? "clt_admin_sidebar_drawermenuitema5a clt_admin_sidebar_drawermenuitem" : "clt_admin_sidebar_drawermenuitema5 clt_admin_sidebar_drawermenuitem"}></div>
                    <span className={adminNavLinks === 6 ? "clt_admin_sidebar_admin-span-text-active clt_admin_sidebar_f-txt" : "clt_admin_sidebar_f-txt"}>Information</span>
                </section> */}
            </div>
        </div>
    )
}

export default Footer;