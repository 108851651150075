import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container,Button } from 'react-bootstrap';
import CustomCarousel from "../CustomCarousel";
import {
  IMG_ALT,
  selectRoomSliderResponsive,
  detailSecondarySliderResponsive
} from "../../../constants";
import CustomOffCanvas from "../CustomOffCanvas";
import Florist1_1 from "../../../assets/img/Florist1_1.jpg";
import detail_slider_1_1 from "../../../assets/img/detail_slider_1_1.png";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import CustomHr from "../CustomHr";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import admin_panel_first_section_desktop from "../../../assets/img/admin_panel_first_section_desktop.png";
import image1 from '../../../assets/img/room_slider_1.jpg';
import image2 from '../../../assets/img/room_slider_2.jpg';
import image3 from '../../../assets/img/room_slider_3.jpg';
import { Link } from 'react-router-dom';
import image4 from '../../../assets/img/room_slider_4.jpg';
import image5 from '../../../assets/img/room_slider_5.jpg';
import image6 from '../../../assets/img/room_slider_6.png';
import image7 from '../../../assets/img/room_slider_7.png';
import chaise_1_1 from '../../../assets/img/chaise_1_1.png';
import chaise_1_2 from '../../../assets/img/chaise_1_2.png';
import chaise_1_3 from '../../../assets/img/chaise_1_3.png';
import chaise_1_4 from '../../../assets/img/chaise_1_4.png';
import table_1_1 from '../../../assets/img/table_1_1.png';
import table_1_2 from '../../../assets/img/table_1_2.png';
import table_1_3 from '../../../assets/img/table_1_3.png';
import table_2_1 from '../../../assets/img/table_2_1.png';
import table_2_2 from '../../../assets/img/table_2_2.png';
import table_3_1 from '../../../assets/img/table_3_1.png';
import table_3_2 from '../../../assets/img/table_3_2.png';
import table_3_3 from '../../../assets/img/table_3_3.png';

import event_WeddingPrestaTime_1 from '../../../assets/img/event_WeddingPrestaTime_1.jpg';
import event_WeddingPrestaTime_2 from '../../../assets/img/event_WeddingPrestaTime_2.jpg';
import event_WeddingPrestaTime_3 from '../../../assets/img/event_WeddingPrestaTime_3.jpg';
import event_WeddingPrestaTime_4 from '../../../assets/img/event_WeddingPrestaTime_4.jpg';
import event_WeddingPrestaTime_5 from '../../../assets/img/event_WeddingPrestaTime_5.jpg';
import event_WeddingPrestaTime_6 from '../../../assets/img/event_WeddingPrestaTime_6.jpg';

import event_mdm_1 from '../../../assets/img/event_mdm_1.jpg';
import event_mdm_2 from '../../../assets/img/event_mdm_2.jpg';
import event_mdm_3 from '../../../assets/img/event_mdm_3.jpg';
import event_mdm_4 from '../../../assets/img/event_mdm_4.jpg';
import event_mdm_5 from '../../../assets/img/event_mdm_5.jpg';
import event_mdm_6 from '../../../assets/img/event_mdm_6.jpg';
import event_mdm_7 from '../../../assets/img/event_mdm_7.jpg';
import event_mdm_8 from '../../../assets/img/event_mdm_8.jpg';
import event_mdm_9 from '../../../assets/img/event_mdm_9.jpg';
import event_mdm_10 from '../../../assets/img/event_mdm_10.jpg';

import event_cn_traiteur_1 from '../../../assets/img/event_cn_traiteur_1.jpg';
import event_cn_traiteur_2 from '../../../assets/img/event_cn_traiteur_2.jpg';
import event_cn_traiteur_3 from '../../../assets/img/event_cn_traiteur_3.jpg';
import event_cn_traiteur_4 from '../../../assets/img/event_cn_traiteur_4.jpg';
import event_cn_traiteur_5 from '../../../assets/img/event_cn_traiteur_5.jpg';
import event_cn_traiteur_6 from '../../../assets/img/event_cn_traiteur_6.jpg';
import event_cn_traiteur_7 from '../../../assets/img/event_cn_traiteur_7.jpg';
import event_cn_traiteur_8 from '../../../assets/img/event_cn_traiteur_8.jpg';
import event_cn_traiteur_9 from '../../../assets/img/event_cn_traiteur_9.jpg';
import event_cn_traiteur_10 from '../../../assets/img/event_cn_traiteur_10.jpg';
import event_cn_traiteur_11 from '../../../assets/img/event_cn_traiteur_11.jpg';
import event_cn_traiteur_12 from '../../../assets/img/event_cn_traiteur_12.jpg';
import event_cn_traiteur_13 from '../../../assets/img/event_cn_traiteur_13.jpg';
import event_cn_traiteur_14 from '../../../assets/img/event_cn_traiteur_14.jpg';
import event_cn_traiteur_15 from '../../../assets/img/event_cn_traiteur_15.jpg';
import event_cn_traiteur_16 from '../../../assets/img/event_cn_traiteur_16.jpg';
import event_cn_traiteur_17 from '../../../assets/img/event_cn_traiteur_17.jpg';
import event_cn_traiteur_18 from '../../../assets/img/event_cn_traiteur_18.jpg';
import event_cn_traiteur_19 from '../../../assets/img/event_cn_traiteur_19.jpg';
import event_cn_traiteur_20 from '../../../assets/img/event_cn_traiteur_20.jpg';
import event_cn_traiteur_21 from '../../../assets/img/event_cn_traiteur_21.jpg';
import event_cn_traiteur_22 from '../../../assets/img/event_cn_traiteur_22.jpg';

import event_laurene_steeve_1 from '../../../assets/img/event_laurene_steeve_1.png';
import event_laurene_steeve_2 from '../../../assets/img/event_laurene_steeve_2.png';
import event_laurene_steeve_3 from '../../../assets/img/event_laurene_steeve_3.png';
import event_laurene_steeve_4 from '../../../assets/img/event_laurene_steeve_4.png';
import event_laurene_steeve_5 from '../../../assets/img/event_laurene_steeve_5.png';
import event_laurene_steeve_6 from '../../../assets/img/event_laurene_steeve_6.png';
import event_laurene_steeve_7 from '../../../assets/img/event_laurene_steeve_7.png';
import event_laurene_steeve_8 from '../../../assets/img/event_laurene_steeve_8.png';
import event_laurene_steeve_9 from '../../../assets/img/event_laurene_steeve_9.png';
import event_laurene_steeve_10 from '../../../assets/img/event_laurene_steeve_10.png';

import event_rolls_royce_1 from '../../../assets/img/event_rolls_royce_1.png';
import event_rolls_royce_2 from '../../../assets/img/event_rolls_royce_2.png';
import event_rolls_royce_3 from '../../../assets/img/event_rolls_royce_3.png';
import event_rolls_royce_4 from '../../../assets/img/event_rolls_royce_4.png';
import event_rolls_royce_5 from '../../../assets/img/event_rolls_royce_5.png';
import event_rolls_royce_6 from '../../../assets/img/event_rolls_royce_6.png';
import event_rolls_royce_7 from '../../../assets/img/event_rolls_royce_7.png';
import event_rolls_royce_8 from '../../../assets/img/event_rolls_royce_8.png';
import event_rolls_royce_9 from '../../../assets/img/event_rolls_royce_9.png';
import event_rolls_royce_10 from '../../../assets/img/event_rolls_royce_10.png';
import event_rolls_royce_11 from '../../../assets/img/event_rolls_royce_11.png';
import event_rolls_royce_12 from '../../../assets/img/event_rolls_royce_12.png';

import dortoirA1 from '../../../assets/img/clt_gallery5.jpg';
import dortoirB1 from '../../../assets/img/clt_gallery8.jpg';
import dortoirC1 from '../../../assets/img/clt_gallery9.jpg';
import clt_gallery12 from '../../../assets/img/clt_gallery12.jpg';
import clt_gallery13 from '../../../assets/img/clt_gallery13.jpg';
import clt_gallery14 from '../../../assets/img/clt_gallery14.jpg';
import clt_gallery15 from '../../../assets/img/clt_gallery15.jpg';
import clt_gallery16 from '../../../assets/img/clt_gallery16.jpg';
import clt_gallery17 from '../../../assets/img/clt_gallery17.jpg';
import clt_gallery18 from '../../../assets/img/clt_gallery18.jpg';
import clt_gallery19 from '../../../assets/img/clt_gallery19.jpg';
import clt_gallery4 from '../../../assets/img/clt_gallery4.jpg';

import imginfo from '../../../assets/img/img_section_info.png';
import admin_icon_booking_detail_3 from '../../../assets/img/admin_icon_booking_detail_3.png';
import room_icon_doublebed from '../../../assets/img/room_icon_doublebed.png';
import room_icon_singlebed from '../../../assets/img/room_icon_singlebed.png';
import { AuthContext } from "../../screens/auth/authContext";




const section4NewSection5 = [
  /* {
    img: dortoirA1,
    name: "Dortoir A",
    secondary_text: "50€/personne par nuit",
    description: "9 personnes",
  },*/

  {
    img: clt_gallery12,
    name: "Suite",
    secondary_text: "50€/personne par nuit",
    description: "9 personnes",
  },
  {
    img: chaise_1_1,
    name: "Chaise",
    secondary_text: "2€/chaise",
    description: "Quantité : 160",
  },


  /*{
    img: dortoirC1,
    name: "Dortoir B",
    secondary_text: "50€/personne par nuit",
    description: "9 personnes",
  },*/
  {
    img: event_laurene_steeve_4,
    name: "Table mange debout",
    secondary_text: "10€/table",
    description: "Quantité : 10",
  },
  /*{
    img: dortoirB1,
    name: "Dortoir C",
    secondary_text: "50€/personne par nuit",
    description: "6 personnes",
  }, */
  {
    img: table_1_1,
    name: "Table ronde ",
    secondary_text: "10€/table",
    description: "Quantité : 10",
  },
  {
    img: table_2_1,
    name: "Table rectangle",
    secondary_text: "10€/table",
    description: "Quantité : 10",
  },
  
  {
    img: event_laurene_steeve_8,
    name: "Table carré",
    secondary_text: "10€/table",
    description: "Quantité : 3",
  },
  {
    img: event_laurene_steeve_1,
    name: "Table demi-cercle",
    secondary_text: "10€/table",
    description: "Quantité : 2",
  },
  //{
   // img: table_3_1,
   // name: "Table rectangle B pour 8 personnes",
   // secondary_text: "10€/table",
   // description: "Quantité : 3",
  //},

  // ... (other section4Products data)
];

const section4ProductsSidebar = [
  /* {
    category: "Dortoir",
    products: [
      {
        img: dortoirA1,
        name: "Dortoir A",
        secondary_text: "50€/personne par nuit",
        description: "9 personnes",
        service: "Lit double x3",
        service1: "Lit simple x3",
        service2: "Chauffage",
        service3: "Climatisation",
        service4: "Linge de lit",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          dortoirA1
        ]
      },
      {
        img: dortoirC1,
        name: "Dortoir B",
        secondary_text: "50€/personne par nuit",
        description: "9 personnes",
        service: "Lit double x3",
        service1: "Lit simple x3",
        service2: "Chauffage",
        service3: "Climatisation",
        service4: "Linge de lit",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          dortoirC1
        ]
      },
      {
        img: dortoirB1,
        name: "Dortoir C",
        secondary_text: "50€/personne par nuit",
        description: "6 personnes",
        service1: "Lit simple x6",
        service2: "Chauffage",
        service3: "Climatisation",
        service4: "Linge de lit",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          dortoirB1
        ]
      },

      
      // ... (other products data)
    ],
  },*/
  {
  category: "Suite",
  products: [
    {
      img: clt_gallery13,
      name: "Suite",
      secondary_text: "50€/personne par nuit",
      description: "9 personnes",
      service: "Lit double x4",
      service1: "Lit simple x1",
      service2: "Chauffage",
      service3: "Climatisation",
      service4: "Linge de lit",
      subTexts: [
        // Add other objects as needed
      ],
      images: [
        clt_gallery12,
        clt_gallery13,
        clt_gallery14,
        clt_gallery4,
        clt_gallery15,
        clt_gallery16,
        clt_gallery17,
        clt_gallery18,
        clt_gallery19,
        
      ]
    },
    // ... (other products data)
  ],
  },
  {
    category: "Mobilier",
    products: [
      {
        img: chaise_1_1,
        name: "Chaise",
        secondary_text: "2€/chaise",
        description: "Quantité : 160",
        service: "Couleur: Marron nature",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          chaise_1_1,
          chaise_1_2,
          chaise_1_3,
          chaise_1_4
        ]
      },
      {
        img: table_1_1,
        name: "Table ronde",
        secondary_text: "10€/table",
        description: "Quantité : 20",
        service: "Couleur: Blanc",
        service1: "Largeur: 152cm",
        service2: "Hauteur:	74cm",
        service3: "Charge max:	500 kg",
        service4: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_1_1,
          table_1_2,
          table_1_3
        ]
      },
      {
        img: table_2_1,
        name: "Table rectangle",
        secondary_text: "10€/table",
        description: "Quantité : 10",
        service: "Couleur: Blanc",
        service1: "Longueur: 152cm",
        service2: "Largeur: 76cm",
        service3: "Hauteur:	74cm",
        service4: "Charge max:	200 kg",
        service5: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_2_1,
          table_2_2
        ]
      }, 
      {
        img: event_laurene_steeve_1,
        name: "Table demi-cercle",
        secondary_text: "10€/table",
        description: "Quantité : 2",
        service: "Couleur: Bois",
        service1: "Longueur:	112cm",
        service2: "Largeur: 60cm",
        service3: "Hauteur: 77cm",
        service4: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          event_laurene_steeve_1,
          event_laurene_steeve_2,
          event_laurene_steeve_3
        ]
      }, 
      {
        img: event_laurene_steeve_4,
        name: "Table mange debout",
        secondary_text: "10€/table",
        description: "Quantité : 10",
        service: "Couleur: Blanc",
        service1: "Diamètre: 80cm",
        service3: "Hauteur:	110cm",
        service5: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          event_laurene_steeve_4,
          event_laurene_steeve_5,
          event_laurene_steeve_6
        ]
      }, 
      {
        img: event_laurene_steeve_8,
        name: "Table carré",
        secondary_text: "10€/table",
        description: "Quantité : 3",
        service: "Couleur: Bois",
        service1: "Longueur: 121cm",
        service2: "Largeur: 121cm",
        service3: "Hauteur:	77cm",
        service4: "Charge max:	200 kg",
        service5: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          event_laurene_steeve_8,
          event_laurene_steeve_7,
          event_laurene_steeve_9
        ]
      }, 
      //{
        //img: table_3_1,
        //name: "Table rectangle B pour 8 personnes",
        //secondary_text: "10€/table",
        //description: "Quantité : 3",
        //service: "Couleur: Bois",
        //service1: "Longueur: 180cm",
        //service2: "Largeur: 76cm",
        //service3: "Hauteur:	76cm",
        //service4: "Charge max:	200 kg",
        //service5: "Type: Table pliante",
        //subTexts: [
          // Add other objects as needed
        //],
        //images: [
        //  table_3_1,
        //  table_3_2,
         // table_3_3
      //  ]
      // },
      // ... (other products data)
    ],
    
  },
  // ... (other categories data)
];

const images = [
  "detail_slider_1_1.png",
  "detail_slider_1_1.png",
  "detail_slider_1_1.png",
  // Add more image URLs as needed
];


const NewSection5 = ({ products, handleProductSlider, sliderProps, customButtonGroupTitle, isMobile, isDetail }) => {
  const [isEventSidebarOpen, setIsEventSidebarOpen] = useState(false);
  const [isEventSidebarList, setIsEventSidebarList] = useState(true);
  const [isEventSidebarDetail, setIsEventSidebarDetail] = useState(false);

  const [secondaryGallery, setSecondaryGallery] = useState([
    
    {
      title: "C&N Traiteur",
      subtitle: "Soirée · 22 photos",
      width: 1950,
      height: 1300,
      src: event_cn_traiteur_1,
    },
    {
      title: "Rolls-Royce",
      subtitle: "Soirée · 12 photos",
      width: 1950,
      height: 1300,
      src: event_rolls_royce_1,
    },
    {
      title: "Laurène & Steeve",
      subtitle: "Mariage · 10 photos",
      width: 1950,
      height: 1300,
      src: event_laurene_steeve_1,
    },
    {
      title: "Maison du Mariage",
      subtitle: "Noël · 6 photos",
      width: 1950,
      height: 1300,
      src: event_mdm_1,
    },
    {
      title: "Wedding Presta Time",
      subtitle: "Afterwork · 10 photos",
      width: 1950,
      height: 1300,
      src: event_WeddingPrestaTime_5,
    },
    
    
  ]);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSidebarOpen = (event) => {
    setIsEventSidebarOpen(true);
  
    // Check if the selected event is 
    if (event.title === "C&N Traiteur") {
      setSelectedEvent({
        title: "C&N Traiteur",
        subtitle: "Soirée · 22 photos",
        description: "Plongez dans une expérience envoûtante au pays des merveilles avec C&N Traiteur lors du lancement de leur toute nouvelle gamme printemps/été 2024. Une soirée enchanteresse orchestrée par Nathalie FAIDHERBE et son équipe, alliant découverte et délice. Applaudissements à BPM AGENCY & KRD Audiovisuel pour une mise en scène spectaculaire au Château Latournelle à Fenouillet !",
        images: [event_cn_traiteur_1, event_cn_traiteur_2, event_cn_traiteur_3, event_cn_traiteur_4, event_cn_traiteur_5, event_cn_traiteur_6, event_cn_traiteur_7, event_cn_traiteur_8, event_cn_traiteur_9, event_cn_traiteur_10, event_cn_traiteur_11, event_cn_traiteur_12, event_cn_traiteur_13, event_cn_traiteur_14, event_cn_traiteur_15, event_cn_traiteur_16, event_cn_traiteur_17, event_cn_traiteur_18, event_cn_traiteur_19, event_cn_traiteur_20, event_cn_traiteur_21, event_cn_traiteur_22, ], // Hardcoded gallery images for 
      });
    }  else if (event.title === "Laurène & Steeve") {
      setSelectedEvent({
        title: "Laurène & Steeve",
        subtitle: "Mariage · 10 photos",
        images: [event_laurene_steeve_1, event_laurene_steeve_2, event_laurene_steeve_3, event_laurene_steeve_4, event_laurene_steeve_5, event_laurene_steeve_6, event_laurene_steeve_7, event_laurene_steeve_8, event_laurene_steeve_10, ], // Hardcoded gallery images for Laurene & Steeve
      });
    } else if (event.title === "Rolls-Royce") {
      setSelectedEvent({
        title: "Rolls-Royce",
        subtitle: "Soirée · 12 photos",
        description: "Pour célébrer leurs 30 ans, l'équipe de support technique de Rolls-Royce a choisi le somptueux Château Latournelle. Une soirée mémorable au cœur de l'excellence, marquée par la reconnaissance des succès passés et l'anticipation d'un avenir prometteur.",
        images: [ event_rolls_royce_1, event_rolls_royce_2, event_rolls_royce_3, event_rolls_royce_4, event_rolls_royce_5, event_rolls_royce_6, event_rolls_royce_7, event_rolls_royce_8, event_rolls_royce_9, event_rolls_royce_10, event_rolls_royce_11, event_rolls_royce_12],
      });
    } else if (event.title === "Maison du Mariage") {
      setSelectedEvent({
        title: "Maison du Mariage",
        subtitle: "Noël · 6 photos",
        description: "Le Château a ouvert ses portes avec joie pour accueillir l'équipe du Groupe TANGRAM, de La Maison du Mariage et de SO Connect pour une Christmas Party empreinte de magie. Dans l'écrin de nos murs chargés d'histoire, l'atmosphère s'est parée de convivialité et de partage. À tous ceux qui ont contribué à la réussite de cette soirée, notre plus profonde gratitude.",
        images: [ event_mdm_1, event_mdm_2, event_mdm_3, event_mdm_4, event_mdm_5, event_mdm_6, event_mdm_7, event_mdm_8, event_mdm_9, event_mdm_10,  ],
      });
    } else if (event.title === "Wedding Presta Time") {
      setSelectedEvent({
        title: "Wedding Presta Time",
        subtitle: "Afterwork · 10 photos",
        description: "Le château a ouvert ses portes avec enchantement pour recevoir l'équipe de Wedding Presta Time lors d'un after work sensationnel. Leur confiance nous touche profondément, et nous sommes impatients de revivre cette expérience avec tout autant d'enthousiasme !",
        images: [ event_WeddingPrestaTime_5, event_WeddingPrestaTime_1, event_WeddingPrestaTime_2, event_WeddingPrestaTime_3, event_WeddingPrestaTime_4, event_WeddingPrestaTime_6,  ],
      });
    }
    
    
    
    else {
      // Handle other events if needed
      setSelectedEvent(event);
    }
  };
  

  
  const [isProductsSiderbarDetail, setIsProductsSiderbarDetail] = useState(false);
  const [productDetail, setProductDetail] = useState({ name: '' });

  const arrowBtnClass = isDetail ? 'clt-detail-left-section-2-cards-arrowBtn' : 'clt-section-3-cards-arrowBtn';


  const DetailMainSliderArrows = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    const disableState = totalItems - slidesToShow;
    return (
      <>
      <div className="d-flex justify-content-between clt-detail-left-section-2-header">
          <p className={isDetail ? 'aaclt_admin_dash_timeslot_edit_box_subheading1744 mt-15' : 'aaclt_admin_dash_timeslot_edit_box_subheading mt-1'} >{rest.subtitle}</p>
      </div>
      <div className={isDetail ? 'd-flex justify-content-between clt-home-visite-h2-header23535454' : 'd-flex justify-content-between clt-home-visite-h2-header2 clt-margin-bottom-section-recent-event'} >

        <h2 className={isDetail ? 'clt-detail-section-7142' : 'clt-home-visite-h2'} >{rest.title}</h2>
        <div>
          <Button
            className={
              currentSlide === 0
                ? `disable ${arrowBtnClass} me-2`
                : `${arrowBtnClass} me-2`
            }
            onClick={() => previous()}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </Button>
          <Button
            className={
              currentSlide === disableState
                ? `disable ${arrowBtnClass}`
                : arrowBtnClass
            }
            onClick={() => next()}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </Button>
        </div>
      </div>
      </>
    );
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const openGallery = async (type, index, myGallery) => {
    const lightboxGallery = await Promise.all(
      myGallery.map(async (item) => {
        const img = new Image();
        img.src = item?.ImageUrl;
        await img.decode();
        return {
          src: img.src,
          name: item.title,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };

  return (
    <>
      <Container className={isDetail ? 'clt-detail-page-container clt-homepage-container' : 'clt-homepage-container'}>
        <Row className="showMobile">
          <Col className={isDetail ? 'clt-section-row-col-pddzkjdkjzn' : 'clt-section-row-col-pd'}>
            <h2 className={isDetail ? 'clt-detail-section-7142' : 'clt-home-visite-h22123'} >Événements récents</h2>
            <p className={isDetail ? 'abaclt_admin_dash_timeslot_edit_box_subheading174 mt-1' : 'abaclt_admin_dash_timeslot_edit_box_subheading mt-1'} >Retrouvez les derniers événements au château</p>


          </Col>
        </Row>
      </Container>
      <Container className={isDetail ? 'clt-detail-page-container clt-homepage-container showMobile' : 'clt-homepage-container  showMobile'} style={{ flexDirection: 'row' }}>
        <Row className="clt-detail-left-section-2-row showMobile">
          <Col>
            <Container className="clt-detail-left-section-2-row-container">
              
              <Row>
                
                <Col className={isDetail ? 'clt-detail-left-section-2-row-col' : 'clt-detail-left-section-2-row-col'}>
                  <div className={isDetail ? 'd-flex justify-content-start align-items-center clt-detail-left-section-2 clt-detail-left-section-21745' : 'd-flex justify-content-start align-items-center clt-detail-left-section-2'}>
                    {secondaryGallery.map((value, index) => (
                      <Col key={index}>
                        <div
                          onClick={() => handleSidebarOpen(value)}
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                        >
                          <div
                            className="d-flex flex-column justify-content-start align-items-center clt-detail-left-section-2-cards clt-detail-left-section-2-cards-main clt-detail-left-section-3-cards clt-detail-left-section-3-cards-main"
                            style={{
                              backgroundImage: `url(${value.src})`,
                            }}
                          >
                            {/* Image content */}
                          </div>
                          {/* Span content */}
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span
                              className="clt-detail-left-section-4-card-item-h2"
                              style={{ cursor: 'pointer' }}
                            >
                              {value.title}
                            </span>
                            <span
                              className="clt-detail-left-section-4-card-item-h2-sub"
                              style={{ cursor: 'pointer' }}
                            >
                              {value.subtitle}
                            </span>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
        </Col>
      </Row>
    </Container>


    <Container fluid className={isDetail ? 'clt-detail-left-section-2-col674677 hideMobile' : 'clt-homepage-container hideMobile'}>
        <Row>
          <Col className={isDetail ? 'clt-section-row-col-pd ' : 'clt-section-row-col-pd dnedzkndze'}>
            <h2 className={isDetail ? 'clt-home-visite-h2354653422kjczncdizdn ' : 'clt-home-visite-h2354653422'}>Événements récents</h2>
            <h2 className={isDetail ? 'clt-home-visite-h2354653422kjczncdizdn ' : 'clt-home-visite-h2'}>Retrouvez les derniers événements</h2>
          </Col>
        </Row>
    </Container>
      
    <Container className={isDetail ? 'clt-detail-page-container clt-detail-left-section-2-col674677 hideMobile' : 'clt-homepage-container hideMobile'}>
      <Row className="hideMobile" style={{ flexDirection: 'row' }}>
        <Col className="clt-detail-left-section-2-col">
          <div className={isDetail ? 'd-flex flex-column-reverse' : 'd-flex flex-column'}>
            <CustomCarousel
              responsive={detailSecondarySliderResponsive}
              arrows={false}
              shouldResetAutoplay={false}
              renderButtonGroupOutside={true}
              customButtonGroup={
                <DetailMainSliderArrows title="Événements récents" subtitle="Retrouvez les derniers événements au château" />
              }
              className={isDetail ? 'clt-detail-left-section-2-cards-div my-carousel175343544' : 'clt-detail-left-section-2-cards-div my-carousel4'} 
            >
                {secondaryGallery.map((item, index) => (
                  <div
                    key={index}
                    className="clt-detail-left-section-2-card-container"
                    onClick={() => handleSidebarOpen(item)}
                  >
                    <div
                      className="d-flex flex-column justify-content-start align-items-center clt-home-visite-h2-cards clt-home-visite-h3-cards clt-detail-left-section-2-cards-main clt-detail-left-section-3-cards clt-detail-left-section-3-cards-main cursor-zoom-in"
                      style={{
                        backgroundImage: `url(${item.src})`,
                      }}
                    >
                      {/* Image content */}
                    </div>
                    {/* Span content*/}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span
                        className={isDetail ? 'clt-detail-left-section-4-card-item-h2KCDJNLKDJ' : 'clt-detail-left-section-4-card-item-h2'} 
                        style={{ cursor: 'pointer' }}
                      >
                        {item.title}
                      </span>
                      <span
                        className={isDetail ? 'clt-detail-left-section-4-card-item-h2-subfjknlekv' : 'clt-detail-left-section-4-card-item-h2-sub'} 
                        style={{ cursor: 'pointer' }}
                      >
                        {item.subtitle}
                      </span>
                    </div>
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </Col>
        </Row>
      </Container>
      

      <CustomOffCanvas
        placement="end"
        className="clt_products_offcanvas2"
        show={isEventSidebarOpen}
        onHide={() => setIsEventSidebarOpen(false)}
        headerClassName="justify-content-start clt_products_offcanvas_header"
        bodyClassName="clt_products_offcanvas_body clt_products_offcanvas_body-p-0"
        headerTitle={selectedEvent ? selectedEvent.title : "Option"}
        isBackBtn={true}
        handleBack={() => setIsEventSidebarOpen(false)}
      >
        {selectedEvent && (
          <Container className="clt_product_detail_offcanvas_body clt_product_detail_offcanvas_body_eventrecent">
            <Row>
              <Col className="header_text_div">
                <h2>{selectedEvent.title}</h2>
                <p>{selectedEvent.description}</p>
              </Col>
            </Row>
           <Row>
            <Col className="p-0">
              <PhotoProvider>
                {selectedEvent.images.map((galleryPic, gIndex) => (
                  <div key={gIndex} className="clt-room-slider-img-div2 cursor-zoom-in">
                    <PhotoView src={galleryPic}>
                      <img
                        src={galleryPic}
                        alt={`Gallery ${gIndex + 1}`}
                        className="img-fluid"
                        onClick={() => openGallery("main", gIndex, selectedEvent.images)}
                      />
                    </PhotoView>
                  </div>
                ))}
              </PhotoProvider>
            </Col>
          </Row>

            
            {/* Additional content for the selected event */}
          </Container>
        )}
      </CustomOffCanvas>

    </>
  );
};

export default NewSection5;

export { section4ProductsSidebar, section4NewSection5, images, NewSection5 };

function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}
