import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container } from 'react-bootstrap';
import CustomCarousel from "../CustomCarousel";
import {
  IMG_ALT,
  selectRoomSliderResponsive
} from "../../../constants";
import CustomOffCanvas from "../CustomOffCanvas";
import Florist1_1 from "../../../assets/img/Florist1_1.jpg";
import detail_slider_1_1 from "../../../assets/img/detail_slider_1_1.png";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import CustomHr from "../CustomHr";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import admin_panel_first_section_desktop from "../../../assets/img/admin_panel_first_section_desktop.png";
import image1 from '../../../assets/img/room_slider_1.jpg';
import image2 from '../../../assets/img/room_slider_2.jpg';
import image3 from '../../../assets/img/room_slider_3.jpg';
import image4 from '../../../assets/img/room_slider_4.jpg';
import image5 from '../../../assets/img/room_slider_5.jpg';
import image6 from '../../../assets/img/room_slider_6.png';
import image7 from '../../../assets/img/room_slider_7.png';
import chaise_1_1 from '../../../assets/img/chaise_1_1.png';
import chaise_1_2 from '../../../assets/img/chaise_1_2.png';
import chaise_1_3 from '../../../assets/img/chaise_1_3.png';
import chaise_1_4 from '../../../assets/img/chaise_1_4.png';
import table_1_1 from '../../../assets/img/table_1_1.png';
import table_1_2 from '../../../assets/img/table_1_2.png';
import table_1_3 from '../../../assets/img/table_1_3.png';
import table_2_1 from '../../../assets/img/table_2_1.png';
import table_2_2 from '../../../assets/img/table_2_2.png';
import table_3_1 from '../../../assets/img/table_3_1.png';
import table_3_2 from '../../../assets/img/table_3_2.png';
import table_3_3 from '../../../assets/img/table_3_3.png';
import table_4_1 from '../../../assets/img/table_4_1.png';
import table_4_2 from '../../../assets/img/table_4_2.png';
import table_4_3 from '../../../assets/img/table_4_3.png';
import table_5_1 from '../../../assets/img/table_5_1.png';
import table_5_2 from '../../../assets/img/table_5_2.png';
import table_5_3 from '../../../assets/img/table_5_3.png';
import table_6_1 from '../../../assets/img/table_6_1.png';
import table_6_2 from '../../../assets/img/table_6_2.png';
import table_6_3 from '../../../assets/img/table_6_3.png';
import dortoirA1 from '../../../assets/img/clt_gallery5.jpg';
import dortoirB1 from '../../../assets/img/clt_gallery8.jpg';
import dortoirC1 from '../../../assets/img/clt_gallery9.jpg';
import clt_gallery12 from '../../../assets/img/clt_gallery12.jpg';
import clt_gallery13 from '../../../assets/img/clt_gallery13.jpg';
import clt_gallery14 from '../../../assets/img/clt_gallery14.jpg';
import clt_gallery15 from '../../../assets/img/clt_gallery15.jpg';
import clt_gallery16 from '../../../assets/img/clt_gallery16.jpg';
import clt_gallery17 from '../../../assets/img/clt_gallery17.jpg';
import clt_gallery18 from '../../../assets/img/clt_gallery18.jpg';
import clt_gallery19 from '../../../assets/img/clt_gallery19.jpg';
import clt_gallery4 from '../../../assets/img/clt_gallery4.jpg';

import imginfo from '../../../assets/img/img_section_info.png';
import admin_icon_booking_detail_3 from '../../../assets/img/admin_icon_booking_detail_3.png';
import room_icon_doublebed from '../../../assets/img/room_icon_doublebed.png';
import room_icon_singlebed from '../../../assets/img/room_icon_singlebed.png';
import { AuthContext } from "../../screens/auth/authContext";




const section4ProductsOptionMariage = [
  /* {
    img: dortoirA1,
    name: "Dortoir A",
    secondary_text: "50€/personne par nuit",
    description: "9 personnes",
  },*/

  {
    img: clt_gallery12,
    name: "Suite",
    secondary_text: "50€/personne par nuit",
    description: "9 personnes",
  },
  {
    img: chaise_1_1,
    name: "Chaise",
    secondary_text: "2€/chaise",
    description: "Quantité : 160",
  },


  /*{
    img: dortoirC1,
    name: "Dortoir B",
    secondary_text: "50€/personne par nuit",
    description: "9 personnes",
  },*/
  {
    img: table_5_1,
    name: "Table mange debout",
    secondary_text: "10€/table",
    description: "Quantité : 10",
  },
  /*{
    img: dortoirB1,
    name: "Dortoir C",
    secondary_text: "50€/personne par nuit",
    description: "6 personnes",
  }, */
  {
    img: table_1_1,
    name: "Table ronde ",
    secondary_text: "10€/table",
    description: "Quantité : 10",
  },
  {
    img: table_2_1,
    name: "Table rectangle",
    secondary_text: "10€/table",
    description: "Quantité : 10",
  },
  
  {
    img: table_6_2,
    name: "Table carré",
    secondary_text: "10€/table",
    description: "Quantité : 3",
  },
  {
    img: table_4_1,
    name: "Table demi-cercle",
    secondary_text: "10€/table",
    description: "Quantité : 2",
  },
  //{
   // img: table_3_1,
   // name: "Table rectangle B pour 8 personnes",
   // secondary_text: "10€/table",
   // description: "Quantité : 3",
  //},

  // ... (other section4Products data)
];

const section4ProductsSidebar = [
  /* {
    category: "Dortoir",
    products: [
      {
        img: dortoirA1,
        name: "Dortoir A",
        secondary_text: "50€/personne par nuit",
        description: "9 personnes",
        service: "Lit double x3",
        service1: "Lit simple x3",
        service2: "Chauffage",
        service3: "Climatisation",
        service4: "Linge de lit",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          dortoirA1
        ]
      },
      {
        img: dortoirC1,
        name: "Dortoir B",
        secondary_text: "50€/personne par nuit",
        description: "9 personnes",
        service: "Lit double x3",
        service1: "Lit simple x3",
        service2: "Chauffage",
        service3: "Climatisation",
        service4: "Linge de lit",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          dortoirC1
        ]
      },
      {
        img: dortoirB1,
        name: "Dortoir C",
        secondary_text: "50€/personne par nuit",
        description: "6 personnes",
        service1: "Lit simple x6",
        service2: "Chauffage",
        service3: "Climatisation",
        service4: "Linge de lit",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          dortoirB1
        ]
      },

      
      // ... (other products data)
    ],
  },*/
  {
  category: "Suite",
  products: [
    {
      img: clt_gallery13,
      name: "Suite",
      secondary_text: "50€/personne par nuit",
      description: "9 personnes",
      service: "Lit double x4",
      service1: "Lit simple x1",
      service2: "Chauffage",
      service3: "Climatisation",
      service4: "Linge de lit",
      subTexts: [
        // Add other objects as needed
      ],
      images: [
        clt_gallery12,
        clt_gallery13,
        clt_gallery14,
        clt_gallery4,
        clt_gallery15,
        clt_gallery16,
        clt_gallery17,
        clt_gallery18,
        clt_gallery19,
        
      ]
    },
    // ... (other products data)
  ],
  },
  {
    category: "Mobilier",
    products: [
      {
        img: chaise_1_1,
        name: "Chaise",
        secondary_text: "2€/chaise",
        description: "Quantité : 160",
        service: "Couleur: Marron nature",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          chaise_1_1,
          chaise_1_2,
          chaise_1_3,
          chaise_1_4
        ]
      },
      {
        img: table_1_1,
        name: "Table ronde",
        secondary_text: "10€/table",
        description: "Quantité : 20",
        service: "Couleur: Blanc",
        service1: "Largeur: 152cm",
        service2: "Hauteur:	74cm",
        service3: "Charge max:	500 kg",
        service4: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_1_1,
          table_1_2,
          table_1_3
        ]
      },
      {
        img: table_2_1,
        name: "Table rectangle",
        secondary_text: "10€/table",
        description: "Quantité : 10",
        service: "Couleur: Blanc",
        service1: "Longueur: 152cm",
        service2: "Largeur: 76cm",
        service3: "Hauteur:	74cm",
        service4: "Charge max:	200 kg",
        service5: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_2_1,
          table_2_2
        ]
      }, 
      {
        img: table_4_1,
        name: "Table demi-cercle",
        secondary_text: "10€/table",
        description: "Quantité : 2",
        service: "Couleur: Bois",
        service1: "Longueur:	112cm",
        service2: "Largeur: 60cm",
        service3: "Hauteur: 77cm",
        service4: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_4_1,
          table_4_2,
          table_4_3
        ]
      }, 
      {
        img: table_5_1,
        name: "Table mange debout",
        secondary_text: "10€/table",
        description: "Quantité : 10",
        service: "Couleur: Blanc",
        service1: "Diamètre: 80cm",
        service3: "Hauteur:	110cm",
        service5: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_5_1,
          table_5_2,
          table_5_3
        ]
      }, 
      {
        img: table_6_2,
        name: "Table carré",
        secondary_text: "10€/table",
        description: "Quantité : 3",
        service: "Couleur: Bois",
        service1: "Longueur: 121cm",
        service2: "Largeur: 121cm",
        service3: "Hauteur:	77cm",
        service4: "Charge max:	200 kg",
        service5: "Type: Table pliante",
        subTexts: [
          // Add other objects as needed
        ],
        images: [
          table_6_2,
          table_6_1,
          table_6_3
        ]
      }, 
      //{
        //img: table_3_1,
        //name: "Table rectangle B pour 8 personnes",
        //secondary_text: "10€/table",
        //description: "Quantité : 3",
        //service: "Couleur: Bois",
        //service1: "Longueur: 180cm",
        //service2: "Largeur: 76cm",
        //service3: "Hauteur:	76cm",
        //service4: "Charge max:	200 kg",
        //service5: "Type: Table pliante",
        //subTexts: [
          // Add other objects as needed
        //],
        //images: [
        //  table_3_1,
        //  table_3_2,
         // table_3_3
      //  ]
      // },
      // ... (other products data)
    ],
    
  },
  // ... (other categories data)
];

const images = [
  "detail_slider_1_1.png",
  "detail_slider_1_1.png",
  "detail_slider_1_1.png",
  // Add more image URLs as needed
];

const ProductsOptionMariage = ({ products, handleProductSlider, sliderProps, customButtonGroupTitle, isMobile }) => {
  const {isProductsSidebarOpen, setIsProductsSidebarOpen} = useContext(AuthContext);
  const [isProductsSidebarList, setIsProductsSidebarList] = useState(true);
  const [isProductsSiderbarDetail, setIsProductsSiderbarDetail] = useState(false);
  const [productDetail, setProductDetail] = useState({ name: '' });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const openGallery = async (type, index, myGallery) => {
    const lightboxGallery = await Promise.all(
      myGallery.map(async (item) => {
        const img = new Image();
        img.src = item?.ImageUrl;
        await img.decode();
        return {
          src: img.src,
          name: item.title,
          width: windowSize?.innerWidth,
          height: (img.height / img.width) * windowSize?.innerWidth,
        };
      })
    );

    let lightbox = {};
    if (type === "main" || type === "secondary") {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#gallery",
        children: "a",
        dataSource: lightboxGallery,
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();
      lightbox.loadAndOpen(index);
    }
  };

  const handleSidebarOpen = () => {
    setIsProductsSidebarOpen(true);
  };

  return (
    <>
      <Row className="showMobile">
        <Col className="clt-detail-left-section-4-h2-mt clt-section-row-col-pd">
          <h2 className="clt-detail-left-section-4-h2">Option</h2>
          <p className="abaclt_admin_dash_timeslot_edit_box_subheading17584 mt-1">
            Certains espaces et équipement sont optionnels et payants, vous pouvez ajouter ces éléments après avoir passé votre réservation en nous contactant
          </p>
        </Col>
      </Row>
      
      <Row className="clt-detail-left-section-4-row showMobile">
        <Col>
          <Container className="clt-detail-left-section-4-row-container">
            <Row>
              <Col className="clt-detail-left-section-4-row-col">
                <div className="d-flex justify-content-start align-items-center clt-detail-left-section-4">
                  {products?.map((value, index) => (
                    <div key={index}>
                      <div className="clt-detail-left-section-4-card-item cursor-zoom-in" onClick={handleSidebarOpen}>
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="clt-detail-left-section-4-card-item-div" style={{ backgroundImage: `url(${value.img})` }}></div>
                          <div><i className="fa-light fa-chevron-right"></i></div>
                        </div>
                        <h2 className="clt-detail-left-section-4-card-item-h2">{value.name}</h2>
                        {/* <p className="clt-detail-left-section-4-card-item-secondary">{value.secondary_text}</p> */}
                        <p className="clt-detail-left-section-4-card-item-desc">{value.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="hideMobile">
        <Col className="clt-detail-left-section-4-col">
          <div className="d-flex flex-column-reverse">
            <CustomCarousel {...sliderProps}>
              {products?.map((value, index) => (
                <>
                  <div key={index} className="clt-detail-left-section-4-card-item cursor-zoom-in" onClick={handleSidebarOpen}>
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="clt-detail-left-section-4-card-item-div" style={{ backgroundImage: `url(${value.img})` }}>
                      </div>
                      <div>
                        <i className="fa-light fa-chevron-right">
                        </i>
                      </div>
                    </div>
                    <h2 className="clt-detail-left-section-4-card-item-h2">{value.name}</h2>
                    {/*<p className="clt-detail-left-section-4-card-item-secondary">{value.secondary_text}</p>*/}
                    <p className="clt-detail-left-section-4-card-item-desc">{value.description}</p>
                  </div>
                </>
              ))}
            </CustomCarousel>
          </div>
        </Col>
      </Row>

      <CustomOffCanvas
        placement="end"
        className="clt_products_offcanvas"
        show={isProductsSidebarOpen}
        onHide={() => setIsProductsSidebarOpen(false)}
        headerClassName="justify-content-start clt_products_offcanvas_header"
        bodyClassName={`${!isProductsSiderbarDetail ? "clt_products_offcanvas_body clt_products_offcanvas_body-p-0" : "p-0"}`}
        headerTitle={isProductsSidebarList ? "Liste des options" : productDetail.name}
        isBackBtn={true}
        handleBack={() =>
          isProductsSiderbarDetail
            ? (setIsProductsSiderbarDetail(false), setIsProductsSidebarList(true))
            : setIsProductsSidebarOpen(false)
        }
      >
        {isProductsSidebarList && section4ProductsSidebar?.map((val, i) => {
          return (
            <Row key={i} className="clt_products_offcanvas_body_mainrow">
              <Col className="p-0 ">
                <Row className="clt_products_offcanvas_body_maindiv">
                  <Col>
                    <h2 className="clt_products_offcanvas_body_category">
                      {val.category}
                    </h2>
                  </Col>
                </Row>
                {val.products?.map((value, index) => (
                  <Row
                    key={index}
                    className="clt_products_offcanvas_body_secdiv"
                    onClick={() => {
                      setIsProductsSiderbarDetail(true);
                      setIsProductsSidebarList(false);
                      setProductDetail(value);
                    }}
                  >
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <h2 className="clt_products_offcanvas_body_product">
                        {value.name}
                      </h2>
                      {/* <p className="clt_products_offcanvas_body_secondary">
                        {value.secondary_text}
                      </p> */}
                      <p className="clt_products_offcanvas_body_desc">
                        {value.description}
                      </p>

                      {/*
                      <div className="d-flex justify-content-start align-items-center clt-products-text-tags">
                        <span
                          className="d-flex justify-content-center align-items-center"
                        >
                          <img src={room_icon_doublebed} alt={IMG_ALT} />
                          {value.span1}
                        </span>

                        <span
                          className="d-flex justify-content-center align-items-center"
                        >
                          <img src={room_icon_singlebed} alt={IMG_ALT} />
                          {value.span2}
                        </span>
                      </div> */}
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={4}>
                      <div className="clt_products_offcanvas_body_img" style={{ backgroundImage: `url(${value.img})`, }}></div>
                    </Col>
                  </Row>
                ))}
              </Col>
              <CustomHr colClass="clt-section-hr-pd2" hrClass="p-0 m-0 clt-hr" />
            </Row>
            
          );
        })}
        {isProductsSiderbarDetail && (
          <Container className="clt_product_detail_offcanvas_body">
            <Row>
              <Col className="p-0">
                <PhotoProvider>
                  <CustomCarousel
                    responsive={selectRoomSliderResponsive}
                    arrows={true}
                    shouldResetAutoplay={false}
                    renderButtonGroupOutside={true}
                    showDots={true}
                    swipeable={true}
                    className="clt-room-slider"
                  >
                    {productDetail.images.map((galleryPic, gIndex) => (
                      <PhotoView src={galleryPic} key={gIndex}>
                        <div
                          className="d-flex justify-content-start align-items-start clt-room-slider-img-div cursor-zoom-in"
                          style={{
                            backgroundImage: `url(${galleryPic})`,
                          }}
                          onClick={() => openGallery("main", gIndex, productDetail.images)}
                        />
                      </PhotoView>
                    ))}
                  </CustomCarousel>
                </PhotoProvider>
              </Col>
            </Row>
            <Row>
              <Col className="header_text_div">
                <h2>{productDetail.name}</h2>
                {/* <p>{productDetail.secondary_text} · {productDetail.description} </p> */}
                <p>{productDetail.description} </p>
              </Col>
            </Row>
            <CustomHr
              colClass="clt_product_detail_offcanvas_hr_pd"
              hrClass="p-0 m-0 clt-hr"
            />

            <Row>
              <Col className="mt-4 clt-prestataire-option-div clt-detail-footer-mb-col">
                <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
                  <img src={imginfo} alt={IMG_ALT} />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                      <h2>Option payante</h2>
                      <p>Cette option est payante, vous pouvez l'ajouter après avoir passé votre réservation en nous contactant</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>


            <Row>
              <Col className="mt-4 clt-prestataire-option-div clt-detail-footer-mb-col">
                <div className="d-flex justify-content-start align-items-start clt-booking-cp-div3">
                  <img src={admin_icon_booking_detail_3} alt={IMG_ALT} />
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start clt-booking-cp-div3-text">
                      <h2>Détail</h2>
                      <p>{productDetail.service}</p>
                      <p>{productDetail.service1}</p>
                      <p>{productDetail.service2}</p>
                      <p>{productDetail.service3}</p>
                      <p>{productDetail.service4}</p>
                      <p>{productDetail.service5}</p>
                      <p>{productDetail.service6}</p>
                      <p>{productDetail.service7}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>


            <Row>
              {productDetail.subTexts?.map((value, index) => (
                <Col
                  key={index}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="sub_text_div"
                >
                  <h2>{value.title}</h2>
                  <p>{value.subText}</p>
                  <p>{value.subText2}</p>
                  <p>{value.subText3}</p>
                  <p>{value.subText4}</p>
                  <p>{value.subText5}</p>
                  <p>{value.subText6}</p>
                  <p>{value.subText7}</p>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </CustomOffCanvas>
    </>
  );
};

export default ProductsOptionMariage;

export { section4ProductsSidebar, section4ProductsOptionMariage, images, ProductsOptionMariage };

function getWindowSize() {
  const { innerHeight, innerWidth } = window;
  return { innerHeight, innerWidth };
}
